import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';

export default class AddTierDialog extends Component {
  getInitialState = () => ({
    page: 'create', // create, upload
    name: '',
    file: null,
  });

  state = this.getInitialState();

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { show } = nextProps;

    if (!show) {
      this.setState(this.getInitialState());
    }
  }

  handleChangeType = event => this.setState({ page: event.target.value });

  handleChangeName = event => this.setState({ name: event.target.value });

  handleChangeFile = event => this.setState({ file: event.target.files[0] });

  handleClickUploadFile = () => {
    const { onSuccess } = this.props;
    const { name, file } = this.state;
    const items = ['name', 'file'];
    const tier = { name, file };

    if (this.validate(items)) {
      onSuccess(tier);
    }
  };

  handleClickAddTier = () => {
    const { onSuccess } = this.props;
    const { name } = this.state;
    const items = ['name'];
    const tier = { name: name.trim() };

    if (this.validate(items)) {
      onSuccess(tier);
    }
  };

  validate = items => {
    const { tiers, addNotification } = this.props;
    const { name, file } = this.state;

    return !items.some(item => {
      if (item === 'name') {
        if (!name.trim()) {
          addNotification({ type: 'danger', text: 'Type a Tier name.' });

          return true;
        }

        if (tiers.some(tier => tier.name.toLowerCase().trim() === name.toLowerCase().trim())) {
          addNotification({ type: 'danger', text: 'That Tier name already exists.' });

          return true;
        }
      }

      if (item === 'file' && !file) {
        addNotification({ type: 'danger', text: 'Select a Data File.' });

        return true;
      }

      return false;
    });
  };

  renderNameInput = () => {
    const { name } = this.state;

    return (
      <div className="form-group">
        <label>Tier name</label>
        <input
          className="form-control"
          placeholder="Enter a name"
          value={name}
          onChange={this.handleChangeName}
          autoFocus
        />
      </div>
    );
  };

  renderTypeSelect = () => {
    const { page } = this.state;

    return (
      <div className="form-group">
        <label>Data source</label>
        <select className="form-control" value={page} onChange={this.handleChangeType}>
          <option value="create">No source</option>
          <option value="upload">CSV file</option>
        </select>
      </div>
    );
  };

  renderFileInput = () => (
    <div className="form-group">
      <label>CSV file</label>
      <input type="file" accept=".csv" onChange={this.handleChangeFile} />
    </div>
  );

  renderCancelButton = () => {
    const { onHide } = this.props;

    return <Button onClick={onHide}>Cancel</Button>;
  };

  renderUploadFileButton = () => (
    <Button bsStyle="primary" onClick={this.handleClickUploadFile}>
      Upload File
    </Button>
  );

  renderAddTierButton = () => (
    <Button bsStyle="primary" onClick={this.handleClickAddTier}>
      Add Tier
    </Button>
  );

  renderBody = () => {
    const { page } = this.state;

    switch (page) {
      case 'create':
        return (
          <div>
            {this.renderNameInput()}
            {this.renderTypeSelect()}
          </div>
        );
      case 'upload':
        return (
          <div>
            {this.renderNameInput()}
            {this.renderTypeSelect()}
            {this.renderFileInput()}
          </div>
        );
      default:
        return null;
    }
  };

  renderFooter = () => {
    const { page } = this.state;

    switch (page) {
      case 'create':
        return (
          <div>
            {this.renderCancelButton()}
            {this.renderAddTierButton()}
          </div>
        );
      case 'upload':
        return (
          <div>
            {this.renderCancelButton()}
            {this.renderUploadFileButton()}
          </div>
        );
      default:
        return null;
    }
  };

  render() {
    const { show, onHide } = this.props;
    const body = this.renderBody();
    const footer = this.renderFooter();

    return (
      <Modal dialogClassName="data-object-dialog" backdrop="static" show={show} onHide={onHide}>
        <Modal.Header>
          <Modal.Title>Add Tier</Modal.Title>
        </Modal.Header>
        <Modal.Body>{body}</Modal.Body>
        <Modal.Footer>{footer}</Modal.Footer>
      </Modal>
    );
  }
}

AddTierDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  tiers: PropTypes.array.isRequired,
  addNotification: PropTypes.func.isRequired,
};
