import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { DeviceType } from './utils/DeviceType';

export default class DeviceRotationControl extends Component {
  getSoftwareRotationOptions = () =>
    [0, 90, 180, 270].map((item, index) => (
      <option key={index} value={item}>
        {item} degrees
      </option>
    ));

  getHardwareRotationSettings = device_type => {
    const hrOptions = [{ value: null, text: 'Not Set' }];
    const setOptions = options =>
      options.forEach(value => hrOptions.push({ value, text: `${value} degrees` }));

    if (
      [DeviceType.HISENSE_DM, DeviceType.M12S, DeviceType.S35, DeviceType.X96_X6].includes(
        device_type,
      )
    ) {
      setOptions([0, 90, 180, 270]);
    } else if (device_type === DeviceType.APC380) {
      setOptions([0, 270]);
    } else if ([DeviceType.PHILIPS_4050_ZIP, DeviceType.PHILIPS_4050].includes(device_type)) {
      hrOptions.push({ value: 0, text: 'Off' }, { value: 90, text: 'Auto' });
    } else if (
      [
        DeviceType.PHILIPS_3650,
        DeviceType.PHILIPS_4051,
        DeviceType.PHILIPS_4150,
        DeviceType.PHILIPS_4550,
        DeviceType.PHILIPS_4650,
        DeviceType.PHILIPS_CRD50,
      ].includes(device_type)
    ) {
      setOptions([0, 90]);
    } else if ([DeviceType.VESTEL_400VS, DeviceType.ANDROID_OTHER_VESTEL].includes(device_type)) {
      setOptions([0, 90, 270]);
    }

    return hrOptions;
  };

  getHardwareRotationOptions = device_type => {
    return this.getHardwareRotationSettings(device_type).map((item, index) => (
      <option key={index} value={item.value}>
        {item.text}
      </option>
    ));
  };

  isHardwareRotationSupported = device_type => {
    return [
      DeviceType.ANDROID_OTHER_VESTEL,
      DeviceType.APC380,
      DeviceType.HISENSE_DM,
      DeviceType.M12S,
      DeviceType.PHILIPS_3650,
      DeviceType.PHILIPS_4050_ZIP,
      DeviceType.PHILIPS_4050,
      DeviceType.PHILIPS_4051,
      DeviceType.PHILIPS_4150,
      DeviceType.PHILIPS_4550,
      DeviceType.PHILIPS_4650,
      DeviceType.PHILIPS_CRD50,
      DeviceType.S35,
      DeviceType.VESTEL_400VS,
      DeviceType.X96_X6,
    ].includes(device_type);
  };

  renderSoftwareRotationControlLabel = hr_supported => {
    let control;
    const label = <ControlLabel>Software Rotation</ControlLabel>;
    const tooltipText =
      'Usually Software Rotation should be 0. However, you may wish to set it for compatibility reasons';

    if (hr_supported) {
      control = (
        <OverlayTrigger
          animation={false}
          placement="bottom"
          overlay={<Tooltip id={tooltipText}>{tooltipText}</Tooltip>}
        >
          {label}
        </OverlayTrigger>
      );
    } else {
      control = label;
    }

    return control;
  };

  render() {
    const {
      device_type,
      viewMode,
      hardware_rotation,
      onChangeSelect,
      software_rotation,
    } = this.props;
    const hr_supported = this.isHardwareRotationSupported(device_type);
    const srLabel = this.renderSoftwareRotationControlLabel(hr_supported);

    return (
      <div>
        <div id="hardware-rotation">
          <ControlLabel>Hardware Rotation</ControlLabel>
          <select
            data-test="select-hwrotation"
            name="hardware_rotation"
            value={hardware_rotation || ''}
            onChange={onChangeSelect}
            disabled={viewMode || !hr_supported}
          >
            {this.getHardwareRotationOptions(device_type)}
          </select>
        </div>

        <div>
          {srLabel}
          <select
            data-test="select-swrotation"
            name="rotation"
            value={software_rotation}
            onChange={onChangeSelect}
            disabled={viewMode}
          >
            {this.getSoftwareRotationOptions()}
          </select>
        </div>
      </div>
    );
  }
}

DeviceRotationControl.propTypes = {
  hardware_rotation: PropTypes.number,
  software_rotation: PropTypes.number.isRequired,
  device_type: PropTypes.string,
  onChangeSelect: PropTypes.func.isRequired,
  viewMode: PropTypes.bool.isRequired,
};
