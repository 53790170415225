import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { OverlayTrigger, Popover } from 'react-bootstrap';

export default class AssetImage extends Component {
  getId = sign => {
    const { id, item } = this.props;

    if (sign) {
      if (sign.content_id) {
        return sign.content_id;
      } // content_item, assignment

      if (sign.sign_id) {
        return sign.sign_id;
      } // sequence entry

      return sign.id; // sign
    }

    return id !== undefined ? id : item.id;
  };

  getSignModifiedDate = id => {
    const {
      signs,
      item: { modifiedDate = '' },
    } = this.props;

    if (!signs) {
      return modifiedDate;
    }

    const sign = signs.find(item => +item.id === +id);

    return sign ? sign.modifiedDate : '';
  };

  getIcon = () => {
    const { type } = this.props;

    switch (type) {
      case 'folder':
        return <i className="icon fa fa-folder" />;
      case 'image':
        return <i className="icon fa fa-file-image-o" />;
      case 'video':
        return <i className="icon fa fa-file-video-o" />;
      case 'file':
        return <i className="icon fa fa-file" />;
      case 'playlist':
        return <i className="icon fa fa-list" />;
      case 'channel':
        return <i className="icon fa fa-play" />;
      case 'sign':
        return <i className="icon fa fa-picture-o" />;
      case 'sequence':
        return <i className="icon fa fa-list-ol" />;
      case 'schedule':
        return <i className="icon fa fa-calendar" />;
      case 'campaign':
        return <i className="icon fa fa-bullhorn" />;
      default:
        return null;
    }
  };

  getThumbnail = () => {
    const { type } = this.props;

    switch (type) {
      case 'image':
      case 'video':
        return this.getFile();
      case 'playlist':
        return this.getPlaylist();
      case 'sign':
        return this.getSign();
      case 'sequence':
        return this.getSequence();
      default:
        return null;
    }
  };

  getFirstEntries = entries => {
    if (!entries) {
      return [];
    }

    return entries
      .filter(item => item)
      .sort((a, b) => a.order - b.order)
      .slice(0, Math.min(entries.length, 3))
      .reverse();
  };

  getTotalThumbnails = () => {
    const {
      type,
      item: { playlistEntries, sequenceEntries },
    } = this.props;

    switch (type) {
      case 'playlist':
        return this.getFirstEntries(playlistEntries).length;
      case 'sequence':
        return this.getFirstEntries(sequenceEntries).length;
      default:
        return 1;
    }
  };

  getThumbnailNode = (index, src, css = '') => (
    <div
      data-test="assigned-thumbnail"
      key={index}
      className={`image-${index}${css ? ` ${css}` : ''}`}
    >
      <img
        src={src}
        alt=""
        onLoad={event => event.target.parentNode.classList.add('loaded')}
        onError={event => event.target.parentNode.classList.remove('loaded')}
        ref={img => {
          if (img?.naturalWidth) {
            img.parentNode.classList[img.complete ? 'add' : 'remove']('loaded');
          }
        }}
      />
    </div>
  );

  getFile = () => {
    const {
      item: { fileId },
    } = this.props;
    const src = `/cms/data/v1/assets/${fileId || this.getId()}/thumbnail`;

    return this.getThumbnailNode(0, src);
  };

  getPlaylist = () => {
    const {
      item: { playlistEntries },
    } = this.props;
    const videos = this.getFirstEntries(playlistEntries);

    return videos.map((video, index) => {
      const src = `/cms/data/v1/assets/${video.asset_id}/thumbnail`;

      return this.getThumbnailNode(index, src);
    });
  };

  getSign = (argItem, index = 0) => {
    const { signs, item: propsItem } = this.props;
    const item = argItem || propsItem;
    const id = this.getId(item);
    const sign = (signs && signs.find(entry => +entry.id === +id)) || item;

    return this.isSignPending(sign)
      ? this.getSignPopover(sign, index)
      : this.getSignThumbnail(sign, index);
  };

  getSequence = () => {
    const {
      item: { sequenceEntries },
    } = this.props;
    const signs = this.getFirstEntries(sequenceEntries);

    return signs.map((sign, index) => this.getSign(sign, index, signs.length));
  };

  getSignThumbnail = (sign, index) => {
    const id = this.getId(sign);
    const src = `/cms/data/v1/signs/${id}/pending/thumbnail?${this.getSignModifiedDate(id)}`;
    const css = this.isSignPending(sign) ? 'changed' : '';

    return this.getThumbnailNode(index, src, css);
  };

  getSignPopover = (sign, index) => {
    const id = this.getId(sign);
    const date = this.getSignModifiedDate(id);

    return (
      <OverlayTrigger
        key={index}
        trigger={['hover', 'focus']}
        placement="right"
        overlay={
          <Popover id="popover-sign-id" className="popover-sign-id">
            <div data-test="alert-assigned-version" className="alert alert-info">
              The latest version is not published.
            </div>
            <table>
              <tbody>
                <tr>
                  <td>
                    <div>Published</div>
                    <div className="date">
                      {format(new Date(sign.publishedDate), 'MMM dd yyyy HH:mm:ss')}
                    </div>
                    <img src={`/cms/data/v1/signs/${id}/published/thumbnail?${date}`} alt="" />
                  </td>
                  <td>
                    <div>Latest</div>
                    <div className="date">
                      {format(new Date(sign.pendingDate), 'MMM dd yyyy HH:mm:ss')}
                    </div>
                    <img src={`/cms/data/v1/signs/${id}/pending/thumbnail?${date}`} alt="" />
                  </td>
                </tr>
              </tbody>
            </table>
          </Popover>
        }
      >
        {this.getSignThumbnail(sign, index)}
      </OverlayTrigger>
    );
  };

  isSignPending = sign => sign.publishedDate && sign.pendingDate !== sign.publishedDate;

  render() {
    return (
      <div className={`asset-image total-${this.getTotalThumbnails()}`}>
        {this.getThumbnail()}
        {this.getIcon()}
      </div>
    );
  }
}

AssetImage.propTypes = {
  type: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
  signs: PropTypes.array,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
