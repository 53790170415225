import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { differenceInSeconds, format, parse } from 'date-fns';
import ToolTipIcon from '../ToolTipIcon/ToolTipIcon';
import SSTable from '../SSTable/SSTable';
import SSColumn from '../SSTable/SSColumn';
import { buildTagList } from '../../utils/tagList';

export default class CampaignScheduleEntriesTable extends Component {
  getMaxDate = () => {
    const { data } = this.props;

    return data.find(
      item => differenceInSeconds(parse(item.date, 'yyyyMMdd', new Date()), new Date()) < 0,
    )
      ? null
      : new Date();
  };

  getPosition = (entry, index) => <span className="circle circle-md">{index + 1}</span>;

  getName = entry => (
    <div className="entry-name">
      <div>
        <Link to={`/campaigns/${entry.campaignId}`}>{entry.campaignName}</Link>
      </div>
      <div>Folder: {entry.campaignParent}</div>
      <div>
        Tags: {buildTagList({ tags: entry.campaignTags ? entry.campaignTags.split(',') : null })}
      </div>
    </div>
  );

  getDescription = entry => <div className="entry-description">{entry.campaignDescription}</div>;

  getDate = (entry, index) => {
    const {
      auth: {
        user: {
          permissions: { Writer },
        },
      },
      onChangeDate,
    } = this.props;
    const maxDate = this.getMaxDate();

    return (
      <DatePicker
        className={`entry-date${maxDate ? ' bg-danger' : ''}`}
        selected={parse(entry.date, 'yyyyMMdd', new Date())}
        onChange={date => onChangeDate(format(date, 'yyyyMMdd'), index)}
        placeholderText="Start date"
        dateFormat="dd/MM/yyyy"
        maxDate={maxDate}
        disabled={!Writer}
      />
    );
  };

  getTime = (entry, index) => {
    const {
      auth: {
        user: {
          permissions: { Writer },
        },
      },
      onChangeHour,
      onChangeMinute,
    } = this.props;
    const hours = [];
    const mins = [];

    for (let i = 0; i < 60; i += 1) {
      if (i < 24) {
        hours.push(<option key={i}>{i < 10 ? `0${i}` : i}</option>);
      }

      mins.push(<option key={i}>{i < 10 ? `0${i}` : i}</option>);
    }

    return (
      <div className="entry-time">
        <select
          value={entry.time.slice(0, 2)}
          onChange={event => onChangeHour(event, index)}
          disabled={!Writer}
        >
          {hours}
        </select>
        <select
          value={entry.time.slice(2, 4)}
          onChange={event => onChangeMinute(event, index)}
          disabled={!Writer}
        >
          {mins}
        </select>
      </div>
    );
  };

  getRemove = (entry, index) => {
    const { data, onRemoveEntry } = this.props;
    let removeButton = '';

    if (data.length > 1) {
      removeButton = (
        <ToolTipIcon toolText="Remove" onClick={() => onRemoveEntry(index)}>
          remove_circle_outline
        </ToolTipIcon>
      );
    }

    return removeButton;
  };

  render() {
    const {
      auth: {
        user: {
          permissions: { Writer },
        },
      },
      data,
    } = this.props;

    if (!data) {
      return null;
    }

    return (
      <SSTable
        className="responsive table-campaign-schedule"
        data={data}
        headerHeight={50}
        rowHeight={50}
        rowsCount={data.length - 1}
        zebra
      >
        <SSColumn cell={this.getPosition} />
        <SSColumn header="Name" cell={this.getName} />
        <SSColumn header="Description" cell={this.getDescription} />
        <SSColumn header="Start Date" cell={this.getDate} />
        <SSColumn header="Start Time" cell={this.getTime} />
        {Writer && <SSColumn width={5} header="Remove" cell={this.getRemove} />}
      </SSTable>
    );
  }
}

CampaignScheduleEntriesTable.propTypes = {
  auth: PropTypes.object.isRequired,
  data: PropTypes.array,
  onChangeDate: PropTypes.func.isRequired,
  onChangeHour: PropTypes.func.isRequired,
  onChangeMinute: PropTypes.func.isRequired,
  onRemoveEntry: PropTypes.func.isRequired,
};
