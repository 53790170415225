import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import async from 'async';
import pluralize from 'pluralize';
import { SOLR_DELAY } from '../../../../constants';
import { getUsers } from '../../../actions/userActions';
import { getCampaignSchedule } from '../../../actions/campaignScheduleActions';
import {
  getDevices,
  getStatus,
  getVersions,
  overrideDownloadSchedule,
  deployContent,
  restartPlayer,
  rebootDevice,
  setCopyConfig,
  copyConfig,
  requestDiagnostics,
  requestScreenshot,
  upgradeAllDevices,
  upgradeDevice,
  clearData,
  registerDevice,
  unregisterDevice,
  select,
  unselectAll,
} from '../../../actions/deviceActions';
import { addNotification } from '../../../actions/notificationActions';
import Header from '../../../components/Header/Header';
import Pagination from '../../Pagination/Pagination';
import PaginationInfo from '../../PaginationInfo/PaginationInfo';
import Filter from '../../Filter/Filter';
import Filters from '../../Filters/Filters';
import FiltersApplied from '../../FiltersApplied/FiltersApplied';
import Spinner from '../../../components/Spinner/Spinner';
import QuickAction from '../../../components/QuickAction/QuickAction';
import getActiveCampaignId from '../../../components/CampaignSchedule/utils/getActiveCampaignId';
import DevicesTable from '../../../components/Devices/DevicesTable';
import DeployDialog from '../../../components/Devices/DeployDialog';
import { getHeartbeatPercent } from '../../../components/Devices/DeviceUtils';
import CopyDeviceConfigDialog from '../../../components/Devices/CopyDeviceConfigDialog';
import RequestDiagnosticsDialog from '../../../components/Devices/RequestDiagnosticsDialog';
import RequestScreenshotDialog from '../../../components/Devices/RequestScreenshotDialog';
import DiagnosticsDialog from '../../../components/Devices/DiagnosticsDialog';
import ScreenshotDialog from '../../../components/Devices/ScreenshotDialog';
import RestartPlayerDialog from '../../../components/Devices/RestartPlayerDialog';
import RebootDeviceDialog from '../../../components/Devices/RebootDeviceDialog';
import UpgradeDeviceDialog from '../../../components/Devices/UpgradeDeviceDialog';
import ClearDataDialog from '../../../components/Devices/ClearDataDialog';
import RegisterDeviceDialog from '../../../components/Devices/RegisterDeviceDialog';
import UnregisterDeviceDialog from '../../../components/Devices/UnregisterDeviceDialog';
import ActionsMenu from '../../../components/ActionsMenu/ActionsMenu';
import errorMessage from '../../../utils/errorMessage';
import { getPermissions } from '../../../utils/getPermissions';
import { checkAllPathsAccess } from '../../../utils/checkPathAccess';
import { getDeployer } from '../../../utils/deployHelper';

export class DevicesPage extends Component {
  state = {
    statusPollInterval: 10000,
    statusPoll: null,
    devices: [],
    showDeployDialog: false,
    showCopyConfigDialog: false,
    showRequestDiagnosticsDialog: false,
    showRequestScreenshotDialog: false,
    showRestartDialog: false,
    showRebootDialog: false,
    showUpgradeDialog: false,
    showClearDataDialog: false,
    showRegisterDialog: false,
    showUnregisterDialog: false,
    showDiagnosticsDialog: false,
    showScreenshotDialog: false,
    showMoreFilters: false,
  };

  componentDidMount() {
    this.loadData();
    this.mergeDevicesStatus(this.props);
    document.addEventListener('visibilitychange', this.loadData);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      location: { search },
      unselectAll,
      getDevices,
    } = this.props;
    const {
      location: { search: nextSearch },
    } = nextProps;

    this.showFetchError(nextProps);
    this.mergeDevicesStatus(nextProps);

    if (search !== nextSearch) {
      unselectAll();
      getDevices(nextSearch);
    }
  }

  componentWillUnmount() {
    const { unselectAll } = this.props;
    const { statusPoll } = this.state;

    unselectAll();
    clearInterval(statusPoll);
    document.removeEventListener('visibilitychange', this.loadData);
  }

  loadData = () => {
    const { getCampaignSchedule, getUsers, getVersions } = this.props;
    const { statusPoll } = this.state;

    if (document.visibilityState === 'hidden') {
      clearInterval(statusPoll);

      return;
    }

    this.pollStatus();
    this.getDevices();
    getCampaignSchedule();
    getUsers();
    getVersions();
  };

  showFetchError = nextProps => {
    const {
      devices: { error },
      addNotification,
    } = this.props;
    const {
      devices: { error: nextError },
    } = nextProps;

    if (!error && nextError) {
      addNotification({ type: 'danger', text: errorMessage(nextError) });
    }
  };

  mergeDevicesStatus = nextProps => {
    const {
      devices: {
        devices: { devices },
        status: { statuses },
      },
    } = nextProps;
    const mergedDevices = [];

    if (!devices) {
      return;
    }

    devices.forEach(device => {
      const status = (statuses || []).find(item => device.id === item.id);
      const mergedDevice = { ...device, ...status };

      mergedDevice.heartbeatPercent = getHeartbeatPercent(mergedDevice);
      mergedDevices.push(mergedDevice);
    });
    this.setState({ devices: mergedDevices });
  };

  getSelectedItems = () => {
    const {
      devices: { selected },
    } = this.props;
    const { devices } = this.state;

    return Object.keys(selected).map(index => devices[index]);
  };

  getFirstSelectedId = () => (this.getSelectedItems()[0] ? this.getSelectedItems()[0].id : null);

  getSelectedExceptCopied = () => {
    const {
      devices: { copyConfig },
    } = this.props;
    const { devices } = this.state;

    if (!copyConfig || !devices.length) {
      return [];
    }

    return this.getSelectedItems().filter(item => item.id !== copyConfig.id);
  };

  getDevices = () => {
    const {
      location: { search },
      getDevices,
    } = this.props;

    return getDevices(search);
  };

  getStatus = () => {
    const { Cypress } = window;
    const { getStatus } = this.props;
    const { devices } = this.state;
    const ids = devices.length && devices.length <= 100 ? devices.map(item => item.id) : null;

    if (Cypress && !Cypress.env('RECORD') && !Cypress.cy.signstixCtx.serverInfo.routesStubbed) {
      return;
    }

    getStatus(ids);
  };

  pollStatus = () => {
    const { statusPollInterval } = this.state;

    this.getStatus();
    this.setState({ statusPoll: setInterval(this.getStatus, statusPollInterval) });
  };

  showCopyConfigDialog = () => {
    if (this.getSelectedExceptCopied().length) {
      this.setState({ showCopyConfigDialog: true });
    }
  };

  showDeployDialog = () => this.setState({ showDeployDialog: true });

  showRequestDiagnosticsDialog = () => this.setState({ showRequestDiagnosticsDialog: true });

  showRequestScreenshotDialog = () => this.setState({ showRequestScreenshotDialog: true });

  showRestartDialog = () => this.setState({ showRestartDialog: true });

  showRebootDialog = () => this.setState({ showRebootDialog: true });

  showUpgradeDialog = () => this.setState({ showUpgradeDialog: true });

  showClearDataDialog = () => this.setState({ showClearDataDialog: true });

  showRegisterDialog = () => this.setState({ showRegisterDialog: true });

  showUnregisterDialog = () => this.setState({ showUnregisterDialog: true });

  showDiagnosticsDialog = () => this.setState({ showDiagnosticsDialog: true });

  showScreenshotDialog = () => this.setState({ showScreenshotDialog: true });

  hideDeployDialog = () => this.setState({ showDeployDialog: false });

  hideCopyConfigDialog = () => this.setState({ showCopyConfigDialog: false });

  hideRequestDiagnosticsDialog = () => this.setState({ showRequestDiagnosticsDialog: false });

  hideRequestScreenshotDialog = () => this.setState({ showRequestScreenshotDialog: false });

  hideRestartDialog = () => this.setState({ showRestartDialog: false });

  hideRebootDialog = () => this.setState({ showRebootDialog: false });

  hideUpgradeDialog = () => this.setState({ showUpgradeDialog: false });

  hideClearDataDialog = () => this.setState({ showClearDataDialog: false });

  hideRegisterDialog = () => this.setState({ showRegisterDialog: false });

  hideUnregisterDialog = () => this.setState({ showUnregisterDialog: false });

  hideDiagnosticsDialog = () => this.setState({ showDiagnosticsDialog: false });

  hideScreenshotDialog = () => this.setState({ showScreenshotDialog: false });

  successCopyConfigDialog = () => {
    this.hideCopyConfigDialog();
    this.copyConfig();
  };

  successRequestDiagnosticsDialog = () => {
    this.hideRequestDiagnosticsDialog();
    this.diagnostics();
  };

  successRequestScreenshotDialog = () => {
    this.hideRequestScreenshotDialog();
    this.screenshot();
  };

  successRestartDialog = () => {
    this.hideRestartDialog();
    this.restart();
  };

  successRebootDialog = () => {
    this.hideRebootDialog();
    this.reboot();
  };

  successClearDataDialog = () => {
    this.hideClearDataDialog();
    this.clearData();
  };

  successUnregisterDialog = () => {
    this.hideUnregisterDialog();
    this.unregister();
  };

  setCopyConfig = () => {
    const { addNotification, setCopyConfig } = this.props;
    const device = this.getSelectedItems()[0];

    setCopyConfig(device);
    const text = (
      <span>
        Device Settings copied from <code key={device.id}>{device.name}</code> {device.description}
      </span>
    );

    addNotification({ type: 'success', text });
  };

  copyConfig = () => {
    const {
      devices: {
        copyConfig: { id },
      },
    } = this.props;
    const items = this.getSelectedExceptCopied();

    async.filter(
      items,
      (item, callback) =>
        copyConfig(id, item.id).then(
          res => callback(null, res),
          () => callback(null, false),
        ),
      this.copyConfigCallback,
    );
  };

  copyConfigCallback = (err, results) => {
    const { addNotification, unselectAll } = this.props;
    const items = this.getSelectedExceptCopied();
    let failedItems;
    let errorMsg = '';

    if (items.length === results.length) {
      addNotification({
        type: 'success',
        text: `${pluralize('Device', items.length, true)} settings updated.`,
      });
    } else {
      // collect the failed request items
      failedItems = items.filter(item => !results.includes(item));
      failedItems.forEach(item => {
        errorMsg += (errorMsg ? ', ' : 'Device settings update failed: ') + item.description;
      });
      addNotification({ type: 'danger', text: errorMsg });
    }

    // if there was at least one successful request, update
    if (results.length) {
      unselectAll();
      setTimeout(this.getDevices, SOLR_DELAY);
    }
  };

  diagnostics = () => {
    const { addNotification } = this.props;

    addNotification({ type: 'info', text: 'Requesting...' });
    const items = this.getSelectedItems();

    async.filterSeries(
      items,
      (item, callback) =>
        requestDiagnostics(item.id).then(
          res => callback(null, res),
          () => callback(null, false),
        ),
      this.diagnosticsCallback,
    );
  };

  diagnosticsCallback = (err, results) => {
    const { addNotification } = this.props;
    const items = this.getSelectedItems();
    let failedItems;
    let errorMsg = '';

    if (items.length === results.length) {
      addNotification({
        type: 'success',
        text: `${pluralize('Device diagnostics', items.length, true)} requested.`,
      });
    } else {
      // collect the failed request items
      failedItems = items.filter(item => !results.includes(item));
      failedItems.forEach(item => {
        errorMsg += (errorMsg ? ', ' : 'Device diagnostics request failed: ') + item.description;
      });
      addNotification({ type: 'danger', text: errorMsg });
    }

    this.getStatus();
  };

  overrideDownloadSchedule = () => {
    const items = this.getSelectedItems();

    async.filter(
      items,
      (item, callback) =>
        overrideDownloadSchedule(item.id).then(
          res => callback(null, res),
          () => callback(null, false),
        ),
      this.overrideDownloadScheduleCallback,
    );
  };

  overrideDownloadScheduleCallback = (err, results) => {
    const { addNotification } = this.props;
    const items = this.getSelectedItems();
    let failedItems;
    let errorMsg = '';

    if (items.length === results.length) {
      addNotification({
        type: 'success',
        text: `${pluralize('Override Download Schedule', items.length, true)} set.`,
      });
    } else {
      // collect the failed request items
      failedItems = items.filter(item => !results.includes(item));
      failedItems.forEach(item => {
        errorMsg += (errorMsg ? ', ' : 'Override Download Schedule failed: ') + item.description;
      });
      addNotification({ type: 'danger', text: errorMsg });
    }
  };

  screenshot = () => {
    const { addNotification } = this.props;

    addNotification({ type: 'info', text: 'Requesting...' });
    const items = this.getSelectedItems();

    async.filterSeries(
      items,
      (item, callback) =>
        requestScreenshot(item.id).then(
          res => callback(null, res),
          () => callback(null, false),
        ),
      this.screenshotCallback,
    );
  };

  screenshotCallback = (err, results) => {
    const { addNotification } = this.props;
    const items = this.getSelectedItems();
    let failedItems;
    let errorMsg = '';

    if (items.length === results.length) {
      addNotification({
        type: 'success',
        text: `${pluralize('Device screenshot', items.length, true)} requested.`,
      });
    } else {
      // collect the failed request items
      failedItems = items.filter(item => !results.includes(item));
      failedItems.forEach(item => {
        errorMsg += (errorMsg ? ', ' : 'Device screenshot request failed: ') + item.description;
      });
      addNotification({ type: 'danger', text: errorMsg });
    }

    this.getStatus();
  };

  restart = version => {
    const items = this.getSelectedItems();

    async.filter(
      items,
      (item, callback) =>
        restartPlayer(item.id, version).then(
          res => callback(null, res),
          () => callback(null, false),
        ),
      this.restartCallback,
    );
  };

  restartCallback = (err, results) => {
    const { addNotification } = this.props;
    const items = this.getSelectedItems();
    let failedItems;
    let errorMsg = '';

    if (items.length === results.length) {
      addNotification({
        type: 'success',
        text: `${pluralize('Player restart', items.length, true)} requested.`,
      });
    } else {
      // collect the failed request items
      failedItems = items.filter(item => !results.includes(item));
      failedItems.forEach(item => {
        errorMsg += (errorMsg ? ', ' : 'Player restart request failed: ') + item.description;
      });
      addNotification({ type: 'danger', text: errorMsg });
    }
  };

  reboot = () => {
    const items = this.getSelectedItems();

    async.filter(
      items,
      (item, callback) =>
        rebootDevice(item.id).then(
          res => callback(null, res),
          () => callback(null, false),
        ),
      this.rebootCallback,
    );
  };

  rebootCallback = (err, results) => {
    const { addNotification } = this.props;
    const items = this.getSelectedItems();
    let failedItems;
    let errorMsg = '';

    // check for errors
    if (items.length === results.length) {
      addNotification({
        type: 'success',
        text: `${pluralize('Device reboot', items.length, true)} requested.`,
      });
    } else {
      // collect the failed request items
      failedItems = items.filter(item => !results.includes(item));
      failedItems.forEach(item => {
        errorMsg += (errorMsg ? ', ' : 'Device reboot request failed: ') + item.description;
      });
      addNotification({ type: 'danger', text: errorMsg });
    }
  };

  upgradeAll = version => {
    const { addNotification } = this.props;

    this.hideUpgradeDialog();

    return upgradeAllDevices(version).then(
      () => addNotification({ type: 'success', text: 'All Devices requested to update.' }),
      err => addNotification({ type: 'danger', text: errorMessage(err) }),
    );
  };

  upgrade = version => {
    const items = this.getSelectedItems();

    this.hideUpgradeDialog();
    async.filter(
      items,
      (item, callback) =>
        upgradeDevice(item.id, version).then(
          res => callback(null, res),
          () => callback(null, false),
        ),
      this.upgradeCallback,
    );
  };

  upgradeCallback = (err, results) => {
    const { addNotification } = this.props;
    const items = this.getSelectedItems();
    let failedItems;
    let errorMsg = '';

    // check for errors
    if (items.length === results.length) {
      addNotification({
        type: 'success',
        text: `${pluralize('Device upgrade', items.length, true)} requested.`,
      });
    } else {
      // collect the failed request items
      failedItems = items.filter(item => !results.includes(item));
      failedItems.forEach(item => {
        errorMsg += (errorMsg ? ', ' : 'Device upgrade request failed: ') + item.description;
      });
      addNotification({ type: 'danger', text: errorMsg });
    }
  };

  clearData = () => {
    const items = this.getSelectedItems();

    async.filter(
      items,
      (item, callback) =>
        clearData(item.id).then(
          res => callback(null, res),
          () => callback(null, false),
        ),
      this.clearDataCallback,
    );
  };

  clearDataCallback = (err, results) => {
    const { addNotification, unselectAll } = this.props;
    const items = this.getSelectedItems();
    let failedItems;
    let errorMsg = '';

    // check for errors
    if (items.length === results.length) {
      addNotification({
        type: 'success',
        text: `${pluralize('Clear signage data', items.length, true)} requested.`,
      });
    } else {
      // collect the failed request items
      failedItems = items.filter(item => !results.includes(item));
      failedItems.forEach(item => {
        errorMsg += (errorMsg ? ', ' : 'Clear signage data request failed: ') + item.description;
      });
      addNotification({ type: 'danger', text: errorMsg });
    }

    // if there was at least one successful request, update
    if (results.length) {
      unselectAll();
      setTimeout(this.getDevices, SOLR_DELAY);
    }
  };

  register = (id, description) => {
    const { history, addNotification } = this.props;

    return registerDevice(id, description).then(
      res => {
        addNotification({ type: 'success', text: 'Device registered.' });
        this.hideRegisterDialog();
        history.push(`/devices/${res.id}?edit`);
      },
      err =>
        addNotification({
          type: 'danger',
          text: `Registration failed. Please check that the device ID is correct. ${errorMessage(
            err,
          )}`,
        }),
    );
  };

  unregister = () => {
    const items = this.getSelectedItems();

    async.filter(
      items,
      (item, callback) =>
        unregisterDevice(item.id).then(
          res => callback(null, res),
          () => callback(null, false),
        ),
      this.unregisterCallback,
    );
  };

  unregisterCallback = (err, results) => {
    const { addNotification, unselectAll } = this.props;
    const items = this.getSelectedItems();
    let failedItems;
    let errorMsg = '';

    // check for errors
    if (items.length === results.length) {
      addNotification({
        type: 'success',
        text: `${pluralize('Device', items.length, true)} unregistered.`,
      });
    } else {
      // collect the failed request items
      failedItems = items.filter(item => !results.includes(item));
      failedItems.forEach(item => {
        errorMsg += (errorMsg ? ', ' : 'Device unregister request failed: ') + item.description;
      });
      addNotification({ type: 'danger', text: errorMsg });
    }

    // if there was at least one successful request, update
    if (results.length) {
      unselectAll();
      setTimeout(this.getDevices, SOLR_DELAY);
    }
  };

  onClickTable = devices => {
    const { select } = this.props;

    select(devices);
  };

  onDoubleClickTable = () => {
    const { history } = this.props;

    history.push(`/devices/${this.getFirstSelectedId()}`);
  };

  getHeaderActions = () => {
    const { auth, devices } = this.props;
    const p = getPermissions(auth);
    const { length } = this.getSelectedItems();
    let pasteConfigToolText = 'Paste Device Settings';
    const devicesLocations = this.getSelectedItems()
      .filter(Boolean)
      .map(device => device.location_path);
    const isAccessAllowed = checkAllPathsAccess(auth.user.user.userLocation, devicesLocations);
    let deployClassName = '';
    let deployToolText = 'Deploy Content';
    let deployFn = this.showDeployDialog;

    if (devices.copyConfig) {
      pasteConfigToolText = (
        <span>
          Paste Device Settings from
          <br />
          <code>{devices.copyConfig.name}</code>
          <br />
          <b>{devices.copyConfig.description}</b>
        </span>
      );
    }

    if (!isAccessAllowed) {
      deployClassName = 'disabled';
      deployToolText =
        'Deploy Content. Some selected Locations are not accessible with current User Location.';

      deployFn = () => {};
    }

    const override = p.atLeastTierStandardOrSuperUser && p.downloadSchedule && (
      <QuickAction
        key="1"
        toolText="Override Download Schedule"
        onClick={this.overrideDownloadSchedule}
        singular
        multiple
      >
        access_time
      </QuickAction>
    );
    const deploy = (
      <QuickAction
        key="2"
        toolText={deployToolText}
        onClick={deployFn}
        className={deployClassName}
        singular
        multiple
      >
        cloud_download
      </QuickAction>
    );
    const overview = (
      <QuickAction
        key="3"
        toolText="Device Overview"
        href={`/devices/${this.getFirstSelectedId()}`}
        singular
      >
        remove_red_eye
      </QuickAction>
    );
    const edit = p.atLeastTierStandardOrSuperUser && (
      <QuickAction
        key="4"
        toolText="Edit Device Settings"
        href={`/devices/${this.getFirstSelectedId()}?edit`}
        singular
      >
        mode_edit
      </QuickAction>
    );
    const copy = p.atLeastTierStandardOrSuperUser && (
      <QuickAction key="5" toolText="Copy Device Settings" onClick={this.setCopyConfig} singular>
        content_copy
      </QuickAction>
    );
    const paste = p.atLeastTierStandardOrSuperUser && (
      <QuickAction
        key="6"
        id="paste-device-settings"
        className={this.getSelectedExceptCopied().length ? '' : 'disabled'}
        toolText={pasteConfigToolText}
        onClick={this.showCopyConfigDialog}
        singular
        multiple
      >
        content_paste
      </QuickAction>
    );
    const diagnostics = p.atLeastTierStandardOrSuperUser && (
      <QuickAction
        key="7"
        toolText="Request Diagnostics"
        onClick={this.showRequestDiagnosticsDialog}
        singular
        multiple
      >
        assignment
      </QuickAction>
    );
    const screenshot = p.atLeastTierStandardOrSuperUser && (
      <QuickAction
        key="8"
        toolText={pluralize('Request Screenshot', length)}
        onClick={this.showRequestScreenshotDialog}
        singular
        multiple
      >
        photo_camera
      </QuickAction>
    );
    const restart = p.atLeastTierStandardOrSuperUser && (
      <QuickAction
        key="9"
        toolText={pluralize('Restart Player', length)}
        onClick={this.showRestartDialog}
        singular
        multiple
      >
        settings_backup_restore
      </QuickAction>
    );
    const reboot = p.atLeastTierStandardOrSuperUser && (
      <QuickAction
        key="10"
        toolText={pluralize('Reboot Device', length)}
        onClick={this.showRebootDialog}
        singular
        multiple
      >
        power_settings_new
      </QuickAction>
    );
    const upgrade = p.atLeastTierStandardOrSuperUser && (
      <QuickAction
        key="11"
        toolText={pluralize('Upgrade Device', length)}
        onClick={this.showUpgradeDialog}
        singular
        multiple
      >
        present_to_all
      </QuickAction>
    );
    const clearData = p.atLeastTierStandardOrSuperUser && (
      <QuickAction
        key="12"
        toolText="Clear Signage Data"
        onClick={this.showClearDataDialog}
        singular
        multiple
      >
        layers_clear
      </QuickAction>
    );
    const unregister = p.atLeastTierStandardOrSuperUser && (
      <QuickAction
        key="13"
        toolText={pluralize('Unregister Device', length)}
        onClick={this.showUnregisterDialog}
        singular
        multiple
      >
        delete
      </QuickAction>
    );
    const register = p.atLeastTierStandardOrSuperUser && (
      <QuickAction key="14" toolText="Register Device" onClick={this.showRegisterDialog} alwaysShow>
        add_circle
      </QuickAction>
    );

    if (!p.deviceManager) {
      return [overview, diagnostics, screenshot].filter(Boolean);
    }

    if (!p.downloadSchedule) {
      return [
        register,
        deploy,
        diagnostics,
        screenshot,
        restart,
        reboot,
        upgrade,
        clearData,
        unregister,
        copy,
        paste,
        overview,
        edit,
      ].filter(Boolean);
    }

    return [
      register,
      override,
      deploy,
      diagnostics,
      screenshot,
      restart,
      reboot,
      upgrade,
      clearData,
      unregister,
      copy,
      paste,
      overview,
      edit,
    ].filter(Boolean);
  };

  toggleMoreFilters = () => {
    const { showMoreFilters } = this.state;

    this.setState({ showMoreFilters: !showMoreFilters });
  };

  getFilters = () => {
    const { showMoreFilters } = this.state;
    const {
      auth,
      users: { users },
      devices: {
        devices: { facet_fields },
      },
    } = this.props;
    const p = getPermissions(auth);

    if (!facet_fields || !Object.keys(facet_fields).length) {
      return null;
    }

    const {
      // device status
      online_status,
      download_status,
      health_status,
      prestaged,
      version,
      firmware_version,
      device_type,
      override_download_schedule,
      healthcheck_config_name,
      // general
      download_interval,
      hardware_rotation,
      rotation,
      viewport_top_left,
      fps_limit,
      is_default,
      commander_enabled,
      // video wall
      synchronisation_method,
      sync_tag,
      time_sync,
      vw_enabled,
      vw_left,
      vw_top,
      vw_width,
      vw_height,
      vw_wall_width,
      vw_wall_height,
      // power schedules
      restart_enabled,
      restart_hour,
      restart_min,
      power_schedule_enabled,
      power_schedule_mon_1_on,
      power_schedule_mon_1_off,
      power_schedule_tue_1_on,
      power_schedule_tue_1_off,
      power_schedule_wed_1_on,
      power_schedule_wed_1_off,
      power_schedule_thu_1_on,
      power_schedule_thu_1_off,
      power_schedule_fri_1_on,
      power_schedule_fri_1_off,
      power_schedule_sat_1_on,
      power_schedule_sat_1_off,
      power_schedule_sun_1_on,
      power_schedule_sun_1_off,
      power_schedule_extra_1_key,
      power_schedule_extra_1_val,
      power_schedule_extra_2_key,
      power_schedule_extra_2_val,
      power_schedule_extra_3_key,
      power_schedule_extra_3_val,
      power_schedule_extra_4_key,
      power_schedule_extra_4_val,
      power_schedule_extra_5_key,
      power_schedule_extra_5_val,
      // diagnostics
      debug_toast,
      save_errors,
      show_progress,
      error_marker,
      show_stats,
      event_analytics_enabled,
      network_watchdog,
      // external
      allow_external_update,
      broadcast,
      broadcast_port,
      // metadata
      createdBy,
      modifiedBy,
      tags,
    } = facet_fields;
    const enabledText = { 0: 'Disabled', 1: 'Enabled', false: 'Disabled', true: 'Enabled' };
    const syncText = { 0: 'None', 1: 'NTP', 2: 'Master', 3: 'Slave' };

    return (
      <Filters>
        <Filter type="text" name="search" title="Search" placeholder="Search devices" />
        <Filter
          type="preset"
          preset="defaultDevice"
          name="is_default"
          title="default device"
          data={is_default}
        />

        <div className="filter-title">Device status</div>

        <Filter type="checkbox" name="online_status" title="Online status" data={online_status} />
        <Filter
          type="checkbox"
          name="download_status"
          title="Download status"
          data={download_status}
        />
        {p.atLeastTierStandardOrSuperUser && (
          <Filter type="dropdown" name="health_status" title="Health status" data={health_status} />
        )}
        <Filter type="dropdown" name="version" title="Player version" data={version} />
        <Filter
          type="dropdown"
          name="firmware_version"
          title="Firmware version"
          data={firmware_version}
        />
        <Filter type="dropdown" name="device_type" title="Device type" data={device_type} />
        <div className={showMoreFilters ? '' : 'hidden'}>
          <Filter
            type="preset"
            preset="prestagedDevices"
            name="prestaged"
            title="Prestage status"
            data={prestaged}
          />
          <div className="filter-title">General</div>
          <Filter
            type="dropdown"
            name="download_interval"
            title="Download interval"
            data={download_interval}
          />
          <Filter
            type="dropdown"
            name="hardware_rotation"
            title="Hardware rotation"
            data={hardware_rotation}
          />
          <Filter type="dropdown" name="rotation" title="Software rotation" data={rotation} />
          <Filter
            type="dropdown"
            name="viewport_top_left"
            title="Draw at top left"
            data={viewport_top_left}
          />
          <Filter type="dropdown" name="fps_limit" title="Max frame rate" data={fps_limit} />
          <Filter
            type="dropdown"
            name="commander_enabled"
            title="Commander"
            data={commander_enabled}
            text={enabledText}
          />
          <Filter
            type="dropdown"
            name="override_download_schedule"
            title="Override download schedule"
            data={override_download_schedule}
            text={enabledText}
          />
          <div className="filter-title">Video Wall</div>
          <Filter
            type="dropdown"
            name="synchronisation_method"
            title="Sync method"
            data={synchronisation_method}
            text={syncText}
          />
          <Filter type="dropdown" name="sync_tag" title="Sync tag" data={sync_tag} />
          <Filter type="dropdown" name="time_sync" title="NTP server" data={time_sync} />
          <Filter
            type="dropdown"
            name="vw_enabled"
            title="Video wall"
            data={vw_enabled}
            text={enabledText}
          />
          <Filter type="dropdown" name="vw_left" title="Video wall panel left" data={vw_left} />
          <Filter type="dropdown" name="vw_top" title="Video wall panel top" data={vw_top} />
          <Filter type="dropdown" name="vw_width" title="Video wall panel width" data={vw_width} />
          <Filter
            type="dropdown"
            name="vw_height"
            title="Video wall panel height"
            data={vw_height}
          />
          <Filter
            type="dropdown"
            name="vw_wall_width"
            title="Video wall width"
            data={vw_wall_width}
          />
          <Filter
            type="dropdown"
            name="vw_wall_height"
            title="Video wall height"
            data={vw_wall_height}
          />

          <div className="filter-title">Power Schedules</div>
          <Filter
            type="dropdown"
            name="restart_enabled"
            title="Scheduled reboot"
            data={restart_enabled}
            text={enabledText}
          />
          <div className="dual">
            <Filter type="dropdown" name="restart_hour" title="Reboot hour" data={restart_hour} />
            <Filter type="dropdown" name="restart_min" title="Reboot min" data={restart_min} />
          </div>
          <Filter
            type="dropdown"
            name="power_schedule_enabled"
            title="Scheduled on/off"
            data={power_schedule_enabled}
            text={enabledText}
          />
          <div className="dual">
            <Filter
              type="dropdown"
              name="power_schedule_mon_1_on"
              title="Mon on"
              data={power_schedule_mon_1_on}
            />
            <Filter
              type="dropdown"
              name="power_schedule_mon_1_off"
              title="Mon off"
              data={power_schedule_mon_1_off}
            />
          </div>
          <div className="dual">
            <Filter
              type="dropdown"
              name="power_schedule_tue_1_on"
              title="Tue on"
              data={power_schedule_tue_1_on}
            />
            <Filter
              type="dropdown"
              name="power_schedule_tue_1_off"
              title="Tue off"
              data={power_schedule_tue_1_off}
            />
          </div>
          <div className="dual">
            <Filter
              type="dropdown"
              name="power_schedule_wed_1_on"
              title="Wed on"
              data={power_schedule_wed_1_on}
            />
            <Filter
              type="dropdown"
              name="power_schedule_wed_1_off"
              title="Wed off"
              data={power_schedule_wed_1_off}
            />
          </div>
          <div className="dual">
            <Filter
              type="dropdown"
              name="power_schedule_thu_1_on"
              title="Thu on"
              data={power_schedule_thu_1_on}
            />
            <Filter
              type="dropdown"
              name="power_schedule_thu_1_off"
              title="Thu off"
              data={power_schedule_thu_1_off}
            />
          </div>
          <div className="dual">
            <Filter
              type="dropdown"
              name="power_schedule_fri_1_on"
              title="Fri on"
              data={power_schedule_fri_1_on}
            />
            <Filter
              type="dropdown"
              name="power_schedule_fri_1_off"
              title="Fri off"
              data={power_schedule_fri_1_off}
            />
          </div>
          <div className="dual">
            <Filter
              type="dropdown"
              name="power_schedule_sat_1_on"
              title="Sat on"
              data={power_schedule_sat_1_on}
            />
            <Filter
              type="dropdown"
              name="power_schedule_sat_1_off"
              title="Sat off"
              data={power_schedule_sat_1_off}
            />
          </div>
          <div className="dual">
            <Filter
              type="dropdown"
              name="power_schedule_sun_1_on"
              title="Sun on"
              data={power_schedule_sun_1_on}
            />
            <Filter
              type="dropdown"
              name="power_schedule_sun_1_off"
              title="Sun off"
              data={power_schedule_sun_1_off}
            />
          </div>
          <div className="dual">
            <Filter
              type="dropdown"
              name="power_schedule_extra_1_key"
              title="option 1: key"
              data={power_schedule_extra_1_key}
            />
            <Filter
              type="dropdown"
              name="power_schedule_extra_1_val"
              title="option 1: value"
              data={power_schedule_extra_1_val}
            />
          </div>
          <div className="dual">
            <Filter
              type="dropdown"
              name="power_schedule_extra_2_key"
              title="option 2: key"
              data={power_schedule_extra_2_key}
            />
            <Filter
              type="dropdown"
              name="power_schedule_extra_2_val"
              title="option 2: value"
              data={power_schedule_extra_2_val}
            />
          </div>
          <div className="dual">
            <Filter
              type="dropdown"
              name="power_schedule_extra_3_key"
              title="option 3: key"
              data={power_schedule_extra_3_key}
            />
            <Filter
              type="dropdown"
              name="power_schedule_extra_3_val"
              title="option 3: value"
              data={power_schedule_extra_3_val}
            />
          </div>
          <div className="dual">
            <Filter
              type="dropdown"
              name="power_schedule_extra_4_key"
              title="option 4: key"
              data={power_schedule_extra_4_key}
            />
            <Filter
              type="dropdown"
              name="power_schedule_extra_4_val"
              title="option 4: value"
              data={power_schedule_extra_4_val}
            />
          </div>
          <div className="dual">
            <Filter
              type="dropdown"
              name="power_schedule_extra_5_key"
              title="option 5: key"
              data={power_schedule_extra_5_key}
            />
            <Filter
              type="dropdown"
              name="power_schedule_extra_5_val"
              title="option 5: value"
              data={power_schedule_extra_5_val}
            />
          </div>

          <div className="filter-title">Diagnostics</div>
          <Filter
            type="dropdown"
            name="debug_toast"
            title="Overlay diagnostics"
            data={debug_toast}
            text={enabledText}
          />
          <Filter
            type="dropdown"
            name="save_errors"
            title="Save errors"
            data={save_errors}
            text={enabledText}
          />
          <Filter
            type="dropdown"
            name="show_progress"
            title="Download progress"
            data={show_progress}
            text={enabledText}
          />
          <Filter
            type="dropdown"
            name="error_marker"
            title="Error marker"
            data={error_marker}
            text={enabledText}
          />
          <Filter
            type="dropdown"
            name="show_stats"
            title="Statistics overlay"
            data={show_stats}
            text={enabledText}
          />
          <Filter
            type="dropdown"
            name="event_analytics_enabled"
            title="Collect analytics"
            data={event_analytics_enabled}
            text={enabledText}
          />
          <Filter
            type="dropdown"
            name="network_watchdog"
            title="Network watchdog"
            data={network_watchdog}
            text={enabledText}
          />
          <Filter
            type="dropdown"
            name="healthcheck_config_name"
            title="Healthcheck config"
            data={healthcheck_config_name}
          />

          <div className="filter-title">External</div>
          <Filter
            type="dropdown"
            name="allow_external_update"
            title="3rd party updates"
            data={allow_external_update}
            text={enabledText}
          />
          <Filter
            type="dropdown"
            name="broadcast"
            title="Broadcast"
            data={broadcast}
            text={enabledText}
          />
          <Filter
            type="dropdown"
            name="broadcast_port"
            title="Broadcast port"
            data={broadcast_port}
          />

          <div className="filter-title">Metadata</div>
          <Filter
            type="checkbox"
            name="createdBy"
            title="Registered by"
            data={createdBy}
            users={users}
          />
          <Filter type="preset" name="createdDate" title="Registered date" preset="daterange" />
          <Filter
            type="checkbox"
            name="modifiedBy"
            title="Modified by"
            data={modifiedBy}
            users={users}
          />
          <Filter type="preset" name="modifiedDate" title="Modified date" preset="daterange" />
          <Filter type="tag" name="tags" title="Tags" data={tags} />
        </div>
        <div className="filters-more">
          {p.atLeastTierStandardOrSuperUser && (
            <Button bsSize="xsmall" onClick={this.toggleMoreFilters}>
              Show {showMoreFilters ? 'less' : 'more'} filters
            </Button>
          )}
        </div>
      </Filters>
    );
  };

  render() {
    const {
      auth,
      users: { users },
      campaignSchedule: {
        campaignSchedule: { campaignScheduleEntries },
      },
      devices: {
        devices: { numFound },
        copyConfig,
        selected,
        versions,
        status: { summary },
      },
      deployContent,
    } = this.props;
    const { downloadSchedule } = auth.user;
    const {
      devices,
      showDeployDialog,
      showCopyConfigDialog,
      showRequestDiagnosticsDialog,
      showRequestScreenshotDialog,
      showRestartDialog,
      showRebootDialog,
      showUpgradeDialog,
      showClearDataDialog,
      showRegisterDialog,
      showUnregisterDialog,
      showDiagnosticsDialog,
      showScreenshotDialog,
    } = this.state;
    const loaded = devices && devices.length >= 0;
    const selectedItems = this.getSelectedItems();
    const { total, online, latestContent } = summary || {};

    return (
      <div className="page-structure">
        <Helmet title="Devices" />
        <Header />
        <ActionsMenu
          actions={this.getHeaderActions()}
          selectedLength={selectedItems.length}
          filterButton
        />
        <div className="page-panels">
          <div className="toolbar">
            <FiltersApplied users={users} />
            <PaginationInfo itemsFound={numFound} />
          </div>
          {this.getFilters()}
          <div className="page-content page-devices">
            <Spinner loading={!loaded}>
              <div data-test="devices-sum" className="devices-sum">
                <span>
                  <b>{total}</b> Devices
                </span>
                <span>
                  <b>{online}</b> Online
                </span>
                <span>
                  <b>{latestContent}</b> Latest Content
                </span>
              </div>
              <DevicesTable
                auth={auth}
                users={users}
                activeCampaignId={getActiveCampaignId(campaignScheduleEntries)}
                data={devices}
                selected={selected}
                onClick={this.onClickTable}
                onDoubleClick={this.onDoubleClickTable}
                onDiagnosticsClick={this.showDiagnosticsDialog}
                onScreenshotClick={this.showScreenshotDialog}
              />
            </Spinner>
            <Pagination itemsFound={numFound} />
          </div>
        </div>
        <DeployDialog
          show={showDeployDialog}
          onHide={this.hideDeployDialog}
          deployer={getDeployer(deployContent)}
          downloadSchedule={downloadSchedule}
          auth={auth}
          devices={selectedItems}
        />
        <CopyDeviceConfigDialog
          devices={this.getSelectedExceptCopied()}
          copyConfig={copyConfig}
          show={showCopyConfigDialog}
          onHide={this.hideCopyConfigDialog}
          onSuccess={this.successCopyConfigDialog}
        />
        <RequestDiagnosticsDialog
          devices={selectedItems}
          show={showRequestDiagnosticsDialog}
          onHide={this.hideRequestDiagnosticsDialog}
          onSuccess={this.successRequestDiagnosticsDialog}
        />
        <RequestScreenshotDialog
          devices={selectedItems}
          show={showRequestScreenshotDialog}
          onHide={this.hideRequestScreenshotDialog}
          onSuccess={this.successRequestScreenshotDialog}
        />
        <RestartPlayerDialog
          devices={selectedItems}
          show={showRestartDialog}
          onHide={this.hideRestartDialog}
          onSuccess={this.successRestartDialog}
        />
        <RebootDeviceDialog
          devices={selectedItems}
          show={showRebootDialog}
          onHide={this.hideRebootDialog}
          onSuccess={this.successRebootDialog}
        />
        <UpgradeDeviceDialog
          devices={selectedItems}
          versions={versions}
          show={showUpgradeDialog}
          onHide={this.hideUpgradeDialog}
          onUpgrade={this.upgrade}
          onUpgradeAll={this.upgradeAll}
        />
        <ClearDataDialog
          devices={selectedItems}
          show={showClearDataDialog}
          onHide={this.hideClearDataDialog}
          onSuccess={this.successClearDataDialog}
        />
        <RegisterDeviceDialog
          show={showRegisterDialog}
          onHide={this.hideRegisterDialog}
          onSuccess={this.register}
        />
        <UnregisterDeviceDialog
          devices={selectedItems}
          show={showUnregisterDialog}
          onHide={this.hideUnregisterDialog}
          onSuccess={this.successUnregisterDialog}
        />
        <DiagnosticsDialog
          auth={auth}
          users={users}
          device={selectedItems[0]}
          show={showDiagnosticsDialog}
          onHide={this.hideDiagnosticsDialog}
          onSuccess={this.hideDiagnosticsDialog}
          onRequestDiagnostics={this.diagnostics}
        />
        <ScreenshotDialog
          auth={auth}
          users={users}
          device={selectedItems[0]}
          show={showScreenshotDialog}
          onHide={this.hideScreenshotDialog}
          onSuccess={this.hideScreenshotDialog}
          onRequestScreenshot={this.screenshot}
        />
      </div>
    );
  }
}

DevicesPage.propTypes = {
  auth: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  users: PropTypes.object.isRequired,
  campaignSchedule: PropTypes.object.isRequired,
  devices: PropTypes.object.isRequired,
  getUsers: PropTypes.func.isRequired,
  getCampaignSchedule: PropTypes.func.isRequired,
  getDevices: PropTypes.func.isRequired,
  getStatus: PropTypes.func.isRequired,
  getVersions: PropTypes.func.isRequired,
  setCopyConfig: PropTypes.func.isRequired,
  select: PropTypes.func.isRequired,
  unselectAll: PropTypes.func.isRequired,
  addNotification: PropTypes.func.isRequired,
  deployContent: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  users: state.users,
  campaignSchedule: state.campaignSchedule,
  devices: state.devices,
});
const mapDispatchToProps = {
  getUsers,
  getCampaignSchedule,
  getDevices,
  getStatus,
  getVersions,
  setCopyConfig,
  select,
  unselectAll,
  addNotification,
  deployContent,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DevicesPage));
