// formatBytes(50000000) => '48MB'
// formatBytes(50000000, 2) => '47.68MB'
export const formatBytes = (bytes, decimals) => {
  if (!bytes || +bytes === 0) {
    return '0';
  }

  const kilobyte = 1024;
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const index = Math.floor(Math.log(bytes) / Math.log(kilobyte));

  return parseFloat((bytes / kilobyte ** index).toFixed(decimals)) + sizes[index];
};

// formatTime(61) => '00:01:01'
// formatTime(61, true) => '1:01'
export const formatTime = (seconds, short) => {
  const time = new Date(seconds * 1000)
    .toISOString()
    .slice(11, 23)
    .replace(/\.000$/, '');

  return short ? time.replace(/^[0:]+/, '') : time;
};

// isoDateToString('2016-02-15T15:47:37Z') => 'February 15 2016'
export const isoDateToString = dateString => {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const date = new Date(dateString);
  const longMonth = monthNames[date.getMonth()];

  return `${longMonth} ${date.getDate()} ${date.getFullYear()}`;
};

export const titleCase = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const isoDateToTime = timestring => {
  const unformattedTime = timestring.replace(/\D/g, '').substring(8);
  const formattedTime = unformattedTime
    .match(/.{1,2}/g)
    .join(':')
    .slice(0, -3);

  return formattedTime;
};
