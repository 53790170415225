import async from 'async';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import pluralize from 'pluralize';
import { bindActionCreators } from 'redux';
import { SOLR_DELAY } from '../../../../constants';
import { getUsers } from '../../../actions/userActions';
import { getSigns } from '../../../actions/signActions';
import { loadCampaign, loadCampaigns } from '../../../actions/campaignActions';
import { loadSequences } from '../../../actions/sequenceActions';
import { loadSchedules } from '../../../actions/scheduleActions';
import { loadPlaylists } from '../../../actions/playlistActions';
import { loadChannels } from '../../../actions/channelActions';
import {
  loadAssignments,
  loadAllAssignments,
  assignmentsSelect,
  assignmentsUnselectAll,
  updateAssignment,
  createAssignment,
  deleteAssignment,
} from '../../../actions/assignmentActions';
import { addNotification } from '../../../actions/notificationActions';
import { overrideDownloadSchedule, deployContent } from '../../../actions/deviceActions';
import Header from '../../../components/Header/Header';
import QuickAction from '../../../components/QuickAction/QuickAction';
import Pagination from '../../Pagination/Pagination';
import AssignmentsTable from '../../../components/Assignments/AssignmentsTable';
import AssignmentSelector from '../../../components/Assignments/AssignmentSelector';
import PaginationInfo from '../../PaginationInfo/PaginationInfo';
import ActionsMenu from '../../../components/ActionsMenu/ActionsMenu';
import FiltersApplied from '../../FiltersApplied/FiltersApplied';
import Filters from '../../Filters/Filters';
import Filter from '../../Filter/Filter';
import Spinner from '../../../components/Spinner/Spinner';
import errorMessage from '../../../utils/errorMessage';
import Confirm from '../../../components/Confirm/Confirm';
import DeployDialog from '../../../components/Devices/DeployDialog';
import { getDeployer } from '../../../utils/deployHelper';
import { getPermissions } from '../../../utils/getPermissions';
import { throttledNotify } from '../../../utils/notificationThrottler';

export class AssignmentsPage extends Component {
  state = {
    selectedContentAssignment: {},
    showChannelSelector: false,
    showSignSelector: false,
    showScheduleSelector: false,
    showSequenceSelector: false,
    showPlaylistSelector: false,
    showCampaignSelector: false,
    selectedAssignments: [],
    selectedAssignmentType: '',
    showDeployDialog: false,
  };

  componentDidMount() {
    this.loadData();
    document.addEventListener('visibilitychange', this.loadData);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      location: { search },
      match: {
        params: { id },
      },
      loadCampaign,
      loadAssignments,
      loadAllAssignments,
    } = this.props;
    const {
      location: { search: nextSearch },
      match: {
        params: { id: nextId },
      },
    } = nextProps;

    if (id !== nextId || search !== nextSearch) {
      this.unselectAll();
      loadCampaign(nextId);
      loadAssignments(this.getQueryWithCampaignId(nextProps));
      loadAllAssignments(`&campaign_id=${nextId}`);
    }
  }

  componentWillUnmount() {
    const { assignmentsUnselectAll } = this.props;

    assignmentsUnselectAll();
    document.removeEventListener('visibilitychange', this.loadData);
  }

  unselectAll() {
    const { assignmentsUnselectAll } = this.props;

    assignmentsUnselectAll();
    this.setState({ selectedAssignments: [] });
  }

  getQueryWithCampaignId = props => {
    const {
      location: { search },
      match: {
        params: { id },
      },
    } = props || this.props;

    return `${search ? `${search}&` : '?'}campaign_id=${id}`;
  };

  loadData = () => {
    if (document.visibilityState === 'hidden') {
      return;
    }

    const {
      match: {
        params: { id },
      },
      loadCampaign,
      loadAssignments,
      loadAllAssignments,
      getUsers,
      getSigns,
      loadSequences,
      loadSchedules,
      loadPlaylists,
      loadCampaigns,
      loadChannels,
    } = this.props;

    loadCampaign(id);
    loadAssignments(this.getQueryWithCampaignId());
    loadAllAssignments(`&campaign_id=${id}`);
    getUsers();
    getSigns('?count=100000000&published=true&isAssignable=true');
    loadSequences('?count=100000000');
    loadSchedules('?count=100000000');
    loadPlaylists('?count=100000000');
    loadCampaigns('?count=100000000');
    loadChannels('?count=100000000');
  };

  getMergedData = () => {
    const {
      assignments: {
        assignments: { assignments },
      },
      sequences: { sequences },
      playlists: { playlists },
    } = this.props;

    if (!sequences || !playlists) {
      return assignments;
    }

    return assignments.map(item => {
      if (item.content_type === 'sequence') {
        const sequence = sequences.find(seq => +seq.id === +item.content_id);

        if (sequence) {
          item.sequenceEntries = sequence.sequenceEntries;
        }
      }

      if (item.content_default_stream_type === 'playlist') {
        const playlist = playlists.find(pl => +pl.id === +item.content_default_stream_id);

        if (playlist) {
          item.playlistEntries = playlist.playlistEntries;
        }
      }

      return item;
    });
  };

  onClickSuccess = type => {
    const { selectedAssignments } = this.state;
    const childDevices = this.getChildDevices();
    const childDevicesHaveContent = childDevices.some(device => this.hasContentAssigned(device));
    const selectedAssignmentsContainLocations = selectedAssignments.some(
      assignment => assignment.type === 'location',
    );

    if (selectedAssignmentsContainLocations && childDevicesHaveContent) {
      this.showAssignmentWarning();
    } else {
      this.saveAssignments();
    }

    this.closeSelector(type);
  };

  onClickAssignmentItem = item => this.setState({ selectedContentAssignment: item });

  onDelete = () => {
    const { selectedAssignments } = this.state;
    const confirm = this.refConfirm;
    const length = selectedAssignments.length;
    const title = `Delete ${pluralize('Assignment', length, true)}`;
    let body = '';
    const someAreLocations = selectedAssignments.some(assignment => assignment.type === 'location');
    const someAreDevices = selectedAssignments.some(assignment => assignment.type === 'device');

    if (someAreLocations && !someAreDevices) {
      body = (
        <div>
          <p>
            Are you sure you want to delete all Assignments for <em>Devices</em> at{' '}
            {pluralize('this', length)} <em>Locations</em>?
          </p>
        </div>
      );
    } else if (someAreLocations && someAreDevices) {
      body = (
        <div>
          <p>
            Are you sure you want to delete all Assignments for the <em>Devices & Locations</em>{' '}
            selected?
          </p>
        </div>
      );
    } else {
      body = (
        <p>
          Are you sure you want to delete the {pluralize('Assignment', length)} for{' '}
          {pluralize('this', length)} <em>{pluralize('Device', length)}</em>?
        </p>
      );
    }

    confirm.show(title, body);
    confirm.onSuccess(this.removeAssignments);
  };

  setSelectedAssignments = selected => {
    const { assignments } = this.props;
    const selectedAssignments = [];

    Object.keys(selected).forEach(key => {
      selectedAssignments.push(assignments.assignments.assignments[key]);
    });

    this.setState({ selectedAssignments: [...selectedAssignments] });
  };

  getChildDevices = () => {
    const {
      assignments: {
        allAssignments: { assignments },
      },
    } = this.props;
    const { selectedAssignments } = this.state;
    let childDevices = [];

    selectedAssignments.forEach(assignment => {
      if (assignment.type === 'location') {
        const parentPath = `${assignment.parent + assignment.name}/`;

        assignments.forEach(item => {
          if (item.type === 'device' && item.parent.startsWith(parentPath)) {
            childDevices.push(item);
          }
        });
      } else {
        childDevices.push(assignment);
      }
    });

    // remove duplications
    childDevices = childDevices.filter(
      (device, index, self) => index === self.findIndex(t => t.device_id === device.device_id),
    );

    return childDevices;
  };

  onCancel = () => {
    this.setState({ selectedAssignments: [], selectedContentAssignment: [] });
  };

  hasContentAssigned = assignment => {
    return assignment.content_type !== 'none' || assignment.content_default_stream_id !== 0;
  };

  showAssignmentWarning = () => {
    const { selectedAssignments } = this.state;
    const childDevices = this.getChildDevices();
    let assignedDevicesCount = 0;
    let locationsCount = 0;
    const someAreLocations = selectedAssignments.some(assignment => assignment.type === 'location');
    const someAreDevices = selectedAssignments.some(assignment => assignment.type === 'device');

    assignedDevicesCount = childDevices.filter(device => this.hasContentAssigned(device)).length;
    locationsCount = selectedAssignments.filter(assignment => assignment.type === 'location')
      .length;
    const confirm = this.refConfirm;
    const title = 'An Assignment already exists';
    let body;

    if (someAreLocations && !someAreDevices) {
      body = (
        <div>
          <p>
            {assignedDevicesCount} <em>{pluralize('Device', assignedDevicesCount)}</em> in the{' '}
            <em>{pluralize('Location', locationsCount)}</em> you have selected already{' '}
            {pluralize('has', assignedDevicesCount)} an Assignment.
          </p>
          <p>Do you wish to proceed?</p>
        </div>
      );
    } else {
      body = (
        <div>
          <p>
            {assignedDevicesCount} selected <em>{pluralize('Device', assignedDevicesCount)}</em>{' '}
            already {pluralize('has', assignedDevicesCount)} an Assignment.
          </p>
          <p>
            The {pluralize('Device', assignedDevicesCount)} may be in the{' '}
            <em>{pluralize('Location', locationsCount)}</em> you have selected.
          </p>
          <p>Do you wish to proceed?</p>
        </div>
      );
    }

    confirm.show(title, body);
    confirm.onSuccess(this.saveAssignments);
    confirm.onCancel(this.onCancel);
  };

  endAssignment = () =>
    this.setState({
      selectedAssignmentType: '',
      selectedAssignments: [],
      selectedContentAssignment: {},
    });

  removeAssignments = () => {
    const { campaign, addNotification } = this.props;
    const { selectedAssignments } = this.state;
    const childDevices = this.getChildDevices();
    let assignments;

    if (childDevices.length > 0) {
      assignments = childDevices;
    } else {
      assignments = selectedAssignments;
    }

    assignments.forEach(assignment => {
      assignment.sign_id = -1;
      assignment.sequence_id = -1;
      assignment.schedule_id = -1;
      assignment.playlist_id = -1;
      assignment.channel_id = -1;

      if (
        assignment.type === 'device' &&
        (assignment.content_type !== 'none' || assignment.content_default_stream_type !== 'none')
      ) {
        return updateAssignment(assignment.device_id, assignment).then(
          () => setTimeout(() => this.performDelete(assignment, campaign.id), SOLR_DELAY),
          err => addNotification({ type: 'danger', text: errorMessage(err) }),
        );
      }

      addNotification({
        type: 'danger',
        text: `There are no Assignments to remove: ${assignment.nameOrDescription}`,
      });
    });
    this.unselectAll();
    this.endAssignment();
  };

  numberAvailable = items => {
    return items ? items.length : 0;
  };

  handleSingleClick = rows => {
    const {
      addNotification,
      assignments: {
        assignments: { assignments },
      },
      assignmentsSelect,
      auth: {
        user: {
          user: { userLocation },
        },
      },
    } = this.props;

    if (userLocation) {
      const restrictedLocations = [];
      const selectableRows = Object.keys(rows).reduce((selectableRows, key) => {
        const { device_id, parent, name } = assignments[key];
        const location = `${parent}${name}/`;

        if (location.startsWith(userLocation)) {
          selectableRows[key] = true;
        } else {
          restrictedLocations.push(device_id ? parent : location);
        }

        return selectableRows;
      }, {});

      if (restrictedLocations.length) {
        throttledNotify(addNotification, {
          type: 'danger',
          text: `Restricted access assigning content to: ${restrictedLocations.join(', ')}`,
        });
      }

      this.setSelectedAssignments(selectableRows);
      assignmentsSelect(selectableRows);
    } else {
      this.setSelectedAssignments(rows);
      assignmentsSelect(rows);
    }
  };

  saveAssignments = assignmentsToSave => {
    const {
      campaign: { id },
      loadAssignments,
      loadAllAssignments,
    } = this.props;
    const childDevices = this.getChildDevices();
    const assignments = assignmentsToSave || childDevices;

    async.eachSeries(
      assignments,
      (assignment, callback) => {
        this.saveAssignment(assignment).then(
          res => callback(null, res),
          () => callback(null, false),
        );
      },
      () => {
        loadAssignments(this.getQueryWithCampaignId());
        loadAllAssignments(`&campaign_id=${id}`);
        this.unselectAll();
        this.endAssignment();
      },
    );
  };

  saveAssignment = assignment => {
    const contentAssignment = this.buildAssignment(assignment);

    if (assignment.content_type !== 'none' || assignment.content_default_stream_id > 0) {
      return this.performUpdate(
        assignment.device_id,
        contentAssignment,
        assignment.nameOrDescription,
      );
    }

    return this.performCreate(contentAssignment, assignment.nameOrDescription);
  };

  buildAssignment(assignment) {
    const {
      campaign: { id },
    } = this.props;
    const { selectedAssignmentType, selectedContentAssignment } = this.state;
    const builtAssignment = {
      device_id: assignment.device_id,
      campaign_id: id,
      sequence_id: -1,
      channel_id: -1,
      playlist_id: -1,
      schedule_id: -1,
      sign_id: -1,
    };

    if (selectedAssignmentType === 'sign') {
      builtAssignment.sign_id = selectedContentAssignment.id;
    }

    if (selectedAssignmentType === 'schedule') {
      builtAssignment.schedule_id = selectedContentAssignment.id;
    }

    if (selectedAssignmentType === 'sequence') {
      builtAssignment.sequence_id = selectedContentAssignment.id;
    }

    if (
      selectedAssignmentType !== 'playlist' &&
      assignment.content_default_stream_id > 0 &&
      assignment.content_default_stream_type === 'playlist'
    ) {
      builtAssignment.playlist_id = assignment.content_default_stream_id;
    }

    if (
      selectedAssignmentType !== 'channel' &&
      assignment.content_default_stream_id > 0 &&
      assignment.content_default_stream_type === 'channel'
    ) {
      builtAssignment.channel_id = assignment.content_default_stream_id;
    }

    if (selectedAssignmentType === 'playlist') {
      if (assignment.content_type === 'sign') {
        builtAssignment.sign_id = assignment.content_id;
      }

      if (assignment.content_type === 'sequence') {
        builtAssignment.sequence_id = assignment.content_id;
      }

      if (assignment.content_type === 'schedule') {
        builtAssignment.schedule_id = assignment.content_id;
      }

      builtAssignment.channel_id = -1;
      builtAssignment.playlist_id = selectedContentAssignment.id;
    }

    if (selectedAssignmentType === 'channel') {
      if (assignment.content_type === 'sign') {
        builtAssignment.sign_id = assignment.content_id;
      }

      if (assignment.content_type === 'sequence') {
        builtAssignment.sequence_id = assignment.content_id;
      }

      if (assignment.content_type === 'schedule') {
        builtAssignment.schedule_id = assignment.content_id;
      }

      builtAssignment.playlist_id = -1;
      builtAssignment.channel_id = selectedContentAssignment.id;
    }

    return builtAssignment;
  }

  closeSelector(type) {
    let close;

    if (type === 'playlist') {
      close = this.setState({ showPlaylistSelector: false });
    }

    if (type === 'sign') {
      close = this.setState({ showSignSelector: false });
    }

    if (type === 'schedule') {
      close = this.setState({ showScheduleSelector: false });
    }

    if (type === 'sequence') {
      close = this.setState({ showSequenceSelector: false });
    }

    if (type === 'channel') {
      close = this.setState({ showChannelSelector: false });
    }

    if (type === 'campaign') {
      close = this.setState({ showCampaignSelector: false });
    }

    return close;
  }

  openSelector(type) {
    let open;

    if (type === 'playlist') {
      open = this.setState({ showPlaylistSelector: true, selectedAssignmentType: type });
    }

    if (type === 'sign') {
      open = this.setState({ showSignSelector: true, selectedAssignmentType: type });
    }

    if (type === 'schedule') {
      open = this.setState({ showScheduleSelector: true, selectedAssignmentType: type });
    }

    if (type === 'sequence') {
      open = this.setState({ showSequenceSelector: true, selectedAssignmentType: type });
    }

    if (type === 'channel') {
      open = this.setState({ showChannelSelector: true, selectedAssignmentType: type });
    }

    if (type === 'campaign') {
      open = this.setState({ showCampaignSelector: true, selectedAssignmentType: type });
    }

    return open;
  }

  changeCampaign() {
    const {
      location: { search },
      history,
      addNotification,
    } = this.props;
    const {
      selectedContentAssignment: { id },
    } = this.state;

    this.closeSelector('campaign');
    history.push(`/campaigns/${id}/assignments${search}`);
    addNotification({ type: 'success', text: 'Campaign has been changed.' });
    this.endAssignment();
  }

  performCreate(contentAssignment, name) {
    const { addNotification } = this.props;

    return createAssignment(contentAssignment).then(
      () => {
        addNotification({ type: 'success', text: `Assignment created for ${name}` });
      },
      err => addNotification({ type: 'danger', text: errorMessage(err) }),
    );
  }

  performUpdate(deviceId, updatedAssignment, name) {
    const { addNotification } = this.props;

    return updateAssignment(deviceId, updatedAssignment).then(
      () => {
        addNotification({ type: 'success', text: `Assignment updated for ${name}` });
      },
      err => addNotification({ type: 'danger', text: errorMessage(err) }),
    );
  }

  performDelete({ device_id, nameOrDescription }, campaignId) {
    const {
      campaign: { id },
      addNotification,
      loadAssignments,
      loadAllAssignments,
    } = this.props;

    return deleteAssignment(device_id, campaignId).then(
      () => {
        addNotification({ type: 'success', text: `Assignment removed: ${nameOrDescription}` });
        loadAssignments(this.getQueryWithCampaignId());
        loadAllAssignments(`&campaign_id=${id}`);
      },
      err => addNotification({ type: 'danger', text: errorMessage(err) }),
    );
  }

  overrideDownloadSchedule = () => {
    const items = this.getChildDevices();

    async.filter(
      items,
      (item, callback) =>
        overrideDownloadSchedule(item.device_id).then(
          res => callback(null, res),
          () => callback(null, false),
        ),
      this.overrideDownloadScheduleCallback,
    );
  };

  overrideDownloadScheduleCallback = (err, results) => {
    const { addNotification } = this.props;
    const items = this.getChildDevices();
    let failedItems;
    let errorMsg = '';

    if (items.length === results.length) {
      addNotification({
        type: 'success',
        text: `${pluralize('Override Download Schedule', items.length, true)} set.`,
      });
    } else {
      // collect the failed request items
      failedItems = items.filter(item => !results.includes(item));
      failedItems.forEach(item => {
        errorMsg += (errorMsg ? ', ' : 'Override Download Schedule failed: ') + item.description;
      });
      addNotification({ type: 'danger', text: errorMsg });
    }
  };

  toggleDeployDialog = () => {
    const { showDeployDialog } = this.state;

    this.setState({ showDeployDialog: !showDeployDialog });
  };

  renderSubheaderActions = () => {
    const {
      auth,
      campaign: { id },
    } = this.props;
    const {
      user: {
        user: { userLocation },
      },
    } = auth;
    const p = getPermissions(auth);

    if (!p.assigner) {
      return [];
    }

    const actions = [];
    const assignArray = [
      { title: 'Assign a Sign', onClick: () => this.openSelector('sign') },
      { title: 'Assign a Sequence', onClick: () => this.openSelector('sequence') },
      { title: 'Assign a Schedule', onClick: () => this.openSelector('schedule') },
      { divider: true },
      { title: 'Assign a Playlist', onClick: () => this.openSelector('playlist') },
      p.atLeastTierStandard && {
        title: 'Assign a Channel',
        onClick: () => this.openSelector('channel'),
      },
    ].filter(Boolean);
    const assignContent = (
      <QuickAction
        key="1"
        id="AssignContent"
        toolText="Assign Content"
        onClick={assignArray}
        singular
        multiple
      >
        add_circle
      </QuickAction>
    );
    const removeAssignment = (
      <QuickAction key="2" toolText="Remove Assignment" onClick={this.onDelete} singular multiple>
        delete
      </QuickAction>
    );
    const override = p.atLeastTierStandardOrSuperUser && p.downloadSchedule && (
      <QuickAction
        key="3"
        toolText="Override Download Schedule"
        onClick={this.overrideDownloadSchedule}
        singular
        multiple
      >
        access_time
      </QuickAction>
    );
    const deploy = (
      <QuickAction
        key="4"
        toolText="Deploy Content"
        onClick={this.toggleDeployDialog}
        singular
        multiple
      >
        cloud_download
      </QuickAction>
    );
    const changeCampaign = p.atLeastTierStandard && (
      <QuickAction
        key="5"
        toolText="Change Campaign"
        onClick={() => this.openSelector('campaign')}
        alwaysShow
      >
        <i className="icon fa fa-bullhorn" />
      </QuickAction>
    );

    actions.push(assignContent, override, deploy, removeAssignment, changeCampaign);

    if (userLocation) {
      const homeUrl = `/campaigns/${id}/assignments${`?parent=${encodeURIComponent(
        `${userLocation}`,
      )}`}`;
      const homeShortcut = (
        <QuickAction key="home" toolText="Assignment Home" href={homeUrl} alwaysShow>
          home
        </QuickAction>
      );

      actions.push(homeShortcut);
    }

    return actions.filter(Boolean);
  };

  render() {
    const {
      assignments: {
        assignments: { numFound, facet_fields },
        allAssignments: { assignments },
        loadingAllAssignments,
        selected,
      },
      auth,
      users,
      campaign,
      signs: { signs: allSigns },
      sequences: { sequences },
      schedules: { schedules },
      playlists: { playlists },
      channels: { channels },
      campaigns: { campaigns },
      deployContent,
    } = this.props;
    const {
      user: { user, downloadSchedule },
    } = auth;
    const {
      showDeployDialog,
      showSignSelector,
      showScheduleSelector,
      showSequenceSelector,
      showPlaylistSelector,
      showChannelSelector,
      showCampaignSelector,
      selectedContentAssignment,
      selectedAssignments,
    } = this.state;
    const p = getPermissions(auth);
    const userLocation = user.userLocation || '';
    const signs = p.atLeastTierStandard
      ? allSigns
      : allSigns.filter(sign => sign.name.startsWith('video'));

    return (
      <div className="page-structure">
        <Helmet title="Content Assignments" />
        <Header overwrite={{ id: campaign }} auth={auth} />
        <ActionsMenu
          actions={this.renderSubheaderActions()}
          selectedLength={selectedAssignments.length}
          filterButton
        />
        <div className="page-panels">
          <div className="toolbar">
            <FiltersApplied users={users} />
            <PaginationInfo itemsFound={numFound} />
          </div>
          <Filters>
            <Filter
              type="text"
              name="search"
              title="Search"
              placeholder="Search content assignments"
            />
            <Filter type="preset" preset="depth" name="folderDepth" title="Include subfolders" />
            <Filter type="checkbox" name="type" title="Type" data={facet_fields.type} />
            <Filter
              type="checkbox"
              name="content_type"
              title="Content Type"
              data={facet_fields.content_type}
            />
            <Filter type="tag" name="tags" title="Tags" data={facet_fields.tags} />
          </Filters>
          <div className="page-content page-assignments">
            <Spinner className="assignments-loader" loading={loadingAllAssignments}>
              <AssignmentsTable
                auth={auth}
                data={this.getMergedData()}
                allData={assignments}
                selected={selected}
                onClick={this.handleSingleClick}
                campaign={campaign}
                signs={signs}
                userLocation={userLocation}
              />
            </Spinner>
            <Pagination itemsFound={numFound} />
            {signs && (
              <AssignmentSelector
                show={showSignSelector}
                onHide={() => this.closeSelector('sign')}
                type="sign"
                data={signs}
                onClick={this.onClickAssignmentItem}
                selectedAssignment={selectedContentAssignment}
                onSuccess={() => this.onClickSuccess('sign')}
              />
            )}
            {schedules && (
              <AssignmentSelector
                show={showScheduleSelector}
                onHide={() => this.closeSelector('schedule')}
                type="schedule"
                data={schedules}
                onClick={this.onClickAssignmentItem}
                selectedAssignment={selectedContentAssignment}
                onSuccess={() => this.onClickSuccess('schedule')}
              />
            )}
            {sequences && (
              <AssignmentSelector
                show={showSequenceSelector}
                onHide={() => this.closeSelector('sequence')}
                type="sequence"
                signs={signs}
                data={sequences}
                onClick={this.onClickAssignmentItem}
                selectedAssignment={selectedContentAssignment}
                onSuccess={() => this.onClickSuccess('sequence')}
              />
            )}
            {playlists && (
              <AssignmentSelector
                show={showPlaylistSelector}
                onHide={() => this.closeSelector('playlist')}
                type="playlist"
                data={playlists}
                onClick={this.onClickAssignmentItem}
                selectedAssignment={selectedContentAssignment}
                onSuccess={() => this.onClickSuccess('playlist')}
              />
            )}
            {channels && (
              <AssignmentSelector
                show={showChannelSelector}
                onHide={() => this.closeSelector('channel')}
                type="channel"
                data={channels}
                onClick={this.onClickAssignmentItem}
                selectedAssignment={selectedContentAssignment}
                onSuccess={() => this.onClickSuccess('channel')}
              />
            )}
            {campaigns && (
              <AssignmentSelector
                show={showCampaignSelector}
                onHide={() => this.closeSelector('campaign')}
                type="campaign"
                data={campaigns}
                onClick={this.onClickAssignmentItem}
                selectedAssignment={selectedContentAssignment}
                onSuccess={() => this.changeCampaign()}
              />
            )}
            <Confirm
              ref={ref => {
                this.refConfirm = ref;
              }}
            />
            <DeployDialog
              show={showDeployDialog}
              onHide={() => this.toggleDeployDialog()}
              deployer={getDeployer(deployContent)}
              downloadSchedule={downloadSchedule}
              auth={auth}
              devices={this.getChildDevices()}
              assignments={selectedAssignments}
              allAssignments={assignments}
            />
          </div>
        </div>
      </div>
    );
  }
}

AssignmentsPage.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  loadCampaign: PropTypes.func.isRequired,
  loadCampaigns: PropTypes.func.isRequired,
  loadAssignments: PropTypes.func.isRequired,
  assignmentsSelect: PropTypes.func.isRequired,
  assignmentsUnselectAll: PropTypes.func.isRequired,
  assignments: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  getUsers: PropTypes.func.isRequired,
  users: PropTypes.array,
  campaign: PropTypes.object,
  campaigns: PropTypes.object,
  getSigns: PropTypes.func.isRequired,
  signs: PropTypes.object,
  loadSequences: PropTypes.func.isRequired,
  sequences: PropTypes.object,
  loadSchedules: PropTypes.func.isRequired,
  schedules: PropTypes.object,
  addNotification: PropTypes.func.isRequired,
  loadPlaylists: PropTypes.func.isRequired,
  playlists: PropTypes.object,
  loadAllAssignments: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  loadChannels: PropTypes.func.isRequired,
  channels: PropTypes.object,
  deployContent: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    assignments: state.assignments,
    allAssignments: state.allAssignments,
    campaigns: state.campaigns.campaigns,
    campaign: state.campaigns.campaign,
    users: state.users.users.users,
    auth: state.auth,
    signs: state.signs.signs,
    sequences: state.sequences.sequences,
    schedules: state.schedules.schedules,
    playlists: state.playlists.playlists,
    channels: state.channels,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getUsers,
      getSigns,
      loadCampaign,
      loadChannels,
      loadCampaigns,
      loadAssignments,
      loadAllAssignments,
      loadSequences,
      loadSchedules,
      loadPlaylists,
      assignmentsSelect,
      assignmentsUnselectAll,
      addNotification,
      deployContent,
    },
    dispatch,
  );
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AssignmentsPage));
