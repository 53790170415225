import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Panel } from 'react-bootstrap';
import SSTable from '../SSTable/SSTable';
import SSColumn from '../SSTable/SSColumn';
import ToolTipIcon from '../ToolTipIcon/ToolTipIcon';
import AssetImage from '../Assets/AssetImage';
import { getPermissions } from '../../utils/getPermissions';
import { launchCreator } from '../../utils/launchCreator';
import { buildTagList } from '../../utils/tagList';

export default class DeviceAssignments extends Component {
  getCampaign = item => {
    const {
      device: { name },
    } = this.props;

    if (!item.campaign) {
      return null;
    }

    const url = `/campaigns/${item.campaign_id}/assignments?parent=/*&search=${name}`;

    return (
      <div className="item-content">
        <div className="item-name">
          <div>
            <Link to={url}>{item.campaign.name}</Link>
          </div>
          <div className="item-tags">Folder: {item.campaign.parent}</div>
          <div className="item-tags">Tags: {buildTagList(item.campaign)}</div>
        </div>
      </div>
    );
  };

  getContent = item => {
    const { auth, signs } = this.props;
    const content = item.sign || item.sequence || item.schedule;
    const p = getPermissions(auth);

    if (!content) {
      return null;
    }

    return (
      <div className="item-content">
        <div>
          <AssetImage id={item.content_id} type={item.content_type} item={content} signs={signs} />
        </div>
        <div className="item-name">
          <div className="item-tags">Type: {item.content_type}</div>
          <div>
            {['sign', 'sequence', 'schedule'].includes(item.content_type) && (
              <ToolTipIcon
                toolText={`Preview ${item.content_name}`}
                href={`/player/?${item.content_type}=${item.content_id}`}
                target="_blank"
              >
                play_circle_filled
              </ToolTipIcon>
            )}
            {p.atleastTierStandard && item.content_type === 'sign' && (
              <ToolTipIcon
                toolText={`Open ${item.content_name} in Creator`}
                onClick={() => launchCreator(item.content_id)}
              >
                <i className="fa fa-paint-brush" />
              </ToolTipIcon>
            )}
          </div>
        </div>
        {content && (
          <div className="item-name">
            <div>
              {!p.atLeastTierStandard && item.content_type === 'sign' ? (
                item.content_name
              ) : (
                <Link to={`/${item.content_type}s/${item.content_id}`}>{item.content_name}</Link>
              )}
            </div>
            <div className="item-tags">Folder: {content.parent}</div>
            <div className="item-tags">Tags: {buildTagList(content)}</div>
          </div>
        )}
      </div>
    );
  };

  getVideo = item => {
    const content = item.playlist || item.channel;

    if (!content) {
      return null;
    }

    return (
      <div className="item-content">
        <div>
          <AssetImage
            id={item.content_default_stream_id}
            type={item.content_default_stream_type}
            item={content}
          />
        </div>
        <div className="item-name">
          <div className="item-tags">Type: {item.content_default_stream_type}</div>
        </div>
        {content && item.playlist && (
          <div className="item-name">
            <div>
              <Link to={`/playlists/${content.id}`}>{content.name}</Link>
            </div>
            <div className="item-tags">Folder: {content.parent}</div>
            <div className="item-tags">Tags: {buildTagList(content)}</div>
          </div>
        )}
        {content && item.channel && content.name}
      </div>
    );
  };

  getPreview = item => {
    const {
      device: { id, name },
    } = this.props;

    if (!item.campaign) {
      return null;
    }

    return (
      <ToolTipIcon
        toolText={`Preview campaign for device: ${name}`}
        href={`/player/?device=${id}&campaign=${item.campaign.id}`}
        target="_blank"
      >
        play_circle_filled
      </ToolTipIcon>
    );
  };

  render() {
    const { data } = this.props;

    if (!data) {
      return null;
    }

    return (
      <Panel data-test="panel-device-assignments" className="device-assignments">
        <Panel.Heading>Assignments</Panel.Heading>
        <Panel.Body>
          <SSTable data={data} rowHeight={50} rowsCount={data.length - 1} zebra>
            <SSColumn header="Campaign" cell={this.getCampaign} />
            <SSColumn header="Content" cell={this.getContent} />
            <SSColumn header="Video" cell={this.getVideo} />
            <SSColumn header="Device Preview" cell={this.getPreview} />
          </SSTable>
        </Panel.Body>
      </Panel>
    );
  }
}

DeviceAssignments.propTypes = {
  auth: PropTypes.object.isRequired,
  device: PropTypes.object.isRequired,
  data: PropTypes.array,
  signs: PropTypes.array,
};
