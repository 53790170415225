import clone from 'clone';
import { add, format } from 'date-fns';
import * as types from '../actions/actionTypes';

export const initialState = {
  loadingAsset: null,
  loadingAssets: null,
  error: null,
  asset: {},
  assetEdit: {},
  assets: {
    assets: [],
    facet_fields: {},
    stats_fields: {},
  },
  uploading: [],
  encodeTypes: [],
  encodingQueue: [],
  assetSelector: {
    path: '',
    assets: [],
    loading: null,
  },
};

export default function assets(state = initialState, action = {}) {
  let uploading;
  let encodingQueue;
  let pos;

  switch (action.type) {
    // assets
    case types.ASSETS_REQUEST:
      return {
        ...state,
        loadingAssets: true,
        error: null,
      };
    case types.ASSETS_REQUEST_SUCCESS:
      return {
        ...state,
        loadingAssets: false,
        assets: action.result,
      };
    case types.ASSETS_REQUEST_FAILED:
      return {
        ...state,
        loadingAssets: false,
        error: action.error,
      };
    // asset
    case types.ASSET_REQUEST:
      return {
        ...state,
        loadingAsset: true,
        error: null,
      };
    case types.ASSET_REQUEST_SUCCESS:
      return {
        ...state,
        loadingAsset: false,
        asset: action.result,
        assetEdit: clone(action.result), // cannot reference to the original
      };
    case types.ASSET_REQUEST_FAILED:
      return {
        ...state,
        loadingAsset: false,
        error: action.error,
      };
    case types.ASSETS_ENCODE_TYPES_SUCCESS:
      return {
        ...state,
        encodeTypes: action.result,
      };
    // file upload
    case types.UPLOAD_QUEUE_ADD_ITEMS:
      return {
        ...state,
        uploading: [...state.uploading, ...action.files],
      };
    case types.VALIDATE_START:
      uploading = [...state.uploading];
      pos = uploading.map(file => file.id).indexOf(action.file.id);

      if (pos > -1) {
        uploading[pos] = { ...uploading[pos], ...action.file, validation: null };
      }

      return {
        ...state,
        uploading,
      };
    case types.VALIDATE_FAILED:
      uploading = [...state.uploading];
      pos = uploading.map(file => file.id).indexOf(action.file.id);

      if (pos > -1) {
        uploading[pos] = { ...uploading[pos], ...action.file, validation: action.res, error: true };
      }

      return {
        ...state,
        uploading,
      };
    case types.VALIDATE_COMPLETE:
      uploading = [...state.uploading];
      pos = uploading.map(file => file.id).indexOf(action.file.id);

      if (pos > -1) {
        uploading[pos] = { ...uploading[pos], ...action.file, validation: false };
      }

      return {
        ...state,
        uploading,
      };
    case types.UPLOAD_START:
      action.file.error = false;
      uploading = [...state.uploading];
      pos = uploading.map(file => file.id).indexOf(action.file.id);

      if (pos > -1) {
        uploading[pos] = { ...uploading[pos], ...action.file };
      }

      return {
        ...state,
        uploading,
      };
    case types.UPLOAD_PROGRESS:
      uploading = [...state.uploading];
      pos = uploading.map(file => file.id).indexOf(action.file.id);

      if (pos > -1) {
        uploading[pos] = { ...uploading[pos], ...action.file };
      }

      return {
        ...state,
        uploading,
      };
    case types.UPLOAD_COMPLETE:
      uploading = [...state.uploading];
      pos = uploading.map(file => file.id).indexOf(action.id);
      uploading[pos] = { ...uploading[pos], asset: action.asset };

      return {
        ...state,
        uploading,
      };
    case types.UPLOAD_FAILED:
      uploading = [...state.uploading];
      pos = uploading.map(file => file.id).indexOf(action.id);

      if (pos > -1) {
        uploading[pos] = { ...uploading[pos] };
        uploading[pos].error = true;
        uploading[pos].validation = action.res;
      }

      return {
        ...state,
        uploading,
      };
    case types.UPLOAD_REMOVE_ITEM:
      uploading = state.uploading.filter(file => action.id !== file.id);

      return {
        ...state,
        uploading,
      };
    case types.UPLOAD_ENCODE_QUEUE_ADD_ITEM:
      encodingQueue = [...state.encodingQueue];
      pos = encodingQueue.map(file => file.uploadId).indexOf(action.id);

      if (pos === -1) {
        encodingQueue.push({ uploadId: action.id, ...action.queueItem });
      }

      return {
        ...state,
        encodingQueue,
      };
    case types.UPLOAD_ENCODE_QUEUE_UPDATE:
      // First Strip non-existent entries from the Queue.
      encodingQueue = state.encodingQueue.filter(queueEntry => {
        return action.queue.find(findCandidate => {
          return findCandidate.id === queueEntry.id;
        });
      });

      // Now update existing entries.
      action.queue.forEach(newFile => {
        pos = encodingQueue.map(file => file.id).indexOf(newFile.id);

        if (pos > -1) {
          encodingQueue[pos] = { ...encodingQueue[pos], ...newFile }; // keep the uploadId
        }
      });

      return {
        ...state,
        encodingQueue,
      };
    case types.ASSET_FORM_EDIT:
      if (
        action.key === 'validity_enabled' &&
        action.value === true &&
        !state.assetEdit.validity_enabled &&
        state.assetEdit.startDate === '20100101' &&
        state.assetEdit.endDate === '20991231'
      ) {
        return {
          ...state,
          assetEdit: {
            ...state.assetEdit,
            validity_enabled: true,
            startDate: format(new Date(), 'yyyyMMdd'),
            endDate: format(add(new Date(), { months: 3 }), 'yyyyMMdd'),
          },
        };
      }

      return {
        ...state,
        assetEdit: {
          ...state.assetEdit,
          [action.key]: action.value,
        },
      };
    case types.ASSET_FORM_CLEAR:
      return {
        ...state,
        asset: {},
        assetEdit: {},
      };
    case types.ASSET_SELECTOR_LOADING:
      return {
        ...state,
        assetSelector: {
          ...state.assetSelector,
          loading: true,
        },
      };
    case types.ASSET_SELECTOR_LOADED:
      return {
        ...state,
        assetSelector: {
          ...state.assetSelector,
          path: action.path,
          assets: action.result,
          loading: false,
        },
      };
    default:
      return state;
  }
}
