import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { PieChart } from 'react-minimal-pie-chart';
import {
  Panel,
  Grid,
  Row,
  Col,
  Clearfix,
  Popover,
  OverlayTrigger,
  Checkbox,
} from 'react-bootstrap';
import { sub } from 'date-fns';
import { getUsers } from '../../../actions/userActions';
import { getAllDevices, getStatus } from '../../../actions/deviceActions';
import { addNotification } from '../../../actions/notificationActions';
import Header from '../../../components/Header/Header';
import ActionsMenu from '../../../components/ActionsMenu/ActionsMenu';
import QuickAction from '../../../components/QuickAction/QuickAction';
import { getHeartbeat, getHeartbeatPercent } from '../../../components/Devices/DeviceUtils';
import DeviceUptimeDiagram from '../../../components/Devices/DeviceUptimeDiagram';
import Spinner from '../../../components/Spinner/Spinner';
import ToolTip from '../../../components/ToolTipIcon/ToolTip';
import DashboardTable from '../../../components/Dashboard/DashboardTable';
import { isQueryParam, addQueryParam, removeQueryParam } from '../../../utils/updateQueryString';
import errorMessage from '../../../utils/errorMessage';
import { getPermissions } from '../../../utils/getPermissions';

export class DashboardPage extends Component {
  getDefaultStatuses = () => ({
    onlineStatus: { online: 0, warning: 0, offline: 0 },
    contentStatus: { current: 0, downloading: 0, pending: 0, outdated: 0 },
  });

  state = {
    pollInterval: 120000,
    poll: null,
    devices: [],
    statuses: this.getDefaultStatuses(),
  };

  componentDidMount() {
    this.loadData();
    this.mergeDevicesStatus(this.props);
    document.addEventListener('visibilitychange', this.loadData);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      location: { search },
    } = this.props;

    this.showFetchError(nextProps);
    this.mergeDevicesStatus(nextProps);

    if (search !== nextProps.location.search) {
      this.getDevicesAndStatus(nextProps);
    }
  }

  componentWillUnmount() {
    const { poll } = this.state;

    clearInterval(poll);
    document.removeEventListener('visibilitychange', this.loadData);
  }

  loadData = () => {
    const { getUsers } = this.props;
    const { poll } = this.state;

    if (document.visibilityState === 'hidden') {
      clearInterval(poll);

      return;
    }

    this.poll();
    getUsers();
  };

  showFetchError = nextProps => {
    const {
      devices: { error },
      addNotification,
    } = this.props;
    const {
      devices: { error: nextError },
    } = nextProps;

    if (!error && nextError) {
      addNotification({ type: 'danger', text: errorMessage(nextError) });
    }
  };

  // some props are missing in old devices
  getFixedDevices = devices =>
    devices.map(device => ({
      ...device,
      description: device.description || '',
      heartbeat: device.heartbeat || Array(24).fill(0),
      createdDate: device.createdDate || new Date(0),
      modifiedDate: device.modifiedDate || new Date(0),
    }));

  mergeDevicesStatus = nextProps => {
    const {
      devices: {
        allDevices: { devices },
        status: { statuses },
      },
    } = nextProps;
    const mergedDevices = [];

    if (!devices) {
      return;
    }

    const newStatuses = this.getDefaultStatuses();

    this.getFixedDevices(devices).forEach(device => {
      const status = (statuses || []).find(item => device.id === item.id);
      const mergedDevice = { ...device, ...status };

      mergedDevice.heartbeatTotal = getHeartbeat(mergedDevice);
      mergedDevice.heartbeatPercent = getHeartbeatPercent(mergedDevice);
      mergedDevices.push(mergedDevice);

      // facet_fields[online_status|download_status] cannot be used because it includes the excluded devices' statuses
      // need to count the online/download statuses for the included device
      newStatuses.onlineStatus[mergedDevice.online_status] += 1;
      newStatuses.contentStatus[mergedDevice.download_status] += 1;
    });
    this.setState({ devices: mergedDevices, statuses: newStatuses });
  };

  getDevicesAndStatus = (props = this.props) => {
    const {
      location: { search },
      getAllDevices,
      getStatus,
    } = props;

    getAllDevices(search);
    getStatus();
  };

  poll = () => {
    const { pollInterval } = this.state;

    this.getDevicesAndStatus();
    this.setState({ poll: setInterval(this.getDevicesAndStatus, pollInterval) });
  };

  onDownload = () => {
    const { auth, location } = this.props;
    const active = isQueryParam(location, 'prestaged', '0');
    const prestaged = isQueryParam(location, 'prestaged', '1');
    let prestageStatus;

    if (active && !prestaged) {
      prestageStatus = 'active';
    } else if (!active && prestaged) {
      prestageStatus = 'prestaged';
    } else {
      // both checked or both unchecked
      prestageStatus = 'all';
    }

    const date = new Date().toLocaleString('hu').replaceAll(' ', '').replaceAll(/[.:]/g, '-');
    const downloadLink = document.createElement('a');

    downloadLink.href = `/cms/data/v1/dashboard/devicesreport${location.search}`;
    downloadLink.download = `SignStix report - ${auth.user.client.name} - ${prestageStatus} devices - ${date}.csv`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);

    return downloadLink;
  };

  onChangePrestaged = () => {
    const { history, location } = this.props;
    const checked = isQueryParam(location, 'prestaged', '1');

    if (checked) {
      removeQueryParam(history, location, 'prestaged', '1');
    } else {
      addQueryParam(history, location, 'prestaged', '1');
    }
  };

  onChangeactive = () => {
    const { history, location } = this.props;
    const checked = isQueryParam(location, 'prestaged', '0');

    if (checked) {
      removeQueryParam(history, location, 'prestaged', '0');
    } else {
      addQueryParam(history, location, 'prestaged', '0');
    }
  };

  getPieChartLabel = ({ dataEntry }) =>
    dataEntry.value ? `${dataEntry.value} ${dataEntry.title}` : '';

  get24hOnlinePieChartLabel = ({ dataEntry }) =>
    dataEntry.value ? `${dataEntry.value}% ${dataEntry.title}` : '';

  getOnlineStatusPieChart = () => {
    const {
      statuses: {
        onlineStatus: { online, warning, offline },
      },
    } = this.state;

    return (
      <PieChart
        radius={40}
        labelPosition={114}
        labelStyle={{ color: '#424242', fontSize: '10px' }}
        label={this.getPieChartLabel}
        data={[
          {
            title: 'online',
            color: '#4caf50',
            value: online,
          },
          {
            title: 'warning',
            color: '#ffc107',
            value: warning,
          },
          {
            title: 'offline',
            color: '#f44336',
            value: offline,
          },
        ]}
      />
    );
  };

  get24hOnlineStatusPieChart = () => {
    const { online, offline } = this.getAverageHeartbeatPercent();

    return (
      <PieChart
        radius={40}
        labelPosition={114}
        labelStyle={{ color: '#424242', fontSize: '10px' }}
        label={this.get24hOnlinePieChartLabel}
        data={[
          {
            title: 'online',
            color: '#4caf50',
            value: online,
          },
          {
            title: 'offline',
            color: '#f44336',
            value: offline,
          },
        ]}
      />
    );
  };

  getContentStatusPieChart = () => {
    const {
      statuses: {
        contentStatus: { current, downloading, pending, outdated },
      },
    } = this.state;

    return (
      <PieChart
        radius={40}
        labelPosition={114}
        labelStyle={{ color: '#424242', fontSize: '10px' }}
        label={this.getPieChartLabel}
        data={[
          {
            title: 'current',
            color: '#4caf50',
            value: current,
          },
          {
            title: 'downloading',
            color: '#a6d7a8',
            value: downloading,
          },
          {
            title: 'pending',
            color: '#ffc107',
            value: pending,
          },
          {
            title: 'outdated',
            color: '#f44336',
            value: outdated,
          },
        ]}
      />
    );
  };

  getAverageHeartbeatPercent = () => {
    const devices = this.getDevicesOlderThan24h();
    let online = 0;
    let offline = 0;

    if (devices.length) {
      const heartbeatMax = devices.length * 86400; // 24 * 60 * 60
      const heartbeatTotal = devices
        .map(item => item.heartbeatTotal)
        .reduce((sum, item) => sum + item);

      online = Math.min((heartbeatTotal / heartbeatMax) * 100, 100);
      offline = 100 - online;

      online = Number(online.toFixed(2));
      offline = Number(offline.toFixed(2));
    }

    return { online, offline };
  };

  getAverageHeartbeatPerHour = () => {
    const { devices } = this.state;
    const heartbeatAverage = Array(24).fill(0);

    devices.forEach(device => {
      heartbeatAverage.forEach((item, idx) => {
        heartbeatAverage[idx] += device.heartbeat[idx];
      });
    });

    return heartbeatAverage.map(item => item / devices.length);
  };

  getDevicesOlderThan24h = () => {
    const yesterday = sub(new Date(), { days: 1 });

    return this.state.devices.filter(item => yesterday > new Date(item.createdDate));
  };

  getOfflineDevices = () =>
    [...this.state.devices]
      .filter(item => ['offline', 'warning'].includes(item.online_status))
      .sort((a, b) => (a.idle < b.idle ? 1 : -1));

  getPrestageStatusCheckboxes = () => {
    const {
      location,
      devices: {
        allDevices: { facet_fields },
      },
    } = this.props;

    if (!facet_fields?.prestaged) {
      return;
    }

    const active = isQueryParam(location, 'prestaged', '0');
    const prestaged = isQueryParam(location, 'prestaged', '1');
    // prestaged: ["0", 10, "1", 2] // 10 active, 2 prestaged device
    const activeCount = facet_fields.prestaged[1] || 0;
    const prestagedCount = facet_fields.prestaged[3] || 0;

    return (
      <span>
        <Checkbox
          data-test="checkbox-prestaged-active"
          checked={active}
          onChange={this.onChangeactive}
        >
          active <span className="total">{activeCount}</span>
        </Checkbox>
        <Checkbox
          data-test="checkbox-prestaged-prestaged"
          checked={prestaged}
          onChange={this.onChangePrestaged}
        >
          prestaged <span className="total">{prestagedCount}</span>
        </Checkbox>
      </span>
    );
  };

  getModifiedDevices = () =>
    [...this.state.devices].sort((a, b) =>
      new Date(a.modifiedDate) < new Date(b.modifiedDate) ? 1 : -1,
    );

  getRegisteredDevices = () =>
    [...this.state.devices].sort((a, b) =>
      new Date(a.createdDate) < new Date(b.createdDate) ? 1 : -1,
    );

  getDevicesWithOutdatedContent = () =>
    [...this.state.devices]
      .filter(item => item.download_status === 'outdated')
      .sort((a, b) => (a.content_ver < b.content_ver ? 1 : -1));

  getDevicesWithNewContent = () =>
    [...this.state.devices]
      .filter(item => item.download_status === 'current')
      .sort((a, b) => (a.content_ver < b.content_ver ? 1 : -1));

  getDevicesWithDownloadingContent = () =>
    [...this.state.devices]
      .filter(item => ['downloading', 'pending'].includes(item.download_status))
      .sort((a, b) => (a.downloading_ver < b.downloading_ver ? 1 : -1));

  getHeaderActions = () => {
    const { auth } = this.props;
    const p = getPermissions(auth);
    const download = p.atLeastTierStandardOrSuperUser && (
      <QuickAction key="1" toolText="Download Devices Report" onClick={this.onDownload} alwaysShow>
        file_download
      </QuickAction>
    );

    return [download].filter(Boolean);
  };

  render() {
    const {
      auth,
      users: { users },
      location,
    } = this.props;
    const {
      devices,
      statuses: {
        onlineStatus: { online, warning, offline },
        contentStatus: { current, downloading, pending, outdated },
      },
    } = this.state;
    const oldDevicesTotal = this.getDevicesOlderThan24h().length;
    const newDevicesTotal = devices.length - oldDevicesTotal;
    const averageHeartbeat = this.getAverageHeartbeatPercent();
    const active = isQueryParam(location, 'prestaged', '0') ? 'prestaged=0' : '';
    const prestaged = isQueryParam(location, 'prestaged', '1') ? 'prestaged=1' : '';
    let search = [active, prestaged].filter(Boolean).join('&');
    const p = getPermissions(auth);

    search = `/devices?${search ? `${search}&` : ''}`;

    return (
      <div className="page-structure">
        <Helmet title="Dashboard" />
        <Header />
        <ActionsMenu actions={this.getHeaderActions()} />
        <div className="page-panels">
          <div className="page-content page-dashboard">
            <Spinner className="content-items-loader" loading={!devices.length}>
              <div className="margin">
                <Panel className="panel-piechart">
                  <Panel.Heading>
                    <div>
                      <span>
                        <i className="material-icons material-icons">devices</i>Summary
                      </span>
                      {this.getPrestageStatusCheckboxes()}
                    </div>
                  </Panel.Heading>
                  <Panel.Body>
                    {Boolean(this.getRegisteredDevices().length) && (
                      <Grid fluid>
                        <Row>
                          <Col lg={3} md={6}>
                            <ToolTip
                              toolText={
                                <span>
                                  Current online status of {devices.length} devices.
                                  <br />
                                  Online: {online}, Warning: {warning}, Offline: {offline}
                                </span>
                              }
                            >
                              <h4>Online status</h4>
                            </ToolTip>
                            <div className="pie-container">{this.getOnlineStatusPieChart()}</div>
                          </Col>
                          <Col lg={3} md={6}>
                            <ToolTip
                              toolText={
                                <span>
                                  24 hours online status of {oldDevicesTotal} devices.
                                  <br />
                                  New devices ({newDevicesTotal}) that registered in the last 24
                                  hours are excluded from this piechart.
                                </span>
                              }
                            >
                              <h4>24 hours online status</h4>
                            </ToolTip>
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Popover
                                  id="popover"
                                  className="popover-uptime-diagram"
                                  title={`${oldDevicesTotal} devices had ${averageHeartbeat.online}% uptime in the last 24 hours.`}
                                >
                                  <DeviceUptimeDiagram
                                    device={{ heartbeat: this.getAverageHeartbeatPerHour() }}
                                    height={150}
                                  />
                                </Popover>
                              }
                            >
                              <div className="pie-container">
                                {this.get24hOnlineStatusPieChart()}
                              </div>
                            </OverlayTrigger>
                          </Col>
                          <Col lg={3} md={6}>
                            <ToolTip
                              toolText={
                                <span>
                                  Deployed content status of {devices.length} devices.
                                  <br />
                                  Current: {current}, Downloading: {downloading}, Pending: {pending}
                                  , Outdated: {outdated}
                                </span>
                              }
                            >
                              <h4>Content status</h4>
                            </ToolTip>
                            <div className="pie-container">{this.getContentStatusPieChart()}</div>
                          </Col>
                          <Col lg={3} md={6}>
                            <ul>
                              <li>
                                <ToolTip toolText="Total devices.">
                                  Devices: <Link to={search}>{devices.length}</Link>
                                </ToolTip>
                              </li>
                              <li>
                                <ToolTip toolText="Devices registered in the last 24 hours.">
                                  New:{' '}
                                  <Link to={`${search}createdDate=[NOW-1DAY TO NOW]`}>
                                    {newDevicesTotal}
                                  </Link>
                                </ToolTip>
                              </li>
                              <li>
                                <hr />
                              </li>
                              <li>
                                <ToolTip toolText="Devices that have polled the server in the last 3 minutes.">
                                  <i className="material-icons material-icons online">lens</i>
                                  Online: <Link to={`${search}online_status=online`}>{online}</Link>
                                </ToolTip>
                              </li>
                              <li>
                                <ToolTip toolText="Devices that have been offline for 3 minutes.">
                                  <i className="material-icons material-icons warning">lens</i>
                                  Warning:{' '}
                                  <Link to={`${search}online_status=warning`}>{warning}</Link>
                                </ToolTip>
                              </li>
                              <li>
                                <ToolTip toolText="Devices that have been offline for 20 minutes.">
                                  <i className="material-icons material-icons offline">lens</i>
                                  Offline:{' '}
                                  <Link to={`${search}online_status=offline`}>{offline}</Link>
                                </ToolTip>
                              </li>
                              <li>
                                <hr />
                              </li>
                              <li>
                                <ToolTip toolText="Devices with the latest content.">
                                  <i className="material-icons current">cloud_download</i>
                                  Current:{' '}
                                  <Link to={`${search}download_status=current`}>{current}</Link>
                                </ToolTip>
                              </li>
                              <li>
                                <ToolTip toolText="Devices downloading the latest content.">
                                  <i className="material-icons downloading">cloud_download</i>
                                  Downloading:{' '}
                                  <Link to={`${search}download_status=downloading`}>
                                    {downloading}
                                  </Link>
                                </ToolTip>
                              </li>
                              <li>
                                <ToolTip toolText="Latest content is available on devices but waiting for synchronized devices to download it before playing.">
                                  <i className="material-icons pending">cloud_download</i>
                                  Pending:{' '}
                                  <Link to={`${search}download_status=pending`}>{pending}</Link>
                                </ToolTip>
                              </li>
                              <li>
                                <ToolTip toolText="Devices do not have the latest content.">
                                  <i className="material-icons outdated">cloud_download</i>
                                  Outdated:{' '}
                                  <Link to={`${search}download_status=outdated`}>{outdated}</Link>
                                </ToolTip>
                              </li>
                            </ul>
                          </Col>
                        </Row>
                      </Grid>
                    )}
                  </Panel.Body>
                </Panel>
                {p.atLeastTierStandardOrSuperUser && (
                  <Grid fluid>
                    <Row>
                      <Col lg={4} md={6}>
                        <DashboardTable
                          type="offlineDevices"
                          users={users}
                          data={this.getOfflineDevices()}
                        />
                      </Col>
                      <Col lg={4} md={6}>
                        <DashboardTable
                          type="modifiedDevices"
                          users={users}
                          data={this.getModifiedDevices()}
                        />
                      </Col>
                      <Clearfix visibleMdBlock />
                      <Col lg={4} md={6}>
                        <DashboardTable
                          type="registeredDevices"
                          users={users}
                          data={this.getRegisteredDevices()}
                        />
                      </Col>
                      <Clearfix visibleLgBlock />
                      <Col lg={4} md={6}>
                        <DashboardTable
                          type="outdatedContent"
                          users={users}
                          data={this.getDevicesWithOutdatedContent()}
                        />
                      </Col>
                      <Clearfix visibleMdBlock />
                      <Col lg={4} md={6}>
                        <DashboardTable
                          type="newContent"
                          users={users}
                          data={this.getDevicesWithNewContent()}
                        />
                      </Col>
                      <Col lg={4} md={6}>
                        <DashboardTable
                          type="downloadingContent"
                          users={users}
                          data={this.getDevicesWithDownloadingContent()}
                        />
                      </Col>
                    </Row>
                  </Grid>
                )}
              </div>
            </Spinner>
          </div>
        </div>
      </div>
    );
  }
}

DashboardPage.propTypes = {
  auth: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  users: PropTypes.object.isRequired,
  devices: PropTypes.object.isRequired,
  getUsers: PropTypes.func.isRequired,
  getAllDevices: PropTypes.func.isRequired,
  getStatus: PropTypes.func.isRequired,
  addNotification: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  users: state.users,
  devices: state.devices,
});
const mapDispatchToProps = {
  getUsers,
  getAllDevices,
  getStatus,
  addNotification,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DashboardPage));
