import React from 'react';
import PropTypes from 'prop-types';

const ErrorModal = ({ data: { msg } }) => (
  <div>
    <h4>An error Occurred.</h4>
    {msg ? <p>{msg}</p> : <p>Please contact support if this error persists.</p>}
  </div>
);

ErrorModal.propTypes = {
  data: PropTypes.shape({
    msg: PropTypes.string,
  }),
};

ErrorModal.defaultProps = {
  data: { msg: '' },
};

export default ErrorModal;
