import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import qs from '../../utils/qs';
import { isQueryParam, addUniqueQueryParam, removeQueryParam } from '../../utils/updateQueryString';
import { addUniqueFilter } from '../../actions/filterActions';

const EMPTY_STRING = 'N/A';

export class FilterDropdown extends Component {
  componentDidMount() {
    this.setFilterFromQuery(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setFilterFromQuery(nextProps);
  }

  getText = value => {
    const { text } = this.props;

    return text ? text[value] : value || EMPTY_STRING;
  };

  setFilterFromQuery = props => {
    const { location, name, title, data, addUniqueFilter } = props;

    if (qs(this.props)[name] === undefined) {
      return;
    }

    for (let index = 0; index < data.length; index += 2) {
      const value = data[index];
      const checked = isQueryParam(location, name, value);
      const text = this.getText(value);

      if (checked) {
        addUniqueFilter({ group: name, name, query: value, text, title });
      }
    }
  };

  handleChange = event => {
    const { history, location, name } = this.props;
    const { value } = event.target;

    if (value === event.target.options[0].value) {
      removeQueryParam(history, location, name, qs(this.props)[name]);
    } else {
      const val = value === EMPTY_STRING ? '' : value;

      addUniqueQueryParam(history, location, name, val);
    }
  };

  renderOptions = () => {
    const { data } = this.props;
    const list = [];

    for (let index = 0; index < data.length; index += 2) {
      const text = this.getText(data[index]);
      const value = data[index] || EMPTY_STRING;
      let count = data[index + 1];

      count = count === '' ? '' : ` (${count})`;

      list.push(
        <option key={index} value={value}>
          {text}
          {count}
        </option>,
      );
    }

    list.unshift(<option key={-1} />);

    return list;
  };

  render() {
    const { name, title } = this.props;
    const options = this.renderOptions();
    const defaultValue =
      qs(this.props)[name] === undefined ? '' : qs(this.props)[name] || EMPTY_STRING;

    if (options.length === 1) {
      return null;
    }

    return (
      <div>
        <div className="filter-section-title">{title}</div>
        <select
          data-test={`select-${name}`}
          className="filter-dropdown form-control"
          onChange={event => this.handleChange(event)}
          value={defaultValue}
        >
          {options}
        </select>
      </div>
    );
  }
}

FilterDropdown.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  data: PropTypes.array,
  text: PropTypes.object,
  addUniqueFilter: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  addUniqueFilter,
};

export default withRouter(connect(null, mapDispatchToProps)(FilterDropdown));
