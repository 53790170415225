import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, FormGroup, FormControl } from 'react-bootstrap';

export default class RegisterDeviceDialog extends Component {
  getInitialState = () => ({ id: '', description: '' });

  state = this.getInitialState();

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { show } = nextProps;

    if (!show) {
      this.setState(this.getInitialState());
    }
  }

  onChangeId = event => this.setState({ id: event.target.value });

  onChangeDescription = event => this.setState({ description: event.target.value });

  render() {
    const { show, onHide, onSuccess } = this.props;
    const { id, description } = this.state;

    return (
      <Modal dialogClassName="device-dialog" backdrop="static" show={show} onHide={onHide}>
        <Modal.Header>
          <Modal.Title data-test="modal-registerdevice-title">Register Device</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormGroup>
            <label>Device ID</label>
            <FormControl
              data-test="modal-registerdevice-input-id"
              placeholder="Enter the Device ID"
              value={id}
              onChange={this.onChangeId}
              autoFocus
            />
          </FormGroup>
          <FormGroup>
            <label>Device Name</label>
            <FormControl
              data-test="modal-registerdevice-input-desc"
              placeholder="Name this Device"
              value={description}
              onChange={this.onChangeDescription}
            />
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button
            data-test="modal-registerdevice-button-cancel"
            className="btn-cancel"
            onClick={onHide}
          >
            Cancel
          </Button>
          <Button
            data-test="modal-registerdevice-button-add"
            className="btn-add"
            bsStyle="primary"
            onClick={() => onSuccess(id, description)}
            disabled={!id.trim() || !description.trim()}
          >
            Register Device
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

RegisterDeviceDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};
