const getSignsForAsset = (asset, signs) => {
  if (!asset || !signs || !signs.length) {
    return [];
  }

  let used = [];

  (asset.usedInSigns || []).filter(Boolean).forEach(item => {
    const [id, published] = item.split('-').map(splitItem => +splitItem);

    if (used.find(sign => sign.id === id)) {
      used = used.map(sign => {
        if (sign.id === id) {
          if (published) {
            sign.published = true;
          } else {
            sign.pending = true;
          }
        }

        return sign;
      });
    } else {
      const sign = signs.find(signItem => signItem.id === id) || { id };

      if (published) {
        sign.published = true;
      } else {
        sign.pending = true;
      }

      used.push(sign);
    }
  });

  return used.sort((a, b) => a.name.localeCompare(b.name));
};

export default getSignsForAsset;
