import clone from 'clone';
import * as types from '../actions/actionTypes';

export const initialState = {
  loadingSign: null,
  loadingSigns: null,
  error: null,
  sign: {},
  signEdit: {},
  signs: {
    signs: [],
    facet_fields: {},
    stats_fields: {},
    numFound: null,
  },
  lastSelected: null,
};

export default function signs(state = initialState, action = {}) {
  switch (action.type) {
    // signs
    case types.SIGNS_REQUEST:
      return {
        ...state,
        loadingSigns: true,
        error: null,
      };
    case types.SIGNS_REQUEST_SUCCESS:
      return {
        ...state,
        loadingSigns: false,
        signs: action.result,
      };
    case types.SIGNS_REQUEST_FAILED:
      return {
        ...state,
        loadingSigns: false,
        error: action.error,
      };
    // sign
    case types.SIGN_REQUEST:
      return {
        ...state,
        loadingSign: true,
        error: null,
      };
    case types.SIGN_REQUEST_SUCCESS:
      return {
        ...state,
        loadingSign: false,
        sign: action.result,
        signEdit: clone(action.result), // cannot reference to the original
      };
    case types.SIGN_REQUEST_FAILED:
      return {
        ...state,
        loadingSign: false,
        error: action.error,
      };
    // sign form
    case types.SIGN_FORM_EDIT:
      return {
        ...state,
        signEdit: {
          ...state.signEdit,
          [action.key]: action.value,
        },
      };
    case types.SIGN_FORM_CLEAR:
      return {
        ...state,
        sign: {},
        signEdit: {},
      };
    default:
      return state;
  }
}
