import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import async from 'async';
import pluralize from 'pluralize';
import { SOLR_DELAY } from '../../../../constants';
import { addNotification } from '../../../actions/notificationActions';
import { getUsers } from '../../../actions/userActions';
import { getRoles, deleteRole, select, unselectAll } from '../../../actions/roleActions';
import Header from '../../../components/Header/Header';
import ActionsMenu from '../../../components/ActionsMenu/ActionsMenu';
import QuickAction from '../../../components/QuickAction/QuickAction';
import Spinner from '../../../components/Spinner/Spinner';
import Confirm from '../../../components/Confirm/Confirm';
import RolesTable from '../../../components/Roles/RolesTable';
import ErrorPage from '../ErrorPage/ErrorPage';

export class RolesPage extends Component {
  componentDidMount() {
    this.loadData();
    document.addEventListener('visibilitychange', this.loadData);
  }

  componentWillUnmount() {
    const { unselectAll } = this.props;

    unselectAll();
    document.removeEventListener('visibilitychange', this.loadData);
  }

  loadData = () => {
    if (document.visibilityState === 'hidden') {
      return;
    }

    const { getUsers, getRoles } = this.props;

    getUsers();
    getRoles();
  };

  getSelectedItems = () => {
    const {
      roles: { selected, roles },
    } = this.props;

    return Object.keys(selected).map(index => roles[index]);
  };

  getSelectedNotInUse = () => {
    const {
      users: { users },
    } = this.props;

    return this.getSelectedItems().filter(
      item => !users.some(user => +user.role_id === +item.role_id),
    );
  };

  delete = () => {
    const items = this.getSelectedNotInUse();

    async.filter(
      items,
      (item, callback) =>
        deleteRole(item.role_id).then(
          res => callback(null, res),
          () => callback(null, false),
        ),
      this.deleteCallback,
    );
  };

  deleteCallback = (err, results) => {
    const { getRoles, addNotification, unselectAll } = this.props;
    const items = this.getSelectedNotInUse();
    let failedItems;
    let errorMsg = '';

    // check for errors
    if (items.length === results.length) {
      addNotification({
        type: 'success',
        text: `${pluralize('Role', items.length, true)} deleted.`,
      });
    } else {
      // collect the failed request items
      failedItems = items.filter(item => !results.includes(item));
      failedItems.forEach(item => {
        errorMsg += (errorMsg ? ', ' : 'Failed to delete: ') + item.name;
      });
      addNotification({ type: 'danger', text: errorMsg });
    }

    // if there was at least one successful request, update
    if (results.length) {
      unselectAll();
      setTimeout(getRoles, SOLR_DELAY);
    }
  };

  onClickDelete = () => {
    const { addNotification } = this.props;
    const confirm = this.refDelete;
    const items = this.getSelectedNotInUse();

    if (this.getSelectedItems().length !== items.length) {
      addNotification({ type: 'danger', text: 'Cannot delete a Role that is in use.' });

      return;
    }

    const roles = items.map((item, index) => (
      <tr key={index}>
        <td>{item.name}</td>
        <td>
          {item.perm
            .split('|')
            .filter(perm => perm)
            .join(', ')}
        </td>
      </tr>
    ));
    const title = `Delete ${pluralize('Role', items.length, true)}`;
    const body = (
      <div>
        <p>
          Are you sure you want to delete {pluralize('this', items.length)}{' '}
          {pluralize('Role', items.length)}?
        </p>
        <table className="table">
          <thead>
            <tr>
              <th>Role</th>
              <th>Permissions</th>
            </tr>
          </thead>
          <tbody>{roles}</tbody>
        </table>
      </div>
    );

    confirm.show(title, body);
    confirm.onSuccess(this.delete);
  };

  onClickTable = roles => {
    const { select } = this.props;

    select(roles);
  };

  onDoubleClickTable = () => {
    const { history } = this.props;

    history.push(`/roles/${this.getSelectedItems()[0].role_id}`);
  };

  getHeaderActions = () => [
    <QuickAction key="1" toolText="Add Role" href="/roles/new" alwaysShow>
      add_circle
    </QuickAction>,
    <QuickAction
      key="2"
      toolText={pluralize('Delete Role', this.getSelectedItems().length)}
      onClick={this.onClickDelete}
      singular
      multiple
    >
      delete
    </QuickAction>,
  ];

  render() {
    const {
      auth,
      roles: { roles, loading, selected, error },
      users: { users },
    } = this.props;

    if (error) {
      return <ErrorPage error={error} />;
    }

    return (
      <div className="page-structure">
        <Helmet title="Roles" />
        <Header />
        <ActionsMenu
          actions={this.getHeaderActions()}
          selectedLength={this.getSelectedItems().length}
        />
        <div className="page-panels">
          <div className="page-content page-roles">
            <Spinner loading={loading}>
              <RolesTable
                data={roles}
                auth={auth}
                users={users}
                selected={selected}
                onClick={this.onClickTable}
                onDoubleClick={this.onDoubleClickTable}
              />
            </Spinner>
          </div>
        </div>
        <Confirm
          ref={ref => {
            this.refDelete = ref;
          }}
        />
      </div>
    );
  }
}

RolesPage.propTypes = {
  history: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  roles: PropTypes.object.isRequired,
  users: PropTypes.object.isRequired,
  getRoles: PropTypes.func.isRequired,
  getUsers: PropTypes.func.isRequired,
  select: PropTypes.func.isRequired,
  unselectAll: PropTypes.func.isRequired,
  addNotification: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  roles: state.roles,
  users: state.users,
});
const mapDispatchToProps = {
  getUsers,
  getRoles,
  select,
  unselectAll,
  addNotification,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RolesPage));
