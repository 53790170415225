import * as types from '../actions/actionTypes';

export const initialState = {
  loadingCampaigns: false,
  loadingCampaign: false,
  errorCampaigns: null,
  errorCampaign: null,
  campaigns: {},
  campaign: {},
};

export default function campaigns(state = initialState, action = {}) {
  switch (action.type) {
    case types.CAMPAIGNS_REQUEST:
      return {
        ...state,
        loadingCampaigns: true,
        errorCampaigns: null,
      };
    case types.CAMPAIGNS_REQUEST_SUCCESS:
      return {
        ...state,
        loadingCampaigns: false,
        errorCampaigns: null,
        campaigns: action.result,
      };
    case types.CAMPAIGNS_REQUEST_FAILED:
      return {
        ...state,
        loadingCampaigns: false,
        errorCampaigns: action.error,
      };
    case types.CAMPAIGN_REQUEST:
      return {
        ...state,
        loadingCampaign: true,
        errorCampaign: null,
      };
    case types.CAMPAIGN_REQUEST_SUCCESS:
      return {
        ...state,
        loadingCampaign: false,
        errorCampaign: null,
        campaign: action.result,
      };
    case types.CAMPAIGN_REQUEST_FAILED:
      return {
        ...state,
        loadingCampaign: false,
        errorCampaign: action.error,
      };
    case types.CAMPAIGN_FORM_CLEAR:
      return {
        ...state,
        campaign: {},
      };
    default:
      return state;
  }
}
