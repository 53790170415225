import React from 'react';
import PropTypes from 'prop-types';

const display = {
  info: 'Info',
  success: 'Success',
  danger: 'Error',
  warning: 'Warning',
};
const NotificationHistory = ({ data }) => (
  <div>
    {data && data.length ? (
      <ul className="dialog-list">
        <li>
          <div className="notification">
            <div className="col col1">
              <strong>Type</strong>
            </div>
            <div className="col col2">
              <strong>Information</strong>
            </div>
            <div className="col col3">
              <strong>Timestamp</strong>
            </div>
          </div>
        </li>
        {data.map(notification => (
          <li key={notification.id} className={`notification ${notification.type}`}>
            <div className="notification">
              <div className="col col1">{display[notification.type]}</div>
              <div className="col col2">{notification.text}</div>
              <div className="col col3">{notification.timestamp}</div>
            </div>
          </li>
        ))}
      </ul>
    ) : (
      <div>No notifications recorded yet.</div>
    )}
  </div>
);

NotificationHistory.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
      id: PropTypes.number.isRequired,
      timestamp: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default NotificationHistory;
