import * as types from '../actions/actionTypes';

export const initialState = {
  loading: false,
  error: null,
  selected: {},
  users: [],
  user: null,
};

export default function users(state = initialState, action = {}) {
  switch (action.type) {
    case types.USERS_SELECT:
      return {
        ...state,
        selected: action.ids,
      };
    case types.USERS_UNSELECT_ALL:
      return {
        ...state,
        selected: {},
      };
    case types.USERS_REQUEST:
      return {
        ...state,
        error: null,
      };
    case types.USERS_REQUEST_WITH_LOADING_STATUS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case types.USERS_REQUEST_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case types.USERS_REQUEST_SUCCESS_USERS:
      return {
        ...state,
        loading: false,
        users: action.result,
      };
    case types.USERS_REQUEST_SUCCESS_USER:
      return {
        ...state,
        loading: false,
        user: action.result,
      };
    case types.USERS_CLEAR_USER:
      return {
        ...state,
        user: null,
      };
    default:
      return state;
  }
}
