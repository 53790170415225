import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Panel } from 'react-bootstrap';
import SSTable from '../SSTable/SSTable';
import SSColumn from '../SSTable/SSColumn';
import ToolTip from '../ToolTipIcon/ToolTip';
import {
  getIdNode,
  getLocalTime,
  getOnlineStatusIcon,
  getDownloadStatusIcon,
  getFormatDistanceToNow,
} from '../Devices/DeviceUtils';

export default class DashboardTable extends Component {
  state = {
    open: false,
  };

  getIcons = device => (
    <div className="device-icons">
      {getOnlineStatusIcon(device)}
      {getDownloadStatusIcon(device)}
    </div>
  );

  getDevice = device => {
    const location = device.location_path === '/' ? 'No Location set' : device.location_path;

    return (
      <div>
        {getIdNode(device, this.props.users)} {device.description}
        <div className="location">
          <i className="material-icons" title="Location">
            public
          </i>
          {location}
        </div>
      </div>
    );
  };

  getDistanceToNowWithToolTip = date => (
    <ToolTip toolText={getLocalTime(date)}>{getFormatDistanceToNow(date)}</ToolTip>
  );

  getOnlineStatusText = device =>
    device.idle ? this.getDistanceToNowWithToolTip(Date.now() + device.idle * 1000) : 'Never seen';

  getModifiedDate = device =>
    device.modifiedDate && new Date(device.modifiedDate).getTime()
      ? this.getDistanceToNowWithToolTip(device.modifiedDate)
      : 'N/A';

  getCreatedDate = device =>
    device.createdDate && new Date(device.createdDate).getTime()
      ? this.getDistanceToNowWithToolTip(device.createdDate)
      : 'N/A';

  getContentDateDistanceToNow = device =>
    Number(device.content_ver)
      ? this.getDistanceToNowWithToolTip(device.content_ver * 1000)
      : 'N/A';

  getDownloadingContentDateDistanceToNow = device =>
    device.downloading_ver
      ? this.getDistanceToNowWithToolTip(device.downloading_ver * 1000)
      : 'N/A';

  getTypeData = () =>
    ({
      offlineDevices: {
        icon: <i className="material-icons material-icons offline">lens</i>,
        title: 'Latest offline devices',
        header: 'Last seen',
        cell: this.getOnlineStatusText,
      },
      modifiedDevices: {
        icon: <i className="material-icons material-icons">mode_edit</i>,
        title: 'Latest modified devices',
        header: 'Modified',
        cell: this.getModifiedDate,
      },
      registeredDevices: {
        icon: <i className="material-icons material-icons">add_circle</i>,
        title: 'Latest registered devices',
        header: 'Registered',
        cell: this.getCreatedDate,
      },
      outdatedContent: {
        icon: <i className="material-icons outdated">cloud_download</i>,
        title: 'Devices with outdated contents',
        header: 'Content deployed',
        cell: this.getContentDateDistanceToNow,
      },
      newContent: {
        icon: <i className="material-icons current">cloud_download</i>,
        title: 'Devices with latest deployed contents',
        header: 'Content deployed',
        cell: this.getContentDateDistanceToNow,
      },
      downloadingContent: {
        icon: <i className="material-icons downloading">cloud_download</i>,
        title: 'Devices are downloading contents',
        header: 'Content deployed',
        cell: this.getDownloadingContentDateDistanceToNow,
      },
    }[this.props.type]);

  toggle = () => this.setState({ open: !this.state.open });

  render() {
    const { users, data = [] } = this.props;
    const { open } = this.state;
    const { icon, title, header, cell } = this.getTypeData();
    const dataExists = Boolean(users && data && data.length);
    const slicedData = open ? data : data.slice(0, 5);

    return (
      <Panel data-test={`panel-${title.replace(/\s/g, '')}`}>
        <Panel.Heading className="toggle" onClick={this.toggle}>
          <ToolTip toolText={`Click to show ${open ? 'latest 5' : 'all'} devices`}>
            {icon}
            <i className="material-icons material-caret inline">
              {open ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}
            </i>
            {title}
          </ToolTip>
        </Panel.Heading>
        <Panel.Body className={open ? 'open' : ''}>
          {dataExists && (
            <SSTable
              className="responsive"
              data={slicedData}
              headerHeight={25}
              rowHeight={45}
              rowsCount={slicedData.length - 1}
              zebra
            >
              <SSColumn header="" cell={this.getIcons} />
              <SSColumn header="Device" cell={this.getDevice} />
              <SSColumn header={header} cell={cell} />
            </SSTable>
          )}
        </Panel.Body>
      </Panel>
    );
  }
}

DashboardTable.propTypes = {
  type: PropTypes.string,
  users: PropTypes.array,
  data: PropTypes.array,
};
