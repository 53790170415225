import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import SSTable from '../SSTable/SSTable';
import SSColumn from '../SSTable/SSColumn';
import ToolTipIcon from '../ToolTipIcon/ToolTipIcon';

export default class UrlRulesetsTable extends Component {
  getName = item => <Link to={`/url-rulesets/${item.id}`}>{item.name}</Link>;

  getInUse = item => {
    const { inUse } = this.props;

    return (
      Array.from(
        new Set(inUse.filter(inuse => +inuse.urlRulesetId === +item.id).map(inuse => inuse.signId)),
      ).length || ''
    );
  };

  getEditButton = item => (
    <ToolTipIcon toolText="Edit URL Ruleset" href={`/url-rulesets/${item.id}`}>
      mode_edit
    </ToolTipIcon>
  );

  render() {
    const { data, selected, onClick, onDoubleClick } = this.props;

    if (!data) {
      return null;
    }

    return (
      <SSTable
        data={data}
        rowHeight={50}
        rowsCount={data.length - 1}
        onSelect={onClick}
        doubleClick={onDoubleClick}
        selected={selected}
        selectable
        zebra
      >
        <SSColumn header="URL Ruleset Name" cell={this.getName} />
        <SSColumn header="Used in Signs" cell={this.getInUse} />
        <SSColumn header="Edit" width={5} cell={this.getEditButton} />
      </SSTable>
    );
  }
}

UrlRulesetsTable.propTypes = {
  data: PropTypes.array,
  inUse: PropTypes.array.isRequired,
  selected: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  onDoubleClick: PropTypes.func.isRequired,
};
