import 'isomorphic-fetch';
import fetchCheck from '../redux/middleware/fetchCheck';
import * as types from './actionTypes';

export const url = '/cms/data/v1/fonts/';

export const credentials = 'include';

export const headers = { Accept: 'application/json', 'Content-Type': 'application/json' };

export const select = ids => ({ type: types.FONTS_SELECT, ids });

export const unselectAll = () => ({ type: types.FONTS_UNSELECT_ALL });

export const request = () => ({ type: types.FONTS_REQUEST });

export const requestFailed = error => ({ type: types.FONTS_REQUEST_FAILED, error });

export const requestSuccessFonts = result => ({
  type: types.FONTS_REQUEST_SUCCESS_FONTS,
  result,
});

export const requestSuccessFont = result => ({ type: types.FONTS_REQUEST_SUCCESS_FONT, result });

export const fetchFonts = () =>
  fetch(url, { method: 'GET', credentials, headers })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const fetchFont = id =>
  fetch(url + id, { method: 'GET', credentials, headers })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const updateFont = (id, font) =>
  fetch(url + id, { method: 'PUT', credentials, headers, body: JSON.stringify(font) })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const deleteFont = id =>
  fetch(url + id, { method: 'DELETE', credentials, headers })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const getFonts = () => dispatch => {
  dispatch(request());

  return fetchFonts().then(
    res => dispatch(requestSuccessFonts(res)),
    err => dispatch(requestFailed(err)),
  );
};

export const getFont = id => dispatch => {
  dispatch(request());

  return fetchFont(id).then(
    res => dispatch(requestSuccessFont(res)),
    err => dispatch(requestFailed(err)),
  );
};
