import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import SSTable from '../SSTable/SSTable';
import SSColumn from '../SSTable/SSColumn';
import ToolTipIcon from '../ToolTipIcon/ToolTipIcon';
import AssetImage from '../Assets/AssetImage';
import { buildTagList } from '../../utils/tagList';
import { launchCreator } from '../../utils/launchCreator';
import { buildUrlForAddUniqueQueryParam } from '../../utils/updateQueryString';
import { checkPathAccess } from '../../utils/checkPathAccess';
import { getPermissions } from '../../utils/getPermissions';

export class AssignmentsTable extends Component {
  getName = item => (
    <div className="item-name">
      <div>
        <Link to={item.type === 'device' ? `/devices/${item.device_id}` : this.getUrl(item)}>
          {item.name}
        </Link>
      </div>
      <div className="item-tags">Location: {item.parent}</div>
    </div>
  );

  getDescription = assignment => (
    <div className="item-name">
      <div>{assignment.description}</div>
      <div className="item-tags">Tags: {buildTagList(assignment)}</div>
    </div>
  );

  getIcon = assignment => {
    const { userLocation } = this.props;
    const isAccessAllowed = checkPathAccess(userLocation, assignment);

    return isAccessAllowed ? (
      <i className="material-icons">
        {assignment.type === 'location' ? 'public' : 'stay_current_landscape'}
      </i>
    ) : (
      <i className="material-icons restricted">
        {assignment.type === 'location' ? 'vpn_locked' : 'screen_lock_landscape'}
      </i>
    );
  };

  getType = assignment => assignment.type;

  getPopulated = assignment => {
    let populated = '';

    if (assignment.type === 'location') {
      populated = `${this.getAssignedContentCount(assignment)}/${this.getChildDevices(assignment)}`;
    }

    return populated;
  };

  getAssignedContent = item => {
    const { auth, signs } = this.props;
    const p = getPermissions(auth);

    if (item.type !== 'device') {
      return null;
    }

    return (
      <div data-test="assigned-content" className="assignment-content">
        <div data-test="assigned-content-image">
          <AssetImage id={item.content_id} type={item.content_type} item={item} signs={signs} />
        </div>
        <div className="item-name">
          <div data-test="assigned-content-name">
            {!p.atLeastTierStandard && item.content_type === 'sign' ? (
              item.content_name
            ) : (
              <Link to={`/${item.content_type}s/${item.content_id}`}>{item.content_name}</Link>
            )}
          </div>
          <div data-test="assigned-content-type" className="item-tags">
            Type: {item.content_type}
          </div>
          <div data-test="assigned-content-actions">
            {['sign', 'sequence', 'schedule'].includes(item.content_type) && (
              <ToolTipIcon
                toolText={`Preview ${item.content_name}`}
                href={`/player/?${item.content_type}=${item.content_id}`}
                target="_blank"
              >
                play_circle_filled
              </ToolTipIcon>
            )}
            {p.atLeastTierStandard && item.content_type === 'sign' && (
              <ToolTipIcon
                toolText={`Open ${item.content_name} in Creator`}
                onClick={() => launchCreator(item.content_id)}
              >
                <i className="fa fa-paint-brush" />
              </ToolTipIcon>
            )}
          </div>
        </div>
      </div>
    );
  };

  getAssignedSource = item => {
    if (item.type !== 'device' || !item.content_default_stream_type) {
      return null;
    }

    return (
      <div data-test="assigned-source" className="assignment-content">
        <div data-test="assigned-source-image">
          <AssetImage
            id={item.content_default_stream_id}
            type={item.content_default_stream_type}
            item={item}
          />
        </div>
        <div className="item-name">
          <div data-test="assigned-source-name">
            {item.content_default_stream_type !== 'playlist' && item.content_default_stream_name}
            {item.content_default_stream_type === 'playlist' && (
              <Link to={`/playlists/${item.content_default_stream_id}`}>
                {item.content_default_stream_name}
              </Link>
            )}
          </div>
          <div data-test="assigned-source-type" className="item-tags">
            Type: {item.content_default_stream_type}
          </div>
        </div>
      </div>
    );
  };

  getChildDevices = assignment => {
    const { allData } = this.props;
    const parentPath = `${assignment.parent + assignment.name}/`;
    let counter = 0;

    if (allData) {
      allData.forEach(item => {
        const isAChildDevice = item.type === 'device' && item.parent.startsWith(parentPath);

        if (isAChildDevice) {
          counter += 1;
        }
      });
    }

    return counter;
  };

  getAssignedContentCount = assignment => {
    const { allData } = this.props;
    const parentPath = `${assignment.parent + assignment.name}/`;
    let counter = 0;

    if (allData) {
      allData.forEach(item => {
        const hasContentAssigned =
          item.type === 'device' &&
          item.parent.startsWith(parentPath) &&
          item.content_type !== 'none';

        if (hasContentAssigned) {
          counter += 1;
        }
      });
    }

    return counter;
  };

  nextOrViewDevice = assignment => {
    const {
      campaign: { id },
    } = this.props;

    if (assignment.type === 'device') {
      return (
        <div className="assignment-icons">
          <ToolTipIcon
            toolText={`Preview campaign for device: ${assignment.name}`}
            href={`/player/?device=${assignment.device_id}&campaign=${id}`}
            target="_blank"
          >
            play_circle_filled
          </ToolTipIcon>
          <ToolTipIcon toolText="Device Overview" href={`/devices/${assignment.device_id}`}>
            remove_red_eye
          </ToolTipIcon>
        </div>
      );
    }

    return (
      <ToolTipIcon toolText="Open" href={this.getUrl(assignment)}>
        keyboard_arrow_right
      </ToolTipIcon>
    );
  };

  getUrl = item => {
    const { location } = this.props;
    const parent = `${item.parent + item.name}/`;

    return buildUrlForAddUniqueQueryParam(location, 'parent', parent);
  };

  onDoubleClick = (event, item) => {
    const { history } = this.props;

    if (item.type === 'location') {
      history.push(this.getUrl(item));
    }
  };

  isDisabled = () => {
    const { userLocation } = this.props;

    if (userLocation) {
      return item => {
        const { parent, name } = item;
        const location = `${parent}${name}/`;

        return !checkPathAccess(userLocation, location);
      };
    }

    return () => false;
  };

  render() {
    const { data, selected, onClick } = this.props;

    return (
      <SSTable
        data={data}
        rowHeight={80}
        className="responsive table-assignments"
        rowsCount={data.length - 1}
        onSelect={onClick}
        doubleClick={this.onDoubleClick}
        selected={selected}
        zebra
        selectable
        isDisabled={this.isDisabled()}
      >
        <SSColumn width={1} cell={this.getIcon} header="" />
        <SSColumn width={1} cell={this.getType} header="Type" />
        <SSColumn width={3} cell={this.getName} header="Name" />
        <SSColumn width={6} cell={this.getDescription} header="Description" />
        <SSColumn width={1} cell={this.getPopulated} header="Populated" />
        <SSColumn width={3} cell={this.getAssignedContent} header="Content" />
        <SSColumn width={3} cell={this.getAssignedSource} header="Video Source" />
        <SSColumn width={1} cell={this.nextOrViewDevice} header="" />
      </SSTable>
    );
  }
}

AssignmentsTable.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  campaign: PropTypes.object.isRequired,
  signs: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  allData: PropTypes.array.isRequired,
  selected: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  userLocation: PropTypes.string.isRequired,
};

export default withRouter(AssignmentsTable);
