import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Prompt } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { SOLR_DELAY } from '../../../../constants';
import { addNotification } from '../../../actions/notificationActions';
import { getUsers } from '../../../actions/userActions';
import {
  getRoles,
  getRole,
  clearRole,
  addRole,
  updateRole,
  deleteRole,
} from '../../../actions/roleActions';
import Header from '../../../components/Header/Header';
import ActionsMenu from '../../../components/ActionsMenu/ActionsMenu';
import QuickAction from '../../../components/QuickAction/QuickAction';
import RoleForm from '../../../components/Roles/RoleForm';
import Confirm from '../../../components/Confirm/Confirm';
import ErrorPage from '../ErrorPage/ErrorPage';
import errorMessage from '../../../utils/errorMessage';

export class RolePage extends Component {
  state = {
    role: {
      role_id: null,
      name: '',
      desc: '',
      perm: '',
    },
    permissions: [
      { id: 1, name: 'Content Viewer' },
      { id: 2, name: 'Writer' },
      { id: 3, name: 'Approver' },
      { id: 4, name: 'Deployer' },
      { id: 5, name: 'Assigner' },
      { id: 6, name: 'Admin' },
      { id: 7, name: 'Locker' },
      { id: 8, name: 'Commander' },
      { id: 9, name: 'Device Viewer' },
      { id: 10, name: 'Device Manager' },
    ],
    dirty: false,
  };

  componentDidMount() {
    this.loadData();
    document.addEventListener('visibilitychange', this.loadData);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      roles: { role },
    } = nextProps;
    const {
      role: { role_id },
    } = this.state;

    if (!role_id && role.role_id) {
      this.setState({ role });
    }
  }

  componentWillUnmount() {
    const { clearRole } = this.props;

    clearRole();
    document.removeEventListener('visibilitychange', this.loadData);
  }

  loadData = () => {
    if (document.visibilityState === 'hidden') {
      return;
    }

    const {
      match: {
        params: { id },
      },
      getUsers,
      getRoles,
      getRole,
    } = this.props;

    getUsers();
    getRoles();

    if (id) {
      getRole(id);
    }
  };

  getRolePermissionIds = () => {
    const {
      role: { perm },
      permissions,
    } = this.state;

    return perm
      .split('|')
      .filter(item => item)
      .map(name => permissions.find(item => name.toLowerCase() === item.name.toLowerCase()))
      .map(item => item.id)
      .sort((a, b) => a - b);
  };

  buildRoleForServer = () => {
    const {
      role: { name, desc },
    } = this.state;

    return {
      name: name.trim(),
      desc,
      permissions: this.getRolePermissionIds().join('|'),
    };
  };

  editForm = (key, value) => {
    const { role } = this.state;

    this.setState({ role: { ...role, [key]: value }, dirty: true });
  };

  goBack = () => {
    const { history, previousLocation } = this.props;
    const url = previousLocation ? previousLocation.pathname + previousLocation.search : '/roles';

    this.setState({ dirty: false }, () => history.push(url));
  };

  onClickDelete = () => {
    const { users, roles, addNotification } = this.props;

    if (users.users.some(item => +item.role_id === +roles.role.role_id)) {
      addNotification({ type: 'danger', text: 'Cannot delete a Role that is in use.' });

      return;
    }

    const confirm = this.refDelete;

    confirm.show('Delete Role', 'Are you sure you want to delete this Role?');
    confirm.onSuccess(this.delete);
  };

  delete = () => {
    const {
      match: {
        params: { id },
      },
      addNotification,
    } = this.props;

    return deleteRole(id).then(
      () => {
        addNotification({ type: 'success', text: 'Role deleted.' });
        setTimeout(this.goBack, SOLR_DELAY);
      },
      err => addNotification({ type: 'danger', text: errorMessage(err) }),
    );
  };

  add = () => {
    const { addNotification } = this.props;
    const role = this.buildRoleForServer();

    return addRole(role).then(
      () => {
        addNotification({ type: 'success', text: 'Role added.' });
        setTimeout(this.goBack, SOLR_DELAY);
      },
      err => addNotification({ type: 'danger', text: errorMessage(err) }),
    );
  };

  update = () => {
    const {
      match: {
        params: { id },
      },
      addNotification,
    } = this.props;
    const role = this.buildRoleForServer();

    return updateRole(id, role).then(
      () => {
        addNotification({ type: 'success', text: 'Role updated.' });
        setTimeout(this.goBack, SOLR_DELAY);
      },
      err => addNotification({ type: 'danger', text: errorMessage(err) }),
    );
  };

  getHeaderActions = () => {
    const {
      roles: {
        role: { role_id },
      },
    } = this.props;

    if (!role_id) {
      return [];
    }

    return [
      <QuickAction key="1" toolText="Delete Role" onClick={this.onClickDelete} alwaysShow>
        delete
      </QuickAction>,
    ];
  };

  render() {
    const {
      match: {
        params: { id },
      },
      auth: {
        user: {
          user: { role_id },
          commander_enabled,
        },
      },
      roles: { roles, role, error },
    } = this.props;
    const { dirty, role: stateRole, permissions } = this.state;

    if (error) {
      return <ErrorPage error={error} />;
    }

    if (!role) {
      return null;
    }

    return (
      <div className="page-structure">
        <Helmet title="Role" />
        <Header overwrite={{ id: role }} />
        <ActionsMenu actions={this.getHeaderActions()} />
        <div className="page-panels">
          <div className="page-content page-role">
            <Prompt
              when={dirty}
              message="You have unsaved changes. Are you sure you want to leave this page?"
            />
            <RoleForm
              role={stateRole}
              roles={roles}
              permissions={
                !commander_enabled ? permissions.filter(x => x.name !== 'Commander') : permissions
              }
              isNew={!id}
              isOwner={+role_id === +role.role_id}
              onEdit={this.editForm}
              onAdd={this.add}
              onUpdate={this.update}
              onCancel={this.goBack}
            />
          </div>
        </div>
        <Confirm
          ref={ref => {
            this.refDelete = ref;
          }}
        />
      </div>
    );
  }
}

RolePage.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  previousLocation: PropTypes.object,
  auth: PropTypes.object.isRequired,
  users: PropTypes.object.isRequired,
  roles: PropTypes.object,
  getUsers: PropTypes.func.isRequired,
  getRoles: PropTypes.func.isRequired,
  getRole: PropTypes.func.isRequired,
  clearRole: PropTypes.func.isRequired,
  addRole: PropTypes.func.isRequired,
  updateRole: PropTypes.func.isRequired,
  deleteRole: PropTypes.func.isRequired,
  addNotification: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  previousLocation: state.app.previousLocation,
  auth: state.auth,
  users: state.users,
  roles: state.roles,
});
const mapDispatchToProps = {
  getUsers,
  getRoles,
  getRole,
  clearRole,
  addRole,
  updateRole,
  deleteRole,
  addNotification,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RolePage));
