import * as types from '../actions/actionTypes';

export const initialState = {
  loading: false,
  loaded: false,
  sites: [],
  zones: {
    loading: false,
    loaded: false,
    zones: [],
  },
};

export default function sites(state = initialState, action = {}) {
  switch (action.type) {
    case types.SITES_GET_SITES:
      return {
        ...state,
        sites: action.sites,
        loaded: true,
        loading: false,
      };
    case types.SITES_GETTING_SITES:
      return {
        ...state,
        loading: true,
        loaded: false,
      };

    case types.SITES_GETTING_ZONES_FOR_SITES:
      return {
        ...state,
        zones: {
          zones: [],
          loaded: false,
          loading: true,
        },
      };
    case types.SITES_GOT_ZONES_FOR_SITES:
      return {
        ...state,
        zones: {
          zones: action.zones,
          loaded: true,
          loading: false,
        },
      };
    default:
      return state;
  }
}
