import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ToolTipIcon from '../ToolTipIcon/ToolTipIcon';
import SSTable from '../SSTable/SSTable';
import SSColumn from '../SSTable/SSColumn';
import AssetImage from '../Assets/AssetImage';
import { getPermissions } from '../../utils/getPermissions';
import { launchCreator } from '../../utils/launchCreator';
import { buildTagList } from '../../utils/tagList';
import { getParent } from '../../utils/parent';

export default class SequenceEntriesTable extends Component {
  getPosition = (item, index) => <span className="circle circle-md">{index + 1}</span>;

  getPreview = item => <AssetImage type="sign" item={item.sign} />;

  getName = item => {
    const { auth } = this.props;
    const p = getPermissions(auth);

    return (
      <div className="entry-name">
        <div>
          {!p.atLeastTierStandard ? (
            item.sign.name
          ) : (
            <Link to={`/signs/${item.sign.id}`}>{item.sign.name}</Link>
          )}
        </div>
        <div>Folder: {item.sign.parent}</div>
        <div>Tags: {buildTagList(item.sign)}</div>
      </div>
    );
  };

  getDescription = item => <div className="entry-description">{item.sign.description}</div>;

  getDimensions = item => item.sign.dimension;

  getRepeat = (item, index) => {
    const { auth, onChangeRepeat } = this.props;
    const p = getPermissions(auth);

    if (!p.atLeastTierStandard) {
      return (
        <select value={-2} disabled>
          <option value={-2}>Indefinitely</option>
        </select>
      );
    }

    return (
      <select
        data-test="select-repeat"
        value={item.repeat}
        onChange={event => onChangeRepeat(event, index)}
        disabled={!p.writer}
      >
        <option value={1}>Don&apos;t loop</option>
        <option value={2}>2x</option>
        <option value={3}>3x</option>
        <option value={4}>4x</option>
        <option value={5}>5x</option>
        <option value={6}>6x</option>
        <option value={7}>7x</option>
        <option value={8}>8x</option>
        <option value={9}>9x</option>
        <option value={10}>10x</option>
        <option value={15}>15x</option>
        <option value={25}>25x</option>
        <option value={50}>50x</option>
        <option value={-2}>Indefinitely</option>
        <option value={-1}>Wait for Video</option>
        <option value={-3}>Loop until Touched</option>
      </select>
    );
  };

  getPlaylist = (item, index) => {
    const { auth, onClickPlaylist, onRemovePlaylist } = this.props;
    const {
      playlist: { id, name },
      sign: { hasVideo },
    } = item;
    const p = getPermissions(auth);

    if (!hasVideo) {
      return null;
    }

    return (
      <div className="playlist-actions">
        <select
          data-test="select-playlist"
          value={name}
          disabled={!p.writer}
          onChange={event => {
            const val = +event.target.value;

            if (val === 1) {
              onClickPlaylist(index);
            }

            if (val === 2) {
              onRemovePlaylist(index);
            }
          }}
        >
          <option>{name}</option>
          <option value="1">{name ? 'Change' : 'Add'} Playlist</option>
          {name && <option value="2">Remove Playlist</option>}
        </select>
        {id > -1 && (
          <ToolTipIcon toolText="Playlist Overview" href={`/playlists/${id}`} target="_blank">
            remove_red_eye
          </ToolTipIcon>
        )}
      </div>
    );
  };

  getPlayer = item => (
    <ToolTipIcon toolText="Preview Sign" href={`/player/?sign=${item.sign.id}`} target="_blank">
      play_circle_filled
    </ToolTipIcon>
  );

  getCreator = item => (
    <ToolTipIcon
      toolText="Open Sign in Creator"
      onClick={() => launchCreator(item.sign.id, getParent(this.props, item.sign.parent).id)}
    >
      <i className="fa fa-paint-brush" />
    </ToolTipIcon>
  );

  getRemove = (item, index) => {
    const { onRemoveEntry } = this.props;

    return (
      <ToolTipIcon toolText="Remove Sign" onClick={() => onRemoveEntry(index)}>
        remove_circle_outline
      </ToolTipIcon>
    );
  };

  render() {
    const { auth, data, onSort } = this.props;
    const entries = data ? data.filter(item => item.sign) : [];
    const p = getPermissions(auth);

    return (
      <SSTable
        className="responsive table-sequence"
        data={entries}
        headerHeight={50}
        rowHeight={50}
        rowsCount={entries.length - 1}
        onSort={onSort}
        selectable
        sortable={p.writer}
        zebra
      >
        <SSColumn cell={this.getPosition} />
        <SSColumn cell={this.getPreview} />
        <SSColumn header="Name" cell={this.getName} />
        <SSColumn header="Description" cell={this.getDescription} />
        <SSColumn header="Dimensions" cell={this.getDimensions} />
        <SSColumn header="Repeat" cell={this.getRepeat} />
        <SSColumn header="Playlist" cell={this.getPlaylist} />
        <SSColumn header="Preview" cell={this.getPlayer} />
        {p.atLeastTierStandard && p.writer && <SSColumn header="Creator" cell={this.getCreator} />}
        {p.writer && <SSColumn width={5} header="Remove" cell={this.getRemove} />}
      </SSTable>
    );
  }
}

SequenceEntriesTable.propTypes = {
  auth: PropTypes.object.isRequired,
  folders: PropTypes.object.isRequired,
  data: PropTypes.array,
  onSort: PropTypes.func.isRequired,
  onChangeRepeat: PropTypes.func.isRequired,
  onClickPlaylist: PropTypes.func.isRequired,
  onRemovePlaylist: PropTypes.func.isRequired,
  onRemoveEntry: PropTypes.func.isRequired,
};
