import 'isomorphic-fetch';
import * as types from './actionTypes';
import fetchCheck from '../redux/middleware/fetchCheck';

export const url = '/cms/data/v1/data-objects/';

export const credentials = 'include';

export const headers = { Accept: 'application/json', 'Content-Type': 'application/json' };

export const formHeaders = { Accept: 'application/json' };

let body;

export const select = ids => ({ type: types.DATA_OBJECTS_SELECT, ids });

export const unselectAll = () => ({ type: types.DATA_OBJECTS_UNSELECT_ALL });

export const request = () => ({ type: types.DATA_OBJECTS_REQUEST });

export const requestFailed = error => ({ type: types.DATA_OBJECTS_REQUEST_FAILED, error });

export const requestSuccessDataObjects = result => ({
  type: types.DATA_OBJECTS_REQUEST_SUCCESS_DATA_OBJECTS,
  result,
});

export const requestSuccessDataObject = result => ({
  type: types.DATA_OBJECTS_REQUEST_SUCCESS_DATA_OBJECT,
  result,
});

export const requestSuccessTier = result => ({
  type: types.DATA_OBJECTS_REQUEST_SUCCESS_TIER,
  result,
});

export const requestSuccessLocations = result => ({
  type: types.DATA_OBJECTS_REQUEST_SUCCESS_LOCATIONS,
  result,
});

export const requestSuccessInUse = result => ({
  type: types.DATA_OBJECTS_REQUEST_SUCCESS_IN_USE,
  result,
});

export const fetchDataObjects = () =>
  fetch(url, { method: 'GET', credentials, headers })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const fetchDataObject = id =>
  fetch(url + id, { method: 'GET', credentials, headers })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const fetchTier = (id, tierid) =>
  fetch(`${url + id}/tiers/${tierid}`, { method: 'GET', credentials, headers })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const fetchLocations = (id, tier_id) =>
  fetch(`${url + id}/locations?tier_id=${tier_id}`, { method: 'GET', credentials, headers })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const fetchInUse = () =>
  fetch(`${url}inuse`, { method: 'GET', credentials, headers })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const uploadCsv = file => {
  body = new FormData();
  body.append('file', file);

  return fetch(`${url}/upload`, { method: 'POST', credentials, headers: formHeaders, body })
    .then(res => fetchCheck(res))
    .then(res => res.json());
};

export const uploadDataObject = ({ name, tier, primarycolumn, file }) =>
  fetch(url, {
    method: 'POST',
    credentials,
    headers,
    body: JSON.stringify({ name, tier, primarycolumn, file }),
  })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const addDataObject = ({ name, tier, primarycolumn, columns }) =>
  fetch(url, {
    method: 'POST',
    credentials,
    headers,
    body: JSON.stringify({ name, tier, primarycolumn, columns: columns.join('|') }),
  })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const duplicateDataObject = (id, name) =>
  fetch(url, { method: 'POST', credentials, headers, body: JSON.stringify({ copyFrom: id, name }) })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const deleteDataObject = id =>
  fetch(url + id, { method: 'DELETE', credentials, headers })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const uploadTier = (id, name, file) => {
  body = new FormData();
  body.append('name', name);
  body.append('file', file);

  return fetch(`${url + id}/tiers/`, { method: 'POST', credentials, headers: formHeaders, body })
    .then(res => fetchCheck(res))
    .then(res => res.json());
};

export const updateTier = (id, tierid, file) => {
  body = new FormData();
  body.append('file', file);

  return fetch(`${url + id}/tiers/${tierid}`, {
    method: 'PUT',
    credentials,
    headers: formHeaders,
    body,
  })
    .then(res => fetchCheck(res))
    .then(res => res.json());
};

export const addTier = (id, name) =>
  fetch(`${url + id}/tiers/`, {
    method: 'POST',
    credentials,
    headers,
    body: JSON.stringify({ name }),
  })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const duplicateTier = (id, tierid, name) =>
  fetch(`${url + id}/tiers/`, {
    method: 'POST',
    credentials,
    headers,
    body: JSON.stringify({ tierid, name }),
  })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const deleteTier = (id, tierid) =>
  fetch(`${url + id}/tiers/${tierid}`, { method: 'DELETE', credentials, headers })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const addRow = (id, tierid, primarykey, columns, data) =>
  fetch(`${url + id}/tiers/${tierid}/data`, {
    method: 'POST',
    credentials,
    headers,
    body: JSON.stringify({ primarykey, headers: columns, data }),
  })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const updateRow = (id, tierid, rowid, primarykey, columns, data) =>
  fetch(`${url + id}/tiers/${tierid}/data`, {
    method: 'PUT',
    credentials,
    headers,
    body: JSON.stringify({ rowid, primarykey, headers: columns, data }),
  })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const deleteRow = (id, tierid, primarykey) =>
  fetch(`${url + id}/tiers/${tierid}/data`, {
    method: 'DELETE',
    credentials,
    headers,
    body: JSON.stringify({ primarykey }),
  })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const addLocation = (id, tier_id, location_id) =>
  fetch(`${url + id}/locations/`, {
    method: 'POST',
    credentials,
    headers,
    body: JSON.stringify({ tier_id, location_id }),
  })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const deleteLocation = (id, location_id) =>
  fetch(`${url + id}/locations/${location_id}`, { method: 'DELETE', credentials, headers })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const getDataObjects = () => dispatch => {
  dispatch(request());

  return fetchDataObjects().then(
    res => dispatch(requestSuccessDataObjects(res)),
    err => dispatch(requestFailed(err)),
  );
};

export const getDataObject = id => dispatch => {
  dispatch(request());

  return fetchDataObject(id).then(
    res => dispatch(requestSuccessDataObject(res)),
    err => dispatch(requestFailed(err)),
  );
};

export const getTier = (id, tierid) => dispatch => {
  dispatch(request());

  return fetchTier(id, tierid).then(
    res => dispatch(requestSuccessTier(res)),
    err => dispatch(requestFailed(err)),
  );
};

export const getLocations = (id, tierId) => dispatch => {
  dispatch(request());

  return fetchLocations(id, tierId).then(
    res => dispatch(requestSuccessLocations(res)),
    err => dispatch(requestFailed(err)),
  );
};

export const getInUse = () => dispatch => {
  dispatch(request());

  return fetchInUse().then(
    res => dispatch(requestSuccessInUse(res)),
    err => dispatch(requestFailed(err)),
  );
};
