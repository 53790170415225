import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';

export default class AddRowDialog extends Component {
  getInitialState = props => {
    const { tier } = props;
    const state = {};

    if (tier.headers) {
      tier.headers.forEach(name => {
        state[name] = '';
      });
    }

    return state;
  };

  state = this.getInitialState(this.props);

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { show } = nextProps;

    if (!show) {
      this.setState(this.getInitialState(nextProps));
    }
  }

  handleClickAdd = () => {
    const { onSuccess } = this.props;

    if (this.validate()) {
      onSuccess(this.state);
    }
  };

  validate = () => {
    const { tier, addNotification } = this.props;
    const { [tier.primary_key]: stateKey } = this.state;

    if (!stateKey.trim()) {
      addNotification({ type: 'danger', text: 'Type a unique Primary Key.' });

      return false;
    }

    if (!this.validateUniqueKey()) {
      addNotification({ type: 'danger', text: 'A Row with this Primary Key already exists.' });

      return false;
    }

    return true;
  };

  validateUniqueKey = () => {
    const {
      tier: { tier, primary_key },
    } = this.props;
    const { [primary_key]: stateKey } = this.state;

    return !tier.data.some(obj =>
      obj.row.columns.some(
        column =>
          column.name === primary_key &&
          column.value.toLowerCase().trim() === stateKey.toLowerCase().trim(),
      ),
    );
  };

  renderInput = (name, placeholder) => {
    const { [name]: stateName = '' } = this.state;

    return (
      <input
        className="form-control"
        placeholder={placeholder}
        value={stateName}
        onChange={event => this.setState({ [name]: event.target.value })}
      />
    );
  };

  renderInputs = () => {
    const { tier } = this.props;
    const fields = [];

    if (!tier.headers) {
      return null;
    }

    tier.headers.forEach((name, index) => {
      const key = name === tier.primary_key;
      const keyIcon = key ? <span className="fa fa-key key" title="Primary Key" /> : null;
      const placeholder = key ? 'Enter a unique value for the Primary Key' : 'Enter a value';
      const input = this.renderInput(name, placeholder);

      fields.push(
        <div key={index} className="form-group">
          <label>
            {keyIcon}
            {name}
          </label>
          {input}
        </div>,
      );
    });

    return fields;
  };

  renderCancelButton = () => {
    const { onHide } = this.props;

    return <Button onClick={onHide}>Cancel</Button>;
  };

  renderAddRowButton = () => (
    <Button bsStyle="primary" onClick={this.handleClickAdd}>
      Add Row
    </Button>
  );

  renderFooter = () => (
    <div>
      {this.renderCancelButton()}
      {this.renderAddRowButton()}
    </div>
  );

  render() {
    const { show, onHide } = this.props;
    const body = this.renderInputs();
    const footer = this.renderFooter();

    return (
      <Modal dialogClassName="data-object-dialog" backdrop="static" show={show} onHide={onHide}>
        <Modal.Header>
          <Modal.Title>Add Row</Modal.Title>
        </Modal.Header>
        <Modal.Body>{body}</Modal.Body>
        <Modal.Footer>{footer}</Modal.Footer>
      </Modal>
    );
  }
}

AddRowDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  tier: PropTypes.object.isRequired,
  addNotification: PropTypes.func.isRequired,
};
