import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Prompt } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { SOLR_DELAY } from '../../../../constants';
import QuickAction from '../../../components/QuickAction/QuickAction';
import Confirm from '../../../components/Confirm/Confirm';
import Spinner from '../../../components/Spinner/Spinner';
import AssetForm from '../../../components/Assets/AssetForm';
import Used from '../../../components/Used/Used';
import Header from '../../../components/Header/Header';
import LocationSelector from '../../../components/Locations/LocationSelector';
import ErrorPage from '../ErrorPage/ErrorPage';
import { trimFileExt, restoreFileExt } from '../../../utils/file';
import { getUsers } from '../../../actions/userActions';
import { loadFolders } from '../../../actions/folderActions';
import { getSigns } from '../../../actions/signActions';
import { loadSequences } from '../../../actions/sequenceActions';
import { loadSchedules } from '../../../actions/scheduleActions';
import { loadPlaylists } from '../../../actions/playlistActions';
import { loadCampaigns } from '../../../actions/campaignActions';
import { loadAllAssignments } from '../../../actions/assignmentActions';
import {
  getAsset,
  deleteAsset,
  updateAsset,
  editAssetForm,
  clearAssetForm,
} from '../../../actions/assetActions';
import { addNotification } from '../../../actions/notificationActions';
import ActionsMenu from '../../../components/ActionsMenu/ActionsMenu';
import errorMessage from '../../../utils/errorMessage';
import { getParent } from '../../../utils/parent';

export class AssetPage extends Component {
  state = {
    dirty: false,
    showFolderSelector: false,
  };

  componentDidMount = () => {
    this.loadData();
    document.addEventListener('visibilitychange', this.loadData);
  };

  componentWillUnmount = () => {
    const { clearAssetForm } = this.props;

    clearAssetForm();
    document.removeEventListener('visibilitychange', this.loadData);
  };

  goBack = () => {
    const { assets, history, previousLocation } = this.props;
    const url = previousLocation
      ? previousLocation.pathname + previousLocation.search
      : `/content?type=${assets.asset.type}&parent=/*`;

    this.setState({ dirty: false }, () => history.push(url));
  };

  loadData = () => {
    if (document.visibilityState === 'hidden') {
      return;
    }

    const {
      match: {
        params: { id },
      },
      loadFolders,
      getSigns,
      loadSequences,
      loadSchedules,
      loadPlaylists,
      loadCampaigns,
      loadAllAssignments,
      getUsers,
      getAsset,
    } = this.props;
    const { dirty } = this.state;

    loadFolders('?count=100000000');
    getSigns('?count=100000000');
    loadSequences('?count=100000000');
    loadSchedules('?count=100000000');
    loadPlaylists('?count=100000000');
    loadCampaigns('?count=100000000');
    loadAllAssignments();
    getUsers();

    if (!dirty) {
      getAsset(id);
    }
  };

  getEditedData = () => {
    const {
      assets: {
        asset,
        assetEdit: { tags, name, description, startDate, endDate, validity_enabled },
      },
    } = this.props;

    return {
      name: restoreFileExt(name, asset.name),
      tags: tags ? tags.sort() : null,
      description,
      parent: getParent(this.props, asset.parent).id,
      startDate: validity_enabled ? startDate : '20100101',
      endDate: validity_enabled ? endDate : '20991231',
    };
  };

  showFolderSelector = () => this.setState({ showFolderSelector: true });

  hideFolderSelector = () => this.setState({ showFolderSelector: false });

  onChange = (key, value) => {
    const { editAssetForm } = this.props;
    const { dirty } = this.state;

    editAssetForm(key, value);

    if (!dirty) {
      this.setState({ dirty: true });
    }
  };

  onDownload = () => {
    const {
      assets: {
        asset: { id, name },
      },
    } = this.props;
    const downloadLink = document.createElement('a');

    downloadLink.href = `/cms/data/v1/assets/${id}/download`;
    downloadLink.download = name;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);

    return downloadLink; // for test
  };

  onDelete = () => {
    const {
      assets: {
        asset: { type, usedInSigns, usedInPlaylists },
      },
    } = this.props;
    const confirm = this.refDelete;
    const usedIn = type === 'image' ? 'sign' : 'playlist';
    const assetType = type.charAt(0).toUpperCase() + type.slice(1);
    const title = `Delete ${assetType}`;
    const body =
      usedInSigns || usedInPlaylists ? (
        `This Asset is used in ${usedIn}s and cannot be deleted. Please remove it from the ${usedIn}s first.`
      ) : (
        <div>
          <p>Are you sure you want to delete this {assetType}?</p>
          <p>Deleting an asset may affect the look of any signage using the asset.</p>
        </div>
      );

    confirm.show(title, body);

    if (!usedInSigns && !usedInPlaylists) {
      confirm.onSuccess(this.delete);
    }

    return { title, body };
  };

  delete = () => {
    const {
      match: {
        params: { id },
      },
      addNotification,
    } = this.props;

    return deleteAsset(id).then(
      () => {
        addNotification({ type: 'success', text: 'Asset deleted.' });
        setTimeout(this.goBack, SOLR_DELAY);
      },
      err => addNotification({ type: 'danger', text: errorMessage(err) }),
    );
  };

  approve = () => {
    const {
      match: {
        params: { id },
      },
      getAsset,
      addNotification,
    } = this.props;
    const asset = this.getEditedData();

    asset.approved = true;

    return updateAsset(id, asset).then(
      () => {
        addNotification({ type: 'success', text: 'Asset approved.' });
        setTimeout(() => {
          getAsset(id);
        }, SOLR_DELAY);
      },
      err => addNotification({ type: 'danger', text: errorMessage(err) }),
    );
  };

  update = () => {
    const {
      match: {
        params: { id },
      },
      addNotification,
    } = this.props;
    const data = this.getEditedData();

    return updateAsset(id, data).then(
      () => {
        addNotification({ type: 'success', text: 'Asset updated.' });
        setTimeout(this.goBack, SOLR_DELAY);
      },
      err => addNotification({ type: 'danger', text: errorMessage(err) }),
    );
  };

  move = parent => {
    const {
      match: {
        params: { id },
      },
      assets,
      getAsset,
      addNotification,
    } = this.props;

    this.hideFolderSelector();

    return updateAsset(id, { ...assets.asset, parent: parent.id }).then(
      () => {
        addNotification({ type: 'success', text: 'Asset moved.' });
        getAsset(id);
      },
      err => addNotification({ type: 'danger', text: errorMessage(err) }),
    );
  };

  getHeaderActions = () => {
    const {
      auth: {
        user: {
          permissions: { Writer },
        },
      },
    } = this.props;
    const download = (
      <QuickAction key="1" toolText="Download Asset" onClick={this.onDownload} alwaysShow>
        file_download
      </QuickAction>
    );
    const move = (
      <QuickAction key="2" toolText="Move Asset" onClick={this.showFolderSelector} alwaysShow>
        subdirectory_arrow_right
      </QuickAction>
    );
    const remove = (
      <QuickAction key="3" toolText="Delete Asset" onClick={this.onDelete} alwaysShow>
        delete
      </QuickAction>
    );

    if (!Writer) {
      return [download];
    }

    return [download, move, remove];
  };

  getAssetPreview = () => {
    const {
      assets: {
        asset: { id, name, modifiedDate, type },
      },
    } = this.props;

    switch (type) {
      case 'image':
        return <img src={`/cms/data/v1/assets/${id}/data?${modifiedDate || ''}`} alt={name} />;
      case 'video':
        return <video src={`/cms/data/v1/assets/${id}/data`} controls />;
      default:
        return null;
    }
  };

  render() {
    const {
      auth,
      users: { users },
      folders: {
        folders: { folders },
      },
      assets: { asset, assetEdit, loadingAsset, error },
      signs: {
        signs: { signs },
      },
      sequences: {
        sequences: { sequences },
      },
      schedules: {
        schedules: { schedules },
      },
      playlists: {
        playlists: { playlists },
      },
      campaigns: {
        campaigns: { campaigns },
      },
      assignments: {
        allAssignments: { assignments },
      },
    } = this.props;
    const { dirty, showFolderSelector } = this.state;

    if (error) {
      return <ErrorPage error={error} />;
    }

    if (!assetEdit.id) {
      return null;
    }

    assetEdit.name = trimFileExt(assetEdit.name);
    const type = asset.type === 'video' ? 'video' : 'asset';

    return (
      <div className="page-structure">
        <Helmet title="Asset" />
        <Header overwrite={{ id: asset }} />
        <ActionsMenu actions={this.getHeaderActions()} selectedLength={0} />
        <div className="page-panels">
          <div className="page-content page-asset">
            <div className="preview">{this.getAssetPreview()}</div>
            <Spinner loading={loadingAsset}>
              <Prompt
                when={dirty}
                message="You have unsaved changes. Are you sure you want to leave this page?"
              />
              <AssetForm
                auth={auth}
                users={users}
                asset={assetEdit}
                onChange={this.onChange}
                onApprove={this.approve}
                onUpdate={this.update}
                onCancel={this.goBack}
              />
              <Used
                type={type}
                item={asset}
                signs={signs}
                sequences={sequences}
                schedules={schedules}
                playlists={playlists}
                campaigns={campaigns}
                assignments={assignments}
                auth={auth}
                users={users}
              />
            </Spinner>
          </div>
        </div>
        <Confirm
          ref={ref => {
            this.refDelete = ref;
          }}
        />
        <LocationSelector
          type="folder"
          show={showFolderSelector}
          onHide={this.hideFolderSelector}
          onSuccess={this.move}
          items={folders}
          currentPath={asset.parent}
        />
      </div>
    );
  }
}

AssetPage.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  previousLocation: PropTypes.object,
  users: PropTypes.object.isRequired,
  folders: PropTypes.object.isRequired,
  assets: PropTypes.object.isRequired,
  signs: PropTypes.object.isRequired,
  sequences: PropTypes.object.isRequired,
  schedules: PropTypes.object.isRequired,
  playlists: PropTypes.object.isRequired,
  campaigns: PropTypes.object.isRequired,
  assignments: PropTypes.object.isRequired,
  getUsers: PropTypes.func.isRequired,
  loadFolders: PropTypes.func.isRequired,
  getSigns: PropTypes.func.isRequired,
  loadSequences: PropTypes.func.isRequired,
  loadSchedules: PropTypes.func.isRequired,
  loadPlaylists: PropTypes.func.isRequired,
  loadCampaigns: PropTypes.func.isRequired,
  loadAllAssignments: PropTypes.func.isRequired,
  getAsset: PropTypes.func.isRequired,
  editAssetForm: PropTypes.func.isRequired,
  clearAssetForm: PropTypes.func.isRequired,
  addNotification: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  previousLocation: state.app.previousLocation,
  users: state.users,
  folders: state.folders,
  assets: state.assets,
  signs: state.signs,
  sequences: state.sequences,
  schedules: state.schedules,
  playlists: state.playlists,
  campaigns: state.campaigns,
  assignments: state.assignments,
});
const mapDispatchToProps = {
  loadFolders,
  getSigns,
  loadSequences,
  loadSchedules,
  loadPlaylists,
  loadCampaigns,
  loadAllAssignments,
  getAsset,
  getUsers,
  editAssetForm,
  clearAssetForm,
  addNotification,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AssetPage));
