import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';

export default class UploadTierDialog extends Component {
  getInitialState = () => ({
    file: null,
  });

  state = this.getInitialState();

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { show } = nextProps;

    if (!show) {
      this.setState(this.getInitialState());
    }
  }

  handleChange = event => this.setState({ file: event.target.files[0] });

  handleClick = () => {
    const { onSuccess } = this.props;
    const { file } = this.state;

    if (this.validate()) {
      onSuccess(file);
    }
  };

  validate = () => {
    const { addNotification } = this.props;
    const { file } = this.state;

    if (!file) {
      addNotification({ type: 'danger', text: 'Select a Data source.' });

      return false;
    }

    return true;
  };

  renderFileInput = () => (
    <div className="form-group">
      <label>CSV file</label>
      <input type="file" accept=".csv" onChange={this.handleChange} />
    </div>
  );

  renderCancelButton = () => {
    const { onHide } = this.props;

    return <Button onClick={onHide}>Cancel</Button>;
  };

  renderUploadFileButton = () => (
    <Button bsStyle="primary" onClick={this.handleClick}>
      Upload File
    </Button>
  );

  renderFooter = () => (
    <div>
      {this.renderCancelButton()}
      {this.renderUploadFileButton()}
    </div>
  );

  render() {
    const { show, onHide } = this.props;
    const body = this.renderFileInput();
    const footer = this.renderFooter();

    return (
      <Modal dialogClassName="data-object-dialog" backdrop="static" show={show} onHide={onHide}>
        <Modal.Header>
          <Modal.Title>Update Tier from CSV File</Modal.Title>
        </Modal.Header>
        <Modal.Body>{body}</Modal.Body>
        <Modal.Footer>{footer}</Modal.Footer>
      </Modal>
    );
  }
}

UploadTierDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  addNotification: PropTypes.func.isRequired,
};
