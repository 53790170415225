import 'isomorphic-fetch';
import fetchCheck from '../redux/middleware/fetchCheck';
import * as types from './actionTypes';

export const url = '/cms/data/v1/locations/';

export const credentials = 'include';

export const headers = { Accept: 'application/json', 'Content-Type': 'application/json' };

export const locationsRequest = () => ({ type: types.LOCATIONS_REQUEST });

export const locationsRequestSuccess = result => ({
  type: types.LOCATIONS_REQUEST_SUCCESS,
  result,
});

export const locationsRequestFailed = error => ({ type: types.LOCATIONS_REQUEST_FAILED, error });

export const allLocationsRequest = () => ({ type: types.ALL_LOCATIONS_REQUEST });

export const allLocationsRequestSuccess = result => ({
  type: types.ALL_LOCATIONS_REQUEST_SUCCESS,
  result,
});

export const allLocationsRequestFailed = error => ({
  type: types.ALL_LOCATIONS_REQUEST_FAILED,
  error,
});

export const locationRequest = () => ({ type: types.LOCATION_REQUEST });

export const locationRequestSuccess = result => ({
  type: types.LOCATION_REQUEST_SUCCESS,
  result,
});

export const locationRequestFailed = error => ({ type: types.LOCATION_REQUEST_FAILED, error });

export const clearLocationForm = () => ({ type: types.LOCATION_FORM_CLEAR });

export const locationsSelect = ids => ({ type: types.LOCATIONS_SELECT, ids });

export const locationsUnselectAll = () => ({ type: types.LOCATIONS_UNSELECT_ALL });

export const fetchAllLocations = () =>
  fetch(`${url}?count=100000000`, { method: 'GET', credentials, headers })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const fetchLocations = query => {
  let newQuery = query;

  if (!query) {
    newQuery = '?parent=/';
  } else if (!query.includes('parent=')) {
    newQuery += '&parent=/';
  }

  return fetch(url + newQuery, { method: 'GET', credentials, headers })
    .then(res => fetchCheck(res))
    .then(res => res.json());
};

export const fetchLocation = id =>
  fetch(url + id, { method: 'GET', credentials, headers })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const addLocation = location =>
  fetch(url, { method: 'POST', credentials, headers, body: JSON.stringify(location) }).then(res =>
    fetchCheck(res),
  );

export const updateLocation = (id, location) =>
  fetch(url + id, {
    method: 'PUT',
    credentials,
    headers,
    body: JSON.stringify(location),
  }).then(res => fetchCheck(res));

export const deleteLocation = id =>
  fetch(url + id, { method: 'DELETE', credentials, headers }).then(res => fetchCheck(res));

export const loadLocations = query => dispatch => {
  dispatch(locationsRequest());

  return fetchLocations(query).then(
    res => dispatch(locationsRequestSuccess(res)),
    err => dispatch(locationsRequestFailed(err)),
  );
};

export const loadAllLocations = () => dispatch => {
  dispatch(allLocationsRequest());

  return fetchAllLocations().then(
    res => dispatch(allLocationsRequestSuccess(res)),
    err => dispatch(allLocationsRequestFailed(err)),
  );
};

export const loadLocation = id => dispatch => {
  dispatch(locationRequest());

  return fetchLocation(id).then(
    res => dispatch(locationRequestSuccess(res)),
    err => dispatch(locationRequestFailed(err)),
  );
};
