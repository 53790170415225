import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import pluralize from 'pluralize';
import { SOLR_DELAY } from '../../../../constants';
import {
  loadLocations,
  addLocation,
  locationsSelect,
  locationsUnselectAll,
  deleteLocation,
  loadAllLocations,
  updateLocation,
} from '../../../actions/locationActions';
import { getUsers } from '../../../actions/userActions';
import { addNotification } from '../../../actions/notificationActions';
import Header from '../../../components/Header/Header';
import QuickAction from '../../../components/QuickAction/QuickAction';
import Filter from '../../Filter/Filter';
import Filters from '../../Filters/Filters';
import FiltersApplied from '../../FiltersApplied/FiltersApplied';
import Pagination from '../../Pagination/Pagination';
import PaginationInfo from '../../PaginationInfo/PaginationInfo';
import Spinner from '../../../components/Spinner/Spinner';
import LocationsTable from '../../../components/Locations/LocationsTable';
import Confirm from '../../../components/Confirm/Confirm';
import AddLocationDialog from '../../../components/Locations/AddLocationDialog';
import LocationSelector from '../../../components/Locations/LocationSelector';
import Prompt from '../../../components/Prompt/Prompt';
import ActionsMenu from '../../../components/ActionsMenu/ActionsMenu';
import ErrorPage from '../ErrorPage/ErrorPage';
import errorMessage from '../../../utils/errorMessage';
import { getParentPath } from '../../../utils/parent';
import qs from '../../../utils/qs';
import { checkPathAccess } from '../../../utils/checkPathAccess';
import { throttledNotify } from '../../../utils/notificationThrottler';

export class LocationsPage extends Component {
  state = {
    showAddLocationDialog: false,
    showLocationSelector: false,
  };

  componentDidMount() {
    this.loadData();
    document.addEventListener('visibilitychange', this.loadData);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      location: { search },
      locationsUnselectAll,
      loadLocations,
    } = this.props;
    const {
      location: { search: nextSearch },
    } = nextProps;

    if (search !== nextSearch) {
      locationsUnselectAll();
      loadLocations(nextSearch);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('visibilitychange', this.loadData);
  }

  loadData = () => {
    if (document.visibilityState === 'hidden') {
      return;
    }

    const {
      location: { search },
      getUsers,
      loadAllLocations,
      loadLocations,
      locationsUnselectAll,
    } = this.props;

    getUsers();
    loadAllLocations();
    loadLocations(search);
    locationsUnselectAll();
  };

  getSelectedLocations = (locationData, selected) => {
    const selectedArray = [];

    if (!selected) {
      return selectedArray;
    }

    Object.keys(selected).forEach(key => {
      if (locationData[key]) {
        selectedArray.push(locationData[key]);
      }
    });

    return selectedArray;
  };

  getSelectedChildLocations = (locationData, parent) => {
    const selectedArray = [];

    if (!parent) {
      return selectedArray;
    }

    for (let i = 0; i < locationData.length; i += 1) {
      if (locationData[i].parent.includes(parent)) {
        selectedArray.push(locationData[i]);
      }
    }

    return selectedArray;
  };

  handleSingleClick = rows => {
    const {
      addNotification,
      locations: {
        locations: { locations },
      },
      locationsSelect,
      auth: {
        user: {
          user: { userLocation },
        },
      },
    } = this.props;

    if (userLocation) {
      const restrictedLocations = [];
      const selectableRows = Object.keys(rows).reduce((selectableRows, key) => {
        const location = locations[key];
        const isAccessAllowed = checkPathAccess(userLocation, location);

        if (isAccessAllowed) {
          selectableRows[key] = true;
        } else {
          restrictedLocations.push(location.name);
        }

        return selectableRows;
      }, {});

      if (restrictedLocations.length) {
        throttledNotify(addNotification, {
          type: 'danger',
          text: `Restricted access assigning content to: ${restrictedLocations.join(', ')}`,
        });
      }

      locationsSelect(selectableRows);
    } else {
      locationsSelect(rows);
    }
  };

  showAddLocationDialog = () => this.setState({ showAddLocationDialog: true });

  hideAddLocationDialog = () => this.setState({ showAddLocationDialog: false });

  showLocationSelector = () => this.setState({ showLocationSelector: true });

  hideLocationSelector = () => this.setState({ showLocationSelector: false });

  handleClickDuplicateLocationStructure = () => {
    const prompt = this.refDuplicateLocationStructure;
    const title = 'Duplicate Location structure';
    const body = 'To help distinguish this Location from others, please give it a memorable name';

    prompt.show(title, body);
    prompt.onSuccess(name => this.completeDuplication(name));
  };

  completeDuplication = name => {
    const {
      location: { search },
      locations: {
        locations: { locations },
        allLocations: { locations: allLocations },
        selected,
      },
      locationsUnselectAll,
      loadAllLocations,
      loadLocations,
      addNotification,
    } = this.props;
    const duplicateParent = this.getSelectedLocations(locations, selected)[0];
    const oldParent = `${duplicateParent.parent + duplicateParent.name}/`;
    const duplicateChildren = this.getSelectedChildLocations(allLocations, oldParent);

    duplicateParent.name = name;

    addLocation(duplicateParent).then(
      () => {
        for (let i = 0; i < duplicateChildren.length; i += 1) {
          const newParent = `${duplicateParent.parent + name}/`;

          duplicateChildren[i].parent = duplicateChildren[i].parent.replace(oldParent, newParent);
          addLocation(duplicateChildren[i]);
        }

        addNotification({ type: 'success', text: 'Location duplicated.' });
        setTimeout(() => {
          locationsUnselectAll();
          loadAllLocations();
          loadLocations(search);
        }, SOLR_DELAY);
      },
      err => addNotification({ type: 'danger', text: errorMessage(err) }),
    );
  };

  moveLocations = location => {
    const {
      location: { search },
      locations: {
        locations: { locations },
        selected,
      },
      locationsUnselectAll,
      loadAllLocations,
      loadLocations,
      addNotification,
    } = this.props;

    this.hideLocationSelector();
    const parentPath = `${location.parent + location.name}/`;
    const successMessage = `${pluralize('Location', this.selectedLength(), true)} moved.`;

    Object.keys(selected).forEach(key =>
      updateLocation(locations[key].id, { ...locations[key], parent: parentPath }),
    );

    setTimeout(() => {
      locationsUnselectAll();
      loadAllLocations();
      loadLocations(search);
    }, SOLR_DELAY);
    addNotification({ type: 'success', text: successMessage });
  };

  showDeleteConfirmation = () => {
    const confirm = this.refDelete;
    const length = this.selectedLength();
    const title = `Delete ${pluralize('Location', length, true)}`;
    const body = (
      <div>
        <p>
          Are you sure you want to delete {pluralize('this', length)}{' '}
          {pluralize('Location', length)}?
        </p>
        <p>
          Note that the items in the deleted {pluralize('Location', length)} will be moved into the
          Location above.
        </p>
        <Alert bsStyle="warning">
          Deleting {pluralize('this', length)} {pluralize('Location', length)} will trigger a
          restart of the Devices within.
        </Alert>
      </div>
    );

    confirm.show(title, body);
    confirm.onSuccess(this.deleteLocations);
  };

  deleteLocations = () => {
    const {
      location: { search },
      locations: {
        locations: { locations },
        selected,
      },
      locationsUnselectAll,
      loadAllLocations,
      loadLocations,
      addNotification,
    } = this.props;
    const successMessage = `${pluralize('Location', this.selectedLength(), true)} deleted.`;

    Object.keys(selected).forEach(key => deleteLocation(locations[key].id));

    setTimeout(() => {
      locationsUnselectAll();
      loadAllLocations();
      loadLocations(search);
    }, SOLR_DELAY);
    addNotification({ type: 'success', text: successMessage });
  };

  addLocation = location => {
    const {
      location: { search },
      locationsUnselectAll,
      loadAllLocations,
      addNotification,
      loadLocations,
    } = this.props;

    this.hideAddLocationDialog();
    location.parent = getParentPath(this.props);
    addLocation(location).then(
      () => {
        addNotification({ type: 'success', text: 'Location created.' });
        setTimeout(() => {
          locationsUnselectAll();
          loadAllLocations();
          loadLocations(search);
        }, SOLR_DELAY);
      },
      err => addNotification({ type: 'danger', text: errorMessage(err) }),
    );
  };

  selectedLength = () => {
    const {
      locations: { selected },
    } = this.props;

    return Object.keys(selected).length;
  };

  renderHeaderActions = () => {
    const {
      auth: {
        user: {
          permissions: { Writer },
          user: { userLocation },
        },
      },
    } = this.props;

    if (!Writer) {
      return [];
    }

    const currentLocation = qs(this.props).parent;
    const isAccessAllowed = checkPathAccess(userLocation, currentLocation);
    const actions = [];

    if (isAccessAllowed) {
      const addToolText = 'Add Location';
      const Add = (
        <QuickAction
          key="add"
          toolText={addToolText}
          onClick={this.showAddLocationDialog}
          alwaysShow
        >
          add_circle
        </QuickAction>
      );

      actions.push(Add);
    }

    if (isAccessAllowed) {
      const moveToolText = pluralize('Move Location', this.selectedLength(), false);
      const Move = (
        <QuickAction
          key="move"
          toolText={moveToolText}
          onClick={this.showLocationSelector}
          singular
          multiple
        >
          subdirectory_arrow_right
        </QuickAction>
      );

      actions.push(Move);
    }

    if (isAccessAllowed) {
      const deleteToolText = pluralize('Delete Location', this.selectedLength(), false);
      const Delete = (
        <QuickAction
          key="4"
          toolText={deleteToolText}
          onClick={this.showDeleteConfirmation}
          singular
          multiple
        >
          delete
        </QuickAction>
      );

      actions.push(Delete);
    }

    if (isAccessAllowed) {
      const duplicateText = 'Duplicate Location Structure';
      const Duplicate = (
        <QuickAction
          key="duplicate"
          toolText={duplicateText}
          onClick={this.handleClickDuplicateLocationStructure}
          singular
        >
          content_copy
        </QuickAction>
      );

      actions.push(Duplicate);
    }

    if (userLocation) {
      const homeUrl = `/locations${`?parent=${encodeURIComponent(`${userLocation}`)}`}`;
      const homeShortcut = (
        <QuickAction key="home" toolText="Locations Home" href={homeUrl} alwaysShow>
          home
        </QuickAction>
      );

      actions.push(homeShortcut);
    }

    return actions;
  };

  renderFilters = () => {
    const {
      locations: {
        locations: { facet_fields },
      },
      users,
    } = this.props;

    if (!Object.keys(facet_fields).length) {
      return null;
    }

    const { tags, createdBy, modifiedBy } = facet_fields;

    return (
      <Filters>
        <Filter type="text" name="search" title="Search" placeholder="Search locations" />
        <Filter type="preset" preset="depth" name="folderDepth" title="Include sublocations" />
        <Filter
          type="checkbox"
          name="createdBy"
          title="Created by"
          data={createdBy}
          users={users}
        />
        <Filter type="preset" name="createdDate" title="Created date" preset="daterange" />
        <Filter
          type="checkbox"
          name="modifiedBy"
          title="Modified by"
          data={modifiedBy}
          users={users}
        />
        <Filter type="preset" name="modifiedDate" title="Modified date" preset="daterange" />
        <Filter type="tag" name="tags" title="Tags" data={tags} />
      </Filters>
    );
  };

  render() {
    const {
      auth: {
        user: { user },
      },
      users,
      location,
      locations: {
        locations: { locations, numFound },
        allLocations: { locations: allLocations },
        loadingLocations,
        loadingAllLocations,
        selected,
        error,
      },
    } = this.props;
    const { showAddLocationDialog, showLocationSelector } = this.state;
    const moving = this.getSelectedLocations(locations, selected);
    const loading = loadingLocations || loadingAllLocations;
    const parent = getParentPath(this.props);
    const userLocation = user.userLocation || '';

    if (error) {
      return <ErrorPage error={error} />;
    }

    return (
      <div className="page-structure">
        <Helmet title="Locations" />
        <Header />
        <ActionsMenu
          actions={this.renderHeaderActions()}
          selectedLength={this.selectedLength()}
          filterButton
        />
        <div className="page-panels">
          <div className="toolbar">
            <FiltersApplied users={users} />
            <PaginationInfo itemsFound={numFound} />
          </div>
          {this.renderFilters()}
          <div className="page-content page-locations">
            <Spinner className="locations-loader" loading={loading}>
              {locations && (
                <LocationsTable
                  data={locations}
                  location={location}
                  users={users}
                  selected={selected}
                  onClick={this.handleSingleClick}
                  userLocation={userLocation}
                />
              )}
            </Spinner>
            <Pagination itemsFound={numFound} />
          </div>
        </div>
        <AddLocationDialog
          show={showAddLocationDialog}
          onHide={this.hideAddLocationDialog}
          onSuccess={this.addLocation}
          locations={allLocations}
          parent={parent}
        />
        <LocationSelector
          show={showLocationSelector}
          onHide={this.hideLocationSelector}
          onSuccess={this.moveLocations}
          items={allLocations}
          moving={moving}
          userLocation={userLocation}
        />
        <Confirm
          ref={ref => {
            this.refDelete = ref;
          }}
        />
        <Prompt
          ref={ref => {
            this.refDuplicateLocationStructure = ref;
          }}
        />
      </div>
    );
  }
}

LocationsPage.propTypes = {
  auth: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  loadLocations: PropTypes.func.isRequired,
  loadAllLocations: PropTypes.func.isRequired,
  addNotification: PropTypes.func.isRequired,
  locations: PropTypes.object.isRequired,
  allLocations: PropTypes.array.isRequired,
  locationsSelect: PropTypes.func.isRequired,
  locationsUnselectAll: PropTypes.func.isRequired,
  getUsers: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  locations: state.locations,
  allLocations: state.locations.allLocations.locations,
  users: state.users.users,
  auth: state.auth,
});
const mapDispatchToProps = {
  loadLocations,
  loadAllLocations,
  locationsSelect,
  locationsUnselectAll,
  addNotification,
  getUsers,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LocationsPage));
