import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { removeNotification, removeAllNotifications } from '../../actions/notificationActions';

export class Notifications extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { notifications, removeNotification } = this.props;
    const { notifications: nextNotifications } = nextProps;

    nextNotifications.forEach(nextPropsItem => {
      if (!notifications.find(item => item.id === nextPropsItem.id)) {
        setTimeout(() => removeNotification(nextPropsItem), 3000);
      }
    });
  }

  componentWillUnmount() {
    const { removeAllNotifications } = this.props;

    removeAllNotifications();
  }

  renderNotifications = () => {
    const { notifications } = this.props;

    if (!notifications.length) {
      return null;
    }

    return notifications.map((item, index) => (
      <div key={index} data-test="notifications">
        <div className={`alert alert-${item.type}`}>{item.text}</div>
      </div>
    ));
  };

  render() {
    const notifications = this.renderNotifications();

    return (
      <div data-test="notifications" className="notifications">
        {notifications}
      </div>
    );
  }
}

Notifications.propTypes = {
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
      id: PropTypes.number.isRequired,
      timestamp: PropTypes.string.isRequired,
    }),
  ).isRequired,
  removeNotification: PropTypes.func.isRequired,
  removeAllNotifications: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  notifications: state.notifications.current,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      removeNotification,
      removeAllNotifications,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
