import clone from 'clone';
import * as types from '../actions/actionTypes';

export const initialState = {
  loading: false,
  error: null,
  selected: {},
  allDevices: {},
  devices: {},
  device: {
    device: {},
    edit: {},
    info: {},
    errors: [],
  },
  status: {},
  versions: [],
  copyConfig: null,
};

export default function devices(state = initialState, action = {}) {
  switch (action.type) {
    case types.DEVICES_SELECT:
      return {
        ...state,
        selected: action.ids,
      };
    case types.DEVICES_UNSELECT_ALL:
      return {
        ...state,
        selected: {},
      };
    case types.DEVICES_REQUEST:
      return {
        ...state,
        error: null,
      };
    case types.DEVICES_REQUEST_WITH_LOADING_STATUS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case types.DEVICES_REQUEST_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case types.DEVICES_REQUEST_SUCCESS_ALL_DEVICES:
      return {
        ...state,
        allDevices: action.result,
      };
    case types.DEVICES_REQUEST_SUCCESS_DEVICES:
      return {
        ...state,
        loading: false,
        devices: action.result,
      };
    case types.DEVICES_REQUEST_SUCCESS_DEVICE:
      return {
        ...state,
        loading: false,
        device: {
          ...state.device,
          device: action.result,
          edit: clone(action.result), // cannot reference to the original
        },
      };
    case types.DEVICES_REQUEST_SUCCESS_DEVICE_INFO:
      return {
        ...state,
        device: {
          ...state.device,
          info: action.result,
        },
      };
    case types.DEVICES_REQUEST_SUCCESS_DEVICE_ERRORS:
      return {
        ...state,
        device: {
          ...state.device,
          errors: action.result,
        },
      };
    case types.DEVICES_REQUEST_SUCCESS_STATUS:
      return {
        ...state,
        status: action.result,
      };
    case types.DEVICES_REQUEST_SUCCESS_VERSIONS:
      return {
        ...state,
        versions: action.result,
      };
    case types.DEVICES_SET_COPY_CONFIG:
      return {
        ...state,
        copyConfig: action.device,
      };
    case types.DEVICES_DEVICE_FORM_EDIT:
      return {
        ...state,
        device: {
          ...state.device,
          edit: {
            ...state.device.edit,
            [action.key]: action.value,
          },
        },
      };
    case types.DEVICES_DEVICE_FORM_CLEAR:
      return {
        ...state,
        device: {
          ...state.device,
          edit: clone(state.device.device),
        },
      };
    default:
      return state;
  }
}
