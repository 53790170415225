import getAssignmentsForPlaylist from './getAssignmentsForPlaylist';
import getPlaylistsForVideo from './getPlaylistsForVideo';

const getAssignmentsForVideo = (asset, signs, sequences, schedules, playlists, assignments) => {
  if (!asset || !signs || !sequences || !schedules || !playlists || !assignments) {
    return [];
  }

  const used = [];

  getPlaylistsForVideo(asset, playlists).forEach(playlist =>
    getAssignmentsForPlaylist(
      playlist,
      signs,
      sequences,
      schedules,
      playlists,
      assignments,
    ).forEach(assignment => {
      if (!used.find(item => item.id === assignment.id)) {
        used.push(assignment);
      }
    }),
  );

  return used.sort((a, b) => a.name.localeCompare(b.name));
};

export default getAssignmentsForVideo;
