import React, { Component } from 'react';
import PropTypes from 'prop-types';

/**
 * Avatar Component
 * -----------------------------------------------------
 * Creates an Avatar based on the user id.
 * If the user has uploaded a profile image it will use that
 * followed by the initials of the user if no image was set.
 *
 * user: This format is defined on the server, this component requires .avatar, .firstname, .lastname, .name
 */
export default class Avatar extends Component {
  generateChars = user => {
    const chars = [];

    if (user.firstname.trim().length === 0 && user.lastname.trim().length === 0) {
      chars[0] = user.name[0].toUpperCase();
    } else {
      if (user.firstname && user.firstname.length !== 0) {
        chars.push(user.firstname[0].toUpperCase());
      }

      if (user.lastname && user.lastname.length !== 0) {
        chars.push(user.lastname[0].toUpperCase());
      }
    }

    return chars;
  };

  generateInitials = (user, size) => {
    const intials = this.generateChars(user);
    const styles = { backgroundColor: '#DDDDDD', lineHeight: `${size}px` };

    return (
      <span className="avatar-initials" style={styles}>
        {intials.join('')}
      </span>
    );
  };

  userPhoto = user => {
    if (user.avatar) {
      return <img className="avatar-image" src={user.avatar} alt={user.name} />;
    }
  };

  render() {
    const { user, size: propsSize } = this.props;

    if (user) {
      if (!user.name) {
        return null;
      }
    }

    const size = propsSize || 40;
    const fontSize = size / 2 - size * 0.15;
    const styles = { width: `${size}px`, height: `${size}px`, fontSize };

    return (
      <span data-test="avatar" className="avatar circle" style={styles}>
        {this.userPhoto(user)}
        {this.generateInitials(user, size)}
      </span>
    );
  }
}

Avatar.propTypes = {
  user: PropTypes.object.isRequired,
  size: PropTypes.number,
  colorNumber: PropTypes.number,
};
