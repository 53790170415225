import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import qs from '../../utils/qs';
import { getPermissions } from '../../utils/getPermissions';
import { toggleNav } from '../../utils/navigationToggle';

const getTitle = (path, name) => {
  switch (path) {
    case 'assets/:id':
      return `Asset: ${name}`;
    case 'campaigns/:id/assignments':
      return 'Assignments';
    case 'campaigns/new':
      return 'New Campaign';
    case 'campaigns/:id':
      return `Campaign: ${name}`;
    case 'campaign-schedule':
      return 'Campaign Schedule';
    case 'content':
      return 'Content';
    case 'dashboard':
      return 'Dashboard';
    case 'data-objects/:id/:tierid/locations':
      return 'Data Object Tier Locations';
    case 'data-objects/:id/:tierid':
      return `Data Object Tier: ${name}`;
    case 'data-objects/:id':
      return `Data Object: ${name}`;
    case 'data-objects':
      return 'Data Objects';
    case 'devices/:id':
      return `Device: ${name}`;
    case 'devices':
      return 'Devices';
    case 'folders/:id':
      return `Folder: ${name}`;
    case 'fonts/:id':
      return `Font: ${name}`;
    case 'fonts':
      return 'Fonts';
    case 'home':
      return 'Home';
    case 'locations/:id':
      return `Location: ${name}`;
    case 'locations':
      return 'Locations';
    case 'playlists/new':
      return 'New Playlist';
    case 'playlists/:id':
      return `Playlist: ${name}`;
    case 'roles/new':
      return 'New Role';
    case 'roles/:id':
      return `Role: ${name}`;
    case 'roles':
      return 'Roles';
    case 'schedules/new':
      return 'New Schedule';
    case 'schedules/:id':
      return `Schedule: ${name}`;
    case 'sequences/new':
      return 'New Sequence';
    case 'sequences/:id':
      return `Sequence: ${name}`;
    case 'signs/:id':
      return `Sign: ${name}`;
    case 'url-rulesets/:id':
      return `URL Ruleset: ${name}`;
    case 'url-rulesets':
      return 'URL Rulesets';
    case 'user':
      return 'My Account';
    case 'users/new':
      return 'New User';
    case 'users/:id':
      return `User: ${name}`;
    case 'users':
      return 'Users';
    default:
      return name;
  }
};

const getOverwrite = (overwrite, param = 'id') => (overwrite && overwrite[param]) || {};

const getBreadcrumbName = (path, params, overwrite) => {
  const param = path.split('/').pop().split(':')[1];
  const { name } = getOverwrite(overwrite, param);

  return param ? name || params[param] : getTitle(path);
};

const getRoutes = props => {
  // build the routes by using the parent path, parent can come from 3 different sources:
  // 1) location.search ('?parent=/A/B/')
  // 2) match.url ('/data-objects/1/2')
  // 3) overwrite ({ id: { ..., parent: '/A/B/' } })
  const {
    match: { params, path, url },
    overwrite,
  } = props;
  const pathArr = path.split('/').slice(1); // '/a/b/:id/:id2' -> ['a','b',':id',':id2']
  const urlArr = url.toLowerCase().split('/').slice(1); // '/A/B/1/2' -> ['a','b', '1','2']
  const extraArr = (getOverwrite(overwrite).parent || '').split('/').slice(1, -1); // {...,parent:'/A/B/'} -> ['A','B']
  const parentArr = (qs(props).parent || '').split('/').slice(1, -1); // '?parent=/A/B/' -> ['A','B']
  const contentPaths = [
    'assets',
    'campaigns',
    'playlists',
    'schedules',
    'sequences',
    'signs',
    'folders',
  ];
  const newContent = contentPaths.includes(pathArr[0]) && path.endsWith('/new');
  const paths = [];
  const parents = [];

  // 1) build routes from location.search. Example:
  // url: '/content?parent=/A/B/'
  // -> [{url:'/content?parent=/A/',name:'A'},{url:'/content?parent=/A/B/',name:'B'}]
  // final array that returns:
  // [{url:'/content',name:'Content'},{url:'/content?parent=/A/',name:'A'},{url:'/content?parent=/A/B/',name:'B'}]
  while (parentArr.length) {
    parents.unshift({
      url: `${newContent ? '/content' : url.toLowerCase()}?parent=${encodeURIComponent(
        `/${parentArr.join('/')}/`,
      )}`,
      name: parentArr.pop(),
    });
  }

  // 2) build routes from match.url. Example:
  // url: '/data-objects/1/2'
  // overwrite: { id: { name: 'A' }, tierid: { name: 'B' } }
  // -> [{url:'/data-objects',name:'Data Objects'},{url:'/data-objects/1',name:'A'},{url:'/data-objects/1/2',name:'B'}]
  while (pathArr.length) {
    paths.unshift({
      url: `/${urlArr.join('/')}`,
      name: getBreadcrumbName(pathArr.join('/'), params, overwrite),
    });
    pathArr.pop();
    urlArr.pop();
  }

  // 3) build routes from overwrite. Example:
  // url: '/signs/1'
  // overwrite: { id: { name: 'Sign 1', parent: '/A/' } }
  // -> [{url:'/content',name:'Content'},{url:'/content?parent=/A/',name:'A'},{url:'/signs/1',name:'Sign 1'}]
  const isContentPath = contentPaths.includes(paths[0].url.slice(1));
  const isLocationPath = paths[0].url === '/locations';

  if (isContentPath || isLocationPath) {
    paths.shift();
    const extraPath = isContentPath
      ? { url: '/content', name: 'Content' }
      : { url: '/locations', name: 'Locations' };

    while (extraArr.length) {
      paths.unshift({
        url: `${extraPath.url}?parent=${encodeURIComponent(`/${extraArr.join('/')}/`)}`,
        name: extraArr.pop(),
      });
    }

    paths.unshift({ url: extraPath.url, name: extraPath.name });
  }

  // handle '*/new' routes ('/sequences/new?parent=/A/B/': Content > A > B > New Sequence)
  if (path.endsWith('/new')) {
    return [paths[0], ...parents, paths[1]];
  }

  return paths.concat(parents);
};

const getBreadcrumbs = (props, routes) => {
  const { auth } = props;
  const p = getPermissions(auth);
  const isLiteTier = !p?.atLeastTierStandard;

  return routes.map(({ url, name }, index) => {
    const isLink = index < routes.length - 1;
    // Campaign breadcrumb is available on the Assignment page but should be disabled on Lite tier
    const isDisabled = isLiteTier && url.startsWith('/campaigns') && index === 1;

    if (isDisabled) {
      return (
        <span data-test={`breadcrumb-${index + 1}`} className="a" key={index}>
          {name}
        </span>
      );
    }

    if (isLink) {
      return (
        <Link data-test={`breadcrumb-${index + 1}`} key={index} to={url}>
          {name}
        </Link>
      );
    }

    return (
      <span data-test={`breadcrumb-${index + 1}`} key={index}>
        {name}
      </span>
    );
  });
};

export const Header = props => {
  const {
    match: { path },
  } = props;
  const routes = getRoutes(props);
  const breadcrumbs = getBreadcrumbs(props, routes);
  const title = getTitle(path.slice(1), routes[routes.length - 1].name);

  return (
    <header>
      <Button className="btn-minimal material-icons navbar-toggle" onClick={toggleNav}>
        menu
      </Button>
      <div className="breadcrumb-nav">
        <div data-test="page-title" className="page-title">
          {title}
        </div>
        <div data-test="breadcrumbs" className="breadcrumbs">
          {breadcrumbs}
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  auth: PropTypes.object,
  match: PropTypes.object.isRequired,
  overwrite: PropTypes.object,
};

export default withRouter(Header);
