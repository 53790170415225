import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

const EMPTY = '';
const NO_FILTER = 'No Filter';
const ERROR_FILTER = 'Errors';
const MODIFIED_FILTER = 'New Content';
const UNCHANGED_FILTER = 'Unchanged';
const nextOutcomeFilters = {
  [NO_FILTER]: ERROR_FILTER,
  [ERROR_FILTER]: MODIFIED_FILTER,
  [MODIFIED_FILTER]: UNCHANGED_FILTER,
  [UNCHANGED_FILTER]: NO_FILTER,
};

const DeployReport = ({ data: { isReportMode, err, devices } }) => {
  const [outcomeFilters, updateOutcomeFilter] = useState({
    current: NO_FILTER,
    next: ERROR_FILTER,
  });
  const [textFilter, updateTextFilter] = useState(EMPTY);

  const nextFilter = () => {
    const current = outcomeFilters.next;
    const next = nextOutcomeFilters[current];

    updateOutcomeFilter({
      current,
      next,
    });
  };

  const filteredDevices = devices
    .filter(device => {
      const { current } = outcomeFilters;
      let include = false;

      if (current === NO_FILTER) {
        include = true;
      }

      if (current === ERROR_FILTER && device.manifestError) {
        include = true;
      }

      if (current === MODIFIED_FILTER && device.modified) {
        include = true;
      }

      if (current === UNCHANGED_FILTER && !device.modified) {
        include = true;
      }

      return include;
    })
    .filter(({ location, description }) => {
      if (!textFilter) {
        return true;
      }

      return (
        location.toLowerCase().includes(textFilter) ||
        description.toLowerCase().includes(textFilter)
      );
    });

  const handleTextFilter = e => {
    const {
      target: { value = '' },
    } = e;

    updateTextFilter(value.toLowerCase());
  };

  return (
    <div>
      {isReportMode && (
        <Alert bsStyle="info">
          <p data-test="alert-report-mode">Test Run Mode - content not deployed.</p>
        </Alert>
      )}
      {err && (
        <Alert bsStyle="danger">
          <p data-test="alert-report-mode">Deploy encountered an error.</p>
          <p>{err.toString()}</p>
        </Alert>
      )}
      {devices && devices.length ? (
        <div className="devices">
          <Button
            data-test="deploy-device-filter"
            className="btn btn-primary change-filter"
            onClick={nextFilter}
            bsSize="xsmall"
          >
            Filter by: {outcomeFilters.next}
          </Button>
          <div className="text-filter">
            <input
              data-test="deploy-device-search"
              value={textFilter}
              placeholder="Search"
              onChange={handleTextFilter}
            />
          </div>
          <div data-test="deploy-device-summary" className="outcome-filter">
            Showing:{' '}
            <span className="current-filter">
              {outcomeFilters.current === NO_FILTER ? 'All' : outcomeFilters.current}
            </span>
          </div>
          <div className="device">
            <div className="col">
              <strong>Location</strong>
            </div>
            <div className="col">
              <strong>Device</strong>
            </div>
            <div className="detail">Outcome</div>
          </div>
          <ul data-test="deploy-device-list" className="dialog-list">
            {!filteredDevices.length && <div>No {outcomeFilters.current} - change filter.</div>}
            {filteredDevices.map(device => {
              const { device_id, modified, manifestError, location, description } = device;
              let tooltip = modified ? 'New Content' : 'Content was already up to date';

              tooltip = manifestError ? manifestError.toString() : tooltip;

              return (
                <li data-test={`deploy-device-${device_id}`} key={device_id} className="striped">
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip id="tooltip-deploy-result">{tooltip}</Tooltip>}
                  >
                    <div
                      // eslint-disable-next-line no-nested-ternary
                      className={`device${manifestError ? ' error' : modified ? ' success' : ''}`}
                    >
                      <div className="col col1">{location}</div>
                      <div className="col">{description}</div>
                      <div className="detail">
                        {modified && !manifestError && (
                          <i
                            data-test="device-modified"
                            className="fa fa-check modified"
                            aria-hidden="true"
                          />
                        )}
                        {manifestError && (
                          <i
                            data-test="device-modified"
                            className="fa fa-times error"
                            aria-hidden="true"
                          />
                        )}
                        {!modified && !manifestError && <span data-test="device-unchanged">-</span>}
                      </div>
                    </div>
                  </OverlayTrigger>
                </li>
              );
            })}
          </ul>
        </div>
      ) : (
        <div>No devices to deploy to.</div>
      )}
    </div>
  );
};

DeployReport.propTypes = {
  data: PropTypes.shape({
    isReportMode: PropTypes.bool,
    err: PropTypes.oneOf([PropTypes.object, PropTypes.string]),
    devices: PropTypes.arrayOf(
      PropTypes.shape({
        device_id: PropTypes.string.isRequired,
        location: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        modified: PropTypes.bool.isRequired,
        manifestError: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      }),
    ),
  }).isRequired,
};

export default DeployReport;
