import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Pagination } from 'react-bootstrap';
import qs from '../../utils/qs';
import { addUniqueQueryParam, removeQueryParam } from '../../utils/updateQueryString';

export const DEFAULT_ITEMS_PER_PAGE = 25;

export const DEFAULT_OFFSET = 0;

export class PaginationPanel extends Component {
  getItemsPerPage = props => {
    const { count } = qs(props);

    return count ? +count : DEFAULT_ITEMS_PER_PAGE;
  };

  getOffset = props => {
    const { offset } = qs(props);

    return offset ? +offset : DEFAULT_OFFSET;
  };

  state = {
    itemsPerPage: this.getItemsPerPage(this.props),
    offset: this.getOffset(this.props),
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      itemsPerPage: this.getItemsPerPage(nextProps),
      offset: this.getOffset(nextProps),
    });
  }

  getActivePage = () => {
    const { itemsPerPage, offset } = this.state;

    return Math.floor(offset / itemsPerPage) + 1;
  };

  getPagesTotal = () => {
    const { itemsFound } = this.props;
    const { itemsPerPage } = this.state;

    return Math.ceil((itemsFound || 0) / itemsPerPage);
  };

  getPageNumberForClick = event => {
    const { innerText } = event.target;
    const total = this.getPagesTotal();
    const active = this.getActivePage();

    switch (innerText) {
      case '‹':
        return Math.max(active - 1, 1);
      case '›':
        return Math.min(active + 1, total);
      default:
        return Number(innerText) || active;
    }
  };

  getPagination = () => {
    const total = this.getPagesTotal();
    const active = this.getActivePage();
    const boundary = 2;
    const totalBtns = boundary * 2 + 3;
    const ellipsis1 = totalBtns < total && active - boundary > 1;
    const ellipsis2 = totalBtns < total && active + boundary < total;
    let btnFirst = ellipsis1 ? active - boundary : 1;
    let btnLast = ellipsis2 ? active + boundary : total;

    if (ellipsis1 && !ellipsis2) {
      btnFirst = total - boundary * 2;
    }

    if (!ellipsis1 && ellipsis2) {
      btnLast = boundary * 2 + 1;
    }

    const items = [];

    for (let index = btnFirst; index <= btnLast; index += 1) {
      items.push(
        <Pagination.Item
          data-test={`pagination-item-${index}`}
          key={index}
          active={index === active}
        >
          {index}
        </Pagination.Item>,
      );
    }

    if (ellipsis1) {
      items.unshift([
        <Pagination.Item key={1}>{1}</Pagination.Item>,
        <Pagination.Ellipsis key="el1" disabled />,
      ]);
    }

    if (ellipsis2) {
      items.push([
        <Pagination.Ellipsis key="el2" disabled />,
        <Pagination.Item key={total}>{total}</Pagination.Item>,
      ]);
    }

    items.unshift([
      <Pagination.Prev data-test="pagination-item-prev" key="prev" disabled={active === 1} />,
    ]);
    items.push([
      <Pagination.Next data-test="pagination-item-next" key="next" disabled={active === total} />,
    ]);

    return <Pagination onClick={this.onClick}>{items}</Pagination>;
  };

  onSelect = event => {
    const { history, location } = this.props;
    const { count } = qs(this.props);
    const itemsPerPage = +event.target.value;

    if (itemsPerPage === DEFAULT_ITEMS_PER_PAGE) {
      removeQueryParam(history, location, 'count', count);
    } else {
      addUniqueQueryParam(history, location, 'count', itemsPerPage);
      // reset the offset
      this.setState({ offset: DEFAULT_OFFSET });
    }

    this.setState({ itemsPerPage });
  };

  onClick = event => {
    const { history, location } = this.props;
    const { offset, itemsPerPage } = this.state;
    const number = this.getPageNumberForClick(event);
    const active = this.getActivePage();

    if (number === active) {
      return;
    }

    const newOffset = (number - 1) * itemsPerPage;

    if (newOffset === DEFAULT_OFFSET) {
      removeQueryParam(history, location, 'offset', offset);
    } else {
      addUniqueQueryParam(history, location, 'offset', newOffset);
    }

    this.setState({ offset: newOffset });
  };

  render() {
    const { itemsPerPage } = this.state;
    const total = this.getPagesTotal();

    if (!total) {
      return null;
    }

    return (
      <div className="pagination-panel">
        <div>
          {this.getPagination()}
          <div className="pagination-select">
            <span>Show </span>
            <select data-test="select-pagination" value={itemsPerPage} onChange={this.onSelect}>
              <option>25</option>
              <option>50</option>
              <option>100</option>
            </select>
            <span> entries</span>
          </div>
        </div>
      </div>
    );
  }
}

PaginationPanel.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  itemsFound: PropTypes.number,
};

export default withRouter(PaginationPanel);
