import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class Pill extends Component {
  handleClick = event => {
    const { disabled, handler } = this.props;

    if (!disabled && handler) {
      handler(event, this.props);
    }
  };

  render() {
    const { children, testId, tooltip, disabled } = this.props;

    if (!children) {
      return null;
    }

    return (
      <div className="pill" aria-describedby={tooltip}>
        <div title={tooltip}>{children}</div>
        {!disabled && (
          <div
            data-test={`pill-${(testId || children).replace(/[^a-z0-9]/gi, '')}`}
            className="action material-icons"
            onClick={this.handleClick}
          >
            close
          </div>
        )}
      </div>
    );
  }
}

Pill.propTypes = {
  testId: PropTypes.string,
  tooltip: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  handler: PropTypes.func,
};
