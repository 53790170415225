import * as types from '../actions/actionTypes';

export const initialState = {
  loading: null,
  error: null,
  selected: {},
  fonts: [],
  font: {},
};

export default function fonts(state = initialState, action = {}) {
  switch (action.type) {
    case types.FONTS_SELECT:
      return {
        ...state,
        selected: action.ids,
      };
    case types.FONTS_UNSELECT_ALL:
      return {
        ...state,
        selected: {},
      };
    case types.FONTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case types.FONTS_REQUEST_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case types.FONTS_REQUEST_SUCCESS_FONTS:
      return {
        ...state,
        loading: false,
        fonts: action.result,
      };
    case types.FONTS_REQUEST_SUCCESS_FONT:
      return {
        ...state,
        loading: false,
        font: action.result,
      };
    default:
      return state;
  }
}
