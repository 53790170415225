import * as types from './actionTypes';

export const addNotification = item => ({
  type: types.ADD_NOTIFICATION,
  item: { ...item, id: Math.floor(Math.random() * Number.MAX_SAFE_INTEGER) },
});

export const removeNotification = item => ({ type: types.REMOVE_NOTIFICATION, item });

export const removeAllNotifications = () => ({ type: types.REMOVE_ALL_NOTIFICATIONS });
