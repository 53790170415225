import * as types from '../actions/actionTypes';

export const initialState = {
  isOpen: true,
};

export default function navigation(state = initialState, action = {}) {
  switch (action.type) {
    case types.NAV_CLOSE_NAVIGATION:
      return {
        ...state,
        isOpen: false,
      };
    case types.NAV_OPEN_NAVIGATION:
      return {
        ...state,
        isOpen: true,
      };
    default:
      return state;
  }
}
