import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, FormControl, Modal } from 'react-bootstrap';
import QRCode from 'qrcode';
import { mfaLogin, mfaValidate, mfaEnable, mfaDisable } from '../../actions/mfaActions';
import Confirm from '../Confirm/Confirm';

export default class MfaModal extends Component {
  getInitialState = props => {
    const { credentials, verified } = props || this.props;
    const onlyDoValidationStep = credentials && verified;
    const step = onlyDoValidationStep ? 3 : 1;

    return {
      onlyDoValidationStep,
      step,
      error: '',
      token: '',
      qr: '',
      user: null,
    };
  };

  state = this.getInitialState();

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { show } = this.props;

    if (show !== nextProps.show) {
      this.setState(this.getInitialState(nextProps));
    }
  }

  getQrFromSecret = ({ secret }) => QRCode.toDataURL(`otpauth://totp/SecretKey?secret=${secret}`);

  onChangeToken = event => this.setState({ error: '', token: event.target.value });

  onEnable = () =>
    mfaEnable(this.props.credentials)
      .then(this.getQrFromSecret)
      .then(qr => this.setState({ qr }, this.nextStep));

  onDisable = () => {
    const { clientMfa, userId, onUpdate } = this.props;
    const confirm = this.refDisable;
    const forced = clientMfa === 2;
    let text = 'Are you sure you want to disable 2FA?';

    if (forced) {
      text += `${
        userId ? ' The user' : ' You'
      } will need to re-setup the authenticator on the next login.`;
    }

    confirm.show('Disable 2FA', text);
    confirm.onSuccess(() => mfaDisable(userId).then(onUpdate));
  };

  onSuccess = () => this.props.onSuccess(this.state.user);

  prevStep = () => this.setState({ error: '', token: '', step: this.state.step - 1 });

  nextStep = async () => {
    const { credentials, onUpdate, onSuccess } = this.props;
    const { onlyDoValidationStep, step, token } = this.state;

    if (step === 3) {
      // use /mfa/login API on Login page and /mfa/validate API on User page
      // if we have credentials we are on the Login page
      const loginOrValidate = credentials ? mfaLogin : mfaValidate;
      let res;

      try {
        res = await loginOrValidate({ ...credentials, token });
      } catch (e) {
        return this.setState({ error: 'Validation failed.' });
      }

      const { user } = res;

      this.setState({ user });

      if (onUpdate) {
        onUpdate();
      }

      if (onlyDoValidationStep) {
        return onSuccess(user);
      }
    }

    this.setState({ error: '', token: '', step: step + 1 });
  };

  step1 = () => {
    const { verified, clientMfa } = this.props;
    const forced = clientMfa === 2;

    return (
      <div data-test="modal-mfa-step-1">
        {forced && <Alert bsStyle="warning">2FA for this client is mandatory.</Alert>}
        {Boolean(verified) && <p>2FA is currently enabled.</p>}
        {!verified && (
          <p>
            {forced ? 'Please' : 'You can'} set up an app like Google Authenticator on your mobile
            to generate a login code.
          </p>
        )}
      </div>
    );
  };

  step2 = () => (
    <div>
      <div className="text-center">
        <img data-test="modal-mfa-qr" src={this.state.qr} alt="QR" />
      </div>
      <p>Please use your authentication app (such as Google Authenticator) to scan this QR code.</p>
    </div>
  );

  step3 = () => (
    <div>
      <p>Please enter the 6 digit code you see on your authentication app.</p>
      <FormControl
        data-test="modal-mfa-input-token"
        placeholder="Enter the authenticaton token"
        value={this.state.token}
        onChange={this.onChangeToken}
        autoFocus
      />
    </div>
  );

  step4 = () => (
    <div data-test="modal-mfa-step-4">
      <p>Two-factor authentication is now set up.</p>
      <p>We will now ask for a login code any time you log in.</p>
    </div>
  );

  getStepContent = () => this[`step${this.state.step}`]();

  getStepButtons = () => {
    const { verified, userId, onHide } = this.props;
    const { onlyDoValidationStep, step } = this.state;
    const enable = (
      <Button
        key={1}
        data-test="mfa-btn-enable"
        className="btn-primary"
        onClick={this.onEnable}
        disabled={Boolean(userId)}
      >
        Enable 2FA
      </Button>
    );
    const disable = (
      <Button key={2} data-test="mfa-btn-disable" className="btn-primary" onClick={this.onDisable}>
        Disable 2FA
      </Button>
    );
    const cancel = (
      <Button key={3} data-test="mfa-btn-cancel" onClick={onHide}>
        Cancel
      </Button>
    );
    const prev = (
      <Button key={4} data-test="mfa-btn-prev" onClick={this.prevStep}>
        Prev
      </Button>
    );
    const next = (
      <Button key={5} data-test="mfa-btn-next" className="btn-primary" onClick={this.nextStep}>
        Next
      </Button>
    );
    const done = (
      <Button key={6} data-test="mfa-btn-ok" className="btn-primary" onClick={this.onSuccess}>
        Finish
      </Button>
    );

    switch (step) {
      case 1:
        return [cancel, verified ? disable : enable];
      case 2:
        return [prev, next];
      case 3:
        return [onlyDoValidationStep ? cancel : prev, next];
      case 4:
        return [done];
      default:
    }
  };

  render() {
    const { show, onHide } = this.props;
    const { error } = this.state;

    return (
      <Modal dialogClassName="modal-mfa" backdrop="static" show={show} onHide={onHide}>
        <Modal.Header>
          <Modal.Title data-test="modal-mfa-title">Two-factor authentication</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.getStepContent()}
          <p data-test="modal-mfa-error" className="text-danger">
            {error}
          </p>
          <Confirm
            ref={ref => {
              this.refDisable = ref;
            }}
          />
        </Modal.Body>
        <Modal.Footer>{this.getStepButtons()}</Modal.Footer>
      </Modal>
    );
  }
}

MfaModal.propTypes = {
  show: PropTypes.bool.isRequired,
  credentials: PropTypes.object,
  clientMfa: PropTypes.number,
  verified: PropTypes.number,
  userId: PropTypes.number,
  onHide: PropTypes.func.isRequired,
  onUpdate: PropTypes.func,
  onSuccess: PropTypes.func.isRequired,
};

MfaModal.defaultProps = {
  clientMfa: 0,
  verified: 0,
  userId: 0,
};
