import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Checkbox, Panel, Nav, NavItem, Badge } from 'react-bootstrap';
import PlaylistFilesTable from './PlaylistFilesTable';
import MultiColumnSelect from '../MultiColumnSelect/MultiColumnSelect';
import getAssignmentsForPlaylist from '../Used/utils/getAssignmentsForPlaylist';
import UsedInAssignmentsTable from '../Used/UsedInAssignmentsTable';
import { buildTagList } from '../../utils/tagList';

export default class TagControlledPlaylistPanel extends Component {
  state = {
    open: true,
    selectedTab: '1',
    selectedTags: [],
    selectedDeviceId: '0',
  };

  onTogglePanel = () => this.setState({ open: !this.state.open });

  onChangeTabs = key => this.setState({ selectedTab: key });

  onChangeTagsCheckbox = event => {
    const { selectedTags } = this.state;
    const { value, checked } = event.target;

    if (checked) {
      const sortedTags = [...selectedTags, value].sort((a, b) => a.localeCompare(b));

      this.setState({ selectedTags: sortedTags });
    } else {
      this.setState({ selectedTags: selectedTags.filter(tag => tag !== value) });
    }
  };

  onChangeDevicesSelect = id => this.setState({ selectedDeviceId: id || '0' });

  onToggleTagsButton = () => {
    const { selectedTags } = this.state;

    if (selectedTags.length) {
      this.setState({ selectedTags: [] });
    } else {
      this.setState({ selectedTags: this.getVideosTags() });
    }
  };

  getVideosTags = () => {
    const { playlist } = this.props;
    const tags = new Set();

    playlist.files.forEach(file => file.tags?.forEach(tag => tag && tags.add(tag)));

    return Array.from(tags).sort();
  };

  getVideosByTags = tags => {
    const { playlist } = this.props;
    const noTags = !tags || !tags.length || !tags[0];

    if (noTags) {
      return [];
    }

    return playlist.files.filter(file => file.tags?.some(tag => tag && tags.includes(tag)));
  };

  getTaggedVideos = () => {
    const { playlist } = this.props;

    return playlist.files.filter(file => file.tags && file.tags.length && file.tags[0]);
  };

  getUntaggedVideos = () => {
    const { playlist } = this.props;

    return playlist.files.filter(file => !file.tags || !file.tags.length || !file.tags[0]);
  };

  getAssignments = () => {
    const { playlist, signs, sequences, schedules, playlists, assignments } = this.props;

    return getAssignmentsForPlaylist(playlist, signs, sequences, schedules, playlists, assignments);
  };

  getMatchedAssignments = (assignments, tags) =>
    assignments.filter(item => item.tags.some(tag => tag && tags.includes(tag)));

  getUnmatchedAssignments = (assignments, tags) =>
    assignments.filter(item => !item.tags.some(tag => tags.includes(tag)));

  getUniqueDevices = devices => {
    const uniqueDevices = [];

    devices.forEach(device => {
      if (!uniqueDevices.find(item => item.device_id === device.device_id)) {
        uniqueDevices.push(device);
      }
    });

    return uniqueDevices;
  };

  getDevice = id => this.props.assignments.find(item => item.device_id === id);

  getAssignmentsForDevice = (assignments, id) => assignments.filter(item => item.device_id === id);

  getDeviceVideos = id => {
    const device = this.getDevice(id);
    const tags = device ? device.tags : [];
    const videos = this.getVideosByTags(tags);

    return videos;
  };

  getVideosTagsCheckboxes = tags => {
    return this.getVideosTags().map(tag => (
      <Checkbox
        key={tag}
        checked={tags.includes(tag)}
        onChange={this.onChangeTagsCheckbox}
        value={tag}
      >
        {tag}
      </Checkbox>
    ));
  };

  getHeader = () => {
    const { open } = this.state;

    return (
      <div data-test="used-playlists-title" className="toggle" onClick={this.onTogglePanel}>
        <i className="material-icons material-caret inline">
          {open ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}
        </i>
        Tag Explorer
      </div>
    );
  };

  render() {
    const { playlist, signs, campaigns } = this.props;
    const { open, selectedTab, selectedTags, selectedDeviceId } = this.state;

    if (!playlist) {
      return null;
    }

    const { files, tagControlled } = playlist;

    if (!tagControlled || !files) {
      return null;
    }

    const videosTags = this.getVideosTags();
    const assignments = this.getAssignments();
    const matchedAssignments = this.getMatchedAssignments(assignments, videosTags);
    const unmatchedAssignments = this.getUnmatchedAssignments(assignments, videosTags);
    const videoTagsCheckboxes = this.getVideosTagsCheckboxes(selectedTags);
    const matchedDevices = this.getUniqueDevices(matchedAssignments, videosTags);
    const unmatchedDevices = this.getUniqueDevices(unmatchedAssignments, videosTags);
    const selectedDeviceAssignments = this.getAssignmentsForDevice(
      matchedAssignments,
      selectedDeviceId,
    );
    const selectedTagsVideos = this.getVideosByTags(selectedTags);
    const taggedVideos = this.getTaggedVideos();
    const untaggedVideos = this.getUntaggedVideos();
    const deviceVideos = this.getDeviceVideos(selectedDeviceId);
    let data = [];

    if (selectedTab === '1') {
      data = selectedTagsVideos;
    } else if (selectedTab === '2') {
      data = untaggedVideos;
    } else if (selectedTab === '3') {
      data = deviceVideos;
    }

    return (
      <div>
        <Alert bsStyle="warning" className="margin-md-vert">
          This playlist is tag controlled. Devices only show videos that share at least one tag with
          the device.
        </Alert>
        {untaggedVideos.length > 0 && (
          <Alert bsStyle="danger">
            This playlist has untagged videos. Untagged videos do not appear on devices.
          </Alert>
        )}
        <Panel
          data-test="panel-tag-controlled-playlist"
          id="panel-tag-controlled-playlist"
          className="used-in"
        >
          <Panel.Heading>{this.getHeader()}</Panel.Heading>
          <Panel.Body className="open">
            {open && (
              <Nav bsStyle="tabs" activeKey={selectedTab} onSelect={k => this.onChangeTabs(k)}>
                <NavItem eventKey="1">
                  Tagged videos <Badge>{taggedVideos.length}</Badge>
                </NavItem>
                <NavItem eventKey="2">
                  Untagged videos <Badge>{untaggedVideos.length}</Badge>
                </NavItem>
                <NavItem eventKey="3">
                  Matched devices <Badge>{matchedDevices.length}</Badge>
                </NavItem>
                <NavItem eventKey="4">
                  Unmatched devices <Badge>{unmatchedDevices.length}</Badge>
                </NavItem>
              </Nav>
            )}
            <div id="video-tags">
              {open && (
                <div>
                  <p>
                    {selectedTab === '1' && !videosTags.length && 'There are no tagged videos.'}
                    {selectedTab === '2' && !data.length && 'There are no untagged videos.'}
                    {selectedTab === '3' && !assignments.length && 'There are no matched devices.'}
                    {selectedTab === '4' &&
                      !unmatchedDevices.length &&
                      'There are no unmatched devices.'}
                  </p>
                  {selectedTab === '1' && videosTags.length > 0 && (
                    <div>
                      <p>
                        Here, you can test how device tags affect video selection. Select tags to
                        see which videos appear on devices with those tags.
                        <br />
                        Tagged videos: {taggedVideos.length}, video tags: {videosTags.length}.
                      </p>
                      <p>
                        <Button onClick={this.onToggleTagsButton}>Toggle Video Tags</Button>
                      </p>
                      {videoTagsCheckboxes}
                      {data.length > 0 && (
                        <div>
                          <h4>Devices with the selected tags show these videos</h4>
                          Device tags: {buildTagList({ tags: selectedTags })}
                          {!data.length && <p>No videos will be shown on this device.</p>}
                        </div>
                      )}
                    </div>
                  )}
                  {selectedTab === '2' && data.length > 0 && (
                    <div>
                      <Alert bsStyle="danger">
                        Untagged videos do not appear on devices. Add tags or remove these videos
                        from the playlist if they are not needed.
                      </Alert>
                      <h4>These videos do not appear on devices</h4>
                    </div>
                  )}
                  {selectedTab === '3' && matchedDevices.length > 0 && (
                    <div>
                      <p>
                        These are the devices where this playlist is used. Select a device to see
                        its video selection.
                        <br />
                        Matched devices: {matchedDevices.length}, matched assignments:{' '}
                        {matchedAssignments.length}.
                      </p>
                      <div className="margin-md-vert">
                        Select Device:
                        <MultiColumnSelect
                          options={matchedDevices}
                          headers={['Device ID', 'Device Name', 'Location', 'Tags']}
                          displayKeys={['name', 'description', 'parent', 'tags']}
                          orderKeys={['parent', 'description']}
                          idKey="device_id"
                          selectedId={selectedDeviceId}
                          onSelect={this.onChangeDevicesSelect}
                        />
                        {selectedDeviceId === '0' && (
                          <p>
                            No device selected. Select a device to see its video selection based on
                            device and video tags.
                          </p>
                        )}
                      </div>
                      {selectedDeviceAssignments.length > 0 && (
                        <div>
                          <UsedInAssignmentsTable
                            type="playlist"
                            data={selectedDeviceAssignments}
                            signs={signs}
                            campaigns={campaigns}
                            title="Assignments for the selected device where this playlist is used"
                            open
                          />
                          <br />
                          <h4>The selected device shows these videos</h4>
                          Device tags: {buildTagList(selectedDeviceAssignments[0] || {})}
                          {!data.length && <p>No videos will be shown on this device.</p>}
                        </div>
                      )}
                    </div>
                  )}
                  {selectedTab === '4' && unmatchedAssignments.length > 0 && (
                    <div className="margin-md-vert">
                      <Alert bsStyle="danger">
                        This playlist is assigned to these devices, but no videos appear because no
                        video tags match the device tags.
                      </Alert>
                      <p>
                        Unmatched devices: {unmatchedDevices.length}, unmatched assignments:{' '}
                        {unmatchedAssignments.length}.
                      </p>
                      {unmatchedAssignments.length > 0 && (
                        <UsedInAssignmentsTable
                          type="playlist"
                          data={unmatchedAssignments}
                          signs={signs}
                          campaigns={campaigns}
                          title="Assignments for unmatched devices where this playlist is used"
                          open
                        />
                      )}
                    </div>
                  )}
                  {data.length > 0 && (
                    <PlaylistFilesTable
                      auth={{ user: { permissions: {} } }}
                      data={data}
                      onSort={() => {}}
                      onRemoveFile={() => {}}
                    />
                  )}
                </div>
              )}
            </div>
          </Panel.Body>
        </Panel>
      </div>
    );
  }
}

TagControlledPlaylistPanel.propTypes = {
  playlist: PropTypes.object,
  signs: PropTypes.array,
  sequences: PropTypes.array,
  schedules: PropTypes.array,
  playlists: PropTypes.array,
  campaigns: PropTypes.array,
  assignments: PropTypes.array,
};
