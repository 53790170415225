import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Alert } from 'react-bootstrap';

export default class RebootDeviceDialog extends Component {
  getInitialState = () => ({ confirm: false });

  state = this.getInitialState();

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { show } = nextProps;

    if (!show) {
      this.setState(this.getInitialState());
    }
  }

  onChangeConfirm = () => {
    const { confirm } = this.state;

    this.setState({ confirm: !confirm });
  };

  getDevices = () => {
    const { devices } = this.props;
    const names = devices.map((item, index) => (
      <li key={index}>
        <code>{item.name}</code> {item.description}
      </li>
    ));

    return <ol>{names}</ol>;
  };

  render() {
    const { show, onHide, onSuccess, devices } = this.props;
    const { confirm } = this.state;
    const plural = devices.length > 1 ? 's' : '';

    return (
      <Modal
        data-test="modal-rebootdevice"
        dialogClassName="device-dialog"
        backdrop="static"
        show={show}
        onHide={onHide}
      >
        <Modal.Header>
          <Modal.Title data-test="modal-rebootdevice-title">Reboot Device</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Device{plural} to reboot:</p>
          {this.getDevices()}
          <Alert bsStyle="warning">
            <label>
              <input
                data-test="modal-rebootdevice-checkbox-confirm"
                type="checkbox"
                checked={confirm}
                onChange={this.onChangeConfirm}
              />{' '}
              I want to I want to reboot {devices.length} Device{plural}.
            </label>
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn-cancel" onClick={onHide}>
            Cancel
          </Button>
          <Button
            data-test="modal-rebootdevice-button-reboot"
            className="btn-add"
            bsStyle="primary"
            onClick={onSuccess}
            disabled={!confirm}
          >
            Reboot Device{plural}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

RebootDeviceDialog.propTypes = {
  devices: PropTypes.array,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};
