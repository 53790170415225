import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import errorMessage from '../../utils/errorMessage';

export default class AddDataObjectDialog extends Component {
  getInitialState = () => ({
    page: 'create1', // create1, create2, upload1, upload2
    name: '',
    tier: '',
    column: '',
    columns: [],
    primarycolumn: null,
    file: null,
  });

  state = this.getInitialState();

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { show } = nextProps;

    if (!show) {
      this.setState(this.getInitialState());
    }
  }

  handleChangeType = event => this.setState({ page: event.target.value });

  handleChangeName = event => this.setState({ name: event.target.value });

  handleChangeTier = event => this.setState({ tier: event.target.value });

  handleChangeFile = event => this.setState({ file: event.target.files[0] });

  handleChangeColumn = event => this.setState({ column: event.target.value });

  handleKeyDownColumn = event => {
    if (event.keyCode === 13) {
      this.handleClickAddColumn();
    }
  };

  handleClickAddColumn = () => {
    const { addNotification } = this.props;
    const { column: stateColumn, columns: stateColumns } = this.state;
    const column = stateColumn.trim();
    const columns = stateColumns.slice(0);

    if (!column) {
      addNotification({ type: 'danger', text: 'Type a Column name.' });

      return;
    }

    if (columns.includes(column)) {
      addNotification({ type: 'danger', text: 'That Column name already exists.' });

      return;
    }

    columns.push(column);
    this.setState({ column: '', columns });
  };

  handleClickRemoveColumn = index => {
    const { columns, primarycolumn } = this.state;
    const newcolumns = columns.filter((item, index2) => index !== index2);
    let newprimary = primarycolumn;

    if (index === primarycolumn) {
      newprimary = null;
    }

    if (index < primarycolumn) {
      newprimary = primarycolumn - 1;
    }

    this.setState({ columns: newcolumns, primarycolumn: newprimary });
  };

  handleClickSetPrimaryColumn = primarycolumn => this.setState({ primarycolumn });

  handleClickAddColumns = () => {
    const items = ['name', 'tier'];

    if (this.validate(items)) {
      this.setState({ page: 'create2' });
    }
  };

  handleClickBack = () => {
    const { page } = this.state;

    this.setState({ page: page === 'create2' ? 'create1' : 'upload1' });
  };

  handleClickUploadFile = () => {
    const items = ['name', 'tier', 'file'];

    if (this.validate(items)) {
      this.uploadCsv();
    }
  };

  handleClickAddDataObject = () => {
    const { onSuccess } = this.props;
    const { name, tier, primarycolumn, columns, file } = this.state;
    const items = ['name', 'tier', 'columns', 'primarycolumn', 'file'];
    const dataObject = { name: name.trim(), tier: tier.trim(), columns, primarycolumn, file };

    if (this.validate(items)) {
      onSuccess(dataObject);
    }
  };

  uploadCsv = () => {
    const { uploadCsv, addNotification } = this.props;
    const { file } = this.state;

    return uploadCsv(file).then(
      res => {
        if (res.headers && res.headers.length) {
          this.setState({
            page: 'upload2',
            columns: res.headers,
            file: res.file, // replace the file with the file id
          });
        } else {
          addNotification({
            type: 'danger',
            text: 'File upload failed, please make sure the CSV file is valid.',
          });
        }
      },
      err => addNotification({ type: 'danger', text: errorMessage(err) }),
    );
  };

  validate = items => {
    const { dataObjects, addNotification } = this.props;
    const { name, tier, primarycolumn, columns, file } = this.state;

    return !items.some(item => {
      if (item === 'name') {
        if (!name.trim()) {
          addNotification({ type: 'danger', text: 'Type a Data Object name.' });

          return true;
        }

        if (
          dataObjects.some(
            dataObject =>
              dataObject.data_object.name.toLowerCase().trim() === name.toLowerCase().trim(),
          )
        ) {
          addNotification({ type: 'danger', text: 'That Data Object name already exists.' });

          return true;
        }
      }

      if (item === 'tier' && !tier.trim()) {
        addNotification({ type: 'danger', text: 'Type a Tier name.' });

        return true;
      }

      if (item === 'columns' && !columns.length) {
        addNotification({ type: 'danger', text: 'Add some Columns.' });

        return true;
      }

      if (item === 'primarycolumn' && primarycolumn === null) {
        addNotification({ type: 'danger', text: 'Assign a Primary Key.' });

        return true;
      }

      if (item === 'file' && !columns.length && !file) {
        addNotification({ type: 'danger', text: 'Select a Data source.' });

        return true;
      }

      return false;
    });
  };

  renderNameInput = () => {
    const { name } = this.state;

    return (
      <div className="form-group">
        <label>Data Object name</label>
        <input
          className="form-control"
          placeholder="Enter a name"
          value={name}
          onChange={this.handleChangeName}
          autoFocus
        />
      </div>
    );
  };

  renderTierInput = () => {
    const { tier } = this.state;

    return (
      <div className="form-group">
        <label>Base Tier name</label>
        <input
          className="form-control"
          placeholder="Enter a name"
          value={tier}
          onChange={this.handleChangeTier}
        />
      </div>
    );
  };

  renderTypeSelect = () => {
    const { page } = this.state;

    return (
      <div className="form-group">
        <label>Data source</label>
        <select className="form-control" value={page} onChange={this.handleChangeType}>
          <option value="create1">No source</option>
          <option value="upload1">CSV file</option>
        </select>
      </div>
    );
  };

  renderFileInput = () => (
    <div className="form-group">
      <label>CSV file</label>
      <input type="file" accept=".csv" onChange={this.handleChangeFile} />
    </div>
  );

  renderAddColumnsPanel = () => {
    const { column } = this.state;

    return (
      <div>
        <h4>Add Columns</h4>
        <p>Add the columns below that you want to use in your Data Object.</p>
        <div className="form-group">
          <div className="col-80">
            <input
              className="form-control"
              placeholder="Enter a Column name"
              value={column}
              onChange={this.handleChangeColumn}
              onKeyDown={this.handleKeyDownColumn}
              autoFocus
            />
          </div>
          <div className="col-40">
            <Button onClick={this.handleClickAddColumn} block>
              Add column
            </Button>
          </div>
        </div>
        <div className="clearfix" />
      </div>
    );
  };

  renderColumnsList = () => {
    const { page, columns, primarycolumn } = this.state;

    if (!columns.length) {
      return null;
    }

    return (
      <div>
        <h4>Assign Primary Key</h4>
        <p>Click on the key icon of the column you wish to set as the Primary Key.</p>
        <ul>
          {columns.map((column, index) => {
            const active = index === primarycolumn ? ' active' : '';
            const keyIcon = (
              <span
                className={`fa fa-key key${active}`}
                onClick={() => {
                  this.handleClickSetPrimaryColumn(index);
                }}
              />
            );
            const removeIcon = (
              <span
                className="fa fa-minus-circle remove"
                onClick={() => {
                  this.handleClickRemoveColumn(index);
                }}
              />
            );

            // do not allow to remove columns from file
            if (page === 'upload2') {
              return (
                <li key={index}>
                  {keyIcon}
                  <span>{column}</span>
                </li>
              );
            }

            return (
              <li key={index}>
                {keyIcon}
                <span>{column}</span>
                {removeIcon}
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  renderCancelButton = () => {
    const { onHide } = this.props;

    return <Button onClick={onHide}>Cancel</Button>;
  };

  renderBackButton = () => (
    <Button className="left" onClick={this.handleClickBack}>
      Back
    </Button>
  );

  renderAddColumnsButton = () => (
    <Button bsStyle="primary" onClick={this.handleClickAddColumns}>
      Add Columns
    </Button>
  );

  renderUploadFileButton = () => (
    <Button bsStyle="primary" onClick={this.handleClickUploadFile}>
      Upload File
    </Button>
  );

  renderAddDataObjectButton = () => (
    <Button bsStyle="primary" onClick={this.handleClickAddDataObject}>
      Add Data Object
    </Button>
  );

  renderBody = () => {
    const { page } = this.state;

    switch (page) {
      case 'create1':
        return (
          <div>
            {this.renderNameInput()}
            {this.renderTierInput()}
            {this.renderTypeSelect()}
          </div>
        );
      case 'upload1':
        return (
          <div>
            {this.renderNameInput()}
            {this.renderTierInput()}
            {this.renderTypeSelect()}
            {this.renderFileInput()}
          </div>
        );
      case 'create2':
        return (
          <div>
            {this.renderAddColumnsPanel()}
            {this.renderColumnsList()}
          </div>
        );
      case 'upload2':
        return <div>{this.renderColumnsList()}</div>;
      default:
        return null;
    }
  };

  renderFooter = () => {
    const { page } = this.state;

    switch (page) {
      case 'create1':
        return (
          <div>
            {this.renderCancelButton()}
            {this.renderAddColumnsButton()}
          </div>
        );
      case 'upload1':
        return (
          <div>
            {this.renderCancelButton()}
            {this.renderUploadFileButton()}
          </div>
        );
      case 'create2':
      case 'upload2':
        return (
          <div>
            {this.renderBackButton()}
            {this.renderCancelButton()}
            {this.renderAddDataObjectButton()}
          </div>
        );
      default:
        return null;
    }
  };

  render() {
    const { show, onHide } = this.props;
    const body = this.renderBody();
    const footer = this.renderFooter();

    return (
      <Modal dialogClassName="data-object-dialog" backdrop="static" show={show} onHide={onHide}>
        <Modal.Header>
          <Modal.Title>Add Data Object</Modal.Title>
        </Modal.Header>
        <Modal.Body>{body}</Modal.Body>
        <Modal.Footer>{footer}</Modal.Footer>
      </Modal>
    );
  }
}

AddDataObjectDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  dataObjects: PropTypes.array.isRequired,
  uploadCsv: PropTypes.func.isRequired,
  addNotification: PropTypes.func.isRequired,
};
