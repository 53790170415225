const getSchedulesForSequence = (sequence, schedules) => {
  if (!sequence || !schedules) {
    return [];
  }

  return schedules
    .filter(schedule => schedule.scheduleEntries.find(item => item.sequence_id === +sequence.id))
    .sort((a, b) => a.name.localeCompare(b.name));
};

export default getSchedulesForSequence;
