import React, { Component } from 'react';
import PropTypes from 'prop-types';
import getPlaylistsForVideo from './utils/getPlaylistsForVideo';
import getSignsForAsset from './utils/getSignsForAsset';
import getSignsForVideo from './utils/getSignsForVideo';
import getSequencesForAsset from './utils/getSequencesForAsset';
import getSequencesForSign from './utils/getSequencesForSign';
import getSequencesForVideo from './utils/getSequencesForVideo';
import getSequencesForPlaylist from './utils/getSequencesForPlaylist';
import getSchedulesForAsset from './utils/getSchedulesForAsset';
import getSchedulesForVideo from './utils/getSchedulesForVideo';
import getSchedulesForSign from './utils/getSchedulesForSign';
import getSchedulesForSequence from './utils/getSchedulesForSequence';
import getSchedulesForPlaylist from './utils/getSchedulesForPlaylist';
import getAssignmentsForAsset from './utils/getAssignmentsForAsset';
import getAssignmentsForVideo from './utils/getAssignmentsForVideo';
import getAssignmentsForSign from './utils/getAssignmentsForSign';
import getAssignmentsForSequence from './utils/getAssignmentsForSequence';
import getAssignmentsForSchedule from './utils/getAssignmentsForSchedule';
import getAssignmentsForPlaylist from './utils/getAssignmentsForPlaylist';
import getAssignmentsForCampaign from './utils/getAssignmentsForCampaign';
import UsedInPlaylistsTable from './UsedInPlaylistsTable';
import UsedInSignsTable from './UsedInSignsTable';
import UsedInSeqsOrSchedsTable from './UsedInSeqsOrSchedsTable';
import UsedInAssignmentsTable from './UsedInAssignmentsTable';

export default class Used extends Component {
  getPlaylists = () => {
    const { type, item, playlists } = this.props;

    switch (type) {
      case 'video':
        return getPlaylistsForVideo(item, playlists);
      default:
        return [];
    }
  };

  getSigns = () => {
    const { type, item, signs, playlists } = this.props;

    switch (type) {
      case 'playlist':
      case 'asset':
        return getSignsForAsset(item, signs);
      case 'video':
        return getSignsForVideo(item, signs, playlists);
      default:
        return [];
    }
  };

  getSequences = () => {
    const { type, item, signs, sequences, playlists } = this.props;

    switch (type) {
      case 'asset':
        return getSequencesForAsset(item, signs, sequences);
      case 'video':
        return getSequencesForVideo(item, signs, sequences, playlists);
      case 'sign':
        return getSequencesForSign(item, sequences);
      case 'playlist':
        return getSequencesForPlaylist(item, signs, sequences);
      default:
        return [];
    }
  };

  getSchedules = () => {
    const { type, item, signs, sequences, schedules, playlists } = this.props;

    switch (type) {
      case 'asset':
        return getSchedulesForAsset(item, signs, sequences, schedules);
      case 'video':
        return getSchedulesForVideo(item, signs, sequences, schedules, playlists);
      case 'sign':
        return getSchedulesForSign(item, sequences, schedules);
      case 'sequence':
        return getSchedulesForSequence(item, schedules);
      case 'playlist':
        return getSchedulesForPlaylist(item, signs, sequences, schedules);
      default:
        return [];
    }
  };

  getAssignments = () => {
    const { type, item, signs, sequences, schedules, playlists, assignments } = this.props;

    switch (type) {
      case 'sign':
        return getAssignmentsForSign(item, sequences, schedules, playlists, assignments);
      case 'sequence':
        return getAssignmentsForSequence(item, sequences, schedules, playlists, assignments);
      case 'schedule':
        return getAssignmentsForSchedule(item, playlists, assignments);
      case 'playlist':
        return getAssignmentsForPlaylist(item, signs, sequences, schedules, playlists, assignments);
      case 'campaign':
        return getAssignmentsForCampaign(item, sequences, schedules, playlists, assignments);
      case 'video':
        return getAssignmentsForVideo(item, signs, sequences, schedules, playlists, assignments);
      case 'asset':
        return getAssignmentsForAsset(item, signs, sequences, schedules, playlists, assignments);
      default:
        return [];
    }
  };

  render() {
    const { auth, users, type, item, signs, campaigns } = this.props;
    const showPlaylists = ['video'].includes(type);
    const showSigns = ['video', 'playlist', 'asset'].includes(type);
    const showSequences = ['video', 'playlist', 'asset', 'sign'].includes(type);
    const showSchedules = ['video', 'playlist', 'asset', 'sign', 'sequence'].includes(type);
    const showAssignments = [
      'video',
      'playlist',
      'asset',
      'sign',
      'sequence',
      'schedule',
      'campaign',
    ].includes(type);

    return (
      <div>
        {showPlaylists && (
          <UsedInPlaylistsTable item={item} data={this.getPlaylists()} auth={auth} users={users} />
        )}
        {showSigns && (
          <UsedInSignsTable
            type={type}
            item={item}
            data={this.getSigns()}
            auth={auth}
            users={users}
          />
        )}
        {showSequences && (
          <UsedInSeqsOrSchedsTable
            mode="sequence"
            type={type}
            data={this.getSequences()}
            auth={auth}
            users={users}
          />
        )}
        {showSchedules && (
          <UsedInSeqsOrSchedsTable
            mode="schedule"
            type={type}
            data={this.getSchedules()}
            auth={auth}
            users={users}
          />
        )}
        {showAssignments && (
          <UsedInAssignmentsTable
            type={type}
            data={this.getAssignments()}
            auth={auth}
            signs={signs}
            campaigns={campaigns}
          />
        )}
      </div>
    );
  }
}

Used.propTypes = {
  type: PropTypes.string,
  item: PropTypes.object,
  signs: PropTypes.array,
  sequences: PropTypes.array,
  schedules: PropTypes.array,
  playlists: PropTypes.array,
  campaigns: PropTypes.array,
  assignments: PropTypes.array,
  auth: PropTypes.object,
  users: PropTypes.array,
};
