import * as types from '../actions/actionTypes';

export const initialState = {
  loaded: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.AUTH_LOAD:
      return {
        ...state,
        loading: true,
      };
    case types.AUTH_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        user: action.result,
      };
    case types.AUTH_LOAD_FAILED:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
    case types.AUTH_NEXT_PATH:
      return {
        ...state,
        nextPath: action.path,
      };
    case types.AUTH_UPDATE_AVATAR:
      return {
        ...state,
        user: {
          ...state.user,
          user: {
            ...state.user.user,
            avatar: action.avatar,
          },
        },
      };
    default:
      return state;
  }
}
