import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { withRouter, Prompt } from 'react-router-dom';
import { SOLR_DELAY } from '../../../../constants';
import { getUsers } from '../../../actions/userActions';
import { loadFolders } from '../../../actions/folderActions';
import { loadAllAssignments } from '../../../actions/assignmentActions';
import { getSigns } from '../../../actions/signActions';
import { loadSequences } from '../../../actions/sequenceActions';
import { loadSchedules } from '../../../actions/scheduleActions';
import { loadPlaylists } from '../../../actions/playlistActions';
import {
  loadCampaigns,
  loadCampaign,
  createCampaign,
  updateCampaign,
  deleteCampaign,
  clearCampaignForm,
} from '../../../actions/campaignActions';
import { getCampaignSchedule } from '../../../actions/campaignScheduleActions';
import { addNotification } from '../../../actions/notificationActions';
import CampaignForm from '../../../components/Campaigns/CampaignForm';
import DuplicateCampaignDialog from '../../../components/Campaigns/DuplicateCampaignDialog';
import Used from '../../../components/Used/Used';
import Header from '../../../components/Header/Header';
import QuickAction from '../../../components/QuickAction/QuickAction';
import Spinner from '../../../components/Spinner/Spinner';
import Confirm from '../../../components/Confirm/Confirm';
import ErrorPage from '../ErrorPage/ErrorPage';
import ActionsMenu from '../../../components/ActionsMenu/ActionsMenu';
import LocationSelector from '../../../components/Locations/LocationSelector';
import errorMessage from '../../../utils/errorMessage';
import { getParent } from '../../../utils/parent';

export class CampaignPage extends Component {
  getNewCampaign = () => ({
    name: '',
    description: '',
    tags: [],
    commander_enabled: false,
  });

  state = {
    campaign: this.props.match.params.id ? null : this.getNewCampaign(),
    dirty: false,
    showDuplicateDialog: false,
    showFolderSelector: false,
  };

  componentDidMount() {
    const { clearCampaignForm } = this.props;

    clearCampaignForm();
    this.loadData();
    document.addEventListener('visibilitychange', this.loadData);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      campaigns: { campaign },
    } = nextProps;
    const { campaign: stateCampaign } = this.state;

    if (!stateCampaign && campaign.id) {
      this.setState({ campaign });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('visibilitychange', this.loadData);
  }

  loadData = () => {
    if (document.visibilityState === 'hidden') {
      return;
    }

    const {
      match: {
        params: { id },
      },
      getCampaignSchedule,
      getUsers,
      loadFolders,
      loadAllAssignments,
      getSigns,
      loadSequences,
      loadSchedules,
      loadPlaylists,
      loadCampaigns,
      loadCampaign,
    } = this.props;

    getCampaignSchedule();
    getUsers();
    loadFolders('?count=100000000');
    loadAllAssignments(`&campaign_id=${id}`);
    getSigns('?count=100000000&published=true&isAssignable=true');
    loadSequences('?count=100000000');
    loadSchedules('?count=100000000');
    loadPlaylists('?count=100000000');
    loadCampaigns('?count=100000000');

    if (id) {
      loadCampaign(id);
    }
  };

  buildCampaignForServer = () => {
    const {
      campaigns: {
        campaign: { parent },
      },
    } = this.props;
    const {
      campaign: { name, description, tags, commander_enabled },
    } = this.state;

    return {
      name: name.trim(),
      description: (description || '').trim(),
      tags,
      commander_enabled,
      parent: +getParent(this.props, parent).id,
    };
  };

  goBack = () => {
    const { history, previousLocation } = this.props;
    const url = previousLocation
      ? previousLocation.pathname + previousLocation.search
      : '/content?type=campaign&parent=/*';

    this.setState({ dirty: false }, () => history.push(url));
  };

  goAssignments = id => {
    const { history } = this.props;
    const url = `/campaigns/${id}/assignments`;

    this.setState({ dirty: false }, () => history.push(url));
  };

  add = () => {
    const { addNotification } = this.props;
    const campaign = this.buildCampaignForServer();

    return createCampaign(campaign).then(
      res => {
        addNotification({ type: 'success', text: 'Campaign added.' });
        this.goAssignments(res.id);
      },
      err => addNotification({ type: 'danger', text: errorMessage(err) }),
    );
  };

  update = () => {
    const {
      match: {
        params: { id },
      },
      addNotification,
    } = this.props;
    const campaign = this.buildCampaignForServer();

    return updateCampaign(id, campaign).then(
      () => {
        addNotification({ type: 'success', text: 'Campaign updated.' });
        setTimeout(this.goBack, SOLR_DELAY);
      },
      err => addNotification({ type: 'danger', text: errorMessage(err) }),
    );
  };

  delete = () => {
    const {
      match: {
        params: { id },
      },
      addNotification,
    } = this.props;

    return deleteCampaign(id).then(
      () => {
        addNotification({ type: 'success', text: 'Campaign deleted.' });
        setTimeout(this.goBack, SOLR_DELAY);
      },
      err => addNotification({ type: 'danger', text: errorMessage(err) }),
    );
  };

  duplicate = name => {
    const { addNotification } = this.props;
    const {
      campaign: { id },
    } = this.state;
    const campaign = this.buildCampaignForServer();

    campaign.name = name.trim();
    campaign.copyAssignmentsFrom = id;

    return createCampaign(campaign).then(
      res => {
        addNotification({ type: 'success', text: 'Campaign duplicated.' });
        this.goAssignments(res.id);
      },
      err => addNotification({ type: 'danger', text: errorMessage(err) }),
    );
  };

  move = parent => {
    const {
      match: {
        params: { id },
      },
      campaigns,
      loadCampaign,
      addNotification,
    } = this.props;

    this.hideFolderSelector();

    return updateCampaign(id, { ...campaigns.campaign, parent: parent.id }).then(
      () => {
        addNotification({ type: 'success', text: 'Campaign moved.' });
        loadCampaign(id);
      },
      err => addNotification({ type: 'danger', text: errorMessage(err) }),
    );
  };

  onDelete = () => {
    const { campaigns, campaignSchedule } = this.props;
    const { campaign } = campaigns;
    const entries = campaignSchedule.campaignSchedule.campaignScheduleEntries;
    let title = '';
    let body = '';
    let success;
    const hasScheduledCampaign = entries.some(
      entry => entry.campaignId === campaign.id && entry.campaignName === campaign.name,
    );
    const confirm = this.confirmDelete;

    if (hasScheduledCampaign) {
      title = 'Scheduled Campaign Warning';
      body = (
        <div>
          <p>
            This campaign is currently assigned to your{' '}
            <a href="/campaign-schedule">Campaign Schedule</a>.
          </p>
          <p>
            If you wish to delete it, you must first replace it in the{' '}
            <a href="/campaign-schedule">Campaign Schedule</a>.
          </p>
        </div>
      );

      success = () => {};
    } else {
      title = 'Delete Campaign';
      body = 'Are you sure you want to delete this Campaign?';
      success = this.delete;
    }

    confirm.show(title, body);
    confirm.onSuccess(success);
  };

  onSuccessDuplicateDialog = name => {
    this.hideDuplicateDialog();
    this.duplicate(name);
  };

  showDuplicateDialog = () => this.setState({ showDuplicateDialog: true });

  showFolderSelector = () => this.setState({ showFolderSelector: true });

  hideDuplicateDialog = () => this.setState({ showDuplicateDialog: false });

  hideFolderSelector = () => this.setState({ showFolderSelector: false });

  onChange = (key, value) => {
    const { campaign } = this.state;

    this.setState({ campaign: { ...campaign, [key]: value }, dirty: true });
  };

  getHeaderActions = () => {
    const {
      match: {
        params: { id },
      },
      auth,
    } = this.props;
    const { Writer, Assigner } = auth.user.permissions;
    const actions = [];
    const assign = (
      <QuickAction
        key="1"
        toolText="View Assignments"
        href={`/campaigns/${id}/assignments`}
        alwaysShow
      >
        featured_play_list
      </QuickAction>
    );
    const duplicate = (
      <QuickAction
        key="2"
        toolText="Duplicate Campaign"
        onClick={this.showDuplicateDialog}
        alwaysShow
      >
        content_copy
      </QuickAction>
    );
    const move = (
      <QuickAction key="3" toolText="Move Campaign" onClick={this.showFolderSelector} alwaysShow>
        subdirectory_arrow_right
      </QuickAction>
    );
    const remove = (
      <QuickAction key="4" toolText="Delete Campaign" onClick={this.onDelete} alwaysShow>
        delete
      </QuickAction>
    );

    if (!id) {
      return [];
    }

    if (Assigner) {
      actions.push(assign);
    }

    if (Writer) {
      actions.push(duplicate, move, remove);
    }

    return actions;
  };

  render() {
    const {
      match: {
        params: { id },
      },
      auth,
      users: { users },
      folders: {
        folders: { folders },
      },
      signs: {
        signs: { signs },
      },
      sequences: {
        sequences: { sequences },
      },
      schedules: {
        schedules: { schedules },
      },
      playlists: {
        playlists: { playlists },
      },
      campaigns: {
        campaigns: { campaigns },
        campaign,
        loadingCampaign,
        errorCampaign,
      },
      assignments: {
        allAssignments: { assignments },
      },
    } = this.props;
    const { dirty, campaign: stateCampaign, showDuplicateDialog, showFolderSelector } = this.state;

    if (id && errorCampaign) {
      return <ErrorPage error={errorCampaign} />;
    }

    return (
      <div className="page-structure">
        <Helmet title="Campaign" />
        <Header overwrite={{ id: campaign }} />
        <ActionsMenu actions={this.getHeaderActions()} />
        <div className="page-panels">
          <div className="page-content page-campaign">
            <Spinner loading={loadingCampaign}>
              <Prompt
                when={dirty}
                message="You have unsaved changes. Are you sure you want to leave this page?"
              />
              <CampaignForm
                auth={auth}
                users={users}
                campaigns={campaigns}
                campaign={stateCampaign}
                isNew={!id}
                onChange={this.onChange}
                onAdd={this.add}
                onUpdate={this.update}
                onCancel={this.goBack}
              />
              <Used
                type="campaign"
                item={stateCampaign}
                signs={signs}
                sequences={sequences}
                schedules={schedules}
                playlists={playlists}
                campaigns={campaigns}
                assignments={assignments}
                auth={auth}
                users={users}
              />
            </Spinner>
            <DuplicateCampaignDialog
              campaigns={campaigns}
              campaign={campaign}
              show={showDuplicateDialog}
              onHide={this.hideDuplicateDialog}
              onSuccess={this.onSuccessDuplicateDialog}
            />
            <Confirm
              ref={confirm => {
                this.confirmDelete = confirm;
              }}
              title="Delete Campaign"
            />
            <LocationSelector
              type="folder"
              show={showFolderSelector}
              onHide={this.hideFolderSelector}
              onSuccess={this.move}
              items={folders}
              currentPath={campaign.parent}
            />
          </div>
        </div>
      </div>
    );
  }
}

CampaignPage.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  previousLocation: PropTypes.object,
  users: PropTypes.object.isRequired,
  folders: PropTypes.object.isRequired,
  signs: PropTypes.object,
  sequences: PropTypes.object,
  schedules: PropTypes.object,
  playlists: PropTypes.object,
  campaigns: PropTypes.object.isRequired,
  assignments: PropTypes.object.isRequired,
  getUsers: PropTypes.func.isRequired,
  loadFolders: PropTypes.func.isRequired,
  getSigns: PropTypes.func.isRequired,
  loadSequences: PropTypes.func.isRequired,
  loadSchedules: PropTypes.func.isRequired,
  loadPlaylists: PropTypes.func.isRequired,
  loadCampaigns: PropTypes.func.isRequired,
  loadCampaign: PropTypes.func.isRequired,
  loadAllAssignments: PropTypes.func.isRequired,
  addNotification: PropTypes.func.isRequired,
  clearCampaignForm: PropTypes.func.isRequired,
  getCampaignSchedule: PropTypes.func.isRequired,
  campaignSchedule: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  previousLocation: state.app.previousLocation,
  auth: state.auth,
  users: state.users,
  folders: state.folders,
  signs: state.signs,
  sequences: state.sequences,
  schedules: state.schedules,
  playlists: state.playlists,
  campaigns: state.campaigns,
  assignments: state.assignments,
  campaignSchedule: state.campaignSchedule,
});
const mapDispatchToProps = {
  getUsers,
  loadFolders,
  getSigns,
  loadSequences,
  loadSchedules,
  loadPlaylists,
  loadCampaigns,
  loadCampaign,
  loadAllAssignments,
  clearCampaignForm,
  addNotification,
  getCampaignSchedule,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CampaignPage));
