import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';

export default class DuplicateUrlRulesetDialog extends Component {
  getInitialState = () => ({ name: '' });

  state = this.getInitialState();

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { show } = nextProps;

    if (!show) {
      this.setState(this.getInitialState());
    }
  }

  handleChangeName = event => this.setState({ name: event.target.value });

  handleClickDuplicateUrlRuleset = () => {
    const { onSuccess } = this.props;
    const { name } = this.state;

    if (this.validate()) {
      onSuccess(name);
    }
  };

  validate = () => {
    const { urlRulesets, addNotification } = this.props;
    const { name } = this.state;

    if (!name) {
      addNotification({ type: 'danger', text: 'Type a URL Ruleset name.' });

      return false;
    }

    if (urlRulesets.some(urlRuleset => urlRuleset.name === name)) {
      addNotification({ type: 'danger', text: 'That URL Ruleset name already exists.' });

      return false;
    }

    return true;
  };

  render() {
    const { show, onHide, original } = this.props;
    const { name } = this.state;

    if (!original) {
      return null;
    }

    return (
      <Modal dialogClassName="url-ruleset-dialog" backdrop="static" show={show} onHide={onHide}>
        <Modal.Header>
          <Modal.Title>Duplicate URL Ruleset</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Duplicate <b>{original.name}</b>. Enter a name for the new URL Ruleset.
          </p>
          <div className="form-group input-name">
            <label>URL Ruleset</label>
            <input
              className="form-control"
              placeholder="Enter a name"
              value={name}
              onChange={this.handleChangeName}
              autoFocus
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn-cancel" onClick={onHide}>
            Cancel
          </Button>
          <Button
            className="btn-duplicate"
            bsStyle="primary"
            onClick={this.handleClickDuplicateUrlRuleset}
          >
            Duplicate URL Ruleset
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

DuplicateUrlRulesetDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  urlRulesets: PropTypes.array.isRequired,
  original: PropTypes.object,
  addNotification: PropTypes.func.isRequired,
};
