import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SSTable from '../SSTable/SSTable';
import SSColumn from '../SSTable/SSColumn';
import ToolTipIcon from '../ToolTipIcon/ToolTipIcon';
import { buildTagList } from '../../utils/tagList';

export default class TierLocationsTable extends Component {
  getLocation = item => item.parent + item.name;

  getTags = item => buildTagList(item);

  getRemove = item => {
    const { onRemove } = this.props;

    return (
      <ToolTipIcon toolText="Remove" onClick={() => onRemove(item)}>
        remove_circle_outline
      </ToolTipIcon>
    );
  };

  render() {
    const {
      auth: {
        user: {
          permissions: { Writer },
        },
      },
      data,
    } = this.props;

    return (
      <SSTable data={data} headerHeight={50} rowHeight={50} rowsCount={data.length - 1} zebra>
        <SSColumn header="Location" cell={this.getLocation} />
        <SSColumn header="Tags" cell={this.getTags} />
        {Writer && <SSColumn width={5} header="Remove" cell={this.getRemove} />}
      </SSTable>
    );
  }
}

TierLocationsTable.propTypes = {
  auth: PropTypes.object.isRequired,
  data: PropTypes.array,
  onRemove: PropTypes.func.isRequired,
};
