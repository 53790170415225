import getPlaylistsForVideo from './getPlaylistsForVideo';
import getSequencesForPlaylist from './getSequencesForPlaylist';

const getSequencesForVideo = (asset, signs, sequences, playlists) => {
  if (!asset || !signs || !sequences || !playlists) {
    return [];
  }

  const used = [];

  getPlaylistsForVideo(asset, playlists).forEach(video =>
    getSequencesForPlaylist(video, signs, sequences).forEach(sequence => {
      if (!used.find(item => item.id === sequence.id)) {
        used.push(sequence);
      }
    }),
  );

  return used.sort((a, b) => a.name.localeCompare(b.name));
};

export default getSequencesForVideo;
