import React from 'react';
import { Alert } from 'react-bootstrap';

export const getDeployer = deploy => (deployDetails = {}) => () => deploy(deployDetails);

export const getScheduleMessage = downloadSchedule => {
  const { start_hours, start_mins, end_hours, end_mins, timezone } = downloadSchedule;
  const [startHours, startMins, endHours, endMins] = [
    start_hours,
    start_mins,
    end_hours,
    end_mins,
  ].map(item => `${item}`.padStart(2, '0'));

  return (
    <Alert bsStyle="warning">
      Client has a Download Schedule set.
      <br />
      Content will be downloaded between{' '}
      <b>
        {startHours}:{startMins}
      </b>{' '}
      and{' '}
      <b>
        {endHours}:{endMins}
      </b>
      .
      <br /> (All times are in timezone: <b>{timezone}</b>).
    </Alert>
  );
};
