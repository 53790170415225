import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { components } from './AppModals';

export const AppModal = ({ appModal }) => {
  const { show, title, content, data, cancelFn, confirmFn } = appModal;

  if (!show) {
    return null;
  }

  const { Component, attributes, dialogClassName } = components[content];

  return (
    <Modal
      data-test="app-modal"
      className="app-modal"
      show={show}
      dialogClassName={dialogClassName}
    >
      {title && (
        <Modal.Header>
          <Modal.Title data-test="app-modal-title">{title}</Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body data-test="app-modal-body">
        <Component {...attributes} data={data} />
      </Modal.Body>
      {(cancelFn || confirmFn) && (
        <Modal.Footer data-test="app-modal-body">
          {cancelFn && (
            <Button data-test="app-modal-cancel" onClick={cancelFn}>
              Close
            </Button>
          )}
          {confirmFn && (
            <Button data-test="app-modal-ok" className="btn btn-primary" onClick={confirmFn}>
              OK
            </Button>
          )}
        </Modal.Footer>
      )}
    </Modal>
  );
};

AppModal.propTypes = {
  appModal: PropTypes.shape({
    show: PropTypes.bool,
    title: PropTypes.string,
    content: PropTypes.string,
    data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    cancelFn: PropTypes.func,
    confirmFn: PropTypes.func,
  }).isRequired,
};
