import 'isomorphic-fetch';
import fetchCheck from '../redux/middleware/fetchCheck';
import * as types from './actionTypes';
import { /* showAppModal, */ hideAppModal } from './appActions';
import { addNotification } from './notificationActions';
import errorMessage from '../utils/errorMessage';
import { delay } from '../utils/delay';

export const url = '/cms/data/v1/devices/';

export const credentials = 'include';

export const headers = { Accept: 'application/json', 'Content-Type': 'application/json' };

export const select = ids => ({ type: types.DEVICES_SELECT, ids });

export const unselectAll = () => ({ type: types.DEVICES_UNSELECT_ALL });

export const request = () => ({ type: types.DEVICES_REQUEST });

export const requestWithLoadingStatus = () => ({ type: types.DEVICES_REQUEST_WITH_LOADING_STATUS });

export const requestFailed = error => ({ type: types.DEVICES_REQUEST_FAILED, error });

export const requestSuccessAllDevices = result => ({
  type: types.DEVICES_REQUEST_SUCCESS_ALL_DEVICES,
  result,
});

export const requestSuccessDevices = result => ({
  type: types.DEVICES_REQUEST_SUCCESS_DEVICES,
  result,
});

export const requestSuccessDevice = result => ({
  type: types.DEVICES_REQUEST_SUCCESS_DEVICE,
  result,
});

export const requestSuccessDeviceInfo = result => ({
  type: types.DEVICES_REQUEST_SUCCESS_DEVICE_INFO,
  result,
});

export const requestSuccessDeviceErrors = result => ({
  type: types.DEVICES_REQUEST_SUCCESS_DEVICE_ERRORS,
  result,
});

export const requestSuccessStatus = result => ({
  type: types.DEVICES_REQUEST_SUCCESS_STATUS,
  result,
});

export const requestSuccessVersions = result => ({
  type: types.DEVICES_REQUEST_SUCCESS_VERSIONS,
  result,
});

export const setCopyConfig = device => ({ type: types.DEVICES_SET_COPY_CONFIG, device });

export const editDeviceForm = (key, value) => ({
  type: types.DEVICES_DEVICE_FORM_EDIT,
  key,
  value,
});

export const clearDeviceForm = () => ({ type: types.DEVICES_DEVICE_FORM_CLEAR });

export const fetchDevices = querystring => {
  const sort = 'sort=location_path+asc,+description+asc';
  let sortedquery = querystring;

  if (!querystring) {
    sortedquery = `?${sort}`;
  } else if (!querystring.includes('sort=')) {
    sortedquery += `&${sort}`;
  }

  return fetch(url + sortedquery, { method: 'GET', credentials, headers })
    .then(res => fetchCheck(res))
    .then(res => res.json());
};

export const fetchDevice = id =>
  fetch(url + id, { method: 'GET', credentials, headers })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const fetchDeviceInfo = id =>
  fetch(`${url + id}/info`, { method: 'GET', credentials, headers })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const fetchDeviceErrors = id =>
  fetch(`${url + id}/errors`, { method: 'GET', credentials, headers })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const fetchStatus = ids => {
  const querystring = ids && ids.length ? `?ids=${ids.join(',')}` : '';

  return fetch(`${url}status${querystring}`, { method: 'GET', credentials, headers })
    .then(res => fetchCheck(res))
    .then(res => res.json());
};

export const fetchVersions = () =>
  fetch(`${url}versions`, { method: 'GET', credentials, headers })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const copyConfig = (from, to) =>
  fetch(`${url}copyconfig`, {
    method: 'POST',
    credentials,
    headers,
    body: JSON.stringify({ from, to }),
  })
    .then(res => fetchCheck(res))
    .then(res => res.json());

// TODO: app wide spinner, deploy report modal and deploy error modal are turned off for now
// - app wide spinner is turned off because it blocks the UI and deploy can take a long time (seconds to minutes)
// - deploy report modal is turned off because it can return way too late after the deploy is done and the user has moved on to other things
// - deploy error modal is turned off because even successful deploys can fail with timeout on the client side after 60 seconds
export const deployContent = ({ ...locationParams }) => async dispatch => {
  const { text } = locationParams;
  // const isReportMode = Boolean(locationParams.isReportMode);

  dispatch(addNotification({ type: 'info', text }));
  // dispatch(showAppModal({ show: true, content: 'Spinner' }));

  await delay(500);

  return fetch(`${url}deploy`, {
    method: 'POST',
    credentials,
    headers,
    body: JSON.stringify(locationParams),
  })
    .then(res => fetchCheck(res))
    .then(res => res.json())
    .then(res => {
      const { err } = res;
      // const { err, devices } = res;
      // const data = {
      //   err,
      //   isReportMode,
      //   devices: devices.sort((a, b) => a.location.localeCompare(b.location)),
      // };

      // dispatch(showAppModal({ show: true, content: 'DeployReport', title: `Deploy Report: ${deployTo}`, data, cancelFn: () => dispatch(hideAppModal()) }));

      if (err) {
        dispatch(
          addNotification({
            type: 'danger',
            text: `Deployment Encountered an Error: ${errorMessage(err)}`,
          }),
        );
      } else {
        dispatch(
          addNotification({
            type: 'success',
            text: text?.replace('Deploying', 'Deployed'),
          }),
        );
      }
    })
    .catch(() => {
      dispatch(hideAppModal());
      // dispatch(showAppModal({ show: true, content: 'ErrorModal', title: `Deploy Error: ${deployTo}`, data: { msg: '' }, cancelFn: () => dispatch(hideAppModal()) }));
      dispatch(
        addNotification({ type: 'danger', text: text?.replace('Deploying', 'Deployment Failed') }),
      );
    });
};

export const overrideDownloadSchedule = id =>
  fetch(`${url + id}/overrideDownloadSchedule`, { method: 'POST', credentials, headers })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const restartPlayer = id =>
  fetch(`${url + id}/restart`, { method: 'POST', credentials, headers })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const rebootDevice = id =>
  fetch(`${url + id}/reboot`, { method: 'POST', credentials, headers })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const upgradeAllDevices = version =>
  fetch(`${url}upgrade`, {
    method: 'POST',
    credentials,
    headers,
    body: JSON.stringify({ version }),
  })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const upgradeDevice = (id, version) =>
  fetch(`${url + id}/upgrade`, {
    method: 'POST',
    credentials,
    headers,
    body: JSON.stringify({ version }),
  })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const requestDiagnostics = id =>
  fetch(`${url + id}/diagnostics`, { method: 'POST', credentials, headers })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const requestScreenshot = id =>
  fetch(`${url + id}/screenshot`, { method: 'POST', credentials, headers })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const registerDevice = (id, description) =>
  fetch(url, { method: 'POST', credentials, headers, body: JSON.stringify({ id, description }) })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const clearData = id =>
  fetch(`${url + id}/cleardata`, { method: 'POST', credentials, headers })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const unregisterDevice = id =>
  fetch(url + id, { method: 'DELETE', credentials, headers })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const updateDevice = device =>
  fetch(url + device.id, { method: 'PUT', credentials, headers, body: JSON.stringify(device) })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const getAllDevices = querystring => dispatch => {
  dispatch(requestWithLoadingStatus());
  const qs = `${querystring}${querystring ? '&' : '?'}count=100000000`;

  return fetchDevices(qs).then(
    res => dispatch(requestSuccessAllDevices(res)),
    err => dispatch(requestFailed(err)),
  );
};

export const getDevices = querystring => dispatch => {
  dispatch(requestWithLoadingStatus());

  return fetchDevices(querystring).then(
    res => dispatch(requestSuccessDevices(res)),
    err => dispatch(requestFailed(err)),
  );
};

export const getDevice = id => dispatch => {
  dispatch(requestWithLoadingStatus());

  return fetchDevice(id).then(
    res => dispatch(requestSuccessDevice(res)),
    err => dispatch(requestFailed(err)),
  );
};

export const getDeviceInfo = id => dispatch => {
  dispatch(request());

  return fetchDeviceInfo(id).then(
    res => dispatch(requestSuccessDeviceInfo(res)),
    err => dispatch(requestFailed(err)),
  );
};

export const getDeviceErrors = id => dispatch => {
  dispatch(request());

  return fetchDeviceErrors(id).then(
    res => dispatch(requestSuccessDeviceErrors(res)),
    err => dispatch(requestFailed(err)),
  );
};

export const getStatus = ids => dispatch => {
  dispatch(request());

  return fetchStatus(ids).then(
    res => dispatch(requestSuccessStatus(res)),
    err => dispatch(requestFailed(err)),
  );
};

export const getVersions = () => dispatch => {
  dispatch(request());

  return fetchVersions().then(
    res => dispatch(requestSuccessVersions(res)),
    err => dispatch(requestFailed(err)),
  );
};
