import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';

export default class RequestDiagnosticsDialog extends Component {
  getDevices = () => {
    const { devices } = this.props;
    const names = devices.map((item, index) => (
      <li key={index}>
        <code>{item.name}</code> {item.description}
      </li>
    ));

    return <ol>{names}</ol>;
  };

  render() {
    const { show, onHide, onSuccess, devices } = this.props;

    if (!devices) {
      return null;
    }

    return (
      <Modal
        data-test="modal-requestdiagnostics"
        dialogClassName="device-dialog"
        show={show}
        onHide={onHide}
      >
        <Modal.Header>
          <Modal.Title data-test="modal-requestdiagnostics-title">
            Request Device Diagnostics
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Request diagnostics from:</p>
          {this.getDevices()}
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn-cancel" onClick={onHide}>
            Cancel
          </Button>
          <Button
            data-test="modal-requestdiagnostics-button-request"
            className="btn-add"
            bsStyle="primary"
            onClick={onSuccess}
          >
            Request Diagnostics
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

RequestDiagnosticsDialog.propTypes = {
  devices: PropTypes.array,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};
