import 'isomorphic-fetch';
import fetchCheck from '../redux/middleware/fetchCheck';
import * as types from './actionTypes';

export const url = '/cms/data/v1/users/';

export const urlUser = '/cms/data/v1/user/';

export const credentials = 'include';

export const headers = { Accept: 'application/json', 'Content-Type': 'application/json' };

let body;

export const select = ids => ({ type: types.USERS_SELECT, ids });

export const unselectAll = () => ({ type: types.USERS_UNSELECT_ALL });

export const request = () => ({ type: types.USERS_REQUEST });

export const requestWithLoadingStatus = () => ({ type: types.USERS_REQUEST_WITH_LOADING_STATUS });

export const requestFailed = error => ({ type: types.USERS_REQUEST_FAILED, error });

export const requestSuccessUsers = result => ({
  type: types.USERS_REQUEST_SUCCESS_USERS,
  result,
});

export const requestSuccessUser = result => ({ type: types.USERS_REQUEST_SUCCESS_USER, result });

export const clearUser = () => ({ type: types.USERS_CLEAR_USER });

export const fetchUsers = query =>
  fetch(url + (query || ''), { method: 'GET', credentials, headers })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const fetchUser = id =>
  fetch(url + id, { method: 'GET', credentials, headers })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const fetchMe = () =>
  fetch(urlUser, { method: 'GET', credentials, headers })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const addUser = user =>
  fetch(url, { method: 'POST', credentials, headers, body: JSON.stringify(user) })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const updateUser = (id, user) =>
  fetch(url + id, { method: 'PUT', credentials, headers, body: JSON.stringify(user) })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const updateMe = user =>
  fetch(urlUser, { method: 'PUT', credentials, headers, body: JSON.stringify(user) })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const deleteUser = id =>
  fetch(url + id, { method: 'DELETE', credentials, headers })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const uploadAvatar = file => {
  body = new FormData();
  body.append('file', file);

  return fetch(`${urlUser}avatar`, {
    method: 'POST',
    credentials,
    headers: { Accept: 'application/json' },
    body,
  })
    .then(res => fetchCheck(res))
    .then(res => res.json());
};

export const getUsersDetails = () => dispatch => {
  dispatch(requestWithLoadingStatus());

  return fetchUsers('details').then(
    res => dispatch(requestSuccessUsers(res)),
    err => dispatch(requestFailed(err)),
  );
};

export const getUsers = () => dispatch => {
  dispatch(requestWithLoadingStatus());

  return fetchUsers().then(
    res => dispatch(requestSuccessUsers(res)),
    err => dispatch(requestFailed(err)),
  );
};

export const getUser = id => dispatch => {
  dispatch(requestWithLoadingStatus());

  return fetchUser(id).then(
    res => dispatch(requestSuccessUser(res)),
    err => dispatch(requestFailed(err)),
  );
};

export const getMe = () => dispatch => {
  dispatch(requestWithLoadingStatus());

  return fetchMe().then(
    res => dispatch(requestSuccessUser(res)),
    err => dispatch(requestFailed(err)),
  );
};
