import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Panel } from 'react-bootstrap';
import pluralize from 'pluralize';
import SSTable from '../SSTable/SSTable';
import SSColumn from '../SSTable/SSColumn';
import ToolTipIcon from '../ToolTipIcon/ToolTipIcon';
import UserProfile from '../UserProfile/UserProfile';
import AssetImage from '../Assets/AssetImage';
import { buildTagList } from '../../utils/tagList';
import { isoDateToString } from '../../utils/formatUnits';

export default class UsedInSeqsOrSchedsTable extends Component {
  state = { open: false };

  getHeader = () => {
    const { type, mode, data } = this.props;
    const { open } = this.state;

    return (
      <div data-test={`used-${mode}s-title`} className="toggle" onClick={this.toggle}>
        <i className="material-icons material-caret inline">
          {open ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}
        </i>
        Used in {pluralize(this.capitalize(mode), data.length, true)}
        {['playlist', 'video'].includes(type) && (
          <span className="text-light text-small">
            {' '}
            Including <i>Video Play Fullscreen</i> events
          </span>
        )}
      </div>
    );
  };

  getThumbnail = item => {
    const { mode } = this.props;

    return <AssetImage id={item.id} type={mode} item={item} />;
  };

  getName = item => {
    const { mode } = this.props;

    return (
      <div className="item-name">
        <div>
          <Link to={`/${mode}s/${item.id}`}>{item.name}</Link>
        </div>
        <div className="item-tags">Folder: {item.parent}</div>
        <div className="item-tags">Tags: {buildTagList(item)}</div>
      </div>
    );
  };

  getPreview = item => {
    const { mode } = this.props;

    return (
      <ToolTipIcon
        toolText={`Preview ${item.name}`}
        href={`/player/?${mode}=${item.id}`}
        target="_blank"
      >
        play_circle_filled
      </ToolTipIcon>
    );
  };

  getCreated = item => {
    const { users } = this.props;

    return (
      <div>
        <UserProfile userId={item.createdBy} users={users} />
        <div className="date">{isoDateToString(item.createdDate)}</div>
      </div>
    );
  };

  getModified = item => {
    const { users } = this.props;

    return (
      <div>
        <UserProfile userId={item.modifiedBy} users={users} />
        <div className="date">{isoDateToString(item.modifiedDate)}</div>
      </div>
    );
  };

  toggle = () => {
    const { open } = this.state;

    this.setState({ open: !open });
  };

  capitalize = s => s.charAt(0).toUpperCase() + s.slice(1);

  render() {
    const { mode, data } = this.props;
    const { open } = this.state;

    if (!data) {
      return null;
    }

    return (
      <Panel className="used-in">
        <Panel.Heading>{this.getHeader()}</Panel.Heading>
        <Panel.Body>
          {open && Boolean(data.length) && (
            <SSTable
              data={data}
              className="responsive table-assignments"
              rowsCount={data.length - 1}
              zebra
            >
              <SSColumn width={5} cell={this.getThumbnail} header="" />
              <SSColumn width={5} cell={this.getPreview} header="Preview" />
              <SSColumn cell={this.getName} header={this.capitalize(mode)} />
              <SSColumn cell={this.getCreated} header="Created" />
              <SSColumn cell={this.getModified} header="Modified" />
            </SSTable>
          )}
        </Panel.Body>
      </Panel>
    );
  }
}

UsedInSeqsOrSchedsTable.propTypes = {
  mode: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  data: PropTypes.array,
  auth: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
};
