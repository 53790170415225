import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import SSTable from '../SSTable/SSTable';
import SSColumn from '../SSTable/SSColumn';
import ToolTipIcon from '../ToolTipIcon/ToolTipIcon';
import Avatar from '../Avatar/Avatar';

export default class UsersTable extends Component {
  getUserBadge = user => {
    const {
      user: { user_id },
    } = this.props;

    return (
      <div>
        <Avatar user={user} />
        <span className="users-username">
          <Link className="semi-bold" to={this.editUserPath(user)}>
            {user.name}
          </Link>
          {user.superuser === 1 && <span className="text-light"> (superuser)</span>}
          {+user_id === +user.user_id && <span className="text-light"> (me)</span>}
        </span>
      </div>
    );
  };

  getPersonal = user => (
    <div>
      <div className="semi-bold">{`${user.firstname} ${user.lastname}`}</div>
      <div className="text-light text-small">{user.email}</div>
    </div>
  );

  getRoleInfo = user => {
    const role = this.getRole(user.role_id) || { desc: 'Role not found', perm: '' };
    const permissions = role.perm
      .split('|')
      .filter(item => item)
      .join(', ');
    const tip = <Tooltip id={`users-tooltip-${user.id}`}>{permissions}</Tooltip>;

    return (
      <OverlayTrigger overlay={tip}>
        <span>
          {user.role}
          <br />
          <span className="text-light text-small">{role.desc}</span>
        </span>
      </OverlayTrigger>
    );
  };

  getRole = id => {
    const { roles } = this.props;

    return roles.find(item => +item.role_id === +id);
  };

  getEdit = user => (
    <ToolTipIcon toolText="Edit User">
      <Link to={this.editUserPath(user)}>mode_edit</Link>
    </ToolTipIcon>
  );

  editUserPath = user => {
    const {
      user: { user_id },
    } = this.props;

    if (user_id === user.user_id) {
      return '/user/';
    }

    return `/users/${user.user_id}`;
  };

  render() {
    const { data, roles, selected, onClick, onDoubleClick } = this.props;

    if (!data || !roles.length) {
      return null;
    }

    return (
      <SSTable
        className="responsive table-users"
        data={data}
        rowHeight={50}
        rowsCount={data.length - 1}
        doubleClick={onDoubleClick}
        onSelect={onClick}
        selected={selected}
        selectable
        zebra
      >
        <SSColumn header="User" cell={this.getUserBadge} />
        <SSColumn header="Personal Details" cell={this.getPersonal} />
        <SSColumn header="Role" cell={this.getRoleInfo} />
        <SSColumn width={5} header="Edit" cell={this.getEdit} />
      </SSTable>
    );
  }
}

UsersTable.propTypes = {
  data: PropTypes.array,
  roles: PropTypes.array,
  user: PropTypes.object.isRequired,
  selected: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  onDoubleClick: PropTypes.func.isRequired,
};
