import fileTypes from '../../common/fileTypes';

export const trimFileExt = fileName => {
  if (!fileName) {
    return '';
  }

  const index = fileName.lastIndexOf('.');

  if (index === -1) {
    return fileName;
  }

  return fileName.toString().substr(0, index);
};

export const restoreFileExt = (newFileName, originalFileNameWithExtension) => {
  const index = originalFileNameWithExtension.lastIndexOf('.');

  if (index === -1) {
    return newFileName;
  }

  const extension = originalFileNameWithExtension.substr(index);

  return newFileName + extension;
};

export const getSupportedExts = () => Object.values(fileTypes).reduce((a, b) => [...a, ...b]);

export const addSupportedAttr = files => {
  const supported = getSupportedExts();

  return files.map(item => {
    const ext = (item.name.split('.').pop() || '').toLowerCase();

    item.supported = supported.includes(`.${ext}`);

    return item;
  });
};

export const isUnixHiddenPath = path => /(\/|^)\.[^/.]/g.test(path);
