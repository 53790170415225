import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import SSTable from '../SSTable/SSTable';
import SSColumn from '../SSTable/SSColumn';
import ToolTipIcon from '../ToolTipIcon/ToolTipIcon';
import DeviceUptimeDiagram from './DeviceUptimeDiagram';
import {
  getHealthcheckReportIcon,
  getDiagnosticsButton,
  getDownloadStatusIcon,
  getIdNode,
  getOnlineStatusIcon,
  getScreenshotButton,
  getSyncInfo,
} from './DeviceUtils';
import { getPermissions } from '../../utils/getPermissions';
import { buildTagList } from '../../utils/tagList';

export default class DevicesTable extends Component {
  getId = device => {
    const { users } = this.props;

    return getIdNode(device, users);
  };

  getName = device => (
    <div className="entry-name">
      <div>{device.description}</div>
      <div>Tags: {buildTagList(device)}</div>
    </div>
  );

  getLocation = device => {
    if (device.location_path === '/') {
      return 'No Location set';
    }

    return device.location_path;
  };

  getVersion = device => (
    <div className="entry-name">
      <div>{device.version}</div>
      <div>
        {device.device_type}
        <br />
        {device.firmware_version}
      </div>
    </div>
  );

  getPrestagedDevice = device => (
    <ToolTipIcon
      id={`tooltip-sync-${device.name}`}
      className={device.prestaged ? '' : 'disabled'}
      toolText="Prestaged Device"
    >
      settings
    </ToolTipIcon>
  );

  getRestart = device => {
    let status = 'disabled';
    let text = 'Scheduled Restart';

    if (device.restart_enabled) {
      const hour =
        device.restart_hour < 10 ? `0${device.restart_hour.toString()}` : device.restart_hour;
      const min =
        device.restart_min < 10 ? `0${device.restart_min.toString()}` : device.restart_min;

      status = '';
      text += `: ${hour}:${min}`;
    }

    return (
      <ToolTipIcon className={status} toolText={text}>
        restore
      </ToolTipIcon>
    );
  };

  getVideoWall = device => {
    const sync = getSyncInfo(device);
    const text = (
      <div className="text-left">
        <div>{sync.vwDetails}</div>
        {!device.vw_enabled ? null : (
          <div>
            <div>
              Panel top/left: {device.vw_top}, {device.vw_left}
            </div>
            <div>
              Panel w/h: {device.vw_width} x {device.vw_height}
            </div>
            <div>
              Wall w/h: {device.vw_wall_width} x {device.vw_wall_height}
            </div>
          </div>
        )}
        <div>{sync.syncType}</div>
        <i>{sync.syncDetails}</i>
      </div>
    );

    return (
      <ToolTipIcon id={`tooltip-sync-${device.name}`} className={sync.className} toolText={text}>
        featured_video
      </ToolTipIcon>
    );
  };

  getUptimeButton = device => {
    const { heartbeatPercent } = device;
    const thresholdOffline = 50;
    const thresholdIdle = 90;
    let className = 'color-red-500';

    if (!device.idle) {
      className = 'outdated';
    } else if (heartbeatPercent >= thresholdIdle) {
      className = 'color-green-500';
    } else if (heartbeatPercent >= thresholdOffline) {
      className = 'color-amber-500';
    }

    const toolText = (
      <Popover
        id={`popover-screenshot-${device.name}`}
        className="popover-uptime-diagram"
        title={`${heartbeatPercent}% Uptime`}
      >
        <DeviceUptimeDiagram device={device} height={150} />
      </Popover>
    );

    return (
      <div className="rel inline-block">
        <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={toolText}>
          <span className={`tool-tip-icon material-icons not-btn ${className}`}>equalizer</span>
        </OverlayTrigger>
      </div>
    );
  };

  getEdit = device => {
    const { auth, activeCampaignId } = this.props;
    const p = getPermissions(auth);
    const overview = (
      <ToolTipIcon toolText="Device Overview" href={`/devices/${device.id}`}>
        remove_red_eye
      </ToolTipIcon>
    );
    const edit = (
      <ToolTipIcon toolText="Edit Device Settings" href={`/devices/${device.id}?edit`}>
        mode_edit
      </ToolTipIcon>
    );
    const preview = (
      <ToolTipIcon
        toolText="Device Preview"
        href={`/player/?device=${device.id}&campaign=${activeCampaignId}`}
        target="_blank"
      >
        play_circle_filled
      </ToolTipIcon>
    );

    return (
      <div className="device-icons edit">
        {overview}
        {p.atLeastTierStandardOrSuperUser && p.deviceManager && edit}
        {preview}
      </div>
    );
  };

  getInfo = device => {
    const { auth, onDiagnosticsClick, onScreenshotClick } = this.props;
    const p = getPermissions(auth);

    return (
      <div className="device-icons info">
        <div
          style={{
            position: 'absolute',
            left: -30,
          }}
        >
          {getHealthcheckReportIcon(device, auth)}
        </div>
        {getOnlineStatusIcon(device)}
        {getDownloadStatusIcon(device)}
        {p.atLeastTierStandardOrSuperUser && this.getUptimeButton(device)}
        {p.atLeastTierStandardOrSuperUser && getDiagnosticsButton(device, onDiagnosticsClick)}
        {p.atLeastTierStandardOrSuperUser && getScreenshotButton(device, onScreenshotClick)}
      </div>
    );
  };

  getSettings = device => (
    <div className="device-icons settings">
      {this.getPrestagedDevice(device)}
      {this.getRestart(device)}
      {this.getVideoWall(device)}
    </div>
  );

  render() {
    const { auth, users, data, selected, onClick, onDoubleClick } = this.props;
    const p = getPermissions(auth);

    if (!users || !data) {
      return null;
    }

    return (
      <SSTable
        className="responsive table-devices"
        data={data}
        rowHeight={50}
        rowsCount={data.length - 1}
        onSelect={onClick}
        doubleClick={onDoubleClick}
        selected={selected}
        selectable
        zebra
      >
        <SSColumn width={5} header="Device ID" cell={this.getId} />
        <SSColumn header="Device Name" cell={this.getName} />
        <SSColumn header="Location" cell={this.getLocation} />
        {p.atLeastTierStandardOrSuperUser && (
          <SSColumn width={10} header="Player, Device, FW" cell={this.getVersion} />
        )}
        <SSColumn width={5} header="Info" cell={this.getInfo} />
        {p.atLeastTierStandardOrSuperUser && (
          <SSColumn width={5} header="Settings" cell={this.getSettings} />
        )}
        <SSColumn width={5} header="Edit" cell={this.getEdit} />
      </SSTable>
    );
  }
}

DevicesTable.propTypes = {
  auth: PropTypes.object.isRequired,
  users: PropTypes.array,
  activeCampaignId: PropTypes.number.isRequired,
  data: PropTypes.array,
  selected: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  onDoubleClick: PropTypes.func.isRequired,
  onDiagnosticsClick: PropTypes.func.isRequired,
  onScreenshotClick: PropTypes.func.isRequired,
};
