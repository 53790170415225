import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import ToolTipIcon from '../ToolTipIcon/ToolTipIcon';
import DeviceTitle from './DeviceTitle';
import {
  getFile,
  getCommandStatus,
  getCommandAndFileTimes,
  getCommandSpinner,
} from './DeviceUtils';

export default class DiagnosticsDialog extends Component {
  state = { height: 0 };

  componentDidMount() {
    this.updateHeight();
    window.addEventListener('resize', this.updateHeight);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateHeight);
  }

  updateHeight = () => this.setState({ height: window.innerHeight - 280 });

  render() {
    const { show, onHide, users, device, onRequestDiagnostics } = this.props;
    const { height } = this.state;

    if (!users || !device) {
      return null;
    }

    const commandStatus = getCommandStatus(device, 'diagnostics');
    const time = getCommandAndFileTimes(device, 'diagnostics');
    const file = getFile(device, 'diagnostics');

    if (!file) {
      return null;
    }

    const fileUrl = `/cms/data/v1/devices/${device.id}/file/${file.filename}?${file.timestamp}`;

    return (
      <Modal
        data-test="modal-diagnostics"
        dialogClassName="device-dialog wide"
        show={show}
        onHide={onHide}
      >
        <Modal.Header closeButton>
          <span className="menu">
            {getCommandSpinner(device, 'diagnostics')}
            <ToolTipIcon toolText="Request Diagnostics" onClick={onRequestDiagnostics}>
              assignment
            </ToolTipIcon>
            <ToolTipIcon toolText="Open in New Tab" href={fileUrl} target="_blank">
              open_in_new
            </ToolTipIcon>
          </span>
          <Modal.Title>Device Diagnostics</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="pull-right">
            <div className="device-request-time">
              <span>{time.commandText}</span>
              {time.commandTime}
            </div>
            <div className="device-request-time">
              <span>Uploaded:</span>
              {time.fileTime}
            </div>
            {commandStatus && <div className="color-red-500">Request failed</div>}
          </div>
          <DeviceTitle users={users} device={device} />
          <iframe style={{ height }} src={fileUrl} title="Diagnostics" />
        </Modal.Body>
      </Modal>
    );
  }
}

DiagnosticsDialog.propTypes = {
  users: PropTypes.array,
  device: PropTypes.object,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onRequestDiagnostics: PropTypes.func.isRequired,
};
