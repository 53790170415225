import 'isomorphic-fetch';
import { SOLR_DELAY } from '../../constants';
import fetchCheck from '../redux/middleware/fetchCheck';
import * as types from './actionTypes';

const url = '/cms/data/v1/assets/';
const credentials = 'include';
const headers = { Accept: 'application/json', 'Content-Type': 'application/json' };

// upload asset
export const encodeTypesSuccess = result => ({ type: types.ASSETS_ENCODE_TYPES_SUCCESS, result });

export const uploadQueueAddItems = files => ({ type: types.UPLOAD_QUEUE_ADD_ITEMS, files });

export const validateStart = file => ({ type: types.VALIDATE_START, file });

export const validateComplete = file => ({ type: types.VALIDATE_COMPLETE, file });

export const validateFailed = (file, res) => ({ type: types.VALIDATE_FAILED, file, res });

export const uploadStart = file => ({ type: types.UPLOAD_START, file });

export const uploadProgress = file => ({ type: types.UPLOAD_PROGRESS, file });

export const uploadComplete = (id, asset) => ({ type: types.UPLOAD_COMPLETE, id, asset });

export const uploadFailed = (id, res) => ({ type: types.UPLOAD_FAILED, id, res });

export const uploadRemoveItem = id => ({ type: types.UPLOAD_REMOVE_ITEM, id });

export const encodingQueueAddItem = (id, queueItem) => ({
  type: types.UPLOAD_ENCODE_QUEUE_ADD_ITEM,
  id,
  queueItem,
});

export const encodingQueueUpdate = queue => ({ type: types.UPLOAD_ENCODE_QUEUE_UPDATE, queue });

// asset selector
export const assetSelectorRequest = () => ({ type: types.ASSET_SELECTOR_LOADING });

export const assetSelectorRequestSuccess = (result, path) => ({
  type: types.ASSET_SELECTOR_LOADED,
  result,
  path,
});

// assets
export const assetsRequest = () => ({ type: types.ASSETS_REQUEST });

export const assetsRequestSuccess = result => ({ type: types.ASSETS_REQUEST_SUCCESS, result });

export const assetsRequestFailed = error => ({ type: types.ASSETS_REQUEST_FAILED, error });

// asset
export const assetRequest = () => ({ type: types.ASSET_REQUEST });

export const assetRequestSuccess = result => ({ type: types.ASSET_REQUEST_SUCCESS, result });

export const assetRequestFailed = error => ({ type: types.ASSET_REQUEST_FAILED, error });

export const editAssetForm = (key, value) => ({ type: types.ASSET_FORM_EDIT, key, value });

export const clearAssetForm = () => ({ type: types.ASSET_FORM_CLEAR });

export const fetchAssets = query =>
  fetch(url + (query || ''), { method: 'GET', credentials, headers })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const fetchAsset = id =>
  fetch(url + id, { method: 'GET', credentials, headers })
    .then(res => fetchCheck(res))
    .then(res => res.json())
    .then(asset => {
      asset.validity_enabled = !(asset.startDate === '20100101' && asset.endDate === '20991231');

      return asset;
    });

export const updateAsset = (id, data) =>
  fetch(url + id, { method: 'PUT', credentials, headers, body: JSON.stringify(data) }).then(res =>
    fetchCheck(res),
  );

export const deleteAsset = id =>
  fetch(url + id, { method: 'DELETE', credentials, headers }).then(res => fetchCheck(res));

export const fetchQueue = () =>
  fetch(`${url}queue`, { method: 'GET', credentials, headers })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const deleteQueueItem = id =>
  fetch(`${url}queue/${id}`, { method: 'DELETE', credentials, headers })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const fetchEncodeTypes = () =>
  fetch(`${url}encodeTypes`, { method: 'GET', credentials, headers })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const getAssets = query => dispatch => {
  dispatch(assetsRequest());

  return fetchAssets(query).then(
    res => dispatch(assetsRequestSuccess(res)),
    err => dispatch(assetsRequestFailed(err)),
  );
};

export const getAsset = id => dispatch => {
  dispatch(assetRequest());

  return fetchAsset(id).then(
    res => dispatch(assetRequestSuccess(res)),
    err => dispatch(assetRequestFailed(err)),
  );
};

export const getEncodeTypes = () => dispatch =>
  fetchEncodeTypes().then(
    res => dispatch(encodeTypesSuccess(res)),
    err => dispatch(assetRequestFailed(err)),
  );

export const getEncodingQueue = () => dispatch =>
  fetchQueue().then(
    res => dispatch(encodingQueueUpdate(res)),
    err => dispatch(assetRequestFailed(err)),
  );

export const assetSelector = parent => {
  const query = `?parent=${parent || '/'}`;

  return dispatch => {
    dispatch(assetSelectorRequest());

    return fetchAssets(query).then(
      res => dispatch(assetSelectorRequestSuccess(res.assets, parent)),
      err => dispatch(assetsRequestFailed(err)),
    );
  };
};

export const queueUploads = files => dispatch =>
  dispatch(
    uploadQueueAddItems(
      files.map(file => ({
        id: file.id,
        type: file.type,
        name: file.name,
        parent: file.parent,
        size: file.size,
        supported: file.supported,
      })),
    ),
  );

const validateAsset = (file, dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const {
      assets: { uploading },
    } = getState();
    const xhr = new XMLHttpRequest();
    const { id, name, size, type, encodeType } = file;
    const isQueued =
      Array.isArray(uploading) &&
      uploading.some(upload => {
        const isComplete = upload.xhr !== undefined && upload.xhr.readyState === 4;

        return upload.id !== id && upload.name === name && !upload.error && !isComplete;
      });
    const data = { name, size, type, encodeType, isQueued };
    let res;

    dispatch(validateStart({ id }));

    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        switch (xhr.status) {
          case 200:
            dispatch(validateComplete({ id }));
            resolve(file);
            break;
          case 400:
            res = JSON.parse(xhr.response);
            dispatch(validateFailed({ id }, res));
            reject(file);
            break;
          default:
            reject(file);
        }
      }
    };

    xhr.open('POST', `${url}validate`);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.send(JSON.stringify(data));
  });
};

export const uploadAsset = (file, callback = () => {}) => (dispatch, getState) => {
  window.SignStix.uploading += 1;
  const xhr = new XMLHttpRequest();
  const data = new FormData();
  const { id, parent } = file;
  let loaded = 0;
  let progress = 0;

  if (!file.supported) {
    // setTimeout required to avoid loop
    setTimeout(() => {
      window.SignStix.uploading -= 1;
      callback();
    });

    return;
  }

  validateAsset(file, dispatch, getState)
    .then(() => {
      dispatch(uploadStart({ id, xhr, loaded, progress }));
      data.append('file', file);
      data.append('parent', parent);

      if (file.encodeType) {
        data.append('encodeType', file.encodeType);
      }

      xhr.upload.parent = parent;
      xhr.upload.addEventListener(
        'progress',
        event => {
          if (event.lengthComputable) {
            loaded = event.loaded;
            progress = Math.round((loaded * 100) / event.total);
            dispatch(uploadProgress({ id, loaded, progress }));
          }
        },
        false,
      );

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            const res = JSON.parse(xhr.response);

            dispatch(uploadComplete(id, res));

            if (res.status === ('waiting' || 'processing')) {
              dispatch(encodingQueueAddItem(id, res));
            } else {
              setTimeout(() => {
                dispatch(getAssets(window.location.search));
              }, SOLR_DELAY);
            }
          } else {
            const res = xhr.status === 400 ? JSON.parse(xhr.response) : {};

            dispatch(uploadFailed(id, res));
          }

          window.SignStix.uploading -= 1;

          callback();
        }
      };

      xhr.open('POST', url);

      if (file.encodeType) {
        xhr.setRequestHeader('X-Encode-Type', file.encodeType);
      }

      xhr.send(data);
    })
    .catch(() => {
      setTimeout(() => {
        window.SignStix.uploading -= 1;
        callback();
      });
    });
};
