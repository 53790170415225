import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { formatDistanceToNow, sub } from 'date-fns';
import { Grid, Row, Col, Panel } from 'react-bootstrap';
import { formatBytes } from '../../utils/formatUnits';
import { getPermissions } from '../../utils/getPermissions';
import UserProfile from '../UserProfile/UserProfile';
import DeviceUptimeDiagram from './DeviceUptimeDiagram';
import {
  getLocalTime,
  getOnlineStatusStyle,
  getDownloadStatusStyle,
  getOnlineStatusText,
  getDownloadStatusText,
} from './DeviceUtils';

export default class DeviceStatus extends Component {
  getErrors = () => {
    const {
      device: { errors },
    } = this.props;

    if (!errors || !errors.length) {
      return 'No errors';
    }

    return errors.map((item, index) => (
      <div key={index}>
        <span>{getLocalTime(item.occurred)}</span>
        {item.error}
      </div>
    ));
  };

  getThresholdColor = (type, value) => {
    const color = ['green', 'amber', 'red'];
    const threshold = {
      temp: [60, 80],
      disk: [60, 80],
      app_mem: [102400, 307200], // Player returns KBytes, thresholds: 100MB, 300MB
    };

    if (value === 0) {
      return 'unknown';
    }

    const active = threshold[type].find(item => item > value);
    const index = active ? threshold[type].indexOf(active) : 2;

    return ` color-${color[index]}-500`;
  };

  render() {
    const { auth, users, device } = this.props;
    const p = getPermissions(auth);

    if (!device) {
      return null;
    }

    const {
      location_path,
      serial = 'N/A',
      idle,
      uptime,
      modifiedBy,
      modifiedDate,
      updated,
      content_ver,
      temp,
      app_mem,
      ctrl_mem,
      disk,
      ip = 'N/A',
      mac,
      device_type = 'N/A',
      firmware_version,
      version = 'N/A',
      heartbeatPercent,
    } = device;

    if (!p.atLeastTierStandardOrSuperUser) {
      return (
        <div className="device-status">
          <Grid className="device-status-icons" fluid>
            <Row>
              <Col xs={4} sm={3} md={2} lg={1}>
                <Panel data-test="status-location">
                  <Panel.Body>
                    <span className="title">Location</span>
                    <span className="material-icons">place</span>
                    <span className="text">
                      <span>{location_path === '/' ? 'No Location set' : location_path}</span>
                    </span>
                  </Panel.Body>
                </Panel>
              </Col>
              <Col xs={4} sm={3} md={2} lg={1}>
                <Panel data-test="status-type">
                  <Panel.Body>
                    <span className="title">Device Type</span>
                    <span className="material-icons">devices_other</span>
                    <span className="text">
                      <span>
                        {device_type}
                        <span className="comment">
                          {Boolean(firmware_version) && `fw: ${firmware_version}`}
                        </span>
                      </span>
                    </span>
                  </Panel.Body>
                </Panel>
              </Col>
            </Row>
          </Grid>
        </div>
      );
    }

    return (
      <div className="device-status">
        <Grid className="device-status-icons" fluid>
          <Row>
            <Col xs={4} sm={3} md={2} lg={1}>
              <Panel data-test="status-location">
                <Panel.Body>
                  <span className="title">Location</span>
                  <span className="material-icons">place</span>
                  <span className="text">
                    <span>{location_path === '/' ? 'No Location set' : location_path}</span>
                  </span>
                </Panel.Body>
              </Panel>
            </Col>
            <Col xs={4} sm={3} md={2} lg={1}>
              <Panel data-test="status-serial">
                <Panel.Body>
                  <span className="title">Serial Number</span>
                  <span className="material-icons">fingerprint</span>
                  <span className="text">
                    <span>{serial}</span>
                  </span>
                </Panel.Body>
              </Panel>
            </Col>
            <Col xs={4} sm={3} md={2} lg={1}>
              <Panel data-test="status-uptime">
                <Panel.Body>
                  <span className="title">Uptime</span>
                  <span className="material-icons">power_settings_new</span>
                  <span className="text">
                    <span>
                      {Boolean(idle && uptime) &&
                        formatDistanceToNow(sub(new Date(), { seconds: uptime }))}
                    </span>
                  </span>
                </Panel.Body>
              </Panel>
            </Col>
            <Col xs={4} sm={3} md={2} lg={1}>
              <Panel data-test="status-lastmodify">
                <Panel.Body>
                  <span className="title">Last Modify</span>
                  <UserProfile userId={modifiedBy} users={users} />
                  <span className="text">
                    <span>
                      <br />
                      {getLocalTime(modifiedDate)}
                      <span className="comment">
                        {formatDistanceToNow(new Date(modifiedDate))} ago
                      </span>
                    </span>
                  </span>
                </Panel.Body>
              </Panel>
            </Col>
            <Col xs={4} sm={3} md={2} lg={1}>
              <Panel data-test="status-lastupdate">
                <Panel.Body>
                  <span className="title">Last Update</span>
                  <span className={`material-icons ${getOnlineStatusStyle(device)}`}>sync</span>
                  <span className="text">
                    <span>
                      {Boolean(idle) && getLocalTime(updated)}
                      <span className="comment">
                        {getOnlineStatusText(device).replace('Updated ', '')}
                      </span>
                    </span>
                  </span>
                </Panel.Body>
              </Panel>
            </Col>
            <Col xs={4} sm={3} md={2} lg={1}>
              <Panel data-test="status-content">
                <Panel.Body>
                  <span className="title">Content Version</span>
                  <span className={`material-icons ${getDownloadStatusStyle(device)}`}>
                    cloud_download
                  </span>
                  <span className="text">
                    <span>
                      {Boolean(idle) && getLocalTime(content_ver)}
                      <span className="comment">{getDownloadStatusText(device)}</span>
                    </span>
                  </span>
                </Panel.Body>
              </Panel>
            </Col>
            <Col xs={4} sm={3} md={2} lg={1}>
              <Panel data-test="status-temperature">
                <Panel.Body>
                  <span className="title">Temperature</span>
                  <span
                    className={`fa fa-thermometer-half ${this.getThresholdColor('temp', temp)}`}
                  />
                  <span className="text">
                    <span>{temp ? `${temp} ℃` : 'N/A'}</span>
                  </span>
                </Panel.Body>
              </Panel>
            </Col>
            <Col xs={4} sm={3} md={2} lg={1}>
              <Panel data-test="status-memory">
                <Panel.Body>
                  <span className="title">Memory</span>
                  <span className={`material-icons ${this.getThresholdColor('app_mem', app_mem)}`}>
                    memory
                  </span>
                  <span className="text">
                    <span>
                      {!app_mem && 'N/A'}
                      {Boolean(app_mem) && `${formatBytes(app_mem * 1024)} App`}
                      <br />
                      {Boolean(app_mem) && `${formatBytes(ctrl_mem * 1024)} Ctrl`}
                    </span>
                  </span>
                </Panel.Body>
              </Panel>
            </Col>
            <Col xs={4} sm={3} md={2} lg={1}>
              <Panel data-test="status-storage">
                <Panel.Body>
                  <span className="title">Storage</span>
                  <span className={`material-icons ${this.getThresholdColor('disk', disk)}`}>
                    sd_storage
                  </span>
                  <span className="text">
                    <span>{disk ? `${disk}%` : 'N/A'}</span>
                  </span>
                </Panel.Body>
              </Panel>
            </Col>
            <Col xs={4} sm={3} md={2} lg={1}>
              <Panel data-test="status-ip">
                <Panel.Body>
                  <span className="title">IP/MAC</span>
                  <span className="material-icons">developer_board</span>
                  <span className="text">
                    <span>
                      {ip}
                      <span className="comment">{Boolean(ip) && mac}</span>
                    </span>
                  </span>
                </Panel.Body>
              </Panel>
            </Col>
            <Col xs={4} sm={3} md={2} lg={1}>
              <Panel data-test="status-type">
                <Panel.Body>
                  <span className="title">Device Type</span>
                  <span className="material-icons">devices_other</span>
                  <span className="text">
                    <span>
                      {device_type}
                      <span className="comment">
                        {Boolean(firmware_version) && `fw: ${firmware_version}`}
                      </span>
                    </span>
                  </span>
                </Panel.Body>
              </Panel>
            </Col>
            <Col xs={4} sm={3} md={2} lg={1}>
              <Panel data-test="status-version">
                <Panel.Body>
                  <span className="title">Player Version</span>
                  <img src="/logo.jpg" alt="" />
                  <span className="text">
                    <span>{version}</span>
                  </span>
                </Panel.Body>
              </Panel>
            </Col>
          </Row>
        </Grid>
        <Panel data-test="panel-uptime">
          <Panel.Heading>{`${heartbeatPercent}% Uptime`}</Panel.Heading>
          <Panel.Body>
            <DeviceUptimeDiagram device={device} />
          </Panel.Body>
        </Panel>
        <Panel data-test="panel-errors" className="device-errors">
          <Panel.Heading>Latest Errors</Panel.Heading>
          <Panel.Body>{this.getErrors()}</Panel.Body>
        </Panel>
      </div>
    );
  }
}

DeviceStatus.propTypes = {
  auth: PropTypes.object,
  device: PropTypes.object,
  users: PropTypes.array,
};
