import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { InputGroup, Button } from 'react-bootstrap';

const MultiColumnSelect = ({
  options,
  headers,
  displayKeys,
  orderKeys,
  idKey = 'id',
  selectedId,
  onSelect,
}) => {
  const selected = options.find(option => option[idKey] === selectedId);
  const selectedDisplayValue = selected ? selected[displayKeys[0]] : '';
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [inputValue, setInputValue] = useState(selectedDisplayValue);
  const [showOptions, setShowOptions] = useState(false);
  const componentRef = useRef(null);

  useEffect(() => {
    const onClickOutside = event => {
      if (componentRef.current && !componentRef.current.contains(event.target)) {
        setShowOptions(false);
      }
    };

    document.addEventListener('click', onClickOutside);

    return () => document.removeEventListener('click', onClickOutside);
  }, []);

  const onInputChange = event => {
    const { value } = event.target;

    if (value === '') {
      setFilteredOptions(options);
    } else {
      setFilteredOptions(
        options.filter(option =>
          displayKeys.some(key => {
            let optionValue = option[key];

            if (Array.isArray(option[key])) {
              optionValue = option[key].join(', ');
            }

            return optionValue.toLowerCase().includes(value.toLowerCase());
          }),
        ),
      );
    }

    setInputValue(value);
    setShowOptions(true);
    onSelect(null);
    componentRef.current.classList.add('filtering');
  };

  const onOptionClick = option => {
    setFilteredOptions(options);
    setInputValue(option[displayKeys[0]]);
    setShowOptions(false);
    onSelect(option[idKey]);
    componentRef.current.classList.remove('filtering');
  };

  const onClear = () => {
    setFilteredOptions(options);
    setInputValue('');
    setShowOptions(false);
    onSelect(null);
  };

  const sortOptions = options => {
    return options.slice().sort((a, b) => {
      for (let i = 0; i < orderKeys.length; i += 1) {
        const key = orderKeys[i];
        const comparison = a[key].toLowerCase().localeCompare(b[key].toLowerCase());

        if (comparison !== 0) {
          return comparison;
        }
      }

      return 0;
    });
  };

  const renderOptionsHeader = () => {
    if (inputValue && !filteredOptions.length) {
      return <div className="multi-column-option header">No results.</div>;
    }

    return (
      <div className="multi-column-option header">
        {headers.map((key, idx) => (
          <span key={key}>
            {key}
            {idx === headers.length - 1 && (
              <div className="total">
                {filteredOptions.length}/{options.length}
              </div>
            )}
          </span>
        ))}
      </div>
    );
  };

  const renderOptions = () => {
    return sortOptions(filteredOptions).map(option => renderOption(option));
  };

  const renderOption = option => (
    <div key={option[idKey]} className="multi-column-option" onClick={() => onOptionClick(option)}>
      {displayKeys.map(key => (
        <span key={key}>{Array.isArray(option[key]) ? option[key].join(', ') : option[key]}</span>
      ))}
    </div>
  );

  return (
    <div className="multi-column-select" ref={componentRef}>
      <InputGroup>
        <input
          className="form-control"
          type="text"
          placeholder="Type to filter options..."
          value={inputValue}
          onChange={onInputChange}
          onFocus={() => setShowOptions(true)}
        />
        <InputGroup.Button>
          <Button onClick={onClear}>Clear</Button>
        </InputGroup.Button>
      </InputGroup>
      {showOptions && (
        <div className="multi-column-options">
          {renderOptionsHeader()}
          {renderOptions()}
        </div>
      )}
    </div>
  );
};

export default MultiColumnSelect;

MultiColumnSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
  displayKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  orderKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  idKey: PropTypes.string,
  selectedId: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  onSelect: PropTypes.func.isRequired,
};
