import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Panel, Grid, Row, Col } from 'react-bootstrap';
import { format } from 'date-fns';
import { formatBytes, formatTime, isoDateToString } from '../../utils/formatUnits';
import UserProfile from '../UserProfile/UserProfile';

export default class Metadata extends Component {
  getBool = data => (data ? 'Yes' : 'No');

  getCreatedLabel = () => {
    const {
      data: { type, ttf_url },
    } = this.props;

    if (['image', 'video'].includes(type) || ttf_url !== undefined) {
      return 'Uploaded';
    }

    return 'Created';
  };

  getCreated = (id, date) => {
    const { users } = this.props;

    return (
      <div className="metadata-created">
        <UserProfile userId={id} users={users} />
        {date && <div className="text-light">{isoDateToString(date)}</div>}
      </div>
    );
  };

  getSignThumbnail = type => {
    const {
      data: { id, publishedDate, pendingDate },
    } = this.props;
    const date = type === 'published' ? publishedDate : pendingDate;
    const info =
      type === 'published' && publishedDate !== pendingDate ? (
        <div className="info">Old version</div>
      ) : null;

    return (
      <div className="metadata-sign-thumbnail">
        {info}
        <div className="text-light">{format(new Date(date), 'MMM dd yyyy HH:mm:ss')}</div>
        <img src={`/cms/data/v1/signs/${id}/${type}/thumbnail?${date}`} alt="" />
      </div>
    );
  };

  getDuration = time => {
    if (!time || time < 0) {
      return null;
    }

    const [secs, ms] = formatTime(time / 1000).split('.');

    return (
      <div>
        {secs}
        {ms ? <span className="text-light">.{ms}</span> : null}
      </div>
    );
  };

  getFontType = () => {
    const { data } = this.props;

    return data.default ? 'Base font' : 'Custom font';
  };

  getFontFiles = () => {
    const { data } = this.props;
    const files = [];

    if (data.ttf_url && data.ttf_url.endsWith('ttf')) {
      files.push('ttf');
    }

    if (data.ttf_url && data.ttf_url.endsWith('otf')) {
      files.push('otf');
    }

    if (data.woff_url) {
      files.push('woff');
    }

    if (data.woff2_url) {
      files.push('woff2');
    }

    return files.join(', ');
  };

  getCol = (name, data) =>
    data ? (
      <Col data-test={`meta-${name.replace(/[^a-z0-9]/gi, '')}`} key={name} xs={6} md={3} lg={2}>
        <label>{name}</label>
        <div>{data}</div>
      </Col>
    ) : null;

  getCols = () => {
    const { data } = this.props;

    return Object.keys(data)
      .map(key => {
        const value = data[key];

        switch (key) {
          // common
          case 'createdBy':
            return this.getCol(this.getCreatedLabel(), this.getCreated(value, data.createdDate));
          case 'modifiedBy':
            return this.getCol('Last modified', this.getCreated(value, data.modifiedDate));
          // asset
          case 'size':
            return this.getCol('Size', formatBytes(value));
          // asset, sign
          case 'width':
            return this.getCol('Dimensions', `${value} x ${data.height}`);
          case 'duration':
            return this.getCol('Duration', this.getDuration(value));
          // sign
          case 'isTemplate':
            return this.getCol('Template', this.getBool(value));
          case 'pendingDate':
            return this.getCol('Latest', this.getSignThumbnail('pending'));
          case 'publishedDate':
            return this.getCol(
              'Published',
              value ? this.getSignThumbnail('published') : this.getBool(value),
            );
          // font
          case 'ttf_url':
            return this.getCol('Type', this.getFontType());
          case 'woff_url':
            return this.getCol('Filename', data.filename);
          case 'woff2_url':
            return this.getCol('Files', this.getFontFiles());
          default:
            return null;
        }
      })
      .filter(item => item);
  };

  render() {
    const cols = this.getCols();

    if (!cols.length) {
      return null;
    }

    return (
      <Panel className="metadata">
        <Panel.Heading>Details</Panel.Heading>
        <Panel.Body>
          <Grid fluid>
            <Row>{cols}</Row>
          </Grid>
        </Panel.Body>
      </Panel>
    );
  }
}

Metadata.propTypes = {
  data: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
};
