export default error => {
  if (error instanceof Error) {
    return error.toString();
  }

  if (error && error.userMessage) {
    return `Error : ${error.userMessage}`;
  }

  if (error) {
    return `Error ${error.status}: ${error.statusText}`;
  }

  return error;
};
