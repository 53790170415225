import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { InputGroup, Button } from 'react-bootstrap';
import Pill from '../Pill/Pill';

export default class TagCloud extends Component {
  state = { tags: this.props.tags };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { tags } = nextProps;

    this.setState({ tags });
  }

  onChange = tags => {
    const { onChange } = this.props;
    const { tags: stateTags } = this.state;

    onChange(tags || stateTags);
  };

  onKeyDown = event => {
    if (![13, 188].includes(event.keyCode)) {
      return;
    }

    if (event.keyCode === 188) {
      event.preventDefault();
    }

    this.addTag(event.target.value);
    event.target.value = null;
  };

  onAdd = () => {
    this.addTag(this.input.value);
    this.input.value = null;
    this.input.focus();
  };

  addTag = name => {
    const { tags: stateTags } = this.state;
    const tags = stateTags || [];

    if (name.trim() === '') {
      return false;
    }

    const tagClone = tags.slice(0);
    // Does the tag exist? If so we return and don't add the tag
    const found = tags.find(tag => tag.toString().toLowerCase() === name.toString().toLowerCase());

    if (found) {
      return false;
    }

    tagClone.push(name.trim());
    this.setState({ tags: tagClone });
    this.onChange(tagClone);

    return true;
  };

  renderTags = () => {
    const { disabled } = this.props;
    const { tags } = this.state;
    const tagNodes = [];

    if (!tags) {
      return null;
    }

    // perform a case insensitive sorting
    tags.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
    tags.forEach((tag, index) => {
      const handler = () => {
        const tagClone = tags.slice(0);

        tagClone.splice(index, 1);
        this.setState({ tags: tagClone });
        this.onChange(tagClone);
      };

      tagNodes.push(
        <Pill key={index} handler={handler} disabled={disabled}>
          {tag}
        </Pill>,
      );
    });

    return tagNodes;
  };

  render() {
    const { className = '', disabled } = this.props;
    const tags = this.renderTags();

    return (
      <div className={`tag-cloud ${className}`}>
        <InputGroup>
          <input
            data-test="input-tag"
            className="form-control"
            name="tags"
            type="text"
            placeholder="Add some comma-delimited tags"
            onKeyDown={this.onKeyDown}
            disabled={disabled}
            ref={ref => {
              this.input = ref;
            }}
          />
          <InputGroup.Button>
            <Button data-test="button-addtag" onClick={this.onAdd} disabled={disabled}>
              Add
            </Button>
          </InputGroup.Button>
        </InputGroup>
        <div className="tags">{tags}</div>
      </div>
    );
  }
}

TagCloud.propTypes = {
  tags: PropTypes.array,
  className: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};
