import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { format, parse } from 'date-fns';
import ToolTipIcon from '../ToolTipIcon/ToolTipIcon';
import SSTable from '../SSTable/SSTable';
import SSColumn from '../SSTable/SSColumn';
import AssetImage from '../Assets/AssetImage';
import { getPermissions } from '../../utils/getPermissions';
import { launchCreator } from '../../utils/launchCreator';
import { buildTagList } from '../../utils/tagList';
import { getParent } from '../../utils/parent';

export default class ScheduleEntriesTable extends Component {
  getPosition = (item, index) => <span className="circle circle-md">{index + 1}</span>;

  getPreview = item => {
    const { signs } = this.props;

    return <AssetImage type={item.type} item={item.entry} signs={signs} />;
  };

  getType = item => item.type;

  getName = item => {
    const { auth } = this.props;
    const p = getPermissions(auth);

    return (
      <div className="entry-name">
        <div>
          {!p.atLeastTierStandard && item.type === 'sign' ? (
            item.entry.name
          ) : (
            <Link to={`/${item.type}s/${item.entry.id}`}>{item.entry.name}</Link>
          )}
        </div>
        <div>Type: {this.getType(item)}</div>
        <div>Folder: {item.entry.parent}</div>
        <div>Tags: {buildTagList(item.entry)}</div>
      </div>
    );
  };

  getDescription = item => <div className="entry-description">{item.entry.description}</div>;

  getDate = (item, index) => {
    const {
      auth: {
        user: {
          permissions: { Writer },
        },
      },
      onChangeDate,
    } = this.props;

    return (
      <div data-test="datepicker-start">
        <DatePicker
          className="entry-date"
          selected={parse(item.date, 'yyyyMMdd', new Date())}
          onChange={date => onChangeDate(format(date, 'yyyyMMdd'), index)}
          placeholderText="Start date"
          dateFormat="dd/MM/yyyy"
          disabled={!Writer}
        />
      </div>
    );
  };

  getTime = (item, index) => {
    const {
      auth: {
        user: {
          permissions: { Writer },
        },
      },
      onChangeHour,
      onChangeMinute,
    } = this.props;
    const hours = [];
    const mins = [];

    for (let i = 0; i < 60; i += 1) {
      if (i < 24) {
        hours.push(<option key={i}>{i < 10 ? `0${i}` : i}</option>);
      }

      mins.push(<option key={i}>{i < 10 ? `0${i}` : i}</option>);
    }

    return (
      <div className="entry-time">
        <select
          data-test="select-hour"
          value={item.time.slice(0, 2)}
          onChange={event => onChangeHour(event, index)}
          disabled={!Writer}
          className="hours"
        >
          {hours}
        </select>
        <select
          data-test="select-minute"
          value={item.time.slice(2, 4)}
          onChange={event => onChangeMinute(event, index)}
          disabled={!Writer}
          className="minutes"
        >
          {mins}
        </select>
      </div>
    );
  };

  getRepeat = (item, index) => {
    const {
      auth: {
        user: {
          permissions: { Writer },
        },
      },
      onChangeRepeat,
    } = this.props;

    return (
      <select
        data-test="select-repeat"
        value={item.recur}
        onChange={event => onChangeRepeat(event, index)}
        disabled={!Writer}
        className="repeat-select"
      >
        <option value={1}>Daily</option>
        <option value={0}>One off</option>
        <option value={2}>Specific days</option>
      </select>
    );
  };

  getDays = (item, index) => {
    const {
      auth: {
        user: {
          permissions: { Writer },
        },
      },
      onChangeDays,
    } = this.props;

    if (item.recur !== 2) {
      return null;
    }

    const bitmask = (item.days || 0).toString(2).split('').map(Number);

    while (bitmask.length < 7) {
      bitmask.unshift(0);
    }

    return (
      <div className="entry-days">
        <label>
          <span>Mon</span>{' '}
          <input
            data-test="checkbox-mon"
            type="checkbox"
            checked={bitmask[5]}
            onChange={event => onChangeDays(event, index, 5)}
            disabled={!Writer}
          />
        </label>{' '}
        {/* 0000010, 2, Mon */}
        <label>
          <span>Tue</span>{' '}
          <input
            data-test="checkbox-tue"
            type="checkbox"
            checked={bitmask[4]}
            onChange={event => onChangeDays(event, index, 4)}
            disabled={!Writer}
          />
        </label>{' '}
        {/* 0000100, 4, Tue */}
        <label>
          <span>Wed</span>{' '}
          <input
            data-test="checkbox-wed"
            type="checkbox"
            checked={bitmask[3]}
            onChange={event => onChangeDays(event, index, 3)}
            disabled={!Writer}
          />
        </label>{' '}
        {/* 0001000, 8, Wed */}
        <label>
          <span>Thu</span>{' '}
          <input
            data-test="checkbox-thu"
            type="checkbox"
            checked={bitmask[2]}
            onChange={event => onChangeDays(event, index, 2)}
            disabled={!Writer}
          />
        </label>{' '}
        {/* 0010000, 16, Thu */} <br />
        <label>
          <span>Fri</span>{' '}
          <input
            data-test="checkbox-fri"
            type="checkbox"
            checked={bitmask[1]}
            onChange={event => onChangeDays(event, index, 1)}
            disabled={!Writer}
          />
        </label>{' '}
        {/* 0100000, 32, Fri */}
        <label>
          <span>Sat</span>{' '}
          <input
            data-test="checkbox-sat"
            type="checkbox"
            checked={bitmask[0]}
            onChange={event => onChangeDays(event, index, 0)}
            disabled={!Writer}
          />
        </label>{' '}
        {/* 1000000, 64, Sat */}
        <label>
          <span>Sun</span>{' '}
          <input
            data-test="checkbox-sun"
            type="checkbox"
            checked={bitmask[6]}
            onChange={event => onChangeDays(event, index, 6)}
            disabled={!Writer}
          />
        </label>{' '}
        {/* 0000001, 1, Sun */}
      </div>
    );
  };

  getOptions = (item, index) => {
    return (
      <div className="options">
        <label>
          <span>Time:</span> {this.getTime(item, index)}
        </label>
        <label>
          <span>Repeat:</span> {this.getRepeat(item, index)}
        </label>
      </div>
    );
  };

  getPlayer = item => (
    <ToolTipIcon
      toolText={`Preview ${item.type === 'sign' ? 'Sign' : 'Sequence'}`}
      href={`/player/?${item.type}=${item.entry.id}`}
      target="_blank"
    >
      play_circle_filled
    </ToolTipIcon>
  );

  getCreator = item =>
    item.type !== 'sign' ? null : (
      <ToolTipIcon
        toolText="Open Sign in Creator"
        onClick={() => launchCreator(item.entry.id, getParent(this.props, item.entry.parent).id)}
      >
        <i className="fa fa-paint-brush" />
      </ToolTipIcon>
    );

  getRemove = (item, index) => {
    const { onRemoveEntry } = this.props;

    return (
      <ToolTipIcon
        toolText={`Remove ${item.type === 'sign' ? 'Sign' : 'Sequence'}`}
        onClick={() => onRemoveEntry(index)}
      >
        remove_circle_outline
      </ToolTipIcon>
    );
  };

  render() {
    const { auth, data, onSort } = this.props;
    const p = getPermissions(auth);

    if (!data || (data.length && !data[0].entry)) {
      return null;
    }

    return (
      <SSTable
        className="responsive table-schedule"
        data={data}
        headerHeight={50}
        rowHeight={80}
        rowsCount={data.length - 1}
        onSort={onSort}
        selectable
        zebra
      >
        <SSColumn width={1} cell={this.getPosition} className="position" />
        <SSColumn width={1} cell={this.getPreview} />
        <SSColumn width={2} header="Name" cell={this.getName} />
        <SSColumn width={2} header="Description" cell={this.getDescription} />
        <SSColumn width={1} header="Start Date" cell={this.getDate} />
        <SSColumn width={1} header="Time & Repeat" cell={this.getOptions} />
        <SSColumn header="Days" cell={this.getDays} />
        {p.atLeastTierStandard && p.writer && (
          <SSColumn width={1} header="Creator" cell={this.getCreator} />
        )}
        <SSColumn width={1} header="Preview" cell={this.getPlayer} />
        {p.writer && <SSColumn width={1} header="Remove" cell={this.getRemove} />}
      </SSTable>
    );
  }
}

ScheduleEntriesTable.propTypes = {
  auth: PropTypes.object.isRequired,
  folders: PropTypes.object.isRequired,
  signs: PropTypes.array.isRequired,
  data: PropTypes.array,
  onSort: PropTypes.func.isRequired,
  onChangeDate: PropTypes.func.isRequired,
  onChangeHour: PropTypes.func.isRequired,
  onChangeMinute: PropTypes.func.isRequired,
  onChangeRepeat: PropTypes.func.isRequired,
  onChangeDays: PropTypes.func.isRequired,
  onRemoveEntry: PropTypes.func.isRequired,
};
