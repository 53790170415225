import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SSTable from '../SSTable/SSTable';
import SSColumn from '../SSTable/SSColumn';

export default class TierTable extends Component {
  renderHeader = header => {
    const { data } = this.props;

    if (header !== data.primary_key) {
      return header;
    }

    return (
      <span>
        <span className="fa fa-key key" title="Primary Key" />
        {header}
      </span>
    );
  };

  renderColumns = () => {
    const { data } = this.props;
    const Col = ({ rowIndex, colIndex }) => (
      <span>{data.tier.data[rowIndex].row.columns[colIndex].value}</span>
    );

    return data.headers.map((header, index) => {
      const head = this.renderHeader(header);

      return <SSColumn header={head} cell={<Col colIndex={index} />} key={index} />;
    });
  };

  render() {
    const { data, selected, onClick, onDoubleClick } = this.props;

    if (!data || !data.tier) {
      return null;
    }

    const rows = data.tier.data;
    const columns = this.renderColumns();

    return (
      <SSTable
        data={rows}
        rowHeight={50}
        rowsCount={rows.length - 1}
        onSelect={onClick}
        doubleClick={onDoubleClick}
        selected={selected}
        selectable
        zebra
      >
        {columns}
      </SSTable>
    );
  }
}

TierTable.propTypes = {
  data: PropTypes.object.isRequired,
  selected: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  onDoubleClick: PropTypes.func.isRequired,
};
