import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Slider from '../../components/Slider/Slider';
import qs from '../../utils/qs';
import { addUniqueQueryParam, removeQueryParam } from '../../utils/updateQueryString';
import { formatTime, formatBytes } from '../../utils/formatUnits';
import { addUniqueFilter } from '../../actions/filterActions';

export class FilterSlider extends Component {
  // NOTE: We need three version of min/max, these are:
  // 1. Abs (absolute): the start and end points of the slider
  // 2. Req (requested): the user defined values, using the slider
  // 3. Act (actual): if this component renders because another filter was used,
  // it can happen the previously set Req values would go beyond the new Abs values.
  // To prevent this happen, we use the Act values which never go beyond the limits.
  // When the Abs values become small/large enough again to contain the original Req values
  // than we can display the stored Req values again.
  state = {
    minAbs: null,
    maxAbs: null,
    minReq: null,
    minAct: null,
    maxAct: null,
  };

  componentDidMount() {
    // if this filter is in the querystring apply it
    this.setFilterFromQuery(this.props);
    this.updateAbsoluteValues(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setFilterFromQuery(nextProps);
    this.updateAbsoluteValues(nextProps);
  }

  componentDidUpdate(prevProps) {
    this.resetActualValuesAfterFilterRemoved(prevProps);
  }

  setFilterFromQuery = props => {
    const { name, title, addUniqueFilter } = props;
    const filter = qs(props)[name];

    if (!filter) {
      return;
    }

    const values = this.getValuesFromQuery(props);
    const text = this.buildText(values.min, values.max);

    addUniqueFilter({ group: name, name, query: filter, text, title });
  };

  resetActualValuesAfterFilterRemoved = prevProps => {
    const { name } = this.props;
    const { minAbs, maxAbs } = this.state;

    // if filter was removed, update the state
    if (!qs(this.props)[name] && qs(prevProps)[name]) {
      this.updateValues(minAbs, maxAbs, null, null);
    }
  };

  updateAbsoluteValues = props => {
    // absolute values (minAbs/maxAbs) can be set by props only
    let { min, max } = props;

    // stat_fields[something] may returns -1
    if (!min || min < 0) {
      min = 0;
    }

    if (!max || max < 0) {
      max = 0;
    }

    // update the actual values
    const query = this.getValuesFromQuery(props);

    this.updateValues(min, max, query.min, query.max);
  };

  updateValues = (minAbs, maxAbs, minReq, maxReq) => {
    // requested values (minReq/maxReq) can be set by 1: onChangeComplete event 2: querystring
    let minAct = minAbs;
    let maxAct = maxAbs;

    // if this component renders because another filter was used,
    // it can happen the user defined value is beyond the new minAbs/maxAbs limits
    // in that case we use the absolute values as actual values
    minAct = minReq !== null && minReq > minAbs ? minReq : minAbs;
    maxAct = maxReq !== null && maxReq < maxAbs ? maxReq : maxAbs;
    this.setState({ minAbs, maxAbs, minReq, minAct, maxAct });
  };

  onChangeComplete = ([min, max]) => {
    const { history, location, name } = this.props;
    const { minAbs, maxAbs, minAct, maxAct } = this.state;
    const query = this.buildQuery(min, max);
    const filter = qs(this.props)[name];

    if (min === minAbs && max === maxAbs) {
      // when the new requested values are equal with the absolute values
      // the filter set to default, and the previous query can be removed
      // first check is there any query at all
      if (!filter) {
        return;
      }

      removeQueryParam(history, location, name, filter);
      this.updateValues(minAbs, maxAbs, null, null);
    } else if (min !== minAct || max !== maxAct) {
      // only update when the new request is different from the current one
      addUniqueQueryParam(history, location, name, query);
      this.updateValues(minAbs, maxAbs, min, max);
    }
  };

  getValuesFromQuery = props => {
    const { name } = props;
    const query = qs(props)[name]; // something like [* TO 50] or [10 TO *] or [10 TO 20]
    let min = null;
    let max = null;
    let values;

    if (query) {
      values = query.slice(1, -1); // remove '[', ']'
      values = values.split(' TO '); // remove ' TO '

      if (values[0] !== '*') {
        min = +values[0];
      }

      if (values[1] !== '*') {
        max = +values[1];
      }
    }

    return { min, max };
  };

  buildQuery = (min, max) => {
    const { minAbs, maxAbs } = this.state;
    const minQuery = min === minAbs ? '*' : min;
    const maxQuery = max === maxAbs ? '*' : max;

    return `[${minQuery} TO ${maxQuery}]`;
  };

  // create a user friendly and unit formatted text that can be used by the FiltersApplied component
  // i.e. "[1024 TO 23468989]" => "1KB to 22.38MB"
  buildText = (min, max) => {
    const { minAbs, maxAbs } = this.state;
    let text = '';

    if (min && min !== minAbs) {
      text += `from ${this.formatUnit(min)}`;
    }

    if (max && max !== maxAbs) {
      text += `${text ? ' ' : ''}to ${this.formatUnit(max)}`;
    }

    return text;
  };

  formatUnit = value => {
    const { unit = '' } = this.props;
    let text;

    switch (unit) {
      case 'time':
        text = formatTime(value, true);
        break;
      case 'byte':
        text = formatBytes(value);
        break;
      default:
        text = value + unit;
    }

    return text;
  };

  render() {
    const { unit, title } = this.props;
    const { minAbs, maxAbs, minAct, maxAct, minReq } = this.state;

    if (!maxAbs || maxAbs <= minReq || minAbs === maxAbs) {
      return null;
    }

    return (
      <div>
        <div className="filter-section-title">{title}</div>
        <Slider
          minAbs={minAbs}
          maxAbs={maxAbs}
          minAct={minAct}
          maxAct={maxAct}
          unit={unit}
          onChangeComplete={this.onChangeComplete}
        />
      </div>
    );
  }
}

FilterSlider.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  unit: PropTypes.string,
  addUniqueFilter: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  addUniqueFilter,
};

export default withRouter(connect(null, mapDispatchToProps)(FilterSlider));
