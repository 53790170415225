import clone from 'clone';
import * as types from '../actions/actionTypes';

const initialAppModal = {
  show: false,
  title: '',
  content: '',
  data: null,
  cancelFn: null,
  confirmFn: null,
};

export const initialState = {
  previousLocation: null,
  appModal: initialAppModal,
};

export default function app(state = initialState, action = {}) {
  switch (action.type) {
    case types.SAVE_PREVIOUS_LOCATION:
      return {
        ...state,
        previousLocation: action.previousLocation,
      };

    case types.SHOW_APP_MODAL:
      return {
        ...state,
        appModal: {
          ...initialAppModal,
          ...clone(action.appModal),
        },
      };

    case types.HIDE_APP_MODAL:
      return {
        ...state,
        appModal: { ...initialAppModal },
      };

    default:
      return state;
  }
}
