import React from 'react';
import { Link } from 'react-router-dom';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { format, formatDistanceToNow, sub } from 'date-fns';
import ToolTipIcon from '../ToolTipIcon/ToolTipIcon';
import UserProfile from '../UserProfile/UserProfile';

export const getHeartbeat = device => {
  if (!device.heartbeat || !device.heartbeat.length) {
    return 0;
  }

  return device.heartbeat.reduce((sum, item) => sum + item);
};

export const getHeartbeatPercent = device => {
  if (!device.heartbeat || !device.heartbeat.length) {
    return 0;
  }

  return Number(
    Math.min(
      ((device.heartbeat.reduce((sum, item) => sum + item) / (3600 * 24)) * 100).toFixed(2),
      100,
    ),
  );
};

export const getOnlineStatusStyle = device =>
  `not-btn ${!device.idle ? 'unknown' : device.online_status}`;

export const getDownloadStatusStyle = device =>
  `not-btn ${!device.idle ? 'unknown' : device.download_status}`;

export const getFormatDistanceToNow = date => `${formatDistanceToNow(new Date(date))} ago`;

export const getOnlineStatusText = device => {
  if (!device.idle) {
    return 'Never seen';
  }

  return `Updated ${formatDistanceToNow(sub(new Date(), { seconds: device.idle }))} ago`;
};

export const getDownloadStatusText = device => {
  if (!device.deployed_ver && !device.downloading_ver && !device.pending_ver) {
    return 'No content';
  }

  switch (device.download_status) {
    case 'current':
      return 'Content up to date';
    case 'downloading':
      return 'Downloading content';
    case 'pending':
      return 'Pending content';
    case 'outdated':
      return 'Content outdated';
    default:
      return '';
  }
};

export const getSyncInfo = device => {
  const sync = device.synchronisation_method;
  const vwDetails = `Video wall ${device.vw_enabled ? 'enabled' : 'disabled'}`;
  let className = 'disabled';

  if (device.vw_enabled) {
    className = 'enabled';
  } else if (sync) {
    className = '';
  }

  let syncType = 'Non-synchronized';
  let syncDetails = '';

  if (sync === 1) {
    syncType = 'Sync: NTP';
    syncDetails = device.time_sync;
  } else if (sync === 2) {
    syncType = 'Sync: master';
    syncDetails = device.sync_tag;
  } else if (sync === 3) {
    syncType = 'Sync: slave';
    syncDetails = device.sync_tag;
  }

  return { className, vwDetails, syncType, syncDetails };
};

export const getCommand = (device, type) =>
  device.commands ? device.commands.filter(item => item.command === type)[0] : null;

export const getFile = (device, type) => {
  if (!device.uploads) {
    return null;
  }

  if (type === 'diagnostics') {
    return device.uploads.filter(item => item.filename.endsWith('.html'))[0];
  }

  if (type === 'screenshot') {
    return device.uploads.filter(item => !item.filename.endsWith('.html'))[0];
  }

  return null;
};

export const getCommandStatus = (device, type) => {
  // return 'failed' when device is online, request is older than 5 mins and there is no new uploaded file
  // device is not online
  if (device.online_status !== 'online') {
    return '';
  }

  // there is no request
  const command = getCommand(device, type);

  if (!command) {
    return '';
  }

  // file is uploaded (file is older than request)
  const file = getFile(device, type);
  const commandTime = new Date(command.timestamp).getTime();
  const fileTime = file ? new Date(file.timestamp).getTime() : null;

  if (fileTime && fileTime >= commandTime) {
    return '';
  }

  // request is older than 5 mins and there is no new uploaded file
  let currentTime = new Date();

  currentTime = currentTime.getTime() + currentTime.getTimezoneOffset() * 60 * 1000;

  if ((currentTime - commandTime) / 1000 > 300) {
    return 'failed';
  }

  return '';
};

export const getLocalTime = time => {
  if (!time) {
    return 'N/A';
  }

  const date = new Date(time);

  return format(sub(date, { minutes: -date.getTimezoneOffset() }), 'dd/MM/yyyy HH:mm:ss');
};

export const getCommandAndFileTimes = (device, type) => {
  const command = getCommand(device, type);
  const file = getFile(device, type);
  const commandTime = command ? getLocalTime(command.timestamp) : '';
  const fileTime = file ? getLocalTime(file.timestamp) : '';
  let commandText;

  if (command) {
    commandText = command.status === 'submitted' ? 'Requested:' : 'Acknowledged:';
  }

  return { commandText, commandTime, fileTime };
};

export const getCommandSpinner = (device, type) => {
  // device is not online
  if (device.online_status !== 'online') {
    return null;
  }

  // no request
  const command = getCommand(device, type);

  if (!command) {
    return null;
  }

  // file is newer than request
  const file = getFile(device, type);

  if (file && command.timestamp <= file.timestamp) {
    return null;
  }

  // request is failed (older than 5 mins)
  if (getCommandStatus(device, type) === 'failed') {
    return null;
  }

  // there is an active request
  return (
    <div className={`spinner ${command.status}`}>
      <div />
    </div>
  );
};

export const getIdNode = (device, users) => {
  const toolText = (
    <Popover
      id={`popover-device-id-${device.name}`}
      className="popover-device-id"
      title={
        <span>
          <code>{device.name}</code> {device.description}
        </span>
      }
    >
      <table>
        <tbody>
          <tr>
            <td>Registered:</td>
            <td>
              <UserProfile userId={device.createdBy} users={users} />
            </td>
            <td>{getLocalTime(device.createdDate)}</td>
          </tr>
          <tr>
            <td>Last Modified:</td>
            <td>
              <UserProfile userId={device.modifiedBy} users={users} />
            </td>
            <td>{getLocalTime(device.modifiedDate)}</td>
          </tr>
        </tbody>
      </table>
    </Popover>
  );

  return (
    <div className="rel inline-block">
      <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={toolText}>
        <Link to={`/devices/${device.id}`}>{device.name}</Link>
      </OverlayTrigger>
    </div>
  );
};

export const getOnlineStatusIcon = device => (
  <ToolTipIcon className={getOnlineStatusStyle(device)} toolText={getOnlineStatusText(device)}>
    lens
  </ToolTipIcon>
);

export const getDownloadStatusIcon = device => (
  <ToolTipIcon className={getDownloadStatusStyle(device)} toolText={getDownloadStatusText(device)}>
    cloud_download
  </ToolTipIcon>
);

export const getDiagnosticsButton = (device, onClick) => {
  const type = 'diagnostics';
  const command = getCommand(device, type);
  const file = getFile(device, type);
  const commandStatus = getCommandStatus(device, type);
  const time = getCommandAndFileTimes(device, type);
  const className = (file ? '' : 'disabled') + commandStatus;
  const toolText = (
    <div>
      Diagnostics <span className="pull-right color-red-500">{commandStatus}</span>
      {command && (
        <div className="device-request-time">
          <span>{time.commandText}</span>
          {time.commandTime}
        </div>
      )}
      {file && (
        <div className="device-request-time">
          <span>Uploaded:</span>
          {time.fileTime}
        </div>
      )}
    </div>
  );

  return (
    <div className="rel inline-block">
      {getCommandSpinner(device, type)}
      <ToolTipIcon
        id={`device-diagnostics-btn-${device.name}`}
        className={className}
        toolText={toolText}
        onClick={file ? onClick : () => {}}
      >
        assignment
      </ToolTipIcon>
    </div>
  );
};

export const getScreenshotButton = (device, onClick) => {
  const type = 'screenshot';
  const command = getCommand(device, type);
  const file = getFile(device, type);
  const commandStatus = getCommandStatus(device, type);
  const time = getCommandAndFileTimes(device, type);
  const spinner = getCommandSpinner(device, type);
  let toolText;

  // no screenshot, show a ToolTip
  if (!file) {
    toolText = (
      <div>
        Screenshot <span className="pull-right color-red-500">{commandStatus}</span>
        {command && (
          <div className="device-request-time">
            <span>{time.commandText}</span>
            {time.commandTime}
          </div>
        )}
      </div>
    );

    return (
      <div className="rel inline-block">
        {spinner}
        <ToolTipIcon
          id={`device-diagnostics-btn-${device.name}`}
          className={`disabled ${commandStatus}`}
          toolText={toolText}
        >
          photo_camera
        </ToolTipIcon>
      </div>
    );
  }

  // there is a screenshot, show a more robust Popover with the screenshot instead of a ToolTip
  const fileUrl = `/cms/data/v1/devices/${device.id}/file/${file.filename}?${file.timestamp}`;
  const title = (
    <span>
      Screenshot
      <span className="pull-right rel">
        {commandStatus && <span className="color-red-500">Request failed</span>}
        {spinner}
      </span>
    </span>
  );

  toolText = (
    <Popover id={`popover-screenshot-${device.name}`} className="popover-screenshot" title={title}>
      {Boolean(time.commandTime) && (
        <div className="device-request-time">
          <span>{time.commandText}</span>
          {time.commandTime}
        </div>
      )}
      <div className="device-request-time">
        <span>Uploaded:</span>
        {time.fileTime}
      </div>
      <div className="device-screenshot">
        <img src={fileUrl} alt="Screenshot" />
      </div>
    </Popover>
  );

  return (
    <div className="rel inline-block">
      {spinner}
      <OverlayTrigger trigger={['hover', 'focus']} placement="left" overlay={toolText}>
        <span
          data-test="icon-screenshot"
          className={`tool-tip-icon material-icons ${commandStatus}`}
          onClick={onClick}
        >
          photo_camera
        </span>
      </OverlayTrigger>
    </div>
  );
};
