import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';

/**
 * Confirm Component
 * -----------------------------------------------------
 * Presents the user with a Confirm dialog. If the user selects OK the onSuccess method is fired.
 * For usage see: docs/AddingAPrompt.md
 */
export default class Confirm extends Component {
  state = {
    showing: false,
    title: this.props.title,
    body: this.props.children,
    callback: () => {},
    cancelCallback: () => {},
  };

  onSuccess = callback => {
    this.setState({ callback });
  };

  onCancel = cancelCallback => {
    this.setState({ cancelCallback });
  };

  save = () => {
    const { callback } = this.state;

    callback();
    this.hide();
  };

  cancel = () => {
    const { cancelCallback } = this.state;

    cancelCallback();
    this.hide();
  };

  show = (title, body) => {
    this.setState({ showing: true });

    if (title) {
      this.setState({ title });
    }

    if (body) {
      this.setState({ body });
    }
  };

  hide = () => {
    const { title, children } = this.props;

    this.setState({
      showing: false,
      title,
      body: children,
      callback: () => {},
      cancelCallback: () => {},
    });
  };

  confirmButtonLabel = () => {
    const { confirmButton } = this.props;
    let label = 'OK';

    if (confirmButton) {
      label = confirmButton;
    }

    return label;
  };

  render() {
    const { dialogClassName } = this.props;
    const { title, body, showing } = this.state;

    return (
      <Modal
        data-test="modal-confirm"
        show={showing}
        onHide={this.cancel}
        dialogClassName={dialogClassName}
      >
        <Modal.Header>
          <Modal.Title data-test="modal-confirm-title">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body data-test="modal-confirm-body">{body}</Modal.Body>
        <Modal.Footer>
          <Button onClick={this.cancel}>Cancel</Button>
          <Button data-test="modal-confirm-button-ok" onClick={this.save} bsStyle="primary">
            {this.confirmButtonLabel()}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

Confirm.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  confirmButton: PropTypes.string,
  dialogClassName: PropTypes.string,
};
