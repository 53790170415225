import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  FormGroup,
  FormControl,
  ControlLabel,
  HelpBlock,
  Grid,
  Row,
  Col,
  Button,
} from 'react-bootstrap';
import TagCloud from '../TagCloud/TagCloud';
import Metadata from '../Metadata/Metadata';

export default class FolderForm extends Component {
  state = {
    showErrors: false,
    errors: {
      name: null,
    },
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { showErrors } = this.state;

    if (showErrors) {
      this.validate(nextProps);
    }
  }

  onChange = event => {
    const { onChange } = this.props;
    const { name, value } = event.target;

    if (value.includes('*') || value.includes('/')) {
      return;
    }

    onChange(name, value);
  };

  onChangeTag = tags => {
    const { onChange } = this.props;

    onChange('tags', tags);
  };

  errorClass = error => (error ? 'error' : null);

  validate = nextProps => {
    const props = nextProps || this.props;
    const {
      folder: { name },
    } = props;
    const errors = {};

    if (!name || !name.trim()) {
      errors.name = 'This field cannot be empty.';
    } else if (!this.isUniqueSiblingName(props)) {
      errors.name = 'This is already in use.';
    }

    this.setState({ showErrors: true, errors });

    return !errors.name;
  };

  submit = event => {
    const { onUpdate } = this.props;

    event.preventDefault();

    if (this.validate()) {
      onUpdate();
    }
  };

  isUniqueSiblingName = props => {
    const { folders, folder } = props;
    const siblings = folders.filter(item => item.parent === folder.parent);

    return !siblings.some(item => {
      const name = folder.name.toLowerCase().trim();
      const itemName = item.name.toLowerCase().trim();

      return +item.id !== +folder.id && itemName === name;
    });
  };

  render() {
    const {
      auth: {
        user: {
          permissions: { Writer },
        },
      },
      users,
      folder,
      onCancel,
    } = this.props;
    const { errors } = this.state;

    if (!folder) {
      return null;
    }

    const { name, description, tags } = folder;

    return (
      <form onSubmit={this.submit}>
        <Grid fluid>
          <Row>
            <Col md={6}>
              <FormGroup validationState={this.errorClass(errors.name)}>
                <ControlLabel>Name</ControlLabel>
                <FormControl
                  data-test="input-name"
                  name="name"
                  value={name}
                  onChange={this.onChange}
                  disabled={!Writer}
                />
                <HelpBlock>{errors.name}</HelpBlock>
              </FormGroup>
              <FormGroup>
                <ControlLabel>Description</ControlLabel>
                <FormControl
                  data-test="input-desc"
                  name="description"
                  componentClass="textarea"
                  rows="4"
                  placeholder="Enter description"
                  value={description}
                  onChange={this.onChange}
                  disabled={!Writer}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <ControlLabel>Tags</ControlLabel>
                <TagCloud tags={tags} onChange={this.onChangeTag} disabled={!Writer} />
              </FormGroup>
            </Col>
          </Row>
        </Grid>
        <Metadata data={folder} users={users} />
        <div className="form-buttons">
          <Button data-test="button-cancel" onClick={onCancel}>
            Cancel
          </Button>
          {Writer && (
            <Button data-test="button-update" bsStyle="success" type="submit">
              Update Folder
            </Button>
          )}
        </div>
      </form>
    );
  }
}

FolderForm.propTypes = {
  auth: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
  folders: PropTypes.array,
  folder: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
