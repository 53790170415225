import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { withRouter, Prompt } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import { getUsers } from '../../../actions/userActions';
import { SOLR_DELAY } from '../../../../constants';
import {
  loadLocation,
  loadAllLocations,
  updateLocation,
  deleteLocation,
  clearLocationForm,
} from '../../../actions/locationActions';
import Header from '../../../components/Header/Header';
import Spinner from '../../../components/Spinner/Spinner';
import LocationForm from '../../../components/Locations/LocationForm';
import LocationSelector from '../../../components/Locations/LocationSelector';
import { addNotification } from '../../../actions/notificationActions';
import Confirm from '../../../components/Confirm/Confirm';
import ErrorPage from '../ErrorPage/ErrorPage';
import ActionsMenu from '../../../components/ActionsMenu/ActionsMenu';
import QuickAction from '../../../components/QuickAction/QuickAction';
import errorMessage from '../../../utils/errorMessage';

export class LocationPage extends Component {
  state = {
    location: null,
    dirty: false,
    showFolderSelector: false,
  };

  componentDidMount() {
    this.loadData();
    document.addEventListener('visibilitychange', this.loadData);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      locations: { location },
    } = nextProps;
    const { location: stateLocation } = this.state;

    if (!stateLocation && location.id) {
      this.setState({ location });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('visibilitychange', this.loadData);
  }

  loadData = () => {
    if (document.visibilityState === 'hidden') {
      return;
    }

    const {
      match: {
        params: { id },
      },
      getUsers,
      clearLocationForm,
      loadAllLocations,
      loadLocation,
    } = this.props;

    getUsers();
    clearLocationForm();
    loadAllLocations();
    loadLocation(id);
  };

  goBack = () => {
    const { history, previousLocation } = this.props;
    const url = previousLocation
      ? previousLocation.pathname + previousLocation.search
      : '/locations';

    this.setState({ dirty: false }, () => history.push(url));
  };

  showFolderSelector = () => this.setState({ showFolderSelector: true });

  hideFolderSelector = () => this.setState({ showFolderSelector: false });

  onChange = (key, value) => {
    const { location } = this.state;

    this.setState({ location: { ...location, [key]: value }, dirty: true });
  };

  move = parent => {
    const {
      match: {
        params: { id },
      },
      locations,
      loadLocation,
      loadAllLocations,
      addNotification,
    } = this.props;

    this.hideFolderSelector();

    return updateLocation(id, {
      ...locations.location,
      parent: `${parent.parent + parent.name}/`,
    }).then(
      () => {
        addNotification({ type: 'success', text: 'Location moved.' });
        setTimeout(() => {
          loadAllLocations();
          loadLocation(id);
        }, SOLR_DELAY);
      },
      err => addNotification({ type: 'danger', text: errorMessage(err) }),
    );
  };

  update = () => {
    const {
      match: {
        params: { id },
      },
      addNotification,
    } = this.props;
    const { location } = this.state;

    return updateLocation(id, location).then(
      () => {
        addNotification({ type: 'success', text: 'Location updated.' });
        setTimeout(this.goBack, SOLR_DELAY);
      },
      err => addNotification({ type: 'danger', text: errorMessage(err) }),
    );
  };

  delete = () => {
    const {
      match: {
        params: { id },
      },
      addNotification,
    } = this.props;

    return deleteLocation(id).then(
      () => {
        addNotification({ type: 'success', text: 'Location deleted.' });
        setTimeout(this.goBack, SOLR_DELAY);
      },
      err => addNotification({ type: 'danger', text: errorMessage(err) }),
    );
  };

  onDelete = () => {
    const confirm = this.refDelete;
    const title = 'Delete Location';
    const body = (
      <div>
        <p>Are you sure you want to delete this Location?</p>
        <p>Note that the items in the deleted Location will be moved into the Location above.</p>
        <Alert bsStyle="warning">
          Deleting this Location will trigger a restart of the Devices within.
        </Alert>
      </div>
    );

    confirm.show(title, body);
    confirm.onSuccess(this.delete);
  };

  getHeaderActions = () => {
    const {
      auth: {
        user: {
          permissions: { Writer },
        },
      },
    } = this.props;
    const move = (
      <QuickAction key="1" toolText="Move Location" onClick={this.showFolderSelector} alwaysShow>
        subdirectory_arrow_right
      </QuickAction>
    );
    const remove = (
      <QuickAction key="2" toolText="Delete Location" onClick={this.onDelete} alwaysShow>
        delete
      </QuickAction>
    );

    return Writer ? [move, remove] : [];
  };

  render() {
    const {
      auth,
      locations: {
        location,
        allLocations: { locations },
        loadingLocation,
        error,
      },
      users: { users },
    } = this.props;
    const { dirty, location: stateLocation, showFolderSelector } = this.state;
    const userLocation = auth.user.user.userLocation || '';

    if (error) {
      return <ErrorPage error={error} />;
    }

    return (
      <div className="page-structure">
        <Helmet title="Location" />
        <Header overwrite={{ id: location }} />
        <ActionsMenu actions={this.getHeaderActions()} />
        <div className="page-panels">
          <div className="page-content page-location">
            <Spinner loading={loadingLocation}>
              <Prompt
                when={dirty}
                message="You have unsaved changes. Are you sure you want to leave this page?"
              />
              <LocationForm
                auth={auth}
                users={users}
                locations={locations}
                location={stateLocation}
                onChange={this.onChange}
                onUpdate={this.update}
                onCancel={this.goBack}
              />
            </Spinner>
            <Confirm
              ref={ref => {
                this.refDelete = ref;
              }}
            />
            <LocationSelector
              show={showFolderSelector}
              onHide={this.hideFolderSelector}
              onSuccess={this.move}
              items={locations}
              moving={[location]}
              userLocation={userLocation}
            />
          </div>
        </div>
      </div>
    );
  }
}

LocationPage.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  previousLocation: PropTypes.object,
  locations: PropTypes.object.isRequired,
  loadAllLocations: PropTypes.func.isRequired,
  loadLocation: PropTypes.func.isRequired,
  users: PropTypes.object.isRequired,
  getUsers: PropTypes.func.isRequired,
  clearLocationForm: PropTypes.func.isRequired,
  addNotification: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  previousLocation: state.app.previousLocation,
  locations: state.locations,
  users: state.users,
});
const mapDispatchToProps = {
  getUsers,
  loadAllLocations,
  loadLocation,
  clearLocationForm,
  addNotification,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LocationPage));
