import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import fileTypes from '../../../common/fileTypes';

export default class UploadFontDialog extends Component {
  state = { files: [] };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { show } = this.props;
    const { show: nextShow } = nextProps;

    if (show && !nextShow) {
      this.setState({ files: [] });
    }
  }

  onChange = event => this.setState({ files: Array.from(event.target.files) });

  render() {
    const { show, onHide, onSuccess } = this.props;
    const { files } = this.state;

    return (
      <Modal backdrop="static" show={show} onHide={onHide}>
        <Modal.Header>
          <Modal.Title>Upload Fonts</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            You can upload <b>{fileTypes.font.join(', ')}</b> fonts.
          </p>
          <input type="file" accept={fileTypes.font.join(', ')} onChange={this.onChange} multiple />
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn-cancel" onClick={onHide}>
            Cancel
          </Button>
          <Button
            className="btn-upload"
            bsStyle="primary"
            onClick={() => onSuccess(files)}
            disabled={!files.length}
          >
            Upload
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

UploadFontDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};
