import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Checkbox } from 'react-bootstrap';
import pluralize from 'pluralize';

export default class DeleteConfirmation extends Component {
  modalTitle = () => {
    const { itemLength } = this.props;

    return `Delete ${pluralize('Item', itemLength, true)}`;
  };

  hasFolders = () => {
    const { foldersSelected } = this.props;

    if (foldersSelected > 0) {
      return true;
    }

    return false;
  };

  render() {
    const {
      itemLength,
      foldersSelected,
      deepDelete,
      toggleDeepDelete,
      show,
      onHide,
      onSuccess,
    } = this.props;

    return (
      <Modal className="delete-confirm" bsSize="lg" backdrop="static" show={show} onHide={onHide}>
        <Modal.Header>
          <Modal.Title data-test="modal-deletecontentitems-title">{this.modalTitle()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Are you sure you want to delete {pluralize('this', itemLength)}{' '}
            {pluralize('Item', itemLength)}?
          </p>
          {this.hasFolders() && (
            <p>
              Note that the items in the deleted {pluralize('folder', foldersSelected)} will be
              moved into the folder above.
              <br />
              If you wish to delete the contents of {pluralize('this', foldersSelected)}{' '}
              {pluralize('folder', foldersSelected)} tick the checkbox below.
            </p>
          )}
          {this.hasFolders() && (
            <Checkbox
              data-test="modal-deletecontentitems-checkbox-deepdelete"
              name="deepDelete"
              checked={deepDelete}
              onChange={toggleDeepDelete}
            >
              Delete contents of {pluralize('this', foldersSelected)}{' '}
              {pluralize('folder', foldersSelected)}
            </Checkbox>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            data-test="modal-deletecontentitems-button-cancel"
            className="btn-cancel"
            onClick={onHide}
          >
            Cancel
          </Button>
          <Button
            data-test="modal-deletecontentitems-button-delete"
            bsStyle="danger"
            onClick={onSuccess}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

DeleteConfirmation.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  toggleDeepDelete: PropTypes.func.isRequired,
  deepDelete: PropTypes.bool.isRequired,
  itemLength: PropTypes.number,
  foldersSelected: PropTypes.number,
};
