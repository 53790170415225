import * as types from '../actions/actionTypes';

export const initialState = {
  playlistsLoading: false,
  playlistLoading: false,
  playlistsError: null,
  playlistError: null,
  selected: {},
  playlist: {},
  playlists: {},
};

export default function playlists(state = initialState, action = {}) {
  switch (action.type) {
    case types.PLAYLISTS_REQUEST:
      return {
        ...state,
        playlistsLoading: true,
        playlistsError: null,
      };
    case types.PLAYLISTS_REQUEST_SUCCESS:
      return {
        ...state,
        playlistsLoading: false,
        playlistsError: null,
        playlists: action.result,
      };
    case types.PLAYLISTS_REQUEST_FAILED:
      return {
        ...state,
        playlistsLoading: false,
        playlistsError: action.error,
      };
    case types.PLAYLIST_REQUEST:
      return {
        ...state,
        playlistLoading: true,
        playlistError: null,
      };
    case types.PLAYLIST_REQUEST_SUCCESS:
      return {
        ...state,
        playlistLoading: false,
        playlistError: null,
        playlist: action.result,
      };
    case types.PLAYLIST_REQUEST_FAILED:
      return {
        ...state,
        playlistLoading: false,
        playlistError: action.error,
      };
    case types.PLAYLIST_FORM_CLEAR:
      return {
        ...state,
        playlist: {},
      };
    default:
      return state;
  }
}
