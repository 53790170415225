/* TODO: #3.0.0  TEST this works... This doesn't work on devcms for some reason so we can't test. */
/**
 * launchCreator() Opens a creator instance
 *
 * @param {signId} [Optional] The id of the sign to open
 * @param {parentId} [Optional] The id of the sign's parent folder
 * @return {void}
 */
export function launchCreator(signId, parentId) {
  const form = document.createElement('form');

  form.target = '_blank';
  form.method = 'POST';
  form.action = '/creatorWrapper/cgi-bin/dmpeng';

  // Create a page prop
  const name = document.createElement('input');

  name.type = 'hidden';
  name.name = 'page';
  name.value = 'signage'; // pass in the name of the sign?
  form.appendChild(name);

  // Create a quick launch prop
  const ql = document.createElement('input');

  ql.type = 'hidden';
  ql.name = 'id';
  ql.value = signId >= 0 ? signId : '';
  form.appendChild(ql);

  const parent = document.createElement('input');

  parent.type = 'hidden';
  parent.name = 'parent';
  parent.value = parentId || 0;
  form.appendChild(parent);

  // Create a tab prop
  const tab = document.createElement('input');

  tab.type = 'hidden';
  tab.name = 'tab';
  tab.value = 'home';
  form.appendChild(tab);

  // Add the form to dom
  document.body.appendChild(form);

  // submit the form
  form.submit();

  // delete the form.
  document.body.removeChild(form);
}
