import * as types from '../actions/actionTypes';

export const initialState = {
  loading: false,
  error: null,
  dataObjects: [],
  dataObject: {},
  tier: {},
  locations: [],
  inUse: [],
  selected: {},
};

export default function dataObjects(state = initialState, action = {}) {
  switch (action.type) {
    case types.DATA_OBJECTS_SELECT:
      return {
        ...state,
        selected: {
          ...action.ids,
        },
      };
    case types.DATA_OBJECTS_UNSELECT_ALL:
      return {
        ...state,
        selected: {},
      };
    case types.DATA_OBJECTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case types.DATA_OBJECTS_REQUEST_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case types.DATA_OBJECTS_REQUEST_SUCCESS_DATA_OBJECTS:
      return {
        ...state,
        loading: false,
        dataObjects: action.result,
      };
    case types.DATA_OBJECTS_REQUEST_SUCCESS_DATA_OBJECT:
      return {
        ...state,
        loading: false,
        dataObject: action.result,
      };
    case types.DATA_OBJECTS_REQUEST_SUCCESS_TIER:
      return {
        ...state,
        loading: false,
        tier: action.result,
      };
    case types.DATA_OBJECTS_REQUEST_SUCCESS_LOCATIONS:
      return {
        ...state,
        loading: false,
        locations: action.result,
      };
    case types.DATA_OBJECTS_REQUEST_SUCCESS_IN_USE:
      return {
        ...state,
        loading: false,
        inUse: action.result,
      };
    default:
      return state;
  }
}
