import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import clone from 'clone';
import deepEqual from 'fast-deep-equal';
import { withRouter, Prompt } from 'react-router-dom';
import { SOLR_DELAY } from '../../../../constants';
import { getUsers } from '../../../actions/userActions';
import { loadFolders } from '../../../actions/folderActions';
import { getAssets } from '../../../actions/assetActions';
import { getSigns } from '../../../actions/signActions';
import { loadSequences } from '../../../actions/sequenceActions';
import { loadSchedules } from '../../../actions/scheduleActions';
import {
  loadPlaylists,
  loadPlaylist,
  createPlaylist,
  updatePlaylist,
  deletePlaylist,
  clearPlaylistForm,
} from '../../../actions/playlistActions';
import { loadCampaigns } from '../../../actions/campaignActions';
import { loadAllAssignments } from '../../../actions/assignmentActions';
import { addNotification } from '../../../actions/notificationActions';
import PlaylistForm from '../../../components/Playlists/PlaylistForm';
import DuplicatePlaylistDialog from '../../../components/Playlists/DuplicatePlaylistDialog';
import Used from '../../../components/Used/Used';
import Header from '../../../components/Header/Header';
import QuickAction from '../../../components/QuickAction/QuickAction';
import Spinner from '../../../components/Spinner/Spinner';
import Confirm from '../../../components/Confirm/Confirm';
import AssetSelector from '../../../components/AssetSelector/AssetSelector';
import LocationSelector from '../../../components/Locations/LocationSelector';
import ErrorPage from '../ErrorPage/ErrorPage';
import ActionsMenu from '../../../components/ActionsMenu/ActionsMenu';
import errorMessage from '../../../utils/errorMessage';
import { getParent } from '../../../utils/parent';
import { getPermissions } from '../../../utils/getPermissions';

export class PlaylistPage extends Component {
  getNewPlaylist = () => ({ name: '', description: '', tags: [], files: [] });

  state = {
    playlist: this.props.match.params.id ? null : this.getNewPlaylist(),
    dirty: false,
    showAssetSelector: false,
    showDuplicateDialog: false,
    showFolderSelector: false,
  };

  componentDidMount() {
    this.loadData();
    document.addEventListener('visibilitychange', this.loadData);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      playlists: { playlist: nextPlaylist },
    } = nextProps;
    const {
      playlists: { playlist: currPlaylist },
    } = this.props;

    if (deepEqual(currPlaylist, nextPlaylist)) {
      return;
    }

    const { playlist } = this.state;

    if (!playlist && nextPlaylist.id) {
      this.setState({ playlist: nextPlaylist });
    }

    this.updateEntries(nextProps);
  }

  componentWillUnmount() {
    document.removeEventListener('visibilitychange', this.loadData);
  }

  loadData = () => {
    if (document.visibilityState === 'hidden') {
      return;
    }

    const {
      match: {
        params: { id },
      },
      getUsers,
      loadFolders,
      getAssets,
      getSigns,
      loadSequences,
      loadSchedules,
      loadPlaylists,
      loadPlaylist,
      loadCampaigns,
      loadAllAssignments,
      clearPlaylistForm,
    } = this.props;

    getUsers();
    loadFolders('?count=100000000');
    getAssets('?type=video&approved=true&count=100000000');
    getSigns('?count=100000000');
    loadSequences('?count=100000000');
    loadSchedules('?count=100000000');
    loadPlaylists('?count=100000000');
    loadCampaigns('?count=100000000');
    loadAllAssignments();
    clearPlaylistForm();

    if (id) {
      loadPlaylist(id);
    }
  };

  buildPlaylistForServer = () => {
    const {
      playlist: { name, description, tags, files, parent, tagControlled },
    } = this.state;

    return {
      name: name.trim(),
      description: (description || '').trim(),
      tags,
      files,
      parent: +getParent(this.props, parent).id,
      tagControlled,
    };
  };

  updateEntries = nextProps => {
    const {
      assets: {
        assets: { assets },
      },
    } = nextProps;
    const { playlist } = this.state;
    const files = [];

    if (!playlist) {
      return;
    }

    playlist.files.forEach(item => {
      const entry = assets.find(asset => asset.id === item.id);

      if (entry) {
        files.push(entry);
      }
    });

    if (files.length) {
      this.setState({ playlist: { ...playlist, files } });
    }
  };

  goBack = () => {
    const { history, previousLocation } = this.props;
    const url = previousLocation
      ? previousLocation.pathname + previousLocation.search
      : '/content?type=playlist&parent=/*';

    this.setState({ dirty: false }, () => history.push(url));
  };

  add = () => {
    const { addNotification } = this.props;
    const playlist = this.buildPlaylistForServer();

    return createPlaylist(playlist).then(
      () => {
        addNotification({ type: 'success', text: 'Playlist added.' });
        setTimeout(this.goBack, SOLR_DELAY);
      },
      err => addNotification({ type: 'danger', text: errorMessage(err) }),
    );
  };

  update = () => {
    const {
      match: {
        params: { id },
      },
      addNotification,
    } = this.props;
    const playlist = this.buildPlaylistForServer();

    return updatePlaylist(id, playlist).then(
      () => {
        addNotification({ type: 'success', text: 'Playlist updated.' });
        setTimeout(this.goBack, SOLR_DELAY);
      },
      err => addNotification({ type: 'danger', text: errorMessage(err) }),
    );
  };

  delete = () => {
    const {
      match: {
        params: { id },
      },
      addNotification,
    } = this.props;

    return deletePlaylist(id).then(
      () => {
        addNotification({ type: 'success', text: 'Playlist deleted.' });
        setTimeout(this.goBack, SOLR_DELAY);
      },
      err => addNotification({ type: 'danger', text: errorMessage(err) }),
    );
  };

  duplicate = name => {
    const { addNotification } = this.props;
    const playlist = this.buildPlaylistForServer();

    playlist.name = name.trim();

    return createPlaylist(playlist).then(
      () => {
        addNotification({ type: 'success', text: 'Playlist duplicated.' });
        setTimeout(this.goBack, SOLR_DELAY);
      },
      err => addNotification({ type: 'danger', text: errorMessage(err) }),
    );
  };

  move = parent => {
    const {
      match: {
        params: { id },
      },
      playlists,
      loadPlaylist,
      addNotification,
    } = this.props;

    this.hideFolderSelector();

    return updatePlaylist(id, { ...playlists.playlist, parent: parent.id }).then(
      () => {
        addNotification({ type: 'success', text: 'Playlist moved.' });
        loadPlaylist(id);
      },
      err => addNotification({ type: 'danger', text: errorMessage(err) }),
    );
  };

  onDelete = () => {
    const {
      playlists: {
        playlist: { usedInSigns },
      },
    } = this.props;
    const confirm = this.confirmDelete;

    confirm.show(
      'Delete Playlist',
      usedInSigns
        ? 'This Playlist is used in signs and cannot be deleted. Please remove it from the signs first.'
        : 'Are you sure you want to delete this Playlist?',
    );

    if (!usedInSigns) {
      confirm.onSuccess(this.delete);
    }
  };

  onSuccessDuplicateDialog = name => {
    this.hideDuplicateDialog();
    this.duplicate(name);
  };

  showAssetSelector = () => this.setState({ showAssetSelector: true });

  showDuplicateDialog = () => this.setState({ showDuplicateDialog: true });

  showFolderSelector = () => this.setState({ showFolderSelector: true });

  hideAssetSelector = () => this.setState({ showAssetSelector: false });

  hideDuplicateDialog = () => this.setState({ showDuplicateDialog: false });

  hideFolderSelector = () => this.setState({ showFolderSelector: false });

  onClickAssetSelectorItem = item => {
    const { addNotification } = this.props;
    const { playlist } = this.state;
    const newPlaylist = clone(playlist);

    if (!newPlaylist.files) {
      newPlaylist.files = [];
    }

    newPlaylist.files.push(item);
    this.setState({ playlist: newPlaylist, dirty: true });
    addNotification({ type: 'success', text: `${item.name} added to the entries.` });

    return newPlaylist;
  };

  onChange = (key, value) => {
    const { playlist } = this.state;

    this.setState({ playlist: { ...playlist, [key]: value }, dirty: true });
  };

  onSort = files => {
    const { playlist } = this.state;

    this.setState({ playlist: { ...playlist, files }, dirty: true });
  };

  onRemoveFile = index => {
    const { playlist } = this.state;
    const newPlaylist = clone(playlist);

    newPlaylist.files.splice(index, 1);
    this.setState({ playlist: newPlaylist, dirty: true });
  };

  getHeaderActions = () => {
    const {
      match: {
        params: { id },
      },
      auth,
    } = this.props;
    const p = getPermissions(auth);

    if (!id || !p.writer) {
      return [];
    }

    const actions = [];
    const duplicate = (
      <QuickAction
        key="1"
        toolText="Duplicate Playlist"
        onClick={this.showDuplicateDialog}
        alwaysShow
      >
        content_copy
      </QuickAction>
    );
    const move = p.atLeastTierStandard && (
      <QuickAction key="2" toolText="Move Playlist" onClick={this.showFolderSelector} alwaysShow>
        subdirectory_arrow_right
      </QuickAction>
    );
    const remove = (
      <QuickAction key="3" toolText="Delete Playlist" onClick={this.onDelete} alwaysShow>
        delete
      </QuickAction>
    );

    actions.push(duplicate, move, remove);

    return actions.filter(Boolean);
  };

  render() {
    const {
      match: {
        params: { id },
      },
      auth,
      users: { users },
      folders: {
        folders: { folders },
      },
      assets: {
        assets: { assets },
      },
      signs: {
        signs: { signs },
      },
      sequences: {
        sequences: { sequences },
      },
      schedules: {
        schedules: { schedules },
      },
      playlists: {
        playlists: { playlists },
        playlist,
        playlistLoading,
        playlistError,
      },
      campaigns: {
        campaigns: { campaigns },
      },
      assignments: {
        allAssignments: { assignments },
      },
    } = this.props;
    const {
      dirty,
      playlist: statePlaylist,
      showAssetSelector,
      showDuplicateDialog,
      showFolderSelector,
    } = this.state;

    if (id && playlistError) {
      return <ErrorPage error={playlistError} />;
    }

    return (
      <div className="page-structure">
        <Helmet title="Playlist" />
        <Header overwrite={{ id: playlist }} />
        <ActionsMenu actions={this.getHeaderActions()} />
        <div className="page-panels">
          <div className="page-content page-playlist">
            <Spinner loading={playlistLoading}>
              <Prompt
                when={dirty}
                message="You have unsaved changes. Are you sure you want to leave this page?"
              />
              <PlaylistForm
                auth={auth}
                users={users}
                playlist={statePlaylist}
                signs={signs}
                sequences={sequences}
                schedules={schedules}
                playlists={playlists}
                campaigns={campaigns}
                assignments={assignments}
                isNew={!id}
                onChange={this.onChange}
                onAdd={this.add}
                onUpdate={this.update}
                onCancel={this.goBack}
                onSort={this.onSort}
                onAddFile={this.showAssetSelector}
                onRemoveFile={this.onRemoveFile}
              />
              <Used
                type="playlist"
                item={statePlaylist}
                signs={signs}
                sequences={sequences}
                schedules={schedules}
                playlists={playlists}
                campaigns={campaigns}
                assignments={assignments}
                auth={auth}
                users={users}
              />
            </Spinner>
            <AssetSelector
              type="video"
              title="Add Videos to the Playlist"
              show={showAssetSelector}
              onHide={this.hideAssetSelector}
              onSuccess={this.onClickAssetSelectorItem}
              assets={assets}
            />
            <DuplicatePlaylistDialog
              playlists={playlists}
              playlist={playlist}
              show={showDuplicateDialog}
              onHide={this.hideDuplicateDialog}
              onSuccess={this.onSuccessDuplicateDialog}
            />
            <Confirm
              ref={confirm => {
                this.confirmDelete = confirm;
              }}
              title="Delete Playlist"
            />
            <LocationSelector
              type="folder"
              show={showFolderSelector}
              onHide={this.hideFolderSelector}
              onSuccess={this.move}
              items={folders}
              currentPath={playlist.parent}
            />
          </div>
        </div>
      </div>
    );
  }
}

PlaylistPage.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  previousLocation: PropTypes.object,
  users: PropTypes.object.isRequired,
  folders: PropTypes.object.isRequired,
  assets: PropTypes.object.isRequired,
  signs: PropTypes.object.isRequired,
  sequences: PropTypes.object.isRequired,
  schedules: PropTypes.object.isRequired,
  playlists: PropTypes.object.isRequired,
  campaigns: PropTypes.object.isRequired,
  assignments: PropTypes.object.isRequired,
  getUsers: PropTypes.func.isRequired,
  getAssets: PropTypes.func.isRequired,
  loadFolders: PropTypes.func.isRequired,
  getSigns: PropTypes.func.isRequired,
  loadSequences: PropTypes.func.isRequired,
  loadSchedules: PropTypes.func.isRequired,
  loadPlaylist: PropTypes.func.isRequired,
  loadPlaylists: PropTypes.func.isRequired,
  loadCampaigns: PropTypes.func.isRequired,
  loadAllAssignments: PropTypes.func.isRequired,
  addNotification: PropTypes.func.isRequired,
  clearPlaylistForm: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  previousLocation: state.app.previousLocation,
  auth: state.auth,
  users: state.users,
  folders: state.folders,
  assets: state.assets,
  signs: state.signs,
  sequences: state.sequences,
  schedules: state.schedules,
  playlists: state.playlists,
  campaigns: state.campaigns,
  assignments: state.assignments,
});
const mapDispatchToProps = {
  getUsers,
  loadFolders,
  getAssets,
  getSigns,
  loadSequences,
  loadSchedules,
  loadPlaylist,
  loadPlaylists,
  loadCampaigns,
  loadAllAssignments,
  clearPlaylistForm,
  addNotification,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PlaylistPage));
