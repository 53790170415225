import getSignsForAsset from './getSignsForAsset';
import getSequencesForPlaylist from './getSequencesForPlaylist';

const getSchedulesForPlaylist = (asset, signs, sequences, schedules) => {
  if (!asset || !signs || !sequences || !schedules) {
    return [];
  }

  const usedInSigns = getSignsForAsset(asset, signs);
  const usedInSequences = getSequencesForPlaylist(asset, signs, sequences);
  const used = [];

  schedules.forEach(schedule =>
    schedule.scheduleEntries.find(item => {
      if (item.sign_id && usedInSigns.find(sign => sign.id === item.sign_id)) {
        used.push(schedule);

        return true;
      }

      if (item.sequence_id && usedInSequences.find(sequence => sequence.id === item.sequence_id)) {
        used.push(schedule);

        return true;
      }

      return false;
    }),
  );

  return used.sort((a, b) => a.name.localeCompare(b.name));
};

export default getSchedulesForPlaylist;
