import React from 'react';
import PropTypes from 'prop-types';

const Spinner = props => {
  const { loading, className, children } = props;

  if (!loading) {
    return <div className={className}>{children}</div>;
  }

  return (
    <div data-test="busy-spinner" className={`${className} loading spinner`}>
      <div />
    </div>
  );
};

Spinner.propTypes = {
  loading: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
};

export default Spinner;
