import Spinner from '../Spinner/Spinner';
import DeployReport from './DeployReport';
import NotificationHistory from './NotificationHistory';
import ErrorModal from './ErrorModal';

export const components = {
  Spinner: {
    Component: Spinner,
    attributes: {
      loading: true,
    },
    dialogClassName: 'app centered transparent',
  },
  DeployReport: {
    Component: DeployReport,
    dialogClassName: 'app wide',
  },
  NotificationHistory: {
    Component: NotificationHistory,
    dialogClassName: 'app wide',
  },
  ErrorModal: {
    Component: ErrorModal,
    dialogClassName: 'app error',
  },
};
