import qs from 'qs';

// parse querystring
export default props => {
  if (!props || !props.location) {
    return {};
  }

  return qs.parse(props.location.search, { ignoreQueryPrefix: true });
};
