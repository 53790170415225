const getPlaylistsForVideo = (asset, playlists) => {
  if (!asset || !playlists) {
    return [];
  }

  return (asset.usedInPlaylists || [])
    .map(id => playlists.find(playlist => playlist.id === +id))
    .filter(Boolean)
    .sort((a, b) => a.name.localeCompare(b.name));
};

export default getPlaylistsForVideo;
