import 'isomorphic-fetch';
import * as types from './actionTypes';
import fetchCheck from '../redux/middleware/fetchCheck';

export const url = '/cms/data/v1/url-rulesets/';

export const credentials = 'include';

export const headers = { Accept: 'application/json', 'Content-Type': 'application/json' };

let body;

export const select = ids => ({ type: types.UR_SELECT, ids });

export const unselectAll = () => ({ type: types.UR_UNSELECT_ALL });

export const request = () => ({ type: types.UR_REQUEST });

export const requestWithLoadingStatus = () => ({ type: types.UR_REQUEST_WITH_LOADING_STATUS });

export const requestFailed = error => ({ type: types.UR_REQUEST_FAILED, error });

export const requestSuccessUrlRulesets = result => ({
  type: types.UR_REQUEST_SUCCESS_URL_RULESETS,
  result,
});

export const requestSuccessUrlRuleset = result => ({
  type: types.UR_REQUEST_SUCCESS_URL_RULESET,
  result,
});

export const requestSuccessInUse = result => ({ type: types.UR_REQUEST_SUCCESS_IN_USE, result });

export const fetchUrlRulesets = () =>
  fetch(url, { method: 'GET', credentials, headers })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const fetchUrlRuleset = id =>
  fetch(url + id, { method: 'GET', credentials, headers })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const addUrlRuleset = name => {
  body = JSON.stringify({ name });

  return fetch(url, { method: 'POST', credentials, headers, body })
    .then(res => fetchCheck(res))
    .then(res => res.json());
};

export const duplicateUrlRuleset = (id, name) => {
  body = JSON.stringify({ name });

  return fetch(url + id, { method: 'POST', credentials, headers, body })
    .then(res => fetchCheck(res))
    .then(res => res.json());
};

export const deleteUrlRuleset = id =>
  fetch(url + id, { method: 'DELETE', credentials, headers })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const fetchInUse = () =>
  fetch(`${url}inuse`, { method: 'GET', credentials, headers })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const addUrlRule = (id, type, beginsWith, rule) => {
  body = JSON.stringify({ type, beginsWith, rule });

  return fetch(`${url + id}/rule/`, { method: 'POST', credentials, headers, body })
    .then(res => fetchCheck(res))
    .then(res => res.json());
};

export const updateUrlRule = (id, ruleId, type, beginsWith, rule) => {
  body = JSON.stringify({ type, beginsWith, rule });

  return fetch(`${url + id}/rule/${ruleId}`, { method: 'PUT', credentials, headers, body })
    .then(res => fetchCheck(res))
    .then(res => res.json());
};

export const deleteUrlRule = (id, ruleId) => {
  return fetch(`${url + id}/rule/${ruleId}`, { method: 'DELETE', credentials, headers })
    .then(res => fetchCheck(res))
    .then(res => res.json());
};

export const getUrlRulesets = () => dispatch => {
  dispatch(requestWithLoadingStatus());

  return fetchUrlRulesets().then(
    res => dispatch(requestSuccessUrlRulesets(res)),
    err => dispatch(requestFailed(err)),
  );
};

export const getUrlRuleset = id => dispatch => {
  dispatch(requestWithLoadingStatus());

  return fetchUrlRuleset(id).then(
    res => dispatch(requestSuccessUrlRuleset(res)),
    err => dispatch(requestFailed(err)),
  );
};

export const getInUse = () => dispatch => {
  dispatch(request());

  return fetchInUse().then(
    res => dispatch(requestSuccessInUse(res)),
    err => dispatch(requestFailed(err)),
  );
};
