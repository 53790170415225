import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import qs from '../../utils/qs';
import { addUniqueQueryParam, removeQueryParam } from '../../utils/updateQueryString';
import { addUniqueFilter } from '../../actions/filterActions';

export class FilterText extends Component {
  state = { value: '' };

  componentDidMount() {
    this.setFilterFromQuery(this.props);
    this.setValueFromQuery(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setFilterFromQuery(nextProps);
    this.setValueFromQuery(nextProps);
  }

  setFilterFromQuery = props => {
    const { name, title, addUniqueFilter } = props;
    const value = qs(props)[name];

    if (value) {
      addUniqueFilter({ group: name, name, query: value, text: value, title });
    }
  };

  setValueFromQuery = props => {
    const { name } = props;

    this.setState({ value: qs(props)[name] || '' });
  };

  onChange = event => {
    const { value } = event.target;

    this.setState({ value });
  };

  onKeyPress = event => {
    if (event.key === 'Enter') {
      this.updateQuery(event);
    }
  };

  updateQuery = event => {
    const { history, location, name } = this.props;
    const value = event.target.value.trim();
    const oldValue = qs(this.props)[name] || '';

    if (value === oldValue) {
      return;
    }

    if (!value) {
      removeQueryParam(history, location, name, oldValue);
    } else {
      addUniqueQueryParam(history, location, name, value);
    }
  };

  render() {
    const { name, placeholder } = this.props;
    const { value } = this.state;

    return (
      <input
        data-test={`input-${name}`}
        type="text"
        className="filter-text form-control"
        placeholder={placeholder || ''}
        value={value}
        onChange={this.onChange}
        onKeyPress={this.onKeyPress}
        onBlur={this.updateQuery}
      />
    );
  }
}

FilterText.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  addUniqueFilter: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  addUniqueFilter,
};

export default withRouter(connect(null, mapDispatchToProps)(FilterText));
