import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { savePreviousLocation, showAppModal, hideAppModal } from '../../actions/appActions';
import { loadContentItems } from '../../actions/contentItemActions';
import { loadFolders } from '../../actions/folderActions';
import { queueUploads, uploadAsset } from '../../actions/assetActions';
import { addNotification } from '../../actions/notificationActions';
import { getCampaignSchedule } from '../../actions/campaignScheduleActions';
import { deployContent } from '../../actions/deviceActions';
import Navigation from '../../components/Navigation/Navigation';
import Notifications from '../../components/Notifications/Notifications';
import FullScreenDropZone from '../../components/FullScreenDropZone/FullScreenDropZone';
import { AppModal } from '../../components/AppModal/AppModal';
import ErrorPage from '../Pages/ErrorPage/ErrorPage';
import config from '../../config';
import { getDeployer } from '../../utils/deployHelper';
import { getPermissions } from '../../utils/getPermissions';
import './App.scss';

export class App extends Component {
  componentDidMount() {
    const { getCampaignSchedule } = this.props;

    getCampaignSchedule();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      location,
      assets: { uploading },
      savePreviousLocation,
    } = this.props;
    const {
      location: { pathname, search },
    } = nextProps;

    window.addEventListener('beforeunload', e => {
      if (!uploading.some(item => item.progress < 100)) {
        return;
      }

      const msg = 'You are still uploading assets. Are you sure you want to leave?';

      e.returnValue = msg;

      return msg;
    });
    const oldLocation = location.pathname + location.search;
    const newLocation = pathname + search;

    if (oldLocation !== newLocation) {
      savePreviousLocation(location);
    }
  }

  showNotificationHistory = () => {
    const { notificationHistory, showAppModal, hideAppModal } = this.props;

    return () =>
      showAppModal({
        show: true,
        content: 'NotificationHistory',
        title: 'Notification History',
        data: notificationHistory,
        cancelFn: () => hideAppModal(),
      });
  };

  authorisation = () => {
    const {
      auth,
      location: { pathname },
    } = this.props;
    const p = getPermissions(auth);
    const path = pathname.split('/')[1];
    // Home page only allowed when client has landing page
    const homePage = pathname.startsWith('/home');
    const clientHasNoLandingPage = !p.landingpage;

    if (homePage && clientHasNoLandingPage) {
      return false;
    }

    // Standard tier
    if (p.atLeastTierStandard) {
      switch (path) {
        case 'content':
        case 'folders':
        case 'assets':
        case 'playlists':
        case 'signs':
        case 'sequences':
        case 'schedules':
        case 'campaigns':
        case 'assignments':
        case 'campaign-schedule':
        case 'fonts':
          return p.contentViewer;
        case 'data-objects':
          return p.contentViewer && p.moduleDataObject;
        case 'url-rulesets':
          return p.contentViewer && p.moduleUrlRuleset;
        case 'dashboard':
          return p.contentViewer && p.deviceViewer;
        case 'devices':
          return p.deviceViewer;
        case 'locations':
        case 'users':
        case 'roles':
          return p.admin;
        default:
          return true;
      }
    }

    // Lite tier

    // Lite tier can only access assignments page but not campaigns page
    // /campaigns/:id - not allowed
    // /campaigns/:id/assignments - allowed
    const campaignPage = pathname.startsWith('/campaigns/') && !pathname.includes('assignments');

    if (campaignPage) {
      return false;
    }

    switch (path) {
      case 'folders':
      case 'signs':
      case 'campaign-schedule':
      case 'fonts':
      case 'data-objects':
      case 'url-rulesets':
      case 'roles':
        return false;
      case 'content':
      case 'assets':
      case 'playlists':
      case 'sequences':
      case 'schedules':
      case 'assignments':
      case 'dashboard':
        return p.contentViewer && p.deviceViewer;
      case 'devices':
        return p.deviceViewer;
      case 'locations':
      case 'users':
        return p.admin && p.atLeastTierStandardOrSuperUser;
      default:
        return true;
    }
  };

  getContent = () => {
    const { children } = this.props;

    if (this.authorisation()) {
      return children;
    }

    return <ErrorPage error={{ status: 403, statusText: 'Forbidden' }} />;
  };

  getContainer = () => {
    const {
      appModal,
      dialog: { isOpen },
      auth: { user },
      assets: { uploading },
      campaignSchedule,
      deployContent,
      location: { pathname },
    } = this.props;
    const homePage = pathname.startsWith('/home');
    const css = `page navigation-open${homePage ? ' home' : ''}`;

    return (
      <div className={`structure${isOpen ? ' blur' : ''}`}>
        <AppModal appModal={appModal} />
        <Notifications />
        <Navigation
          user={user}
          uploading={uploading}
          campaignSchedule={campaignSchedule}
          deployer={getDeployer(deployContent)}
          showNotificationHistory={this.showNotificationHistory()}
        />
        <div className={css}>{this.getContent()}</div>
      </div>
    );
  };

  getDropZone = () => {
    const {
      location,
      folders: {
        folders: { folders },
      },
      queueUploads,
      uploadAsset,
      loadFolders,
      loadContentItems,
      addNotification,
    } = this.props;

    return (
      <FullScreenDropZone
        location={location}
        folders={folders}
        queueUploads={queueUploads}
        uploadAsset={uploadAsset}
        loadFolders={loadFolders}
        loadContentItems={loadContentItems}
        addNotification={addNotification}
      >
        {this.getContainer()}
      </FullScreenDropZone>
    );
  };

  getPage = () => {
    const {
      auth: {
        user: {
          permissions: { Writer },
        },
      },
    } = this.props;

    return Writer ? this.getDropZone() : this.getContainer();
  };

  render() {
    return (
      <div className="app">
        <Helmet {...config.app.head} />
        {this.getPage()}
      </div>
    );
  }
}

App.propTypes = {
  appModal: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  addNotification: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  queueUploads: PropTypes.func.isRequired,
  uploadAsset: PropTypes.func.isRequired,
  loadFolders: PropTypes.func.isRequired,
  loadContentItems: PropTypes.func.isRequired,
  savePreviousLocation: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  assets: PropTypes.object.isRequired,
  folders: PropTypes.object.isRequired,
  dialog: PropTypes.object.isRequired,
  campaignSchedule: PropTypes.object.isRequired,
  getCampaignSchedule: PropTypes.func.isRequired,
  deployContent: PropTypes.func.isRequired,
  notificationHistory: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
      id: PropTypes.number.isRequired,
      timestamp: PropTypes.string.isRequired,
    }),
  ).isRequired,
  showAppModal: PropTypes.func.isRequired,
  hideAppModal: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  appModal: state.app.appModal,
  auth: state.auth,
  assets: state.assets,
  folders: state.folders,
  campaignSchedule: state.campaignSchedule,
  dialog: state.dialog,
  notificationHistory: state.notifications.history,
});
const mapDispatchToProps = {
  addNotification,
  queueUploads,
  uploadAsset,
  loadFolders,
  loadContentItems,
  savePreviousLocation,
  getCampaignSchedule,
  deployContent,
  showAppModal,
  hideAppModal,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
