import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Prompt } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Button } from 'react-bootstrap';
import { format, min, parse } from 'date-fns';
import clone from 'clone';
import { SOLR_DELAY } from '../../../../constants';
import Header from '../../../components/Header/Header';
import { loadCampaigns } from '../../../actions/campaignActions';
import {
  getCampaignSchedule,
  updateCampaignSchedule,
} from '../../../actions/campaignScheduleActions';
import { addNotification } from '../../../actions/notificationActions';
import Spinner from '../../../components/Spinner/Spinner';
import AssetSelector from '../../../components/AssetSelector/AssetSelector';
import ErrorPage from '../ErrorPage/ErrorPage';
import ToolTipIcon from '../../../components/ToolTipIcon/ToolTipIcon';
import CampaignScheduleEntriesTable from '../../../components/CampaignSchedule/CampaignScheduleEntriesTable';
import errorMessage from '../../../utils/errorMessage';

export class CampaignSchedulePage extends Component {
  state = {
    entries: [],
    dirty: false,
    showCampaignSelector: false,
  };

  componentDidMount() {
    this.loadData();
    document.addEventListener('visibilitychange', this.loadData);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      campaignSchedule: { loading },
    } = this.props;
    const {
      campaignSchedule: {
        loading: nextLoading,
        campaignSchedule: { campaignScheduleEntries },
      },
    } = nextProps;

    if (loading && !nextLoading && campaignScheduleEntries) {
      this.setState({ entries: campaignScheduleEntries });
    }
  }

  componentWillUnmount = () => {
    document.removeEventListener('visibilitychange', this.loadData);
  };

  loadData = () => {
    if (document.visibilityState === 'hidden') {
      return;
    }

    const { loadCampaigns, getCampaignSchedule } = this.props;
    const { dirty } = this.state;

    loadCampaigns('?count=100000000');

    if (!dirty) {
      getCampaignSchedule();
    }
  };

  showCampaignSelector = () => this.setState({ showCampaignSelector: true });

  hideCampaignSelector = () => this.setState({ showCampaignSelector: false });

  onClickCampaignSelectorItem = item => {
    const { addNotification } = this.props;
    const { entries } = this.state;
    const newEntries = clone(entries);
    const entry = {
      campaignId: +item.id,
      campaignName: item.name,
      campaignDescription: item.description,
      campaignParent: item.parent,
      campaignTags: item.tags ? item.tags.join(',') : null,
      date: format(new Date(), 'yyyyMMdd'),
      time: '1200',
    };

    newEntries.push(entry);
    this.setState({ entries: newEntries, dirty: true });
    addNotification({ type: 'success', text: `${item.name} added to the entries.` });

    return entry;
  };

  buildCampaignScheduleForServer = () => {
    const { entries } = this.state;

    return {
      campaignScheduleEntries: entries.map(item => ({
        campaignId: item.campaignId,
        date: item.date,
        time: item.time,
      })),
    };
  };

  cancel = () => {
    const {
      campaignSchedule: {
        campaignSchedule: { campaignScheduleEntries },
      },
    } = this.props;

    this.setState({
      entries: campaignScheduleEntries,
      dirty: false,
    });
  };

  update = () => {
    const { addNotification, getCampaignSchedule } = this.props;
    const schedule = this.buildCampaignScheduleForServer();

    return updateCampaignSchedule(schedule).then(
      () => {
        addNotification({ type: 'success', text: 'Campaign Schedule updated.' });
        this.setState({ dirty: false });
        setTimeout(getCampaignSchedule, SOLR_DELAY);
      },
      err => addNotification({ type: 'danger', text: errorMessage(err) }),
    );
  };

  onChangeDate = (value, index) => {
    const { entries } = this.state;
    const newEntries = clone(entries);

    newEntries[index].date = value;
    this.setState({ entries: newEntries, dirty: true });
  };

  onChangeHour = (event, index) => {
    const { entries } = this.state;
    const newEntries = clone(entries);

    newEntries[index].time = event.target.value + newEntries[index].time.slice(2, 4);
    this.setState({ entries: newEntries, dirty: true });
  };

  onChangeMinute = (event, index) => {
    const { entries } = this.state;
    const newEntries = clone(entries);

    newEntries[index].time = newEntries[index].time.slice(0, 2) + event.target.value;
    this.setState({ entries: newEntries, dirty: true });
  };

  onRemoveEntry = index => {
    const { entries } = this.state;
    const newEntries = clone(entries);

    newEntries.splice(index, 1);
    this.setState({ entries: newEntries, dirty: true });
  };

  loneCampaignWarning = () => {
    const { entries } = this.state;

    if (entries.length === 1) {
      return (
        <p>
          <small>
            You must have at least one campaign in your schedule. If you wish to remove the campaign
            shown below you will must first add another to replace it.
          </small>
        </p>
      );
    }
  };

  hasActiveCampaign = () => {
    const { entries } = this.state;
    const dates = entries.map(item => parse(item.date, 'yyyyMMdd', new Date()));

    return min(dates) < new Date();
  };

  render() {
    const {
      auth,
      campaigns: {
        campaigns: { campaigns },
      },
      campaignSchedule: { loading, error },
    } = this.props;
    const { dirty, entries, showCampaignSelector } = this.state;
    const {
      user: {
        permissions: { Writer },
      },
    } = auth;

    if (error) {
      return <ErrorPage error={error} />;
    }

    return (
      <div className="page-structure">
        <Helmet title="Campaign Schedule" />
        <Header />
        <div className="page-panels">
          <div className="page-content page-campaign-schedule">
            <Spinner loading={loading}>
              <Prompt
                when={dirty}
                message="You have unsaved changes. Are you sure you want to leave this page?"
              />
              <form>
                {Writer && (
                  <ToolTipIcon
                    className="add-btn"
                    toolText="Add Campaign"
                    onClick={this.showCampaignSelector}
                  >
                    add_circle
                  </ToolTipIcon>
                )}
                <h4>Campaign Schedule Entries</h4>
                {this.loneCampaignWarning()}
                <CampaignScheduleEntriesTable
                  auth={auth}
                  data={entries}
                  onChangeDate={this.onChangeDate}
                  onChangeHour={this.onChangeHour}
                  onChangeMinute={this.onChangeMinute}
                  onRemoveEntry={this.onRemoveEntry}
                />
                {Writer && (
                  <div className="form-buttons">
                    <Button onClick={this.cancel}>Discard Changes</Button>
                    <Button
                      bsStyle="success"
                      onClick={this.update}
                      disabled={!dirty || !this.hasActiveCampaign()}
                    >
                      Update Campaign Schedule
                    </Button>
                  </div>
                )}
              </form>
            </Spinner>
          </div>
        </div>
        <AssetSelector
          type="campaign"
          title="Add Campaigns to the Campaign Schedule"
          show={showCampaignSelector}
          onHide={this.hideCampaignSelector}
          onSuccess={this.onClickCampaignSelectorItem}
          assets={campaigns}
        />
      </div>
    );
  }
}

CampaignSchedulePage.propTypes = {
  auth: PropTypes.object.isRequired,
  campaigns: PropTypes.object.isRequired,
  campaignSchedule: PropTypes.object.isRequired,
  loadCampaigns: PropTypes.func.isRequired,
  getCampaignSchedule: PropTypes.func.isRequired,
  addNotification: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  campaigns: state.campaigns,
  campaignSchedule: state.campaignSchedule,
});
const mapDispatchToProps = {
  loadCampaigns,
  getCampaignSchedule,
  addNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignSchedulePage);
