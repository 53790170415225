import * as types from '../actions/actionTypes';

export const initialState = {
  loading: false,
  error: null,
  selected: {},
  roles: [],
  role: {},
};

export default function roles(state = initialState, action = {}) {
  switch (action.type) {
    case types.ROLES_SELECT:
      return {
        ...state,
        selected: action.ids,
      };
    case types.ROLES_UNSELECT_ALL:
      return {
        ...state,
        selected: {},
      };
    case types.ROLES_REQUEST:
      return {
        ...state,
        error: null,
      };
    case types.ROLES_REQUEST_WITH_LOADING_STATUS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case types.ROLES_REQUEST_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case types.ROLES_REQUEST_SUCCESS_ROLES:
      return {
        ...state,
        loading: false,
        roles: action.result,
      };
    case types.ROLES_REQUEST_SUCCESS_ROLE:
      return {
        ...state,
        loading: false,
        role: action.result,
      };
    case types.ROLES_CLEAR_ROLE:
      return {
        ...state,
        role: {},
      };
    default:
      return state;
  }
}
