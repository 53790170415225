import * as types from './actionTypes';

export function savePreviousLocation(previousLocation) {
  return {
    type: types.SAVE_PREVIOUS_LOCATION,
    previousLocation,
  };
}

export function showAppModal(appModal) {
  return {
    type: types.SHOW_APP_MODAL,
    appModal,
  };
}

export function hideAppModal() {
  return {
    type: types.HIDE_APP_MODAL,
  };
}
