import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Alert, FormGroup, FormControl } from 'react-bootstrap';
import { DeviceType, FirmwareType, deviceTypesToFirmwareTypes } from './utils/DeviceType';
import { getOnlineStatusIcon } from './DeviceUtils';
import semver from '../../utils/semver';

export default class UpgradeDeviceDialog extends Component {
  getInitialState = () => ({
    version: '',
    target: 'selected',
    confirm: false,
  });

  state = this.getInitialState();

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { show } = nextProps;

    if (!show) {
      this.setState(this.getInitialState());
    }
  }

  onChangeVersion = event => this.setState({ version: event.target.value, confirm: false });

  onChangeUpgrade = event => this.setState({ target: event.target.value, confirm: false });

  onChangeConfirm = () => {
    const { confirm } = this.state;

    this.setState({ confirm: !confirm });
  };

  upgrade = () => {
    const { onUpgrade, onUpgradeAll } = this.props;
    const { version, target } = this.state;

    if (target === 'selected') {
      onUpgrade(version);
    } else {
      onUpgradeAll(version);
    }
  };

  getVersionsThatAvailableForEveryDevice = (devices, versions) => {
    // e.g. deviceTypes = ['', 'android_allsee_batterypowered_ekd08', 'android_allsee_dualscreen_ekd08', 'android_apc380', 'android_ekd08', 'android_m12', 'android_mk808', 'android_other', 'android_tpv', 'android_tpv_4150'];
    const deviceTypes = [...new Set(devices.map(item => item.device_type))].sort();
    // e.g. firmwareTypes = ['android', 'tpv_3650', 'tpv_4050', 'tpv_4051', 'tpv_4150', 'tpv_4550', 'tpv_4650', 'tpv_crd50']
    const firmwareTypes = deviceTypesToFirmwareTypes(deviceTypes);
    // e.g. availableVersions = ['3.3.4', '3.3.5'] these are the available versions for EVERY device
    let availableVersions = versions
      .filter(item => firmwareTypes.every(type => item.firmware_types.includes(type)))
      .map(item => item.version)
      .filter(semver.valid)
      .sort((b, a) => semver.compare(a, b)); // sort in descending order (newest version first)
    // there is a device with a device_type that is missing from the DeviceType object - this results in FirmwareType.UNKNOWN
    // e.g. device.device_type = 'some_unknown_device_type'
    const hasUnknown = firmwareTypes.includes(FirmwareType.UNKNOWN);
    // legacy Windows device version starts with 'win' e.g. 'win2.8.5'
    // e.g device.version = 'win2.8.5'
    const hasLegacyWindows = devices.some(item => item.version?.startsWith('win'));
    // HTML Player based players do not return device_type and firmware_version prior 1.6.4
    const hasHtml = devices
      .filter(item => !item.device_type && !item.firmware_version)
      .filter(item => semver.valid(item.version))
      .some(item => semver.compare(item.version, '1.6.4') === -1);
    const hasAllSeeDualScreen = deviceTypes.includes(DeviceType.ALLSEE_DUALSCREEN_EKD08);
    const hasWedge = devices.some(item => item.device_type === DeviceType.EKD08_WEDGE);
    const hasRefeeA55fd = deviceTypes.includes(DeviceType.REFEE_A55FD);
    const hasRefeeBa37b = deviceTypes.includes(DeviceType.REFEE_BA37B);
    const hasLamasaDualScreen = deviceTypes.includes(DeviceType.LAMASA_DUALSCREEN);
    const hasLedStudio339a = deviceTypes.includes(DeviceType.LEDSTUDIO_339A);
    const hasVestel400vs = deviceTypes.includes(DeviceType.VESTEL_400VS);
    const hasVestelOther = deviceTypes.includes(DeviceType.ANDROID_OTHER_VESTEL);
    const hasX6 = deviceTypes.includes(DeviceType.X96_X6);
    const hasPhilips4050 = deviceTypes.includes(DeviceType.PHILIPS_4050);
    const hasLinux = deviceTypes.includes(DeviceType.LINUX);
    const hasWindows = deviceTypes.includes(DeviceType.WINDOWS);
    const hasSamsungTizen4 = deviceTypes.includes(DeviceType.SAMSUNG_TIZEN_4);
    const hasSamsungTizen7 = deviceTypes.includes(DeviceType.SAMSUNG_TIZEN_7);

    // non-default apk-s:
    // (no need to check for first supported version, like we do it for default apk based players)
    // PHILIPS_3650:     supported from 3.3.15,   apk: CmsClient_3.3.15.3650Q.apk
    // PHILIPS_4050_ZIP: supported from 2.10.5L4, apk: update_2.10.5L4.zip
    // PHILIPS_4050:     supported from 3.3.8,    apk: CmsClient_3.3.8.apk (default apk)
    // PHILIPS_4051:     supported from 3.3.4R3,  apk: CmsClient_3.3.5.4051D.apk
    // PHILIPS_4150:     supported from 3.3.0,    apk: CmsClient_3.3.0.4150D.apk
    // PHILIPS_4550:     supported from 3.3.5,    apk: CmsClient_3.3.5.4550D.apk
    // PHILIPS_4650:     supported from 3.3.14,   apk: CmsClient_3.3.14.4650D.apk
    // PHILIPS_CRD50:    supported from 3.3.14,   apk: CmsClient_3.3.14.CRD50.apk
    // ALLSEE:           supported from 3.3.5,    apk: CmsClient_3.3.5.allsee.apk

    if (hasUnknown) {
      // prevent the upgrade, unknown device
      availableVersions = [];
    }

    if (hasLegacyWindows) {
      // prevent the upgrade, legacy Windows Player cannot be updated in Director
      availableVersions = [];
    }

    if (hasHtml) {
      // prevent the upgrade, HTML Player does not support upgrade yet
      availableVersions = [];
    }

    if (hasWedge) {
      // supported up to 3.3.3, using default apk (they are only tested up to 3.3.3)
      // we also added allsee_dualscreen support in 3.3.5 and wedge devices are no longer supported because of that
      // wedge and allsee_dualscreen devices are both using the default apk and they have rk3288 chipset
      // because no model numbers are provided in their API, wedge and allsee_dualscreen devices are indistinguishable
      availableVersions = availableVersions.filter(item => semver.compare(item, '3.3.4') === -1);
    }

    if (hasAllSeeDualScreen) {
      // supported from 3.3.5, using default apk
      availableVersions = availableVersions.filter(item => semver.compare(item, '3.3.5') > -1);
    }

    if (hasRefeeBa37b || hasVestel400vs || hasVestelOther || hasX6) {
      // supported from 3.3.7, using default apk
      availableVersions = availableVersions.filter(item => semver.compare(item, '3.3.7') > -1);
    }

    if (hasPhilips4050) {
      // supported from 3.3.8, using default apk
      availableVersions = availableVersions.filter(item => semver.compare(item, '3.3.8') > -1);
    }

    if (hasRefeeA55fd) {
      // supported from 3.3.13, using default apk
      availableVersions = availableVersions.filter(item => semver.compare(item, '3.3.13') > -1);
    }

    if (hasLamasaDualScreen) {
      // supported from 3.3.14, using default apk
      availableVersions = availableVersions.filter(item => semver.compare(item, '3.3.14') > -1);
    }

    if (hasLedStudio339a) {
      // supported from 3.3.16, using default apk
      availableVersions = availableVersions.filter(item => semver.compare(item, '3.3.16') > -1);
    }

    if (hasLinux || hasWindows || hasSamsungTizen4 || hasSamsungTizen7) {
      // supported from 1.6.4
      availableVersions = availableVersions.filter(item => semver.compare(item, '1.6.4') > -1);
    }

    return availableVersions;
  };

  getVersionOptions = (devices, availableVersions) => {
    const errorMessage =
      devices.length > 1
        ? 'No compatible player versions for the selected devices - try to select fewer devices.'
        : 'No player versions available for this device.';
    const optionMessage = availableVersions.length ? 'Select a player version' : errorMessage;
    const options = availableVersions.map((item, index) => <option key={index}>{item}</option>);

    options.unshift(
      <option key={-1} value="" disabled>
        {optionMessage}
      </option>,
    );

    return options;
  };

  getDevices = () => {
    const { devices } = this.props;
    const rows = devices.map((item, index) => (
      <tr key={index}>
        <td>{index + 1}.</td>
        <td>{getOnlineStatusIcon(item)}</td>
        <td>
          <code>{item.name}</code>
        </td>
        <td>{item.description}</td>
        <td>{item.version || <small className="text-light">N/A</small>}</td>
        <td>{item.device_type || <small className="text-light">N/A</small>}</td>
        <td>{item.firmware_version || <small className="text-light">N/A</small>}</td>
      </tr>
    ));

    return (
      <table className="table table-striped table-device-upgrade">
        <thead>
          <tr>
            <th />
            <th />
            <th>Device ID</th>
            <th>Device Name</th>
            <th>Player Version</th>
            <th>Device Type</th>
            <th>Firmware</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    );
  };

  render() {
    const { show, onHide, devices, versions, total } = this.props;
    const { version, target, confirm } = this.state;
    const plural = devices.length > 1 ? 's' : '';
    const availableVersions = this.getVersionsThatAvailableForEveryDevice(devices, versions);

    return (
      <Modal
        data-test="modal-upgradedevice"
        dialogClassName="device-dialog width-auto"
        backdrop="static"
        show={show}
        onHide={onHide}
      >
        <Modal.Header>
          <Modal.Title data-test="modal-upgradedevice-title">Upgrade Device{plural}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormGroup>
            <label>SignStix Player version</label>
            <FormControl componentClass="select" value={version} onChange={this.onChangeVersion}>
              {this.getVersionOptions(devices, availableVersions)}
            </FormControl>
          </FormGroup>
          {total && (
            <FormGroup>
              <label>Device{plural} to upgrade</label>
              <FormControl componentClass="select" value={target} onChange={this.onChangeUpgrade}>
                <option value="selected">
                  Selected ({devices.length} device{plural})
                </option>
                <option value="all">All Compatible Devices</option>
              </FormControl>
            </FormGroup>
          )}
          {target === 'all' && (
            <div>
              You are going to upgrade <b>ALL</b> of your compatible devices.
              <br />
              <br />
            </div>
          )}
          {target === 'selected' && (
            <div>
              <p>
                {devices.length} device{plural} to upgrade:
              </p>
              {this.getDevices()}
            </div>
          )}
          {version && (
            <Alert bsStyle="warning">
              <label>
                <input
                  data-test="modal-upgradedevice-checkbox-confirm"
                  type="checkbox"
                  checked={confirm}
                  onChange={this.onChangeConfirm}
                />{' '}
                I want to upgrade{' '}
                {target === 'all' ? 'all compatible Devices' : `${devices.length} Device${plural}`}{' '}
                to Player version {version}.
              </label>
            </Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn-cancel" onClick={onHide}>
            Cancel
          </Button>
          <Button
            data-test="modal-upgradedevice-button-upgrade"
            className="btn-add"
            bsStyle="primary"
            onClick={this.upgrade}
            disabled={!confirm || !version}
          >
            Upgrade Device{plural}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

UpgradeDeviceDialog.propTypes = {
  devices: PropTypes.array.isRequired,
  total: PropTypes.number,
  versions: PropTypes.array.isRequired,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onUpgrade: PropTypes.func.isRequired,
  onUpgradeAll: PropTypes.func.isRequired,
};
