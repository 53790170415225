import React from 'react';
import Tag from '../components/Tag/Tag';

export const buildTagList = object => {
  const tagList = [];

  if (object.tags) {
    for (let i = 0; i < object.tags.length; i += 1) {
      if (object.tags[i].length > 0) {
        tagList.push(<Tag key={i}>{object.tags[i]}</Tag>);
      }
    }
  }

  return tagList;
};
