import fetchCheck from '../redux/middleware/fetchCheck';
import * as types from './actionTypes';

export const authLoad = () => {
  return { type: types.AUTH_LOAD };
};

export const authLoadSuccess = result => {
  return { type: types.AUTH_LOAD_SUCCESS, result };
};

export const authLoadFailure = error => {
  return { type: types.AUTH_LOAD_FAILED, error };
};

export const nextPath = path => {
  return { type: types.AUTH_NEXT_PATH, path };
};

export const updateAvatarPicture = avatar => {
  return { type: types.AUTH_UPDATE_AVATAR, avatar };
};

export const internalLoadAuth = () => {
  return fetch('/cms/data/v1/loadAuth', {
    method: 'GET',
    credentials: 'include',
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
  }).then(res => {
    if (res.ok) {
      return res.json();
    }

    throw new Error();
  });
};

export const logoutUser = () => {
  return fetch('/cms/data/v1/logout', {
    method: 'POST',
    credentials: 'include',
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
  })
    .then(res => fetchCheck(res))
    .then(res => res.json());
};

export const load = () => {
  return dispatch => {
    dispatch(authLoad());

    return internalLoadAuth().then(
      res => dispatch(authLoadSuccess(res)),
      err => dispatch(authLoadFailure(err)),
    );
  };
};

export const updateAvatar = avatar => dispatch => dispatch(updateAvatarPicture(avatar));
