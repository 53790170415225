const getSequencesForSign = (sign, sequences) => {
  if (!sign || !sequences) {
    return [];
  }

  return sequences
    .filter(sequence => sequence.sequenceEntries.find(item => item.sign_id === +sign.id))
    .sort((a, b) => a.name.localeCompare(b.name));
};

export default getSequencesForSign;
