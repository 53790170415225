import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Tag from '../../components/Tag/Tag';
import { isQueryParam, addQueryParam, removeQueryParam } from '../../utils/updateQueryString';
import { addFilter } from '../../actions/filterActions';

export class FilterTagList extends Component {
  componentDidMount() {
    this.setFilterFromQuery(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setFilterFromQuery(nextProps);
  }

  setFilterFromQuery = props => {
    const { location, name, title, data, addFilter } = props;

    if (!data || !data.length) {
      return;
    }

    for (let index = 0; index < data.length; index += 2) {
      const value = data[index];
      const checked = isQueryParam(location, name, value);

      if (checked) {
        addFilter({ group: name, name: value, query: value, text: value, title });
      }
    }
  };

  handleClick = value => {
    const { history, location, name } = this.props;
    const checked = isQueryParam(location, name, value);

    if (checked) {
      removeQueryParam(history, location, name, value);
    } else {
      addQueryParam(history, location, name, value);
    }
  };

  renderTagList = () => {
    const { location, name, data } = this.props;
    const list = [];

    if (!data || !data.length) {
      return list;
    }

    for (let index = 0; index < data.length; index += 2) {
      const value = data[index];
      const count = data[index + 1];
      const checked = isQueryParam(location, name, value);

      if (value.length > 0) {
        list.push(
          <Tag
            key={index}
            checked={checked}
            title={`found: ${count}`}
            onClick={() => this.handleClick(value)}
          >
            {value}
          </Tag>,
        );
      }
    }

    return list;
  };

  render() {
    const { title } = this.props;
    const tagList = this.renderTagList();

    if (!tagList.length) {
      return null;
    }

    return (
      <div>
        <div className="filter-section-title">{title}</div>
        <div className="filter-taglist">{tagList}</div>
      </div>
    );
  }
}

FilterTagList.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  data: PropTypes.array,
  addFilter: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  addFilter,
};

export default withRouter(connect(null, mapDispatchToProps)(FilterTagList));
