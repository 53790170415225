import getPlaylistsForVideo from './getPlaylistsForVideo';
import getSchedulesForPlaylist from './getSchedulesForPlaylist';

const getSchedulesForVideo = (asset, signs, sequences, schedules, playlists) => {
  if (!asset || !signs || !sequences || !schedules || !playlists) {
    return [];
  }

  const used = [];

  getPlaylistsForVideo(asset, playlists).forEach(video =>
    getSchedulesForPlaylist(video, signs, sequences, schedules).forEach(schedule => {
      if (!used.find(item => item.id === schedule.id)) {
        used.push(schedule);
      }
    }),
  );

  return used.sort((a, b) => a.name.localeCompare(b.name));
};

export default getSchedulesForVideo;
