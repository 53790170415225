import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Header from '../../../components/Header/Header';
import { getPermissions } from '../../../utils/getPermissions';

export class HomePage extends Component {
  state = { height: 0 };

  componentDidMount() {
    this.updateHeight();
    window.addEventListener('resize', this.updateHeight);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateHeight);
  }

  updateHeight = () => this.setState({ height: window.innerHeight - 75 });

  render() {
    const { auth } = this.props;
    const { height } = this.state;
    const p = getPermissions(auth);

    return (
      <div className="page-structure">
        <Helmet title="Home" />
        <Header />
        <div className="page-panels">
          <div className="page-content page-home">
            <iframe style={{ height }} src={p.landingpage} title="Landing Page" />
          </div>
        </div>
      </div>
    );
  }
}

HomePage.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
});
const mapDispatchToProps = {};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomePage));
