import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { withRouter, Prompt } from 'react-router-dom';
import { SOLR_DELAY } from '../../../../constants';
import Header from '../../../components/Header/Header';
import { loadFolders } from '../../../actions/folderActions';
import { getUsers } from '../../../actions/userActions';
import { getSigns } from '../../../actions/signActions';
import {
  loadSequences,
  loadSequence,
  saveSequence,
  updateSequence,
  deleteSequence,
  setForm,
  editForm,
} from '../../../actions/sequenceActions';
import { loadSchedules } from '../../../actions/scheduleActions';
import { loadPlaylists } from '../../../actions/playlistActions';
import { loadCampaigns } from '../../../actions/campaignActions';
import { loadAllAssignments } from '../../../actions/assignmentActions';
import QuickAction from '../../../components/QuickAction/QuickAction';
import SequenceForm from '../../../components/Sequences/SequenceForm';
import Used from '../../../components/Used/Used';
import DuplicateSequenceDialog from '../../../components/Sequences/DuplicateSequenceDialog';
import AssetSelector from '../../../components/AssetSelector/AssetSelector';
import LocationSelector from '../../../components/Locations/LocationSelector';
import Spinner from '../../../components/Spinner/Spinner';
import Confirm from '../../../components/Confirm/Confirm';
import { addNotification } from '../../../actions/notificationActions';
import ErrorPage from '../ErrorPage/ErrorPage';
import ActionsMenu from '../../../components/ActionsMenu/ActionsMenu';
import errorMessage from '../../../utils/errorMessage';
import { getPermissions } from '../../../utils/getPermissions';
import { getParent } from '../../../utils/parent';

export class SequencePage extends Component {
  state = {
    index: null,
    dirty: false,
    showSignSelector: false,
    showPlaylistSelector: false,
    showDuplicateDialog: false,
    showFolderSelector: false,
  };

  componentDidMount() {
    this.loadData();
    this.buildSequenceWhenDataIsLoaded(this.props);
    document.addEventListener('visibilitychange', this.loadData);
    this.setForm();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    const {
      match: {
        params: { id: nextId },
      },
    } = nextProps;

    if (id !== nextId) {
      this.setForm(nextProps);
    }

    this.buildSequenceWhenDataIsLoaded(nextProps);
    this.updateEntries(nextProps);
  }

  componentWillUnmount() {
    document.removeEventListener('visibilitychange', this.loadData);
  }

  loadData = () => {
    if (document.visibilityState === 'hidden') {
      return;
    }

    const {
      match: {
        params: { id },
      },
      getUsers,
      loadFolders,
      loadSequences,
      loadSequence,
      loadSchedules,
      loadPlaylists,
      loadCampaigns,
      loadAllAssignments,
      getSigns,
    } = this.props;
    const { dirty } = this.state;

    getUsers();
    loadFolders('?count=100000000');
    loadSequences('?count=100000000');
    loadSchedules('?count=100000000');
    loadPlaylists('?count=100000000');
    loadCampaigns('?count=100000000');
    loadAllAssignments();
    getSigns('?count=100000000&published=true&isAssignable=true');

    if (!dirty && id) {
      loadSequence(id);
    }
  };

  setForm = nextProps => {
    const props = nextProps?.match?.params ? nextProps : this.props;
    const {
      match: {
        params: { id },
      },
      sequences,
      setForm,
    } = props;
    const newSequence = { name: '', description: '', tags: null, sequenceEntries: [] };
    const sequence = id ? sequences.sequence : newSequence;

    setForm(sequence);
  };

  buildSequenceForServer = sequence => ({
    name: sequence.name.trim(),
    parent: sequence.parentInfo.id,
    description: sequence.description ? sequence.description.trim() : null,
    tags: sequence.tags,
    sequenceEntries: sequence.sequenceEntries.map((item, index) => ({
      order: index + 1,
      repeat: item.repeat,
      sign_id: item.sign.id,
      playlist_id: item.playlist.id,
      channel_id: 0,
    })),
  });

  buildSequenceEntries = nextProps => {
    const {
      sequences: {
        edit: { sequenceEntries },
      },
      signs: {
        signs: { signs },
      },
      playlists: {
        playlists: { playlists },
      },
      editForm,
    } = nextProps;
    let entries = sequenceEntries.map(entry => {
      const sign = signs.find(item => +item.id === +entry.sign_id);
      let playlist = playlists.find(item => +item.id === +entry.playlist_id);

      if (!playlist) {
        playlist = { id: -1, name: '' };
      }

      entry.sign = sign;
      entry.playlist = playlist;

      return entry;
    });

    // remove sequence entry if sign not found
    entries = entries.filter(item => item.sign);
    editForm('sequenceEntries', entries);
  };

  updateEntries = nextProps => {
    const {
      signs: {
        signs: { signs },
      },
      sequences: {
        edit: { sequenceEntries },
      },
    } = nextProps;
    const entries = [];
    let changed = false;

    if (!sequenceEntries) {
      return;
    }

    sequenceEntries.forEach(entry => {
      const newSign = signs.find(sign => +sign.id === +entry.sign_id);

      if (!entry.sign || !newSign) {
        return;
      }

      if (
        entry.sign.modifiedDate !== newSign.modifiedDate ||
        entry.sign.pendingDate !== newSign.pendingDate ||
        entry.sign.publishedDate !== newSign.publishedDate
      ) {
        entry.sign = newSign;
        changed = true;
      }

      entries.push(entry);
    });

    if (changed) {
      editForm('sequenceEntries', entries);
    }
  };

  buildSequenceWhenDataIsLoaded = nextProps => {
    const {
      folders: { loadingFolders },
      sequences: {
        edit: { sequenceEntries, parent, parentInfo },
      },
      signs: { loadingSigns },
      playlists: { playlistsLoading },
      editForm,
    } = nextProps;
    const entriesNeedToBuild =
      sequenceEntries && sequenceEntries[0] && !sequenceEntries[0].playlist;

    if (!loadingFolders && !parentInfo) {
      const parentInf = getParent(nextProps, parent);

      editForm('parentInfo', parentInf);
    }

    if (!playlistsLoading && !loadingSigns && entriesNeedToBuild) {
      this.buildSequenceEntries(nextProps);
    }
  };

  goBack = () => {
    const { history, previousLocation } = this.props;
    const url = previousLocation
      ? previousLocation.pathname + previousLocation.search
      : '/content?type=sequence&parent=/*';

    this.setState({ dirty: false }, () => history.push(url));
  };

  add = () => {
    const { sequences, addNotification } = this.props;
    const sequence = this.buildSequenceForServer(sequences.edit);

    return saveSequence(sequence).then(
      () => {
        addNotification({ type: 'success', text: 'Sequence added.' });
        setTimeout(this.goBack, SOLR_DELAY);
      },
      err => addNotification({ type: 'danger', text: errorMessage(err) }),
    );
  };

  update = () => {
    const {
      match: {
        params: { id },
      },
      sequences,
      addNotification,
    } = this.props;
    const sequence = this.buildSequenceForServer(sequences.edit);

    return updateSequence(id, sequence).then(
      () => {
        addNotification({ type: 'success', text: 'Sequence updated.' });
        setTimeout(this.goBack, SOLR_DELAY);
      },
      err => addNotification({ type: 'danger', text: errorMessage(err) }),
    );
  };

  delete = () => {
    const {
      match: {
        params: { id },
      },
      addNotification,
    } = this.props;

    return deleteSequence(id).then(
      () => {
        addNotification({ type: 'success', text: 'Sequence deleted.' });
        setTimeout(this.goBack, SOLR_DELAY);
      },
      err => addNotification({ type: 'danger', text: errorMessage(err) }),
    );
  };

  duplicate = name => {
    const { sequences, addNotification } = this.props;
    const sequence = this.buildSequenceForServer(sequences.edit);

    sequence.name = name.trim();

    return saveSequence(sequence).then(
      () => {
        addNotification({ type: 'success', text: 'Sequence duplicated.' });
        setTimeout(this.goBack, SOLR_DELAY);
      },
      err => addNotification({ type: 'danger', text: errorMessage(err) }),
    );
  };

  move = parent => {
    const {
      match: {
        params: { id },
      },
      sequences,
      loadSequence,
      addNotification,
    } = this.props;

    this.hideFolderSelector();

    return updateSequence(id, { ...sequences.sequence, parent: parent.id }).then(
      () => {
        addNotification({ type: 'success', text: 'Sequence moved.' });
        loadSequence(id);
      },
      err => addNotification({ type: 'danger', text: errorMessage(err) }),
    );
  };

  onDelete = () => {
    const confirm = this.confirmDelete;

    confirm.show('Delete Sequence', 'Are you sure you want to delete this Sequence?');
    confirm.onSuccess(this.delete);
  };

  onSuccessDuplicateDialog = name => {
    this.hideDuplicateDialog();
    this.duplicate(name);
  };

  showSignSelector = () => this.setState({ showSignSelector: true });

  showPlaylistSelector = () => this.setState({ showPlaylistSelector: true });

  showDuplicateDialog = () => this.setState({ showDuplicateDialog: true });

  showFolderSelector = () => this.setState({ showFolderSelector: true });

  hideSignSelector = () => this.setState({ showSignSelector: false });

  hidePlaylistSelector = () => this.setState({ showPlaylistSelector: false });

  hideDuplicateDialog = () => this.setState({ showDuplicateDialog: false });

  hideFolderSelector = () => this.setState({ showFolderSelector: false });

  onChange = (key, value) => {
    const { editForm } = this.props;
    const { dirty } = this.state;

    editForm(key, value);

    if (!dirty) {
      this.setState({ dirty: true });
    }
  };

  onClickSignSelectorItem = item => {
    const {
      auth,
      sequences: {
        edit: { sequenceEntries },
      },
      addNotification,
    } = this.props;
    const p = getPermissions(auth);
    const entry = {
      repeat: p.atLeastTierStandard ? 1 : -2, // -2: Indefinitely required for Lite tier video signs
      sign: item,
      playlist: { id: -1, name: '' },
    };

    sequenceEntries.push(entry);
    this.onChange('sequenceEntries', sequenceEntries);
    addNotification({ type: 'success', text: `${item.name} added to the entries.` });
  };

  onClickPlaylistSelectorItem = item => {
    const {
      sequences: {
        edit: { sequenceEntries },
      },
      addNotification,
    } = this.props;
    const { index } = this.state;

    sequenceEntries[index].playlist = item;
    this.onChange('sequenceEntries', sequenceEntries);
    addNotification({ type: 'success', text: `${item.name} added to the entries.` });
  };

  onSort = items => this.onChange('sequenceEntries', items);

  onRemoveEntry = index => {
    const {
      sequences: {
        edit: { sequenceEntries },
      },
    } = this.props;

    sequenceEntries.splice(index, 1);
    this.onChange('sequenceEntries', sequenceEntries);
  };

  onChangeRepeat = (event, index) => {
    const {
      sequences: {
        edit: { sequenceEntries },
      },
    } = this.props;

    sequenceEntries[index].repeat = Number(event.target.value);
    this.onChange('sequenceEntries', sequenceEntries);
  };

  onClickPlaylist = index => {
    this.showPlaylistSelector();
    this.setState({ index });
  };

  onRemovePlaylist = index => {
    const {
      sequences: {
        edit: { sequenceEntries },
      },
    } = this.props;

    sequenceEntries[index].playlist = { id: -1, name: '' };
    this.onChange('sequenceEntries', sequenceEntries);
  };

  getHeaderActions = () => {
    const {
      match: {
        params: { id },
      },
      auth,
    } = this.props;
    const p = getPermissions(auth);
    const actions = [];
    const preview = (
      <QuickAction
        key="1"
        toolText="Preview Sequence"
        href={`/player/?sequence=${id}`}
        target="_blank"
        alwaysShow
      >
        play_circle_filled
      </QuickAction>
    );
    const duplicate = (
      <QuickAction
        key="2"
        toolText="Duplicate Sequence"
        onClick={this.showDuplicateDialog}
        alwaysShow
      >
        content_copy
      </QuickAction>
    );
    const move = p.atLeastTierStandard && (
      <QuickAction key="3" toolText="Move Sequence" onClick={this.showFolderSelector} alwaysShow>
        subdirectory_arrow_right
      </QuickAction>
    );
    const remove = (
      <QuickAction key="4" toolText="Delete Sequence" onClick={this.onDelete} alwaysShow>
        delete
      </QuickAction>
    );

    if (!id) {
      return [];
    }

    actions.push(preview);

    if (p.writer) {
      actions.push(duplicate, move, remove);
    }

    return actions.filter(Boolean);
  };

  render() {
    const {
      match: {
        params: { id },
      },
      auth,
      users: { users },
      folders,
      signs: {
        signs: { signs: allSigns },
      },
      sequences: {
        sequences: { sequences },
        sequence,
        edit,
        error,
      },
      schedules: {
        schedules: { schedules },
      },
      playlists: {
        playlists: { playlists },
      },
      campaigns: {
        campaigns: { campaigns },
      },
      assignments: {
        allAssignments: { assignments },
      },
    } = this.props;
    const {
      dirty,
      showSignSelector,
      showPlaylistSelector,
      showDuplicateDialog,
      showFolderSelector,
    } = this.state;
    const p = getPermissions(auth);
    const signs = p.atLeastTierStandard
      ? allSigns
      : allSigns.filter(sign => sign.name.startsWith('video'));

    if (error) {
      return <ErrorPage error={error} />;
    }

    if (!sequences || !signs || !playlists) {
      return null;
    }

    return (
      <div className="page-structure">
        <Helmet title="Sequence" />
        <Header overwrite={{ id: sequence }} />
        <ActionsMenu actions={this.getHeaderActions()} />
        <div className="page-panels">
          <div className="page-content page-sequence">
            <Spinner loading={sequences.loadingSequence}>
              <Prompt
                when={dirty}
                message="You have unsaved changes. Are you sure you want to leave this page?"
              />
              <SequenceForm
                auth={auth}
                users={users}
                folders={folders}
                sequences={sequences}
                sequence={edit}
                isNew={!id}
                onChange={this.onChange}
                onAdd={this.add}
                onUpdate={this.update}
                onCancel={this.goBack}
                onSort={this.onSort}
                onAddEntry={this.showSignSelector}
                onRemoveEntry={this.onRemoveEntry}
                onChangeRepeat={this.onChangeRepeat}
                onClickPlaylist={this.onClickPlaylist}
                onRemovePlaylist={this.onRemovePlaylist}
              />
              <Used
                type="sequence"
                item={edit}
                signs={signs}
                sequences={sequences}
                schedules={schedules}
                playlists={playlists}
                campaigns={campaigns}
                assignments={assignments}
                auth={auth}
                users={users}
              />
            </Spinner>
          </div>
          <AssetSelector
            type="sign"
            title="Add Signs to the Sequence"
            show={showSignSelector}
            onHide={this.hideSignSelector}
            onSuccess={this.onClickSignSelectorItem}
            assets={signs}
          />
          <AssetSelector
            type="playlist"
            title="Assign Playlist to a Sign in the Sequence"
            show={showPlaylistSelector}
            onHide={this.hidePlaylistSelector}
            onSuccess={this.onClickPlaylistSelectorItem}
            assets={playlists}
          />
        </div>
        <Confirm
          ref={confirm => {
            this.confirmDelete = confirm;
          }}
          title="Delete Sequence"
        />
        <DuplicateSequenceDialog
          sequences={sequences}
          original={sequence}
          show={showDuplicateDialog}
          onHide={this.hideDuplicateDialog}
          onSuccess={this.onSuccessDuplicateDialog}
        />
        <LocationSelector
          type="folder"
          show={showFolderSelector}
          onHide={this.hideFolderSelector}
          onSuccess={this.move}
          items={folders.folders.folders}
          currentPath={sequence.parent}
        />
      </div>
    );
  }
}

SequencePage.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  previousLocation: PropTypes.object,
  users: PropTypes.object.isRequired,
  folders: PropTypes.object.isRequired,
  signs: PropTypes.object,
  sequences: PropTypes.object.isRequired,
  schedules: PropTypes.object.isRequired,
  playlists: PropTypes.object.isRequired,
  campaigns: PropTypes.object.isRequired,
  assignments: PropTypes.object.isRequired,
  getUsers: PropTypes.func.isRequired,
  getSigns: PropTypes.func.isRequired,
  loadFolders: PropTypes.func.isRequired,
  loadSequence: PropTypes.func.isRequired,
  loadSequences: PropTypes.func.isRequired,
  loadSchedules: PropTypes.func.isRequired,
  loadPlaylists: PropTypes.func.isRequired,
  loadCampaigns: PropTypes.func.isRequired,
  loadAllAssignments: PropTypes.func.isRequired,
  editForm: PropTypes.func.isRequired,
  setForm: PropTypes.func.isRequired,
  addNotification: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  previousLocation: state.app.previousLocation,
  users: state.users,
  folders: state.folders,
  signs: state.signs,
  sequences: state.sequences,
  schedules: state.schedules,
  playlists: state.playlists,
  campaigns: state.campaigns,
  assignments: state.assignments,
});
const mapDispatchToProps = {
  getUsers,
  loadFolders,
  getSigns,
  loadSequence,
  loadSequences,
  loadSchedules,
  loadPlaylists,
  loadCampaigns,
  loadAllAssignments,
  editForm,
  setForm,
  addNotification,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SequencePage));
