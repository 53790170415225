import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ControlLabel } from 'react-bootstrap';
import DatePicker from 'react-datepicker';

export default class PowerScheduleOnOff extends Component {
  onChangeOn = (onDate, event) => {
    const { day, offDate, onChange } = this.props;
    const isValid = Boolean(onDate && !event);
    const isReset = Boolean(!onDate && event);

    if (isValid || isReset) {
      onChange(onDate, offDate, day, isReset);
    }
  };

  onChangeOff = (offDate, event) => {
    const { day, onDate, onChange } = this.props;
    const isValid = Boolean(offDate && !event);
    const isReset = Boolean(!offDate && event);

    if (isValid || isReset) {
      onChange(onDate, offDate, day, isReset);
    }
  };

  onChangeRawOn = event => {
    const { day, offDate, onChange, onChangeRaw } = this.props;
    const isValid = event.target.value.length === 5; // hh:mm
    const onDate = onChangeRaw(event);

    if (isValid) {
      onChange(onDate, offDate, day);
    }
  };

  onChangeRawOff = event => {
    const { day, onDate, onChange, onChangeRaw } = this.props;
    const isValid = event.target.value.length === 5; // hh:mm
    const offDate = onChangeRaw(event);

    if (isValid) {
      onChange(onDate, offDate, day);
    }
  };

  render() {
    const { day, dayFull, disabled, onDate, offDate, isClearable } = this.props;

    return (
      <div data-test={`datepicker-onoff-${day}-on`} className="power-schedules-times">
        <ControlLabel>{dayFull}</ControlLabel>
        <div className="time-wrapper">
          <DatePicker
            className="form-control"
            selected={onDate}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            placeholderText="--:--"
            timeCaption="On time"
            dateFormat="HH:mm"
            timeFormat="HH:mm"
            onChange={this.onChangeOn}
            onChangeRaw={this.onChangeRawOn}
            disabled={disabled}
            isClearable={isClearable}
          />
        </div>
        <div data-test={`datepicker-onoff-${day}-off`} className="time-wrapper">
          <DatePicker
            className="form-control"
            selected={offDate}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            placeholderText="--:--"
            timeCaption="Off time"
            dateFormat="HH:mm"
            timeFormat="HH:mm"
            onChange={this.onChangeOff}
            onChangeRaw={this.onChangeRawOff}
            disabled={disabled}
            isClearable={isClearable}
          />
        </div>
      </div>
    );
  }
}

PowerScheduleOnOff.propTypes = {
  day: PropTypes.string.isRequired,
  dayFull: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  isClearable: PropTypes.bool.isRequired,
  onDate: PropTypes.object,
  offDate: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onChangeRaw: PropTypes.func.isRequired,
};
