import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import EulaText from './EulaText';
import AupText from './AupText';

export default class EulaModal extends Component {
  state = { maxHeight: 0 };

  componentDidMount() {
    this.updateHeight();
    window.addEventListener('resize', this.updateHeight);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateHeight);
  }

  updateHeight = () => this.setState({ maxHeight: window.innerHeight - 240 });

  render() {
    const { show, onHide, onSuccess, version } = this.props;
    const { maxHeight } = this.state;

    return (
      <Modal className="eula" bsSize="lg" backdrop="static" show={show} onHide={onHide}>
        <Modal.Header>
          <Modal.Title data-test="modal-eula-title">
            End User License Agreement <small>ver: {version}</small>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ maxHeight }} className="eula-scroll">
            {/* Any time the text in here is update you must also increment the eulaVersion number
             In src/config.js */}
            <EulaText />
            <AupText />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button data-test="modal-eula-button-disagree" onClick={onHide} bsStyle="danger">
            Disagree
          </Button>
          <Button data-test="modal-eula-button-agree" onClick={onSuccess} bsStyle="primary">
            Agree
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

EulaModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  version: PropTypes.number.isRequired,
};
