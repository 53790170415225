import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  Grid,
  Row,
  Col,
  FormGroup,
  FormControl,
  ControlLabel,
  HelpBlock,
  Button,
  Alert,
} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { differenceInSeconds, format, isAfter, isBefore, parse } from 'date-fns';
import TagCloud from '../TagCloud/TagCloud';
import Metadata from '../Metadata/Metadata';

export default class AssetForm extends Component {
  onChange = event => {
    const { onChange } = this.props;
    const { name, value } = event.target;

    onChange(name, value);
  };

  onChangeCheckbox = event => {
    const { onChange } = this.props;
    const { name, checked } = event.target;

    onChange(name, checked);
  };

  onChangeTag = tags => {
    const { onChange } = this.props;

    onChange('tags', tags);
  };

  validate = () => {
    const {
      asset: { name },
    } = this.props;

    return Boolean(name.trim());
  };

  submit = event => {
    const { onUpdate } = this.props;

    event.preventDefault();

    if (this.validate()) {
      onUpdate();
    }
  };

  onChangeDates = (startDate, endDate) => {
    const { onChange } = this.props;

    onChange('startDate', format(startDate, 'yyyyMMdd'));
    onChange('endDate', format(endDate, 'yyyyMMdd'));
  };

  handleChangeStartDate = value => {
    const {
      asset: { endDate: end },
    } = this.props;
    const { startDate, endDate } = this.truncateDates(value, parse(end, 'yyyyMMdd', new Date()));

    this.onChangeDates(startDate, endDate);
  };

  handleChangeEndDate = value => {
    const {
      asset: { startDate: start },
    } = this.props;
    const { startDate, endDate } = this.truncateDates(parse(start, 'yyyyMMdd', new Date()), value);

    this.onChangeDates(startDate, endDate);
  };

  truncateDates = (d1, d2) => {
    let { startDate, endDate } = this.sortDates(d1, d2);
    const start = new Date('2010-01-01');
    const end = new Date('2099-12-31');

    if (isBefore(startDate, start)) {
      startDate = start;
    }

    if (isAfter(endDate, end)) {
      endDate = end;
    }

    return { startDate, endDate };
  };

  sortDates = (startDate, endDate) => {
    if (!startDate || !endDate) {
      return { startDate, endDate };
    }

    if (differenceInSeconds(startDate, endDate) > 0) {
      return { startDate: endDate, endDate: startDate };
    }

    return { startDate, endDate };
  };

  errorClass = () => {
    const {
      asset: { name },
    } = this.props;

    return name ? null : 'error';
  };

  render() {
    const {
      auth: {
        user: {
          permissions: { Writer, Approver },
        },
      },
      asset,
      users,
      onApprove,
      onCancel,
    } = this.props;
    const startDate = parse(asset.startDate, 'yyyyMMdd', new Date());
    const endDate = parse(asset.endDate, 'yyyyMMdd', new Date());

    return (
      <form onSubmit={this.submit}>
        {!asset.approved && <Alert bsStyle="warning">This asset requires approval</Alert>}
        <Grid fluid>
          <Row>
            <Col md={6}>
              <FormGroup validationState={this.errorClass()}>
                <ControlLabel>Name</ControlLabel>
                <FormControl
                  data-test="input-name"
                  name="name"
                  value={asset.name}
                  onChange={this.onChange}
                  disabled={!Writer}
                />
                <HelpBlock>This field cannot be empty.</HelpBlock>
              </FormGroup>
              <FormGroup>
                <ControlLabel>Description</ControlLabel>
                <FormControl
                  data-test="input-desc"
                  name="description"
                  componentClass="textarea"
                  rows="4"
                  placeholder="Enter description"
                  value={asset.description}
                  onChange={this.onChange}
                  disabled={!Writer}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <ControlLabel>Tags</ControlLabel>
                <TagCloud tags={asset.tags} onChange={this.onChangeTag} disabled={!Writer} />
              </FormGroup>
              {asset.type === 'video' && (
                <FormGroup>
                  <ControlLabel>Validity Period</ControlLabel>
                  <div>
                    <Checkbox
                      data-test="checkbox-validity"
                      name="validity_enabled"
                      checked={asset.validity_enabled}
                      onChange={this.onChangeCheckbox}
                    >
                      Enable
                    </Checkbox>
                    {asset.validity_enabled && (
                      <div data-test="datepicker-validity">
                        <DatePicker
                          className="form-control"
                          dateFormat="dd/MM/yyyy"
                          selected={startDate}
                          selectsStart
                          startDate={startDate}
                          endDate={endDate}
                          onChange={this.handleChangeStartDate}
                          placeholderText="Start date"
                          todayButton="Today"
                          disabled={!asset.validity_enabled}
                        />
                        <DatePicker
                          className="form-control"
                          dateFormat="dd/MM/yyyy"
                          selected={endDate}
                          selectsEnd
                          startDate={startDate}
                          endDate={endDate}
                          onChange={this.handleChangeEndDate}
                          placeholderText="End date"
                          todayButton="Today"
                          disabled={!asset.validity_enabled}
                        />
                      </div>
                    )}
                  </div>
                </FormGroup>
              )}
            </Col>
          </Row>
        </Grid>
        <Metadata data={asset} users={users} />
        <div className="form-buttons">
          <Button data-test="button-cancel" onClick={onCancel}>
            Cancel
          </Button>
          {Writer && (
            <Button
              data-test="button-update"
              bsStyle="success"
              type="submit"
              disabled={!this.validate()}
            >
              Update Asset
            </Button>
          )}
          {Approver && !asset.approved && (
            <Button data-test="button-approve" bsStyle="primary" onClick={onApprove}>
              Approve
            </Button>
          )}
        </div>
      </form>
    );
  }
}

AssetForm.propTypes = {
  auth: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
  asset: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onApprove: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
