import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';

export default class EditRowDialog extends Component {
  getInitialState = props => {
    const { row } = props;
    const state = {};

    if (row) {
      row.row.columns.forEach(column => {
        state[column.name] = column.value;
      });
    }

    return state;
  };

  state = this.getInitialState(this.props);

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState(this.getInitialState(nextProps));
  }

  handleClickUpdate = () => {
    const { onSuccess } = this.props;

    onSuccess(this.state);
  };

  renderInput = (name, key) => {
    const { [name]: stateName = '' } = this.state;

    return (
      <input
        className="form-control"
        placeholder="Enter a value"
        value={stateName}
        disabled={key}
        onChange={event => this.setState({ [name]: event.target.value })}
      />
    );
  };

  renderInputs = () => {
    const { tier, row } = this.props;
    const fields = [];

    if (!row) {
      return null;
    }

    row.row.columns.forEach((column, index) => {
      const { name } = column;
      const key = name === tier.primary_key;
      const keyIcon = key ? <span className="fa fa-key key" title="Primary Key" /> : null;
      const input = this.renderInput(name, key);

      fields.push(
        <div key={index} className="form-group">
          <label>
            {keyIcon}
            {name}
          </label>
          {input}
        </div>,
      );
    });

    return fields;
  };

  renderCancelButton = () => {
    const { onHide } = this.props;

    return <Button onClick={onHide}>Cancel</Button>;
  };

  renderUpdateRowButton = () => (
    <Button bsStyle="primary" onClick={this.handleClickUpdate}>
      Update Row
    </Button>
  );

  renderFooter = () => (
    <div>
      {this.renderCancelButton()}
      {this.renderUpdateRowButton()}
    </div>
  );

  render() {
    const { show, onHide } = this.props;
    const body = this.renderInputs();
    const footer = this.renderFooter();

    return (
      <Modal dialogClassName="data-object-dialog" backdrop="static" show={show} onHide={onHide}>
        <Modal.Header>
          <Modal.Title>Edit Row</Modal.Title>
        </Modal.Header>
        <Modal.Body>{body}</Modal.Body>
        <Modal.Footer>{footer}</Modal.Footer>
      </Modal>
    );
  }
}

EditRowDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  tier: PropTypes.object.isRequired,
  row: PropTypes.object,
  addNotification: PropTypes.func.isRequired,
};
