import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { addFolder } from '../../actions/folderActions';
import errorMessage from '../../utils/errorMessage';
import { addSupportedAttr, isUnixHiddenPath } from '../../utils/file';
import { getParentPath } from '../../utils/parent';

export default class FullScreenDropZone extends Component {
  state = { active: false };

  componentDidMount() {
    const { loadFolders } = this.props;

    if (!window.SignStix) {
      window.SignStix = {};
    }

    window.SignStix.uploadingFiles = [];
    window.SignStix.uploadingLimit = 4;
    window.SignStix.uploading = 0;
    this.enterCounter = 0;
    loadFolders('?count=100000000');
  }

  isFileDrag = event => {
    const { types } = event.dataTransfer;

    if (Array.isArray(types) && types.includes('Files')) {
      return true;
    } // Chrome, Firefox, Safari

    if (!Array.isArray(types) && types.contains('Files')) {
      return true;
    } // IE, Edge

    return false;
  };

  onDragEnter = event => {
    if (!this.isFileDrag(event)) {
      return;
    }

    this.enterCounter += 1;
    this.setState({ active: true });
  };

  onDragLeave = () => {
    this.enterCounter -= 1;

    if (this.enterCounter <= 0) {
      this.setState({ active: false });
    }
  };

  onDragOver = event => event.preventDefault();

  onDrop = event => {
    const { files, items } = event.dataTransfer;

    event.preventDefault();
    this.enterCounter = 0;
    this.setState({ active: false });

    if (!this.isFileDrag(event)) {
      return;
    }

    // Chrome, Firefox, Safari, Edge
    if (items) {
      return this.getDataTransferItems([...items]).then(res =>
        this.uploadAssets(res.folders, res.files),
      );
    }

    // IE
    this.files = [...files];
    this.uploadStart();
  };

  uploadAssets = (allFolders, allFiles) => {
    const { addNotification } = this.props;
    const files = allFiles.filter(item => !isUnixHiddenPath(item.parent + item.name));
    const folders = allFolders.filter(item => !isUnixHiddenPath(item.parent + item.name));
    const conflicts = this.getConflictingFolders(folders);

    if (conflicts.length) {
      addNotification({
        type: 'danger',
        text: `Folders exist: ${conflicts.join(', ')}`,
      });

      return;
    }

    this.files = addSupportedAttr(files);
    this.addFolders(folders);
  };

  addFolders = items => {
    const {
      location: { pathname, search },
      loadContentItems,
      loadFolders,
      addNotification,
    } = this.props;

    if (!items.length) {
      if (pathname === '/content') {
        loadContentItems(search);
      }

      loadFolders('?count=100000000');
      this.uploadStart();

      return;
    }

    const item = items.shift();
    const parent = getParentPath(this.props) + item.parent;

    return addFolder({ name: item.name, parent }).then(
      () => {
        addNotification({
          type: 'success',
          text: `Folder created: ${parent}${item.name}`,
        });
        this.addFolders(items);
      },
      err => addNotification({ type: 'danger', text: errorMessage(err) }),
    );
  };

  getConflictingFolders = uploadingFolders => {
    const { folders } = this.props;
    const rootFolders = [];

    uploadingFolders.forEach(item => {
      const folder = item.fullPath.split('/')[1];

      if (!rootFolders.includes(folder)) {
        rootFolders.push(folder);
      }
    });

    return folders
      .filter(item => item.parent === getParentPath(this.props) && rootFolders.includes(item.name))
      .map(item => item.name);
  };

  uploadStart = () => {
    const { queueUploads } = this.props;

    if (!this.files.length) {
      return;
    }

    const files = this.files.map(file => {
      file.id = Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);
      file.parent = getParentPath(this.props) + (file.parent || '');

      return file;
    });

    window.SignStix.uploadingFiles.push(...files);
    queueUploads(files);
    const limit = Math.min(window.SignStix.uploadingFiles.length, window.SignStix.uploadingLimit);

    while (window.SignStix.uploading < limit) {
      this.uploadNext();
    }
  };

  uploadNext = () => {
    const { uploadAsset } = this.props;

    if (!window.SignStix.uploadingFiles.length) {
      return;
    }

    const file = window.SignStix.uploadingFiles.shift();

    uploadAsset(file, this.uploadNext);
  };

  getDataTransferItems = items => {
    const folders = [];
    const files = [];
    const traverseFileTree = (item, path = '') =>
      new Promise(resolve => {
        if (item.isFile) {
          item.file(file => {
            file.parent = path;
            files.push(file);
            resolve(file);
          });
        } else if (item.isDirectory) {
          item.parent = item.fullPath.slice(0, -item.name.length).replace('/', '');
          folders.push(item);
          item.createReader().readEntries(entries => {
            const promises = entries.map(entry => traverseFileTree(entry, `${path + item.name}/`));

            resolve(Promise.all(promises));
          });
        }
      });

    return new Promise(resolve => {
      const promises = items.map(item => traverseFileTree(item.webkitGetAsEntry()));

      Promise.all(promises).then(() => resolve({ folders, files }));
    });
  };

  render() {
    const { children } = this.props;
    const { active } = this.state;

    return (
      <div
        className={`drop-zone ${active ? 'active' : ''}`}
        onDragEnter={this.onDragEnter}
        onDragLeave={this.onDragLeave}
        onDragOver={this.onDragOver}
        onDrop={this.onDrop}
      >
        <div className="drop-zone-notification">
          <div>Drop to upload files</div>
        </div>
        {children}
      </div>
    );
  }
}

FullScreenDropZone.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object.isRequired,
  folders: PropTypes.array.isRequired,
  queueUploads: PropTypes.func.isRequired,
  uploadAsset: PropTypes.func.isRequired,
  loadFolders: PropTypes.func.isRequired,
  loadContentItems: PropTypes.func.isRequired,
  addNotification: PropTypes.func.isRequired,
};
