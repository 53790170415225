import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ProgressBar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import fileTypes from '../../../common/fileTypes';
import ToolTipIcon from '../ToolTipIcon/ToolTipIcon';
import Confirm from '../Confirm/Confirm';
import { deleteQueueItem } from '../../actions/assetActions';
import { formatBytes } from '../../utils/formatUnits';

export default class AssetsUploads extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.queuePoll(nextProps);
  }

  queuePoll = props => {
    const { encodingQueue, getEncodingQueue } = props;

    clearInterval(this.queueInterval);

    if (!encodingQueue.length) {
      return;
    }

    this.queueInterval = setInterval(getEncodingQueue, 5000);
  };

  getEncodingQueueItem = id => {
    const { encodingQueue } = this.props;

    return encodingQueue.find(item => item.uploadId === id);
  };

  getCustomError = item => {
    const { validation } = item;
    let info = 'Upload failed';

    if (!validation) {
      return info;
    }

    const { code } = validation;
    /**
     * TODO: #ian
     * - Create a proper object to hold error constants
     */
    const isDuplicate = code === 'FILE_NAME_EXISTS';
    const isQueuedLocal = code === 'FILE_ALREADY_QUEUED_LOCAL';
    const isQueuedEncoding = code === 'FILE_ALREADY_QUEUED_ENCODING';
    const isInvalidFileType = code === 'FILE_TYPE_NOT_SUPPORTED';

    if (isDuplicate) {
      let caption;
      let smallCaption;
      let link;
      let folder = 'assets';
      const {
        data: { assets: duplicateAssets, name: lookupname },
      } = validation;

      if (!Array.isArray(duplicateAssets)) {
        return info;
      }

      // TODO: #ian UI implemented for duplicate fonts and PDF documents,
      // but functionality needs to be added to backend
      if (this.isFont(item)) {
        caption = 'View Font';
        folder = 'fonts';
      } else if (this.isPdf(item)) {
        caption = 'View First Image';
        duplicateAssets[0].assetId = `${duplicateAssets[0].assetId}-0`;
      } else {
        caption = 'View Asset';
      }

      if (duplicateAssets.length > 1) {
        link = `/content?search=${lookupname}&parent=%2F*`;
        smallCaption = `File already exists (${duplicateAssets.length})`;
      } else {
        link = `/${folder}/${duplicateAssets[0]}`;
        smallCaption = 'File already exists';
      }

      info = (
        <div>
          <Link className="text-danger" to={link}>
            {caption}
          </Link>
          <div className="upload-info">{smallCaption}</div>
        </div>
      );
    } else if (isQueuedEncoding) {
      info = (
        <div>
          <span className="text-danger">Upload failed</span>
          <div className="upload-info">File in encoding queue</div>
        </div>
      );
    } else if (isQueuedLocal) {
      info = (
        <div>
          <span className="text-danger">Upload failed</span>
          <div className="upload-info">File already in queue</div>
        </div>
      );
    } else if (isInvalidFileType) {
      info = (
        <div>
          <span className="text-danger">Upload failed</span>
          <div className="upload-info">Unsupported file type</div>
        </div>
      );
    }

    return info;
  };

  getStatusInfo = item => {
    const { id, progress, error, supported } = item;
    const queueItem = this.getEncodingQueueItem(id);
    let status = 'info';
    let info;

    if (!supported) {
      status = 'danger';
      info = 'Not supported file format';
    } else if (error) {
      status = 'danger';
      info = this.getCustomError(item);
    } else if (!progress) {
      info = 'Waiting for upload';
    } else if (progress < 100) {
      info = `${progress}%`;
    } else {
      status = 'success';
      info = 'Upload completed';
      const isVideo = this.isVideo(item);
      const isPdf = this.isPdf(item);

      if (isVideo || isPdf) {
        status = 'success';
        info = isVideo ? 'Upload and encode complete' : 'Upload and conversion complete';

        if (queueItem) {
          switch (queueItem.status) {
            case 'waiting':
              status = 'info';
              info = isVideo ? 'Waiting for encoding' : 'Waiting to convert PDF';
              break;
            case 'processing':
              status = 'info';
              info = isVideo ? 'Encoding file' : 'Extracting images';
              break;
            case 'error':
              status = 'danger';
              info = isVideo ? 'Encode failed' : 'Image conversion failed';
              break;
            default:
          }
        }
      }
    }

    return { status, info };
  };

  isComplete = item => this.getStatusInfo(item).status === 'success' && Boolean(item.asset);

  isFinished = item => !item.supported || item.error || this.isComplete(item);

  onClickClose = item => {
    const { type, xhr, validation } = item;
    const { status } = this.getStatusInfo(item);

    if (validation?.code) {
      return this.removeFile(item);
    }

    if (type.includes('video') && status !== 'success') {
      const confirm = this.refDelete;

      confirm.show(
        'Remove Video from Encode Queue',
        <div>
          <p>Do you want to remove this video from the encode queue?</p>
          <b>{item.name}</b>
        </div>,
      );
      confirm.onSuccess(() => {
        if (item.asset) {
          deleteQueueItem(item.asset.id);
        }

        this.removeFile(item);

        if (xhr) {
          xhr.abort();
        }
      });
    } else {
      this.removeFile(item);

      if (xhr) {
        xhr.abort();
      }
    }
  };

  removeFile = item => {
    const { uploadRemoveItem } = this.props;

    window.SignStix.uploadingFiles = window.SignStix.uploadingFiles.filter(
      file => file.id !== item.id,
    );
    uploadRemoveItem(item.id);
  };

  onClickCloseAllFinished = () => {
    const { uploading } = this.props;

    uploading.forEach(item => {
      if (this.isFinished(item)) {
        this.removeFile(item);
      }
    });
  };

  getProgressTotal = () => {
    const { uploading } = this.props;
    let loaded = 0;
    let size = 0;
    let errors = 0;
    let finished = 0;

    uploading.forEach(item => {
      size += item.size;

      if (item.error) {
        errors += 1;
      } else {
        loaded += item.loaded || 0;
      }

      if (this.isFinished(item)) {
        finished += 1;
      }
    });

    return { loaded, size, progress: Math.min(100, (loaded / size) * 100), errors, finished };
  };

  getProgressInfo = item => {
    const { status, info } = this.getStatusInfo(item);

    if (!item.progress) {
      return info;
    }

    if (this.isComplete(item)) {
      if (this.isFont(item)) {
        return <Link to={`/fonts/${item.asset.id}`}>View Font</Link>;
      }

      if (this.isPdf(item)) {
        return <Link to={`/assets/${item.asset.id}-0`}>View First Image</Link>;
      }

      if (item.asset.id) {
        return <Link to={`/assets/${item.asset.id}`}>View Asset</Link>;
      }
    }

    if (this.isFinished(item) && typeof info === 'object') {
      return info;
    }

    return <ProgressBar bsStyle={status} now={item.progress} label={info} />;
  };

  isPdf = item => item.type.includes('pdf');

  isFont = item => fileTypes.font.some(ext => item.name.toLowerCase().endsWith(ext));

  isVideo = item => fileTypes.video.some(ext => item.name.toLowerCase().endsWith(ext));

  getCloseButton = item => {
    const { progress } = item;
    const text = `${progress < 100 ? 'Abort' : 'Close'} Upload`;

    return (
      <ToolTipIcon toolText={text} onClick={() => this.onClickClose(item)}>
        remove_circle_outline
      </ToolTipIcon>
    );
  };

  getUploads = () => {
    const { uploading } = this.props;
    const rows = uploading.map((item, index) => {
      const { type, name, parent, size, loaded, supported } = item;
      const { status } = this.getStatusInfo(item);
      const error = status === 'danger' ? 'error' : '';
      const complete = this.isComplete(item) ? 'complete' : '';

      return (
        <tr key={index} className={error || complete}>
          <td className="upload-file">
            {name}
            {supported && !this.isFont(item) && <div className="upload-info">Folder: {parent}</div>}
          </td>
          <td className="upload-size">
            {formatBytes(Math.min(loaded, size))} / {formatBytes(size)}
            <div className="upload-info">{type}</div>
          </td>
          <td className="upload-progress">{this.getProgressInfo(item)}</td>
          <td className="upload-close">{this.getCloseButton(item)}</td>
        </tr>
      );
    });

    return (
      <table className="table">
        <tbody>{rows}</tbody>
      </table>
    );
  };

  render() {
    const { title = 'Uploading Assets', uploading } = this.props;
    const unsupported = uploading.filter(item => !item.supported).length;

    if (!uploading || !uploading.length) {
      return null;
    }

    const { progress, loaded, size, errors, finished } = this.getProgressTotal();
    const done = uploading.length === finished;
    const uploads = this.getUploads();
    let status = <span className="loading">{title}</span>;
    let color = 'info';

    if (progress === 100) {
      color = 'success';
    }

    if (errors || unsupported) {
      color = 'danger';
    }

    if (done) {
      if (errors || unsupported) {
        status = <span className="text-danger">FINISHED WITH ERRORS</span>;
      } else {
        status = <span className="text-success">FINISHED</span>;
      }
    }

    return (
      <div className="assets-uploads panel panel-default">
        <div className="panel-heading">
          {status}
          <span className="info">{progress.toFixed('2')}%</span>
          <span className="info">
            {uploading.filter(item => !item.error && item.loaded >= item.size).length} /{' '}
            {uploading.length} Files
          </span>
          <span className="info">
            {formatBytes(Math.min(loaded, size))} / {formatBytes(size)}
          </span>
          {Boolean(errors) && <span className="info text-danger">{errors} failed</span>}
          {Boolean(unsupported) && (
            <span className="info text-danger">{unsupported} not supported</span>
          )}
          <ToolTipIcon
            className="pull-right"
            toolText="Clear Finished Uploads"
            onClick={this.onClickCloseAllFinished}
          >
            remove_circle_outline
          </ToolTipIcon>
          <div className={`progress-total ${color}`} style={{ width: `${progress}%` }} />
        </div>
        <div className="panel-body">{uploads}</div>
        <Confirm
          ref={ref => {
            this.refDelete = ref;
          }}
        />
      </div>
    );
  }
}

AssetsUploads.propTypes = {
  title: PropTypes.string,
  uploading: PropTypes.array,
  encodingQueue: PropTypes.array,
  uploadRemoveItem: PropTypes.func.isRequired,
  getEncodingQueue: PropTypes.func.isRequired,
};
