import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import async from 'async';
import pluralize from 'pluralize';
import { SOLR_DELAY } from '../../../../constants';
import { getUsers } from '../../../actions/userActions';
import { getSigns } from '../../../actions/signActions';
import { loadSequences } from '../../../actions/sequenceActions';
import { loadSchedules } from '../../../actions/scheduleActions';
import { loadPlaylists } from '../../../actions/playlistActions';
import { loadCampaigns } from '../../../actions/campaignActions';
import { loadAllAssignments } from '../../../actions/assignmentActions';
import {
  getDataObject,
  getInUse,
  uploadTier,
  addTier,
  deleteTier,
  duplicateTier,
  select,
  unselectAll,
} from '../../../actions/dataObjectActions';
import { addNotification } from '../../../actions/notificationActions';
import Spinner from '../../../components/Spinner/Spinner';
import QuickAction from '../../../components/QuickAction/QuickAction';
import Confirm from '../../../components/Confirm/Confirm';
import Prompt from '../../../components/Prompt/Prompt';
import DataObjectTable from '../../../components/DataObjects/DataObjectTable';
import Used from '../../../components/Used/Used';
import AddTierDialog from '../../../components/DataObjects/AddTierDialog';
import Header from '../../../components/Header/Header';
import ActionsMenu from '../../../components/ActionsMenu/ActionsMenu';
import ErrorPage from '../ErrorPage/ErrorPage';
import errorMessage from '../../../utils/errorMessage';

export class DataObjectPage extends Component {
  state = {
    showAddDialog: false,
  };

  componentDidMount() {
    this.loadData();
    document.addEventListener('visibilitychange', this.loadData);
  }

  componentWillUnmount() {
    const { unselectAll } = this.props;

    unselectAll();
    document.removeEventListener('visibilitychange', this.loadData);
  }

  loadData = () => {
    if (document.visibilityState === 'hidden') {
      return;
    }

    const {
      match: {
        params: { id },
      },
      getSigns,
      loadSequences,
      loadSchedules,
      loadPlaylists,
      loadCampaigns,
      loadAllAssignments,
      getUsers,
      getDataObject,
      getInUse,
    } = this.props;

    getSigns('?count=100000000');
    loadSequences('?count=100000000');
    loadSchedules('?count=100000000');
    loadPlaylists('?count=100000000');
    loadCampaigns('?count=100000000');
    loadAllAssignments();
    getUsers();
    getDataObject(id);
    getInUse();
  };

  getSelectedItems = () => {
    const {
      dataObjects: {
        dataObject: { tiers },
        selected,
      },
    } = this.props;

    return tiers
      ? Object.keys(selected)
          .map(index => tiers[index])
          .filter(Boolean)
      : [];
  };

  getSelectedExceptBaseAndInUse = () => {
    const {
      match: {
        params: { id },
      },
      dataObjects: { inUse },
    } = this.props;

    return this.getSelectedItems()
      .filter(item => !+item.isbase)
      .filter(item => !inUse.some(inuse => +inuse.doId === +id && +inuse.tierId === +item.id));
  };

  getUsedInSigns = () => {
    const {
      match: {
        params: { id },
      },
      dataObjects: { inUse },
    } = this.props;

    return inUse.filter(item => item.doId === +id).map(item => `${item.signId}-${item.published}`);
  };

  upload = tier => {
    const {
      match: {
        params: { id },
      },
      getDataObject,
      addNotification,
    } = this.props;

    return uploadTier(id, tier.name, tier.file).then(
      res => {
        if (res.data_object) {
          addNotification({ type: 'success', text: 'Tier uploaded.' });
          setTimeout(() => getDataObject(id), SOLR_DELAY);
        } else {
          addNotification({ type: 'danger', text: 'Request failed.' });
        }
      },
      err => addNotification({ type: 'danger', text: errorMessage(err) }),
    );
  };

  add = tier => {
    const {
      match: {
        params: { id },
      },
      getDataObject,
      addNotification,
    } = this.props;

    return addTier(id, tier.name).then(
      () => {
        addNotification({ type: 'success', text: 'Tier added.' });
        setTimeout(() => getDataObject(id), SOLR_DELAY);
      },
      err => addNotification({ type: 'danger', text: errorMessage(err) }),
    );
  };

  delete = () => {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    const items = this.getSelectedExceptBaseAndInUse();

    async.filter(
      items,
      (item, callback) =>
        deleteTier(id, item.id).then(
          res => callback(null, res),
          () => callback(null, false),
        ),
      this.deleteCallback,
    );
  };

  deleteCallback = (err, results) => {
    const {
      match: {
        params: { id },
      },
      getDataObject,
      addNotification,
      unselectAll,
    } = this.props;
    const items = this.getSelectedExceptBaseAndInUse();
    let failedItems;
    let errorMsg = '';

    // check for errors
    if (items.length === results.length) {
      addNotification({
        type: 'success',
        text: `${pluralize('Tier', items.length, true)} deleted.`,
      });
    } else {
      // collect the failed request items
      failedItems = items.filter(item => !results.includes(item));
      failedItems.forEach(item => {
        errorMsg += (errorMsg ? ', ' : 'Failed to delete: ') + item.name;
      });
      addNotification({ type: 'danger', text: errorMsg });
    }

    // if there was at least one successful request, update
    if (results.length) {
      unselectAll();
      setTimeout(() => getDataObject(id), SOLR_DELAY);
    }
  };

  validateDuplicate = name => {
    const { dataObjects, addNotification } = this.props;
    const exist = dataObjects.dataObjects.some(item => item.data_object.name === name);

    if (exist) {
      addNotification({ type: 'danger', text: 'That Tier name already exists.' });
      setTimeout(this.handleClickDuplicate); // reopen the dialog
    } else {
      this.duplicate(name);
    }
  };

  duplicate = name => {
    const {
      match: {
        params: { id },
      },
      getDataObject,
      addNotification,
    } = this.props;
    const tier = this.getSelectedItems()[0];

    return duplicateTier(id, tier.id, name).then(
      res => {
        if (res.id > -1) {
          addNotification({ type: 'success', text: 'Tier duplicated.' });
          setTimeout(() => getDataObject(id), SOLR_DELAY);
        } else {
          addNotification({ type: 'danger', text: 'Request failed.' });
        }
      },
      err => addNotification({ type: 'danger', text: errorMessage(err) }),
    );
  };

  successAddDialog = tier => {
    this.hideAddDialog();

    if (tier.file) {
      this.upload(tier);
    } else {
      this.add(tier);
    }
  };

  hideAddDialog = () => this.setState({ showAddDialog: false });

  handleClickAdd = () => this.setState({ showAddDialog: true });

  handleClickDelete = () => {
    const confirm = this.refDelete;
    const items = this.getSelectedExceptBaseAndInUse();
    const names = items.map((item, index) => <li key={index}>{item.name}</li>);
    const title = `Delete ${pluralize('Tier', items.length, true)}`;
    const body = (
      <div>
        <p>
          Are you sure you want to delete {pluralize('this', items.length)}{' '}
          {pluralize('Tier', items.length)}?
        </p>
        <ol>{names}</ol>
      </div>
    );

    confirm.show(title, body);
    confirm.onSuccess(this.delete);

    return { title, body };
  };

  handleClickDuplicate = () => {
    const prompt = this.refDuplicate;
    const tier = this.getSelectedItems()[0];
    const title = 'Duplicate Tier';
    const body = (
      <div>
        Duplicate <b>{tier.name}</b>. Enter a name for the new Tier.
      </div>
    );

    prompt.show(title, body);
    prompt.onSuccess(name => this.duplicate(name));

    return { title, body };
  };

  handleClickTable = tiers => {
    const { select } = this.props;

    select(tiers);
  };

  handleDoubleClickTable = (event, tier) => {
    const {
      history,
      match: {
        params: { id },
      },
    } = this.props;

    history.push(`/data-objects/${id}/${tier.id}`);
  };

  renderHeaderActions = () => {
    const {
      auth: {
        user: {
          permissions: { Writer },
        },
      },
      match: {
        params: { id },
      },
    } = this.props;

    if (!Writer) {
      return [];
    }

    const selectedUrl = this.getSelectedItems()[0]
      ? `/data-objects/${id}/${this.getSelectedItems()[0].id}`
      : '';
    const removables = this.getSelectedExceptBaseAndInUse().length;
    const add = (
      <QuickAction key="1" toolText="Add Tier" onClick={this.handleClickAdd} alwaysShow>
        add_circle
      </QuickAction>
    );
    const remove = (
      <QuickAction
        key="2"
        toolText={pluralize('Delete Tier', removables)}
        onClick={this.handleClickDelete}
        singular
        multiple
      >
        delete
      </QuickAction>
    );
    const duplicate = (
      <QuickAction key="3" toolText="Duplicate Tier" onClick={this.handleClickDuplicate} singular>
        content_copy
      </QuickAction>
    );
    const edit = (
      <QuickAction key="4" toolText="Edit Tier" href={selectedUrl} singular>
        mode_edit
      </QuickAction>
    );

    return removables ? [add, remove, duplicate, edit] : [add, duplicate, edit];
  };

  renderTable = () => {
    const {
      dataObjects: { dataObject, inUse, selected, loading },
    } = this.props;
    const data = dataObject.tiers || [];

    return (
      <Spinner loading={loading}>
        <DataObjectTable
          id={dataObject.id}
          data={data}
          inUse={inUse}
          selected={selected}
          onClick={this.handleClickTable}
          onDoubleClick={this.handleDoubleClickTable}
        />
      </Spinner>
    );
  };

  render() {
    const {
      auth,
      users: { users },
      dataObjects: { dataObject, error },
      addNotification,
      signs: {
        signs: { signs },
      },
      sequences: {
        sequences: { sequences },
      },
      schedules: {
        schedules: { schedules },
      },
      playlists: {
        playlists: { playlists },
      },
      campaigns: {
        campaigns: { campaigns },
      },
      assignments: {
        allAssignments: { assignments },
      },
    } = this.props;
    const { showAddDialog } = this.state;
    const tiers = dataObject.tiers || [];
    const table = this.renderTable();

    if (error) {
      return <ErrorPage error={error} />;
    }

    return (
      <div className="page-structure">
        <Helmet title="Data Object" />
        <Header overwrite={{ id: dataObject }} />
        <ActionsMenu
          actions={this.renderHeaderActions()}
          selectedLength={this.getSelectedItems().length}
        />
        <div className="page-panels">
          <div className="page-content page-data-object">
            {table}
            <Used
              type="asset"
              item={{ usedInSigns: this.getUsedInSigns() }}
              signs={signs}
              sequences={sequences}
              schedules={schedules}
              playlists={playlists}
              campaigns={campaigns}
              assignments={assignments}
              auth={auth}
              users={users}
            />
          </div>
        </div>
        <AddTierDialog
          show={showAddDialog}
          onHide={this.hideAddDialog}
          onSuccess={this.successAddDialog}
          tiers={tiers}
          addNotification={addNotification}
        />
        <Confirm
          ref={ref => {
            this.refDelete = ref;
          }}
        />
        <Prompt
          ref={ref => {
            this.refDuplicate = ref;
          }}
        />
      </div>
    );
  }
}

DataObjectPage.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  users: PropTypes.object.isRequired,
  signs: PropTypes.object.isRequired,
  sequences: PropTypes.object.isRequired,
  schedules: PropTypes.object.isRequired,
  playlists: PropTypes.object.isRequired,
  campaigns: PropTypes.object.isRequired,
  assignments: PropTypes.object.isRequired,
  dataObjects: PropTypes.object.isRequired,
  getSigns: PropTypes.func.isRequired,
  loadSequences: PropTypes.func.isRequired,
  loadSchedules: PropTypes.func.isRequired,
  loadPlaylists: PropTypes.func.isRequired,
  loadCampaigns: PropTypes.func.isRequired,
  loadAllAssignments: PropTypes.func.isRequired,
  getUsers: PropTypes.func.isRequired,
  getDataObject: PropTypes.func.isRequired,
  getInUse: PropTypes.func.isRequired,
  select: PropTypes.func.isRequired,
  unselectAll: PropTypes.func.isRequired,
  addNotification: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  users: state.users,
  signs: state.signs,
  sequences: state.sequences,
  schedules: state.schedules,
  playlists: state.playlists,
  campaigns: state.campaigns,
  assignments: state.assignments,
  dataObjects: state.dataObjects,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getUsers,
      getSigns,
      loadSequences,
      loadSchedules,
      loadPlaylists,
      loadCampaigns,
      loadAllAssignments,
      getDataObject,
      getInUse,
      select,
      unselectAll,
      addNotification,
    },
    dispatch,
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DataObjectPage));
