import * as types from '../actions/actionTypes';

export const initialState = {
  loadingSchedule: null,
  loadingSchedules: null,
  schedule: {},
  schedules: {},
  error: null,
};

export default function schedules(state = initialState, action = {}) {
  switch (action.type) {
    case types.SCHEDULE_REQUEST:
      return {
        ...state,
        loadingSchedule: true,
        error: false,
      };
    case types.SCHEDULE_REQUEST_SUCCESS:
      return {
        ...state,
        loadingSchedule: false,
        schedule: action.result,
      };
    case types.SCHEDULE_REQUEST_FAILED:
      return {
        ...state,
        loadingSchedule: false,
        error: action.error,
      };
    case types.SCHEDULES_REQUEST:
      return {
        ...state,
        loadingSchedules: true,
        error: false,
      };
    case types.SCHEDULES_REQUEST_SUCCESS:
      return {
        ...state,
        loadingSchedules: false,
        schedules: action.result,
      };
    case types.SCHEDULES_REQUEST_FAILED:
      return {
        ...state,
        loadingSchedules: false,
        error: action.error,
      };
    default:
      return state;
  }
}
