const getAssignmentsForSchedule = (schedule, playlists, assignments) => {
  if (!schedule || !playlists || !assignments) {
    return [];
  }

  return assignments
    .filter(item => item.content_type === 'schedule' && item.content_id === +schedule.id)
    .map(item => {
      if (item.content_default_stream_type === 'playlist') {
        const playlist = playlists.find(pl => pl.id === item.content_default_stream_id);

        if (playlist) {
          item.playlistEntries = playlist.playlistEntries;
        }
      }

      return item;
    })
    .sort((a, b) => a.name.localeCompare(b.name));
};

export default getAssignmentsForSchedule;
