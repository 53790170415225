import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { differenceInSeconds, format, sub } from 'date-fns';
import qs from '../../utils/qs';
import { isQueryParam, addUniqueQueryParam, removeQueryParam } from '../../utils/updateQueryString';
import { addUniqueFilter } from '../../actions/filterActions';

export class FilterDateRange extends Component {
  state = {
    selectedValue: '',
    showDatePicker: false,
    startDate: '',
    endDate: '',
  };

  componentDidMount() {
    this.setFilterFromQuery(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setFilterFromQuery(nextProps);
  }

  setFilterFromQuery = props => {
    const { location, name, title, addUniqueFilter } = props;
    const data = this.getData();
    const filter = qs(props)[name];

    if (!data || !data.length) {
      return;
    }

    // filter is not in the query
    if (!filter) {
      this.setState({
        selectedValue: '',
        showDatePicker: false,
      });

      return;
    }

    // filter is in the query, and it is not customized (using [NOW-7DAY TO NOW] format)
    if (filter.indexOf('NOW') > -1) {
      for (let index = 0; index < data.length; index += 2) {
        const value = data[index];
        const text = data[index + 1];
        const checked = isQueryParam(location, name, value);

        if (checked) {
          this.setState({
            selectedValue: value,
            showDatePicker: false,
          });
          addUniqueFilter({ group: name, name, query: value, text, title });
        }
      }

      return;
    }

    // filter is in the query, and it is customized (using '[2017-04-01T23:00:00.000Z TO 2017-05-30T23:00:00.000Z]' format)
    const [startDate, endDate] = filter
      .slice(1, -1)
      .split(' TO ')
      .map(item => new Date(item));
    const text = `from ${format(startDate, 'dd/MM/yyyy')} to ${format(endDate, 'dd/MM/yyyy')}`;

    this.setState({
      selectedValue: 'customized',
      showDatePicker: true,
      startDate,
      endDate,
    });
    addUniqueFilter({ group: name, name, query: filter, text, title });
  };

  getData = () => [
    '',
    '',
    '[NOW-1HOUR TO NOW]',
    'Last Hour',
    '[NOW-1DAY TO NOW]',
    'Last Day',
    '[NOW-7DAY TO NOW]',
    'Last Week',
    '[NOW-1MONTH TO NOW]',
    'Last Month',
    '[NOW-1YEAR TO NOW]',
    'Last Year',
    'customized',
    'Customized',
  ];

  handleChange = event => {
    const { history, location, name } = this.props;
    const { value } = event.target;

    if (value === 'customized') {
      this.handleChangeDatePicker();
    } else if (!value) {
      removeQueryParam(history, location, name, qs(this.props)[name]);
    } else {
      addUniqueQueryParam(history, location, name, value);
    }
  };

  handleChangeDatePicker = () => {
    const { history, location, name } = this.props;
    const { startDate, endDate } = this.state;
    const start = startDate || sub(new Date(), { months: 1 });
    const end = endDate || new Date();
    const value = `[${start.toISOString()} TO ${end.toISOString()}]`; // '[2017-04-01T23:00:00.000Z TO 2017-05-30T23:00:00.000Z]'

    addUniqueQueryParam(history, location, name, value);
  };

  handleChangeDatePickerStart = value => {
    const { endDate: end } = this.state;
    const { startDate, endDate } = this.sortDates(value, end);

    this.setState({ startDate, endDate }, this.handleChangeDatePicker);
  };

  handleChangeDatePickerEnd = value => {
    const { startDate: start } = this.state;
    const { startDate, endDate } = this.sortDates(start, value);

    this.setState({ startDate, endDate }, this.handleChangeDatePicker);
  };

  sortDates = (startDate, endDate) => {
    if (!startDate || !endDate) {
      return { startDate, endDate };
    }

    if (differenceInSeconds(startDate, endDate) > 0) {
      return { startDate: endDate, endDate: startDate };
    }

    return { startDate, endDate };
  };

  renderOptions = () => {
    const data = this.getData();
    const list = [];

    for (let index = 0; index < data.length; index += 2) {
      const value = data[index];
      const text = data[index + 1];

      list.push(
        <option key={index} value={value}>
          {text}
        </option>,
      );
    }

    return list;
  };

  render() {
    const { name, title } = this.props;
    const { selectedValue, showDatePicker, startDate, endDate } = this.state;
    const options = this.renderOptions();
    const customized = { display: showDatePicker ? 'block' : 'none' };

    return (
      <div>
        <div className="filter-section-title">{title}</div>
        <select
          data-test={`select-${name}`}
          className="filter-daterange form-control"
          onChange={event => this.handleChange(event)}
          value={selectedValue}
        >
          {options}
        </select>
        <div data-test={`datepicker-${name}`} style={customized}>
          <DatePicker
            className="form-control"
            dateFormat="dd/MM/yyyy"
            selected={startDate}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            onChange={this.handleChangeDatePickerStart}
            placeholderText="Start date"
          />
          <DatePicker
            className="form-control"
            dateFormat="dd/MM/yyyy"
            selected={endDate}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            onChange={this.handleChangeDatePickerEnd}
            placeholderText="End date"
          />
        </div>
      </div>
    );
  }
}

FilterDateRange.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  addUniqueFilter: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  addUniqueFilter,
};

export default withRouter(connect(null, mapDispatchToProps)(FilterDateRange));
