import React from 'react';

export default () => (
  <div>
    <h3>Appendix A</h3>
    <h1>Services Acceptable Use Policy (AUP)</h1>

    <h2>1. INTRODUCTION</h2>
    <ol>
      <li>
        <strong>1.1</strong> Defined terms used in this AUP shall have the same meanings as given to
        them in the Signstix licence agreement and the rules of interpretation set out therein will
        also apply in this AUP.
      </li>
      <li>
        <strong>1.2</strong> This document sets out the standards to be achieved and adhered to by
        all Customers and Users when using any Signstix Services and details certain restrictions on
        and unauthorised uses of, those Services.
      </li>
      <li>
        <strong>1.3</strong> This AUP forms part of the Customer licence agreement for use of the
        Services and accordingly, will be binding.Where there is a contractual arrangement in place
        between the Customer and a Reseller, the Customer shall be required to agree to this AUP
        when it accesses the Services via the Signstix portal and in such circumstances, this AUP
        will be binding on the Customer and may be enforce directly by Signstix.
      </li>
      <li>
        <strong>1.4</strong> The Customer shall ensure that all Users who agree to and accept the
        terms of this AUP adhere to it and further, the Customer undertakes to Signstix that those
        persons have authority to bind the Customer.
      </li>
    </ol>
    <h2>2. PROHIBITED USES OF THE SERVICES</h2>
    <ol>
      <li>
        <strong>2.1</strong> Services must strictly only be used for lawful purposes.Customers are
        entirely responsible for ensuring that they use the Services at all times in accordance with
        all applicable laws and not for any unlawful activity whatsoever.
      </li>
      <li>
        <strong>2.2</strong> Without prejudice to the generality of the Customer’s other
        obligations, the Customer must not under any circumstances use the Services (or allow any
        other person to use make use of the Services):
      </li>
      <li>
        <strong>2.2.1</strong> in any way that breaches any applicable local, national or
        international law or regulation;
      </li>
      <li>
        <strong>2.2.2</strong> in any way that is unlawful or fraudulent or has any unlawful or
        fraudulent purpose or effect;{' '}
      </li>
      <li>
        <strong>2.2.3</strong> to engage in or encourage any criminal activity;
      </li>
      <li>
        <strong>2.2.4</strong> to circumvent the security of third party machines or systems or to
        attempt unauthorised or illegal access to computers, networks or accounts not belonging to
        the Customer or to interfere with the services of another user or network or for any other
        act relating to the circumvention of security measures;
      </li>
      <li>
        <strong>2.2.5</strong> for the purpose of harming or attempting to harm minors in any way or
        in a way which does or could harm minors even if that is not the intended purpose;
      </li>
      <li>
        <strong>2.2.6</strong> to send, knowingly receive, upload, download, use or re-use any data,
        information, material or Customer Data which does not comply with the requirements and
        standards for Customer Data in this AUP;{' '}
      </li>
      <li>
        <strong>2.2.7</strong> to transmit, send or procure or permit the transmission or sending of
        any unauthorised advertising or promotional material (including in a way which is in breach
        of the Privacy and Electronic Communications Regulations 2003 (or any replacement
        legislation or equivalent regulations in any other jurisdiction)), unsolicited bulk
        messaging or any other form of similar solicitation (spam);
      </li>
      <li>
        <strong>2.2.8</strong> to knowingly transmit, send or upload any material that contains
        viruses, trojan horses, worms, time-bombs, keystroke loggers, spyware, adware or any other
        harmful programs or similar computer code designed to adversely affect the operation of any
        computer software, hardware or system;
      </li>
      <li>
        <strong>2.2.9</strong> in any way which may harm Signstix’s own network or that of its other
        customers;
      </li>
      <li>
        <strong>2.2.10</strong> in any way to attempt to use or gain unauthorised access to data,
        accounts, hosts, systems or networks of Signstix or any of its customers, suppliers or any
        other party;
      </li>
      <li>
        <strong>2.2.11</strong> to attempt to probe, scan or test the vulnerability of a system or
        network of Signstix, any Signstix product or service or any system, network, product or
        service of any of its customers, suppliers or those of any other party;
      </li>
      <li>
        <strong>2.2.12</strong> to forge any TCP/IP packet header or any part of the header
        information in an e-mail or otherwise;
      </li>
      <li>
        <strong>2.2.13</strong> to interfere, or in any way attempt to interfere with services to
        any user, host or network including mail-bombing, denial of service attacks, flooding or
        deliberately attempting to overload any system;
      </li>
      <li>
        <strong>2.2.14</strong> in a way which does not comply with reasonable instructions given by
        Signstix, its Resellers or contractors.
      </li>
      <li>
        <strong>2.3</strong> Customers shall not engage in any activity that interferes with or
        disrupts the Services, services to any other customers of Signstix or the servers and
        networks which are connected to any of them.
      </li>
      <li>
        <strong>2.4</strong> Customers shall not exceed any restrictions on capacity which apply to
        use of the Services without prior express, written permission.
      </li>
      <li>
        <strong>2.5</strong> Customers should note that a misuse of the Services including (but not
        limited to) in connection with certain activity as prohibited in accordance with this AUP or
        in connection with the transmission, receipt or storage of certain types of files, may also
        be governed by criminal legislation and may, in some cases, result in criminal
        prosecution.Customers shall be entirely responsible for compliance with the law and ensuring
        that they do not use the Services in any way for any illegal activity.
      </li>
      <li>
        <strong>2.6</strong> The Customer is responsible for the conduct of all Users of its account
        whether those Users are authorised by the Customer or not.
      </li>
    </ol>
    <h2>3. CUSTOMER DATA STANDARDS</h2>
    <ol>
      <li>
        <strong>3.1</strong> The Customer acknowledges and agrees that all information, data, text,
        software, music, sound, photographs, recordings, graphics, images, video, messages and all
        other materials whether posted, transmitted, created, uploaded or sent (in each case whether
        publicly or privately) or in any other way used in connection with the Services (Customer
        Data), are its sole responsibility.This means that the Customer is entirely responsible for
        all Customer Data that it uses in any way with the Services and the consequences of such use
        and Signstix shall not be liable for the same in any way.{' '}
      </li>
      <li>
        <strong>3.2</strong> All Customer Data must:
      </li>
      <li>
        <strong>3.2.1</strong> be accurate (in the case of fact) or be genuinely held (in the case
        of opinion);
      </li>
      <li>
        <strong>3.2.2</strong> comply with all applicable law (including in any country in which
        such Customer Data is created, from which it is posted or is otherwise derived);
      </li>
      <li>
        <strong>3.2.3</strong> not under any circumstances be or contain any element which;
      </li>
      <li>
        <strong>3.2.3.1</strong> is defamatory of any person;
      </li>
      <li>
        <strong>3.2.3.2</strong> is obscene, indecent, offensive, hateful or inflammatory;
      </li>
      <li>
        <strong>3.2.3.3</strong> includes or promotes sexually explicit material, child pornography,
        sexual or other exploitation, violence or discrimination based on race, sex, religion,
        nationality, disability, sexual orientation or age;
      </li>
      <li>
        <strong>3.2.3.4</strong> is intended to or is likely to deceive any person;
      </li>
      <li>
        <strong>3.2.3.5</strong> promotes any illegal activity or promotes or incites terrorist
        activity;
      </li>
      <li>
        <strong>3.2.3.6</strong> is threatening, abusive or invades another&apos;s privacy or causes
        annoyance, inconvenience or needless anxiety or is likely to harass, upset, embarrass or
        alarm any other person;
      </li>
      <li>
        <strong>3.2.3.7</strong> advocates, promotes or assists any unlawful act such as copyright
        infringement or computer misuse;
      </li>
      <li>
        <strong>3.2.4</strong> not be copied or otherwise infringe the Intellectual Property Rights
        or other proprietary rights of any other person (by way of example, sharing without
        permission of the copyright owner, protected material such as a music or video file);
      </li>
      <li>
        <strong>3.2.5</strong> comply in all respects with all applicable data protection
        legislation;
      </li>
      <li>
        <strong>3.2.6</strong> not be used in breach of any legal duty owed to a third party
        including a contractual duty or a duty of confidence.
      </li>
      <li>
        <strong>3.3</strong> The Customer is responsible for all Customer Data used in connection
        with the Services. It is the sole responsibility of the Customer to ensure that Customer
        Data is protected by a robust backup strategy and system and Signstix accepts no liability
        for any Customer Data loss suffered by the Customer as a result of a failure to do so or for
        any other reason.
      </li>
      <li>
        <strong>3.4</strong> The standards and requirements as set out in this AUP apply to each
        part of Customer Data as well as to its whole.
      </li>
    </ol>
    <h2>4. INTELLECTUAL PROPERTY RIGHTS</h2>
    <ol>
      <li>
        <strong>4.1</strong> Customers must not under any circumstances use the Services or any
        Customer Data in connection with the Services, in any which infringes or is likely to
        infringe the Intellectual Property Rights or other proprietary rights of a third party.
        Infringing another person’s Intellectual Property Rights can lead to claims or allegations
        of claims by those rights owners and in some circumstances, infringement of certain
        Intellectual Property Rights can also constitute an offence.
      </li>
      <li>
        <strong>4.2</strong> The Customer shall be responsible for obtaining all permissions,
        licences and consents as necessary for the use of Customer Data in connection with the
        Services.
      </li>
      <li>
        <strong>4.3</strong> Signstix may assist any rights holders in the enforcement of their
        Intellectual Property Rights and shall not in any way be responsible or liable to the
        Customer as a result of any such action.
      </li>
      <li>
        <strong>4.4</strong> Without in any way affecting its other rights under the Agreement,
        Signstix may terminate or suspend the Services in the event that there is a claim or
        allegation of a claim of infringement of any Intellectual Property Rights of a third party
        or if Signstix otherwise has reasonable grounds to be believe that there is the risk of a
        claim or allegation of a claim.
      </li>
    </ol>
    <h2>5. FURTHER OBLIGATIONS ON THE CUSTOMER</h2>
    <ol>
      <li>
        <strong>5.1</strong> Customers must comply with the spirit of the standards in this AUP as
        well as the letter of them.
      </li>
      <li>
        <strong>5.2</strong> The Customer shall not under any circumstances, access without
        authority, interfere with, damage or disrupt:
      </li>
      <li>
        <strong>5.2.1</strong> Signstix’s Service portal or any part of it or any equipment or
        network on which the Service portal is stored;{' '}
      </li>
      <li>
        <strong>5.2.2</strong> any software used in the provision of the Services;
      </li>
      <li>
        <strong>5.2.3</strong> any other elements of Signstix’s equipment or network; or
      </li>
      <li>
        <strong>5.2.4</strong> any equipment, the network or software owned or used by any third
        party.
      </li>
    </ol>
    <h2>6. USE OF CUSTOMER ACCOUNTS AND UNAUTHORSIED ACCESS</h2>
    <ol>
      <li>
        <strong>6.1</strong> The Customer must not itself and shall ensure that its account Users do
        not, use a Signstix account for the purpose of obtaining unauthorised access to the
        Service.The Customer is responsible at all times for use of the Passcode, whether itself or
        through third parties. Customers must not by any means, attempt to avoid any restrictions
        which may be imposed by Signstix.
      </li>
      <li>
        <strong>6.2</strong> In order to access the Services, the Customer may be required to
        provide information as part of the Service access or as part of the continued use of the
        Services or to gain or use a Passcode. The Customer agrees that any registration information
        will always be accurate, correct and up to date.If the Customer provides any information
        that is untrue, inaccurate, not current or incomplete or if Signstix has reasonable grounds
        to suspect that that is the case, Signstix may suspend or terminate the Customer account and
        refuse any and all current or future use of the Services (or any portion thereof).
      </li>
      <li>
        <strong>6.3</strong> The Customer shall be responsible for bringing this AUP to the
        attention of its Users and ensuring that those Users adhere to the standards contained in
        it.{' '}
      </li>
    </ol>
    <h2>7. BREACH of AUP, Suspension and Termination</h2>
    <ol>
      <li>
        <strong>7.1</strong> Failure to comply with this AUP constitutes a material breach of the
        licence and may result in Signstix taking all or any of the following actions:
      </li>
      <li>
        <strong>7.1.1</strong> immediate, temporary or permanent withdrawal of the Service;
      </li>
      <li>
        <strong>7.1.2</strong> immediate, temporary or permanent removal of any Customer Data;
      </li>
      <li>
        <strong>7.1.3</strong> issue of a warning to the Customer and/or to its Reseller;
      </li>
      <li>
        <strong>7.1.4</strong> legal proceedings against the Customer;
      </li>
      <li>
        <strong>7.1.5</strong> other legal action against the Customer;
      </li>
      <li>
        <strong>7.1.6</strong> disclosure of such information to law enforcement authorities as
        Signstix reasonably deems necessary.
      </li>
      <li>
        <strong>7.2</strong> Without in any way affecting its rights referred to above which it may
        choose to exercise at any time, Signstix reserves the right (but shall not be obliged) to
        require that a Customer takes corrective action if it is found not to be acting in
        compliance with this AUP.Signstix may also impose timescales for such actions.
      </li>
      <li>
        <strong>7.3</strong> Any breaches of this AUP should be reported to Signstix and when
        relevant, Customer Data, together with the full headers, should be forwarded. Although it is
        not always possible to respond to each report, complaints will be investigated.Signstix
        excludes liability for any action taken or inaction in response to breaches of, or
        assertions of breach of, this AUP.The responses described in this policy are not exhaustive
        and Signstix may take any other action as it reasonably deems appropriate.
      </li>
    </ol>
    <h2>8. Changes TO THIS ACCEPTABLE USE POLICY</h2>
    <p>
      Signstix may change this AUP at any time.Customers are expected to check the AUP as included
      on the Signstix Service portal from time to time to take notice of any changes. Some of the
      provisions contained in this AUP may be superseded by provisions included elsewhere.
    </p>
  </div>
);
