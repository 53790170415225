import React from 'react';
import PropTypes from 'prop-types';

const Tag = props => {
  const { children, title, checked, onClick } = props;
  const classes = `tag ${checked ? 'active' : ''}`;

  return (
    <div data-test={`tag-${children}`} className={classes} onClick={onClick} title={title}>
      {children}
    </div>
  );
};

Tag.propTypes = {
  children: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  checked: PropTypes.bool,
  onClick: PropTypes.func,
};

export default Tag;
