import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Button } from 'react-bootstrap';
import errorMessage from '../../../utils/errorMessage';

const ErrorPage = ({ error }) => {
  const history = useHistory();
  const redirectToHome = () => history.push('/');

  // possible error objects:
  // 1. routes.js:      error = { status: 404 }
  // 2. fetchCheck.js:  error = { status: 403, statusText: 'Forbidden' }
  // 3. genuine error:  error = new Error('TypeError: Failed to fetch')
  if (error.status !== 404) {
    return <div className="margin alert alert-danger">{errorMessage(error)}</div>;
  }

  return (
    <div className="page-structure home">
      <Helmet title="404" />
      <div className="page-panels">
        <div className="page-content text-center">
          <h1>404. Page not found</h1>
          <p>Sorry but the page you are looking for has either moved or does not exist.</p>
          <p>If you entered a web address please check it was correct or </p>
          <Button bsStyle="success" onClick={redirectToHome}>
            Click here to return to home
          </Button>
        </div>
      </div>
    </div>
  );
};

ErrorPage.propTypes = {
  error: PropTypes.object.isRequired,
};

export default ErrorPage;
