import 'isomorphic-fetch';
import fetchCheck from '../redux/middleware/fetchCheck';
import * as types from './actionTypes';

export const channelsRequest = () => ({ type: types.CHANNELS_REQUEST });

export const channelsRequestSuccess = result => ({
  type: types.CHANNELS_REQUEST_SUCCESS,
  result,
});

export const channelsRequestFailed = error => ({ type: types.CHANNELS_REQUEST_FAILED, error });

export const fetchChannels = () => {
  return fetch('/cms/data/v1/channels/', {
    method: 'GET',
    credentials: 'include',
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
  })
    .then(res => fetchCheck(res))
    .then(res => res.json());
};

export const loadChannels = () => dispatch => {
  dispatch(channelsRequest());

  return fetchChannels().then(
    res => dispatch(channelsRequestSuccess(res)),
    err => dispatch(channelsRequestFailed(err)),
  );
};
