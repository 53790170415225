import getSignsForAsset from './getSignsForAsset';
import getSchedulesForSign from './getSchedulesForSign';

const getSchedulesForAsset = (asset, signs, sequences, schedules) => {
  if (!asset || !signs || !sequences || !schedules) {
    return [];
  }

  const used = [];

  getSignsForAsset(asset, signs).forEach(sign =>
    getSchedulesForSign(sign, sequences, schedules).forEach(schedule => {
      if (!used.find(item => item.id === schedule.id)) {
        used.push(schedule);
      }
    }),
  );

  return used.sort((a, b) => a.name.localeCompare(b.name));
};

export default getSchedulesForAsset;
