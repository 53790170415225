import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';

export default class EditUrlRuleDialog extends Component {
  state = {
    id: -1,
    type: 'white',
    rule: 'http://',
    beginsWith: false,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { urlRule } = nextProps;

    if (!urlRule) {
      return;
    }

    const { id, type, rule, beginsWith } = urlRule;

    this.setState({ id, type, rule, beginsWith });
  }

  handleChangeType = event => {
    const type = event.target.value;

    this.setState({ type });

    if (type === 'black') {
      this.setState({ beginsWith: false });
    }
  };

  handleChangeBeginsWith = () => {
    const { beginsWith } = this.state;

    this.setState({ beginsWith: !beginsWith });
  };

  handleChangeRule = event => this.setState({ rule: event.target.value });

  handleClickUpdateUrlRule = () => {
    const { onSuccess } = this.props;

    onSuccess(this.state);
  };

  render() {
    const { show, onHide, urlRule } = this.props;
    const { type, rule, beginsWith } = this.state;

    if (!urlRule) {
      return null;
    }

    return (
      <Modal dialogClassName="url-ruleset-dialog" backdrop="static" show={show} onHide={onHide}>
        <Modal.Header>
          <Modal.Title>Edit URL Rule</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group select-type">
            <label>Type</label>
            <select className="form-control" value={type} onChange={this.handleChangeType}>
              <option value="white">Whitelist</option>
              <option value="black">Blacklist</option>
            </select>
          </div>
          <div className="form-group input-rule">
            <label>URL</label>
            <input
              className="form-control"
              placeholder="Enter a URL"
              value={rule}
              onChange={this.handleChangeRule}
              autoFocus
            />
          </div>
          {type === 'white' && (
            <div className="form-group checkbox-beginswith">
              <label>Begins with</label>
              <div>
                <input
                  type="checkbox"
                  checked={Boolean(beginsWith)}
                  onChange={this.handleChangeBeginsWith}
                />
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn-cancel" onClick={onHide}>
            Cancel
          </Button>
          <Button className="btn-update" bsStyle="primary" onClick={this.handleClickUpdateUrlRule}>
            Update URL Rule
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

EditUrlRuleDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  urlRule: PropTypes.object,
  addNotification: PropTypes.func.isRequired,
};
