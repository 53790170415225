import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import async from 'async';
import pluralize from 'pluralize';
import { SOLR_DELAY } from '../../../../constants';
import { getUsers } from '../../../actions/userActions';
import { getSigns } from '../../../actions/signActions';
import { loadSequences } from '../../../actions/sequenceActions';
import { loadSchedules } from '../../../actions/scheduleActions';
import { loadPlaylists } from '../../../actions/playlistActions';
import { loadCampaigns } from '../../../actions/campaignActions';
import { loadAllAssignments } from '../../../actions/assignmentActions';
import {
  getTier,
  getInUse,
  updateTier,
  addRow,
  updateRow,
  deleteRow,
  select,
  unselectAll,
} from '../../../actions/dataObjectActions';
import { addNotification } from '../../../actions/notificationActions';
import Spinner from '../../../components/Spinner/Spinner';
import QuickAction from '../../../components/QuickAction/QuickAction';
import Confirm from '../../../components/Confirm/Confirm';
import Prompt from '../../../components/Prompt/Prompt';
import TierTable from '../../../components/DataObjects/TierTable';
import Used from '../../../components/Used/Used';
import UploadTierDialog from '../../../components/DataObjects/UploadTierDialog';
import AddRowDialog from '../../../components/DataObjects/AddRowDialog';
import EditRowDialog from '../../../components/DataObjects/EditRowDialog';
import Header from '../../../components/Header/Header';
import ActionsMenu from '../../../components/ActionsMenu/ActionsMenu';
import ErrorPage from '../ErrorPage/ErrorPage';
import errorMessage from '../../../utils/errorMessage';

export class TierPage extends Component {
  state = {
    showUploadDialog: false,
    showAddDialog: false,
    showEditDialog: false,
  };

  componentDidMount() {
    this.loadData();
    document.addEventListener('visibilitychange', this.loadData);
  }

  componentWillUnmount() {
    const { unselectAll } = this.props;

    unselectAll();
    document.removeEventListener('visibilitychange', this.loadData);
  }

  loadData = () => {
    if (document.visibilityState === 'hidden') {
      return;
    }

    const {
      match: {
        params: { id, tierid },
      },
      getSigns,
      loadSequences,
      loadSchedules,
      loadPlaylists,
      loadCampaigns,
      loadAllAssignments,
      getUsers,
      getTier,
      getInUse,
    } = this.props;

    getSigns('?count=100000000');
    loadSequences('?count=100000000');
    loadSchedules('?count=100000000');
    loadPlaylists('?count=100000000');
    loadCampaigns('?count=100000000');
    loadAllAssignments();
    getUsers();
    getTier(id, tierid);
    getInUse();
  };

  getSelectedItems = () => {
    const {
      dataObjects: { tier, selected },
    } = this.props;

    return tier.tier ? Object.keys(selected).map(index => tier.tier.data[index]) : [];
  };

  getUsedInSigns = () => {
    const {
      match: {
        params: { id, tierid },
      },
      dataObjects: { inUse },
    } = this.props;

    return inUse
      .filter(item => item.doId === +id && item.tierId === +tierid)
      .map(item => `${item.signId}-${item.published}`);
  };

  getPrimaryKeyValue = row => {
    const {
      dataObjects: {
        tier: { primary_key },
      },
    } = this.props;

    return row.row.columns.find(item => item.name === primary_key).value;
  };

  updateTier = file => {
    const {
      match: {
        params: { id, tierid },
      },
      getTier,
      addNotification,
    } = this.props;

    return updateTier(id, tierid, file).then(
      res => {
        if (!res.error) {
          addNotification({ type: 'success', text: 'Tier updated.' });
          setTimeout(() => getTier(id, tierid), SOLR_DELAY);
        } else {
          addNotification({ type: 'danger', text: res.error.message });
        }
      },
      err => addNotification({ type: 'danger', text: errorMessage(err) }),
    );
  };

  update = row => {
    const {
      match: {
        params: { id, tierid },
      },
      dataObjects: {
        tier: { primary_key, headers },
      },
      getTier,
      addNotification,
    } = this.props;
    const rowid = this.getSelectedItems()[0].row.row_id;

    return updateRow(id, tierid, rowid, primary_key, headers, row).then(
      res => {
        if (res.row_id > -1) {
          addNotification({ type: 'success', text: 'Row updated.' });
          setTimeout(() => getTier(id, tierid), SOLR_DELAY);
        } else {
          addNotification({ type: 'danger', text: 'Request failed.' });
        }
      },
      err => addNotification({ type: 'danger', text: errorMessage(err) }),
    );
  };

  add = row => {
    const {
      match: {
        params: { id, tierid },
      },
      dataObjects: {
        tier: { primary_key, headers },
      },
      getTier,
      addNotification,
    } = this.props;

    return addRow(id, tierid, primary_key, headers, row).then(
      res => {
        if (res.row_id > -1) {
          addNotification({ type: 'success', text: 'Row added.' });
          setTimeout(() => getTier(id, tierid), SOLR_DELAY);
        } else {
          addNotification({ type: 'danger', text: 'Request failed.' });
        }
      },
      err => addNotification({ type: 'danger', text: errorMessage(err) }),
    );
  };

  delete = () => {
    const {
      match: {
        params: { id, tierid },
      },
    } = this.props;
    const items = this.getSelectedItems();

    async.filter(
      items,
      (item, callback) =>
        deleteRow(id, tierid, this.getPrimaryKeyValue(item)).then(
          res => callback(null, res.primarykey),
          () => callback(null, false),
        ),
      this.deleteCallback,
    );
  };

  deleteCallback = (err, results) => {
    const {
      match: {
        params: { id, tierid },
      },
      getTier,
      addNotification,
      unselectAll,
    } = this.props;
    const items = this.getSelectedItems();
    let failedItems;
    let errorMsg = '';

    // check for errors
    if (items.length === results.length) {
      addNotification({
        type: 'success',
        text: `${pluralize('Row', items.length, true)} deleted.`,
      });
    } else {
      // collect the failed request items
      failedItems = items.filter(item => !results.includes(item));
      failedItems.forEach(item => {
        errorMsg += (errorMsg ? ', ' : 'Failed to delete: ') + this.getPrimaryKeyValue(item);
      });
      addNotification({ type: 'danger', text: errorMsg });
    }

    // if there was at least one successful request, update
    if (results.length) {
      unselectAll();
      setTimeout(() => getTier(id, tierid), SOLR_DELAY);
    }
  };

  successUploadDialog = file => {
    this.hideUploadDialog();
    this.updateTier(file);
  };

  successAddDialog = row => {
    this.hideAddDialog();
    this.add(row);
  };

  successEditDialog = row => {
    this.hideEditDialog();
    this.update(row);
  };

  hideUploadDialog = () => this.setState({ showUploadDialog: false });

  hideAddDialog = () => this.setState({ showAddDialog: false });

  hideEditDialog = () => this.setState({ showEditDialog: false });

  handleClickUpload = () => this.setState({ showUploadDialog: true });

  handleClickAdd = () => this.setState({ showAddDialog: true });

  handleClickEdit = () => this.setState({ showEditDialog: true });

  handleClickDelete = () => {
    const confirm = this.refDelete;
    const items = this.getSelectedItems();
    const names = items.map((item, index) => <li key={index}>{this.getPrimaryKeyValue(item)}</li>);
    const title = `Delete ${pluralize('Row', items.length, true)}`;
    const body = (
      <div>
        <p>
          Are you sure you want to delete {pluralize('this', items.length)}{' '}
          {pluralize('Row', items.length)}?
        </p>
        <ol>{names}</ol>
      </div>
    );

    confirm.show(title, body);
    confirm.onSuccess(this.delete);

    return { title, body };
  };

  handleClickTable = rows => {
    const { select } = this.props;

    select(rows);
  };

  handleDoubleClickTable = () => {
    const {
      auth: {
        user: {
          permissions: { Writer },
        },
      },
    } = this.props;

    if (Writer) {
      this.handleClickEdit();
    }
  };

  renderHeaderActions = () => {
    const {
      auth: {
        user: {
          permissions: { Writer },
        },
      },
    } = this.props;

    if (!Writer) {
      return [];
    }

    const actions = [
      <QuickAction key="1" toolText="Add Row" onClick={this.handleClickAdd} alwaysShow>
        add_circle
      </QuickAction>,
      <QuickAction
        key="2"
        toolText="Update Tier from CSV File"
        onClick={this.handleClickUpload}
        alwaysShow
      >
        file_upload
      </QuickAction>,
      <QuickAction
        key="3"
        toolText={pluralize('Delete Row', this.getSelectedItems().length)}
        onClick={this.handleClickDelete}
        singular
        multiple
      >
        delete
      </QuickAction>,
      <QuickAction key="4" toolText="Edit Row" onClick={this.handleClickEdit} singular>
        mode_edit
      </QuickAction>,
    ];

    return actions;
  };

  render() {
    const {
      auth,
      users: { users },
      dataObjects: { tier, selected, loading, error },
      addNotification,
      signs: {
        signs: { signs },
      },
      sequences: {
        sequences: { sequences },
      },
      schedules: {
        schedules: { schedules },
      },
      playlists: {
        playlists: { playlists },
      },
      campaigns: {
        campaigns: { campaigns },
      },
      assignments: {
        allAssignments: { assignments },
      },
    } = this.props;
    const { showUploadDialog, showAddDialog, showEditDialog } = this.state;
    const items = this.getSelectedItems();

    if (error) {
      return <ErrorPage error={error} />;
    }

    return (
      <div className="page-structure">
        <Helmet title="Tier" />
        <Header overwrite={{ id: tier, tierid: tier.tier }} />
        <ActionsMenu actions={this.renderHeaderActions()} selectedLength={items.length} />
        <div className="page-panels">
          <div className="page-content page-data-object-tier">
            <Spinner loading={loading}>
              <TierTable
                data={tier}
                selected={selected}
                onClick={this.handleClickTable}
                onDoubleClick={this.handleDoubleClickTable}
              />
              <Used
                type="asset"
                item={{ usedInSigns: this.getUsedInSigns() }}
                signs={signs}
                sequences={sequences}
                schedules={schedules}
                playlists={playlists}
                campaigns={campaigns}
                assignments={assignments}
                auth={auth}
                users={users}
              />
            </Spinner>
          </div>
        </div>
        <UploadTierDialog
          show={showUploadDialog}
          onHide={this.hideUploadDialog}
          onSuccess={this.successUploadDialog}
          addNotification={addNotification}
        />
        <AddRowDialog
          show={showAddDialog}
          onHide={this.hideAddDialog}
          onSuccess={this.successAddDialog}
          tier={tier}
          addNotification={addNotification}
        />
        <EditRowDialog
          show={showEditDialog}
          onHide={this.hideEditDialog}
          onSuccess={this.successEditDialog}
          tier={tier}
          row={items[0]}
          addNotification={addNotification}
        />
        <Confirm
          ref={ref => {
            this.refDelete = ref;
          }}
        />
        <Prompt
          ref={ref => {
            this.refUpload = ref;
          }}
        />
      </div>
    );
  }
}

TierPage.propTypes = {
  match: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  users: PropTypes.object.isRequired,
  signs: PropTypes.object.isRequired,
  sequences: PropTypes.object.isRequired,
  schedules: PropTypes.object.isRequired,
  playlists: PropTypes.object.isRequired,
  campaigns: PropTypes.object.isRequired,
  assignments: PropTypes.object.isRequired,
  dataObjects: PropTypes.object.isRequired,
  getSigns: PropTypes.func.isRequired,
  loadSequences: PropTypes.func.isRequired,
  loadSchedules: PropTypes.func.isRequired,
  loadPlaylists: PropTypes.func.isRequired,
  loadCampaigns: PropTypes.func.isRequired,
  loadAllAssignments: PropTypes.func.isRequired,
  getUsers: PropTypes.func.isRequired,
  getTier: PropTypes.func.isRequired,
  getInUse: PropTypes.func.isRequired,
  select: PropTypes.func.isRequired,
  unselectAll: PropTypes.func.isRequired,
  addNotification: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  users: state.users,
  signs: state.signs,
  sequences: state.sequences,
  schedules: state.schedules,
  playlists: state.playlists,
  campaigns: state.campaigns,
  assignments: state.assignments,
  dataObjects: state.dataObjects,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getUsers,
      getSigns,
      loadSequences,
      loadSchedules,
      loadPlaylists,
      loadCampaigns,
      loadAllAssignments,
      getTier,
      getInUse,
      select,
      unselectAll,
      addNotification,
    },
    dispatch,
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TierPage));
