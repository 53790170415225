const throttleInterval = 800;
let throttleActive;

export const throttledNotify = (notifier, msg) => {
  if (throttleActive) {
    return;
  }

  notifier(msg);
  throttleActive = true;
  setTimeout(() => {
    throttleActive = false;
  }, throttleInterval);
};
