import * as types from '../actions/actionTypes';

export const initialState = {
  loading: false,
  error: null,
  channels: [],
};

export default function channels(state = initialState, action = {}) {
  switch (action.type) {
    case types.CHANNELS_REQUEST:
      return {
        ...state,
        loadingChannels: true,
        error: false,
      };
    case types.CHANNELS_REQUEST_SUCCESS:
      return {
        ...state,
        loadingChannels: false,
        channels: action.result,
      };
    case types.CHANNELS_REQUEST_FAILED:
      return {
        ...state,
        loadingChannels: false,
        error: action.error,
      };
    default:
      return state;
  }
}
