import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Avatar from '../Avatar/Avatar';

export default class UserProfile extends Component {
  getUserFromUsers = userId => {
    const { users } = this.props;

    if (!userId) {
      return false;
    }

    const userPosition = users.findIndex(user => user.user_id === userId);

    if (userPosition === -1) {
      return false;
    }

    return users[userPosition];
  };

  generateName = user => {
    if (!user) {
      return 'N/A';
    }

    const { firstname, lastname, name } = user;

    if (firstname.trim() === '' || lastname.trim() === '') {
      return name;
    }

    return `${firstname} ${lastname}`;
  };

  render() {
    const { users, userId, className, avatarSize } = this.props;

    if (!users) {
      return null;
    }

    const user = this.getUserFromUsers(userId);

    return (
      <div className={`user-profile ${className}`}>
        <div className="avatar-holder">
          {user && <Avatar size={avatarSize || 30} user={user} />}
          <span>{this.generateName(user)}</span>
        </div>
      </div>
    );
  }
}

UserProfile.propTypes = {
  userId: PropTypes.number,
  users: PropTypes.array,
  avatarSize: PropTypes.number,
  className: PropTypes.string,
};
