import React from 'react';
import PropTypes from 'prop-types';
import ToolTipIcon from './ToolTipIcon';

const ToolTip = props => {
  const { placement } = props;

  return <ToolTipIcon {...props} placement={placement || 'top'} notIcon />;
};

ToolTip.propTypes = {
  children: PropTypes.node.isRequired,
  toolText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  id: PropTypes.string,
  placement: PropTypes.string,
  href: PropTypes.string,
  target: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default ToolTip;
