import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Pill from '../../components/Pill/Pill';
import UserProfile from '../../components/UserProfile/UserProfile';
import { removeFilter } from '../../actions/filterActions';
import {
  isQueryParam,
  isQueryParamSuffix,
  removeQueryParam,
  truncateQueryParam,
} from '../../utils/updateQueryString';

export class FiltersApplied extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.removeFiltersIfNotInQuery(nextProps);
  }

  removeFiltersIfNotInQuery = props => {
    const { location, filter, removeFilter } = props;

    if (!filter || !Object.keys(filter.applied).length) {
      return null;
    }

    Object.keys(filter.applied).forEach(groupName => {
      const group = filter.applied[groupName];

      Object.keys(group).forEach(name => {
        const item = group[name];
        const applied = item.suffix
          ? isQueryParamSuffix(location, item.suffix.param, item.suffix.value)
          : isQueryParam(location, item.group, item.query);

        if (!applied) {
          removeFilter({ group: item.group, name: item.name });
        }
      });
    });
  };

  onClick = item => {
    const { history, location } = this.props;

    if (item.suffix) {
      truncateQueryParam(history, location, item.suffix.param, item.suffix.value);
    } else {
      removeQueryParam(history, location, item.group, item.query);
    }
  };

  getFilter = (item, key) => {
    const { users } = this.props;
    const title = item.title + (item.text ? ': ' : '');
    const testId = item.group + item.text;

    if (['createdBy', 'modifiedBy'].includes(item.group)) {
      return (
        <Pill key={key} testId={testId} handler={() => this.onClick(item)}>
          {title}
          <UserProfile userId={parseInt(item.text, 10)} users={users} />
        </Pill>
      );
    }

    return (
      <Pill key={key} testId={testId} handler={() => this.onClick(item)}>
        {title}
        {item.text}
      </Pill>
    );
  };

  getFilters = () => {
    const { filter } = this.props;
    const filters = [];

    // check is there any applied filter
    if (!filter || !Object.keys(filter.applied).length) {
      return null;
    }

    Object.keys(filter.applied).forEach((groupName, index) => {
      const group = filter.applied[groupName];

      Object.keys(group).forEach((name, index2) => {
        const item = group[name];
        const key = `${index}.${index2}`;

        filters.push(this.getFilter(item, key));
      });
    });

    return filters;
  };

  render() {
    return <div className="filters-applied pull-left">{this.getFilters()}</div>;
  }
}

FiltersApplied.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  filter: PropTypes.object,
  removeFilter: PropTypes.func.isRequired,
  users: PropTypes.array,
};

const mapStateToProps = state => ({
  filter: state.filter,
});
const mapDispatchToProps = {
  removeFilter,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FiltersApplied));
