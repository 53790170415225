import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, FormGroup } from 'react-bootstrap';
import fileTypes from '../../../common/fileTypes';
import { getSupportedExts } from '../../utils/file';

export default class UploadPrompt extends Component {
  getEncodeType = () => {
    const { encodeTypes } = this.props;

    if (!encodeTypes || !encodeTypes.length) {
      return '';
    }

    if (encodeTypes.some(item => item.name === 'Make Compatible')) {
      return 'Make Compatible';
    }

    return encodeTypes[0].name;
  };

  getInitialState = () => ({ uploadType: 'file', files: [], encodeType: this.getEncodeType() });

  state = this.getInitialState();

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { show } = this.props;
    const { show: nextShow } = nextProps;
    const { encodeType } = this.state;

    if (!encodeType) {
      this.setState({ encodeType: this.getEncodeType() });
    }

    if (show && !nextShow) {
      this.setState(this.getInitialState());
    }
  }

  onChangeUploadType = event =>
    this.setState({ uploadType: event.target.value, files: [], encodeType: this.getEncodeType() });

  onChangeFiles = event => this.setState({ files: Array.from(event.target.files) });

  onChangeEncodeType = event => this.setState({ encodeType: event.target.value });

  getOptions = () => {
    const { encodeTypes = [] } = this.props;

    return encodeTypes.map((item, index) => <option key={index}>{item.name}</option>);
  };

  isVideo = item => fileTypes.video.some(ext => item.name.toLowerCase().endsWith(ext));

  hasVideo = () => {
    const { files } = this.state;

    for (let i = 0; i < files.length; i += 1) {
      if (this.isVideo(files[i])) {
        return true;
      }
    }

    return false;
  };

  render() {
    const {
      show,
      title = 'Upload',
      body,
      accept = getSupportedExts(),
      onHide,
      multiple,
      onSuccess,
    } = this.props;
    const { uploadType, files, encodeType } = this.state;

    return (
      <Modal backdrop="static" show={show} onHide={onHide}>
        <Modal.Header>
          <Modal.Title data-test="modal-upload-title">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {body}
          {multiple && (
            <FormGroup>
              <label className="normal block">
                <input
                  type="radio"
                  name="upload"
                  value="file"
                  checked={uploadType === 'file'}
                  onChange={this.onChangeUploadType}
                />
                <span className="margin">Upload Files</span>
              </label>
              <label className="normal block">
                <input
                  type="radio"
                  name="upload"
                  value="folder"
                  checked={uploadType === 'folder'}
                  onChange={this.onChangeUploadType}
                />
                <span className="margin">Upload Folder</span>
              </label>
            </FormGroup>
          )}
          {uploadType === 'file' && (
            <FormGroup>
              <label>Upload File{multiple ? 's' : ''}</label>
              <input
                type="file"
                accept={accept}
                onChange={this.onChangeFiles}
                multiple={multiple}
              />
            </FormGroup>
          )}
          {multiple && uploadType === 'folder' && (
            <FormGroup>
              <label>Upload Folder</label>
              <input
                type="file"
                accept={accept}
                onChange={this.onChangeFiles}
                multiple
                ref={node => {
                  if (!node) {
                    return;
                  }

                  node.webkitdirectory = true;
                  node.directory = true;
                }}
              />
            </FormGroup>
          )}
          {this.hasVideo() && (
            <FormGroup>
              <label>Encoding type</label>
              <select
                value={encodeType}
                className="form-control"
                onChange={this.onChangeEncodeType}
              >
                {this.getOptions()}
              </select>
            </FormGroup>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button data-test="modal-upload-button-cancel" className="btn-cancel" onClick={onHide}>
            Cancel
          </Button>
          <Button
            data-test="modal-upload-button-upload"
            className="btn-upload"
            bsStyle="primary"
            onClick={() => onSuccess(multiple ? files : files[0], encodeType)}
            disabled={!files.length}
          >
            Upload
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

UploadPrompt.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  title: PropTypes.string,
  body: PropTypes.string,
  accept: PropTypes.string,
  encodeTypes: PropTypes.array,
  onSuccess: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
};
