import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Checkbox } from 'react-bootstrap';
import { isQueryParam, addQueryParam, removeQueryParam } from '../../utils/updateQueryString';
import { addFilter } from '../../actions/filterActions';

export class FilterPrestagedDevices extends Component {
  componentDidMount() {
    this.setFilterFromQuery(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setFilterFromQuery(nextProps);
  }

  setFilterFromQuery = props => {
    const { location, name, title, addFilter } = props;
    const activeChecked = isQueryParam(location, 'prestaged', '0');
    const prestagedChecked = isQueryParam(location, 'prestaged', '1');

    if (activeChecked) {
      addFilter({ group: name, name: '0', query: '0', text: 'active', title });
    }

    if (prestagedChecked) {
      addFilter({ group: name, name: '1', query: '1', text: 'prestaged', title });
    }
  };

  onChangePrestaged = () => {
    const { history, location } = this.props;
    const checked = isQueryParam(location, 'prestaged', '1');

    if (checked) {
      removeQueryParam(history, location, 'prestaged', '1');
    } else {
      addQueryParam(history, location, 'prestaged', '1');
    }
  };

  onChangeActive = () => {
    const { history, location } = this.props;
    const checked = isQueryParam(location, 'prestaged', '0');

    if (checked) {
      removeQueryParam(history, location, 'prestaged', '0');
    } else {
      addQueryParam(history, location, 'prestaged', '0');
    }
  };

  render() {
    const { location, title, data = [] } = this.props;
    const activeChecked = isQueryParam(location, 'prestaged', '0');
    const prestagedChecked = isQueryParam(location, 'prestaged', '1');
    // prestaged: ["0", 10, "1", 2] // 10 active, 2 prestaged device
    const activeCount = data[1] || 0;
    const prestagedCount = data[3] || 0;

    return (
      <div>
        <div className="filter-section-title">{title}</div>
        <div className="filter-checkboxlist">
          <Checkbox
            data-test="checkbox-prestaged-prestaged"
            checked={prestagedChecked}
            onChange={this.onChangePrestaged}
          >
            prestaged <span className="total">{prestagedCount}</span>
          </Checkbox>
          <Checkbox
            data-test="checkbox-prestaged-active"
            checked={activeChecked}
            onChange={this.onChangeActive}
          >
            active <span className="total">{activeCount}</span>
          </Checkbox>
        </div>
      </div>
    );
  }
}

FilterPrestagedDevices.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  data: PropTypes.array,
  addFilter: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  addFilter,
};

export default withRouter(connect(null, mapDispatchToProps)(FilterPrestagedDevices));
