import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Modal, Panel } from 'react-bootstrap';
import pluralize from 'pluralize';
import { getScheduleMessage } from '../../utils/deployHelper';
import { getPermissions } from '../../utils/getPermissions';

const getChildDevices = (assignment, allAssignments) => {
  const parentPath = `${assignment.parent + assignment.name}/`;
  let counter = 0;

  allAssignments.forEach(item => {
    const isAChildDevice = item.type === 'device' && item.parent.startsWith(parentPath);

    if (isAChildDevice) {
      counter += 1;
    }
  });

  return counter;
};

const getAssignedContentCount = (assignment, allAssignments) => {
  const parentPath = `${assignment.parent + assignment.name}/`;
  let counter = 0;

  allAssignments.forEach(item => {
    const hasContentAssigned =
      item.type === 'device' && item.parent.startsWith(parentPath) && item.content_type !== 'none';

    if (hasContentAssigned) {
      counter += 1;
    }
  });

  return counter;
};

const getPopulated = (assignment, allAssignments) => {
  let populated = '';

  if (assignment.type === 'location') {
    populated = `${getAssignedContentCount(assignment, allAssignments)}/${getChildDevices(
      assignment,
      allAssignments,
    )}`;
  }

  return populated;
};

// different pages have different ways of getting the device id and location path
// Devices/Device pages use 'id', Assignments page uses 'device_id'
// Devices/Device pages use 'location_path', Assignments page uses 'parent', also if type is 'location' then 'name' should be appended to 'parent'
const getDeviceId = item => item.device_id || item.id;
const getPath = item =>
  item.location_path || (item.type === 'location' ? item.parent + item.name : item.parent);

const DeployDialog = ({
  show,
  onHide,
  deployer,
  downloadSchedule,
  auth,
  devices = [],
  assignments = [],
  allAssignments = [],
  deployAll,
}) => {
  const [showLocations, setShowLocations] = useState(false);
  const [showDevices, setShowDevices] = useState(false);
  const [size, setSize] = useState({ maxHeight: window.innerHeight - 200 });
  const updateSize = () => setSize({ maxHeight: window.innerHeight - 200 });

  useEffect(() => {
    updateSize();

    window.addEventListener('resize', updateSize);

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  if (!auth || (!deployAll && !devices)) {
    return null;
  }

  const { userLocation } = auth.user.user;
  const p = getPermissions(auth);
  const locs = assignments.filter(item => item.type === 'location');
  const devs = assignments.filter(item => item.type === 'device');
  let deployToMsg = 'Deploying to';

  if (deployAll) {
    deployToMsg += userLocation ? ` User Location: "${userLocation}"` : ': All Locations';
  } else if (devices.length) {
    deployToMsg += pluralize('Device', devices.length, true);
  }

  const deployDetails = {
    text: deployToMsg,
  };

  // important to only include deviceIds if not deploying to all devices
  if (!deployAll) {
    deployDetails.deviceIds = devices.map(getDeviceId);
  }

  const onDeploy = () => {
    deployer({ ...deployDetails, isReportMode: false })();
    onHide();
  };

  // const onTestRun = () => {
  //   deployer({ ...deployDetails, isReportMode: true })();
  //   onHide();
  // };

  const getLocationsPanelHeader = () => (
    <div className="toggle" onClick={() => setShowLocations(!showLocations)}>
      <i className="material-icons material-caret inline">
        {showLocations ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}
      </i>
      {selectedMsg}
    </div>
  );
  const getDevicesPanelHeader = () => (
    <div className="toggle" onClick={() => setShowDevices(!showDevices)}>
      <i className="material-icons material-caret inline">
        {showDevices ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}
      </i>
      {deployToMsg}
    </div>
  );

  const getLocations = () => {
    const rows = assignments.map((item, index) => (
      <tr key={index}>
        <td>{index + 1}.</td>
        <td>{item.type}</td>
        <td>{getPath(item)}</td>
        <td>{getPopulated(item, allAssignments)}</td>
        <td>
          {item.type === 'device' && (
            <a target="_blank" rel="noreferrer" href={`/devices/${getDeviceId(item)}`}>
              {item.name}
            </a>
          )}
        </td>
        <td>{item.type === 'device' && item.description}</td>
      </tr>
    ));

    return (
      <table className="table table-striped">
        <thead>
          <tr>
            <th />
            <th>Type</th>
            <th>Location</th>
            <th>Populated</th>
            <th>Device ID</th>
            <th>Name</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    );
  };

  const getDevices = () => {
    const rows = devices.map((item, index) => (
      <tr key={index}>
        <td>{index + 1}.</td>
        <td>{getPath(item)}</td>
        <td>
          <a target="_blank" rel="noreferrer" href={`/devices/${item.id}`}>
            {item.name}
          </a>
        </td>
        <td>{item.description}</td>
      </tr>
    ));

    return (
      <table className="table table-striped">
        <thead>
          <tr>
            <th />
            <th>Location</th>
            <th>Device ID</th>
            <th>Name</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    );
  };

  const selectedLocs = locs.length && pluralize('Location', locs.length, true);
  const selectedDevs = devs.length && pluralize('Device', devs.length, true);
  const selectedItems = [selectedLocs, selectedDevs].filter(Boolean).join(', ');
  const selectedMsg = selectedItems ? `Selected items: ${selectedItems}` : '';
  const cssClass = `device-dialog${devices.length > 0 ? '' : ''}`;

  return (
    <Modal dialogClassName={cssClass} backdrop="static" show={show} onHide={onHide}>
      <Modal.Header>
        <Modal.Title>Deploy</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: size.maxHeight, overflowX: 'auto' }}>
        <p>Are you sure you want to deploy?</p>
        {downloadSchedule && getScheduleMessage(downloadSchedule)}
        {deployAll && <Alert bsStyle="warning">{deployToMsg}</Alert>}
        {!deployAll && p.atLeastTierStandard && (
          <div>
            {selectedItems && (
              <Panel className="dark">
                <Panel.Heading>{getLocationsPanelHeader()}</Panel.Heading>
                <Panel.Body
                  style={{
                    display: showLocations ? 'block' : 'none',
                  }}
                >
                  {getLocations()}
                </Panel.Body>
              </Panel>
            )}
            <Panel className="dark">
              <Panel.Heading>{getDevicesPanelHeader()}</Panel.Heading>
              <Panel.Body
                style={{
                  display: showDevices ? 'block' : 'none',
                }}
              >
                {getDevices()}
              </Panel.Body>
            </Panel>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button data-test="deploy-cancel" className="btn-cancel" onClick={onHide}>
          Cancel
        </Button>
        {/* <Button data-test="deploy-testrun" bsStyle="info" onClick={onTestRun}>
          Test Run
        </Button> */}
        <Button data-test="deploy-ok" bsStyle="primary" onClick={onDeploy}>
          Deploy
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

DeployDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  deployer: PropTypes.func.isRequired,
  downloadSchedule: PropTypes.object,
  auth: PropTypes.object,
  devices: PropTypes.arrayOf(PropTypes.object),
  assignments: PropTypes.arrayOf(PropTypes.object),
  allAssignments: PropTypes.arrayOf(PropTypes.object),
  deployAll: PropTypes.bool,
};

export default DeployDialog;
