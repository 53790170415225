import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, FormGroup, FormControl } from 'react-bootstrap';
import pluralize from 'pluralize';
import LazyLoad from 'react-lazy-load';
import AssetImage from '../Assets/AssetImage';
import { buildTagList } from '../../utils/tagList';

export default class AssetSelector extends Component {
  state = {
    maxHeight: 0,
    filter: '',
    filteredAssets: [],
  };

  componentDidMount() {
    this.updateHeight();
    window.addEventListener('resize', this.updateHeight);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { show } = this.props;
    const { assets, show: nextShow } = nextProps;
    const { filter } = this.state;

    // while open
    if (nextShow) {
      this.setState({
        filteredAssets: this.getFilteredAssets(assets, filter),
      });
    }

    // on close
    if (show && !nextShow) {
      this.setState({ filter: '' });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateHeight);
  }

  updateHeight = () => this.setState({ maxHeight: window.innerHeight - 342 });

  onFilter = event => {
    const { assets } = this.props;
    const filter = event.target.value.toLowerCase();

    this.setState({ filter, filteredAssets: this.getFilteredAssets(assets, filter) });
  };

  getFilteredAssets = (assets, filterStr) => {
    const filters = filterStr.split(' ').filter(item => item);

    return assets.filter(item => {
      if (!filters.length) {
        return true;
      }

      return !filters.find(
        filter =>
          !(
            item.name.toLowerCase().includes(filter) ||
            item.parent.toLowerCase().includes(filter) ||
            (item.tags && item.tags.some(tag => tag.toLowerCase().includes(filter)))
          ),
      );
    });
  };

  getList = () => {
    const { type, signs, onSuccess } = this.props;
    const { filteredAssets, maxHeight } = this.state;
    const assets = filteredAssets.map((item, index) => (
      <li
        data-test={`modal-assetselector-item-${index + 1}`}
        key={index}
        onClick={() => onSuccess(item)}
      >
        <LazyLoad>
          <div>
            <AssetImage type={item.type || type} item={item} signs={signs} />
            <div className="name">
              {item.name}
              <div className="details">Folder: {item.parent}</div>
              <div className="details">Tags: {buildTagList(item)}</div>
            </div>
            <div className="clearfix" />
          </div>
        </LazyLoad>
      </li>
    ));

    return (
      <ul data-test="modal-assetselector-list" style={{ maxHeight }}>
        {assets}
      </ul>
    );
  };

  render() {
    const { show, onHide, type, assets, title, body } = this.props;
    const { filter, filteredAssets } = this.state;

    if (!assets) {
      return null;
    }

    return (
      <Modal className="asset-selector" bsSize="lg" backdrop="static" show={show} onHide={onHide}>
        <Modal.Header>
          <Modal.Title data-test="modal-assetselector-title">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {body}
          <div className="results">
            <p>
              {filter && `${pluralize(' result', filteredAssets.length, true)}. `}
              There {pluralize('is', assets.length)} {pluralize(type, assets.length, true)}{' '}
              available.
            </p>
            <p>To filter on multiple terms, separate them with a space.</p>
          </div>
          <FormGroup>
            <FormControl
              data-test="modal-assetselector-input-filter"
              name="filter"
              onChange={this.onFilter}
              placeholder="Start typing to filter results"
              autoFocus
            />
          </FormGroup>
          {filteredAssets.length > 0 && this.getList()}
        </Modal.Body>
        <Modal.Footer>
          <Button data-test="modal-assetselector-button-close" onClick={onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

AssetSelector.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  assets: PropTypes.array,
  signs: PropTypes.array,
  title: PropTypes.string,
  body: PropTypes.string,
};
