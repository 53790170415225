import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { withRouter, Prompt } from 'react-router-dom';
import { SOLR_DELAY } from '../../../../constants';
import { getUsers } from '../../../actions/userActions';
import {
  getDevice,
  getDeviceInfo,
  getDeviceErrors,
  getStatus,
  getVersions,
  overrideDownloadSchedule,
  deployContent,
  restartPlayer,
  rebootDevice,
  setCopyConfig,
  copyConfig,
  requestDiagnostics,
  requestScreenshot,
  upgradeAllDevices,
  upgradeDevice,
  clearData,
  unregisterDevice,
  updateDevice,
  editDeviceForm,
  clearDeviceForm,
} from '../../../actions/deviceActions';
import { loadAllLocations } from '../../../actions/locationActions';
import { getSigns } from '../../../actions/signActions';
import { loadSequences } from '../../../actions/sequenceActions';
import { loadSchedules } from '../../../actions/scheduleActions';
import { loadCampaigns } from '../../../actions/campaignActions';
import { getCampaignSchedule } from '../../../actions/campaignScheduleActions';
import { loadPlaylists } from '../../../actions/playlistActions';
import { loadChannels } from '../../../actions/channelActions';
import { loadAssignments } from '../../../actions/assignmentActions';
import { addNotification } from '../../../actions/notificationActions';
import Header from '../../../components/Header/Header';
import ToolTipIcon from '../../../components/ToolTipIcon/ToolTipIcon';
import Spinner from '../../../components/Spinner/Spinner';
import getActiveCampaignId from '../../../components/CampaignSchedule/utils/getActiveCampaignId';
import DeviceTitle from '../../../components/Devices/DeviceTitle';
import DeviceStatus from '../../../components/Devices/DeviceStatus';
import DeviceAssignments from '../../../components/Devices/DeviceAssignments';
import DeviceForm from '../../../components/Devices/DeviceForm';
import QuickAction from '../../../components/QuickAction/QuickAction';
import CopyDeviceConfigDialog from '../../../components/Devices/CopyDeviceConfigDialog';
import RequestDiagnosticsDialog from '../../../components/Devices/RequestDiagnosticsDialog';
import RequestScreenshotDialog from '../../../components/Devices/RequestScreenshotDialog';
import RestartPlayerDialog from '../../../components/Devices/RestartPlayerDialog';
import RebootDeviceDialog from '../../../components/Devices/RebootDeviceDialog';
import UpgradeDeviceDialog from '../../../components/Devices/UpgradeDeviceDialog';
import ClearDataDialog from '../../../components/Devices/ClearDataDialog';
import UnregisterDeviceDialog from '../../../components/Devices/UnregisterDeviceDialog';
import ScreenshotDialog from '../../../components/Devices/ScreenshotDialog';
import DiagnosticsDialog from '../../../components/Devices/DiagnosticsDialog';
import DeployDialog from '../../../components/Devices/DeployDialog';
import {
  getHeartbeatPercent,
  getDiagnosticsButton,
  getScreenshotButton,
} from '../../../components/Devices/DeviceUtils';
import LocationSelector from '../../../components/Locations/LocationSelector';
import ActionsMenu from '../../../components/ActionsMenu/ActionsMenu';
import errorMessage from '../../../utils/errorMessage';
import qs from '../../../utils/qs';
import { getPermissions } from '../../../utils/getPermissions';
import { getDeployer } from '../../../utils/deployHelper';
import { checkPathAccess } from '../../../utils/checkPathAccess';

export class DevicePage extends Component {
  state = {
    statusPollInterval: 10000,
    statusPoll: null,
    device: {},
    disabled: true,
    dirty: false,
    showDeployDialog: false,
    showCopyConfigDialog: false,
    showRequestDiagnosticsDialog: false,
    showRequestScreenshotDialog: false,
    showRestartDialog: false,
    showRebootDialog: false,
    showUpgradeDialog: false,
    showClearDataDialog: false,
    showUnregisterDialog: false,
    showDiagnosticsDialog: false,
    showScreenshotDialog: false,
    showLocationSelector: false,
  };

  componentDidMount = () => {
    this.loadData();
    document.addEventListener('visibilitychange', this.loadData);
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      devices: {
        device: {
          device: { name },
        },
      },
      loadAssignments,
    } = this.props;
    const {
      devices: {
        device: {
          device: { name: nextName },
        },
      },
    } = nextProps;

    this.authorisation(nextProps);
    this.showFetchError(nextProps);
    this.mergeDeviceStatusInfoErrors(nextProps);

    if (name !== nextName) {
      loadAssignments(`?count=100000000&parent=/*&search=${nextName}`);
    }
  }

  componentWillUnmount() {
    const { clearDeviceForm } = this.props;
    const { statusPoll } = this.state;

    clearDeviceForm();
    clearInterval(statusPoll);
    document.removeEventListener('visibilitychange', this.loadData);
  }

  loadData = () => {
    const {
      match: {
        params: { id },
      },
      devices: {
        device: {
          device: { name },
        },
      },
      getUsers,
      getVersions,
      loadAllLocations,
      getSigns,
      loadSequences,
      loadSchedules,
      loadPlaylists,
      loadChannels,
      loadCampaigns,
      getCampaignSchedule,
      loadAssignments,
      getDevice,
    } = this.props;
    const { dirty, statusPoll } = this.state;

    if (document.visibilityState === 'hidden') {
      clearInterval(statusPoll);

      return;
    }

    this.pollStatus();
    getUsers();
    getVersions();
    loadAllLocations();
    getSigns('?count=100000000&published=true&isAssignable=true');
    loadSequences('?count=100000000');
    loadSchedules('?count=100000000');
    loadPlaylists('?count=100000000');
    loadChannels('?count=100000000');
    loadCampaigns('?count=100000000');
    getCampaignSchedule();

    if (name) {
      loadAssignments(`?count=100000000&parent=/*&search=${name}`);
    }

    if (!dirty) {
      getDevice(id);
    }
  };

  showFetchError = nextProps => {
    const {
      devices: { error },
      addNotification,
    } = this.props;
    const {
      devices: { error: nextError },
    } = nextProps;

    if (!error && nextError) {
      addNotification({ type: 'danger', text: errorMessage(nextError) });
    }
  };

  authorisation = nextProps => {
    const {
      auth,
      history,
      match: {
        params: { id },
      },
    } = nextProps;
    const { disabled } = this.state;
    const isDisabled = qs(nextProps).edit === undefined;
    const p = getPermissions(auth);

    if (!p.deviceManager && !isDisabled) {
      history.push(`/devices/${id}`);
    }

    if (disabled !== isDisabled) {
      this.setState({ disabled: isDisabled });
    }
  };

  mergeDeviceStatusInfoErrors = nextProps => {
    const {
      devices: {
        device: { device, info, errors },
        status: { statuses },
      },
    } = nextProps;

    if (!device.id) {
      return;
    }

    const status = (statuses || []).find(item => device.id === item.id);
    const mergedDevice = { ...device, ...status, content_ver: undefined, ...info };

    mergedDevice.errors = errors;
    mergedDevice.heartbeatPercent = getHeartbeatPercent(mergedDevice);
    this.setState({ device: mergedDevice });
  };

  mergeDeviceAssignmentsData = () => {
    const {
      assignments: {
        assignments: { assignments },
      },
      signs: {
        signs: { signs },
      },
      sequences: {
        sequences: { sequences },
      },
      schedules: {
        schedules: { schedules },
      },
      playlists: {
        playlists: { playlists },
      },
      channels: { channels },
      campaigns: {
        campaigns: { campaigns },
      },
    } = this.props;

    if (!signs || !sequences || !schedules || !playlists || !channels || !campaigns) {
      return assignments;
    }

    return assignments
      .map(item => {
        item.campaign = campaigns.find(camp => +camp.id === +item.campaign_id);

        if (item.content_type === 'sign') {
          item.sign = signs.find(sign => +sign.id === +item.content_id);
        }

        if (item.content_type === 'sequence') {
          item.sequence = sequences.find(sequence => +sequence.id === +item.content_id);
        }

        if (item.content_type === 'schedule') {
          item.schedule = schedules.find(schedule => +schedule.id === +item.content_id);
        }

        if (item.content_default_stream_type === 'playlist') {
          item.playlist = playlists.find(
            playlist => +playlist.id === +item.content_default_stream_id,
          );
        }

        if (item.content_default_stream_type === 'channel') {
          item.channel = channels.find(channel => +channel.id === +item.content_default_stream_id);
        }

        return item;
      })
      .filter(item => item.campaign && (item.content_id || item.content_default_stream_id))
      .sort((a, b) => a.campaign.name.localeCompare(b.campaign.name));
  };

  getStatus = () => {
    const { Cypress } = window;
    const {
      match: {
        params: { id },
      },
      getStatus,
      getDeviceInfo,
      getDeviceErrors,
    } = this.props;
    const { unregisterInProgress } = this.state;

    if (Cypress && !Cypress.env('RECORD') && !Cypress.cy.signstixCtx.serverInfo.routesStubbed) {
      return;
    }

    if (unregisterInProgress) {
      return;
    }

    getStatus([id]);
    getDeviceInfo(id);
    getDeviceErrors(id);
  };

  pollStatus = () => {
    const { statusPollInterval } = this.state;

    this.getStatus();
    this.setState({ statusPoll: setInterval(this.getStatus, statusPollInterval) });
  };

  goBack = () => {
    const { history, previousLocation } = this.props;
    const url = previousLocation ? previousLocation.pathname + previousLocation.search : '/devices';

    this.setState({ dirty: false }, () => history.push(url));
  };

  onChange = (key, value) => {
    const { editDeviceForm } = this.props;
    const { dirty } = this.state;

    editDeviceForm(key, value);

    if (!dirty) {
      this.setState({ dirty: true });
    }
  };

  onLeave = event => {
    const {
      history,
      match: {
        params: { id },
      },
      clearDeviceForm,
    } = this.props;
    const { disabled, dirty } = this.state;

    event.preventDefault();

    if (dirty) {
      if (window.confirm('You have unsaved changes. Are you sure you want to leave this page?')) {
        this.setState({ dirty: false }, () => {
          clearDeviceForm();
          history.push(`/devices/${id}`);
        });
      }
    } else {
      history.push(`/devices/${id}${disabled ? '?edit' : ''}`);
    }
  };

  showCopyConfigDialog = () => {
    if (this.isPasteConfigEnabled()) {
      this.setState({ showCopyConfigDialog: true });
    }
  };

  showDeployDialog = () => this.setState({ showDeployDialog: true });

  showRequestDiagnosticsDialog = () => this.setState({ showRequestDiagnosticsDialog: true });

  showRequestScreenshotDialog = () => this.setState({ showRequestScreenshotDialog: true });

  showRestartDialog = () => this.setState({ showRestartDialog: true });

  showRebootDialog = () => this.setState({ showRebootDialog: true });

  showUpgradeDialog = () => this.setState({ showUpgradeDialog: true });

  showClearDataDialog = () => this.setState({ showClearDataDialog: true });

  showUnregisterDialog = () => this.setState({ showUnregisterDialog: true });

  showDiagnosticsDialog = () => this.setState({ showDiagnosticsDialog: true });

  showScreenshotDialog = () => this.setState({ showScreenshotDialog: true });

  showLocationSelector = () => this.setState({ showLocationSelector: true });

  hideDeployDialog = () => this.setState({ showDeployDialog: false });

  hideCopyConfigDialog = () => this.setState({ showCopyConfigDialog: false });

  hideRequestDiagnosticsDialog = () => this.setState({ showRequestDiagnosticsDialog: false });

  hideRequestScreenshotDialog = () => this.setState({ showRequestScreenshotDialog: false });

  hideRestartDialog = () => this.setState({ showRestartDialog: false });

  hideRebootDialog = () => this.setState({ showRebootDialog: false });

  hideUpgradeDialog = () => this.setState({ showUpgradeDialog: false });

  hideClearDataDialog = () => this.setState({ showClearDataDialog: false });

  hideUnregisterDialog = () => this.setState({ showUnregisterDialog: false });

  hideDiagnosticsDialog = () => this.setState({ showDiagnosticsDialog: false });

  hideScreenshotDialog = () => this.setState({ showScreenshotDialog: false });

  hideLocationSelector = () => this.setState({ showLocationSelector: false });

  successCopyConfigDialog = () => {
    this.hideCopyConfigDialog();
    this.copyConfig();
  };

  successDiagnosticsDialog = () => {
    this.hideRequestDiagnosticsDialog();
    this.diagnostics();
  };

  successScreenshotDialog = () => {
    this.hideRequestScreenshotDialog();
    this.screenshot();
  };

  successRestartDialog = () => {
    this.hideRestartDialog();
    this.restart();
  };

  successRebootDialog = () => {
    this.hideRebootDialog();
    this.reboot();
  };

  successClearDataDialog = () => {
    this.hideClearDataDialog();
    this.clearData();
  };

  successUnregisterDialog = () => {
    this.hideUnregisterDialog();
    this.unregister();
  };

  successUpgradeDialog = version => {
    this.hideUpgradeDialog();
    this.upgrade(version);
  };

  successUpgradeAllDialog = version => {
    this.hideUpgradeDialog();
    this.upgradeAll(version);
  };

  overrideDownloadSchedule = () => {
    const {
      match: {
        params: { id },
      },
      addNotification,
    } = this.props;

    return overrideDownloadSchedule(id).then(
      () => addNotification({ type: 'success', text: 'Override Download Schedule set.' }),
      err => addNotification({ type: 'danger', text: errorMessage(err) }),
    );
  };

  diagnostics = () => {
    const {
      match: {
        params: { id },
      },
      addNotification,
    } = this.props;

    return requestDiagnostics(id).then(
      () => {
        addNotification({ type: 'success', text: 'Device diagnostics requested.' });
        this.getStatus();
      },
      err => addNotification({ type: 'danger', text: errorMessage(err) }),
    );
  };

  screenshot = () => {
    const {
      match: {
        params: { id },
      },
      addNotification,
    } = this.props;

    return requestScreenshot(id).then(
      () => {
        addNotification({ type: 'success', text: 'Device screenshot requested.' });
        this.getStatus();
      },
      err => addNotification({ type: 'danger', text: errorMessage(err) }),
    );
  };

  restart = () => {
    const {
      match: {
        params: { id },
      },
      addNotification,
    } = this.props;

    return restartPlayer(id).then(
      () => addNotification({ type: 'success', text: 'Player restart requested.' }),
      err => addNotification({ type: 'danger', text: errorMessage(err) }),
    );
  };

  reboot = () => {
    const {
      match: {
        params: { id },
      },
      addNotification,
    } = this.props;

    return rebootDevice(id).then(
      () => addNotification({ type: 'success', text: 'Device reboot requested.' }),
      err => addNotification({ type: 'danger', text: errorMessage(err) }),
    );
  };

  upgradeAll = version => {
    const { addNotification } = this.props;

    return upgradeAllDevices(version).then(
      () => addNotification({ type: 'success', text: 'Upgrade ALL Devices requested.' }),
      err => addNotification({ type: 'danger', text: errorMessage(err) }),
    );
  };

  upgrade = version => {
    const {
      match: {
        params: { id },
      },
      addNotification,
    } = this.props;

    return upgradeDevice(id, version).then(
      () => addNotification({ type: 'success', text: 'Upgrade Device requested.' }),
      err => addNotification({ type: 'danger', text: errorMessage(err) }),
    );
  };

  clearData = () => {
    const {
      match: {
        params: { id },
      },
      addNotification,
    } = this.props;

    return clearData(id).then(
      () => addNotification({ type: 'success', text: 'Clear signage data requested.' }),
      err => addNotification({ type: 'danger', text: errorMessage(err) }),
    );
  };

  unregister = () => {
    const {
      history,
      match: {
        params: { id },
      },
      addNotification,
    } = this.props;

    this.setState({ unregisterInProgress: true });

    return unregisterDevice(id).then(
      () => {
        addNotification({ type: 'success', text: 'Device unregistered.' });
        setTimeout(() => history.push('/devices'), SOLR_DELAY);
      },
      err => {
        this.setState({ unregisterInProgress: false });
        addNotification({ type: 'danger', text: errorMessage(err) });
      },
    );
  };

  update = () => {
    const {
      devices: {
        device: { edit },
      },
      addNotification,
      getDevice,
    } = this.props;

    return updateDevice(edit).then(
      () => {
        addNotification({ type: 'success', text: 'Device updated.' });
        getDevice(edit.id);
        setTimeout(this.goBack, SOLR_DELAY);
      },
      err => addNotification({ type: 'danger', text: errorMessage(err) }),
    );
  };

  copyConfig = () => {
    const {
      match: {
        params: { id },
      },
      devices: {
        copyConfig: { id: copyId },
      },
      addNotification,
    } = this.props;

    return copyConfig(copyId, id).then(
      () => {
        addNotification({ type: 'success', text: 'Device settings updated.' });
        setTimeout(this.goBack, SOLR_DELAY);
      },
      err => addNotification({ type: 'danger', text: errorMessage(err) }),
    );
  };

  isPasteConfigEnabled = () => {
    const {
      match: {
        params: { id },
      },
      devices: { copyConfig },
    } = this.props;

    return Boolean(copyConfig) && copyConfig.id !== id;
  };

  setCopyConfig = () => {
    const {
      devices: {
        device: { device },
      },
      setCopyConfig,
      addNotification,
    } = this.props;

    setCopyConfig(device);
    const text = (
      <span>
        Device Settings copied from <code key={device.id}>{device.name}</code> {device.description}
      </span>
    );

    addNotification({ type: 'success', text });
  };

  getFullLocation = device => {
    const {
      locations: {
        allLocations: { locations },
      },
    } = this.props;
    const location = locations.find(item => +item.id === +device.location_id);

    return location ? `${location.parent + location.name}/` : 'No Location set';
  };

  setLocation = location => {
    this.onChange('location_id', location.id);
    this.hideLocationSelector();
  };

  getHeaderActions = () => {
    const {
      match: {
        params: { id },
      },
      auth,
      campaignSchedule,
      devices: {
        device: { device },
        copyConfig,
      },
    } = this.props;
    const { campaignScheduleEntries } = campaignSchedule.campaignSchedule;
    const { disabled } = this.state;
    const pasteConfigClassName = this.isPasteConfigEnabled() ? '' : 'disabled';
    const deviceLocation = this.getFullLocation(device);
    const isAccessAllowed = checkPathAccess(auth.user.user.userLocation, deviceLocation);
    const p = getPermissions(auth);
    let pasteConfigToolText = 'Paste Device Settings';
    let deployClassName = '';
    let deployToolText = 'Deploy Content';
    let deployFn = this.showDeployDialog;

    if (copyConfig) {
      pasteConfigToolText = (
        <span>
          Paste Device Settings from
          <br />
          <code>{copyConfig.name}</code>
          <br />
          <b>{copyConfig.description}</b>
        </span>
      );
    }

    if (!isAccessAllowed) {
      deployClassName = 'disabled';
      deployToolText =
        'Deploy Content. Deploy Content. Location is not accessible with current User Location.';

      deployFn = () => {};
    }

    const override = p.atLeastTierStandardOrSuperUser && p.downloadSchedule && (
      <QuickAction
        key="1"
        toolText="Override Download Schedule"
        onClick={this.overrideDownloadSchedule}
        alwaysShow
      >
        access_time
      </QuickAction>
    );
    const deploy = (
      <QuickAction
        key="2"
        toolText={deployToolText}
        onClick={deployFn}
        className={deployClassName}
        alwaysShow
      >
        cloud_download
      </QuickAction>
    );
    const overview = p.atLeastTierStandardOrSuperUser && (
      <QuickAction
        key="3"
        toolText={disabled ? 'Edit Device Settings' : 'Device Overview'}
        href={`/devices/${id}${disabled ? '?edit' : ''}`}
        onClick={this.onLeave}
        alwaysShow
      >
        {disabled ? 'mode_edit' : 'remove_red_eye'}
      </QuickAction>
    );
    const copy = p.atLeastTierStandardOrSuperUser && (
      <QuickAction key="4" toolText="Copy Device Settings" onClick={this.setCopyConfig} alwaysShow>
        content_copy
      </QuickAction>
    );
    const paste = p.atLeastTierStandardOrSuperUser && (
      <QuickAction
        key="5"
        id="paste-device-settings"
        className={pasteConfigClassName}
        toolText={pasteConfigToolText}
        onClick={this.showCopyConfigDialog}
        alwaysShow
      >
        content_paste
      </QuickAction>
    );
    const diagnostics = p.atLeastTierStandardOrSuperUser && (
      <QuickAction
        key="6"
        toolText="Request Diagnostics"
        onClick={this.showRequestDiagnosticsDialog}
        alwaysShow
      >
        assignment
      </QuickAction>
    );
    const screenshot = p.atLeastTierStandardOrSuperUser && (
      <QuickAction
        key="7"
        toolText="Request Screenshot"
        onClick={this.showRequestScreenshotDialog}
        alwaysShow
      >
        photo_camera
      </QuickAction>
    );
    const restart = p.atLeastTierStandardOrSuperUser && (
      <QuickAction key="8" toolText="Restart Player" onClick={this.showRestartDialog} alwaysShow>
        settings_backup_restore
      </QuickAction>
    );
    const reboot = p.atLeastTierStandardOrSuperUser && (
      <QuickAction key="9" toolText="Reboot Device" onClick={this.showRebootDialog} alwaysShow>
        power_settings_new
      </QuickAction>
    );
    const upgrade = p.atLeastTierStandardOrSuperUser && (
      <QuickAction key="10" toolText="Upgrade Device" onClick={this.showUpgradeDialog} alwaysShow>
        present_to_all
      </QuickAction>
    );
    const clearData = p.atLeastTierStandardOrSuperUser && (
      <QuickAction
        key="11"
        toolText="Clear Signage Data"
        onClick={this.showClearDataDialog}
        alwaysShow
      >
        layers_clear
      </QuickAction>
    );
    const unregister = p.atLeastTierStandardOrSuperUser && (
      <QuickAction
        key="12"
        toolText="Unregister Device"
        onClick={this.showUnregisterDialog}
        alwaysShow
      >
        delete
      </QuickAction>
    );
    const preview = (
      <QuickAction
        key="13"
        toolText="Device Preview"
        href={`/player/?device=${id}&campaign=${getActiveCampaignId(campaignScheduleEntries)}`}
        target="_blank"
        alwaysShow
      >
        play_circle_filled
      </QuickAction>
    );

    if (!p.deviceManager) {
      return [preview, diagnostics, screenshot].filter(Boolean);
    }

    return [
      preview,
      override,
      deploy,
      diagnostics,
      screenshot,
      restart,
      reboot,
      upgrade,
      clearData,
      unregister,
      copy,
      paste,
      overview,
    ].filter(Boolean);
  };

  render() {
    const {
      match: {
        params: { id },
      },
      auth,
      users: { users },
      devices: {
        device: { device, edit },
        loading,
        versions,
        copyConfig,
      },
      locations: {
        allLocations: { locations },
      },
      signs: {
        signs: { signs },
      },
      deployContent,
    } = this.props;
    const { downloadSchedule } = auth.user;
    const {
      dirty,
      device: stateDevice,
      showDeployDialog,
      showLocationSelector,
      showCopyConfigDialog,
      showRequestDiagnosticsDialog,
      showRequestScreenshotDialog,
      showRestartDialog,
      showRebootDialog,
      showUpgradeDialog,
      showClearDataDialog,
      showUnregisterDialog,
      showDiagnosticsDialog,
      showScreenshotDialog,
      disabled,
    } = this.state;
    const location = edit?.location_id;
    const userLocation = auth.user.user.userLocation || '';
    const p = getPermissions(auth);

    if (!stateDevice.id) {
      return null;
    }

    return (
      <div className="page-structure">
        <Helmet title="Device" />
        <Header overwrite={{ id: device }} />
        <ActionsMenu actions={this.getHeaderActions()} />
        <div className="page-panels">
          <div className="page-content page-device">
            <Spinner loading={loading}>
              <div className="device-filemenu">
                {p.atLeastTierStandardOrSuperUser &&
                  disabled &&
                  getDiagnosticsButton(stateDevice, this.showDiagnosticsDialog)}
                {p.atLeastTierStandardOrSuperUser &&
                  disabled &&
                  getScreenshotButton(stateDevice, this.showScreenshotDialog)}
                {!disabled && (
                  <ToolTipIcon
                    toolText="Cancel Edit"
                    href={`/devices/${id}`}
                    onClick={this.onLeave}
                  >
                    cancel
                  </ToolTipIcon>
                )}
              </div>
              <div className="margin">
                <DeviceTitle users={users} device={stateDevice} />
                {disabled && <DeviceStatus auth={auth} device={stateDevice} users={users} />}
              </div>
              <Prompt
                when={dirty}
                message="You have unsaved changes. Are you sure you want to leave this page?"
              />
              <DeviceForm
                auth={auth}
                locations={locations}
                original={device}
                device={edit}
                device_type={stateDevice.device_type}
                disabled={disabled}
                onChange={this.onChange}
                onShowLocationSelector={this.showLocationSelector}
                onUpdate={this.update}
                onCancel={this.goBack}
                getFullLocation={this.getFullLocation}
                userLocation={userLocation}
              />
              {disabled && (
                <div className="margin">
                  <DeviceAssignments
                    auth={auth}
                    device={device}
                    data={this.mergeDeviceAssignmentsData()}
                    signs={signs}
                  />
                </div>
              )}
            </Spinner>
          </div>
        </div>
        <DeployDialog
          show={showDeployDialog}
          onHide={this.hideDeployDialog}
          deployer={getDeployer(deployContent)}
          downloadSchedule={downloadSchedule}
          auth={auth}
          devices={[device]}
        />
        <CopyDeviceConfigDialog
          devices={[device]}
          copyConfig={copyConfig}
          show={showCopyConfigDialog}
          onHide={this.hideCopyConfigDialog}
          onSuccess={this.successCopyConfigDialog}
        />
        <RequestDiagnosticsDialog
          devices={[device]}
          show={showRequestDiagnosticsDialog}
          onHide={this.hideRequestDiagnosticsDialog}
          onSuccess={this.successDiagnosticsDialog}
        />
        <RequestScreenshotDialog
          devices={[device]}
          show={showRequestScreenshotDialog}
          onHide={this.hideRequestScreenshotDialog}
          onSuccess={this.successScreenshotDialog}
        />
        <RestartPlayerDialog
          devices={[device]}
          show={showRestartDialog}
          onHide={this.hideRestartDialog}
          onSuccess={this.successRestartDialog}
        />
        <RebootDeviceDialog
          devices={[device]}
          show={showRebootDialog}
          onHide={this.hideRebootDialog}
          onSuccess={this.successRebootDialog}
        />
        <UpgradeDeviceDialog
          devices={[stateDevice]}
          versions={versions}
          show={showUpgradeDialog}
          onHide={this.hideUpgradeDialog}
          onUpgrade={this.successUpgradeDialog}
          onUpgradeAll={this.successUpgradeAllDialog}
        />
        <ClearDataDialog
          devices={[device]}
          show={showClearDataDialog}
          onHide={this.hideClearDataDialog}
          onSuccess={this.successClearDataDialog}
        />
        <UnregisterDeviceDialog
          devices={[device]}
          show={showUnregisterDialog}
          onHide={this.hideUnregisterDialog}
          onSuccess={this.successUnregisterDialog}
        />
        <DiagnosticsDialog
          users={users}
          device={stateDevice}
          show={showDiagnosticsDialog}
          onHide={this.hideDiagnosticsDialog}
          onSuccess={this.hideDiagnosticsDialog}
          onRequestDiagnostics={this.diagnostics}
        />
        <ScreenshotDialog
          users={users}
          device={stateDevice}
          show={showScreenshotDialog}
          onHide={this.hideScreenshotDialog}
          onSuccess={this.hideScreenshotDialog}
          onRequestScreenshot={this.screenshot}
        />
        <LocationSelector
          show={showLocationSelector}
          onHide={this.hideLocationSelector}
          onSuccess={this.setLocation}
          items={locations}
          currentId={location}
          userLocation={userLocation}
        />
      </div>
    );
  }
}

DevicePage.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  previousLocation: PropTypes.object,
  location: PropTypes.object.isRequired,
  locations: PropTypes.object.isRequired,
  users: PropTypes.object.isRequired,
  devices: PropTypes.object.isRequired,
  signs: PropTypes.object.isRequired,
  sequences: PropTypes.object.isRequired,
  schedules: PropTypes.object.isRequired,
  playlists: PropTypes.object.isRequired,
  channels: PropTypes.object.isRequired,
  campaigns: PropTypes.object.isRequired,
  campaignSchedule: PropTypes.object.isRequired,
  assignments: PropTypes.object.isRequired,
  getUsers: PropTypes.func.isRequired,
  getDevice: PropTypes.func.isRequired,
  getStatus: PropTypes.func.isRequired,
  getDeviceInfo: PropTypes.func.isRequired,
  getDeviceErrors: PropTypes.func.isRequired,
  getVersions: PropTypes.func.isRequired,
  setCopyConfig: PropTypes.func.isRequired,
  editDeviceForm: PropTypes.func.isRequired,
  clearDeviceForm: PropTypes.func.isRequired,
  addNotification: PropTypes.func.isRequired,
  loadAllLocations: PropTypes.func.isRequired,
  getSigns: PropTypes.func.isRequired,
  loadSequences: PropTypes.func.isRequired,
  loadSchedules: PropTypes.func.isRequired,
  loadPlaylists: PropTypes.func.isRequired,
  loadChannels: PropTypes.func.isRequired,
  loadCampaigns: PropTypes.func.isRequired,
  getCampaignSchedule: PropTypes.func.isRequired,
  loadAssignments: PropTypes.func.isRequired,
  deployContent: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  previousLocation: state.app.previousLocation,
  locations: state.locations,
  users: state.users,
  devices: state.devices,
  signs: state.signs,
  sequences: state.sequences,
  schedules: state.schedules,
  playlists: state.playlists,
  channels: state.channels,
  campaigns: state.campaigns,
  campaignSchedule: state.campaignSchedule,
  assignments: state.assignments,
});
const mapDispatchToProps = {
  getUsers,
  getDevice,
  getStatus,
  getDeviceInfo,
  getDeviceErrors,
  getVersions,
  setCopyConfig,
  editDeviceForm,
  clearDeviceForm,
  addNotification,
  loadAllLocations,
  getSigns,
  loadSequences,
  loadSchedules,
  loadPlaylists,
  loadChannels,
  loadCampaigns,
  getCampaignSchedule,
  loadAssignments,
  deployContent,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DevicePage));
