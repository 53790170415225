export const closeNav = () => {
  const sideNav = document.getElementsByTagName('NAV')[0];
  const page = document.getElementsByClassName('page')[0];

  sideNav.classList.remove('navigation-open');
  page.classList.remove('navigation-open');
};

export const openNav = () => {
  const sideNav = document.getElementsByTagName('NAV')[0];
  const page = document.getElementsByClassName('page')[0];

  sideNav.classList.add('navigation-open');
  page.classList.add('navigation-open');
};

export const toggleNav = () => {
  const sideNav = document.getElementsByTagName('NAV')[0];

  if (process.env.NODE_ENV !== 'test') {
    if (sideNav.classList.contains('navigation-open')) {
      closeNav();
    } else {
      openNav();
    }
  }
};
