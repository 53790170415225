const getAssignmentsForSign = (sign, sequences, schedules, playlists, assignments) => {
  if (!sign || !sequences || !schedules || !playlists || !assignments) {
    return [];
  }

  return assignments
    .map(item => {
      if (item.content_type === 'schedule') {
        const schedule = schedules.find(sch => sch.id === item.content_id);

        if (schedule) {
          schedule.scheduleEntries.forEach(entry => {
            if (entry.sign_id === +sign.id) {
              item.signInUse = true;
            }

            if (entry.sequence_id) {
              const sequence = sequences.find(seq => seq.id === entry.sequence_id);

              if (
                sequence &&
                sequence.sequenceEntries.find(seqEntry => seqEntry.sign_id === +sign.id)
              ) {
                item.signInUse = true;
              }
            }
          });
        }
      }

      if (item.content_type === 'sequence') {
        const sequence = sequences.find(seq => seq.id === item.content_id);

        if (sequence) {
          item.sequenceEntries = sequence.sequenceEntries;
        }
      }

      if (item.content_default_stream_type === 'playlist') {
        const playlist = playlists.find(pl => pl.id === item.content_default_stream_id);

        if (playlist) {
          item.playlistEntries = playlist.playlistEntries;
        }
      }

      return item;
    })
    .filter(
      item =>
        (item.content_type === 'sign' && item.content_id === +sign.id) ||
        (item.content_type === 'sequence' &&
          item.sequenceEntries.find(entry => entry.sign_id === +sign.id)) ||
        (item.content_type === 'schedule' && item.signInUse),
    )
    .sort((a, b) => a.name.localeCompare(b.name));
};

export default getAssignmentsForSign;
