import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, FormGroup, FormControl } from 'react-bootstrap';
import LazyLoad from 'react-lazy-load';
import AssetImage from '../Assets/AssetImage';
import { buildTagList } from '../../utils/tagList';

export default class AssignmentSelector extends Component {
  state = {
    maxheight: 0,
    filter: '',
    filteredData: [],
  };

  componentDidMount() {
    this.updateHeight();
    window.addEventListener('resize', this.updateHeight);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { show } = this.props;
    const { data, show: nextShow } = nextProps;
    const { filter } = this.state;

    if (nextShow) {
      this.setState({ filteredData: this.getFilteredData(data, filter) });
    }

    if (show && !nextShow) {
      this.setState({ filter: '' });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateHeight);
  }

  onFilter = event => {
    const { data } = this.props;
    const filter = event.target.value.toLowerCase();

    this.setState({ filter, filteredData: this.getFilteredData(data, filter) });
  };

  getFilteredData = (data, filterString) => {
    const { type } = this.props;
    const filters = filterString.split(' ').filter(item => item);

    if (type === 'channel') {
      return data.filter(item => {
        if (!filters.length) {
          return true;
        }

        return !filters.find(filter => !item.name.toLowerCase().includes(filter));
      });
    }

    return data.filter(item => {
      if (!filters.length) {
        return true;
      }

      return !filters.find(
        filter =>
          !(
            item.name.toLowerCase().includes(filter) ||
            item.parent.toLowerCase().includes(filter) ||
            (item.tags && item.tags.some(tag => tag.toLowerCase().includes(filter)))
          ),
      );
    });
  };

  updateHeight = () => this.setState({ maxheight: window.innerHeight - 340 });

  submitLabel = () => {
    const { type } = this.props;

    return type === 'campaign' ? 'Change' : 'Assign';
  };

  buildList() {
    const { type, signs, onClick } = this.props;
    const { filteredData } = this.state;
    const listItems = filteredData.map((item, index) => (
      <li key={index} onClick={() => onClick(item)} className={this.selectedItem(item)}>
        <LazyLoad>
          <div>
            <AssetImage type={item.type || type} item={item} signs={signs} />
            <div className="asset-name">
              {item.name}
              <div className="details">Folder: {item.parent}</div>
              <div className="details">Tags: {buildTagList(item)}</div>
            </div>
            <div className="clearfix" />
          </div>
        </LazyLoad>
      </li>
    ));

    return listItems;
  }

  selectedItem(item) {
    const { selectedAssignment } = this.props;

    if (selectedAssignment === item) {
      return 'selected';
    }
  }

  disableSubmit() {
    const { selectedAssignment } = this.props;

    if (Object.keys(selectedAssignment).length === 0) {
      return true;
    }

    return false;
  }

  renderModalBodyText() {
    const { type } = this.props;
    let text = '';

    if (type === 'campaign') {
      text = 'Select the Campaign you would like to view.';
    } else {
      text = `Select the ${type} you would like to assign from the list below, then click Assign.`;
    }

    return text;
  }

  render() {
    const { show, onHide, type, onSuccess } = this.props;
    const { maxheight } = this.state;
    const capitalisedType = type.charAt(0).toUpperCase() + type.slice(1);

    return (
      <Modal className="asset-selector" bsSize="lg" backdrop="static" show={show} onHide={onHide}>
        <Modal.Header>
          <Modal.Title>Select {capitalisedType}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{this.renderModalBodyText()}</p>
          <p>To filter on multiple terms, separate them with a space.</p>
          <FormGroup controlId="filter">
            <FormControl
              type="text"
              name="filter"
              onChange={this.onFilter}
              placeholder="Start typing to filter results"
              autoFocus
            />
          </FormGroup>
          <ul style={{ maxHeight: maxheight }} className="assignment-list">
            {this.buildList()}
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onHide}>Cancel</Button>
          <Button onClick={onSuccess} bsStyle="primary" disabled={this.disableSubmit()}>
            {this.submitLabel()}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

AssignmentSelector.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  data: PropTypes.array,
  signs: PropTypes.array,
  onClick: PropTypes.func.isRequired,
  selectedAssignment: PropTypes.object,
  onSuccess: PropTypes.func.isRequired,
};
