import getAssignmentsForAsset from './getAssignmentsForAsset';
import getSequencesForPlaylist from './getSequencesForPlaylist';
import getSchedulesForPlaylist from './getSchedulesForPlaylist';

const getAssignmentsForPlaylist = (
  playlist,
  signs,
  sequences,
  schedules,
  playlists,
  assignments,
) => {
  if (!playlist || !signs || !sequences || !schedules || !playlists || !assignments) {
    return [];
  }

  const usedInContent = getAssignmentsForAsset(
    playlist,
    signs,
    sequences,
    schedules,
    playlists,
    assignments,
  );
  const usedInSequenceIds = getSequencesForPlaylist(playlist, signs, sequences).map(
    item => item.id,
  );
  const usedInScheduleIds = getSchedulesForPlaylist(playlist, signs, sequences, schedules).map(
    item => item.id,
  );

  return assignments
    .filter(item => {
      const {
        content_type,
        content_id,
        content_default_stream_type,
        content_default_stream_id,
      } = item;

      if (content_default_stream_type === 'playlist' && content_default_stream_id === playlist.id) {
        return true;
      }

      // playlist in sequence in schedule
      if (content_type === 'schedule' && usedInScheduleIds.includes(content_id)) {
        return true;
      }

      // playlist in sequence
      if (content_type === 'sequence' && usedInSequenceIds.includes(content_id)) {
        return true;
      }

      // "Video Play Fullscreen" event in: sign | sign in sequence | sign in sequence in schedule
      if (
        usedInContent.find(
          item => item.content_type === content_type && item.content_id === content_id,
        )
      ) {
        return true;
      }

      return false;
    })
    .sort((a, b) => a.name.localeCompare(b.name));
};

export default getAssignmentsForPlaylist;
