import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ControlLabel } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { getHours, getMinutes, set } from 'date-fns';

export default class PowerScheduleReboot extends Component {
  timeToDate = () => {
    const { device } = this.props;
    const date = set(new Date(), { hours: device.restart_hour, minutes: device.restart_min });

    return date;
  };

  onChange = date => {
    const { onChange } = this.props;

    onChange('restart_hour', getHours(date));
    onChange('restart_min', getMinutes(date));
  };

  onChangeRaw = event => {
    const { onChangeRaw } = this.props;
    const isValid = event.target.value.length === 5; // hh:mm
    const date = onChangeRaw(event);

    if (isValid) {
      this.onChange(date);
    }
  };

  render() {
    const { disabled } = this.props;

    return (
      <div data-test="datepicker-restart" className="power-schedule-days">
        <ControlLabel>Reboot Time</ControlLabel>
        <div className="time-wrapper">
          <DatePicker
            className="form-control"
            selected={this.timeToDate()}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            placeholderText="--:--"
            timeCaption="Reboot time"
            dateFormat="HH:mm"
            timeFormat="HH:mm"
            onChange={this.onChange}
            onChangeRaw={this.onChangeRaw}
            disabled={disabled}
          />
        </div>
      </div>
    );
  }
}

PowerScheduleReboot.propTypes = {
  device: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onChangeRaw: PropTypes.func.isRequired,
};
