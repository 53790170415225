import * as types from '../actions/actionTypes';

export const initialState = {
  loadingAssignment: null,
  loadingAssignments: null,
  loadingAllAssignments: null,
  assignment: {},
  assignments: {
    assignments: [],
    facet_fields: {},
    stats_fields: {},
  },
  allAssignments: {
    assignments: [],
  },
  selected: {},
  currentPath: '/',
  errors: {},
};

export default function assignments(state = initialState, action = {}) {
  switch (action.type) {
    case types.ASSIGNMENTS_REQUEST:
      return {
        ...state,
        loadingAssignments: true,
        error: false,
      };
    case types.ASSIGNMENTS_REQUEST_SUCCESS:
      return {
        ...state,
        loadingAssignments: false,
        assignments: action.result,
      };
    case types.ASSIGNMENTS_REQUEST_FAILED:
      return {
        ...state,
        loadingAssignments: false,
        error: action.error,
      };
    case types.ASSIGNMENTS_SELECT:
      return {
        ...state,
        selected: {
          ...action.ids,
        },
      };
    case types.ASSIGNMENTS_UNSELECT_ALL:
      return {
        ...state,
        selected: {},
      };
    case types.ALL_ASSIGNMENTS_REQUEST:
      return {
        ...state,
        loadingAllAssignments: true,
        error: false,
      };
    case types.ALL_ASSIGNMENTS_REQUEST_SUCCESS:
      return {
        ...state,
        loadingAllAssignments: false,
        allAssignments: action.result,
      };
    case types.ALL_ASSIGNMENTS_REQUEST_FAILED:
      return {
        ...state,
        loadingAllAssignments: false,
        error: action.error,
      };
    default:
      return state;
  }
}
