import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import ToolTipIcon from '../ToolTipIcon/ToolTipIcon';
import DeviceTitle from './DeviceTitle';
import {
  getFile,
  getCommandStatus,
  getCommandAndFileTimes,
  getCommandSpinner,
} from './DeviceUtils';

export default class ScreenshotDialog extends Component {
  state = { maxWidth: 0, maxHeight: 0 };

  componentDidMount() {
    this.updateSize();
    window.addEventListener('resize', this.updateSize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateSize);
  }

  updateSize = () =>
    this.setState({ maxWidth: window.innerWidth - 100, maxHeight: window.innerHeight - 255 });

  render() {
    const { show, onHide, users, device, onRequestScreenshot } = this.props;
    const { maxWidth, maxHeight } = this.state;

    if (!users || !device) {
      return null;
    }

    const commandStatus = getCommandStatus(device, 'screenshot');
    const time = getCommandAndFileTimes(device, 'screenshot');
    const file = getFile(device, 'screenshot');

    if (!file) {
      return null;
    }

    const fileUrl = `/cms/data/v1/devices/${device.id}/file/${file.filename}?${file.timestamp}`;

    return (
      <Modal
        data-test="modal-screenshot"
        dialogClassName="device-dialog width-auto"
        show={show}
        onHide={onHide}
      >
        <Modal.Header closeButton>
          <span className="menu">
            {getCommandSpinner(device, 'screenshot')}
            <ToolTipIcon toolText="Request Screenshot" onClick={onRequestScreenshot}>
              photo_camera
            </ToolTipIcon>
            <ToolTipIcon toolText="Open in New Tab" href={fileUrl} target="_blank">
              open_in_new
            </ToolTipIcon>
          </span>
          <Modal.Title>Device Screenshot</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="pull-right">
            <div className="device-request-time">
              <span>{time.commandText}</span>
              {time.commandTime}
            </div>
            <div className="device-request-time">
              <span>Uploaded:</span>
              {time.fileTime}
            </div>
            {commandStatus && <div className="color-red-500">Request failed</div>}
          </div>
          <DeviceTitle users={users} device={device} />
          <img
            style={{ maxWidth, maxHeight }}
            className="device-screenshot"
            src={fileUrl}
            alt="Screenshot"
          />
        </Modal.Body>
      </Modal>
    );
  }
}

ScreenshotDialog.propTypes = {
  users: PropTypes.array,
  device: PropTypes.object,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onRequestScreenshot: PropTypes.func.isRequired,
};
