import * as types from './actionTypes';

export const openFilters = () => ({ type: types.FILTERS_OPEN_FILTERS });

export const closeFilters = () => ({ type: types.FILTERS_CLOSE_FILTERS });

export const toggleFilters = () => ({ type: types.FILTERS_TOGGLE_FILTERS });

export const resetFilters = () => ({ type: types.FILTERS_RESET_FILTERS });

export const addFilter = filter => ({ type: types.FILTERS_ADD_FILTER, filter });

export const addUniqueFilter = filter => ({ type: types.FILTERS_ADD_UNIQUE_FILTER, filter });

export const removeFilter = filter => ({ type: types.FILTERS_REMOVE_FILTER, filter });
