import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Panel,
  Grid,
  Row,
  Col,
  Clearfix,
  FormControl,
  ControlLabel,
  Checkbox,
  Button,
} from 'react-bootstrap';
import DeviceRotationControl from './DeviceRotationControl';
import PowerSchedules from './PowerSchedules';
import TagCloud from '../TagCloud/TagCloud';
import { checkPathAccess } from '../../utils/checkPathAccess';
import { getPermissions } from '../../utils/getPermissions';
import ToolTip from '../ToolTipIcon/ToolTip';

export default class DeviceForm extends Component {
  submit = event => {
    const { onUpdate } = this.props;

    event.preventDefault();
    onUpdate();
  };

  componentDidMount() {
    if (window) {
      this.resizeDescriptionTextarea();
      window.addEventListener('resize', this.resizeDescriptionTextarea);
    }
  }

  componentWillUnmount() {
    if (window) {
      window.removeEventListener('resize', this.resizeDescriptionTextarea);
    }
  }

  onChange = event => {
    const { onChange } = this.props;

    onChange(event.target.name, event.target.value);
  };

  onChangeTag = tags => {
    const { onChange } = this.props;

    onChange('tags', tags);
  };

  onChangeCheckbox = event => {
    // every checkbox use 0|1 in the JSON
    const { onChange } = this.props;
    const { name, checked } = event.target;
    const value = checked ? 1 : 0;

    onChange(name, value);
  };

  onChangeSelect = event => {
    // every select use numbers in the JSON
    const { onChange } = this.props;
    const { name, value } = event.target;

    onChange(name, parseInt(value, 10));
  };

  onChangeTextarea = event => {
    // max description length in the db is 255
    if (event.target.value.length > 255) {
      return;
    }

    this.onChange(event);
    this.resizeDescriptionTextarea();
  };

  resizeDescriptionTextarea = () => {
    if (!this.description) {
      return;
    }

    this.description.style.height = 'auto'; // reset height to recalculate scrollHeight
    this.description.style.height = `${this.description.scrollHeight}px`;
  };

  getDownloadIntervalOptions = () => {
    const texts = ['Short', 'Medium', 'Long'];

    return [30, 60, 120].map((item, index) => (
      <option key={index} value={item}>
        {texts[index]} - {item} seconds
      </option>
    ));
  };

  getFpsOptions = () =>
    [0, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60].map((item, index) => (
      <option key={index} value={item}>
        {index ? `${item} FPS` : 'Unlimited'}
      </option>
    ));

  getSyncMethodOptions = () =>
    ['None', 'NTP', 'Master', 'Slave'].map((item, index) => (
      <option key={index} value={index}>
        {item}
      </option>
    ));

  getOverlaySourceOptions = () =>
    ['None', 'Qmatic', 'KeyPress', 'Demo'].map((item, index) => (
      <option key={index} value={index}>
        {item}
      </option>
    ));

  getOverlayAlignmentOptions = () =>
    ['None', 'Centre', 'Right'].map((item, index) => (
      <option key={index} value={index}>
        {item}
      </option>
    ));

  getLocation = () => {
    const { getFullLocation, device } = this.props;
    const location = getFullLocation(device);

    return location || 'No Location set';
  };

  getGeneral = () => {
    const {
      auth,
      original,
      device,
      device_type,
      disabled,
      onShowLocationSelector,
      userLocation,
    } = this.props;
    const isAccessAllowed = checkPathAccess(userLocation, device.location_path);

    return (
      <Col md={6} lg={4}>
        <Panel>
          <Panel.Heading>General</Panel.Heading>
          <Panel.Body>
            <div>
              <ControlLabel>Name</ControlLabel>
              <FormControl
                data-test="input-desc"
                name="description"
                value={device.description}
                onChange={this.onChange}
                disabled={disabled}
              />
            </div>
            <div>
              <ControlLabel>Description</ControlLabel>
              <FormControl
                data-test="input-desc-2"
                name="description_2"
                componentClass="textarea"
                rows={1}
                value={device.description_2 || ''}
                onChange={this.onChangeTextarea}
                disabled={disabled}
                inputRef={ref => {
                  this.description = ref;
                }}
              />
            </div>
            <div data-test="tags" className={`device-tags ${disabled ? '' : 'edit'}`}>
              <ControlLabel>Tags</ControlLabel>
              <TagCloud tags={device.tags} onChange={this.onChangeTag} disabled={disabled} />
              <div className="clearfix" />
            </div>
            <div>
              <ControlLabel>Location</ControlLabel>
              <FormControl
                data-test="input-location"
                value={this.getLocation()}
                onClick={onShowLocationSelector}
                disabled={disabled || !isAccessAllowed}
                readOnly
              />
            </div>
            <div>
              <ControlLabel>Download Interval</ControlLabel>
              <select
                data-test="input-downloadinterval"
                name="download_interval"
                value={device.download_interval}
                onChange={this.onChangeSelect}
                disabled={disabled}
              >
                {this.getDownloadIntervalOptions()}
              </select>
            </div>
            <DeviceRotationControl
              hardware_rotation={device.hardware_rotation}
              software_rotation={device.rotation}
              device_type={device_type}
              onChangeSelect={this.onChangeSelect}
              viewMode={disabled}
            />
            <div>
              <ControlLabel>Draw at Top Left</ControlLabel>
              <Checkbox
                data-test="checkbox-drawtopleft"
                name="viewport_top_left"
                checked={device.viewport_top_left}
                onChange={this.onChangeCheckbox}
                disabled={disabled}
              />
            </div>
            <div>
              <ControlLabel>Max Frame Rate</ControlLabel>
              <select
                data-test="select-fps"
                name="fps_limit"
                value={device.fps_limit}
                onChange={this.onChangeSelect}
                disabled={disabled}
              >
                {this.getFpsOptions()}
              </select>
            </div>
            <div>
              <ControlLabel>Default Device</ControlLabel>
              <Checkbox
                data-test="checkbox-default"
                name="is_default"
                checked={device.is_default || original.is_default}
                onChange={this.onChangeCheckbox}
                disabled={disabled || Boolean(original.is_default)}
              />
            </div>
            <div>
              <ControlLabel>Prestaged Device</ControlLabel>
              <Checkbox
                data-test="checkbox-prestaged"
                name="prestaged"
                checked={device.prestaged}
                onChange={this.onChangeCheckbox}
                disabled={disabled}
              />
            </div>
            {Boolean(auth.user.commander_enabled) && (
              <div>
                <ControlLabel>Commander</ControlLabel>
                <Checkbox
                  data-test="checkbox-commander"
                  name="commander_enabled"
                  checked={device.commander_enabled}
                  onChange={this.onChangeCheckbox}
                  disabled={disabled}
                />
              </div>
            )}
          </Panel.Body>
        </Panel>
      </Col>
    );
  };

  getVideoWall = () => {
    const { device, disabled } = this.props;

    return (
      <Col md={6} lg={4}>
        <Panel>
          <Panel.Heading>Video Wall</Panel.Heading>
          <Panel.Body>
            <div>
              <ControlLabel>Sync Method</ControlLabel>
              <select
                data-test="select-syncmethod"
                name="synchronisation_method"
                value={device.synchronisation_method}
                onChange={this.onChangeSelect}
                disabled={disabled}
              >
                {this.getSyncMethodOptions()}
              </select>
            </div>
            <div>
              <ControlLabel>Sync Tag</ControlLabel>
              <FormControl
                data-test="input-synctag"
                name="sync_tag"
                value={device.sync_tag}
                onChange={this.onChange}
                disabled={disabled || [0, 1].includes(device.synchronisation_method)}
              />
            </div>
            <div>
              <ControlLabel>NTP Server</ControlLabel>
              <FormControl
                data-test="input-ntpserver"
                name="time_sync"
                value={device.time_sync}
                onChange={this.onChange}
                disabled={disabled || device.synchronisation_method !== 1}
              />
            </div>
            <div>
              <ControlLabel>Video Wall Enabled</ControlLabel>
              <Checkbox
                data-test="checkbox-videowall"
                name="vw_enabled"
                checked={device.vw_enabled}
                onChange={this.onChangeCheckbox}
                disabled={disabled}
              />
            </div>
            <div>
              <ControlLabel>Panel Left</ControlLabel>
              <FormControl
                data-test="input-vwleft"
                name="vw_left"
                value={device.vw_left}
                onChange={this.onChange}
                disabled={disabled || !device.vw_enabled}
              />
            </div>
            <div>
              <ControlLabel>Panel Top</ControlLabel>
              <FormControl
                data-test="input-vwtop"
                name="vw_top"
                value={device.vw_top}
                onChange={this.onChange}
                disabled={disabled || !device.vw_enabled}
              />
            </div>
            <div>
              <ControlLabel>Panel Width</ControlLabel>
              <FormControl
                data-test="input-vwpanelwidth"
                name="vw_width"
                value={device.vw_width}
                onChange={this.onChange}
                disabled={disabled || !device.vw_enabled}
              />
            </div>
            <div>
              <ControlLabel>Panel Height</ControlLabel>
              <FormControl
                data-test="input-vwpanelheight"
                name="vw_height"
                value={device.vw_height}
                onChange={this.onChange}
                disabled={disabled || !device.vw_enabled}
              />
            </div>
            <div>
              <ControlLabel>Wall Width</ControlLabel>
              <FormControl
                data-test="input-vwwallwidth"
                name="vw_wall_width"
                value={device.vw_wall_width}
                onChange={this.onChange}
                disabled={disabled || !device.vw_enabled}
              />
            </div>
            <div>
              <ControlLabel>Wall Height</ControlLabel>
              <FormControl
                data-test="input-vwwallheight"
                name="vw_wall_height"
                value={device.vw_wall_height}
                onChange={this.onChange}
                disabled={disabled || !device.vw_enabled}
              />
            </div>
          </Panel.Body>
        </Panel>
      </Col>
    );
  };

  getPowerSchedules = () => {
    const { device, device_type, disabled, onChange } = this.props;

    return (
      <Col md={6} lg={4}>
        <PowerSchedules
          device={device}
          device_type={device_type}
          disabled={disabled}
          onChange={onChange}
          onChangeCheckbox={this.onChangeCheckbox}
        />
      </Col>
    );
  };

  getDiagnostics = () => {
    const { device, disabled } = this.props;

    return (
      <Col md={6} lg={4}>
        <Panel>
          <Panel.Heading>Diagnostics</Panel.Heading>
          <Panel.Body>
            <div>
              <ControlLabel>Overlay Diagnostics</ControlLabel>
              <Checkbox
                data-test="checkbox-diagnostics"
                name="debug_toast"
                checked={device.debug_toast}
                onChange={this.onChangeCheckbox}
                disabled={disabled}
              />
            </div>
            <div>
              <ControlLabel>Save Errors</ControlLabel>
              <Checkbox
                data-test="checkbox-saveerrors"
                name="save_errors"
                checked={device.save_errors}
                onChange={this.onChangeCheckbox}
                disabled={disabled}
              />
            </div>
            <div>
              <ControlLabel>Download Progress</ControlLabel>
              <Checkbox
                data-test="checkbox-progress"
                name="show_progress"
                checked={device.show_progress}
                onChange={this.onChangeCheckbox}
                disabled={disabled}
              />
            </div>
            <div>
              <ControlLabel>Error Marker</ControlLabel>
              <Checkbox
                data-test="checkbox-errormarker"
                name="error_marker"
                checked={device.error_marker}
                onChange={this.onChangeCheckbox}
                disabled={disabled}
              />
            </div>
            <div>
              <ControlLabel>Statistics Overlay</ControlLabel>
              <Checkbox
                data-test="checkbox-statistics"
                name="show_stats"
                checked={device.show_stats}
                onChange={this.onChangeCheckbox}
                disabled={disabled}
              />
            </div>
            <div>
              <ControlLabel>Collect Analytics</ControlLabel>
              <Checkbox
                data-test="checkbox-analytics"
                name="event_analytics_enabled"
                checked={device.event_analytics_enabled}
                onChange={this.onChangeCheckbox}
                disabled={disabled}
              />
            </div>
            <div>
              <ControlLabel>Network Watchdog</ControlLabel>
              <Checkbox
                data-test="checkbox-networkwatchdog"
                name="network_watchdog"
                checked={device.network_watchdog}
                onChange={this.onChangeCheckbox}
                disabled={disabled}
              />
            </div>
            <div>
              <ControlLabel>Healthcheck Config</ControlLabel>
              {!device.healthcheck_config && <span>{device.healthcheck_config_name}</span>}
              {device.healthcheck_config && (
                <ToolTip
                  toolText={
                    <pre style={{ textAlign: 'left', fontSize: '0.7em' }}>
                      {JSON.stringify(device.healthcheck_config, null, 2)}
                    </pre>
                  }
                >
                  {device.healthcheck_config_name}
                </ToolTip>
              )}
            </div>
          </Panel.Body>
        </Panel>
      </Col>
    );
  };

  getOverlays = () => {
    const { device, disabled } = this.props;

    return (
      <Col md={6} lg={4}>
        <Panel>
          <Panel.Heading>Overlays</Panel.Heading>
          <Panel.Body>
            <div>
              <ControlLabel>Overlay Source</ControlLabel>
              <select
                data-test="select-overlaysource"
                name="overlay_source_type"
                value={device.overlay_source_type}
                onChange={this.onChangeSelect}
                disabled={disabled}
              >
                {this.getOverlaySourceOptions()}
              </select>
            </div>
            <div>
              <ControlLabel>Overlay Params</ControlLabel>
              <FormControl
                data-test="input-overlayparams"
                name="overlay_source_params"
                value={device.overlay_source_params}
                onChange={this.onChange}
                disabled={disabled || !device.overlay_source_type}
              />
            </div>
            <div>
              <ControlLabel>Overlay Prefix</ControlLabel>
              <FormControl
                data-test="input-overlayprefix"
                name="overlay_prefix"
                value={device.overlay_prefix}
                onChange={this.onChange}
                disabled={disabled || !device.overlay_source_type}
              />
            </div>
            <div>
              <ControlLabel>Overlay Suffix</ControlLabel>
              <FormControl
                data-test="input-overlaysuffix"
                name="overlay_suffix"
                value={device.overlay_suffix}
                onChange={this.onChange}
                disabled={disabled || !device.overlay_source_type}
              />
            </div>
            <div>
              <ControlLabel>Overlay Alignment</ControlLabel>
              <select
                data-test="select-overlayalignment"
                name="overlay_alignment"
                value={device.overlay_alignment}
                onChange={this.onChangeSelect}
                disabled={disabled || !device.overlay_source_type}
              >
                {this.getOverlayAlignmentOptions()}
              </select>
            </div>
            <div>
              <ControlLabel>Overlay Duration</ControlLabel>
              <FormControl
                data-test="input-overlayduration"
                name="overlay_duration"
                onChange={this.onChange}
                value={device.overlay_duration}
                disabled={disabled || !device.overlay_source_type}
              />
            </div>
            <div>
              <ControlLabel>Overlay Speech</ControlLabel>
              <Checkbox
                data-test="checkbox-speech"
                name="overlay_generate_speech"
                checked={device.overlay_generate_speech}
                onChange={this.onChangeCheckbox}
                disabled={disabled || !device.overlay_source_type}
              />
            </div>
            <div>
              <ControlLabel>Speech Prefix</ControlLabel>
              <FormControl
                data-test="input-speechprefix"
                name="overlay_speech_prefix"
                value={device.overlay_speech_prefix}
                onChange={this.onChange}
                disabled={
                  disabled || !device.overlay_source_type || !device.overlay_generate_speech
                }
              />
            </div>
            <div>
              <ControlLabel>Speech Suffix</ControlLabel>
              <FormControl
                data-test="input-speechsuffix"
                name="overlay_speech_suffix"
                value={device.overlay_speech_suffix}
                onChange={this.onChange}
                disabled={
                  disabled || !device.overlay_source_type || !device.overlay_generate_speech
                }
              />
            </div>
          </Panel.Body>
        </Panel>
      </Col>
    );
  };

  getExternal = () => {
    const { device, disabled } = this.props;

    return (
      <Col md={6} lg={4}>
        <Panel>
          <Panel.Heading>External</Panel.Heading>
          <Panel.Body>
            <div>
              <ControlLabel>3rd-party updates</ControlLabel>
              <Checkbox
                data-test="checkbox-externalupdate"
                name="allow_external_update"
                checked={device.allow_external_update}
                onChange={this.onChangeCheckbox}
                disabled={disabled}
              />
            </div>
            <div>
              <ControlLabel>Broadcast</ControlLabel>
              <Checkbox
                data-test="checkbox-broadcast"
                name="broadcast"
                checked={device.broadcast}
                onChange={this.onChangeCheckbox}
                disabled={disabled}
              />
            </div>
            <div>
              <ControlLabel>Broadcast Port</ControlLabel>
              <FormControl
                data-test="input-broadcastport"
                name="broadcast_port"
                value={device.broadcast_port}
                onChange={this.onChange}
                disabled={disabled}
              />
            </div>
          </Panel.Body>
        </Panel>
      </Col>
    );
  };

  getButtons = () => {
    const { auth, disabled, onCancel } = this.props;
    const p = getPermissions(auth);

    if (disabled) {
      return null;
    }

    return (
      <div className="form-buttons">
        <Button data-test="button-cancel" onClick={onCancel}>
          Cancel
        </Button>
        {p.deviceManager && (
          <Button data-test="button-update" bsStyle="success" type="submit">
            Update Device
          </Button>
        )}
      </div>
    );
  };

  render() {
    const { auth, device, disabled } = this.props;
    const p = getPermissions(auth);

    if (!p.atLeastTierStandardOrSuperUser || !device.id) {
      return null;
    }

    return (
      <form className={disabled ? 'disabled' : ''} onSubmit={this.submit}>
        <Grid fluid>
          <Row>
            {this.getGeneral()}
            {this.getVideoWall()}
            <Clearfix visibleMdBlock />
            {this.getPowerSchedules()}
            <Clearfix visibleLgBlock />
            {this.getDiagnostics()}
            <Clearfix visibleMdBlock />
            {this.getOverlays()}
            {this.getExternal()}
            <Clearfix visibleMdBlock />
            <Clearfix visibleLgBlock />
          </Row>
        </Grid>
        {this.getButtons()}
      </form>
    );
  }
}

DeviceForm.propTypes = {
  auth: PropTypes.object.isRequired,
  locations: PropTypes.array.isRequired,
  original: PropTypes.object.isRequired,
  device: PropTypes.object.isRequired,
  device_type: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onShowLocationSelector: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  getFullLocation: PropTypes.func.isRequired,
  userLocation: PropTypes.string.isRequired,
};
