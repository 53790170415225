import getPlaylistsForVideo from './getPlaylistsForVideo';
import getSignsForAsset from './getSignsForAsset';

const getSignsForVideo = (asset, signs, playlists) => {
  if (!asset || !signs || !playlists || !signs.length) {
    return [];
  }

  const used = [];

  getPlaylistsForVideo(asset, playlists).forEach(playlist =>
    getSignsForAsset(playlist, signs).forEach(sign => {
      if (!used.find(item => item.id === sign.id)) {
        used.push(sign);

        // video asset 'usedInSigns' prop is null - which is correct (the playlist used not the video)
        // but we need the usedInSigns info for the UsedInSignsTable - we fill up the usedInSigns here
        // this solution shows both pending and published version of the sign which is incorrect
        if (asset.type === 'video') {
          if (!asset.usedInSigns) {
            asset.usedInSigns = [];
          }

          if (sign.pending && !asset.usedInSigns.includes(`${sign.id}-0`)) {
            asset.usedInSigns.push(`${sign.id}-0`);
          }

          if (sign.published && !asset.usedInSigns.includes(`${sign.id}-1`)) {
            asset.usedInSigns.push(`${sign.id}-1`);
          }
        }
      }
    }),
  );

  return used.sort((a, b) => a.name.localeCompare(b.name));
};

export default getSignsForVideo;
