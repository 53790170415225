import qs from './qs';

// check whether the given name=value is in the querystring
export function isQueryParam(location, name, value) {
  if (!location) {
    return false;
  }

  const paramValue = qs({ location })[name];

  if (paramValue === undefined) {
    return false;
  }

  // the queryParam can be string or array, depends how many values it has
  if (Array.isArray(paramValue) && paramValue.indexOf(value) > -1) {
    return true;
  }

  if (paramValue === value) {
    return true;
  }

  return false;
}

export function isQueryParamSuffix(location, name, value) {
  if (!location) {
    return false;
  }

  const paramValue = qs({ location })[name];

  if (!paramValue) {
    return false;
  }

  if (typeof paramValue === 'string' && paramValue.endsWith(value)) {
    return true;
  }

  return false;
}

// add the given name=value to the querystring
// it does not overwrite other params in the query with the same name, like name=value2
export function buildUrlForAddQueryParam(location, name, value) {
  let querystring = '';
  let paramValue;
  let found = false;
  const query = qs({ location });

  Object.keys(query).forEach(paramName => {
    paramValue = query[paramName];

    // it the param has multiple values it is an array
    if (Array.isArray(paramValue)) {
      paramValue.forEach(itemValue => {
        querystring += `&${paramName}=${encodeURIComponent(itemValue)}`;

        if (paramName === name && itemValue === value) {
          found = true;
        }
      });

      return;
    }

    // always remove the offset, except when the user set pagination offset
    if (paramName !== 'offset') {
      querystring += `&${paramName}=${encodeURIComponent(paramValue)}`;

      if (paramName === name && paramValue === value) {
        found = true;
      }
    }
  });

  if (!found) {
    querystring += `&${name}=${encodeURIComponent(value)}`;
  }

  return location.pathname + querystring.replace('&', '?');
}

function buildUrlActionHelper(location, name, paramProvider) {
  let querystring = '';
  let paramValue;
  let found = false;
  const query = qs({ location });

  Object.keys(query).forEach(paramName => {
    paramValue = query[paramName];

    // it the param has multiple values it is an array
    if (Array.isArray(paramValue)) {
      paramValue.forEach(itemValue => {
        querystring += `&${paramName}=${encodeURIComponent(itemValue)}`;
      });

      return;
    }

    // always remove the offset, except when the user set pagination offset
    if (paramName !== 'offset') {
      if (paramName === name) {
        querystring += paramProvider(paramValue);
        found = true;
      } else {
        querystring += `&${paramName}=${encodeURIComponent(paramValue)}`;
      }
    }
  });

  if (!found) {
    querystring += paramProvider();
  }

  return location.pathname + querystring.replace('&', '?');
}

// add the given name=value to the querystring
// it expects a unique name, overwrite other params with the same name, like name=value2
export function buildUrlForAddUniqueQueryParam(location, name, value) {
  return buildUrlActionHelper(location, name, () => {
    return `&${name}=${encodeURIComponent(value)}`;
  });
}

// append value to an existing name=value param in the querystring
// If there is no param 'name' currently in the query string, it  will create a
// new name=initialValue param if initialValue is supplied or will create name=value if it doesn't
export function buildUrlForAppendUniqueQueryParam(location, name, value, initialValue) {
  return buildUrlActionHelper(location, name, currentValue => {
    let updatedValue = '';

    if (currentValue) {
      updatedValue = currentValue + value;
    } else {
      updatedValue = initialValue || value;
    }

    return `&${name}=${encodeURIComponent(updatedValue)}`;
  });
}

export function buildUrlForTruncateQueryParam(location, name, value) {
  return buildUrlActionHelper(location, name, currentValue => {
    if (currentValue) {
      let newValue = currentValue;

      if (typeof currentValue === 'string' && currentValue.endsWith(value)) {
        newValue = currentValue.substring(0, currentValue.lastIndexOf(value));
      }

      return `&${name}=${encodeURIComponent(newValue)}`;
    }

    return '';
  });
}

// remove the given name=value from the querystring
export function buildUrlForRemoveQueryParam(location, name, value) {
  let querystring = '';
  let paramValue;
  const query = qs({ location });

  Object.keys(query).forEach(paramName => {
    paramValue = query[paramName];

    // it the param has multiple values it is an array
    if (Array.isArray(paramValue)) {
      paramValue.forEach(itemValue => {
        if (paramName !== name || itemValue !== value) {
          querystring += `&${paramName}=${encodeURIComponent(itemValue)}`;
        }
      });

      return;
    }

    // always remove the offset
    if (paramName !== 'offset' && (paramName !== name || paramValue !== value)) {
      querystring += `&${paramName}=${encodeURIComponent(paramValue)}`;
    }
  });

  return location.pathname + querystring.replace('&', '?');
}

export function addQueryParam(history, location, name, value) {
  const url = buildUrlForAddQueryParam(location, name, value);

  history.push(url);
}

export function addUniqueQueryParam(history, location, name, value) {
  const url = buildUrlForAddUniqueQueryParam(location, name, value);

  history.push(url);
}

export function appendUniqueQueryParam(history, location, name, value, initialValue) {
  const url = buildUrlForAppendUniqueQueryParam(location, name, value, initialValue);

  history.push(url);
}

export function removeQueryParam(history, location, name, value) {
  const url = buildUrlForRemoveQueryParam(location, name, value);

  history.push(url);
}

export function truncateQueryParam(history, location, name, value) {
  const url = buildUrlForTruncateQueryParam(location, name, value);

  history.push(url);
}
