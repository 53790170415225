import 'isomorphic-fetch';
import fetchCheck from '../redux/middleware/fetchCheck';
import * as types from './actionTypes';

export const url = '/cms/data/v1/playlists/';

export const credentials = 'include';

export const headers = { Accept: 'application/json', 'Content-Type': 'application/json' };

export const playlistsRequest = () => ({ type: types.PLAYLISTS_REQUEST });

export const playlistsRequestSuccess = result => ({
  type: types.PLAYLISTS_REQUEST_SUCCESS,
  result,
});

export const playlistsRequestFailed = error => ({ type: types.PLAYLISTS_REQUEST_FAILED, error });

export const playlistRequest = () => ({ type: types.PLAYLIST_REQUEST });

export const playlistRequestSuccess = result => ({
  type: types.PLAYLIST_REQUEST_SUCCESS,
  result,
});

export const playlistRequestFailed = error => ({ type: types.PLAYLIST_REQUEST_FAILED, error });

export const clearPlaylistForm = () => ({ type: types.PLAYLIST_FORM_CLEAR });

export const fetchPlaylists = query =>
  fetch(url + (query || ''), { method: 'GET', credentials, headers })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const fetchPlaylist = id =>
  fetch(url + id, { method: 'GET', credentials, headers })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const createPlaylist = playlist =>
  fetch(url, { method: 'POST', credentials, headers, body: JSON.stringify(playlist) })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const updatePlaylist = (id, playlist) =>
  fetch(url + id, { method: 'PUT', credentials, headers, body: JSON.stringify(playlist) })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const deletePlaylist = id =>
  fetch(url + id, { method: 'DELETE', credentials, headers }).then(res => fetchCheck(res));

export const loadPlaylists = query => dispatch => {
  dispatch(playlistsRequest());

  return fetchPlaylists(query).then(
    res => dispatch(playlistsRequestSuccess(res)),
    err => dispatch(playlistsRequestFailed(err)),
  );
};

export const loadPlaylist = id => dispatch => {
  dispatch(playlistRequest());

  return fetchPlaylist(id).then(
    res => dispatch(playlistRequestSuccess(res)),
    err => dispatch(playlistRequestFailed(err)),
  );
};
