import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';

export default class CopyDeviceConfigDialog extends Component {
  getDevices = () => {
    const { devices } = this.props;
    const names = devices.map((item, index) => (
      <li key={index}>
        <code>{item.name}</code> {item.description}
      </li>
    ));

    return <ol>{names}</ol>;
  };

  render() {
    const { show, onHide, onSuccess, devices, copyConfig } = this.props;

    if (!devices || !copyConfig) {
      return null;
    }

    const plural = devices.length > 1 ? 's' : '';

    return (
      <Modal
        data-test="modal-copydevice"
        dialogClassName="device-dialog"
        show={show}
        onHide={onHide}
      >
        <Modal.Header>
          <Modal.Title data-test="modal-copydevice-title">Copy Device Settings</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Copy device settings from <code>{copyConfig.name}</code>{' '}
            <b>{copyConfig.description} </b>
            to {devices.length} Device{plural}:
          </p>
          {this.getDevices()}
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn-cancel" onClick={onHide}>
            Cancel
          </Button>
          <Button
            data-test="modal-copydevice-button-copy"
            className="btn-add"
            bsStyle="primary"
            onClick={onSuccess}
          >
            Copy Device Settings
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

CopyDeviceConfigDialog.propTypes = {
  devices: PropTypes.array,
  copyConfig: PropTypes.object,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};
