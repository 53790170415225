import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, FormGroup, FormControl, ControlLabel, HelpBlock } from 'react-bootstrap';

export default class DuplicateScheduleDialog extends Component {
  getInitialState = () => ({
    name: '',
    error: null,
    showError: false,
  });

  state = this.getInitialState();

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { show } = nextProps;

    if (!show) {
      this.setState(this.getInitialState());
    }
  }

  onChange = event => {
    const { showError } = this.state;

    this.setState({ name: event.target.value });

    if (showError) {
      this.validate(event.target.value);
    }
  };

  duplicate = () => {
    const { onSuccess } = this.props;
    const { name } = this.state;

    if (this.validate()) {
      onSuccess(name);
    }
  };

  errorClass = error => (error ? 'error' : null);

  validate = value => {
    const { name: stateName } = this.state;
    const name = value || stateName;
    let error = null;

    if (!name.trim()) {
      error = 'This field cannot be empty.';
    } else if (this.isNameInUse(name)) {
      error = 'This name is already in use.';
    }

    this.setState({ showError: true, error });

    return !error;
  };

  isNameInUse = name => {
    const { schedules } = this.props;

    return schedules.some(item => {
      const itemName = item.name.toLowerCase().trim();
      const newName = name.toLowerCase().trim();

      return itemName === newName;
    });
  };

  render() {
    const { original, show, onHide } = this.props;
    const { name, error } = this.state;

    return (
      <Modal dialogClassName="schedule-dialog" backdrop="static" show={show} onHide={onHide}>
        <Modal.Header>
          <Modal.Title data-test="modal-duplicateschedule-title">Duplicate Schedule</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Duplicate <b>{original.name}</b>. Enter a name for the new Schedule.
          </p>
          <FormGroup validationState={this.errorClass(error)}>
            <ControlLabel>Name</ControlLabel>
            <FormControl
              data-test="modal-duplicateschedule-input-name"
              name="name"
              placeholder="Enter a name"
              value={name}
              onChange={this.onChange}
              autoFocus
            />
            <HelpBlock>{error}</HelpBlock>
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn-cancel" onClick={onHide}>
            Cancel
          </Button>
          <Button
            data-test="modal-duplicateschedule-button-duplicate"
            className="btn-add"
            bsStyle="primary"
            onClick={this.duplicate}
          >
            Duplicate Schedule
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

DuplicateScheduleDialog.propTypes = {
  schedules: PropTypes.array,
  original: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};
