import 'isomorphic-fetch';
import fetchCheck from '../redux/middleware/fetchCheck';

export const url = '/cms/data/v1/mfa/';

export const options = {
  method: 'POST',
  credentials: 'include',
  headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
};

export const fetchMfa = (path, params) => {
  const opts = params ? { ...options, body: JSON.stringify(params) } : options;

  return fetch(url + path, opts)
    .then(fetchCheck)
    .then(res => res.json());
};

export const mfaLogin = loginCredsAndOptionalToken => fetchMfa('login', loginCredsAndOptionalToken);

export const mfaValidate = token => fetchMfa('validate', token);

export const mfaEnable = optionalLoginCreds => fetchMfa('enable', optionalLoginCreds);

export const mfaDisable = id => fetchMfa(id ? `disable/${id}` : 'disable');
