import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Prompt } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { SOLR_DELAY } from '../../../../constants';
import { load as loadAuth, updateAvatar } from '../../../actions/authActions';
import { addNotification } from '../../../actions/notificationActions';
import { loadAllLocations } from '../../../actions/locationActions';
import { getRoles } from '../../../actions/roleActions';
import {
  getUsersDetails,
  getUser,
  getMe,
  clearUser,
  addUser,
  updateUser,
  updateMe,
  deleteUser,
  uploadAvatar,
} from '../../../actions/userActions';
import Header from '../../../components/Header/Header';
import ActionsMenu from '../../../components/ActionsMenu/ActionsMenu';
import QuickAction from '../../../components/QuickAction/QuickAction';
import UserForm from '../../../components/Users/UserForm';
import LocationSelector from '../../../components/Locations/LocationSelector';
import UploadPrompt from '../../../components/UploadPrompt/UploadPrompt';
import Confirm from '../../../components/Confirm/Confirm';
import MfaModal from '../../../components/Mfa/MfaModal';
import ErrorPage from '../ErrorPage/ErrorPage';
import errorMessage from '../../../utils/errorMessage';

export class UserPage extends Component {
  state = {
    user: null,
    dirty: false,
    showLocationSelector: false,
    showUploadDialog: false,
    showMfa: false,
    location_selector_id: -1,
  };

  componentDidMount() {
    this.loadData();
    document.addEventListener('visibilitychange', this.loadData);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    const {
      match: {
        params: { id: nextId },
      },
      users: { user: nextUser },
    } = nextProps;
    const { user } = this.state;

    if (id !== nextId) {
      this.getUser(nextProps);
    }

    if (!nextUser) {
      return;
    }

    if (!user || user.user_id !== nextUser.user_id) {
      return this.setUser(nextUser);
    }

    const { mfa, mfa_verified } = nextUser;

    if (user.mfa !== mfa || user.mfa_verified !== mfa_verified) {
      this.setUser({ mfa, mfa_verified });
    }
  }

  componentWillUnmount() {
    const { clearUser } = this.props;

    clearUser();
    document.removeEventListener('visibilitychange', this.loadData);
  }

  loadData = () => {
    if (document.visibilityState === 'hidden') {
      return;
    }

    const {
      auth: {
        user: {
          permissions: { Admin },
        },
      },
      getRoles,
      getUsersDetails,
      loadAllLocations,
    } = this.props;

    if (Admin) {
      loadAllLocations();
      getRoles();
      getUsersDetails();
    }

    this.getUser(this.props);
  };

  onClickDelete = () => {
    const confirm = this.refDelete;

    confirm.show('Delete User', 'Are you sure you want to delete this User?');
    confirm.onSuccess(this.delete);
  };

  onSuccessLocationSelector = location => {
    this.editForm({ location_id: location.id });
    this.hideLocationSelector();
  };

  getPath = props => {
    const {
      match: { path },
    } = props || this.props;

    return path.split('/').pop();
  };

  getNewUser = () => ({
    user_id: -1,
    location_id: -1,
    name: '',
    firstname: '',
    lastname: '',
    email: '',
    role: '',
    role_id: -1,
    avatar: '',
    password: '',
    password2: '',
    promptPasswordChange: 0,
  });

  getUser = props => {
    const path = this.getPath(props);

    if (path === 'user') {
      props.getMe();
    }

    if (path === ':id') {
      props.getUser(props.match.params.id);
    }

    if (path === 'new') {
      this.setUser(this.getNewUser());
    }
  };

  setUser = user =>
    this.setState({ user: { ...this.state.user, ...user, password: '', password2: '' } });

  getHeaderActions = () => {
    const { auth, users } = this.props;

    if (!users.user || +auth.user.userid === +users.user.user_id) {
      return [];
    }

    return [
      <QuickAction key="1" toolText="Delete User" onClick={this.onClickDelete} alwaysShow>
        delete
      </QuickAction>,
    ];
  };

  add = () => {
    const { addNotification } = this.props;
    const user = this.buildUserForServer();

    return addUser(user).then(
      () => {
        addNotification({ type: 'success', text: 'User added.' });
        setTimeout(this.goBack, SOLR_DELAY);
      },
      err => addNotification({ type: 'danger', text: errorMessage(err) }),
    );
  };

  update = overrideId => {
    const {
      auth,
      match: { params },
      loadAuth,
      addNotification,
    } = this.props;
    const {
      user: { user_id },
    } = this.state;
    const id = overrideId || params.id;
    const user = this.buildUserForServer();
    const { promptPasswordChange, password } = user;

    if (overrideId && promptPasswordChange && password) {
      user.promptPasswordChange = 0;
    }

    return updateUser(id, user).then(
      () => {
        if (auth.user.userid === user_id) {
          loadAuth();
        }

        addNotification({ type: 'success', text: 'User updated.' });
        setTimeout(this.goBack, SOLR_DELAY);
      },
      err => addNotification({ type: 'danger', text: errorMessage(err) }),
    );
  };

  updateMe = () => {
    const { auth, loadAuth, addNotification } = this.props;

    if (auth.user.permissions.Admin) {
      return this.update(auth.user.userid);
    }

    const user = this.buildUserForServer();
    const { promptPasswordChange, password } = user;

    if (promptPasswordChange === 1 && password !== '') {
      user.promptPasswordChange = 0;
    }

    return updateMe(user).then(
      () => {
        addNotification({ type: 'success', text: 'Account updated.' });
        loadAuth();
        setTimeout(this.goBack, SOLR_DELAY);
      },
      err => addNotification({ type: 'danger', text: errorMessage(err) }),
    );
  };

  uploadAvatar = file => {
    const { auth, updateAvatar, addNotification } = this.props;
    const {
      user: { user_id },
    } = this.state;

    return uploadAvatar(file).then(
      res => {
        addNotification({ type: 'success', text: 'Avatar updated.' });
        this.editForm({ avatar: res.avatar });

        if (auth.user.userid === user_id) {
          updateAvatar(res.avatar);
        }
      },
      err => addNotification({ type: 'danger', text: errorMessage(err) }),
    );
  };

  showUserLocationSelector = () => {
    const { user } = this.state;

    this.setState({
      showLocationSelector: true,
      location_selector_id: user.location_id,
    });
  };

  hideLocationSelector = () =>
    this.setState({
      showLocationSelector: false,
      location_selector_id: -1,
    });

  showUploadDialog = () => this.setState({ showUploadDialog: true });

  hideUploadDialog = () => this.setState({ showUploadDialog: false });

  showMfa = () => this.setState({ showMfa: true });

  hideMfa = () => this.setState({ showMfa: false });

  onUpdateMfa = () => this.getUser(this.props);

  onUpload = file => {
    this.hideUploadDialog();
    this.uploadAvatar(file);
  };

  delete = () => {
    const { users, addNotification } = this.props;

    return deleteUser(users.user.user_id).then(
      () => {
        addNotification({ type: 'success', text: 'User deleted.' });
        setTimeout(this.goBack, SOLR_DELAY);
      },
      err => addNotification({ type: 'danger', text: errorMessage(err) }),
    );
  };

  goBack = () => {
    const { auth, history, previousLocation } = this.props;
    const defaultUrl = auth.user.permissions.Admin ? '/users' : '/';
    const url = previousLocation ? previousLocation.pathname + previousLocation.search : defaultUrl;

    this.setState({ dirty: false }, () => history.push(url));
  };

  editForm = data => {
    const { user } = this.state;

    this.setState({ user: { ...user, ...data }, dirty: true });
  };

  buildUserForServer = () => {
    const { user } = this.state;
    const data = {
      name: user.name,
      firstname: user.firstname,
      lastname: user.lastname,
      email: user.email,
      role: user.role,
      location_id: Number(user.location_id) || -1,
      eula_accepted: user.eula_accepted,
      promptPasswordChange: user.promptPasswordChange,
    };

    if (user.password) {
      data.password = user.password;
    }

    return data;
  };

  render() {
    const {
      match: {
        params: { id },
      },
      auth,
      locations: {
        allLocations: { locations },
      },
      roles: { roles },
      users: { users, error },
    } = this.props;
    const {
      user,
      dirty,
      location_selector_id,
      showLocationSelector,
      showUploadDialog,
      showMfa,
    } = this.state;
    const userLocation = auth.user.user.userLocation || '';

    if (id && error) {
      return <ErrorPage error={error} />;
    }

    if (!user) {
      return null;
    }

    return (
      <div className="page-structure">
        <Helmet title="User" />
        <Header overwrite={{ id: { ...user, name: `${user.firstname} ${user.lastname}` } }} />
        <ActionsMenu actions={this.getHeaderActions()} />
        <div className="page-panels">
          <div className="page-content page-user">
            <Prompt
              when={dirty}
              message="You have unsaved changes. Are you sure you want to leave this page?"
            />
            <UserForm
              auth={auth}
              user={user}
              users={users}
              roles={roles}
              locations={locations}
              path={this.getPath()}
              onClickUserLocation={this.showUserLocationSelector}
              onClickAvatar={this.showUploadDialog}
              onClickMfa={this.showMfa}
              onEdit={this.editForm}
              onAdd={this.add}
              onUpdate={this.update}
              onUpdateMe={this.updateMe}
              onCancel={this.goBack}
              userLocation={userLocation}
            />
          </div>
        </div>
        <LocationSelector
          show={showLocationSelector}
          onHide={this.hideLocationSelector}
          onSuccess={this.onSuccessLocationSelector}
          items={locations}
          currentId={location_selector_id}
        />
        <UploadPrompt
          show={showUploadDialog}
          onHide={this.hideUploadDialog}
          title="Upload Profile Photo"
          accept="image/jpg, image/jpeg, image/png"
          onSuccess={this.onUpload}
        />
        <Confirm
          ref={ref => {
            this.refDelete = ref;
          }}
        />
        <MfaModal
          show={showMfa}
          userId={Number(id)}
          clientMfa={auth.user.client.mfa}
          verified={user.mfa_verified}
          onHide={this.hideMfa}
          onUpdate={this.onUpdateMfa}
          onSuccess={this.hideMfa}
        />
      </div>
    );
  }
}

UserPage.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  previousLocation: PropTypes.object,
  locations: PropTypes.object,
  roles: PropTypes.object,
  users: PropTypes.object.isRequired,
  loadAuth: PropTypes.func.isRequired,
  loadAllLocations: PropTypes.func.isRequired,
  getRoles: PropTypes.func.isRequired,
  getUsersDetails: PropTypes.func.isRequired,
  getUser: PropTypes.func.isRequired,
  getMe: PropTypes.func.isRequired,
  clearUser: PropTypes.func.isRequired,
  addUser: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  updateMe: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired,
  uploadAvatar: PropTypes.func.isRequired,
  updateAvatar: PropTypes.func.isRequired,
  addNotification: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  previousLocation: state.app.previousLocation,
  locations: state.locations,
  roles: state.roles,
  users: state.users,
});
const mapDispatchToProps = {
  loadAuth,
  loadAllLocations,
  getRoles,
  getUsersDetails,
  getUser,
  getMe,
  clearUser,
  addUser,
  updateUser,
  updateMe,
  deleteUser,
  uploadAvatar,
  updateAvatar,
  addNotification,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserPage));
