import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FilterText from './FilterText';
import FilterDropdown from './FilterDropdown';
import FilterTagList from './FilterTagList';
import FilterCheckboxList from './FilterCheckboxList';
import FilterSlider from './FilterSlider';
import FilterDateRange from './FilterDateRange';
import FilterFolderDepth from './FilterFolderDepth';
import FilterDefaultDevice from './FilterDefaultDevice';
import FilterPrestagedDevices from './FilterPrestagedDevices';

export default class Filter extends Component {
  isValidFilter = () => {
    const { type, data, preset } = this.props;
    const filters = ['preset', 'text', 'dropdown', 'checkbox', 'tag', 'slider'];
    const presets = ['daterange', 'depth', 'defaultDevice', 'prestagedDevices'];
    const arrays = ['checkbox', 'tag', 'dropdown'];

    if (!filters.includes(type)) {
      return false;
    }

    if (type === 'text') {
      return true;
    }

    if (type === 'preset') {
      return presets.includes(preset);
    }

    if (!data) {
      return false;
    }

    if (arrays.includes(type)) {
      return Boolean(Array.isArray(data) && data.length);
    }

    if (type === 'slider') {
      return Number.isInteger(data.min) && Number.isInteger(data.max);
    }

    return true;
  };

  renderFilter = () => {
    const { type, name, title, data, preset, unit, placeholder, users, text } = this.props;
    let filter = null;

    switch (type) {
      case 'text':
        filter = <FilterText name={name} title={title} placeholder={placeholder} />;
        break;
      case 'dropdown':
        filter = <FilterDropdown name={name} title={title} data={data} text={text} />;
        break;
      case 'checkbox':
        filter = <FilterCheckboxList name={name} title={title} data={data} users={users} />;
        break;
      case 'tag':
        filter = <FilterTagList name={name} title={title} data={data} />;
        break;
      case 'slider':
        filter = (
          <FilterSlider name={name} title={title} min={data.min} max={data.max} unit={unit} />
        );
        break;
      case 'preset':
        switch (preset) {
          case 'daterange':
            filter = <FilterDateRange name={name} title={title} />;
            break;
          case 'depth':
            filter = <FilterFolderDepth name={name} title={title} />;
            break;
          case 'defaultDevice':
            filter = <FilterDefaultDevice name={name} title={title} data={data} />;
            break;
          case 'prestagedDevices':
            filter = <FilterPrestagedDevices name={name} title={title} data={data} />;
            break;
          default:
        }

        break;
      default:
    }

    return filter;
  };

  render() {
    const isValidFilter = this.isValidFilter();

    if (!isValidFilter) {
      return null;
    }

    const filter = this.renderFilter();

    return <div className="filter-section">{filter}</div>;
  }
}

Filter.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  unit: PropTypes.string,
  placeholder: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  text: PropTypes.object,
  preset: PropTypes.string,
  users: PropTypes.array,
};
