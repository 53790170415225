import 'isomorphic-fetch';
import fetchCheck from '../redux/middleware/fetchCheck';

export const url = '/cms/data/v1/sso/';

export const options = {
  method: 'POST',
  credentials: 'include',
  headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
};

export const fetchSSO = (path, method, params) => {
  options.method = method;
  const opts = params ? { ...options, body: JSON.stringify(params) } : options;

  return fetch(url + path, opts)
    .then(fetchCheck)
    .then(res => res.json());
};

export const ssoLogin = (client, idp, redirect) =>
  fetchSSO('entrypoint', 'POST', { client, idp, redirect });

export const ssoFetchIdps = () => fetchSSO('idp', 'GET');
