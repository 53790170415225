const getAssignmentsForSequence = (sequence, sequences, schedules, playlists, assignments) => {
  if (!sequence || !sequences || !schedules || !playlists || !assignments) {
    return [];
  }

  return assignments
    .map(item => {
      if (item.content_type === 'schedule') {
        const schedule = schedules.find(sch => sch.id === item.content_id);

        if (schedule) {
          schedule.scheduleEntries.forEach(entry => {
            if (entry.sequence_id === +sequence.id) {
              item.signInUse = true;
            }
          });
        }
      }

      if (item.content_type === 'sequence') {
        const sequenceItem = sequences.find(seq => seq.id === item.content_id);

        if (sequenceItem) {
          item.sequenceEntries = sequenceItem.sequenceEntries;
        }
      }

      if (item.content_default_stream_type === 'playlist') {
        const playlist = playlists.find(pl => pl.id === item.content_default_stream_id);

        if (playlist) {
          item.playlistEntries = playlist.playlistEntries;
        }
      }

      return item;
    })
    .filter(
      item =>
        (item.content_type === 'sequence' && item.content_id === +sequence.id) ||
        (item.content_type === 'schedule' && item.signInUse),
    )
    .sort((a, b) => a.name.localeCompare(b.name));
};

export default getAssignmentsForSequence;
