import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { withRouter, Prompt } from 'react-router-dom';
import { SOLR_DELAY } from '../../../../constants';
import { getUsers } from '../../../actions/userActions';
import { getSigns } from '../../../actions/signActions';
import { loadSequences } from '../../../actions/sequenceActions';
import { loadSchedules } from '../../../actions/scheduleActions';
import { loadPlaylists } from '../../../actions/playlistActions';
import { loadCampaigns } from '../../../actions/campaignActions';
import { loadAllAssignments } from '../../../actions/assignmentActions';
import {
  loadFolder,
  loadFolders,
  updateFolder,
  deleteFolder,
  clearFolderForm,
} from '../../../actions/folderActions';
import Header from '../../../components/Header/Header';
import Spinner from '../../../components/Spinner/Spinner';
import FolderForm from '../../../components/Folders/FolderForm';
import Used from '../../../components/Used/Used';
import { addNotification } from '../../../actions/notificationActions';
import Confirm from '../../../components/Confirm/Confirm';
import ErrorPage from '../ErrorPage/ErrorPage';
import ActionsMenu from '../../../components/ActionsMenu/ActionsMenu';
import QuickAction from '../../../components/QuickAction/QuickAction';
import LocationSelector from '../../../components/Locations/LocationSelector';
import errorMessage from '../../../utils/errorMessage';

export class FolderPage extends Component {
  state = {
    folder: null,
    dirty: false,
    showFolderSelector: false,
  };

  componentDidMount() {
    this.loadData();
    document.addEventListener('visibilitychange', this.loadData);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      folders: { folder },
    } = nextProps;
    const { folder: stateFolder } = this.state;

    if (!stateFolder && folder.id) {
      this.setState({ folder });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('visibilitychange', this.loadData);
  }

  loadData = () => {
    if (document.visibilityState === 'hidden') {
      return;
    }

    const {
      match: {
        params: { id },
      },
      getUsers,
      getSigns,
      loadSequences,
      loadSchedules,
      loadPlaylists,
      loadCampaigns,
      loadAllAssignments,
      clearFolderForm,
      loadFolders,
      loadFolder,
    } = this.props;

    getUsers();
    getSigns('?count=100000000');
    loadSequences('?count=100000000');
    loadSchedules('?count=100000000');
    loadPlaylists('?count=100000000');
    loadCampaigns('?count=100000000');
    loadAllAssignments();
    clearFolderForm();
    loadFolders('?count=100000000');
    loadFolder(id);
  };

  goBack = () => {
    const { history, previousLocation } = this.props;
    const url = previousLocation
      ? previousLocation.pathname + previousLocation.search
      : '/content?type=folder&parent=/*';

    this.setState({ dirty: false }, () => history.push(url));
  };

  showFolderSelector = () => this.setState({ showFolderSelector: true });

  hideFolderSelector = () => this.setState({ showFolderSelector: false });

  onChange = (key, value) => {
    const { folder } = this.state;

    this.setState({ folder: { ...folder, [key]: value }, dirty: true });
  };

  move = parent => {
    const {
      match: {
        params: { id },
      },
      folders,
      loadFolder,
      loadFolders,
      addNotification,
    } = this.props;

    this.hideFolderSelector();

    return updateFolder(id, { ...folders.folder, parent: `${parent.parent + parent.name}/` }).then(
      () => {
        addNotification({ type: 'success', text: 'Folder moved.' });
        setTimeout(() => {
          loadFolders('?count=100000000');
          loadFolder(id);
        }, SOLR_DELAY);
      },
      err => addNotification({ type: 'danger', text: errorMessage(err) }),
    );
  };

  update = () => {
    const {
      match: {
        params: { id },
      },
      addNotification,
    } = this.props;
    const { folder } = this.state;

    return updateFolder(id, folder).then(
      () => {
        addNotification({ type: 'success', text: 'Folder updated.' });
        setTimeout(this.goBack, SOLR_DELAY);
      },
      err => addNotification({ type: 'danger', text: errorMessage(err) }),
    );
  };

  delete = () => {
    const {
      match: {
        params: { id },
      },
      addNotification,
    } = this.props;
    const {
      folder: { parent },
    } = this.state;

    return deleteFolder(id, parent).then(
      () => {
        addNotification({ type: 'success', text: 'Folder deleted.' });
        setTimeout(this.goBack, SOLR_DELAY);
      },
      err => addNotification({ type: 'danger', text: errorMessage(err) }),
    );
  };

  onDelete = () => {
    const {
      folders: {
        folder: { usedInSigns },
      },
    } = this.props;
    const confirm = this.refDelete;

    confirm.show(
      'Delete Folder',
      usedInSigns
        ? 'The folder is used in signs and cannot be deleted. Please remove it from the signs first.'
        : 'Are you sure you want to delete this Folder?',
    );

    if (!usedInSigns) {
      confirm.onSuccess(this.delete);
    }
  };

  getHeaderActions = () => {
    const {
      auth: {
        user: {
          permissions: { Writer },
        },
      },
    } = this.props;

    if (!Writer) {
      return [];
    }

    return [
      <QuickAction key="1" toolText="Move Folder" onClick={this.showFolderSelector} alwaysShow>
        subdirectory_arrow_right
      </QuickAction>,
      <QuickAction key="2" toolText="Delete Folder" onClick={this.onDelete} alwaysShow>
        delete
      </QuickAction>,
    ];
  };

  render() {
    const {
      match: {
        params: { id },
      },
      auth,
      users: { users },
      folders: {
        folders: { folders },
        folder,
        loadingFolder,
        error,
      },
      signs: {
        signs: { signs },
      },
      sequences: {
        sequences: { sequences },
      },
      schedules: {
        schedules: { schedules },
      },
      playlists: {
        playlists: { playlists },
      },
      campaigns: {
        campaigns: { campaigns },
      },
      assignments: {
        allAssignments: { assignments },
      },
    } = this.props;
    const { dirty, folder: stateFolder, showFolderSelector } = this.state;

    if (id && error) {
      return <ErrorPage error={error} />;
    }

    return (
      <div className="page-structure">
        <Helmet title="Folder" />
        <Header overwrite={{ id: folder }} />
        <ActionsMenu actions={this.getHeaderActions()} />
        <div className="page-panels">
          <div className="page-content page-folder">
            <Spinner loading={loadingFolder}>
              <Prompt
                when={dirty}
                message="You have unsaved changes. Are you sure you want to leave this page?"
              />
              <FolderForm
                auth={auth}
                users={users}
                folders={folders}
                folder={stateFolder}
                onChange={this.onChange}
                onUpdate={this.update}
                onCancel={this.goBack}
              />
              <Used
                type="asset"
                item={folder}
                signs={signs}
                sequences={sequences}
                schedules={schedules}
                playlists={playlists}
                campaigns={campaigns}
                assignments={assignments}
                auth={auth}
                users={users}
              />
            </Spinner>
            <Confirm
              ref={ref => {
                this.refDelete = ref;
              }}
            />
          </div>
        </div>
        <LocationSelector
          type="folder"
          show={showFolderSelector}
          onHide={this.hideFolderSelector}
          onSuccess={this.move}
          items={folders}
          moving={[folder]}
        />
      </div>
    );
  }
}

FolderPage.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  previousLocation: PropTypes.object,
  users: PropTypes.object.isRequired,
  signs: PropTypes.object.isRequired,
  sequences: PropTypes.object.isRequired,
  schedules: PropTypes.object.isRequired,
  playlists: PropTypes.object.isRequired,
  campaigns: PropTypes.object.isRequired,
  assignments: PropTypes.object.isRequired,
  getUsers: PropTypes.func.isRequired,
  folders: PropTypes.object.isRequired,
  loadFolders: PropTypes.func.isRequired,
  loadFolder: PropTypes.func.isRequired,
  getSigns: PropTypes.func.isRequired,
  loadSequences: PropTypes.func.isRequired,
  loadSchedules: PropTypes.func.isRequired,
  loadPlaylists: PropTypes.func.isRequired,
  loadCampaigns: PropTypes.func.isRequired,
  loadAllAssignments: PropTypes.func.isRequired,
  clearFolderForm: PropTypes.func.isRequired,
  addNotification: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  users: state.users,
  folders: state.folders,
  signs: state.signs,
  sequences: state.sequences,
  schedules: state.schedules,
  playlists: state.playlists,
  campaigns: state.campaigns,
  assignments: state.assignments,
  previousLocation: state.app.previousLocation,
});
const mapDispatchToProps = {
  getUsers,
  loadFolders,
  loadFolder,
  getSigns,
  loadSequences,
  loadSchedules,
  loadPlaylists,
  loadCampaigns,
  loadAllAssignments,
  clearFolderForm,
  addNotification,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FolderPage));
