import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Alert, Panel, ControlLabel, Checkbox } from 'react-bootstrap';
import { getHours, getMinutes, set } from 'date-fns';
import { DeviceType } from './utils/DeviceType';
import ToolTip from '../ToolTipIcon/ToolTip';
import PowerScheduleOnOff from './PowerScheduleOnOff';
import PowerScheduleReboot from './PowerScheduleReboot';

const midnight = set(new Date(), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });

export default class PowerSchedules extends Component {
  state = {
    days: ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'],
    daysFull: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
    onDateDefault: midnight,
    offDateDefault: set(midnight, { hours: 23, minutes: 55 }),
    setAllDays: false,
  };

  // on/off times stored as TIME data type in the db (hh:mm:ss), need to convert the time string to date for react-datepicker
  timeToDate = time =>
    time ? set(midnight, { hours: time.slice(0, 2), minutes: time.slice(3, 5) }) : null;

  // on/off times stored as TIME data type in the db (hh:mm:ss), need to convert the react-datepicker date to time string
  dateToTime = date => {
    if (!date) {
      return null;
    }

    const hours = getHours(date).toString().padStart(2, '0');
    const minutes = getMinutes(date).toString().padStart(2, '0');
    const seconds = '00';

    return `${hours}:${minutes}:${seconds}`;
  };

  isScheduledOnOffDisabled = () => {
    const { disabled } = this.props;
    const isDisabled = disabled || !this.isScheduledOnOffSupportedByDeviceType();

    return isDisabled;
  };

  isScheduledOnOffDaysDisabled = () => {
    const { device } = this.props;
    const isDisabled = this.isScheduledOnOffDisabled() || !device.power_schedule_enabled;

    return isDisabled;
  };

  isSetAllDaysDisabled = () => {
    const { allOnOffTimesShouldBeSet } = this.getScheduledOnOffRulesByDeviceType();
    const isDisabled = this.isScheduledOnOffDaysDisabled() || allOnOffTimesShouldBeSet;

    return isDisabled;
  };

  isScheduledOnOffSupportedByDeviceType = () => {
    const { device_type } = this.props;
    const supportedDeviceTypes = [
      DeviceType.HISENSE_DM,
      DeviceType.LAMASA_DUALSCREEN,
      DeviceType.PHILIPS_3650,
      DeviceType.PHILIPS_4050_ZIP,
      DeviceType.PHILIPS_4050,
      DeviceType.PHILIPS_4051,
      DeviceType.PHILIPS_4150,
      DeviceType.PHILIPS_4550,
      DeviceType.PHILIPS_4650,
      DeviceType.PHILIPS_CRD50,
      DeviceType.REFEE_A55FD,
      DeviceType.REFEE_BA37B,
      DeviceType.S35,
      DeviceType.SAMSUNG_TIZEN_4,
      DeviceType.SAMSUNG_TIZEN_7,
      DeviceType.VESTEL_400VS,
    ];
    const isSupported = supportedDeviceTypes.includes(device_type);

    return isSupported;
  };

  getScheduledOnOffRulesByDeviceType = () => {
    const { device_type } = this.props;
    const availableOnOffRules = {
      allOnOffTimesShouldBeSet: true,
      allSetOnOffTimesShouldBeSame: true,
      atLeastOneOnOffTimesShouldBeSet: true,
      onOffTimePairsBothShouldBeSetOrNotSet: true,
      onTimeShouldBeBeforeOffTime: true,
    };
    const {
      // allOnOffTimesShouldBeSet,
      allSetOnOffTimesShouldBeSame,
      atLeastOneOnOffTimesShouldBeSet,
      onOffTimePairsBothShouldBeSetOrNotSet,
      onTimeShouldBeBeforeOffTime,
    } = availableOnOffRules;

    switch (device_type) {
      case DeviceType.REFEE_A55FD:
      case DeviceType.REFEE_BA37B:
        return {
          atLeastOneOnOffTimesShouldBeSet,
          onOffTimePairsBothShouldBeSetOrNotSet,
          onTimeShouldBeBeforeOffTime,
        };
      case DeviceType.HISENSE_DM:
      case DeviceType.LAMASA_DUALSCREEN:
      case DeviceType.PHILIPS_3650:
      case DeviceType.PHILIPS_4050_ZIP:
      case DeviceType.PHILIPS_4050:
      case DeviceType.PHILIPS_4051:
      case DeviceType.PHILIPS_4150:
      case DeviceType.PHILIPS_4550:
      case DeviceType.PHILIPS_4650:
      case DeviceType.PHILIPS_CRD50:
      case DeviceType.S35:
      case DeviceType.SAMSUNG_TIZEN_4:
      case DeviceType.SAMSUNG_TIZEN_7:
      case DeviceType.VESTEL_400VS:
        return {
          allSetOnOffTimesShouldBeSame,
          atLeastOneOnOffTimesShouldBeSet,
          onOffTimePairsBothShouldBeSetOrNotSet,
          onTimeShouldBeBeforeOffTime,
        };

      default:
        return {};
    }
  };

  getScheduledOnOffRulesTextList = () => {
    const allRulesText = {
      onOffTimePairsBothShouldBeSetOrNotSet: 'Days must be fully set or not set at all.',
      onTimeShouldBeBeforeOffTime: 'ON time must be earlier than OFF time.',
      allSetOnOffTimesShouldBeSame: 'All set days must have the same ON-OFF times.',
      atLeastOneOnOffTimesShouldBeSet: 'Minimum one day must be fully set.',
      allOnOffTimesShouldBeSet: 'All days must be fully set.',
    };
    const rules = Object.keys(this.getScheduledOnOffRulesByDeviceType());

    if (!rules.length) {
      return ' N/A';
    }

    const list = rules.map(r => <li key={r}>{allRulesText[r]}</li>);

    return <ol className="text-left margin-md-vert">{list}</ol>;
  };

  setDefaultExtraOptionsIfSupportedButNotSet = () => {
    const { device, device_type, onChange } = this.props;
    const isPhilips = [
      DeviceType.HISENSE_DM,
      DeviceType.PHILIPS_3650,
      DeviceType.PHILIPS_4050_ZIP,
      DeviceType.PHILIPS_4050,
      DeviceType.PHILIPS_4051,
      DeviceType.PHILIPS_4150,
      DeviceType.PHILIPS_4550,
      DeviceType.PHILIPS_4650,
      DeviceType.PHILIPS_CRD50,
      DeviceType.S35,
    ].includes(device_type);
    // only Philips devices have extra options atm
    const isExtraOptionsSupported = isPhilips;

    if (!isExtraOptionsSupported) {
      return;
    }

    const key1 = device.power_schedule_extra_1_key;
    const val1 = device.power_schedule_extra_1_val;
    const key2 = device.power_schedule_extra_2_key;
    const val2 = device.power_schedule_extra_2_val;
    const key3 = device.power_schedule_extra_3_key;
    const val3 = device.power_schedule_extra_3_val;
    const key4 = device.power_schedule_extra_4_key;
    const val4 = device.power_schedule_extra_4_val;
    const key5 = device.power_schedule_extra_5_key;
    const val5 = device.power_schedule_extra_5_val;
    const isAtLeastOneExtraOptionAlreadySet = Boolean(
      key1 || val1 || key2 || val2 || key3 || val3 || key4 || val4 || key5 || val5,
    );

    // only set default extra options if nothing is set yet
    if (isAtLeastOneExtraOptionAlreadySet) {
      return;
    }

    const extraOptions = {
      philips: {
        key1: 'Power save',
        val1: 1,
        key2: 'Logo',
        val2: 1,
        key3: 'Boot on Source',
        val3: 'Custom',
        key4: 'Custom Source',
        val4: 1,
        key5: 'Force Restart on Bootup',
        val5: 1,
      },
    };
    let options;

    if (isPhilips) {
      options = extraOptions.philips;
    }

    onChange('power_schedule_extra_1_key', options.key1);
    onChange('power_schedule_extra_1_val', options.val1);
    onChange('power_schedule_extra_2_key', options.key2);
    onChange('power_schedule_extra_2_val', options.val2);
    onChange('power_schedule_extra_3_key', options.key3);
    onChange('power_schedule_extra_3_val', options.val3);
    onChange('power_schedule_extra_4_key', options.key4);
    onChange('power_schedule_extra_4_val', options.val4);
    onChange('power_schedule_extra_5_key', options.key5);
    onChange('power_schedule_extra_5_val', options.val5);
  };

  onChangeCheckboxScheduledOnOff = event => {
    const { onChangeCheckbox } = this.props;
    const { checked } = event.target;

    if (checked) {
      this.validateScheduledOnOffDates();
      this.setDefaultExtraOptionsIfSupportedButNotSet();
    }

    onChangeCheckbox(event);
  };

  onChangeCheckboxSetAllDays = () => this.setState(state => ({ setAllDays: !state.setAllDays }));

  // when user enters the time into the react-datepicker input (instead of using the react-datepicker UI to select a time)
  // then only allow 4 numbers to have the hh:mm format as there is no default validation for react-datepicker
  onChangeRawDatePicker = event => {
    const { value } = event.target;
    const time = value.replace(/\D+/g, '').slice(0, 4);
    let hours = time.slice(0, 2);
    let minutes = time.slice(2);

    hours = hours > 23 ? 23 : hours;
    minutes = minutes > 59 ? 59 : minutes;
    const date = set(midnight, { hours, minutes });

    event.target.value = hours;

    if (minutes) {
      event.target.value += `:${minutes}`;
    }

    return date;
  };

  getAllDays = days => Object.keys(days);

  getAnySetDays = days => Object.keys(days).filter(day => days[day].filter(Boolean).length);

  getHalfSetDays = days => Object.keys(days).filter(day => days[day].filter(Boolean).length === 1);

  getFullSetDays = days => Object.keys(days).filter(day => days[day].filter(Boolean).length === 2);

  validateScheduledOnOffDates = () => {
    const { device } = this.props;
    const rules = this.getScheduledOnOffRulesByDeviceType();

    if (!Object.keys(rules).length) {
      return;
    }

    // trigger a validation
    const onDate = this.timeToDate(device.power_schedule_mon_1_on);
    const offDate = this.timeToDate(device.power_schedule_mon_1_off);

    this.validateScheduledOnOffDate(onDate, offDate, 'mon');
  };

  validateScheduledOnOffDate = (onDate, offDate, day, isReset) => {
    const { device, onChange } = this.props;
    const { onDateDefault, offDateDefault, setAllDays } = this.state;
    const {
      allSetOnOffTimesShouldBeSame,
      allOnOffTimesShouldBeSet,
      atLeastOneOnOffTimesShouldBeSet,
      onOffTimePairsBothShouldBeSetOrNotSet,
      onTimeShouldBeBeforeOffTime,
    } = this.getScheduledOnOffRulesByDeviceType();
    const days = {
      mon: [
        this.timeToDate(device.power_schedule_mon_1_on),
        this.timeToDate(device.power_schedule_mon_1_off),
      ],
      tue: [
        this.timeToDate(device.power_schedule_tue_1_on),
        this.timeToDate(device.power_schedule_tue_1_off),
      ],
      wed: [
        this.timeToDate(device.power_schedule_wed_1_on),
        this.timeToDate(device.power_schedule_wed_1_off),
      ],
      thu: [
        this.timeToDate(device.power_schedule_thu_1_on),
        this.timeToDate(device.power_schedule_thu_1_off),
      ],
      fri: [
        this.timeToDate(device.power_schedule_fri_1_on),
        this.timeToDate(device.power_schedule_fri_1_off),
      ],
      sat: [
        this.timeToDate(device.power_schedule_sat_1_on),
        this.timeToDate(device.power_schedule_sat_1_off),
      ],
      sun: [
        this.timeToDate(device.power_schedule_sun_1_on),
        this.timeToDate(device.power_schedule_sun_1_off),
      ],
    };
    const on = onDate;
    const off = offDate;

    days[day] = [on, off];

    if (setAllDays) {
      this.getAllDays(days).forEach(d => {
        days[d] = [on, off];
      });
    }

    if (allOnOffTimesShouldBeSet) {
      this.getAllDays(days).forEach(d => {
        days[d] = [on || onDateDefault, off || offDateDefault];
      });
    }

    if (allSetOnOffTimesShouldBeSame) {
      if (!isReset) {
        this.getAnySetDays(days).forEach(d => {
          days[d] = days[day];
        });
      }
    }

    if (onOffTimePairsBothShouldBeSetOrNotSet) {
      this.getHalfSetDays(days).forEach(d => {
        days[d] = isReset ? [null, null] : [on || onDateDefault, off || offDateDefault];
      });
    }

    if (onTimeShouldBeBeforeOffTime) {
      this.getFullSetDays(days).forEach(d => {
        if (days[d][0] >= days[d][1]) {
          days[d] = [onDateDefault, offDateDefault];
        }
      });
    }

    // this rule should be the last
    if (atLeastOneOnOffTimesShouldBeSet) {
      if (!this.getFullSetDays(days).length) {
        days[day] = [on || onDateDefault, off || offDateDefault];

        // need to re-validate the allSetOnOffTimesShouldBeSame rule after the atLeastOneOnOffTimesShouldBeSet rule
        if (allSetOnOffTimesShouldBeSame) {
          this.getAnySetDays(days).forEach(d => {
            days[d] = days[day];
          });
        }
      }
    }

    // update every modified time
    this.getAllDays(days).forEach(d => {
      const onKey = `power_schedule_${d}_1_on`;
      const offKey = `power_schedule_${d}_1_off`;
      const onVal = this.dateToTime(days[d][0]);
      const offVal = this.dateToTime(days[d][1]);

      if (device[onKey] !== onVal) {
        onChange(onKey, onVal);
      }

      if (device[offKey] !== offVal) {
        onChange(offKey, offVal);
      }
    });
  };

  getNodeScheduledReboot = () => {
    const { device, disabled, onChange, onChangeCheckbox } = this.props;

    return (
      <div>
        <div>
          <ControlLabel>
            <ToolTip toolText="When device will automatically reboot" infoIcon>
              Scheduled Reboot
            </ToolTip>
          </ControlLabel>
          <Checkbox
            data-test="checkbox-restart"
            name="restart_enabled"
            checked={device.restart_enabled}
            onChange={onChangeCheckbox}
            disabled={disabled}
          />
        </div>
        <div className="power-schedules-times">
          <PowerScheduleReboot
            device={device}
            onChange={onChange}
            onChangeRaw={this.onChangeRawDatePicker}
            disabled={!device.restart_enabled}
          />
        </div>
      </div>
    );
  };

  getNodeScheduledOnOff = () => {
    const { device, device_type } = this.props;
    const { setAllDays } = this.state;
    const { allOnOffTimesShouldBeSet } = this.getScheduledOnOffRulesByDeviceType();
    const isCheckedSetAllDays = Boolean(setAllDays || allOnOffTimesShouldBeSet);

    return (
      <div>
        <div>
          <ControlLabel>
            <ToolTip
              toolText={
                <div>
                  {!this.isScheduledOnOffSupportedByDeviceType() && (
                    <Alert bsStyle="warning">
                      Not supported on <b>{device_type}</b> device.
                    </Alert>
                  )}
                  When the device will power on and power off by system (turns device OFF)
                </div>
              }
              infoIcon
            >
              Scheduled On/Off
            </ToolTip>
          </ControlLabel>
          <Checkbox
            data-test="checkbox-onoff"
            name="power_schedule_enabled"
            checked={device.power_schedule_enabled || 0}
            onChange={this.onChangeCheckboxScheduledOnOff}
            disabled={this.isScheduledOnOffDisabled()}
          />
        </div>
        <div>
          <ControlLabel>
            <small>Set all days at once</small>
          </ControlLabel>
          <Checkbox
            checked={isCheckedSetAllDays}
            onChange={this.onChangeCheckboxSetAllDays}
            disabled={this.isSetAllDaysDisabled()}
          />
        </div>
        <div className="power-schedules-times">
          <ControlLabel>
            <ToolTip
              toolText={
                <div>
                  Restrictions on <code>{device_type}</code>:{this.getScheduledOnOffRulesTextList()}
                </div>
              }
              infoIcon
            >
              Days <small>(rules)</small>
            </ToolTip>
          </ControlLabel>
          <div className="time-wrapper">
            <span className="form-control col-header">On</span>
          </div>
          <div className="time-wrapper">
            <span className="form-control col-header">Off</span>
          </div>
        </div>
        {this.getDatePickersByDays()}
      </div>
    );
  };

  getDatePickersByDays = () => {
    const { device } = this.props;
    const { days, daysFull } = this.state;
    const { allOnOffTimesShouldBeSet } = this.getScheduledOnOffRulesByDeviceType();

    return days.map((day, i) => (
      <PowerScheduleOnOff
        key={day}
        disabled={this.isScheduledOnOffDaysDisabled()}
        day={day}
        dayFull={daysFull[i]}
        isClearable={!allOnOffTimesShouldBeSet}
        onDate={this.timeToDate(device[`power_schedule_${day}_1_on`])}
        offDate={this.timeToDate(device[`power_schedule_${day}_1_off`])}
        onChange={this.validateScheduledOnOffDate}
        onChangeRaw={this.onChangeRawDatePicker}
      />
    ));
  };

  render() {
    return (
      <Panel>
        <Panel.Heading>Power Schedules</Panel.Heading>
        <Panel.Body>
          {this.getNodeScheduledReboot()}
          {this.getNodeScheduledOnOff()}
        </Panel.Body>
      </Panel>
    );
  }
}

PowerSchedules.propTypes = {
  device: PropTypes.object.isRequired,
  device_type: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onChangeCheckbox: PropTypes.func.isRequired,
};
