module.exports = {
  isProduction: process.env.NODE_ENV === 'production',
  host: process.env.HOST || 'localhost',
  port: process.env.PORT || 3000,
  apiHost: process.env.APIHOST || 'localhost',
  apiPort: process.env.APIPORT || 3030,
  socketPort: process.env.SOCKETPORT || 9000,
  devtools: true,
  app: {
    title: 'SignStix Director',
    description: 'A Pre release version of SignStix Director v3.',
    version: process.env.npm_package_version,
    eulaVersion: 1,
    head: {
      titleTemplate: 'SignStix Director: %s',
      meta: [
        { name: 'description', content: 'All the modern best practices in one example.' },
        { charset: 'utf-8' },
        { property: 'og:site_name', content: 'SignStix Director' },
        { property: 'og:image', content: 'http://www.signstix.com/media/1357/signstix-logo.png' },
        { property: 'og:locale', content: 'en_UK' },
        { property: 'og:title', content: 'SignStix Director' },
        { property: 'og:description', content: 'SignStix Director is a...' },
        { property: 'og:card', content: 'summary' },
        { property: 'og:image:width', content: '200' },
        { property: 'og:image:height', content: '200' },
      ],
    },
  },
};
