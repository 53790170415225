import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import SSTable from '../SSTable/SSTable';
import SSColumn from '../SSTable/SSColumn';
import ToolTipIcon from '../ToolTipIcon/ToolTipIcon';

export default class DataObjectTable extends Component {
  getName = tier => {
    const { id } = this.props;
    const name = <Link to={`/data-objects/${id}/${tier.id}`}>{tier.name}</Link>;

    return +tier.isbase ? (
      <span>
        <span className="fa fa-key key" title="Base Tier" />
        {name}
      </span>
    ) : (
      name
    );
  };

  getInUse = item => {
    const { inUse, id } = this.props;

    return (
      Array.from(
        new Set(
          inUse
            .filter(inuse => +inuse.doId === +id && +inuse.tierId === +item.id)
            .map(inuse => inuse.signId),
        ),
      ).length || ''
    );
  };

  getLocation = tier => {
    const { id } = this.props;

    return (
      <ToolTipIcon toolText="Show Locations" href={`/data-objects/${id}/${tier.id}/locations`}>
        place
      </ToolTipIcon>
    );
  };

  getEdit = tier => {
    const { id } = this.props;

    return (
      <ToolTipIcon toolText="Show Data" href={`/data-objects/${id}/${tier.id}`}>
        mode_edit
      </ToolTipIcon>
    );
  };

  render() {
    const { data, selected, onClick, onDoubleClick } = this.props;

    if (!data) {
      return null;
    }

    return (
      <SSTable
        className="responsive table-data-object"
        data={data}
        rowHeight={50}
        rowsCount={data.length - 1}
        onSelect={onClick}
        doubleClick={onDoubleClick}
        selected={selected}
        zebra
        selectable
      >
        <SSColumn header="Tier Name" cell={this.getName} />
        <SSColumn header="Used in Signs" cell={this.getInUse} />
        <SSColumn header="Locations" width={5} cell={this.getLocation} />
        <SSColumn header="Data" width={5} cell={this.getEdit} />
      </SSTable>
    );
  }
}

DataObjectTable.propTypes = {
  id: PropTypes.string,
  data: PropTypes.array.isRequired,
  inUse: PropTypes.array.isRequired,
  selected: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  onDoubleClick: PropTypes.func.isRequired,
};
