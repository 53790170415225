const tsRegex = /(.+:[0-9]{2}:[0-9]{2}).+/;

export const getHumanDate = date => {
  try {
    const timestampParsed = date.toString().match(tsRegex);

    return timestampParsed[1];
  } catch {
    return 'Invalid Date';
  }
};
