import * as types from '../actions/actionTypes';

export const initialState = {
  loading: false,
  error: null,
  selected: {},
  urlRulesets: [],
  urlRuleset: {},
  inUse: [],
};

export default function urlRulesets(state = initialState, action = {}) {
  switch (action.type) {
    case types.UR_SELECT:
      return {
        ...state,
        selected: action.ids,
      };
    case types.UR_UNSELECT_ALL:
      return {
        ...state,
        selected: {},
      };
    case types.UR_REQUEST:
      return {
        ...state,
        error: null,
      };
    case types.UR_REQUEST_WITH_LOADING_STATUS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case types.UR_REQUEST_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case types.UR_REQUEST_SUCCESS_URL_RULESETS:
      return {
        ...state,
        loading: false,
        urlRulesets: action.result,
      };
    case types.UR_REQUEST_SUCCESS_URL_RULESET:
      return {
        ...state,
        loading: false,
        urlRuleset: action.result,
      };
    case types.UR_REQUEST_SUCCESS_IN_USE:
      return {
        ...state,
        loading: false,
        inUse: action.result,
      };
    default:
      return state;
  }
}
