import clone from 'clone';
import * as types from '../actions/actionTypes';

export const initialState = {
  open: false,
  applied: {},
};

/* Reducer */
export default function filter(state = initialState, action = {}) {
  const stateClone = clone(state);

  switch (action.type) {
    case types.FILTERS_CLOSE_FILTERS:
      return { ...state, open: false };
    case types.FILTERS_OPEN_FILTERS:
      return { ...state, open: true };
    case types.FILTERS_TOGGLE_FILTERS:
      return { ...state, open: !state.open };
    case types.FILTERS_RESET_FILTERS:
      return { ...state, applied: {} };
    case types.FILTERS_ADD_FILTER:
      return {
        ...state,
        applied: {
          ...state.applied,
          [action.filter.group]: {
            ...state.applied[action.filter.group],
            [action.filter.name]: action.filter,
          },
        },
      };
    case types.FILTERS_ADD_UNIQUE_FILTER:
      return {
        ...state,
        applied: {
          ...state.applied,
          [action.filter.group]: {
            [action.filter.name]: action.filter,
          },
        },
      };
    case types.FILTERS_REMOVE_FILTER:
      // remove the item from the group
      if (
        !stateClone.applied[action.filter.group] ||
        !stateClone.applied[action.filter.group][action.filter.name]
      ) {
        return state;
      }

      delete stateClone.applied[action.filter.group][action.filter.name];

      // if the group still not empty add the remaining items to it
      if (Object.keys(stateClone.applied[action.filter.group]).length) {
        return {
          ...state,
          applied: {
            ...state.applied,
            [action.filter.group]: stateClone.applied[action.filter.group],
          },
        };
      }

      // if the group is empty remove it
      delete stateClone.applied[action.filter.group];

      return {
        ...state,
        applied: stateClone.applied,
      };
    default:
      return state;
  }
}
