import * as types from '../actions/actionTypes';

export const initialState = {
  loadingFolder: null,
  loadingFolders: null,
  error: null,
  folder: {},
  folders: {
    folders: [],
    facet_fields: {},
    stats_fields: {},
  },
};

export default function folders(state = initialState, action = {}) {
  switch (action.type) {
    case types.FOLDERS_REQUEST:
      return {
        ...state,
        loadingFolders: true,
        error: false,
      };
    case types.FOLDERS_REQUEST_SUCCESS:
      return {
        ...state,
        loadingFolders: false,
        folders: action.result,
      };
    case types.FOLDERS_REQUEST_FAILED:
      return {
        ...state,
        loadingFolders: false,
        error: action.error,
      };
    case types.FOLDER_REQUEST:
      return {
        ...state,
        loadingFolder: true,
        error: false,
      };
    case types.FOLDER_REQUEST_SUCCESS:
      return {
        ...state,
        loadingFolder: false,
        folder: action.result,
      };
    case types.FOLDER_REQUEST_FAILED:
      return {
        ...state,
        loadingFolder: false,
        error: action.error,
      };
    case types.FOLDER_FORM_CLEAR:
      return {
        ...state,
        folder: {},
      };
    default:
      return state;
  }
}
