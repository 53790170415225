import * as types from '../actions/actionTypes';

export const initialState = {
  loadingLocation: null,
  loadingLocations: null,
  loadingAllLocations: null,
  location: {},
  locations: {
    locations: [],
    facet_fields: {},
    stats_fields: {},
  },
  allLocations: {
    locations: [],
  },
  selected: {},
  errors: {},
};

export default function locations(state = initialState, action = {}) {
  switch (action.type) {
    case types.LOCATIONS_REQUEST:
      return {
        ...state,
        loadingLocations: true,
        error: false,
      };
    case types.LOCATIONS_REQUEST_SUCCESS:
      return {
        ...state,
        loadingLocations: false,
        locations: action.result,
      };
    case types.LOCATIONS_REQUEST_FAILED:
      return {
        ...state,
        loadingLocations: false,
        error: action.error,
      };
    case types.LOCATION_REQUEST:
      return {
        ...state,
        loadingLocation: true,
        error: false,
      };
    case types.LOCATION_REQUEST_SUCCESS:
      return {
        ...state,
        loadingLocation: false,
        location: action.result,
      };
    case types.LOCATION_REQUEST_FAILED:
      return {
        ...state,
        loadingLocation: false,
        error: action.error,
      };
    case types.LOCATION_FORM_CLEAR:
      return {
        ...state,
        location: {},
      };
    case types.LOCATIONS_SELECT:
      return {
        ...state,
        selected: {
          ...action.ids,
        },
      };
    case types.LOCATIONS_UNSELECT_ALL:
      return {
        ...state,
        selected: {},
      };
    case types.ALL_LOCATIONS_REQUEST:
      return {
        ...state,
        loadingAllLocations: true,
        error: false,
      };
    case types.ALL_LOCATIONS_REQUEST_SUCCESS:
      return {
        ...state,
        loadingAllLocations: false,
        allLocations: action.result,
      };
    case types.ALL_LOCATIONS_REQUEST_FAILED:
      return {
        ...state,
        loadingAllLocations: false,
        error: action.error,
      };
    default:
      return state;
  }
}
