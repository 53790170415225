import * as types from '../actions/actionTypes';

export const initialState = {
  loadingContentItem: null,
  loadingContentItems: null,
  contentItem: {},
  contentItems: {
    contentItems: [],
    facet_fields: {},
    stats_fields: {},
  },
  allContentItems: {
    contentItems: [],
  },
  selected: {},
  selectedFolder: null,
  currentPath: '/',
  errors: {},
};

export default function contentItems(state = initialState, action = {}) {
  switch (action.type) {
    case types.CONTENT_ITEMS_REQUEST:
      return {
        ...state,
        loadingContentItems: true,
        error: false,
      };
    case types.CONTENT_ITEMS_REQUEST_SUCCESS:
      return {
        ...state,
        loadingContentItems: false,
        contentItems: action.result,
      };
    case types.ALL_CONTENT_ITEMS_REQUEST_SUCCESS:
      return {
        ...state,
        allContentItems: action.result,
      };
    case types.CONTENT_ITEMS_REQUEST_FAILED:
      return {
        ...state,
        loadingContentItems: false,
        error: action.error,
      };
    case types.CONTENT_ITEMS_SELECT:
      return {
        ...state,
        selected: {
          ...action.ids,
        },
      };
    case types.CONTENT_ITEMS_UNSELECT_ALL:
      return {
        ...state,
        selected: {},
      };
    default:
      return state;
  }
}
