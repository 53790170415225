import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import async from 'async';
import pluralize from 'pluralize';
import { SOLR_DELAY } from '../../../../constants';
import {
  getDataObjects,
  getInUse,
  uploadCsv,
  uploadDataObject,
  addDataObject,
  deleteDataObject,
  duplicateDataObject,
  select,
  unselectAll,
} from '../../../actions/dataObjectActions';
import { addNotification } from '../../../actions/notificationActions';
import Spinner from '../../../components/Spinner/Spinner';
import QuickAction from '../../../components/QuickAction/QuickAction';
import Confirm from '../../../components/Confirm/Confirm';
import Prompt from '../../../components/Prompt/Prompt';
import DataObjectsTable from '../../../components/DataObjects/DataObjectsTable';
import AddDataObjectDialog from '../../../components/DataObjects/AddDataObjectDialog';
import Header from '../../../components/Header/Header';
import ActionsMenu from '../../../components/ActionsMenu/ActionsMenu';
import ErrorPage from '../ErrorPage/ErrorPage';
import errorMessage from '../../../utils/errorMessage';

export class DataObjectsPage extends Component {
  state = {
    showAddDialog: false,
  };

  componentDidMount() {
    this.loadData();
    document.addEventListener('visibilitychange', this.loadData);
  }

  componentWillUnmount() {
    const { unselectAll } = this.props;

    unselectAll();
    document.removeEventListener('visibilitychange', this.loadData);
  }

  loadData = () => {
    if (document.visibilityState === 'hidden') {
      return;
    }

    const { getDataObjects, getInUse } = this.props;

    getDataObjects();
    getInUse();
  };

  getSelectedItems = () => {
    const {
      dataObjects: { dataObjects, selected },
    } = this.props;

    return Object.keys(selected).map(index => dataObjects[index]);
  };

  getSelectedExceptInUse = () => {
    const {
      dataObjects: { inUse },
    } = this.props;

    return this.getSelectedItems().filter(
      item => !inUse.some(inuse => +inuse.doId === +item.data_object.id),
    );
  };

  upload = dataObject => {
    const { getDataObjects, addNotification } = this.props;

    return uploadDataObject(dataObject).then(
      () => {
        addNotification({ type: 'success', text: 'Data Object uploaded.' });
        setTimeout(getDataObjects, SOLR_DELAY);
      },
      err => addNotification({ type: 'danger', text: errorMessage(err) }),
    );
  };

  add = dataObject => {
    const { getDataObjects, addNotification } = this.props;

    return addDataObject(dataObject).then(
      () => {
        addNotification({ type: 'success', text: 'Data Object added.' });
        setTimeout(getDataObjects, SOLR_DELAY);
      },
      err => addNotification({ type: 'danger', text: errorMessage(err) }),
    );
  };

  delete = () => {
    const items = this.getSelectedExceptInUse();

    async.filter(
      items,
      (item, callback) =>
        deleteDataObject(item.data_object.id).then(
          res => callback(null, res),
          () => callback(null, false),
        ),
      this.deleteCallback,
    );
  };

  deleteCallback = (err, results) => {
    const { getDataObjects, addNotification, unselectAll } = this.props;
    const items = this.getSelectedExceptInUse();
    let failedItems;
    let errorMsg = '';

    // check for errors
    if (items.length === results.length) {
      addNotification({
        type: 'success',
        text: `${pluralize('Data Object', items.length, true)} deleted.`,
      });
    } else {
      // collect the failed request items
      failedItems = items.filter(item => !results.includes(item));
      failedItems.forEach(item => {
        errorMsg += (errorMsg ? ', ' : 'Failed to delete: ') + item.data_object.name;
      });
      addNotification({ type: 'danger', text: errorMsg });
    }

    // if there was at least one successful request, update
    if (results.length) {
      unselectAll();
      setTimeout(getDataObjects, SOLR_DELAY);
    }
  };

  validateDuplicate = name => {
    const { dataObjects, addNotification } = this.props;
    const exist = dataObjects.dataObjects.some(item => item.data_object.name === name);

    if (exist) {
      addNotification({ type: 'danger', text: 'That Data Object name already exists.' });
      setTimeout(this.handleClickDuplicate); // reopen the dialog
    } else {
      this.duplicate(name);
    }
  };

  duplicate = name => {
    const { getDataObjects, addNotification } = this.props;
    const dataObject = this.getSelectedItems()[0];
    const { id } = dataObject.data_object;

    return duplicateDataObject(id, name).then(
      res => {
        if (res.id > -1) {
          addNotification({ type: 'success', text: 'Data Object duplicated.' });
          setTimeout(getDataObjects, SOLR_DELAY);
        } else {
          addNotification({ type: 'danger', text: 'Request failed.' });
        }
      },
      err => addNotification({ type: 'danger', text: errorMessage(err) }),
    );
  };

  successAddDialog = dataObject => {
    this.hideAddDialog();

    if (dataObject.file) {
      this.upload(dataObject);
    } else {
      this.add(dataObject);
    }
  };

  hideAddDialog = () => this.setState({ showAddDialog: false });

  handleClickAdd = () => this.setState({ showAddDialog: true });

  handleClickDelete = () => {
    const confirm = this.refDelete;
    const items = this.getSelectedExceptInUse();
    const names = items.map((item, index) => <li key={index}>{item.data_object.name}</li>);
    const title = `Delete ${pluralize('Data Object', items.length, true)}`;
    const body = (
      <div>
        <p>
          Are you sure you want to delete {pluralize('this', items.length)}{' '}
          {pluralize('Data Object', items.length)}?
        </p>
        <ol>{names}</ol>
      </div>
    );

    confirm.show(title, body);
    confirm.onSuccess(this.delete);

    return { title, body };
  };

  handleClickDuplicate = () => {
    const prompt = this.refDuplicate;
    const dataObject = this.getSelectedItems()[0];
    const title = 'Duplicate Data Object';
    const body = (
      <div>
        Duplicate <b>{dataObject.data_object.name}</b>. Enter a name for the new Data Object.
      </div>
    );

    prompt.show(title, body);
    prompt.onSuccess(name => this.validateDuplicate(name));

    return { title, body };
  };

  handleClickTable = dataObjects => {
    const { select } = this.props;

    select(dataObjects);
  };

  handleDoubleClickTable = (event, dataObject) => {
    const { history } = this.props;

    history.push(`/data-objects/${dataObject.data_object.id}`);
  };

  renderHeaderActions = () => {
    const {
      auth: {
        user: {
          permissions: { Writer },
        },
      },
    } = this.props;

    if (!Writer) {
      return [];
    }

    const selectedUrl = this.getSelectedItems()[0]
      ? `/data-objects/${this.getSelectedItems()[0].data_object.id}`
      : '';
    const removables = this.getSelectedExceptInUse().length;
    const add = (
      <QuickAction key="1" toolText="Add Data Object" onClick={this.handleClickAdd} alwaysShow>
        add_circle
      </QuickAction>
    );
    const remove = (
      <QuickAction
        key="2"
        toolText={pluralize('Delete Data Object', removables)}
        onClick={this.handleClickDelete}
        singular
        multiple
      >
        delete
      </QuickAction>
    );
    const duplicate = (
      <QuickAction
        key="3"
        toolText="Duplicate Data Object"
        onClick={this.handleClickDuplicate}
        singular
      >
        content_copy
      </QuickAction>
    );
    const edit = (
      <QuickAction key="4" toolText="Edit Data Object" href={selectedUrl} singular>
        mode_edit
      </QuickAction>
    );

    return removables ? [add, remove, duplicate, edit] : [add, duplicate, edit];
  };

  render() {
    const {
      dataObjects: { dataObjects, loading, inUse, selected, error },
      addNotification,
    } = this.props;
    const { showAddDialog } = this.state;

    if (error) {
      return <ErrorPage error={error} />;
    }

    return (
      <div className="page-structure">
        <Helmet title="Data Objects" />
        <Header />
        <ActionsMenu
          actions={this.renderHeaderActions()}
          selectedLength={this.getSelectedItems().length}
        />
        <div className="page-panels">
          <div className="page-content page-data-objects">
            <Spinner loading={loading}>
              <DataObjectsTable
                data={dataObjects}
                inUse={inUse}
                selected={selected}
                onClick={this.handleClickTable}
                onDoubleClick={this.handleDoubleClickTable}
              />
            </Spinner>
          </div>
        </div>
        <AddDataObjectDialog
          show={showAddDialog}
          onHide={this.hideAddDialog}
          onSuccess={this.successAddDialog}
          dataObjects={dataObjects}
          uploadCsv={uploadCsv}
          addNotification={addNotification}
        />
        <Confirm
          ref={ref => {
            this.refDelete = ref;
          }}
        />
        <Prompt
          ref={ref => {
            this.refDuplicate = ref;
          }}
        />
      </div>
    );
  }
}

DataObjectsPage.propTypes = {
  history: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  dataObjects: PropTypes.object.isRequired,
  getDataObjects: PropTypes.func.isRequired,
  getInUse: PropTypes.func.isRequired,
  select: PropTypes.func.isRequired,
  unselectAll: PropTypes.func.isRequired,
  addNotification: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  dataObjects: state.dataObjects,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getDataObjects,
      getInUse,
      select,
      unselectAll,
      addNotification,
    },
    dispatch,
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DataObjectsPage));
