import React from 'react';
import PropTypes from 'prop-types';
import { getIdNode, getOnlineStatusIcon, getDownloadStatusIcon } from './DeviceUtils';

const DeviceTitle = ({ users, device }) => (
  <h2 data-test="device-title" className="device-title">
    {device.description}
    <br />
    {getIdNode(device, users)}
    <span>
      {getOnlineStatusIcon(device)}
      {getDownloadStatusIcon(device)}
    </span>
  </h2>
);

DeviceTitle.propTypes = {
  users: PropTypes.array.isRequired,
  device: PropTypes.object.isRequired,
};

export default DeviceTitle;
