import React from 'react';
import PropTypes from 'prop-types';
import {
  getIdNode,
  getOnlineStatusIcon,
  getDownloadStatusIcon,
  getHealthcheckReportIcon,
} from './DeviceUtils';

const DeviceTitle = ({ auth, device, users }) => (
  <h2 data-test="device-title" className="device-title">
    {device.description}
    <br />
    {getIdNode(device, users)}
    <span>
      {getHealthcheckReportIcon(device, auth)}
      {getOnlineStatusIcon(device)}
      {getDownloadStatusIcon(device)}
    </span>
  </h2>
);

DeviceTitle.propTypes = {
  auth: PropTypes.object,
  device: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
};

export default DeviceTitle;
