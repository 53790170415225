import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Panel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import pluralize from 'pluralize';
import SSTable from '../SSTable/SSTable';
import SSColumn from '../SSTable/SSColumn';
import { buildTagList } from '../../utils/tagList';
import { isoDateToString } from '../../utils/formatUnits';
import UserProfile from '../UserProfile/UserProfile';
import AssetImage from '../Assets/AssetImage';

export default class UsedInPlaylistsTable extends Component {
  state = { open: false };

  getHeader = () => {
    const { data } = this.props;
    const { open } = this.state;

    return (
      <div data-test="used-playlists-title" className="toggle" onClick={this.toggle}>
        <i className="material-icons material-caret inline">
          {open ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}
        </i>
        Used in {pluralize('Playlist', data.length, true)}
      </div>
    );
  };

  getThumbnail = item => <AssetImage id={item.id} type="playlist" item={item} />;

  getPending = item => {
    const {
      item: { usedInPlaylists },
    } = this.props;
    const { id, pendingDate = '' } = item;

    return (
      usedInPlaylists.includes(`${id}-0`) && (
        <img src={`/cms/data/v1/playlists/${id}/pending/thumbnail?${pendingDate}`} alt="" />
      )
    );
  };

  getName = item => (
    <div className="item-name">
      <div>
        <Link to={`/playlists/${item.id}`}>{item.name}</Link>
      </div>
      <div className="item-tags">Folder: {item.parent}</div>
      <div className="item-tags">Tags: {buildTagList(item)}</div>
    </div>
  );

  getCreated = item => {
    const { users } = this.props;

    return (
      <div>
        <UserProfile userId={item.createdBy} users={users} />
        <div className="date">{isoDateToString(item.createdDate)}</div>
      </div>
    );
  };

  getModified = item => {
    const { users } = this.props;

    return (
      <div>
        <UserProfile userId={item.modifiedBy} users={users} />
        <div className="date">{isoDateToString(item.modifiedDate)}</div>
      </div>
    );
  };

  toggle = () => {
    const { open } = this.state;

    this.setState({ open: !open });
  };

  render() {
    const { item, data } = this.props;
    const { open } = this.state;

    if (!item || !data) {
      return null;
    }

    return (
      <Panel className="used-in">
        <Panel.Heading>{this.getHeader()}</Panel.Heading>
        <Panel.Body>
          {open && Boolean(data.length) && (
            <SSTable data={data} rowsCount={data.length - 1} zebra>
              <SSColumn width={5} header="" cell={this.getThumbnail} />
              <SSColumn header="Playlist Name" cell={this.getName} />
              <SSColumn header="Created" cell={this.getCreated} />
              <SSColumn header="Modified" cell={this.getModified} />
            </SSTable>
          )}
        </Panel.Body>
      </Panel>
    );
  }
}

UsedInPlaylistsTable.propTypes = {
  item: PropTypes.object,
  data: PropTypes.array,
  auth: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
};
