import 'isomorphic-fetch';
import fetchCheck from '../redux/middleware/fetchCheck';
import * as types from './actionTypes';

export const url = '/cms/data/v1/assignments/';

export const credentials = 'include';

export const headers = { Accept: 'application/json', 'Content-Type': 'application/json' };

export const assignmentsRequest = () => ({ type: types.ASSIGNMENTS_REQUEST });

export const assignmentsRequestSuccess = result => ({
  type: types.ASSIGNMENTS_REQUEST_SUCCESS,
  result,
});

export const assignmentsRequestFailed = error => ({
  type: types.ASSIGNMENTS_REQUEST_FAILED,
  error,
});

export const allAssignmentsRequest = () => ({ type: types.ALL_ASSIGNMENTS_REQUEST });

export const allAssignmentsRequestSuccess = result => ({
  type: types.ALL_ASSIGNMENTS_REQUEST_SUCCESS,
  result,
});

export const allAssignmentsRequestFailed = error => ({
  type: types.ALL_ASSIGNMENTS_REQUEST_FAILED,
  error,
});

export const assignmentsSelect = ids => ({ type: types.ASSIGNMENTS_SELECT, ids });

export const assignmentsUnselectAll = () => ({ type: types.ASSIGNMENTS_UNSELECT_ALL });

export const fetchAssignments = querystring => {
  let modifiedQuerystring;

  if (!querystring) {
    modifiedQuerystring = '?parent=/&sort=type+desc,+nameOrDescription+asc';
  } else {
    modifiedQuerystring = '';

    if (!querystring.includes('sort=')) {
      modifiedQuerystring += '&sort=type+desc,+nameOrDescription+asc';
    }

    if (!querystring.includes('parent=')) {
      modifiedQuerystring += '&parent=/';
    }

    modifiedQuerystring = querystring + modifiedQuerystring;
  }

  return fetch(url + modifiedQuerystring, { method: 'GET', credentials, headers })
    .then(res => fetchCheck(res))
    .then(res => res.json());
};

export const fetchAllAssignments = querystring => {
  let allUrl = `${url}?count=100000000`;

  if (querystring) {
    allUrl += querystring;
  }

  return fetch(allUrl, { method: 'GET', credentials, headers })
    .then(res => fetchCheck(res))
    .then(res => res.json());
};

export const updateAssignment = (deviceId, assignment, callback) => {
  return fetch(`${url}${deviceId}`, {
    method: 'PUT',
    body: JSON.stringify(assignment),
    credentials,
    headers,
  })
    .then(res => fetchCheck(res))
    .then(res => res.json())
    .then(callback);
};

export const createAssignment = (assignment, callback) => {
  return fetch(url, {
    method: 'POST',
    body: JSON.stringify(assignment),
    credentials,
    headers,
  })
    .then(res => fetchCheck(res))
    .then(res => res.json())
    .then(callback);
};

export const deleteAssignment = (deviceId, campaignId) => {
  return fetch(`${url}${deviceId}/campaigns/${campaignId}`, {
    method: 'DELETE',
    credentials,
    headers,
  }).then(res => fetchCheck(res));
};

export const loadAssignments = querystring => {
  return dispatch => {
    dispatch(assignmentsRequest());

    return fetchAssignments(querystring)
      .then(res => dispatch(assignmentsRequestSuccess(res)))
      .catch(err => dispatch(assignmentsRequestFailed(err)));
  };
};

export const loadAllAssignments = querystring => {
  return dispatch => {
    dispatch(allAssignmentsRequest());

    return fetchAllAssignments(querystring)
      .then(res => dispatch(allAssignmentsRequestSuccess(res)))
      .catch(err => dispatch(allAssignmentsRequestFailed(err)));
  };
};
