import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Alert } from 'react-bootstrap';

export default class ClearDataDialog extends Component {
  getInitialState = () => ({ confirm: false });

  state = this.getInitialState();

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { show } = nextProps;

    if (!show) {
      this.setState(this.getInitialState());
    }
  }

  onChangeConfirm = () => {
    const { confirm } = this.state;

    this.setState({ confirm: !confirm });
  };

  getDevices = () => {
    const { devices } = this.props;
    const names = devices.map((item, index) => (
      <li key={index}>
        <code>{item.name}</code> {item.description}
      </li>
    ));

    return <ol>{names}</ol>;
  };

  render() {
    const { show, onHide, onSuccess, devices } = this.props;
    const { confirm } = this.state;
    const plural = devices.length > 1 ? 's' : '';

    return (
      <Modal
        data-test="modal-cleardata"
        dialogClassName="device-dialog"
        backdrop="static"
        show={show}
        onHide={onHide}
      >
        <Modal.Header>
          <Modal.Title data-test="modal-cleardata-title">Clear Signage Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Clear signage data on these Device{plural}:</p>
          {this.getDevices()}
          <Alert bsStyle="warning">
            <label>
              <input
                data-test="modal-cleardata-checkbox-confirm"
                type="checkbox"
                checked={confirm}
                onChange={this.onChangeConfirm}
              />{' '}
              I want to clear signage data on {devices.length} Device{plural}. The device{plural}{' '}
              must download the signage again.
            </label>
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn-cancel" onClick={onHide}>
            Cancel
          </Button>
          <Button
            data-test="modal-cleardata-button-cleardata"
            className="btn-add"
            bsStyle="primary"
            onClick={onSuccess}
            disabled={!confirm}
          >
            Clear Signage Data
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

ClearDataDialog.propTypes = {
  devices: PropTypes.array,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};
