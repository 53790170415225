import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';

/**
 * Prompt Component
 * -----------------------------------------------------
 * Displays a dialog to get input from a user.
 */
export default class Prompt extends Component {
  state = {
    showing: false,
    inputValue: '',
    title: this.props.title,
    callback: () => {},
  };

  onSuccess = callback => {
    this.setState({ callback });
  };

  handleKeyPress = event => {
    if (event.key === 'Enter') {
      this.save();
    }
  };

  save = () => {
    const { inputValue, callback } = this.state;

    if (inputValue.length) {
      callback(inputValue);
      this.hide();
    }
  };

  show = (title, body) => {
    this.setState({ showing: true, inputValue: '' });

    if (title) {
      this.setState({ title });
    }

    if (body) {
      this.setState({ body });
    }
  };

  hide = () => {
    const { title } = this.props;

    this.setState({ showing: false, inputValue: '', title, callback: () => {} });
  };

  render() {
    const { title, body, showing, inputValue } = this.state;

    return (
      <Modal backdrop="static" show={showing} onHide={this.hide}>
        <Modal.Header>
          <Modal.Title data-test="modal-prompt-title">{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {body}
          <div className="form-group" style={{ marginTop: '14px' }}>
            <input
              data-test="modal-prompt-input-name"
              className="form-control"
              value={inputValue}
              onKeyPress={this.handleKeyPress}
              onChange={event => {
                this.setState({ inputValue: event.target.value });
              }}
              autoFocus
            />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={this.hide}>Cancel</Button>
          <Button
            data-test="modal-prompt-button-ok"
            disabled={!inputValue.trim().length}
            onClick={this.save}
            bsStyle="primary"
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

Prompt.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
};
