import React, { Component } from 'react';
import PropTypes from 'prop-types';

/**
 * SSColumn Component
 * -----------------------------------------------------
 * Used to add a column to a SSTable
 * @props {header, cell, width}
 * header - The text for the header cell of the table <th>,
 * cell - The content for the table column <td>,
 * width - The width in % of the table column.
 */
export default class SSColumn extends Component {
  /* Possible not the best solution as we can't get access to props */
  /* We could however pass in it's own props */
  /* A little messy and confussing however this class isn't called directly */
  static renderHeader = (props, index) => {
    const styles = {
      width: props.width ? `${props.width}%` : 'auto',
    };

    return (
      <th
        data-test={`sstable-th-${index + 1}`}
        key={`ss-table-header-cell-${index}`}
        className="psudo-table-th"
        style={styles}
      >
        <span className="psudo-table-column-title">{props.header}</span>
      </th>
    );
  };

  render() {
    /*
     *  Header is a required property
     *  We don't render if it isn't present
     */
    const { header, cell } = this.props;
    let { width } = this.props;

    if (!header) {
      return false;
    }

    /*
     *  Set the width of the element to auto
     *  if no width was passed through
     */
    if (width === undefined) {
      width = 'auto';
    }

    return <div className="ss-column">{cell}</div>;
  }
}

SSColumn.propTypes = {
  header: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.node.isRequired]),
  cell: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.node]),
  width: PropTypes.number,
};
