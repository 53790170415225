const parseDate = (date, time) => {
  const year = date.substr(0, 4);
  const month = date.substr(4, 2) - 1;
  const day = date.substr(6, 2);
  const hour = time.substr(0, 2);
  const minute = time.substr(2, 4);

  return new Date(year, month, day, hour, minute);
};

const getActiveCampaignId = entries => {
  let campaign;

  if (entries) {
    campaign =
      [...entries].reverse().find(item => new Date() >= parseDate(item.date, item.time)) ||
      entries[0];
  }

  return campaign ? campaign.campaignId : 0;
};

export default getActiveCampaignId;
