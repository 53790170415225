import async from 'async';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Alert } from 'react-bootstrap';
import pluralize from 'pluralize';
import { SOLR_DELAY } from '../../../../constants';
import {
  fetchContentItems,
  loadAllContentItems,
  loadContentItems,
  contentItemsSelect,
  contentItemsUnselectAll,
} from '../../../actions/contentItemActions';
import {
  deleteAsset,
  queueUploads,
  uploadAsset,
  uploadRemoveItem,
  updateAsset,
  getAsset,
  getAssets,
  getEncodeTypes,
  getEncodingQueue,
} from '../../../actions/assetActions';
import { deleteSchedule, updateSchedule, loadSchedule } from '../../../actions/scheduleActions';
import {
  deleteSequence,
  updateSequence,
  loadSequences,
  loadSequence,
} from '../../../actions/sequenceActions';
import { deleteSign, updateSign, getSigns, getSign } from '../../../actions/signActions';
import { deleteCampaign, updateCampaign, loadCampaign } from '../../../actions/campaignActions';
import {
  loadPlaylists,
  loadPlaylist,
  updatePlaylist,
  deletePlaylist,
} from '../../../actions/playlistActions';
import { getUsers } from '../../../actions/userActions';
import {
  addFolder,
  loadFolders,
  updateFolder,
  deleteFolder,
  loadFolder,
} from '../../../actions/folderActions';
import { getCampaignSchedule } from '../../../actions/campaignScheduleActions';
import Header from '../../../components/Header/Header';
import AssetsUploads from '../../../components/Assets/AssetsUploads';
import Spinner from '../../../components/Spinner/Spinner';
import UploadPrompt from '../../../components/UploadPrompt/UploadPrompt';
import QuickAction from '../../../components/QuickAction/QuickAction';
import Pagination from '../../Pagination/Pagination';
import Filter from '../../Filter/Filter';
import Filters from '../../Filters/Filters';
import FiltersApplied from '../../FiltersApplied/FiltersApplied';
import PaginationInfo from '../../PaginationInfo/PaginationInfo';
import { addNotification } from '../../../actions/notificationActions';
import Confirm from '../../../components/Confirm/Confirm';
import ContentItemsTable from '../../../components/ContentItems/ContentItemsTable';
import { launchCreator } from '../../../utils/launchCreator';
import ActionsMenu from '../../../components/ActionsMenu/ActionsMenu';
import AddLocationDialog from '../../../components/Locations/AddLocationDialog';
import DeleteConfirmation from '../../../components/ContentItems/DeleteConfirmation';
import LocationSelector from '../../../components/Locations/LocationSelector';
import ErrorPage from '../ErrorPage/ErrorPage';
import errorMessage from '../../../utils/errorMessage';
import { getPermissions } from '../../../utils/getPermissions';
import { addSupportedAttr, isUnixHiddenPath } from '../../../utils/file';
import { getParent, getParentPath } from '../../../utils/parent';

export class ContentsPage extends Component {
  state = {
    showUploadDialog: false,
    showAddFolderDialog: false,
    showFolderSelector: false,
    showDeleteDialog: false,
    deepDelete: false,
  };

  componentDidMount() {
    this.loadData();
    document.addEventListener('visibilitychange', this.loadData);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      location: { search },
      contentItemsUnselectAll,
      loadContentItems,
    } = this.props;
    const {
      location: { search: nextSearch },
    } = nextProps;

    if (search !== nextSearch) {
      contentItemsUnselectAll();
      loadContentItems(nextSearch);
    }
  }

  componentWillUnmount() {
    const { contentItemsUnselectAll } = this.props;

    contentItemsUnselectAll();
    document.removeEventListener('visibilitychange', this.loadData);
  }

  loadData = () => {
    if (document.visibilityState === 'hidden') {
      return;
    }

    const {
      location: { search },
      loadAllContentItems,
      getCampaignSchedule,
      getUsers,
      getAssets,
      loadFolders,
      getSigns,
      loadSequences,
      loadPlaylists,
      loadContentItems,
      getEncodeTypes,
    } = this.props;

    loadAllContentItems();
    getCampaignSchedule();
    getUsers();
    getAssets('?count=100000000');
    loadFolders('?count=100000000');
    getSigns('?count=100000000');
    loadSequences('?count=100000000');
    loadPlaylists('?count=100000000');
    loadContentItems(search);
    getEncodeTypes();
  };

  getSelectedFolders = (data, selected) => {
    if (!selected) {
      return [];
    }

    return data.filter((contentItem, index) => selected[index] && contentItem.type === 'folder');
  };

  getSelectedItemsDescription = () => {
    const {
      contentItems: {
        contentItems: { content_items },
        selected,
      },
    } = this.props;
    let folders = 0;
    let files = 0;
    let text = '';

    content_items.forEach((item, index) => {
      if (selected[index]) {
        if (item.type === 'folder') {
          folders += 1;
        } else {
          files += 1;
        }
      }
    });

    if (folders) {
      text += `${folders} folder`;

      if (folders > 1) {
        text += 's';
      }

      text += ' (and every subfolder and file)';
    }

    if (folders && files) {
      text += ' and ';
    }

    if (files) {
      text += `${files} file`;

      if (files > 1) {
        text += 's';
      }
    }

    return text;
  };

  launchCreator = event => {
    event.preventDefault();
    launchCreator(0, getParent(this.props).id);
  };

  toggleDeepDelete = () => {
    const { deepDelete } = this.state;

    if (deepDelete) {
      this.setState({ deepDelete: false });
    } else {
      this.setState({ deepDelete: true });
    }
  };

  moveItems = parent => {
    const {
      location: { search },
      contentItems: {
        contentItems: { content_items },
        selected,
      },
      addNotification,
      contentItemsUnselectAll,
      loadFolders,
      loadAllContentItems,
      loadContentItems,
    } = this.props;

    this.hideFolderSelector();
    const data = this.sortedData(content_items);
    const holdingMessage = `Moving ${this.selectedLength()} ${pluralize(
      'Item',
      this.selectedLength(),
    )}`;

    addNotification({ type: 'info', text: holdingMessage });

    let errorCount = 0;

    async.eachSeries(
      Object.keys(selected),
      (key, callback) => {
        this.moveItem(data[key], parent).then(
          () => callback(),
          () => {
            errorCount += 1;
            callback();
          },
        );
      },
      () => {
        if (errorCount > 0) {
          const failureMessage = `${errorCount} ${pluralize(
            'Item',
            errorCount,
          )} out of ${this.selectedLength()} failed to move`;

          addNotification({ type: 'error', text: failureMessage });
        } else {
          const successMessage = `${pluralize('Item', this.selectedLength(), true)} moved`;

          addNotification({ type: 'success', text: successMessage });
        }

        contentItemsUnselectAll();
        setTimeout(() => {
          loadFolders('?count=100000000');
          loadAllContentItems();
          loadContentItems(search);
        }, SOLR_DELAY);
      },
    );
  };

  moveItem = (item, parent) => {
    const {
      getAsset,
      loadPlaylist,
      getSign,
      loadSequence,
      loadSchedule,
      loadCampaign,
      loadFolder,
    } = this.props;

    switch (item.type) {
      case 'image':
      case 'video':
      case 'file':
        return getAsset(item.fileId).then(res =>
          updateAsset(item.fileId, { ...res.result, parent: parent.id }),
        );
      case 'playlist':
        return loadPlaylist(item.content_id).then(res =>
          updatePlaylist(item.content_id, { ...res.result, parent: parent.id }),
        );
      case 'sign':
        return getSign(item.content_id).then(res =>
          updateSign(item.content_id, { ...res.result, parent: parent.id }),
        );
      case 'sequence':
        return loadSequence(item.content_id).then(res =>
          updateSequence(item.content_id, { ...res.result, parent: parent.id }),
        );
      case 'schedule':
        return loadSchedule(item.content_id).then(res =>
          updateSchedule(item.content_id, { ...res.result, parent: parent.id }),
        );
      case 'campaign':
        return loadCampaign(item.content_id).then(res =>
          updateCampaign(item.content_id, { ...res.result, parent: parent.id }),
        );
      case 'folder':
        return loadFolder(item.content_id).then(res =>
          updateFolder(item.content_id, {
            ...res.result,
            parent: `${parent.parent + parent.name}/`,
          }),
        );
      default:
        return Promise.reject();
    }
  };

  approveAsset = item => {
    const {
      location: { search },
      getAsset,
      loadContentItems,
    } = this.props;

    getAsset(item.fileId).then(res => {
      res.result.approved = true;
      res.result.parent = getParent(this.props).id;
      updateAsset(item.fileId, res.result);
      setTimeout(() => {
        loadContentItems(search);
      }, SOLR_DELAY);
    });
  };

  uploadAssets = (allFiles, encodeType) => {
    const { addNotification } = this.props;
    const files = this.filterUploadingFiles(allFiles);
    const folders = this.getUploadingFolders(files);
    const conflicts = this.getConflictedUploadingFolders(folders);

    this.hideUploadDialog();

    if (conflicts.length) {
      addNotification({
        type: 'danger',
        text: `Folders exist: ${conflicts.join(', ')}`,
      });

      return;
    }

    this.files = files;
    this.encodeType = encodeType;
    this.addUploadingFolders(folders);
  };

  addUploadingFolders = items => {
    const {
      location: { search },
      loadFolders,
      loadContentItems,
      addNotification,
    } = this.props;
    const parentPath = getParentPath(this.props);

    if (!items.length) {
      loadFolders('?count=100000000');
      loadContentItems(search);
      this.uploadStart();

      return;
    }

    const item = items.shift();
    const parents = item.split('/').filter(item => item);
    const name = parents.pop();
    let parent = parentPath + parents.join('/');

    if (!parent.endsWith('/')) {
      parent += '/';
    }

    return addFolder({ name, parent }).then(
      () => {
        this.addUploadingFolders(items);
        addNotification({ type: 'success', text: `Folder created: ${parent}${name}` });
      },
      err => addNotification({ type: 'danger', text: errorMessage(err) }),
    );
  };

  getUploadingFolders = files => {
    const folders = [];

    files
      .filter(item => item.webkitRelativePath)
      .forEach(item => {
        const parents = item.webkitRelativePath.slice(0, -item.name.length - 1).split('/');
        let fullPath = '';

        parents.forEach(parent => {
          fullPath += `${parent}/`;

          if (!folders.includes(fullPath)) {
            folders.push(fullPath);
          }
        });
      });

    return folders;
  };

  getConflictedUploadingFolders = uploadingFolders => {
    const {
      folders: {
        folders: { folders },
      },
    } = this.props;
    const parentPath = getParentPath(this.props);
    const rootFolders = [];

    uploadingFolders.forEach(item => {
      const folder = item.split('/')[0];

      if (!rootFolders.includes(folder)) {
        rootFolders.push(folder);
      }
    });

    return folders
      .filter(item => item.parent === parentPath && rootFolders.includes(item.name))
      .map(item => item.name);
  };

  filterUploadingFiles = allFiles =>
    addSupportedAttr(allFiles.filter(item => !isUnixHiddenPath(item.webkitRelativePath)));

  uploadStart = () => {
    const { queueUploads } = this.props;

    if (!this.files.length) {
      return;
    }

    const files = this.files.map(file => {
      file.id = Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);
      file.parent =
        getParentPath(this.props) + (file.webkitRelativePath || '').slice(0, -file.name.length);
      file.encodeType = this.encodeType;

      return file;
    });

    window.SignStix.uploadingFiles.push(...files);
    queueUploads(files);
    const limit = Math.min(window.SignStix.uploadingFiles.length, window.SignStix.uploadingLimit);

    while (window.SignStix.uploading < limit) {
      this.uploadNext();
    }
  };

  uploadNext = () => {
    const { uploadAsset } = this.props;

    if (!window.SignStix.uploadingFiles.length) {
      return;
    }

    const file = window.SignStix.uploadingFiles.shift();

    uploadAsset(file, this.uploadNext);
  };

  showUploadDialog = () => this.setState({ showUploadDialog: true });

  hideUploadDialog = () => this.setState({ showUploadDialog: false });

  showAddFolderDialog = () => this.setState({ showAddFolderDialog: true });

  hideAddFolderDialog = () => this.setState({ showAddFolderDialog: false });

  showFolderSelector = () => this.setState({ showFolderSelector: true });

  hideFolderSelector = () => this.setState({ showFolderSelector: false });

  showDeleteDialog = () => this.setState({ showDeleteDialog: true });

  hideDeleteDialog = () => this.setState({ showDeleteDialog: false });

  addFolder = folder => {
    const {
      location: { search },
      addNotification,
      loadFolders,
      loadContentItems,
    } = this.props;

    this.hideAddFolderDialog();
    folder.parent = getParentPath(this.props);
    addFolder(folder).then(
      () => {
        addNotification({ type: 'success', text: 'Folder created.' });
        loadFolders('?count=100000000');
        loadContentItems(search);
      },
      err => addNotification({ type: 'danger', text: errorMessage(err) }),
    );
  };

  selectedFolders = () => {
    const { contentItems } = this.props;

    return this.getSelectedFolders(
      this.sortedData(contentItems.contentItems.content_items),
      contentItems.selected,
    );
  };

  onDelete = () => {
    this.hideDeleteDialog();
    fetchContentItems('?count=100000000').then(res => {
      const itemsForDeletion = this.getItemsForDeletion(res);
      const items = this.getFilteredItemsForDeletion(itemsForDeletion);

      this.showFailedDeleteWarning(items);
      this.deleteItems(items.itemsToDelete);
    });
  };

  getItemsForDeletion = allContentItems => {
    const {
      contentItems: { contentItems, selected },
    } = this.props;
    const { deepDelete } = this.state;
    const items = [];
    const sortedItems = this.sortedData(contentItems.content_items);

    if (deepDelete) {
      Object.keys(selected).forEach(key => {
        if (sortedItems[key].type === 'folder') {
          const folderPath = `${sortedItems[key].parent + sortedItems[key].name}/`;

          allContentItems.content_items.forEach(item => {
            if (item.parent.startsWith(folderPath)) {
              items.push(item);
            }
          });
          // lets not forget to add the folder once we are done with it
          items.push(sortedItems[key]);
        } else {
          items.push(sortedItems[key]);
        }
      });
    } else {
      Object.keys(selected).forEach(key => items.push(sortedItems[key]));
    }

    return items;
  };

  getFilteredItemsForDeletion = items => {
    const { folders, assets, playlists, campaignSchedule } = this.props;
    const itemsToDelete = [];
    const foldersInUse = [];
    const imagesInUse = [];
    const playlistsInUse = [];
    const campaignsInUse = [];
    const videosInUse = [];
    let currItem;
    let used;

    items.forEach(item => {
      used = false;

      if (item.type === 'folder') {
        currItem = folders.folders.folders.find(folder => +folder.id === +item.content_id);

        if (currItem && Boolean(currItem.usedInSigns[0])) {
          foldersInUse.push(currItem);
          used = true;
        }
      } else if (item.type === 'image') {
        currItem = assets.assets.assets.find(asset => asset.id === item.fileId);

        if (currItem && Boolean(currItem.usedInSigns[0])) {
          imagesInUse.push(currItem);
          used = true;
        }
      } else if (item.type === 'video') {
        currItem = assets.assets.assets.find(asset => asset.id === item.fileId);

        if (currItem && Boolean(currItem.usedInPlaylists[0])) {
          videosInUse.push(currItem);
          used = true;
        }
      } else if (item.type === 'playlist') {
        currItem = playlists.playlists.playlists.find(playlist => playlist.id === item.content_id);

        if (currItem && Boolean(currItem.usedInSigns[0])) {
          playlistsInUse.push(currItem);
          used = true;
        }
      } else if (item.type === 'campaign') {
        currItem = campaignSchedule.campaignSchedule.campaignScheduleEntries.find(
          entry => entry.campaignId === item.content_id,
        );

        if (currItem) {
          campaignsInUse.push(currItem);
          used = true;
        }
      }

      if (!used) {
        itemsToDelete.push(item);
      }
    });

    return {
      itemsToDelete,
      foldersInUse,
      imagesInUse,
      playlistsInUse,
      campaignsInUse,
      videosInUse,
    };
  };

  showFailedDeleteWarning = items => {
    const total =
      items.foldersInUse.length +
      items.imagesInUse.length +
      items.playlistsInUse.length +
      items.campaignsInUse.length;

    if (!total) {
      return;
    }

    const body = (
      <div>
        <p>
          {pluralize('items', items.itemsToDelete.length, true)}{' '}
          {pluralize('is', items.itemsToDelete.length)} deleted.
        </p>
        <Alert bsStyle="warning">
          {pluralize('item', total, true)} cannot be deleted because the {pluralize('item', total)}{' '}
          {pluralize('is', total)} in use:
        </Alert>
        <ul>
          {items.foldersInUse.map(item => (
            <li key={item.id}>
              <Link to={`/folders/${item.id}`} target="_blank">
                {item.name}
              </Link>
              <span className="text-light"> [folder]</span> used in{' '}
              {pluralize(
                'sign',
                new Set(item.usedInSigns.map(sign => sign.split('-')[0])).size,
                true,
              )}
            </li>
          ))}
          {items.imagesInUse.map(item => (
            <li key={item.id}>
              <Link to={`/assets/${item.id}`} target="_blank">
                {item.name}
              </Link>
              <span className="text-light"> [image]</span> used in{' '}
              {pluralize(
                'sign',
                new Set(item.usedInSigns.map(sign => sign.split('-')[0])).size,
                true,
              )}
            </li>
          ))}
          {items.playlistsInUse.map(item => (
            <li key={item.id}>
              <Link to={`/playlists/${item.id}`} target="_blank">
                {item.name}
              </Link>
              <span className="text-light"> [playlist]</span> used in{' '}
              {pluralize(
                'sign',
                new Set(item.usedInSigns.map(sign => sign.split('-')[0])).size,
                true,
              )}
            </li>
          ))}
          {items.campaignsInUse.map(item => (
            <li key={item.campaignId}>
              <Link to={`/campaigns/${item.campaignId}`} target="_blank">
                {item.campaignName}
              </Link>
              <span className="text-light"> [campaign]</span> used in{' '}
              <Link to="/campaign-schedule">Campaign Schedule</Link>
            </li>
          ))}
          {items.videosInUse.map(item => (
            <li key={item.id}>
              <Link to={`/assets/${item.id}`} target="_blank">
                {item.name}
              </Link>
              <span className="text-light"> [video]</span> used in{' '}
              {pluralize('playlist', item.usedInPlaylists.length, true)}
            </li>
          ))}
        </ul>
      </div>
    );

    this.refWarning.show('Delete Warning', body);
  };

  deleteItems = contentItems => {
    const {
      location: { search },
      addNotification,
      contentItemsUnselectAll,
      loadFolders,
      loadContentItems,
      loadAllContentItems,
    } = this.props;
    let deleteMessage = '';
    let failureMessage = '';

    async.eachSeries(
      contentItems,
      (contentItem, callback) => {
        deleteMessage = `${contentItem.type}: ${contentItem.name} has been deleted.`;
        failureMessage = `${contentItem.type}: ${contentItem.name} could not be deleted.`;
        this.deleteItem(contentItem).then(
          res => {
            addNotification({ type: 'success', text: deleteMessage });
            callback(null, res);
          },
          () => {
            addNotification({ type: 'danger', text: failureMessage });
            callback(null, false);
          },
        );
      },
      () => {
        loadFolders('?count=100000000');
        loadContentItems(search);
        loadAllContentItems();
        contentItemsUnselectAll();
      },
    );
  };

  deleteItem = item => {
    switch (item.type) {
      case 'image':
      case 'video':
      case 'file':
        return deleteAsset(item.fileId);
      case 'playlist':
        return deletePlaylist(item.content_id);
      case 'sign':
        return deleteSign(item.content_id);
      case 'sequence':
        return deleteSequence(item.content_id);
      case 'schedule':
        return deleteSchedule(item.content_id);
      case 'campaign':
        return deleteCampaign(item.content_id);
      case 'folder':
        return deleteFolder(item.content_id, item.parent);
      default:
        return null;
    }
  };

  handleClickContentsTable = rows => {
    const { contentItemsSelect } = this.props;

    contentItemsSelect(rows);
  };

  sortedData = contentItems => {
    const {
      location: { search },
    } = this.props;
    const newArray = [];

    if (contentItems) {
      if (search === '') {
        for (let i = 0; i < contentItems.length; i += 1) {
          if (contentItems[i].type === 'folder' && contentItems[i].parent === '/') {
            newArray.push(contentItems[i]);
          }
        }

        for (let i = 0; i < contentItems.length; i += 1) {
          if (contentItems[i].type !== 'folder' && contentItems[i].parent === '/') {
            newArray.push(contentItems[i]);
          }
        }
      } else {
        for (let i = 0; i < contentItems.length; i += 1) {
          if (contentItems[i].type === 'folder') {
            newArray.push(contentItems[i]);
          }
        }

        for (let i = 0; i < contentItems.length; i += 1) {
          if (contentItems[i].type !== 'folder') {
            newArray.push(contentItems[i]);
          }
        }
      }
    }

    return newArray;
  };

  mergeData = items => {
    const {
      auth,
      sequences: {
        sequences: { sequences },
      },
      playlists: {
        playlists: { playlists },
      },
    } = this.props;
    const p = getPermissions(auth);

    if (!sequences || !playlists) {
      return items;
    }

    let mergedItems = items.map(item => {
      if (item.type === 'sequence') {
        const sequence = sequences.find(seq => +seq.id === +item.content_id);

        if (sequence) {
          item.sequenceEntries = sequence.sequenceEntries;
        }
      }

      if (item.type === 'playlist') {
        const playlist = playlists.find(pl => +pl.id === +item.content_id);

        if (playlist) {
          item.playlistEntries = playlist.playlistEntries;
        }
      }

      return item;
    });

    mergedItems = mergedItems.filter(item => {
      // exclude some items from Lite tier
      if (!p.atLeastTierStandard && ['folder', 'image', 'sign', 'campaign'].includes(item.type)) {
        return false;
      }

      return true;
    });

    return mergedItems;
  };

  selectedLength = () => {
    const {
      contentItems: { selected },
    } = this.props;

    return Object.keys(selected).length;
  };

  renderFilters = () => {
    const {
      auth,
      users,
      contentItems: {
        contentItems: { facet_fields, stats_fields },
      },
    } = this.props;
    const p = getPermissions(auth);

    if (!Object.keys(facet_fields).length) {
      return null;
    }

    // exclude from Lite tier these types: 'folder', 'image', 'sign', 'campaign' (exclude their value too, the next item in the array)
    // e.g. ['sign',4,'video',4,'campaign',2,'folder',2,'playlist',2,'schedule',1,'sequence',1] => ['video',4,'playlist',2,'schedule',1,'sequence',1]
    if (!p.atLeastTierStandard) {
      const allowedTypes = [];

      for (let i = 0; i < facet_fields.type.length; i += 2) {
        if (!['folder', 'image', 'sign', 'campaign'].includes(facet_fields.type[i])) {
          allowedTypes.push(facet_fields.type[i]);
          allowedTypes.push(facet_fields.type[i + 1]);
        }
      }

      facet_fields.type = allowedTypes;
    }

    const { type, tags, createdBy, modifiedBy } = facet_fields;

    return (
      <Filters>
        <Filter type="text" name="search" title="Search" placeholder="Search contents" />
        <Filter type="preset" preset="depth" name="folderDepth" title="Include subfolders" />
        <Filter type="checkbox" name="type" title="Type" data={type} />
        <Filter type="slider" name="width" title="Width" data={stats_fields.width} unit="px" />
        <Filter type="slider" name="height" title="Height" data={stats_fields.height} unit="px" />
        <Filter
          type="checkbox"
          name="createdBy"
          title="Created by"
          data={createdBy}
          users={users}
        />
        <Filter type="preset" name="createdDate" title="Created date" preset="daterange" />
        <Filter
          type="checkbox"
          name="modifiedBy"
          title="Modified by"
          data={modifiedBy}
          users={users}
        />
        <Filter type="preset" name="modifiedDate" title="Modified date" preset="daterange" />
        <Filter type="tag" name="tags" title="Tags" data={tags} />
      </Filters>
    );
  };

  renderSubheaderActions = () => {
    const { auth } = this.props;
    const p = getPermissions(auth);

    if (!p.writer) {
      return [];
    }

    const actions = [];
    const parent = getParentPath(this.props);
    const query = parent === '/' ? '' : `?parent=${encodeURIComponent(parent)}`;
    const addNew = [
      p.atLeastTierStandard && { title: 'New Folder', onClick: this.showAddFolderDialog },
      p.atLeastTierStandard && { title: 'New Sign', onClick: this.launchCreator },
      { title: 'New Sequence', href: `/sequences/new${query}` },
      { title: 'New Schedule', href: `/schedules/new${query}` },
      { title: 'New Playlist', href: `/playlists/new${query}` },
      p.atLeastTierStandard && { title: 'New Campaign', href: `/campaigns/new${query}` },
    ].filter(Boolean);
    const Add = (
      <QuickAction id="add" key="1" onClick={addNew} alwaysShow>
        add_circle
      </QuickAction>
    );
    const uploadItem = (
      <QuickAction key="3" toolText="Upload Assets" onClick={this.showUploadDialog} alwaysShow>
        file_upload
      </QuickAction>
    );
    const moveToolText = pluralize('Move Item', this.selectedLength(), false);
    const moveItem = p.atLeastTierStandard && (
      <QuickAction
        key="4"
        toolText={moveToolText}
        onClick={this.showFolderSelector}
        singular
        multiple
      >
        subdirectory_arrow_right
      </QuickAction>
    );
    const deleteToolText = pluralize('Delete Item', this.selectedLength(), false);
    const Delete = (
      <QuickAction
        key="5"
        toolText={deleteToolText}
        onClick={this.showDeleteDialog}
        singular
        multiple
      >
        delete
      </QuickAction>
    );

    actions.push(Add, uploadItem, moveItem, Delete);

    return actions.filter(Boolean);
  };

  renderAssetsUploads = () => {
    const { assets, uploadRemoveItem, getEncodingQueue } = this.props;
    const { uploading, encodingQueue } = assets;

    return (
      <AssetsUploads
        uploading={uploading}
        uploadRemoveItem={uploadRemoveItem}
        encodingQueue={encodingQueue}
        getEncodingQueue={getEncodingQueue}
      />
    );
  };

  render() {
    const {
      auth,
      users,
      assets: { encodeTypes },
      folders,
      signs: {
        signs: { signs },
      },
      contentItems: {
        loadingContentItems,
        contentItems: { numFound, content_items },
        allContentItems,
        selected,
        error,
      },
    } = this.props;
    const {
      deepDelete,
      showDeleteDialog,
      showUploadDialog,
      showAddFolderDialog,
      showFolderSelector,
    } = this.state;
    const sortedData = this.sortedData(content_items);
    const foldersBeingMoved = this.getSelectedFolders(sortedData, selected);
    const mergedData = this.mergeData(sortedData);
    const parent = getParentPath(this.props);

    if (error) {
      return <ErrorPage error={error} />;
    }

    return (
      <div className="page-structure">
        <Helmet title="Content" />
        <Header />
        <ActionsMenu
          actions={this.renderSubheaderActions()}
          selectedLength={this.selectedLength()}
          filterButton
        />
        {this.renderAssetsUploads()}
        <div className="page-panels">
          <div className="toolbar">
            <FiltersApplied users={users} />
            <PaginationInfo itemsFound={numFound} />
          </div>
          {this.renderFilters()}
          <div className="page-content page-content-items">
            <Spinner className="content-items-loader" loading={loadingContentItems}>
              {allContentItems && (
                <ContentItemsTable
                  data={mergedData}
                  users={users}
                  selected={selected}
                  onClick={this.handleClickContentsTable}
                  approveAsset={this.approveAsset}
                  auth={auth}
                  folders={folders}
                  signs={signs}
                />
              )}
            </Spinner>
            <Pagination itemsFound={numFound} />
          </div>
        </div>
        <DeleteConfirmation
          show={showDeleteDialog}
          onHide={this.hideDeleteDialog}
          onSuccess={this.onDelete}
          toggleDeepDelete={this.toggleDeepDelete}
          deepDelete={deepDelete}
          itemLength={this.selectedLength()}
          foldersSelected={this.selectedFolders().length}
        />
        <Confirm
          ref={ref => {
            this.refWarning = ref;
          }}
        />
        <UploadPrompt
          show={showUploadDialog}
          onHide={this.hideUploadDialog}
          title="Upload Assets"
          encodeTypes={encodeTypes}
          onSuccess={this.uploadAssets}
          multiple
        />
        <AddLocationDialog
          show={showAddFolderDialog}
          onHide={this.hideAddFolderDialog}
          type="folder"
          onSuccess={this.addFolder}
          locations={folders.folders.folders}
          parent={parent}
        />
        <LocationSelector
          type="folder"
          show={showFolderSelector}
          onHide={this.hideFolderSelector}
          onSuccess={this.moveItems}
          items={folders.folders.folders}
          moving={foldersBeingMoved}
        />
      </div>
    );
  }
}

ContentsPage.propTypes = {
  loadContentItems: PropTypes.func.isRequired,
  getUsers: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  contentItems: PropTypes.object.isRequired,
  allContentItems: PropTypes.object,
  users: PropTypes.array.isRequired,
  contentItemsSelect: PropTypes.func.isRequired,
  contentItemsUnselectAll: PropTypes.func.isRequired,
  addNotification: PropTypes.func.isRequired,
  loadFolder: PropTypes.func.isRequired,
  loadFolders: PropTypes.func.isRequired,
  folders: PropTypes.object.isRequired,
  signs: PropTypes.object.isRequired,
  sequences: PropTypes.object.isRequired,
  playlists: PropTypes.object.isRequired,
  queueUploads: PropTypes.func.isRequired,
  uploadAsset: PropTypes.func.isRequired,
  uploadRemoveItem: PropTypes.func.isRequired,
  loadSchedule: PropTypes.func.isRequired,
  loadSequences: PropTypes.func.isRequired,
  loadSequence: PropTypes.func.isRequired,
  getSigns: PropTypes.func.isRequired,
  getSign: PropTypes.func.isRequired,
  loadCampaign: PropTypes.func.isRequired,
  getAsset: PropTypes.func.isRequired,
  getAssets: PropTypes.func.isRequired,
  loadPlaylists: PropTypes.func.isRequired,
  loadPlaylist: PropTypes.func.isRequired,
  getEncodeTypes: PropTypes.func.isRequired,
  getEncodingQueue: PropTypes.func.isRequired,
  assets: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  getCampaignSchedule: PropTypes.func.isRequired,
  campaignSchedule: PropTypes.object.isRequired,
  loadAllContentItems: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  contentItems: state.contentItems,
  folders: state.folders,
  signs: state.signs,
  sequences: state.sequences,
  playlists: state.playlists,
  users: state.users.users,
  assets: state.assets,
  auth: state.auth,
  campaignSchedule: state.campaignSchedule,
});
const mapDispatchToProps = {
  loadAllContentItems,
  loadContentItems,
  getUsers,
  contentItemsSelect,
  contentItemsUnselectAll,
  loadCampaign,
  addNotification,
  loadFolder,
  loadFolders,
  queueUploads,
  uploadAsset,
  uploadRemoveItem,
  loadSchedule,
  loadSequences,
  loadSequence,
  getSigns,
  getSign,
  getAsset,
  getAssets,
  loadPlaylist,
  loadPlaylists,
  getEncodeTypes,
  getEncodingQueue,
  getCampaignSchedule,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContentsPage));
