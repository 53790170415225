import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import { formatTime, formatBytes } from '../../utils/formatUnits';

export const SLIDER_WIDTH_DEFAULT = 220;

export default class Slider extends Component {
  state = {
    minAct: this.props.minAct,
    maxAct: this.props.maxAct,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { minAct, maxAct } = nextProps;

    this.setState({ minAct, maxAct });
  }

  formatUnit = value => {
    const { unit = '' } = this.props;
    let text;

    switch (unit) {
      case 'time':
        text = formatTime(value, true);
        break;
      case 'byte':
        text = formatBytes(value);
        break;
      default:
        text = `${value}${unit}`;
    }

    return text;
  };

  onChange = ([minAct, maxAct]) => this.setState({ minAct, maxAct });

  getPosition = value => {
    const { minAbs, maxAbs, width } = this.props;
    const silderWidth = width || SLIDER_WIDTH_DEFAULT;

    return ((value - minAbs) / (maxAbs - minAbs)) * silderWidth;
  };

  render = () => {
    const { minAbs, maxAbs, width, onChangeComplete } = this.props;
    const { minAct, maxAct } = this.state;
    // the texts should follow the slider position
    const minText = this.formatUnit(minAct);
    const maxText = this.formatUnit(maxAct);
    const silderWidth = width || SLIDER_WIDTH_DEFAULT;
    const style = {
      marks: { [minAbs]: minText, [maxAbs]: maxText },
      dots: { display: 'none' },
      handle: { border: '1px solid #FF8F00' },
      track: { height: '2px', backgroundColor: '#C5BBB2' },
      rail: { height: '2px', backgroundColor: '#F1F1F1' },
    };

    return (
      <div className="ss-slider" style={{ width: `${silderWidth}px` }}>
        <Range
          min={minAbs}
          max={maxAbs}
          value={[Math.max(minAbs, minAct), Math.min(maxAbs, maxAct)]}
          onChange={this.onChange}
          onAfterChange={onChangeComplete}
          marks={style.marks}
          dotStyle={style.dots}
          handleStyle={[style.handle, style.handle]}
          trackStyle={[style.track]}
          railStyle={style.rail}
        />
      </div>
    );
  };
}

Slider.propTypes = {
  minAbs: PropTypes.number.isRequired,
  maxAbs: PropTypes.number.isRequired,
  minAct: PropTypes.number.isRequired,
  maxAct: PropTypes.number.isRequired,
  unit: PropTypes.string,
  width: PropTypes.number,
  onChangeComplete: PropTypes.func.isRequired,
};
