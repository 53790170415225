import 'isomorphic-fetch';
import fetchCheck from '../redux/middleware/fetchCheck';
import * as types from './actionTypes';

export const url = '/cms/data/v1/sequences/';

export const credentials = 'include';

export const headers = { Accept: 'application/json', 'Content-Type': 'application/json' };

export const sequencesRequest = () => ({ type: types.SEQUENCES_REQUEST });

export const sequencesRequestSuccess = result => ({
  type: types.SEQUENCES_REQUEST_SUCCESS,
  result,
});

export const sequencesRequestFailed = error => ({ type: types.SEQUENCES_REQUEST_FAILED, error });

export const sequenceRequest = () => ({ type: types.SEQUENCE_REQUEST });

export const sequenceRequestSuccess = result => ({
  type: types.SEQUENCE_REQUEST_SUCCESS,
  result,
});

export const sequenceRequestFailed = error => ({ type: types.SEQUENCE_REQUEST_FAILED, error });

export const setForm = sequence => ({ type: types.SEQUENCE_FORM_SET, sequence });

export const editForm = (key, value) => ({ type: types.SEQUENCE_FORM_EDIT, key, value });

export const fetchSequences = query =>
  fetch(url + (query || ''), { method: 'GET', credentials, headers })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const fetchSequence = id =>
  fetch(url + id, { method: 'GET', credentials, headers })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const saveSequence = sequence =>
  fetch(url, { method: 'POST', credentials, headers, body: JSON.stringify(sequence) })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const updateSequence = (id, sequence) =>
  fetch(url + id, { method: 'PUT', credentials, headers, body: JSON.stringify(sequence) })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const deleteSequence = id =>
  fetch(url + id, { method: 'DELETE', credentials, headers }).then(res => fetchCheck(res));

export const loadSequences = query => dispatch => {
  dispatch(sequencesRequest());

  return fetchSequences(query).then(
    res => dispatch(sequencesRequestSuccess(res)),
    err => dispatch(sequencesRequestFailed(err)),
  );
};

export const loadSequence = id => dispatch => {
  dispatch(sequenceRequest());

  return fetchSequence(id).then(
    res => dispatch(sequenceRequestSuccess(res)),
    err => dispatch(sequenceRequestFailed(err)),
  );
};
