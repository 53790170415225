export const checkPathAccess = (userLocation = '', item = '') => {
  let userLocationPath = '';
  let itemLocation = '';

  if (userLocation) {
    userLocationPath = userLocation.endsWith('/') ? userLocation : `${userLocation}/`;
  } else {
    return true;
  }

  if (typeof item === 'string') {
    itemLocation = item.endsWith('/') ? item : `${item}/`;
  } else if (item && item.name && item.parent) {
    const parentPath = item.parent.endsWith('/') ? item.parent : `${item.parent}/`;
    const namePath = item.name.endsWith('/') ? item.name : `${item.name}/`;

    itemLocation = `${parentPath}${namePath}`;
  }

  // remove duplicate slash characters // -> /
  userLocationPath = userLocationPath.replace(/\/+/g, '/');
  itemLocation = itemLocation.replace(/\/+/g, '/');

  return itemLocation.startsWith(userLocationPath);
};

export const checkAllPathsAccess = (userLocation = '', items = []) =>
  items.every(item => checkPathAccess(userLocation, item));
