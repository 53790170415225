import getSignsForAsset from './getSignsForAsset';
import getSequencesForSign from './getSequencesForSign';

const getSequencesForPlaylist = (asset, signs, sequences) => {
  if (!asset || !signs || !sequences) {
    return [];
  }

  const used = sequences.filter(seq =>
    seq.sequenceEntries.find(item => item.playlist_id === asset.id),
  );

  // get the sequences where the playlist is used in a "Video Play Fullscreen" event in a sign
  getSignsForAsset(asset, signs).forEach(sign =>
    getSequencesForSign(sign, sequences).forEach(sequence => {
      if (!used.find(item => item.id === sequence.id)) {
        used.push(sequence);
      }
    }),
  );

  return used.sort((a, b) => a.name.localeCompare(b.name));
};

export default getSequencesForPlaylist;
