import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import SSTable from '../SSTable/SSTable';
import SSColumn from '../SSTable/SSColumn';
import UserProfile from '../UserProfile/UserProfile';
import { buildTagList } from '../../utils/tagList';
import ToolTipIcon from '../ToolTipIcon/ToolTipIcon';
import { isoDateToString } from '../../utils/formatUnits';
import { buildUrlForAddUniqueQueryParam } from '../../utils/updateQueryString';
import qs from '../../utils/qs';
import { checkPathAccess } from '../../utils/checkPathAccess';

export class LocationsTable extends Component {
  getIcon = location => {
    const { userLocation } = this.props;
    const isAccessAllowed = checkPathAccess(userLocation, location);

    return isAccessAllowed ? (
      <i className="material-icons">public</i>
    ) : (
      <i className="material-icons restricted">vpn_locked</i>
    );
  };

  getName = location => (
    <div className="item-name">
      <p>
        <Link to={this.getUrl(location)}>{location.name}</Link>
      </p>
      <div className="item-tags">Location: {location.parent}</div>
      <div className="item-tags">Tags: {buildTagList(location)}</div>
    </div>
  );

  getDescription = location => location.description;

  getCreator = location => {
    const { users } = this.props;

    return (
      <div className="created-badge">
        <UserProfile userId={location.createdBy} users={users} />
        <p className="date">on {isoDateToString(location.createdDate)}</p>
      </div>
    );
  };

  getModifier = location => {
    const { users } = this.props;

    return (
      <div className="modified-badge">
        <UserProfile userId={location.modifiedBy} users={users} />
        <p className="date">on {isoDateToString(location.modifiedDate)}</p>
      </div>
    );
  };

  getUrl = item => {
    const { location } = this.props;
    const sub = (qs(this.props).parent || '').endsWith('*') ? '*' : '';
    const parent = `${item.parent + item.name}/${sub}`;

    return buildUrlForAddUniqueQueryParam(location, 'parent', parent);
  };

  onDoubleClick = (event, item) => {
    const { history } = this.props;

    history.push(this.getUrl(item));
  };

  editButton = location => {
    const { userLocation } = this.props;
    const isAccessAllowed = checkPathAccess(userLocation, location);

    if (isAccessAllowed) {
      return (
        <ToolTipIcon toolText={`Edit ${location.name}`} href={`/locations/${location.id}`}>
          mode_edit
        </ToolTipIcon>
      );
    }

    return (
      <ToolTipIcon className="restricted" toolText={`Access Restricted to ${location.name}`}>
        block
      </ToolTipIcon>
    );
  };

  nextIcon = location => (
    <ToolTipIcon toolText="Open" href={this.getUrl(location)}>
      <i className="material-icons">keyboard_arrow_right</i>
    </ToolTipIcon>
  );

  isDisabled = () => {
    const { userLocation } = this.props;

    if (userLocation) {
      return item => !checkPathAccess(userLocation, item);
    }

    return () => false;
  };

  render() {
    const { data, selected, onClick } = this.props;

    if (!data) {
      return null;
    }

    return (
      <SSTable
        className="responsive table-locations"
        data={data}
        rowHeight={90}
        rowsCount={data.length - 1}
        onSelect={onClick}
        doubleClick={this.onDoubleClick}
        selected={selected}
        selectable
        zebra
        isDisabled={this.isDisabled()}
      >
        <SSColumn width={1} cell={this.getIcon} header="" />
        <SSColumn width={10} cell={this.getName} header="" />
        <SSColumn width={7} cell={this.getDescription} header="Description" />
        <SSColumn width={5} cell={this.getCreator} header="Created" />
        <SSColumn width={5} cell={this.getModifier} header="Modified" />
        <SSColumn width={2} cell={this.editButton} header="Edit" />
        <SSColumn width={2} cell={this.nextIcon} header="" />
      </SSTable>
    );
  }
}

LocationsTable.propTypes = {
  data: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
  selected: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  userLocation: PropTypes.string.isRequired,
};

export default withRouter(LocationsTable);
