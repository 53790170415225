import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import SSTable from '../SSTable/SSTable';
import SSColumn from '../SSTable/SSColumn';
import ToolTipIcon from '../ToolTipIcon/ToolTipIcon';

export default class FontsTable extends Component {
  getName = item => <Link to={`/fonts/${item.id}`}>{item.name}</Link>;

  getPreview = item => (
    <div className={`font font-${item.id}`}>The quick brown fox jumps over the lazy dog</div>
  );

  getDefault = item => {
    const {
      auth: {
        user: { defaultClientFont },
      },
    } = this.props;

    return defaultClientFont === item.filename ? (
      <i className="material-icons">check_circle</i>
    ) : null;
  };

  getFiles = item => {
    const files = [];

    if (item.ttf_url && item.ttf_url.toLowerCase().endsWith('ttf')) {
      files.push('ttf');
    }

    if (item.ttf_url && item.ttf_url.toLowerCase().endsWith('otf')) {
      files.push('otf');
    }

    if (item.woff_url) {
      files.push('woff');
    }

    if (item.woff2_url) {
      files.push('woff2');
    }

    return (
      <div>
        <div>{item.default ? 'base' : 'custom'}</div>
        <div className="type">{files.join(', ')}</div>
      </div>
    );
  };

  getEdit = item => (
    <ToolTipIcon toolText="Edit Font">
      <Link to={`/fonts/${item.id}`}>mode_edit</Link>
    </ToolTipIcon>
  );

  render() {
    const { data, selected, onClick, onDoubleClick } = this.props;

    return (
      <SSTable
        className="responsive table-fonts"
        data={data}
        rowHeight={50}
        rowsCount={data.length - 1}
        doubleClick={onDoubleClick}
        onSelect={onClick}
        selected={selected}
        selectable
        zebra
      >
        <SSColumn header="Name" cell={this.getName} />
        <SSColumn header="Preview" cell={this.getPreview} />
        <SSColumn width={5} header="Default Font" cell={this.getDefault} />
        <SSColumn width={5} header="Type" cell={this.getFiles} />
        <SSColumn width={5} header="Edit" cell={this.getEdit} />
      </SSTable>
    );
  }
}

FontsTable.propTypes = {
  data: PropTypes.array,
  auth: PropTypes.object.isRequired,
  selected: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  onDoubleClick: PropTypes.func.isRequired,
};
