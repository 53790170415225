import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Checkbox } from 'react-bootstrap';
import { isQueryParam, addUniqueQueryParam, removeQueryParam } from '../../utils/updateQueryString';
import { addUniqueFilter } from '../../actions/filterActions';

export class FilterDefaultDevice extends Component {
  componentDidMount() {
    this.setFilterFromQuery(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setFilterFromQuery(nextProps);
  }

  setFilterFromQuery = props => {
    const { location, name, title, addUniqueFilter } = props;
    const checked = isQueryParam(location, 'is_default', '1');

    if (checked) {
      addUniqueFilter({ group: name, name: '1', query: '1', text: '', title });
    }
  };

  handleChange = () => {
    const { history, location } = this.props;
    const checked = isQueryParam(location, 'is_default', '1');

    if (checked) {
      removeQueryParam(history, location, 'is_default', '1');
    } else {
      addUniqueQueryParam(history, location, 'is_default', '1');
    }
  };

  render() {
    const { location, title, data = [] } = this.props;
    const checked = isQueryParam(location, 'is_default', '1');
    // is_default: ["0", 10, "1", 1] // 10 non-default, 1 default
    const count = data[3] || 0;

    return (
      <div className="filter-checkboxlist">
        <Checkbox data-test="checkbox-default" checked={checked} onChange={this.handleChange}>
          {title} <span className="total">{count}</span>
        </Checkbox>
      </div>
    );
  }
}

FilterDefaultDevice.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  data: PropTypes.array,
  addUniqueFilter: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  addUniqueFilter,
};

export default withRouter(connect(null, mapDispatchToProps)(FilterDefaultDevice));
