import getSignsForAsset from './getSignsForAsset';
import getSequencesForSign from './getSequencesForSign';

const getSequencesForAsset = (asset, signs, sequences) => {
  if (!asset || !signs || !sequences) {
    return [];
  }

  const used = [];

  getSignsForAsset(asset, signs).forEach(sign =>
    getSequencesForSign(sign, sequences).forEach(sequence => {
      if (!used.find(item => item.id === sequence.id)) {
        used.push(sequence);
      }
    }),
  );

  return used.sort((a, b) => a.name.localeCompare(b.name));
};

export default getSequencesForAsset;
