import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { ProgressBar } from 'react-bootstrap';
import getActiveCampaignId from '../CampaignSchedule/utils/getActiveCampaignId';
import UserBar from '../UserBar/UserBar';
import NavigationSection from './NavigationSection';
import { getPermissions } from '../../utils/getPermissions';
import { closeNav, openNav } from '../../utils/navigationToggle';
import { formatBytes } from '../../utils/formatUnits';
import config from '../../config';

export default class Navigation extends Component {
  state = {
    content: { open: false, class: 'group closed' },
    assignments: { open: false, class: 'group closed' },
    modules: { open: false, class: 'group closed' },
    admin: { open: false, class: 'group closed' },
    help: { open: false, class: 'group closed' },
  };

  componentDidMount() {
    if (window) {
      this.onResize();
      window.addEventListener('resize', this.onResize);
    }
  }

  componentWillUnmount() {
    if (window) {
      window.removeEventListener('resize', this.onResize);
    }
  }

  onResize = () => {
    const smallDesktopBreakpoint = 1024;
    const isSmallView = window.innerWidth < smallDesktopBreakpoint;

    if (process.env.NODE_ENV !== 'test') {
      // this piece of code is running during the loading of the application and breaks the tests
      // so this will make it only run when it is not in the test environment
      if (isSmallView) {
        closeNav();
      } else {
        openNav();
      }
    }
  };

  getActiveCampaignUrl = locationSearch => {
    const {
      campaignSchedule: {
        campaignSchedule: { campaignScheduleEntries },
      },
    } = this.props;
    const id = getActiveCampaignId(campaignScheduleEntries);

    return id ? `/campaigns/${id}/assignments${locationSearch}` : '';
  };

  getUploadNotification = () => {
    const { uploading } = this.props;

    if (!uploading.length) {
      return null;
    }

    let loaded = 0;
    let size = 0;
    let errors = 0;

    uploading.forEach(item => {
      size += item.size;

      if (item.error) {
        errors += 1;
      } else {
        loaded += item.loaded || 0;
      }
    });
    const progress = Math.min(100, (loaded / size) * 100);
    let color = 'info';

    if (progress === 100) {
      color = 'success';
    }

    if (errors) {
      color = 'danger';
    }

    return (
      <div className="uploads">
        <NavLink activeClassName="active" className="uploads-title" to="/content">
          <i className="nav-group-icon material-icons inline color-lime-500">file_upload</i>
          Uploading Assets {Boolean(errors) && <span className="text-danger">{errors} failed</span>}
        </NavLink>
        <span className="info">{progress.toFixed('2')}%</span>
        <span className="info">
          {uploading.filter(item => item.loaded >= item.size && !item.error).length} /{' '}
          {uploading.length} Files
        </span>
        <span className="info">
          {formatBytes(Math.min(loaded, size))} / {formatBytes(size)}
        </span>
        <ProgressBar bsStyle={color} now={progress} />
      </div>
    );
  };

  openCloseMenu = type => {
    const { state } = this;

    if (state[type].open) {
      state[type].open = false;
      state[type].class = 'group closed';
    } else {
      state[type].open = true;
      state[type].class = 'group open';
    }

    this.setState({ state });
  };

  getLinkProps = url => ({
    activeClassName: 'active',
    isActive: (match, location) => url === location.pathname + location.search,
    to: url,
  });

  getLocationSearch = userLocation =>
    userLocation ? `?parent=${encodeURIComponent(userLocation)}` : '';

  render() {
    const { user, uploading, deployer, showNotificationHistory } = this.props;
    const { content, assignments, modules, admin, help } = this.state;
    const locationSearch = this.getLocationSearch(user.user.userLocation);
    const currentAssignment = this.getActiveCampaignUrl(locationSearch);
    const p = getPermissions({ user });

    return (
      <nav className="navigation-open">
        <div className="navigation-wrapper">
          <UserBar
            user={user}
            deployer={deployer}
            showNotificationHistory={showNotificationHistory}
          />
          <ul className="nav nav-sidebar">
            {p.landingpage && (
              <NavigationSection iconName="home" title="Home" url="/home" cssClass="group closed" />
            )}

            {p.contentViewer && (
              <NavigationSection
                iconName="content_copy"
                title="Content"
                url="/content"
                handleClick={() => this.openCloseMenu('content')}
                cssClass={content.class}
                openClosed={content.open}
              >
                {p.atLeastTierStandard && (
                  <li data-test="side-nav-content-home-folder">
                    <NavLink {...this.getLinkProps('/content')}>Home Folder</NavLink>
                  </li>
                )}
                <li data-test="side-nav-content-search-recent">
                  <NavLink
                    {...this.getLinkProps(
                      '/content?parent=/*&modifiedDate=%5BNOW-7DAY%20TO%20NOW%5D&sort=modifiedDate+desc',
                    )}
                  >
                    Recent
                  </NavLink>
                </li>
                {p.atLeastTierStandard && (
                  <li data-test="side-nav-content-search-images">
                    <NavLink {...this.getLinkProps('/content?type=image&parent=/*')}>
                      Images
                    </NavLink>
                  </li>
                )}
                <li data-test="side-nav-content-search-videos">
                  <NavLink {...this.getLinkProps('/content?type=video&parent=/*')}>Videos</NavLink>
                </li>
                {p.atLeastTierStandard && (
                  <li data-test="side-nav-content-search-signs">
                    <NavLink {...this.getLinkProps('/content?type=sign&parent=/*')}>Signs</NavLink>
                  </li>
                )}
                <li data-test="side-nav-content-search-sequences">
                  <NavLink {...this.getLinkProps('/content?type=sequence&parent=/*')}>
                    Sequences
                  </NavLink>
                </li>
                <li data-test="side-nav-content-search-schedules">
                  <NavLink {...this.getLinkProps('/content?type=schedule&parent=/*')}>
                    Schedules
                  </NavLink>
                </li>
                <li data-test="side-nav-content-search-playlists">
                  <NavLink {...this.getLinkProps('/content?type=playlist&parent=/*')}>
                    Playlists
                  </NavLink>
                </li>
                {p.atLeastTierStandard && (
                  <li data-test="side-nav-content-search-campaigns">
                    <NavLink {...this.getLinkProps('/content?type=campaign&parent=/*')}>
                      Campaigns
                    </NavLink>
                  </li>
                )}
              </NavigationSection>
            )}

            {p.deviceViewer && (
              <NavigationSection
                iconName="devices"
                title="Devices"
                url="/devices"
                cssClass="group closed"
              />
            )}

            {p.contentViewer && (
              <NavigationSection
                iconName="public"
                title="Assignments"
                url={currentAssignment}
                handleClick={() => this.openCloseMenu('assignments')}
                cssClass={assignments.class}
                openClosed={assignments.open}
              >
                {currentAssignment && (
                  <li data-test="side-nav-assignments-active-campaign">
                    <NavLink activeClassName="active" to={currentAssignment}>
                      Active Campaign
                    </NavLink>
                  </li>
                )}
                {p.atLeastTierStandard && (
                  <li data-test="side-nav-assignments-campaign-schedule">
                    <NavLink activeClassName="active" to="/campaign-schedule">
                      Campaign Schedule
                    </NavLink>
                  </li>
                )}
              </NavigationSection>
            )}

            {p.contentViewer && p.deviceViewer && (
              <NavigationSection
                iconName="dashboard"
                title="Dashboard"
                url="/dashboard?prestaged=0"
                cssClass="group closed"
              />
            )}

            {p.atLeastTierStandard && p.contentViewer && p.hasModules && (
              <NavigationSection
                iconName="device_hub"
                title="Modules"
                handleClick={() => this.openCloseMenu('modules')}
                cssClass={modules.class}
                openClosed={modules.open}
              >
                <li data-test="side-nav-modules-fonts">
                  <NavLink activeClassName="active" to="/fonts">
                    Fonts
                  </NavLink>
                </li>
                {p.moduleDataObject && (
                  <li data-test="side-nav-modules-data-objects">
                    <NavLink activeClassName="active" to="/data-objects">
                      Data Objects
                    </NavLink>
                  </li>
                )}
                {p.moduleUrlRuleset && (
                  <li data-test="side-nav-modules-url-rulesets">
                    <NavLink activeClassName="active" to="/url-rulesets">
                      URL Rulesets
                    </NavLink>
                  </li>
                )}
              </NavigationSection>
            )}

            {p.atLeastTierStandardOrSuperUser && p.admin && (
              <NavigationSection
                iconName="settings"
                title="Admin"
                handleClick={() => this.openCloseMenu('admin')}
                cssClass={admin.class}
                openClosed={admin.open}
              >
                <li data-test="side-nav-admin-location-management">
                  <NavLink activeClassName="active" to={`/locations${locationSearch}`}>
                    Locations
                  </NavLink>
                </li>
                <li data-test="side-nav-admin-user-management">
                  <NavLink activeClassName="active" to="/users">
                    Users
                  </NavLink>
                </li>
                {p.atLeastTierStandard && (
                  <li data-test="side-nav-admin-role-management">
                    <NavLink activeClassName="active" to="/roles">
                      Roles
                    </NavLink>
                  </li>
                )}
              </NavigationSection>
            )}

            <NavigationSection
              iconName="help"
              title="Help"
              handleClick={() => this.openCloseMenu('help')}
              cssClass={help.class}
              openClosed={help.open}
            >
              <li data-test="side-nav-admin-help-support">
                <a target="_blank" rel="noopener noreferrer" href="http://www.signstix.com/support">
                  Support
                </a>
              </li>
              <li data-test="side-nav-admin-help-tutorials">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://www.signstix.com/support/videos/"
                >
                  Tutorials
                </a>
              </li>
            </NavigationSection>
          </ul>
        </div>
        <div className="navigation-footer">
          {Boolean(uploading.length) && this.getUploadNotification()}
          {!uploading.length && (
            <div className="application-logo">
              <img
                className="logo"
                alt="SignStix"
                src="/assets/images/logo/signstix_landscape_orange.svg"
              />
              <span className="app-version small">
                {p.tierText} {config.app.version}
              </span>
            </div>
          )}
        </div>
      </nav>
    );
  }
}

Navigation.propTypes = {
  user: PropTypes.object,
  uploading: PropTypes.array.isRequired,
  campaignSchedule: PropTypes.object.isRequired,
  deployer: PropTypes.func.isRequired,
  showNotificationHistory: PropTypes.func.isRequired,
};
