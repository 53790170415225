import 'isomorphic-fetch';
import fetchCheck from '../redux/middleware/fetchCheck';
import * as types from './actionTypes';

export const contentItemsRequest = () => ({ type: types.CONTENT_ITEMS_REQUEST });

export const contentItemsRequestSuccess = result => ({
  type: types.CONTENT_ITEMS_REQUEST_SUCCESS,
  result,
});

export const allContentItemsRequestSuccess = result => ({
  type: types.ALL_CONTENT_ITEMS_REQUEST_SUCCESS,
  result,
});

export const contentItemsRequestFailed = error => ({
  type: types.CONTENT_ITEMS_REQUEST_FAILED,
  error,
});

export const contentItemsSelect = ids => ({ type: types.CONTENT_ITEMS_SELECT, ids });

export const contentItemsUnselectAll = () => ({ type: types.CONTENT_ITEMS_UNSELECT_ALL });

export const fetchContentItems = querystring => {
  let modifiedQuerystring;
  const url = '/cms/data/v1/content-items';

  if (!querystring) {
    modifiedQuerystring = '?parent=/&sort=type+asc,+name+asc';
  } else {
    modifiedQuerystring = '';

    if (!querystring.includes('sort=')) {
      modifiedQuerystring += '&sort=type+asc,+name+asc';
    }

    if (!querystring.includes('parent=') && !querystring.includes('?count=100000000')) {
      modifiedQuerystring += '&parent=/';
    }

    modifiedQuerystring = querystring + modifiedQuerystring;
  }

  return fetch(url + modifiedQuerystring, {
    method: 'GET',
    credentials: 'include',
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
  })
    .then(res => fetchCheck(res))
    .then(res => res.json());
};

export const loadContentItems = querystring => {
  return dispatch => {
    dispatch(contentItemsRequest());

    return fetchContentItems(querystring)
      .then(res => dispatch(contentItemsRequestSuccess(res)))
      .catch(err => dispatch(contentItemsRequestFailed(err)));
  };
};

export const loadAllContentItems = () => {
  return dispatch => {
    dispatch(contentItemsRequest());

    return fetchContentItems('?count=100000000')
      .then(res => dispatch(allContentItemsRequestSuccess(res)))
      .catch(err => dispatch(contentItemsRequestFailed(err)));
  };
};
