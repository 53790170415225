export default async function fetchCheck(response) {
  if (response.ok) {
    return response;
  }

  if (response.status === 403) {
    if (window) {
      window.location.reload(true);
    }

    return false;
  }

  const errorDtls = await response.json();
  const err = {
    status: response.status,
    statusText: response.statusText,
    userMessage: errorDtls && errorDtls.userMessage,
  };

  throw err;
}
