import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import SSTable from '../SSTable/SSTable';
import SSColumn from '../SSTable/SSColumn';
import ToolTipIcon from '../ToolTipIcon/ToolTipIcon';

export default class RolesTable extends Component {
  getName = role => {
    const {
      auth: {
        user: {
          user: { role_id },
        },
      },
    } = this.props;

    return (
      <div>
        <Link to={`/roles/${role.role_id}`}>{role.name}</Link>
        {+role_id === +role.role_id && <span className="text-light"> (my role)</span>}
      </div>
    );
  };

  getDescription = role => role.desc;

  getPermissions = role =>
    role.perm
      .split('|')
      .filter(item => item)
      .map((item, index) => (
        <span key={index} className="label label-default">
          {item}
        </span>
      ));

  getUsers = role => {
    const { users } = this.props;

    return users.filter(item => +item.role_id === +role.role_id).length;
  };

  getEdit = role => (
    <ToolTipIcon toolText="Edit Role">
      <Link to={`/roles/${role.role_id}`}>mode_edit</Link>
    </ToolTipIcon>
  );

  render() {
    const { data, selected, onClick, onDoubleClick } = this.props;

    if (!data) {
      return null;
    }

    return (
      <SSTable
        className="responsive table-roles"
        data={data}
        rowHeight={50}
        rowsCount={data.length - 1}
        doubleClick={onDoubleClick}
        onSelect={onClick}
        selected={selected}
        selectable
        zebra
      >
        <SSColumn header="Role" cell={this.getName} />
        <SSColumn header="Description" cell={this.getDescription} />
        <SSColumn header="Permissions" cell={this.getPermissions} />
        <SSColumn header="Users with this Role" cell={this.getUsers} />
        <SSColumn width={5} header="Edit" cell={this.getEdit} />
      </SSTable>
    );
  }
}

RolesTable.propTypes = {
  data: PropTypes.array,
  auth: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
  selected: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  onDoubleClick: PropTypes.func.isRequired,
};
