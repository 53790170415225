import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { SOLR_DELAY } from '../../../../constants';
import { loadAllLocations } from '../../../actions/locationActions';
import {
  getTier,
  getLocations,
  addLocation,
  deleteLocation,
} from '../../../actions/dataObjectActions';
import { addNotification } from '../../../actions/notificationActions';
import Spinner from '../../../components/Spinner/Spinner';
import QuickAction from '../../../components/QuickAction/QuickAction';
import Header from '../../../components/Header/Header';
import LocationSelector from '../../../components/Locations/LocationSelector';
import TierLocationsTable from '../../../components/DataObjects/TierLocationsTable';
import ActionsMenu from '../../../components/ActionsMenu/ActionsMenu';
import ErrorPage from '../ErrorPage/ErrorPage';
import errorMessage from '../../../utils/errorMessage';

export class TierLocationsPage extends Component {
  state = { showLocationSelector: false };

  componentDidMount() {
    this.loadData();
    document.addEventListener('visibilitychange', this.loadData);
  }

  componentWillUnmount() {
    document.removeEventListener('visibilitychange', this.loadData);
  }

  loadData = () => {
    if (document.visibilityState === 'hidden') {
      return;
    }

    const {
      match: {
        params: { id, tierid },
      },
      loadAllLocations,
      getLocations,
      getTier,
    } = this.props;

    loadAllLocations();
    getLocations(id, tierid);
    getTier(id, tierid);
  };

  getLocations = () => {
    const {
      match: {
        params: { id, tierid },
      },
      getLocations,
    } = this.props;

    getLocations(id, tierid);
  };

  showLocationSelector = () => this.setState({ showLocationSelector: true });

  hideLocationSelector = () => this.setState({ showLocationSelector: false });

  onSuccessLocationSelector = location => {
    this.add(location);
    this.hideLocationSelector();
  };

  add = location => {
    const {
      match: {
        params: { id, tierid },
      },
      addNotification,
    } = this.props;

    return addLocation(id, tierid, location.id).then(
      () => {
        addNotification({ type: 'success', text: 'Location added.' });
        setTimeout(this.getLocations, SOLR_DELAY);
      },
      err => addNotification({ type: 'danger', text: errorMessage(err) }),
    );
  };

  delete = location => {
    const {
      match: {
        params: { id },
      },
      addNotification,
    } = this.props;

    return deleteLocation(id, location.id).then(
      () => {
        addNotification({ type: 'success', text: 'Location deleted.' });
        setTimeout(this.getLocations, SOLR_DELAY);
      },
      err => addNotification({ type: 'danger', text: errorMessage(err) }),
    );
  };

  getHeaderActions = () => {
    const {
      auth: {
        user: {
          permissions: { Writer },
        },
      },
    } = this.props;

    if (!Writer) {
      return [];
    }

    const actions = [
      <QuickAction key="1" toolText="Add Location" onClick={this.showLocationSelector} alwaysShow>
        add_circle
      </QuickAction>,
    ];

    return actions;
  };

  render() {
    const {
      auth,
      dataObjects: { loading, tier, locations, error },
      locations: {
        allLocations: { locations: allLocations },
      },
    } = this.props;
    const { showLocationSelector } = this.state;
    const tierLocations = allLocations.filter(item =>
      locations.some(loc => +loc.location_id === +item.id),
    );
    const userLocation = auth.user.user.userLocation || '';

    if (error) {
      return <ErrorPage error={error} />;
    }

    return (
      <div className="page-structure">
        <Helmet title="Tier Locations" />
        <Header overwrite={{ id: tier, tierid: tier.tier }} />
        <ActionsMenu actions={this.getHeaderActions()} selectedLength={locations.length} />
        <div className="page-panels">
          <div className="page-content page-data-object-tier-locations">
            <Spinner loading={loading}>
              <TierLocationsTable auth={auth} data={tierLocations} onRemove={this.delete} />
            </Spinner>
          </div>
        </div>
        <LocationSelector
          show={showLocationSelector}
          onHide={this.hideLocationSelector}
          onSuccess={this.onSuccessLocationSelector}
          items={allLocations}
          userLocation={userLocation}
        />
      </div>
    );
  }
}

TierLocationsPage.propTypes = {
  match: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  locations: PropTypes.object.isRequired,
  dataObjects: PropTypes.object.isRequired,
  getTier: PropTypes.func.isRequired,
  getLocations: PropTypes.func.isRequired,
  loadAllLocations: PropTypes.func.isRequired,
  addNotification: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  locations: state.locations,
  dataObjects: state.dataObjects,
});
const mapDispatchToProps = {
  addNotification,
  getTier,
  getLocations,
  loadAllLocations,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TierLocationsPage));
