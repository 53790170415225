import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SSTable from '../SSTable/SSTable';
import SSColumn from '../SSTable/SSColumn';
import ToolTipIcon from '../ToolTipIcon/ToolTipIcon';

export default class UrlRulesTable extends Component {
  getType = urlRule => (urlRule.type === 'white' ? 'Whitelist' : 'Blacklist');

  getBeginsWith = urlRule => {
    if (!urlRule.beginsWith) {
      return null;
    }

    return <span className="material-icons">check_circle</span>;
  };

  getRule = urlRule => urlRule.rule;

  getEditButton = urlRule => {
    const { onDoubleClick } = this.props;

    return (
      <ToolTipIcon toolText="Edit URL Rule" onClick={event => onDoubleClick(event, urlRule)}>
        mode_edit
      </ToolTipIcon>
    );
  };

  render() {
    const { data, selected, onClick, onDoubleClick } = this.props;

    if (!data) {
      return null;
    }

    return (
      <SSTable
        data={data}
        rowHeight={50}
        rowsCount={data.length - 1}
        onSelect={onClick}
        doubleClick={onDoubleClick}
        selected={selected}
        selectable
        zebra
      >
        <SSColumn header="Type" width={15} cell={this.getType} />
        <SSColumn header="Begins with" width={15} cell={this.getBeginsWith} />
        <SSColumn header="URL" cell={this.getRule} />
        <SSColumn header="Edit" width={5} cell={this.getEditButton} />
      </SSTable>
    );
  }
}

UrlRulesTable.propTypes = {
  data: PropTypes.array,
  selected: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  onDoubleClick: PropTypes.func.isRequired,
};
