import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import qs from '../../utils/qs';
import { DEFAULT_ITEMS_PER_PAGE, DEFAULT_OFFSET } from '../Pagination/Pagination';

export class PaginationInfo extends Component {
  getItemsPerPage = props => {
    const { count } = qs(props);

    return count ? +count : DEFAULT_ITEMS_PER_PAGE;
  };

  getOffset = props => {
    const { offset } = qs(props);

    return offset ? +offset : DEFAULT_OFFSET;
  };

  state = {
    itemsPerPage: this.getItemsPerPage(this.props),
    offset: this.getOffset(this.props),
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      itemsPerPage: this.getItemsPerPage(nextProps),
      offset: this.getOffset(nextProps),
    });
  }

  getFirstItem = () => {
    const { itemsFound } = this.props;
    const { offset } = this.state;
    const itemsTotal = itemsFound || 0;

    return itemsTotal ? offset + 1 : 0;
  };

  getLastItem = () => {
    const { itemsFound } = this.props;
    const { itemsPerPage, offset } = this.state;
    const itemsTotal = itemsFound || 0;

    return Math.min(itemsTotal, offset + itemsPerPage);
  };

  render() {
    const { itemsFound } = this.props;
    const firstItem = this.getFirstItem();
    const lastItem = this.getLastItem();

    return (
      <div data-test="pagination-details" className="pagination-details">
        Showing {firstItem} to {lastItem} of {itemsFound || 0} entries
      </div>
    );
  }
}

PaginationInfo.propTypes = {
  itemsFound: PropTypes.number,
};

export default withRouter(PaginationInfo);
