/**
 * THIS IS THE ENTRY POINT FOR THE CLIENT, JUST LIKE server.js IS THE ENTRY POINT FOR THE SERVER.
 */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import store from './redux/store';
import Auth from './containers/Auth/Auth';
import Routes from './routes';

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Auth>
        <Routes />
      </Auth>
    </BrowserRouter>
  </Provider>,
  document.getElementById('content'),
);
