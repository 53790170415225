import { combineReducers } from 'redux';
import app from './appReducer';
import assets from './assetsReducer';
import assignments from './assignmentsReducer';
import auth from './authReducer';
import campaigns from './campaignsReducer';
import campaignSchedule from './campaignScheduleReducer';
import channels from './channelsReducer';
import contentItems from './contentItemsReducer';
import dataObjects from './dataObjectsReducer';
import devices from './devicesReducer';
import dialog from './dialogReducer';
import filter from './filterReducer';
import folders from './foldersReducer';
import fonts from './fontsReducer';
import locations from './locationsReducer';
import navigation from './navigationReducer';
import notifications from './notificationsReducer';
import playlists from './playlistsReducer';
import roles from './rolesReducer';
import schedules from './schedulesReducer';
import sequences from './sequencesReducer';
import signs from './signsReducer';
import sites from './sitesReducer';
import urlRulesets from './urlRulesetReducer';
import users from './usersReducer';

const rootReducer = combineReducers({
  app,
  assets,
  assignments,
  auth,
  campaigns,
  campaignSchedule,
  channels,
  contentItems,
  dataObjects,
  devices,
  dialog,
  filter,
  folders,
  fonts,
  locations,
  navigation,
  notifications,
  playlists,
  roles,
  schedules,
  sequences,
  signs,
  sites,
  urlRulesets,
  users,
});

export default rootReducer;
