import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import clone from 'clone';
import qs from '../../utils/qs';
import { resetFilters } from '../../actions/filterActions';

export class Filters extends Component {
  componentWillUnmount() {
    const { resetFilters } = this.props;

    resetFilters();
  }

  getQueryParam = name => {
    const param = qs(this.props)[name];

    if (!param) {
      return [];
    }

    return Array.isArray(param) ? param : [param];
  };

  getData = props => {
    const param = [
      'type',
      'createdBy',
      'modifiedBy',
      'tags',
      'version',
      'online_status',
      'download_status',
    ];
    const data = clone(props.data) || [];

    if (param.includes(props.name)) {
      this.getQueryParam(props.name).forEach(item => {
        if (!data.includes(item)) {
          data.push(item, 0);
        }
      });
    }

    return data;
  };

  render() {
    const { children, open } = this.props;
    const className = `filters-panel${open ? ' open' : ''}`;
    const childNodes = React.Children.map(children, child =>
      React.cloneElement(child, { data: this.getData(child.props) }),
    );

    return (
      <div data-test="filters" className={className}>
        {childNodes}
      </div>
    );
  }
}

Filters.propTypes = {
  devices: PropTypes.object.isRequired,
  children: PropTypes.node,
  open: PropTypes.bool.isRequired,
  resetFilters: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  devices: state.devices,
  open: state.filter.open,
});
const mapDispatchToProps = {
  resetFilters,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Filters));
