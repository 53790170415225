import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Panel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import pluralize from 'pluralize';
import SSTable from '../SSTable/SSTable';
import SSColumn from '../SSTable/SSColumn';
import ToolTipIcon from '../ToolTipIcon/ToolTipIcon';
import { getPermissions } from '../../utils/getPermissions';
import { launchCreator } from '../../utils/launchCreator';
import { buildTagList } from '../../utils/tagList';
import { isoDateToString } from '../../utils/formatUnits';
import UserProfile from '../UserProfile/UserProfile';

export default class UsedInSignsTable extends Component {
  state = { open: false };

  getHeader = () => {
    const { type, data } = this.props;
    const { open } = this.state;

    return (
      <div data-test="used-signs-title" className="toggle" onClick={this.toggle}>
        <i className="material-icons material-caret inline">
          {open ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}
        </i>
        Used in {pluralize('Sign', data.length, true)}
        {['playlist', 'video'].includes(type) && (
          <span className="text-light text-small">
            {' '}
            Including <i>Video Play Fullscreen</i> events
          </span>
        )}
      </div>
    );
  };

  getPublished = item => {
    const {
      item: { usedInSigns },
    } = this.props;
    const { id, publishedDate = '' } = item;

    return (
      usedInSigns.includes(`${id}-1`) && (
        <img src={`/cms/data/v1/signs/${id}/published/thumbnail?${publishedDate}`} alt="" />
      )
    );
  };

  getPending = item => {
    const {
      item: { usedInSigns },
    } = this.props;
    const { id, pendingDate = '' } = item;

    return (
      usedInSigns.includes(`${id}-0`) && (
        <img src={`/cms/data/v1/signs/${id}/pending/thumbnail?${pendingDate}`} alt="" />
      )
    );
  };

  getLinks = item => {
    const { auth } = this.props;
    const p = getPermissions(auth);

    return (
      <div className="item-name">
        <ToolTipIcon
          toolText={`Preview ${item.name}`}
          href={`/player/?sign=${item.id}`}
          target="_blank"
        >
          play_circle_filled
        </ToolTipIcon>
        {p.atLeastTierStandard && p.writer && (
          <ToolTipIcon
            toolText={`Open ${item.name} in Creator`}
            onClick={() => launchCreator(item.id)}
          >
            <i className="fa fa-paint-brush" />
          </ToolTipIcon>
        )}
      </div>
    );
  };

  getName = item => {
    const { auth } = this.props;
    const p = getPermissions(auth);

    return (
      <div className="item-name">
        <div>
          {!p.atLeastTierStandard ? item.name : <Link to={`/signs/${item.id}`}>{item.name}</Link>}
        </div>
        <div className="item-tags">Folder: {item.parent}</div>
        <div className="item-tags">Tags: {buildTagList(item)}</div>
      </div>
    );
  };

  getCreated = item => {
    const { users } = this.props;

    return (
      <div>
        <UserProfile userId={item.createdBy} users={users} />
        <div className="date">{isoDateToString(item.createdDate)}</div>
      </div>
    );
  };

  getModified = item => {
    const { users } = this.props;

    return (
      <div>
        <UserProfile userId={item.modifiedBy} users={users} />
        <div className="date">{isoDateToString(item.modifiedDate)}</div>
      </div>
    );
  };

  toggle = () => {
    const { open } = this.state;

    this.setState({ open: !open });
  };

  render() {
    const { item, data } = this.props;
    const { open } = this.state;

    if (!item || !data) {
      return null;
    }

    return (
      <Panel className="used-in">
        <Panel.Heading>{this.getHeader()}</Panel.Heading>
        <Panel.Body>
          {open && Boolean(data.length) && (
            <SSTable data={data} rowsCount={data.length - 1} zebra>
              <SSColumn width={5} header="Published" cell={this.getPublished} />
              <SSColumn width={5} header="Latest" cell={this.getPending} />
              <SSColumn width={5} header="" cell={this.getLinks} />
              <SSColumn header="Sign Name" cell={this.getName} />
              <SSColumn header="Created" cell={this.getCreated} />
              <SSColumn header="Modified" cell={this.getModified} />
            </SSTable>
          )}
        </Panel.Body>
      </Panel>
    );
  }
}

UsedInSignsTable.propTypes = {
  type: PropTypes.string.isRequired,
  item: PropTypes.object,
  data: PropTypes.array,
  auth: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
};
