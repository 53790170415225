import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DropdownButton, MenuItem, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { logoutUser } from '../../actions/authActions';
import Avatar from '../Avatar/Avatar';
import DeployDialog from '../Devices/DeployDialog';
import { getPermissions } from '../../utils/getPermissions';
import { launchCreator } from '../../utils/launchCreator';

export const UserBar = props => {
  const [showDeployDialog, toggleDeployDialog] = useState(false);

  const editUserDetails = () => {
    const { history } = props;

    history.push('/user/');
  };

  const generateName = () => {
    const {
      user: {
        user: { firstname, lastname, name },
      },
    } = props;

    if (firstname.trim() === '' || lastname.trim() === '') {
      return name;
    }

    return `${firstname} ${lastname}`;
  };

  const logoutTheUser = () => {
    const { history } = props;

    return logoutUser().then(({ url }) => {
      if (url) {
        // Redirect to sso/logout-init endpoint which initiates logout request with IdP
        window.location.href = url;
      } else {
        return history.push('/logout');
      }
    });
  };

  const { deployer, user: authUser, showNotificationHistory } = props;
  const { client, user, downloadSchedule } = authUser;
  const p = getPermissions({ user: authUser });

  return (
    <div className="user">
      <div className="avatar-holder">
        <Avatar size={85} user={user} />
      </div>
      <DropdownButton
        data-test="user-dropdown"
        className="btn-minimal"
        id="user-dropdown"
        title={generateName()}
      >
        <MenuItem data-test="user-my-account" onClick={editUserDetails}>
          My Account
        </MenuItem>
        <MenuItem data-test="user-notification-history" onClick={showNotificationHistory}>
          Notifications
        </MenuItem>
        <MenuItem divider />
        <MenuItem data-test="user-logout" onClick={logoutTheUser}>
          Logout
        </MenuItem>
      </DropdownButton>
      <div className="client">{client.name}</div>
      <div className="launch-actions">
        {p.deployer && (
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="deploy">Deploy All Content</Tooltip>}
            onClick={() => toggleDeployDialog(true)}
          >
            <span data-test="user-deploy" className="btn">
              Deploy All <span className="material-icons">cloud_download</span>
            </span>
          </OverlayTrigger>
        )}
        {p.atLeastTierStandard && p.writer && (
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="creator">Launch Creator</Tooltip>}
            onClick={launchCreator}
          >
            <span data-test="user-creator" className="btn creator">
              Creator <i className="fa fa-paint-brush" />
            </span>
          </OverlayTrigger>
        )}
      </div>
      <DeployDialog
        show={showDeployDialog}
        onHide={() => toggleDeployDialog(false)}
        deployer={deployer}
        downloadSchedule={downloadSchedule}
        auth={{ user: authUser }}
        deployAll
      />
    </div>
  );
};

UserBar.propTypes = {
  history: PropTypes.object.isRequired,
  deployer: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  showNotificationHistory: PropTypes.func.isRequired,
};

export default withRouter(UserBar);
