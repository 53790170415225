import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import ToolTipIcon from '../ToolTipIcon/ToolTipIcon';

export default class QuickAction extends Component {
  state = { open: false };

  renderMenuItems = () => {
    const { id, onClick } = this.props;

    return onClick.map((item, index) => {
      const { title = '', href, target, onClick, divider } = item;
      const testId = `${id}-${title.replace(/[^a-z0-9]/gi, '')}`;

      if (divider) {
        return <MenuItem key={index} divider />;
      }

      if (!href) {
        return (
          <MenuItem data-test={testId} key={index} onClick={onClick}>
            {title}
          </MenuItem>
        );
      }

      return (
        <li key={index}>
          <Link data-test={testId} to={href} target={target} onClick={onClick}>
            {title}
          </Link>
        </li>
      );
    });
  };

  render() {
    const { id, className, toolText, href, target, children, onClick } = this.props;
    const { open } = this.state;

    if (onClick && onClick.constructor === Array) {
      return (
        <DropdownButton
          noCaret
          data-test={`dropdown-${id}`}
          className="material-icons btn btn-minimal"
          title="add_circle"
          id={id}
          open={open}
          onToggle={() => this.setState({ open: !open })}
          onMouseEnter={() => this.setState({ open: true })}
          onMouseLeave={() => this.setState({ open: false })}
        >
          {this.renderMenuItems()}
        </DropdownButton>
      );
    }

    return (
      <ToolTipIcon
        id={id}
        className={className}
        toolText={toolText}
        href={href}
        target={target}
        onClick={onClick}
      >
        {children}
      </ToolTipIcon>
    );
  }
}

QuickAction.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
  toolText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
  multiple: PropTypes.bool,
  singular: PropTypes.bool,
  href: PropTypes.string,
  target: PropTypes.string,
  onClick: PropTypes.oneOfType([PropTypes.func, PropTypes.array]),
};
