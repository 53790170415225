import React, { Component } from 'react';
import PropTypes from 'prop-types';

/**
 * SSTable Component
 * -----------------------------------------------------
 * Used to add a column to a SSTable
 * @props {header, cell, width}
 * header - The text for the header cell of the table <th>,
 * cell - The content for the table column <td>,
 * width - The width in % of the table column.
 */
export default class SSTable extends Component {
  state = {
    dragging: false,
    draggingIndex: 0,
    draggingOver: false,
    top: false,
    shiftLastIndex: false,
  };

  holdTimer = null;

  getChildren = () => {
    const { children } = this.props;

    if (!children) {
      return [];
    }

    if (Array.isArray(children)) {
      return children.filter(item => item);
    }

    return [children];
  };

  handelRowClick = (item, index, event) => {
    const { selected: propsSelected = {}, onSelect } = this.props;
    const { shiftLastIndex } = this.state;

    // remove the text highlight from the selected rows
    window.getSelection().removeAllRanges();

    if (onSelect) {
      let selected = propsSelected;
      const isMac = navigator.platform.indexOf('Mac') > -1;

      /* If the alt key is pressed */
      if (event[isMac ? 'metaKey' : 'ctrlKey']) {
        if (selected[index] === true) {
          delete selected[index];
          this.setState({ shiftLastIndex: false });
        } else if (!selected[index]) {
          selected[index] = true;
          this.setState({ shiftLastIndex: index });
        }
      } else if (event.shiftKey && shiftLastIndex !== false) {
        const pre = shiftLastIndex;

        if (index > pre) {
          for (let pos = pre; pos <= index; pos += 1) {
            selected[pos] = true;
          }
        } else if (index < pre) {
          for (let pos = index; pos <= pre; pos += 1) {
            selected[pos] = true;
          }
        }
      } else {
        selected = {};
        selected[index] = true;
        this.setState({ shiftLastIndex: index });
      }

      onSelect(selected);
    }
  };

  handelRowDoubleClick = (index, event) => {
    const { data, doubleClick } = this.props;

    // remove the text highlight from the selected rows
    window.getSelection().removeAllRanges();

    if (doubleClick) {
      doubleClick.call(this, event, data[index], index);
    }
  };

  handleTouchStart = index => {
    this.holdTimer = setTimeout(() => {
      this.setState({
        dragging: index,
        draggingIndex: index,
        draggingOver: false,
        top: false,
        mobile: true,
      });
    }, 200);
  };

  handleTouchMove = (index, event) => {
    const { top, dragging, draggingOver, draggingIndex } = this.state;

    clearTimeout(this.holdTimer);

    if (dragging) {
      if (event.preventDefault) {
        event.preventDefault();
      }

      if (!draggingOver) {
        const currentOver = this.refs[index];

        this.setState({ draggingOver: currentOver });

        return;
      }

      const dragY = event.touches[0].clientY;
      const overRect = draggingOver.getBoundingClientRect();
      const topBarrier = dragY - overRect.top;
      const bottomBarrier = overRect.bottom - dragY;
      /* Need to check the 50% */
      let currentOverIndex = draggingIndex;
      const positionY = dragY - overRect.top;

      if (!top && positionY <= overRect.height / 2) {
        this.setState({ top: true });
      } else if (top && positionY > overRect.height / 2) {
        this.setState({ top: false });
      }

      if (topBarrier < 0) {
        if (currentOverIndex !== 0) {
          currentOverIndex -= 1;
          const before = Object.keys(this.refs)[currentOverIndex];
          const newRow = this.refs[before];

          if (newRow.id !== draggingOver.id) {
            this.setState({
              draggingOver: newRow,
              draggingIndex: currentOverIndex,
              top: false,
            });
          }
        }
      } else if (bottomBarrier < 0) {
        currentOverIndex += 1;

        if (currentOverIndex !== Object.keys(this.refs).length) {
          const after = Object.keys(this.refs)[currentOverIndex];
          const newRow = this.refs[after];

          this.setState({
            draggingOver: newRow,
            draggingIndex: currentOverIndex,
            top: true,
          });
        }
      }
    }
  };

  /* Not sure this method is supported in ie10... */
  handleDrag = (index, event) => {
    event.dataTransfer.setData('text/plain', null); // FF requires this line
    this.setState({ dragging: index, draggingIndex: event, draggingOver: false, top: false });
  };

  handleDragOver = (index, event) => {
    const { top, draggingOver } = this.state;

    if (index === 0) {
      const rect = event.currentTarget.getBoundingClientRect();
      const positionY = event.clientY - rect.top;

      if (positionY <= rect.height / 2) {
        if (top === false || draggingOver !== index) {
          this.setState({
            draggingOver: index,
            top: true,
          });
        }

        return;
      }
    }

    if (draggingOver !== index || top === true) {
      this.setState({
        draggingOver: index,
        top: false,
      });
    }

    event.stopPropagation();
    /* event.currentTarget */
  };

  handleDragEnd = () => {
    const { data: propsData, onSort } = this.props;
    const { top, dragging, draggingOver } = this.state;

    /* Reorder the rows */
    if (onSort) {
      let position = draggingOver + 1;
      let move = false;

      if (top) {
        position -= 1;
      }

      if (!(position === dragging || position === dragging + 1)) {
        move = true;
      }

      if (position > dragging) {
        position -= 1;
      }

      if (move && dragging !== false) {
        const data = propsData.slice(0);

        data.splice(position, 0, data.splice(dragging, 1)[0]);
        onSort(data);
      }
    }

    this.setState({ dragging: false, draggingOver: false, top: false, mobile: false });
  };

  handleContentMenu = event => {
    const { mobile } = this.state;

    if (mobile) {
      event.preventDefault();
    }
  };

  renderColumnHeaders = () => {
    const { headerHeight = 50 } = this.props;
    const styles = {
      height: headerHeight,
    };
    const headerColumns = [];

    this.getChildren().forEach((item, index) => {
      const comp = item.type.renderHeader(item.props, index);

      headerColumns.push(comp);
    });

    return (
      <tr data-test="sstable-thead-tr" className="psudo-table-tr" style={styles}>
        {headerColumns}
      </tr>
    );
  };

  renderColumnBodys = () => {
    const {
      data,
      sortable,
      selectable,
      selected,
      zebra,
      rowsCount,
      rowHeight,
      onSelect,
      isDisabled,
    } = this.props;
    const { top, dragging, draggingOver } = this.state;
    const bodyRows = [];
    const children = this.getChildren();
    const columnCount = children.length;
    const styles = {
      height: rowHeight || 50,
    };

    for (let index = 0; index <= rowsCount; index += 1) {
      /* TODO: #3.1.0 https://github.com/clauderic/react-sortable-hoc is better then our implementation, can we take from or use this method instead with tables.?  */
      const item = data[index];
      const clicker = selectable ? this.handelRowClick.bind(this, item, index) : null;
      const dragStart = sortable ? this.handleDrag.bind(this, index) : null;
      const dragOver = sortable ? this.handleDragOver.bind(this, index) : null;
      const dragEnd = sortable ? this.handleDragEnd.bind(this, index) : null;
      const touchStart = sortable ? this.handleTouchStart.bind(this, index) : null;
      const touchMove = sortable ? this.handleTouchMove.bind(this, index) : null;
      const touchEnd = sortable ? this.handleDragEnd.bind(this, index) : null;
      const doubleClick = this.handelRowDoubleClick.bind(this, index);
      let classes = 'psudo-table-tr';

      if (isDisabled && isDisabled(item)) {
        classes += ' disabled';
      }

      if (onSelect || doubleClick) {
        classes += ' interactive';
      }

      if (zebra && index % 2 === 1) {
        classes += ' psudo-zebra-row';
      }

      if (selected && selected[index]) {
        classes += ' selected';
      }

      if (dragging !== false && index === dragging) {
        classes += ' dragging';
      }

      if (draggingOver !== false && index === draggingOver) {
        if (top) {
          classes += ' drag-over-top';
        } else {
          classes += ' drag-over';
        }
      }

      bodyRows.push(
        <tr
          data-test={`sstable-tr-${index + 1}`}
          onContextMenu={this.handleContentMenu}
          draggable={sortable}
          ref={index}
          onTouchStart={touchStart}
          onTouchMove={touchMove}
          onTouchEnd={touchEnd}
          onDragStart={dragStart}
          onDragEnd={dragEnd}
          onDragOver={dragOver}
          key={`ss-table-row-${index}`}
          className={classes}
          style={styles}
          onClick={clicker}
          onDoubleClick={doubleClick}
        >
          {this.renderColumns(columnCount, index)}
        </tr>,
      );
    }

    return bodyRows;
  };

  renderColumns = (columnCount, rowIndex) => {
    const { data } = this.props;
    const cols = [];
    const children = this.getChildren();

    for (let index = 0; index < columnCount; index += 1) {
      let cell = null;
      const styles = {
        width: 'auto',
      };
      const child = children[index] || children;

      switch (typeof child.props.cell) {
        case 'object':
          if (child.props.cell.type) {
            // react component
            cell = React.cloneElement(child.props.cell, { rowIndex, props: this.props });
          } else if (child.props.cell.length) {
            // array
            cell = child.props.cell[rowIndex];
          }

          break;
        case 'function':
          cell = child.props.cell(data[rowIndex], rowIndex);
          break;
        case 'string':
          cell = child.props.cell;
          break;
        default:
      }

      styles.width = child.props.width ? `${child.props.width}%` : 'auto';
      cols.push(
        <td
          data-test={`sstable-td-${rowIndex + 1}-${index + 1}`}
          className="psudo-table-td"
          key={`ss-table-cell-${rowIndex}-${index}`}
          style={styles}
        >
          <div className="psudo-table-cell-content">{cell}</div>
        </td>,
      );
    }

    return cols;
  };

  render() {
    const { rowsCount, noHeader, className } = this.props;

    if (rowsCount === false || typeof rowsCount === 'undefined') {
      return false;
    }

    /* If noHeader is set to true then we shouldn't render the header element */
    const renderHeader = !noHeader ? (
      this.renderColumnHeaders()
    ) : (
      <tr className="psudo-table-nohead" />
    );

    return (
      <div className="table-responsive">
        <table className={`psudo-table ${className}`} ref="psudo-table">
          <thead data-test="sstable-thead" className="psudo-table-head">
            {renderHeader}
          </thead>
          <tbody data-test="sstable-tbody" className="psudo-table-tbody">
            {this.renderColumnBodys()}
          </tbody>
        </table>
      </div>
    );
  }
}

SSTable.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  rowsCount: PropTypes.number.isRequired,
  rowHeight: PropTypes.number,
  headerHeight: PropTypes.number,
  data: PropTypes.array.isRequired,
  selectable: PropTypes.bool,
  selected: PropTypes.object,
  onSelect: PropTypes.func,
  sortable: PropTypes.bool,
  noHeader: PropTypes.bool,
  zebra: PropTypes.bool,
  onSort: PropTypes.func,
  doubleClick: PropTypes.func,
  isDisabled: PropTypes.func,
};
