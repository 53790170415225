import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import async from 'async';
import pluralize from 'pluralize';
import { SOLR_DELAY } from '../../../../constants';
import { addNotification } from '../../../actions/notificationActions';
import { getRoles } from '../../../actions/roleActions';
import { getUsersDetails, deleteUser, select, unselectAll } from '../../../actions/userActions';
import Header from '../../../components/Header/Header';
import ActionsMenu from '../../../components/ActionsMenu/ActionsMenu';
import QuickAction from '../../../components/QuickAction/QuickAction';
import Spinner from '../../../components/Spinner/Spinner';
import Confirm from '../../../components/Confirm/Confirm';
import UsersTable from '../../../components/Users/UsersTable';
import ErrorPage from '../ErrorPage/ErrorPage';

export class UsersPage extends Component {
  componentDidMount() {
    this.loadData();
    document.addEventListener('visibilitychange', this.loadData);
  }

  componentWillUnmount() {
    const { unselectAll } = this.props;

    unselectAll();
    document.removeEventListener('visibilitychange', this.loadData);
  }

  loadData = () => {
    if (document.visibilityState === 'hidden') {
      return;
    }

    const { getRoles, getUsersDetails } = this.props;

    getRoles();
    getUsersDetails();
  };

  onClickDelete = () => {
    const confirm = this.refDelete;
    const items = this.getSelectedExceptActiveUser();
    const users = items.map((item, index) => (
      <tr key={index}>
        <td>{item.name}</td>
        <td>
          {item.firstname} {item.lastname}
        </td>
        <td>{item.email}</td>
        <td>{item.role}</td>
      </tr>
    ));
    const title = `Delete ${pluralize('User', items.length, true)}`;
    const body = (
      <div>
        <p>
          Are you sure you want to delete {pluralize('this', items.length)}{' '}
          {pluralize('User', items.length)}?
        </p>
        <table className="table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Full Name</th>
              <th>Email</th>
              <th>Role</th>
            </tr>
          </thead>
          <tbody>{users}</tbody>
        </table>
      </div>
    );

    confirm.show(title, body);
    confirm.onSuccess(this.delete);
  };

  onClickTable = users => {
    const { select } = this.props;

    select(users);
  };

  onDoubleClickTable = () => this.edit(this.getSelectedItems()[0]);

  getSelectedItems = () => {
    const {
      users: { users, selected },
    } = this.props;

    return Object.keys(selected).map(index => users[index]);
  };

  getSelectedExceptActiveUser = () => {
    const {
      auth: {
        user: { userid },
      },
    } = this.props;

    return this.getSelectedItems().filter(item => +item.user_id !== +userid);
  };

  getHeaderActions = () => [
    <QuickAction key="1" toolText="Add User" href="/users/new" alwaysShow>
      add_circle
    </QuickAction>,
    <QuickAction
      key="2"
      toolText={pluralize('Delete User', this.getSelectedItems().length)}
      onClick={this.onClickDelete}
      singular
      multiple
    >
      delete
    </QuickAction>,
  ];

  edit = user => {
    const { history } = this.props;

    history.push(`/users/${user.user_id}`);
  };

  delete = () => {
    const items = this.getSelectedExceptActiveUser();

    async.filter(
      items,
      (item, callback) =>
        deleteUser(item.user_id).then(
          res => callback(null, res),
          () => callback(null, false),
        ),
      this.deleteCallback,
    );
  };

  deleteCallback = (err, results) => {
    const { getUsersDetails, addNotification, unselectAll } = this.props;
    const items = this.getSelectedExceptActiveUser();
    let failedItems;
    let errorMsg = '';

    // check for errors
    if (items.length === results.length) {
      addNotification({
        type: 'success',
        text: `${pluralize('User', items.length, true)} deleted.`,
      });
    } else {
      // collect the failed request items
      failedItems = items.filter(item => !results.includes(item));
      failedItems.forEach(item => {
        errorMsg += (errorMsg ? ', ' : 'Failed to delete: ') + item.name;
      });
      addNotification({ type: 'danger', text: errorMsg });
    }

    // if there was at least one successful request, update
    if (results.length) {
      unselectAll();
      setTimeout(getUsersDetails, SOLR_DELAY);
    }
  };

  render() {
    const {
      auth: {
        user: { user },
      },
      roles: { roles },
      users: { users, loading, selected, error },
    } = this.props;

    if (error) {
      return <ErrorPage error={error} />;
    }

    return (
      <div className="page-structure">
        <Helmet title="Users" />
        <Header />
        <ActionsMenu
          actions={this.getHeaderActions()}
          selectedLength={this.getSelectedExceptActiveUser().length}
        />
        <div className="page-panels">
          <div className="page-content page-users">
            <Spinner loading={loading}>
              <UsersTable
                data={users}
                user={user}
                roles={roles}
                selected={selected}
                onClick={this.onClickTable}
                onDoubleClick={this.onDoubleClickTable}
              />
            </Spinner>
          </div>
        </div>
        <Confirm
          ref={ref => {
            this.refDelete = ref;
          }}
        />
      </div>
    );
  }
}

UsersPage.propTypes = {
  history: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  roles: PropTypes.object.isRequired,
  users: PropTypes.object.isRequired,
  getRoles: PropTypes.func.isRequired,
  getUsersDetails: PropTypes.func.isRequired,
  select: PropTypes.func.isRequired,
  unselectAll: PropTypes.func.isRequired,
  addNotification: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  roles: state.roles,
  users: state.users,
});
const mapDispatchToProps = {
  getRoles,
  getUsersDetails,
  select,
  unselectAll,
  addNotification,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UsersPage));
