import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { format, parse } from 'date-fns';
import SSTable from '../SSTable/SSTable';
import SSColumn from '../SSTable/SSColumn';
import UserProfile from '../UserProfile/UserProfile';
import AssetImage from '../Assets/AssetImage';
import { formatTime, isoDateToString } from '../../utils/formatUnits';
import { buildTagList } from '../../utils/tagList';
import ToolTipIcon from '../ToolTipIcon/ToolTipIcon';
import { buildUrlForAddUniqueQueryParam } from '../../utils/updateQueryString';
import { launchCreator } from '../../utils/launchCreator';
import { getParent } from '../../utils/parent';
import qs from '../../utils/qs';

export class ContentItemsTable extends Component {
  getThumbnail = item => {
    const { signs } = this.props;

    return <AssetImage id={item.content_id} type={item.type} item={item} signs={signs} />;
  };

  getName = item => (
    <div className="item-name">
      <p>
        <Link to={item.type === 'folder' ? this.getFolderUrl(item) : this.getUrl(item)}>
          {item.name}
        </Link>
      </p>
      <div className="item-tags">Folder: {item.parent}</div>
      <div className="item-tags">Tags: {buildTagList(item)}</div>
    </div>
  );

  getDuration = item => {
    const { duration } = item;

    if (!duration || duration <= 0) {
      return null;
    }

    const [secs, ms] = formatTime(duration / 1000).split('.');

    return (
      <div>
        {secs}
        {ms ? <span className="text-light">.{ms}</span> : null}
      </div>
    );
  };

  getType = item => (
    <div className="item-name">
      <p>{item.type}</p>
      {this.getDimensions(item)}
      {this.getValidityPeriod(item)}
    </div>
  );

  getDimensions = item => {
    if (item.width > 0 && item.height > 0) {
      return <div className="item-tags">{`${item.width} x ${item.height}`}</div>;
    }

    return null;
  };

  getValidityPeriod = item => {
    if (item.type !== 'video' || (item.startDate === '20100101' && item.endDate === '20991231')) {
      return null;
    }

    return (
      <div className="item-tags">
        {`${format(parse(item.startDate, 'yyyyMMdd', new Date()), 'dd/MM/yyyy')} - ${format(
          parse(item.endDate, 'yyyyMMdd', new Date()),
          'dd/MM/yyyy',
        )}`}
      </div>
    );
  };

  getCreator = item => {
    const { users } = this.props;

    return (
      <div className="created-badge">
        <UserProfile userId={item.createdBy} users={users} />
        <p className="date">on {isoDateToString(item.createdDate)}</p>
      </div>
    );
  };

  getModifier = item => {
    const { users } = this.props;

    return (
      <div className="modified-badge">
        <UserProfile userId={item.modifiedBy} users={users} />
        <p className="date">on {isoDateToString(item.modifiedDate)}</p>
      </div>
    );
  };

  getUrl = item => {
    switch (item.type) {
      case 'image':
      case 'video':
      case 'file':
        return `/assets/${item.fileId}`;
      default:
        return `/${item.type}s/${item.content_id}`;
    }
  };

  getFolderUrl = item => {
    const { location } = this.props;
    const sub = (qs(this.props).parent || '').endsWith('*') ? '*' : '';
    const parent = `${item.parent + item.name}/${sub}`;

    return buildUrlForAddUniqueQueryParam(location, 'parent', parent);
  };

  onDoubleClick = (event, item) => {
    const { history } = this.props;
    const url = item.type === 'folder' ? this.getFolderUrl(item) : this.getUrl(item);

    history.push(url);
  };

  editButton = item => (
    <ToolTipIcon toolText={`Edit ${item.name}`} href={this.getUrl(item)}>
      mode_edit
    </ToolTipIcon>
  );

  approveButton = item => {
    const {
      auth: {
        user: {
          permissions: { Approver },
        },
      },
      approveAsset,
    } = this.props;

    if (item.approved === undefined) {
      return 'N/A';
    }

    if (Approver && !item.approved) {
      return (
        <ToolTipIcon toolText="Approve" onClick={() => approveAsset(item)}>
          thumb_up
        </ToolTipIcon>
      );
    }

    if (item.approved) {
      return <ToolTipIcon toolText="Approved">check</ToolTipIcon>;
    }

    return <ToolTipIcon toolText="Not Approved">cancel</ToolTipIcon>;
  };

  extraActions = item => {
    const hasPreview = ['sign', 'sequence', 'schedule'].includes(item.type);
    const previewLink = hasPreview ? `/player/?${item.type}=${item.content_id}` : null;
    const actionArray = [];
    let openFolderButton = '';
    let previewButton = '';
    let creatorButton = '';

    if (item.type === 'folder') {
      openFolderButton = (
        <ToolTipIcon key="1" toolText="Open" href={this.getFolderUrl(item)}>
          <i className="material-icons">keyboard_arrow_right</i>
        </ToolTipIcon>
      );
      actionArray.push(openFolderButton);
    }

    if (hasPreview) {
      previewButton = (
        <ToolTipIcon key="2" toolText={`Preview ${item.name}`} href={previewLink} target="_blank">
          <span>play_circle_filled</span>
        </ToolTipIcon>
      );
      actionArray.push(previewButton);
    }

    if (item.type === 'sign') {
      creatorButton = (
        <ToolTipIcon
          key="3"
          toolText={`Open ${item.name} in Creator`}
          onClick={() => launchCreator(item.content_id, getParent(this.props, item.parent).id)}
        >
          <i className="fa fa-paint-brush" />
        </ToolTipIcon>
      );
      actionArray.push(creatorButton);
    }

    return actionArray;
  };

  render() {
    const { data, selected, onClick } = this.props;

    if (!data) {
      return null;
    }

    return (
      <SSTable
        className="responsive table-content-items"
        data={data}
        rowHeight={90}
        rowsCount={data.length - 1}
        onSelect={onClick}
        doubleClick={this.onDoubleClick}
        zebra
        selected={selected}
        selectable
      >
        <SSColumn cell={this.getThumbnail} header="" />
        <SSColumn cell={this.getType} header="Type" />
        <SSColumn cell={this.getName} header="Name" />
        <SSColumn cell={this.getDuration} header="Duration" />
        <SSColumn cell={this.getCreator} header="Created" />
        <SSColumn cell={this.getModifier} header="Modified" />
        <SSColumn cell={this.approveButton} header="Approved" />
        <SSColumn cell={this.editButton} header="Edit" />
        <SSColumn cell={this.extraActions} header="" />
      </SSTable>
    );
  }
}

ContentItemsTable.propTypes = {
  data: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  selected: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  approveAsset: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  folders: PropTypes.object.isRequired,
  signs: PropTypes.array.isRequired,
};

export default withRouter(ContentItemsTable);
