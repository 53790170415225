import qs from './qs';

// get the parent path from the search or a string
export const getParentPath = (props, parentPath) => {
  const parent = parentPath || qs(props).parent || '/';

  return parent.endsWith('*') ? parent.slice(0, -1) : parent;
};

// get the parent folder from the search or a string
export const getParent = (props, parentPath) => {
  const path = getParentPath(props, parentPath);

  return path === '/'
    ? { id: 0 }
    : props.folders.folders.folders.find(item => `${item.parent + item.name}/` === path);
};
