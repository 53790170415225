import 'isomorphic-fetch';
import fetchCheck from '../redux/middleware/fetchCheck';
import * as types from './actionTypes';

export const url = '/cms/data/v1/roles/';

export const credentials = 'include';

export const headers = { Accept: 'application/json', 'Content-Type': 'application/json' };

export const select = ids => ({ type: types.ROLES_SELECT, ids });

export const unselectAll = () => ({ type: types.ROLES_UNSELECT_ALL });

export const request = () => ({ type: types.ROLES_REQUEST });

export const requestWithLoadingStatus = () => ({ type: types.ROLES_REQUEST_WITH_LOADING_STATUS });

export const requestFailed = error => ({ type: types.ROLES_REQUEST_FAILED, error });

export const requestSuccessRoles = result => ({
  type: types.ROLES_REQUEST_SUCCESS_ROLES,
  result,
});

export const requestSuccessRole = result => ({ type: types.ROLES_REQUEST_SUCCESS_ROLE, result });

export const clearRole = () => ({ type: types.ROLES_CLEAR_ROLE });

export const fetchRoles = () =>
  fetch(url, { method: 'GET', credentials, headers })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const fetchRole = id =>
  fetch(url + id, { method: 'GET', credentials, headers })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const addRole = role =>
  fetch(url, { method: 'POST', credentials, headers, body: JSON.stringify(role) })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const updateRole = (id, role) =>
  fetch(url + id, { method: 'PUT', credentials, headers, body: JSON.stringify(role) })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const deleteRole = id =>
  fetch(url + id, { method: 'DELETE', credentials, headers })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const getRoles = () => dispatch => {
  dispatch(requestWithLoadingStatus());

  return fetchRoles().then(
    res => dispatch(requestSuccessRoles(res)),
    err => dispatch(requestFailed(err)),
  );
};

export const getRole = id => dispatch => {
  dispatch(requestWithLoadingStatus());

  return fetchRole(id).then(
    res => dispatch(requestSuccessRole(res)),
    err => dispatch(requestFailed(err)),
  );
};
