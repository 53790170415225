import * as types from '../actions/actionTypes';
import { getHumanDate } from '../utils/humanDate';

export const initialState = {
  current: [],
  history: [],
};

export default function notifications(state = initialState, action = {}) {
  switch (action.type) {
    case types.ADD_NOTIFICATION: {
      const item = { ...action.item, timestamp: getHumanDate(new Date()) };

      return {
        current: [item, ...state.current],
        history: [item, ...state.history],
      };
    }

    case types.REMOVE_NOTIFICATION:
      return {
        ...state,
        current: state.current.filter(item => item.id !== action.item.id),
      };

    case types.REMOVE_ALL_NOTIFICATIONS:
      return {
        ...state,
        current: [],
      };

    default:
      return state;
  }
}
