import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { format, sub } from 'date-fns';

export default class DeviceUptimeDiagram extends Component {
  state = {
    width: this.props.width || 100,
    height: this.props.height || 150,
    heartbeat: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], // to trigger CSS animation
  };

  componentDidMount() {
    // force React to re-render the columns in order to trigger the CSS animation
    this.setHeartbeat(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setHeartbeat(nextProps);
  }

  setHeartbeat = props => {
    if (props.device) {
      this.setState({ heartbeat: props.device.heartbeat });
    }
  };

  getWidthUnit = () => {
    const { width } = this.props;

    return width ? 'px' : '%';
  };

  addRows = () => {
    const { height } = this.state;
    const rowHeight = height / 10;
    const rows = [];
    let top;
    let text;

    for (let index = 0; index < 10; index += 1) {
      top = `${index * rowHeight}px`;
      text = `${(10 - index) * 10}%`;
      rows.push(
        <span key={index} style={{ top }}>
          <span>{text}</span>
        </span>,
      );
    }

    return rows;
  };

  addColumns = () => {
    const { width: stateWidth, height: stateHeight, heartbeat } = this.state;
    const maxColWidth = stateWidth / 24;
    const unit = this.getWidthUnit();

    return heartbeat.map((item, index) => {
      const percent = Math.min((item / 3600) * 100, 100).toFixed(2);
      const height = Math.round((stateHeight - 2) * (percent / 100));
      const width = `calc(${maxColWidth}${unit} - 1px)`;
      const left = index * maxColWidth + unit;
      const time = format(sub(new Date(), { hours: 24 - index }), 'HH:mm');

      return (
        <div key={index} title={percent} style={{ left, width, height }}>
          <div>{parseInt(percent, 10)}</div>
          <span className="visible-lg-block">
            {time}
            <span>-{24 - index}h</span>
          </span>
        </div>
      );
    });
  };

  render() {
    const { device } = this.props;
    const { width, height } = this.state;

    if (!device) {
      return null;
    }

    const unit = this.getWidthUnit();

    return (
      <div className="device-uptime-diagram-container">
        <div className="device-uptime-diagram" style={{ width: width + unit, height }}>
          {this.addRows()}
          {this.addColumns()}
        </div>
      </div>
    );
  }
}

DeviceUptimeDiagram.propTypes = {
  device: PropTypes.object,
  width: PropTypes.number,
  height: PropTypes.number,
};
