import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Prompt } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { SOLR_DELAY } from '../../../../constants';
import { loadFolders } from '../../../actions/folderActions';
import { getUsers } from '../../../actions/userActions';
import {
  getSigns,
  getSign,
  unpublishSign,
  deleteSign,
  updateSign,
  editSignForm,
  clearSignForm,
} from '../../../actions/signActions';
import { loadSequences } from '../../../actions/sequenceActions';
import { loadSchedules } from '../../../actions/scheduleActions';
import { loadPlaylists } from '../../../actions/playlistActions';
import { loadCampaigns } from '../../../actions/campaignActions';
import { loadAllAssignments } from '../../../actions/assignmentActions';
import { addNotification } from '../../../actions/notificationActions';
import ErrorPage from '../ErrorPage/ErrorPage';
import Header from '../../../components/Header/Header';
import QuickAction from '../../../components/QuickAction/QuickAction';
import Confirm from '../../../components/Confirm/Confirm';
import Spinner from '../../../components/Spinner/Spinner';
import { launchCreator } from '../../../utils/launchCreator';
import SignForm from '../../../components/Signs/SignForm';
import Used from '../../../components/Used/Used';
import ActionsMenu from '../../../components/ActionsMenu/ActionsMenu';
import LocationSelector from '../../../components/Locations/LocationSelector';
import errorMessage from '../../../utils/errorMessage';
import { getParent } from '../../../utils/parent';

export class SignPage extends Component {
  state = {
    dirty: false,
    showFolderSelector: false,
  };

  componentDidMount() {
    this.loadData();
    document.addEventListener('visibilitychange', this.loadData);
  }

  componentWillUnmount() {
    const { clearSignForm } = this.props;

    clearSignForm();
    document.removeEventListener('visibilitychange', this.loadData);
  }

  loadData = () => {
    if (document.visibilityState === 'hidden') {
      return;
    }

    const {
      match: {
        params: { id },
      },
      getUsers,
      loadFolders,
      getSigns,
      getSign,
      loadSequences,
      loadSchedules,
      loadPlaylists,
      loadCampaigns,
      loadAllAssignments,
    } = this.props;
    const { dirty } = this.state;

    getUsers();
    loadFolders('?count=100000000');
    getSigns('?count=100000000');
    loadSequences('?count=100000000');
    loadSchedules('?count=100000000');
    loadPlaylists('?count=100000000');
    loadCampaigns('?count=100000000');
    loadAllAssignments();

    if (!dirty && id) {
      getSign(id);
    }
  };

  goBack = () => {
    const { history, previousLocation } = this.props;
    const url = previousLocation
      ? previousLocation.pathname + previousLocation.search
      : '/content?type=sign&parent=/*';

    this.setState({ dirty: false }, () => history.push(url));
  };

  buildSignForServer = () => {
    const {
      signs: {
        sign: { parent },
        signEdit: { name, description, tags },
      },
    } = this.props;

    return {
      name: name.trim(),
      parent: getParent(this.props, parent).id,
      description: (description || '').trim(),
      tags,
    };
  };

  showFolderSelector = () => this.setState({ showFolderSelector: true });

  hideFolderSelector = () => this.setState({ showFolderSelector: false });

  onChange = (key, value) => {
    const { editSignForm } = this.props;
    const { dirty } = this.state;

    editSignForm(key, value);

    if (!dirty) {
      this.setState({ dirty: true });
    }
  };

  edit = () => {
    const {
      signs: {
        sign: { id, parent },
      },
    } = this.props;

    launchCreator(id, getParent(this.props, parent).id);
  };

  unpublish = () => {
    const confirm = this.confirmUnpublish;

    confirm.show('Unpublish Sign', 'Are you sure you want to unpublish this Sign?');
    confirm.onSuccess(this.unpublishConfirmSuccess);
  };

  unpublishConfirmSuccess = () => {
    const {
      match: {
        params: { id },
      },
      addNotification,
    } = this.props;

    return unpublishSign(id).then(
      () => {
        addNotification({ type: 'success', text: 'Sign unpublished.' });
        setTimeout(this.goBack, SOLR_DELAY);
      },
      err => addNotification({ type: 'danger', text: errorMessage(err) }),
    );
  };

  onDelete = () => {
    const confirm = this.confirmDelete;

    confirm.show('Delete Sign', 'Are you sure you want to delete this Sign?');
    confirm.onSuccess(this.delete);
  };

  delete = () => {
    const {
      match: {
        params: { id },
      },
      addNotification,
    } = this.props;

    return deleteSign(id).then(
      () => {
        addNotification({ type: 'success', text: 'Sign deleted.' });
        setTimeout(this.goBack, SOLR_DELAY);
      },
      err => addNotification({ type: 'danger', text: errorMessage(err) }),
    );
  };

  update = () => {
    const {
      match: {
        params: { id },
      },
      addNotification,
    } = this.props;
    const sign = this.buildSignForServer();

    return updateSign(id, sign).then(
      () => {
        addNotification({ type: 'success', text: 'Sign updated.' });
        setTimeout(this.goBack, SOLR_DELAY);
      },
      err => addNotification({ type: 'danger', text: errorMessage(err) }),
    );
  };

  move = parent => {
    const {
      match: {
        params: { id },
      },
      signs,
      getSign,
      addNotification,
    } = this.props;

    this.hideFolderSelector();

    return updateSign(id, { ...signs.sign, parent: parent.id }).then(
      () => {
        addNotification({ type: 'success', text: 'Sign moved.' });
        getSign(id);
      },
      err => addNotification({ type: 'danger', text: errorMessage(err) }),
    );
  };

  getHeaderActions = () => {
    const {
      match: {
        params: { id },
      },
      auth,
    } = this.props;
    const { permissions } = auth.user;
    const actions = [];
    const preview = (
      <QuickAction
        key="1"
        toolText="Preview Sign"
        href={`/player/?sign=${id}`}
        target="_blank"
        alwaysShow
      >
        play_circle_filled
      </QuickAction>
    );
    const edit = (
      <QuickAction key="2" toolText="Open Sign in Creator" onClick={this.edit} alwaysShow>
        <i className="fa fa-paint-brush" />
      </QuickAction>
    );
    const unpublish = (
      <QuickAction key="3" toolText="Unpublish Sign" onClick={this.unpublish} alwaysShow>
        cloud_off
      </QuickAction>
    );
    const move = (
      <QuickAction key="4" toolText="Move Sign" onClick={this.showFolderSelector} alwaysShow>
        subdirectory_arrow_right
      </QuickAction>
    );
    const remove = (
      <QuickAction key="5" toolText="Delete Sign" onClick={this.onDelete} alwaysShow>
        delete
      </QuickAction>
    );

    if (!id) {
      return [];
    }

    actions.push(preview);

    if (permissions.Writer) {
      actions.push(edit, unpublish, move, remove);
    }

    return actions;
  };

  render() {
    const {
      auth,
      users: { users },
      folders: {
        folders: { folders },
      },
      signs: {
        signs: { signs },
        sign,
        signEdit,
        loadingSign,
        error,
      },
      sequences: {
        sequences: { sequences },
      },
      schedules: {
        schedules: { schedules },
      },
      playlists: {
        playlists: { playlists },
      },
      campaigns: {
        campaigns: { campaigns },
      },
      assignments: {
        allAssignments: { assignments },
      },
    } = this.props;
    const { dirty, showFolderSelector } = this.state;

    if (error) {
      return <ErrorPage error={error} />;
    }

    return (
      <div className="page-structure">
        <Helmet title="Signs" />
        <Header overwrite={{ id: sign }} />
        <ActionsMenu actions={this.getHeaderActions()} />
        <div className="page-panels">
          <div className="page-content page-sign">
            <div className="preview">
              <img
                className="center-block sign-preview"
                src={`/cms/data/v1/signs/${sign.id}/pending/thumbnail?${sign.modifiedDate || ''}`}
                alt={sign.name}
              />
            </div>
            <Spinner className="signform-loader" loading={loadingSign}>
              <Prompt
                when={dirty}
                message="You have unsaved changes. Are you sure you want to leave this page?"
              />
              <SignForm
                auth={auth}
                users={users}
                signs={signs}
                sign={signEdit}
                onChange={this.onChange}
                onUpdate={this.update}
                onCancel={this.goBack}
              />
              <Used
                type="sign"
                item={sign}
                signs={signs}
                sequences={sequences}
                schedules={schedules}
                playlists={playlists}
                campaigns={campaigns}
                assignments={assignments}
                auth={auth}
                users={users}
              />
            </Spinner>
          </div>
        </div>
        <Confirm
          ref={confirm => {
            this.confirmUnpublish = confirm;
          }}
        />
        <Confirm
          ref={confirm => {
            this.confirmDelete = confirm;
          }}
        />
        <LocationSelector
          type="folder"
          show={showFolderSelector}
          onHide={this.hideFolderSelector}
          onSuccess={this.move}
          items={folders}
          currentPath={sign.parent}
        />
      </div>
    );
  }
}

SignPage.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  previousLocation: PropTypes.object,
  users: PropTypes.object.isRequired,
  folders: PropTypes.object.isRequired,
  signs: PropTypes.object.isRequired,
  sequences: PropTypes.object.isRequired,
  schedules: PropTypes.object.isRequired,
  playlists: PropTypes.object.isRequired,
  campaigns: PropTypes.object.isRequired,
  assignments: PropTypes.object.isRequired,
  getUsers: PropTypes.func.isRequired,
  loadFolders: PropTypes.func.isRequired,
  getSign: PropTypes.func.isRequired,
  getSigns: PropTypes.func.isRequired,
  loadSequences: PropTypes.func.isRequired,
  loadSchedules: PropTypes.func.isRequired,
  loadPlaylists: PropTypes.func.isRequired,
  loadCampaigns: PropTypes.func.isRequired,
  loadAllAssignments: PropTypes.func.isRequired,
  editSignForm: PropTypes.func.isRequired,
  clearSignForm: PropTypes.func.isRequired,
  addNotification: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  previousLocation: state.app.previousLocation,
  users: state.users,
  folders: state.folders,
  signs: state.signs,
  sequences: state.sequences,
  schedules: state.schedules,
  playlists: state.playlists,
  campaigns: state.campaigns,
  assignments: state.assignments,
});
const mapDispatchToProps = {
  loadFolders,
  getSigns,
  getSign,
  getUsers,
  editSignForm,
  clearSignForm,
  loadSequences,
  loadSchedules,
  loadPlaylists,
  loadCampaigns,
  loadAllAssignments,
  addNotification,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignPage));
