import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';

const PasswordPrompt = ({ show, onSuccess }) => (
  <Modal className="update-prompt" bsSize="lg" backdrop="static" show={show}>
    <Modal.Header>
      <Modal.Title data-test="modal-password-title">Please update your password</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>You have been requested to change your password by a System Administrator.</p>
    </Modal.Body>
    <Modal.Footer>
      <Button data-test="modal-password-button-update" onClick={onSuccess} bsStyle="primary">
        Update Password
      </Button>
    </Modal.Footer>
  </Modal>
);

PasswordPrompt.propTypes = {
  show: PropTypes.bool.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default PasswordPrompt;
