import 'isomorphic-fetch';
import fetchCheck from '../redux/middleware/fetchCheck';
import * as types from './actionTypes';

export const url = '/cms/data/v1/campaign-schedule/';

export const credentials = 'include';

export const headers = { Accept: 'application/json', 'Content-Type': 'application/json' };

export const campaignScheduleRequest = () => ({ type: types.CAMPAIGN_SCHEDULE_REQUEST });

export const campaignScheduleRequestSuccess = result => ({
  type: types.CAMPAIGN_SCHEDULE_REQUEST_SUCCESS,
  result,
});

export const campaignScheduleRequestFailed = error => ({
  type: types.CAMPAIGN_SCHEDULE_REQUEST_FAILED,
  error,
});

export const fetchCampaignSchedule = () =>
  fetch(url, { method: 'GET', credentials, headers })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const updateCampaignSchedule = schedule =>
  fetch(url, { method: 'PUT', credentials, headers, body: JSON.stringify(schedule) }).then(res =>
    fetchCheck(res),
  );

export const getCampaignSchedule = () => dispatch => {
  dispatch(campaignScheduleRequest());

  return fetchCampaignSchedule().then(
    res => dispatch(campaignScheduleRequestSuccess(res)),
    err => dispatch(campaignScheduleRequestFailed(err)),
  );
};
