import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  FormGroup,
  FormControl,
  ControlLabel,
  HelpBlock,
  Grid,
  Row,
  Col,
  Button,
  Checkbox,
} from 'react-bootstrap';
import TagCloud from '../TagCloud/TagCloud';
import Metadata from '../Metadata/Metadata';

export default class CampaignForm extends Component {
  state = {
    showErrors: false,
    errors: {},
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { showErrors } = this.state;

    if (showErrors) {
      this.validate(nextProps);
    }
  }

  onChange = event => {
    const { onChange } = this.props;

    onChange(event.target.name, event.target.value);
  };

  onChangeTag = tags => {
    const { onChange } = this.props;

    onChange('tags', tags);
  };

  onChangeCommander = () => {
    const {
      campaign: { commander_enabled },
      onChange,
    } = this.props;

    onChange('commander_enabled', !commander_enabled);
  };

  errorClass = error => (error ? 'error' : null);

  validate = nextProps => {
    const props = nextProps || this.props;
    const {
      campaign: { name },
    } = props;
    const errors = {};

    if (!name || !name.trim()) {
      errors.name = 'This field cannot be empty.';
    } else if (!this.isUniqueName(props)) {
      errors.name = 'This name is already in use.';
    }

    this.setState({ showErrors: true, errors });

    return !errors.name;
  };

  submit = event => {
    const { isNew, onAdd, onUpdate } = this.props;

    event.preventDefault();

    if (!this.validate()) {
      return;
    }

    if (isNew) {
      onAdd();
    } else {
      onUpdate();
    }
  };

  isUniqueName = props => {
    const { campaigns, campaign } = props;
    const name = campaign.name.toLowerCase().trim();

    return !campaigns.some(item => {
      const itemName = item.name.toLowerCase().trim();

      return +item.id !== +campaign.id && itemName === name;
    });
  };

  getButtons = () => {
    const {
      auth: {
        user: {
          permissions: { Writer },
        },
      },
      isNew,
      onCancel,
    } = this.props;
    const add = (
      <Button key="1" data-test="button-add" bsStyle="success" type="submit">
        Add Campaign
      </Button>
    );
    const update = (
      <Button key="2" data-test="button-update" bsStyle="success" type="submit">
        Update Campaign
      </Button>
    );
    const cancel = (
      <Button key="3" data-test="button-cancel" onClick={onCancel}>
        Cancel
      </Button>
    );

    if (!Writer) {
      return [cancel];
    }

    return isNew ? [cancel, add] : [cancel, update];
  };

  render() {
    const {
      auth: {
        user: {
          commander_enabled,
          permissions: { Writer, Deployer },
        },
      },
      users,
      campaign,
    } = this.props;
    const { errors } = this.state;

    if (!campaign) {
      return null;
    }

    const { name, description, tags } = campaign;

    return (
      <form onSubmit={this.submit}>
        <Grid fluid>
          <Row>
            <Col md={6}>
              <FormGroup validationState={this.errorClass(errors.name)}>
                <ControlLabel>Name</ControlLabel>
                <FormControl
                  data-test="input-name"
                  name="name"
                  value={name}
                  onChange={this.onChange}
                  disabled={!Writer}
                />
                <HelpBlock>{errors.name}</HelpBlock>
              </FormGroup>
              <FormGroup>
                <ControlLabel>Description</ControlLabel>
                <FormControl
                  data-test="input-desc"
                  name="description"
                  componentClass="textarea"
                  rows="4"
                  placeholder="Enter description"
                  value={description}
                  onChange={this.onChange}
                  disabled={!Writer}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <ControlLabel>Tags</ControlLabel>
                <TagCloud tags={tags} onChange={this.onChangeTag} disabled={!Writer} />
              </FormGroup>
              {Boolean(commander_enabled) && (
                <FormGroup>
                  <Checkbox
                    data-test="checkbox-commander"
                    name="commander_enabled"
                    checked={campaign.commander_enabled}
                    onChange={this.onChangeCommander}
                    disabled={!Deployer}
                  >
                    Commander enabled
                  </Checkbox>
                </FormGroup>
              )}
            </Col>
          </Row>
        </Grid>
        <Metadata data={campaign} users={users} />
        <div className="form-buttons">{this.getButtons()}</div>
      </form>
    );
  }
}

CampaignForm.propTypes = {
  auth: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
  campaigns: PropTypes.array,
  campaign: PropTypes.object,
  isNew: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
