const getAssignmentsForCampaign = (campaign, sequences, schedules, playlists, assignments) => {
  if (!campaign || !sequences || !schedules || !playlists || !assignments) {
    return [];
  }

  return assignments
    .filter(item => item.campaign_id === campaign.id && item.content_name)
    .map(item => {
      if (item.content_type === 'sequence') {
        const sequence = sequences.find(seq => +seq.id === +item.content_id);

        if (sequence) {
          item.sequenceEntries = sequence.sequenceEntries;
        }
      }

      if (item.content_default_stream_type === 'playlist') {
        const playlist = playlists.find(pl => +pl.id === +item.content_default_stream_id);

        if (playlist) {
          item.playlistEntries = playlist.playlistEntries;
        }
      }

      return item;
    })
    .sort((a, b) => a.name.localeCompare(b.name));
};

export default getAssignmentsForCampaign;
