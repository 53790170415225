const getSchedulesForSign = (sign, sequences, schedules) => {
  if (!sign || !sequences || !schedules) {
    return [];
  }

  return schedules
    .filter(schedule =>
      schedule.scheduleEntries.find(item => {
        if (item.sign_id) {
          return item.sign_id === +sign.id;
        }

        if (item.sequence_id) {
          const sequence = sequences.find(seq => seq.id === item.sequence_id);

          if (sequence) {
            return sequence.sequenceEntries.find(entry => entry.sign_id === +sign.id);
          }
        }

        return false;
      }),
    )
    .sort((a, b) => a.name.localeCompare(b.name));
};

export default getSchedulesForSign;
