import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, FormGroup, FormControl, ControlLabel, HelpBlock } from 'react-bootstrap';
import TagCloud from '../TagCloud/TagCloud';

export default class AddLocationDialog extends Component {
  getInitialState = () => ({
    name: '',
    description: '',
    tags: [],
    error: null,
    showError: false,
  });

  state = this.getInitialState();

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { show } = nextProps;

    if (!show) {
      this.setState(this.getInitialState());
    }
  }

  onChange = event => {
    const { showError } = this.state;
    const { name, value } = event.target;

    if (name === 'name' && (value.includes('*') || value.includes('/'))) {
      return;
    }

    this.setState({ [name]: value }, () => {
      if (showError) {
        this.validate();
      }
    });
  };

  onChangeTags = tags => this.setState({ tags });

  add = () => {
    const { onSuccess } = this.props;
    const { name, description, tags } = this.state;

    if (this.validate()) {
      onSuccess({ name, description, tags });
    }
  };

  errorClass = error => (error ? 'error' : null);

  validate = () => {
    const { name } = this.state;
    let error = null;

    if (!name.trim()) {
      error = 'This field cannot be empty.';
    } else if (!this.isUniqueSiblingName(name)) {
      error = 'This is already in use.';
    }

    this.setState({ showError: true, error });

    return !error;
  };

  isUniqueSiblingName = name => {
    const { locations, parent } = this.props;
    const siblings = locations.filter(item => item.parent === parent);

    return !siblings.some(item => item.name.toLowerCase().trim() === name.toLowerCase().trim());
  };

  render() {
    const { type = 'location', show, onHide } = this.props;
    const { name, description, tags, error } = this.state;
    const capitalisedType = type.charAt(0).toUpperCase() + type.slice(1);

    return (
      <Modal
        dialogClassName="location-dialog"
        bsSize="lg"
        backdrop="static"
        show={show}
        onHide={onHide}
      >
        <Modal.Header>
          <Modal.Title data-test="modal-addlocation-title">Add {capitalisedType}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ControlLabel>Name</ControlLabel>
          <FormGroup validationState={this.errorClass(error)}>
            <FormControl
              data-test="modal-addlocation-input-name"
              name="name"
              placeholder="Enter a name"
              value={name}
              onChange={this.onChange}
              autoFocus
            />
            <HelpBlock>{error}</HelpBlock>
          </FormGroup>
          <FormGroup>
            <ControlLabel>Description</ControlLabel>
            <FormControl
              data-test="modal-addlocation-input-desc"
              name="description"
              componentClass="textarea"
              rows="3"
              placeholder="Enter description"
              value={description}
              onChange={this.onChange}
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>Tags</ControlLabel>
            <TagCloud tags={tags} onChange={this.onChangeTags} />
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn-cancel" onClick={onHide}>
            Cancel
          </Button>
          <Button
            data-test="modal-addlocation-button-add"
            className="btn-add"
            bsStyle="primary"
            onClick={this.add}
          >
            Add {capitalisedType}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

AddLocationDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  type: PropTypes.string,
  locations: PropTypes.array,
  parent: PropTypes.string,
};
