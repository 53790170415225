// simplify and flatten user permissions and other client/user data for easy access
export const getPermissions = auth => {
  const { user } = auth || {};

  if (!user) {
    return {};
  }

  const { client: c, user: u, permissions: p, Modules: m } = user;
  const r = {};

  r.downloadSchedule = Boolean(user.downloadSchedule);

  // r.tierLite = c.tier === 0;
  // r.tierStandard = c.tier === 1;
  // r.tierPro = c.tier === 2;

  r.atLeastTierStandardOrSuperUser = c.tier > 0 || Boolean(u.superuser);
  r.atLeastTierStandard = c.tier > 0;
  r.atLeastTierPro = c.tier > 1;
  r.tierText = ['Lite', '', 'Enterprise'][c.tier];

  r.superuser = Boolean(u.superuser);

  r.reader = p.Reader;
  r.contentViewer = p['Content Viewer'];
  r.writer = p.Writer;
  r.approver = p.Approver;
  r.deployer = p.Deployer;
  r.assigner = p.Assigner;
  r.admin = p.Admin;
  r.locker = p.Locker;
  r.commander = p.Commander;
  r.deviceViewer = p['Device Viewer'];
  r.deviceManager = p['Device Manager'];

  r.hasModules = m.length > 0;
  r.moduleDataObject = m.some(mod => mod.name === 'DataObject');
  r.moduleUrlRuleset = m.some(mod => mod.name === 'UrlRuleset');

  r.landingpage = c.landingpage || null;

  return r;
};
