import 'isomorphic-fetch';
import fetchCheck from '../redux/middleware/fetchCheck';
import * as types from './actionTypes';

export const url = '/cms/data/v1/schedules/';

export const credentials = 'include';

export const headers = { Accept: 'application/json', 'Content-Type': 'application/json' };

export const schedulesRequest = () => ({ type: types.SCHEDULES_REQUEST });

export const schedulesRequestSuccess = result => ({
  type: types.SCHEDULES_REQUEST_SUCCESS,
  result,
});

export const schedulesRequestFailed = error => ({ type: types.SCHEDULES_REQUEST_FAILED, error });

export const scheduleRequest = () => ({ type: types.SCHEDULE_REQUEST });

export const scheduleRequestSuccess = result => ({
  type: types.SCHEDULE_REQUEST_SUCCESS,
  result,
});

export const scheduleRequestFailed = error => ({ type: types.SCHEDULE_REQUEST_FAILED, error });

export const fetchSchedules = query =>
  fetch(url + (query || ''), { method: 'GET', credentials, headers })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const fetchSchedule = id =>
  fetch(url + id, { method: 'GET', credentials, headers })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const createSchedule = schedule =>
  fetch(url, { method: 'POST', credentials, headers, body: JSON.stringify(schedule) })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const updateSchedule = (id, schedule) =>
  fetch(url + id, { method: 'PUT', credentials, headers, body: JSON.stringify(schedule) })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const deleteSchedule = id =>
  fetch(url + id, { method: 'DELETE', credentials, headers }).then(res => fetchCheck(res));

export const loadSchedules = query => dispatch => {
  dispatch(schedulesRequest());

  return fetchSchedules(query).then(
    res => dispatch(schedulesRequestSuccess(res)),
    err => dispatch(schedulesRequestFailed(err)),
  );
};

export const loadSchedule = id => dispatch => {
  dispatch(scheduleRequest());

  return fetchSchedule(id).then(
    res => dispatch(scheduleRequestSuccess(res)),
    err => dispatch(scheduleRequestFailed(err)),
  );
};
