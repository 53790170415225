import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toggleFilters } from '../../actions/filterActions';
import QuickAction from '../QuickAction/QuickAction';

export class ActionsMenu extends Component {
  getActions = () => {
    const { actions, selectedLength } = this.props;

    if (!actions) {
      return null;
    }

    const filteredActions = [];

    actions.forEach(item => {
      const { alwaysShow, singular, multiple } = item.props;

      if (alwaysShow) {
        filteredActions.push(item);
      } else if (singular && selectedLength === 1) {
        filteredActions.push(item);
      } else if (multiple && selectedLength > 1) {
        filteredActions.push(item);
      }
    });

    return filteredActions;
  };

  getFilter = () => {
    const { filterButton, toggleFilters } = this.props;

    if (!filterButton) {
      return null;
    }

    return (
      <QuickAction toolText="Toggle Filter" onClick={toggleFilters} alwaysShow>
        filter_list
      </QuickAction>
    );
  };

  render() {
    return (
      <div className="actions-bar">
        {this.getFilter()}
        {this.getActions()}
      </div>
    );
  }
}

ActionsMenu.propTypes = {
  actions: PropTypes.array,
  selectedLength: PropTypes.number,
  filterButton: PropTypes.bool,
  toggleFilters: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  filter: state.filter,
});
const mapDispatchToProps = {
  toggleFilters,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionsMenu);
