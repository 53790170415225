import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Alert, Modal, Button, FormGroup, FormControl } from 'react-bootstrap';
import pluralize from 'pluralize';
import ToolTipIcon from '../ToolTipIcon/ToolTipIcon';
import { buildTagList } from '../../utils/tagList';

const ACCESS_RESTRICTED = 'access restricted';

export default class LocationSelector extends Component {
  getInitialState = () => ({
    maxHeight: 0,
    treeView: true,
    location: null,
    currentLocation: null,
    rootLocation: { id: 0, name: '', parent: '' },
    filter: '',
    filteredLocations: [],
  });

  state = this.getInitialState();

  componentDidMount() {
    this.updateHeight();
    window.addEventListener('resize', this.updateHeight);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { show } = this.props;
    const { show: nextShow, items } = nextProps;
    const { filter } = this.state;

    // while open
    if (nextShow) {
      this.setState({
        filteredLocations: this.getFilteredLocations(items, filter),
      });
    }

    // on open
    if (!show && nextShow) {
      this.updateHeight();
      this.setCurrent(nextProps);
    }

    // on close
    if (show && !nextShow) {
      this.setState(this.getInitialState());
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateHeight);
  }

  updateHeight = () => {
    const { type } = this.props;

    this.setState({
      maxHeight: window.innerHeight - 342 - (!type || type === 'location' ? 71 : 0),
    });
  };

  changeView = () => {
    const { treeView } = this.state;

    this.setState({ treeView: !treeView });
  };

  onFilter = event => {
    const { items } = this.props;
    const filter = event.target.value.toLowerCase();

    this.setState({
      filter,
      filteredLocations: this.getFilteredLocations(items, filter),
    });
  };

  getFilteredLocations = (items, filterStr) => {
    const { rootLocation } = this.state;
    const filters = filterStr.split(' ').filter(item => item);

    return [rootLocation, ...items].filter(item => {
      if (!filters.length) {
        return true;
      }

      return !filters.find(
        filter =>
          !(
            item.name.toLowerCase().includes(filter) ||
            item.parent.toLowerCase().includes(filter) ||
            (item.tags && item.tags.some(tag => tag.toLowerCase().includes(filter)))
          ),
      );
    });
  };

  setCurrent = props => {
    const { items, currentId, currentPath } = props;
    const { rootLocation } = this.state;
    let currentLocation;

    if (currentPath) {
      currentLocation =
        items.find(item => `${item.parent + item.name}/` === currentPath) || rootLocation;
    }

    if (currentId !== undefined) {
      currentLocation = items.find(item => +item.id === +currentId) || rootLocation;
    }

    if (currentId !== undefined || currentPath) {
      this.setState({ currentLocation });
    }
  };

  onClick = location => this.setState({ location });

  isFiltered = location => {
    const { filteredLocations } = this.state;

    return !filteredLocations.find(item => +item.id === +location.id);
  };

  isCurrent = location => {
    const { currentLocation } = this.state;

    return currentLocation ? location.id === currentLocation.id : false;
  };

  isMoving = location => {
    const { moving } = this.props;

    if (!moving || !moving.length || !moving[0].parent) {
      return false;
    }

    return moving.some(item => {
      const id = item.content_id || item.id;

      return +id === +location.id;
    });
  };

  isNameConflictBeforeMove = () => {
    const { moving } = this.props;

    if (!moving || !moving.length || !moving[0].parent) {
      return false;
    }

    const names = moving.map(item => item.name.toLowerCase());

    while (names.length) {
      const name = names.pop();

      if (names.includes(name)) {
        return name;
      }
    }

    return false;
  };

  isNameConflictAfterMove = location => {
    const { items, moving } = this.props;

    if (!moving || !moving.length || !moving[0].parent) {
      return false;
    }

    const parent = location.parent ? `${location.parent + location.name}/` : '/';
    const siblings = items.filter(item => item.parent === parent);
    const conflict = moving.find(item =>
      siblings.find(sibling => item.name.toLowerCase() === sibling.name.toLowerCase()),
    );

    return conflict ? conflict.name : false;
  };

  isAccessRestricted = (item, location) => {
    const { userLocation } = this.props;

    if (!userLocation) {
      return false;
    }

    const { parent, name } = item;
    const thisLocation = `${parent}${name}/`;

    return !(userLocation === thisLocation || location.startsWith(userLocation));
  };

  getIcon = () => {
    const { type } = this.props;

    return <i className="asset-icon material-icons earth">{type ? 'folder' : 'public'}</i>;
  };

  getLocations = (location, parentLevel, parentDisabled) => {
    const { type = 'location', items } = this.props;
    const { location: stateLocation, currentLocation } = this.state;
    const parent = `${location ? location.parent + location.name : ''}/`;
    const level = parentLevel ? parentLevel + 1 : 1;

    return items
      .filter(item => item.parent === parent)
      .map((item, index) => {
        const isCurrent = this.isCurrent(item);
        const isMoving = this.isMoving(item);
        const isAccessRestricted = this.isAccessRestricted(item, parent);
        const disabled = (!currentLocation && parentDisabled) || isMoving || isCurrent;
        const selected = +stateLocation?.id === +item.id;
        const conflict = disabled ? null : this.isNameConflictAfterMove(item);
        const current = isCurrent ? <small>current</small> : null;
        const accessRestricted =
          !isCurrent && isAccessRestricted ? <small>{ACCESS_RESTRICTED}</small> : null;
        const moving =
          !isCurrent && !isAccessRestricted && disabled ? (
            <small>{isMoving ? 'moving' : 'parent moving'}</small>
          ) : null;
        const error = conflict ? (
          <small>
            contains {type}: {conflict}
          </small>
        ) : null;

        return (
          <li key={item.id} className={this.isFiltered(item) ? 'filtered' : ''}>
            <div
              data-test={`modal-locationselector-item-${level}-${index + 1}`}
              className={`item level-${level}${selected ? ' selected' : ''}${
                disabled || conflict || isAccessRestricted ? ' disabled' : ''
              }${isCurrent || isMoving ? ' bg-info' : ''}`}
              onClick={() => {
                if (!disabled && !conflict && !isAccessRestricted) {
                  this.onClick(item);
                }
              }}
            >
              <div>
                <i className="next-location material-icons">subdirectory_arrow_right</i>
                {this.getIcon()}
                {item.name}
              </div>
              <div className="info">
                {error}
                {current}
                {moving}
                {!error && accessRestricted}
              </div>
              <div className="details">
                <div>Parent: {item.parent}</div>
                <div>Tags: {buildTagList(item)}</div>
              </div>
            </div>
            <ul>{this.getLocations(item, level, disabled)}</ul>
          </li>
        );
      });
  };

  render() {
    const { type = 'location', show, onHide, onSuccess, items, moving, userLocation } = this.props;
    const {
      maxHeight: maxheight,
      treeView,
      location,
      rootLocation,
      filter,
      filteredLocations,
    } = this.state;
    const capitalisedType = type.charAt(0).toUpperCase() + type.slice(1);
    const isCurrent = this.isCurrent(rootLocation);
    const conflictBeforeMove = this.isNameConflictBeforeMove();
    const conflict = this.isNameConflictAfterMove(rootLocation);
    const current = isCurrent ? <small>current</small> : null;
    const error = conflict ? (
      <small>
        contains {type}: {conflict}
      </small>
    ) : null;
    const text = moving ? 'Move Here' : 'Select';

    return (
      <Modal
        className="location-selector"
        bsSize="lg"
        backdrop="static"
        show={show}
        onHide={onHide}
      >
        <Modal.Header>
          <Modal.Title data-test="modal-locationselector-title">
            Select {capitalisedType}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {Boolean(conflictBeforeMove) && (
            <div>
              Error, name is not unique: <b>{conflictBeforeMove}</b>. You cannot move items with the
              same name.
            </div>
          )}
          {!conflictBeforeMove && (
            <div>
              <ToolTipIcon
                className={`view ${filter ? 'disabled' : ''}`}
                toolText="Change View"
                onClick={this.changeView}
              >
                {filter || !treeView ? 'view_quilt' : 'view_list'}
              </ToolTipIcon>
              <div className="results">
                <p>
                  {filter && `${pluralize(' result', filteredLocations.length, true)}. `}
                  There {pluralize('is', items.length)} {pluralize(type, items.length, true)}{' '}
                  available.
                </p>
                <p>To filter on multiple terms, separate them with a space.</p>
              </div>
              <FormGroup>
                <FormControl
                  data-test="modal-locationselector-input-filter"
                  name="filter"
                  placeholder="Start typing to filter results"
                  onChange={this.onFilter}
                  autoFocus
                />
              </FormGroup>
              {filteredLocations.length > 0 && (
                <div>
                  <ul
                    data-test="modal-locationselector-list"
                    style={{ maxHeight: maxheight }}
                    className={filter || !treeView ? '' : 'treeview'}
                  >
                    <li key="root" className={this.isFiltered(rootLocation) ? 'filtered' : ''}>
                      <div
                        data-test="modal-locationselector-item-0"
                        className={`item root ${location && location.id === 0 ? ' selected' : ''}${
                          isCurrent || conflict || userLocation ? ' disabled' : ''
                        }${isCurrent ? ' bg-info' : ''}`}
                        onClick={() => {
                          if (!isCurrent && !conflict && !userLocation) {
                            this.onClick(rootLocation);
                          }
                        }}
                      >
                        <div>
                          {this.getIcon()}
                          {rootLocation.name}
                        </div>
                        <div className="info">
                          {error}
                          {current}
                          {userLocation && <small>{ACCESS_RESTRICTED}</small>}
                        </div>
                      </div>
                      <ul>{this.getLocations()}</ul>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          )}
          {type === 'location' && moving && (
            <Alert bsStyle="warning">
              Moving {pluralize('this', moving.length)} {pluralize('Location', moving.length)} will
              trigger a restart of the Devices within.
            </Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button data-test="modal-locationselector-button-cancel" onClick={onHide}>
            Cancel
          </Button>
          <Button
            data-test="modal-locationselector-button-select"
            bsStyle="primary"
            onClick={() => onSuccess(location)}
            disabled={!location}
          >
            {text}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

LocationSelector.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  type: PropTypes.string,
  items: PropTypes.array.isRequired,
  moving: PropTypes.array,
  currentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currentPath: PropTypes.string,
  userLocation: PropTypes.string,
};
