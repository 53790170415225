import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';

export default class AddUrlRuleDialog extends Component {
  getInitialState = () => ({
    type: 'white',
    rule: 'http://',
    beginsWith: true,
  });

  state = this.getInitialState();

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { show } = nextProps;

    if (!show) {
      this.setState(this.getInitialState());
    }
  }

  handleChangeType = event => {
    const type = event.target.value;

    this.setState({ type });

    if (type === 'black') {
      this.setState({ beginsWith: false });
    }
  };

  handleChangeBeginsWith = () => {
    const { beginsWith } = this.state;

    this.setState({ beginsWith: !beginsWith });
  };

  handleChangeRule = event => this.setState({ rule: event.target.value });

  handleClickAddUrlRule = () => {
    const { onSuccess } = this.props;

    onSuccess(this.state);
  };

  render() {
    const { show, onHide } = this.props;
    const { type, rule, beginsWith } = this.state;

    return (
      <Modal dialogClassName="url-ruleset-dialog" backdrop="static" show={show} onHide={onHide}>
        <Modal.Header>
          <Modal.Title>Add URL Rule</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group select-type">
            <label>Type</label>
            <select className="form-control" value={type} onChange={this.handleChangeType}>
              <option value="white">Whitelist</option>
              <option value="black">Blacklist</option>
            </select>
          </div>
          <div className="form-group input-rule">
            <label>URL</label>
            <input
              className="form-control"
              placeholder="Enter a URL"
              value={rule}
              onChange={this.handleChangeRule}
            />
          </div>
          {type === 'white' && (
            <div className="form-group checkbox-beginswith">
              <label>Begins with</label>
              <div>
                <input
                  type="checkbox"
                  checked={beginsWith}
                  onChange={this.handleChangeBeginsWith}
                />
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn-cancel" onClick={onHide}>
            Cancel
          </Button>
          <Button className="btn-add" bsStyle="primary" onClick={this.handleClickAddUrlRule}>
            Add URL Rule
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

AddUrlRuleDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  addNotification: PropTypes.func.isRequired,
};
