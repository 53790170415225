import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  FormGroup,
  FormControl,
  ControlLabel,
  HelpBlock,
  Grid,
  Row,
  Col,
  Button,
} from 'react-bootstrap';
import ToolTipIcon from '../ToolTipIcon/ToolTipIcon';
import TagCloud from '../TagCloud/TagCloud';
import ScheduleEntriesTable from './ScheduleEntriesTable';
import Metadata from '../Metadata/Metadata';
import { getPermissions } from '../../utils/getPermissions';

export default class ScheduleForm extends Component {
  state = {
    showErrors: false,
    errors: {
      name: null,
    },
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { showErrors } = this.state;

    if (showErrors) {
      this.validate(nextProps);
    }
  }

  onChange = event => {
    const { onChange } = this.props;

    onChange(event.target.name, event.target.value);
  };

  onChangeTag = tags => {
    const { onChange } = this.props;

    onChange('tags', tags);
  };

  errorClass = error => (error ? 'error' : null);

  validate = nextProps => {
    const props = nextProps || this.props;
    const {
      schedule: { name },
    } = props;
    const errors = { name: null };
    let valid = true;

    // name
    if (!name || !name.trim()) {
      errors.name = 'This field cannot be empty.';
      valid = false;
    } else if (this.isNameInUse(props)) {
      errors.name = 'This name is already in use.';
      valid = false;
    }

    this.setState({ showErrors: true, errors });

    return valid;
  };

  submit = event => {
    const { isNew, onAdd, onUpdate } = this.props;

    event.preventDefault();

    if (!this.validate()) {
      return;
    }

    if (isNew) {
      onAdd();
    } else {
      onUpdate();
    }
  };

  isNameInUse = props => {
    const { schedules, schedule } = props;

    return schedules.some(item => {
      const name = schedule.name.toLowerCase().trim();
      const itemName = item.name.toLowerCase().trim();

      return item.id !== schedule.id && itemName === name;
    });
  };

  getButtons = () => {
    const { auth, isNew, onCancel } = this.props;
    const p = getPermissions(auth);
    const add = (
      <Button key="1" data-test="button-add" bsStyle="success" type="submit">
        Add Schedule
      </Button>
    );
    const update = (
      <Button key="2" data-test="button-update" bsStyle="success" type="submit">
        Update Schedule
      </Button>
    );
    const cancel = (
      <Button key="3" data-test="button-cancel" onClick={onCancel}>
        Cancel
      </Button>
    );

    if (!p.writer) {
      return [cancel];
    }

    return isNew ? [cancel, add] : [cancel, update];
  };

  render() {
    const {
      auth,
      users,
      folders,
      signs,
      schedule,
      entries,
      onSort,
      onChangeDate,
      onChangeHour,
      onChangeMinute,
      onChangeRepeat,
      onChangeDays,
      onRemoveEntry,
      onClickAddSign,
      onClickAddSequence,
    } = this.props;
    const { errors } = this.state;
    const p = getPermissions(auth);

    if (!schedule) {
      return null;
    }

    const { name = '', description, tags } = schedule;

    return (
      <form onSubmit={this.submit}>
        <Grid fluid>
          <Row>
            <Col md={6}>
              <FormGroup validationState={this.errorClass(errors.name)}>
                <ControlLabel>Name</ControlLabel>
                <FormControl
                  data-test="input-name"
                  name="name"
                  value={name}
                  onChange={this.onChange}
                  disabled={!p.writer}
                />
                <HelpBlock>{errors.name}</HelpBlock>
              </FormGroup>
              <FormGroup>
                <ControlLabel>Description</ControlLabel>
                <FormControl
                  data-test="input-desc"
                  name="description"
                  componentClass="textarea"
                  rows="4"
                  placeholder="Enter description"
                  value={description}
                  onChange={this.onChange}
                  disabled={!p.writer}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <ControlLabel>Tags</ControlLabel>
                <TagCloud tags={tags} onChange={this.onChangeTag} disabled={!p.writer} />
              </FormGroup>
            </Col>
          </Row>
        </Grid>
        <div className="clearfix" />
        {p.writer && (
          <ToolTipIcon
            className="pull-right add-sequence"
            toolText="Add Sequence"
            onClick={onClickAddSequence}
          >
            add_circle
          </ToolTipIcon>
        )}
        {p.writer && p.atLeastTierStandard && (
          <ToolTipIcon className="pull-right" toolText="Add Sign" onClick={onClickAddSign}>
            add_circle_outline
          </ToolTipIcon>
        )}
        <h4>Schedule Entries</h4>
        <ScheduleEntriesTable
          auth={auth}
          folders={folders}
          signs={signs}
          data={entries}
          onSort={onSort}
          onChangeDate={onChangeDate}
          onChangeHour={onChangeHour}
          onChangeMinute={onChangeMinute}
          onChangeRepeat={onChangeRepeat}
          onChangeDays={onChangeDays}
          onRemoveEntry={onRemoveEntry}
        />
        <Metadata data={schedule} users={users} />
        <div className="form-buttons">{this.getButtons()}</div>
      </form>
    );
  }
}

ScheduleForm.propTypes = {
  auth: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
  folders: PropTypes.object.isRequired,
  signs: PropTypes.array.isRequired,
  schedules: PropTypes.array,
  schedule: PropTypes.object,
  entries: PropTypes.array,
  isNew: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
  onChangeDate: PropTypes.func.isRequired,
  onChangeHour: PropTypes.func.isRequired,
  onChangeMinute: PropTypes.func.isRequired,
  onChangeRepeat: PropTypes.func.isRequired,
  onChangeDays: PropTypes.func.isRequired,
  onRemoveEntry: PropTypes.func.isRequired,
  onClickAddSign: PropTypes.func.isRequired,
  onClickAddSequence: PropTypes.func.isRequired,
};
