import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import async from 'async';
import pluralize from 'pluralize';
import { SOLR_DELAY } from '../../../../constants';
import fileTypes from '../../../../common/fileTypes';
import { addNotification } from '../../../actions/notificationActions';
import { load as loadAuth } from '../../../actions/authActions';
import {
  queueUploads,
  uploadAsset,
  uploadRemoveItem,
  getEncodingQueue,
} from '../../../actions/assetActions';
import { getFonts, deleteFont, select, unselectAll } from '../../../actions/fontActions';
import Header from '../../../components/Header/Header';
import Spinner from '../../../components/Spinner/Spinner';
import ActionsMenu from '../../../components/ActionsMenu/ActionsMenu';
import QuickAction from '../../../components/QuickAction/QuickAction';
import Confirm from '../../../components/Confirm/Confirm';
import AssetsUploads from '../../../components/Assets/AssetsUploads';
import UploadFontDialog from '../../../components/Fonts/UploadFontDialog';
import FontsTable from '../../../components/Fonts/FontsTable';
import ErrorPage from '../ErrorPage/ErrorPage';
import { addSupportedAttr } from '../../../utils/file';

export class FontsPage extends Component {
  state = { showUploadFontDialog: false };

  componentDidMount() {
    this.loadData();
    document.addEventListener('visibilitychange', this.loadData);
  }

  componentWillUnmount() {
    const { unselectAll } = this.props;

    unselectAll();
    document.removeEventListener('visibilitychange', this.loadData);
  }

  loadData = () => {
    if (document.visibilityState === 'hidden') {
      return;
    }

    const { getFonts } = this.props;

    getFonts();
  };

  getSelectedItems = () => {
    const {
      fonts: { fonts, selected },
    } = this.props;

    return Object.keys(selected).map(index => fonts[index]);
  };

  getSelectedExceptDefaultAndUsedFonts = () =>
    this.getSelectedItems().filter(item => !item.default && !item.usedInSigns);

  edit = item => {
    const { history } = this.props;

    history.push(`/fonts/${item.id}`);
  };

  upload = allFiles => {
    const { queueUploads } = this.props;

    this.hideUploadFontDialog();
    const files = addSupportedAttr(allFiles).map(file => {
      file.id = Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);

      return file;
    });

    window.SignStix.uploadingFiles.push(...files);
    queueUploads(files);
    const limit = Math.min(window.SignStix.uploadingFiles.length, window.SignStix.uploadingLimit);

    while (window.SignStix.uploading < limit) {
      this.uploadNext();
    }
  };

  uploadNext = () => {
    const { uploadAsset } = this.props;

    if (!window.SignStix.uploadingFiles.length) {
      return;
    }

    const file = window.SignStix.uploadingFiles.shift();

    uploadAsset(file, this.uploadNext);
  };

  delete = () => {
    const items = this.getSelectedExceptDefaultAndUsedFonts();

    async.filter(
      items,
      (item, callback) =>
        deleteFont(item.id).then(
          res => callback(null, res),
          () => callback(null, false),
        ),
      this.deleteCallback,
    );
  };

  deleteCallback = (err, results) => {
    const { unselectAll, loadAuth, getFonts, addNotification } = this.props;
    const items = this.getSelectedExceptDefaultAndUsedFonts();
    let failedItems;
    let errorMsg = '';

    if (items.length === results.length) {
      addNotification({
        type: 'success',
        text: `${pluralize('Font', items.length, true)} deleted.`,
      });
    } else {
      failedItems = items.filter(item => !results.includes(item));
      failedItems.forEach(item => {
        errorMsg += (errorMsg ? ', ' : 'Failed to delete: ') + item.name;
      });
      addNotification({ type: 'danger', text: errorMsg });
    }

    if (results.length) {
      unselectAll();
      setTimeout(() => {
        loadAuth();
        getFonts();
      }, SOLR_DELAY);
    }
  };

  onDelete = () => {
    const items = this.getSelectedExceptDefaultAndUsedFonts();
    const title = `Delete ${pluralize('Font', items.length, true)}`;
    const body = (
      <div>
        <p>
          Are you sure you want to delete {pluralize('this', items.length)}{' '}
          {pluralize('Font', items.length)}?
        </p>
        <ul>
          {items.map((item, index) => (
            <li key={index}>{item.name}</li>
          ))}
        </ul>
      </div>
    );

    this.refDelete.show(title, body);
    this.refDelete.onSuccess(this.delete);
  };

  getCss = () => {
    const {
      fonts: { fonts },
    } = this.props;

    return fonts
      .map(item => {
        const src = [];
        let url;

        if (item.default) {
          url = '/creatorWrapper/creator/style/fonts/';

          if (item.woff2_url) {
            src.push(`url("${url}${item.woff2_url}") format("woff2")`);
          }

          if (item.woff_url) {
            src.push(`url("${url}${item.woff_url}") format("woff")`);
          }

          if (item.ttf_url) {
            if (item.ttf_url.endsWith('.ttf')) {
              src.push(`url("${url}${item.ttf_url}") format("truetype")`);
            } else {
              src.push(`url("${url}${item.ttf_url}") format("opentype")`);
            }
          }
        } else {
          url = `/cms/data/v1/fonts/${item.id}/data/`;

          if (item.woff2_url) {
            src.push(`url("${url}woff2") format("woff2")`);
          }

          if (item.woff_url) {
            src.push(`url("${url}woff") format("woff")`);
          }

          if (item.ttf_url) {
            if (item.ttf_url.endsWith('.ttf')) {
              src.push(`url("${url}ttf") format("truetype")`);
            } else {
              src.push(`url("${url}otf") format("opentype")`);
            }
          }
        }

        return (
          `@font-face { font-family: "${item.filename}"; src: ${src.join(',\n')}; }\n` +
          `.font-${item.id} { font-family: "${item.filename}"; }\n`
        );
      })
      .join('\n');
  };

  showUploadFontDialog = () => this.setState({ showUploadFontDialog: true });

  hideUploadFontDialog = () => this.setState({ showUploadFontDialog: false });

  onClickTable = users => {
    const { select } = this.props;

    select(users);
  };

  onDoubleClickTable = () => this.edit(this.getSelectedItems()[0]);

  getHeaderActions = () => {
    const {
      auth: {
        user: {
          permissions: { Writer },
        },
      },
    } = this.props;
    const actions = [];
    const upload = (
      <QuickAction key="1" toolText="Upload Fonts" onClick={this.showUploadFontDialog} alwaysShow>
        file_upload
      </QuickAction>
    );
    const remove = (
      <QuickAction key="2" toolText="Delete Font" onClick={this.onDelete} singular multiple>
        delete
      </QuickAction>
    );

    if (Writer) {
      actions.push(upload);

      if (this.getSelectedExceptDefaultAndUsedFonts().length) {
        actions.push(remove);
      }
    }

    return actions;
  };

  render() {
    const {
      auth,
      assets: { uploading, encodingQueue },
      fonts: { fonts, error, loading, selected },
      uploadRemoveItem,
      getEncodingQueue,
    } = this.props;
    const { showUploadFontDialog } = this.state;

    if (error) {
      return <ErrorPage error={error} />;
    }

    return (
      <div className="page-structure">
        <Helmet title="Fonts" />
        <Header />
        <ActionsMenu
          actions={this.getHeaderActions()}
          selectedLength={this.getSelectedExceptDefaultAndUsedFonts().length}
        />
        <div className="page-panels">
          <div className="page-content page-fonts">
            <AssetsUploads
              title="Uploading Fonts"
              uploading={uploading.filter(item =>
                fileTypes.font.some(ext => item.name.toLowerCase().endsWith(ext)),
              )}
              encodingQueue={encodingQueue}
              uploadRemoveItem={uploadRemoveItem}
              getEncodingQueue={getEncodingQueue}
            />
            <Spinner loading={loading}>
              <div className="toolbar">{fonts.length} Fonts</div>
              <style dangerouslySetInnerHTML={{ __html: this.getCss() }} />
              <FontsTable
                data={fonts}
                auth={auth}
                selected={selected}
                onClick={this.onClickTable}
                onDoubleClick={this.onDoubleClickTable}
              />
            </Spinner>
          </div>
        </div>
        <Confirm
          ref={ref => {
            this.refDelete = ref;
          }}
        />
        <UploadFontDialog
          show={showUploadFontDialog}
          onHide={this.hideUploadFontDialog}
          onSuccess={this.upload}
        />
      </div>
    );
  }
}

FontsPage.propTypes = {
  history: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  assets: PropTypes.object.isRequired,
  fonts: PropTypes.object.isRequired,
  loadAuth: PropTypes.func.isRequired,
  getFonts: PropTypes.func.isRequired,
  queueUploads: PropTypes.func.isRequired,
  uploadAsset: PropTypes.func.isRequired,
  uploadRemoveItem: PropTypes.func.isRequired,
  getEncodingQueue: PropTypes.func.isRequired,
  select: PropTypes.func.isRequired,
  unselectAll: PropTypes.func.isRequired,
  addNotification: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  assets: state.assets,
  fonts: state.fonts,
});
const mapDispatchToProps = {
  loadAuth,
  getFonts,
  queueUploads,
  uploadAsset,
  uploadRemoveItem,
  getEncodingQueue,
  select,
  unselectAll,
  addNotification,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FontsPage));
