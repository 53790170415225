import * as types from '../actions/actionTypes';

export const initialState = {
  loading: null,
  error: null,
  campaignSchedule: {},
};

export default function campaignSchedule(state = initialState, action = {}) {
  switch (action.type) {
    case types.CAMPAIGN_SCHEDULE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case types.CAMPAIGN_SCHEDULE_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        campaignSchedule: action.result,
      };
    case types.CAMPAIGN_SCHEDULE_REQUEST_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
}
