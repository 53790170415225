import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import SSTable from '../SSTable/SSTable';
import SSColumn from '../SSTable/SSColumn';
import ToolTipIcon from '../ToolTipIcon/ToolTipIcon';

export default class DataObjectsTable extends Component {
  getName = item => (
    <Link to={`/data-objects/${item.data_object.id}`}>{item.data_object.name}</Link>
  );

  getInUse = item => {
    const { inUse } = this.props;

    return (
      Array.from(
        new Set(
          inUse.filter(inuse => +inuse.doId === +item.data_object.id).map(inuse => inuse.signId),
        ),
      ).length || ''
    );
  };

  getEditButton = item => (
    <ToolTipIcon toolText="Edit Data Object" href={`/data-objects/${item.data_object.id}`}>
      mode_edit
    </ToolTipIcon>
  );

  render() {
    const { data, selected, onClick, onDoubleClick } = this.props;

    if (!data) {
      return null;
    }

    return (
      <SSTable
        className="responsive table-data-objects"
        data={data}
        rowHeight={50}
        rowsCount={data.length - 1}
        onSelect={onClick}
        doubleClick={onDoubleClick}
        selected={selected}
        zebra
        selectable
      >
        <SSColumn header="Data Object Name" cell={this.getName} />
        <SSColumn header="Used in Signs" cell={this.getInUse} />
        <SSColumn header="Edit" width={5} cell={this.getEditButton} />
      </SSTable>
    );
  }
}

DataObjectsTable.propTypes = {
  data: PropTypes.array.isRequired,
  inUse: PropTypes.array.isRequired,
  selected: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  onDoubleClick: PropTypes.func.isRequired,
};
