import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

export class NavigationSection extends Component {
  onClick = () => {
    const { history, url, handleClick } = this.props;

    if (handleClick) {
      handleClick();
    }

    if (url) {
      history.push(url);
    }
  };

  render() {
    const { cssClass, title, children, iconName } = this.props;

    return (
      <li className={cssClass}>
        <div data-test={`side-nav-${title.toLowerCase()}`} onClick={this.onClick}>
          <i className="pull-right material-icons material-caret inline">keyboard_arrow_right</i>
          <i className="nav-group-icon material-icons inline color-lime-500">{iconName}</i>
          <span className="navigation-group-text">{title}</span>
        </div>
        <ul>{children}</ul>
      </li>
    );
  }
}

NavigationSection.propTypes = {
  history: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  iconName: PropTypes.string.isRequired,
  children: PropTypes.node,
  url: PropTypes.string,
  handleClick: PropTypes.func,
  cssClass: PropTypes.string,
  openClosed: PropTypes.bool,
};

export default withRouter(NavigationSection);
