export const SAVE_PREVIOUS_LOCATION = 'component/app/SAVE_PREVIOUS_LOCATION';

export const SHOW_APP_MODAL = 'component/app/SHOW_APP_MODAL';

export const HIDE_APP_MODAL = 'component/app/HIDE_APP_MODAL';

// assets
export const ASSETS_REQUEST = 'component/assets/ASSETS_REQUEST';

export const ASSETS_REQUEST_SUCCESS = 'component/assets/ASSETS_REQUEST_SUCCESS';

export const ASSETS_REQUEST_FAILED = 'component/assets/ASSETS_REQUEST_FAILED';

export const ASSET_REQUEST = 'component/assets/ASSET_REQUEST';

export const ASSET_REQUEST_SUCCESS = 'component/assets/ASSET_REQUEST_SUCCESS';

export const ASSET_REQUEST_FAILED = 'component/assets/ASSET_REQUEST_FAILED';

export const ASSET_FORM_EDIT = 'components/assets/ASSET_FORM_EDIT';

export const ASSET_FORM_CLEAR = 'components/assets/ASSET_FORM_CLEAR';

export const ASSETS_ENCODE_TYPES_SUCCESS = 'components/assets/ASSETS_ENCODE_TYPES_SUCCESS';

export const VALIDATE_START = 'components/assets/VALIDATE_START';

export const VALIDATE_COMPLETE = 'components/assets/VALIDATE_COMPLETE';

export const VALIDATE_FAILED = 'components/assets/VALIDATE_FAILED';

export const UPLOAD_QUEUE_ADD_ITEMS = 'components/assets/UPLOAD_QUEUE_ADD_ITEMS';

export const UPLOAD_START = 'components/assets/UPLOAD_START';

export const UPLOAD_PROGRESS = 'components/assets/UPLOAD_PROGRESS';

export const UPLOAD_COMPLETE = 'components/assets/UPLOAD_COMPLETE';

export const UPLOAD_FAILED = 'components/assets/UPLOAD_FAILED';

export const UPLOAD_REMOVE_ITEM = 'components/assets/UPLOAD_REMOVE_ITEM';

export const UPLOAD_ENCODE_QUEUE_ADD_ITEM = 'components/assets/UPLOAD_ENCODE_QUEUE_ADD_ITEM';

export const UPLOAD_ENCODE_QUEUE_UPDATE = 'components/assets/UPLOAD_ENCODE_QUEUE_UPDATE';

// asset selector
export const ASSET_SELECTOR_LOADING = 'component/assets/ASSET_SELECTOR_LOADING';

export const ASSET_SELECTOR_LOADED = 'component/assets/ASSET_SELECTOR_LOADED';

// Assignments
export const ASSIGNMENTS_REQUEST = 'components/assignments/ASSIGNMENTS_REQUEST';

export const ASSIGNMENTS_REQUEST_SUCCESS = 'components/assignments/ASSIGNMENTS_REQUEST_SUCCESS';

export const ASSIGNMENTS_REQUEST_FAILED = 'components/assignments/ASSIGNMENTS_REQUEST_FAILED';

export const ASSIGNMENTS_SELECT = 'components/assignments/ASSIGNMENTS_SELECT';

export const ASSIGNMENTS_UNSELECT_ALL = 'component/assignments/ASSIGNMENTS_UNSELECT_ALL';

export const ALL_ASSIGNMENTS_REQUEST = 'components/assignments/ALL_ASSIGNMENTS_REQUEST';

export const ALL_ASSIGNMENTS_REQUEST_SUCCESS =
  'components/assignments/ALL_ASSIGNMENTS_REQUEST_SUCCESS';

export const ALL_ASSIGNMENTS_REQUEST_FAILED =
  'components/assignments/ALL_ASSIGNMENTS_REQUEST_FAILED';

// Auth
export const AUTH_LOAD = 'components/auth/AUTH_LOAD';

export const AUTH_LOAD_SUCCESS = 'components/auth/AUTH_LOAD_SUCCESS';

export const AUTH_LOAD_FAILED = 'components/auth/AUTH_LOAD_FAILED';

export const AUTH_NEXT_PATH = 'components/auth/AUTH_NEXT_PATH';

export const AUTH_UPDATE_AVATAR = 'components/auth/AUTH_UPDATE_AVATAR';

// Campaigns
export const CAMPAIGNS_REQUEST = 'component/campaigns/CAMPAIGNS_REQUEST';

export const CAMPAIGNS_REQUEST_SUCCESS = 'component/campaigns/CAMPAIGNS_REQUEST_SUCCESS';

export const CAMPAIGNS_REQUEST_FAILED = 'component/campaigns/CAMPAIGNS_REQUEST_FAILED';

export const CAMPAIGN_REQUEST = 'component/campaigns/CAMPAIGN_REQUEST';

export const CAMPAIGN_REQUEST_SUCCESS = 'component/campaigns/CAMPAIGN_REQUEST_SUCCESS';

export const CAMPAIGN_REQUEST_FAILED = 'component/campaigns/CAMPAIGN_REQUEST_FAILED';

export const CAMPAIGN_ADDED_CAMPAIGN = 'component/campaigns/CAMPAIGN_ADDED_CAMPAIGN';

export const CAMPAIGN_FORM_CLEAR = 'component/campaigns/CAMPAIGN_FORM_CLEAR';

// Campaign Schedule
export const CAMPAIGN_SCHEDULE_REQUEST = 'component/campaignSchedule/CAMPAIGN_SCHEDULE_REQUEST';

export const CAMPAIGN_SCHEDULE_REQUEST_SUCCESS =
  'component/campaignSchedule/CAMPAIGN_SCHEDULE_REQUEST_SUCCESS';

export const CAMPAIGN_SCHEDULE_REQUEST_FAILED =
  'component/campaignSchedule/CAMPAIGN_SCHEDULE_REQUEST_FAILED';

// Channels
export const CHANNELS_REQUEST = 'component/channels/CHANNELS_REQUEST';

export const CHANNELS_REQUEST_SUCCESS = 'component/channels/CHANNELS_REQUEST_SUCCESS';

export const CHANNELS_REQUEST_FAILED = 'component/channels/CHANNELS_REQUEST_FAILED';

// Content Items
export const CONTENT_ITEMS_REQUEST = 'component/contentItems/CONTENT_ITEMS_REQUEST';

export const CONTENT_ITEMS_REQUEST_SUCCESS = 'component/contentItems/CONTENT_ITEMS_REQUEST_SUCCESS';

export const ALL_CONTENT_ITEMS_REQUEST_SUCCESS =
  'component/contentItems/ALL_CONTENT_ITEMS_REQUEST_SUCCESS';

export const CONTENT_ITEMS_REQUEST_FAILED = 'component/ContentItems/CONTENT_ITEMS_REQUEST_FAILED';

export const CONTENT_ITEMS_SELECT = 'components/ContentItems/CONTENT_ITEMS_SELECT';

export const CONTENT_ITEMS_UNSELECT_ALL = 'component/ContentItems/CONTENT_ITEMS_UNSELECT_ALL';

// dataObjects
export const DATA_OBJECTS_SELECT = 'component/dataObjects/DATA_OBJECTS_SELECT';

export const DATA_OBJECTS_UNSELECT_ALL = 'component/dataObjects/DATA_OBJECTS_UNSELECT_ALL';

export const DATA_OBJECTS_REQUEST = 'component/dataObjects/DATA_OBJECTS_REQUEST';

export const DATA_OBJECTS_REQUEST_FAILED = 'component/dataObjects/DATA_OBJECTS_REQUEST_FAILED';

export const DATA_OBJECTS_REQUEST_SUCCESS_DATA_OBJECTS =
  'component/dataObjects/DATA_OBJECTS_REQUEST_SUCCESS_DATA_OBJECTS';

export const DATA_OBJECTS_REQUEST_SUCCESS_DATA_OBJECT =
  'component/dataObjects/DATA_OBJECTS_REQUEST_SUCCESS_DATA_OBJECT';

export const DATA_OBJECTS_REQUEST_SUCCESS_TIER =
  'component/dataObjects/DATA_OBJECTS_REQUEST_SUCCESS_TIER';

export const DATA_OBJECTS_REQUEST_SUCCESS_LOCATIONS =
  'component/dataObjects/DATA_OBJECTS_REQUEST_SUCCESS_LOCATIONS';

export const DATA_OBJECTS_REQUEST_SUCCESS_IN_USE =
  'component/dataObjects/DATA_OBJECTS_REQUEST_SUCCESS_IN_USE';

// urlRulesets
export const UR_SELECT = 'component/urlRulesets/UR_SELECT';

export const UR_UNSELECT_ALL = 'component/urlRulesets/UR_UNSELECT_ALL';

export const UR_REQUEST = 'component/urlRulesets/UR_REQUEST';

export const UR_REQUEST_WITH_LOADING_STATUS =
  'component/urlRulesets/UR_REQUEST_WITH_LOADING_STATUS';

export const UR_REQUEST_FAILED = 'component/urlRulesets/UR_REQUEST_FAILED';

export const UR_REQUEST_SUCCESS_URL_RULESETS =
  'component/urlRulesets/UR_REQUEST_SUCCESS_URL_RULESETS';

export const UR_REQUEST_SUCCESS_URL_RULESET =
  'component/urlRulesets/UR_REQUEST_SUCCESS_URL_RULESET';

export const UR_REQUEST_SUCCESS_IN_USE = 'component/urlRulesets/UR_REQUEST_SUCCESS_IN_USE';

// devices
export const DEVICES_SELECT = 'component/devices/DEVICES_SELECT';

export const DEVICES_UNSELECT_ALL = 'component/devices/DEVICES_UNSELECT_ALL';

export const DEVICES_REQUEST = 'component/devices/DEVICES_REQUEST';

export const DEVICES_REQUEST_WITH_LOADING_STATUS =
  'component/devices/DEVICES_REQUEST_WITH_LOADING_STATUS';

export const DEVICES_REQUEST_FAILED = 'component/devices/DEVICES_REQUEST_FAILED';

export const DEVICES_REQUEST_SUCCESS_ALL_DEVICES =
  'component/devices/DEVICES_REQUEST_SUCCESS_ALL_DEVICES';

export const DEVICES_REQUEST_SUCCESS_DEVICES = 'component/devices/DEVICES_REQUEST_SUCCESS_DEVICES';

export const DEVICES_REQUEST_SUCCESS_DEVICE = 'component/devices/DEVICES_REQUEST_SUCCESS_DEVICE';

export const DEVICES_REQUEST_SUCCESS_DEVICE_INFO =
  'component/devices/DEVICES_REQUEST_SUCCESS_DEVICE_INFO';

export const DEVICES_REQUEST_SUCCESS_DEVICE_ERRORS =
  'component/devices/DEVICES_REQUEST_SUCCESS_DEVICE_ERRORS';

export const DEVICES_REQUEST_SUCCESS_STATUS = 'component/devices/DEVICES_REQUEST_SUCCESS_STATUS';

export const DEVICES_REQUEST_SUCCESS_VERSIONS =
  'component/devices/DEVICES_REQUEST_SUCCESS_VERSIONS';

export const DEVICES_SET_COPY_CONFIG = 'component/devices/DEVICES_SET_COPY_CONFIG';

export const DEVICES_DEVICE_FORM_EDIT = 'components/devices/DEVICES_DEVICE_FORM_EDIT';

export const DEVICES_DEVICE_FORM_CLEAR = 'components/devices/DEVICES_DEVICE_FORM_CLEAR';

// Dialog
export const OPEN_DIALOG = 'component/dialog/OPEN_DIALOG';

export const CLOSE_DIALOG = 'component/dialog/CLOSE_DIALOG';

// Fake key
export const FAKE_KEY_LOAD = 'fakeKey/LOAD';

export const FAKE_KEY_LOAD_SUCCESS = 'fakeKey/LOAD_SUCCESS';

export const FAKE_KEY_LOAD_FAIL = 'fakeKey/LOAD_FAIL';

// Filters
export const FILTERS_OPEN_FILTERS = 'component/filters/OPEN_FILTERS';

export const FILTERS_CLOSE_FILTERS = 'component/filters/CLOSE_FILTERS';

export const FILTERS_TOGGLE_FILTERS = 'component/filters/TOGGLE_FILTERS';

export const FILTERS_ADD_FILTER = 'component/filters/ADD_FILTER';

export const FILTERS_ADD_UNIQUE_FILTER = 'component/filters/FILTERS_ADD_UNIQUE_FILTER';

export const FILTERS_REMOVE_FILTER = 'component/filters/REMOVE_FILTER';

export const FILTERS_RESET_FILTERS = 'component/filters/RESET_FILTERS';

// Folders
export const FOLDERS_REQUEST = 'component/folders/FOLDERS_REQUEST';

export const FOLDERS_REQUEST_SUCCESS = 'component/folders/FOLDERS_REQUEST_SUCCESS';

export const FOLDERS_REQUEST_FAILED = 'component/folders/FOLDERS_REQUEST_FAILED';

export const FOLDER_REQUEST = 'component/folders/FOLDER_REQUEST';

export const FOLDER_REQUEST_SUCCESS = 'component/folders/FOLDER_REQUEST_SUCCESS';

export const FOLDER_REQUEST_FAILED = 'component/folders/FOLDER_REQUEST_FAILED';

export const FOLDER_FORM_CLEAR = 'component/folders/FOLDER_FORM_CLEAR';

// Fonts
export const FONTS_SELECT = 'components/assignments/FONTS_SELECT';

export const FONTS_UNSELECT_ALL = 'component/assignments/FONTS_UNSELECT_ALL';

export const FONTS_REQUEST = 'component/fonts/FONTS_REQUEST';

export const FONTS_REQUEST_FAILED = 'component/fonts/FONTS_REQUEST_FAILED';

export const FONTS_REQUEST_SUCCESS_FONTS = 'component/fonts/FONTS_REQUEST_SUCCESS_FONTS';

export const FONTS_REQUEST_SUCCESS_FONT = 'component/fonts/FONTS_REQUEST_SUCCESS_FONT';

// Locations
export const LOCATIONS_REQUEST = 'component/locations/LOCATIONS_REQUEST';

export const ALL_LOCATIONS_REQUEST = 'component/locations/ALL_LOCATIONS_REQUEST';

export const LOCATIONS_REQUEST_SUCCESS = 'component/locations/LOCATIONS_REQUEST_SUCCESS';

export const ALL_LOCATIONS_REQUEST_SUCCESS = 'component/locations/ALL_LOCATIONS_REQUEST_SUCCESS';

export const LOCATIONS_REQUEST_FAILED = 'component/locations/LOCATIONS_REQUEST_FAILED';

export const ALL_LOCATIONS_REQUEST_FAILED = 'component/locations/ALL_LOCATIONS_REQUEST_FAILED';

export const LOCATION_REQUEST = 'component/locations/LOCATION_REQUEST';

export const LOCATION_REQUEST_SUCCESS = 'component/locations/LOCATION_REQUEST_SUCCESS';

export const LOCATION_REQUEST_FAILED = 'component/locations/LOCATION_REQUEST_FAILED';

export const LOCATION_FORM_CLEAR = 'component/locations/LOCATION_FORM_CLEAR';

export const LOCATIONS_SELECT = 'components/locations/LOCATIONS_SELECT';

export const LOCATIONS_UNSELECT_ALL = 'component/locations/LOCATIONS_UNSELECT_ALL';

// Navigation
export const NAV_CLOSE_NAVIGATION = 'component/navigation/CLOSE_NAVIGATION';

export const NAV_OPEN_NAVIGATION = 'component/navigation/OPEN_NAVIGATION';

// Notifications
export const ADD_NOTIFICATION = 'component/notification/ADD_NOTIFICATION';

export const REMOVE_NOTIFICATION = 'component/notification/REMOVE_NOTIFICATION';

export const REMOVE_ALL_NOTIFICATIONS = 'component/notification/REMOVE_ALL_NOTIFICATIONS';

// Playlists
export const PLAYLISTS_REQUEST = 'component/playlists/PLAYLISTS_REQUEST';

export const PLAYLISTS_REQUEST_SUCCESS = 'component/playlists/PLAYLISTS_REQUEST_SUCCESS';

export const PLAYLISTS_REQUEST_FAILED = 'component/playlists/PLAYLISTS_REQUEST_FAILED';

export const PLAYLIST_REQUEST = 'component/playlists/PLAYLIST_REQUEST';

export const PLAYLIST_REQUEST_SUCCESS = 'component/playlists/PLAYLIST_REQUEST_SUCCESS';

export const PLAYLIST_REQUEST_FAILED = 'component/playlists/PLAYLIST_REQUEST_FAILED';

export const PLAYLIST_FORM_CLEAR = 'components/playlist/PLAYLIST_FORM_CLEAR';

// Roles
export const ROLES_SELECT = 'component/roles/ROLES_SELECT';

export const ROLES_UNSELECT_ALL = 'component/roles/ROLES_UNSELECT_ALL';

export const ROLES_REQUEST = 'component/roles/ROLES_REQUEST';

export const ROLES_REQUEST_WITH_LOADING_STATUS =
  'component/roles/ROLES_REQUEST_WITH_LOADING_STATUS';

export const ROLES_REQUEST_FAILED = 'component/roles/ROLES_REQUEST_FAILED';

export const ROLES_REQUEST_SUCCESS_ROLES = 'component/roles/ROLES_REQUEST_SUCCESS_ROLES';

export const ROLES_REQUEST_SUCCESS_ROLE = 'component/roles/ROLES_REQUEST_SUCCESS_ROLE';

export const ROLES_CLEAR_ROLE = 'component/roles/ROLES_CLEAR_ROLE';

// sequences
export const SEQUENCES_REQUEST = 'component/sequences/SEQUENCES_REQUEST';

export const SEQUENCES_REQUEST_SUCCESS = 'component/sequences/SEQUENCES_REQUEST_SUCCESS';

export const SEQUENCES_REQUEST_FAILED = 'component/sequences/SEQUENCES_REQUEST_FAILED';

export const SEQUENCE_REQUEST = 'component/sequences/SEQUENCE_REQUEST';

export const SEQUENCE_REQUEST_SUCCESS = 'component/sequences/SEQUENCE_REQUEST_SUCCESS';

export const SEQUENCE_REQUEST_FAILED = 'component/sequences/SEQUENCE_REQUEST_FAILED';

export const SEQUENCE_FORM_SET = 'component/sequences/SEQUENCE_FORM_SET';

export const SEQUENCE_FORM_EDIT = 'component/sequences/SEQUENCE_FORM_EDIT';

// schedules
export const SCHEDULE_REQUEST = 'component/schedules/SCHEDULE_REQUEST';

export const SCHEDULE_REQUEST_SUCCESS = 'component/schedules/SCHEDULE_REQUEST_SUCCESS';

export const SCHEDULE_REQUEST_FAILED = 'component/schedules/SCHEDULE_REQUEST_FAILED';

export const SCHEDULES_REQUEST = 'component/schedules/SCHEDULES_REQUEST';

export const SCHEDULES_REQUEST_SUCCESS = 'component/schedules/SCHEDULES_REQUEST_SUCCESS';

export const SCHEDULES_REQUEST_FAILED = 'component/schedules/SCHEDULES_REQUEST_FAILED';

// Signs
export const SIGNS_REQUEST = 'component/signs/SIGNS_REQUEST';

export const SIGNS_REQUEST_SUCCESS = 'component/signs/SIGNS_REQUEST_SUCCESS';

export const SIGNS_REQUEST_FAILED = 'component/signs/SIGNS_REQUEST_FAILED';

// Sign
export const SIGN_REQUEST = 'component/signs/SIGN_REQUEST';

export const SIGN_REQUEST_SUCCESS = 'component/signs/SIGN_REQUEST_SUCCESS';

export const SIGN_REQUEST_FAILED = 'component/signs/SIGN_REQUEST_FAILED';

export const SIGN_FORM_EDIT = 'component/signs/SIGN_FORM_EDIT';

export const SIGN_FORM_CLEAR = 'component/signs/SIGN_FORM_CLEAR';

// Sites
export const SITES_GET_SITES = 'component/sites/GET_SITES';

export const SITES_GETTING_SITES = 'component/sites/GETTING_SITES';

export const SITES_GETTING_ZONES_FOR_SITES = 'component/sites/GETTING_ZONES_FOR_SITES';

export const SITES_GOT_ZONES_FOR_SITES = 'component/sites/GOT_ZONES_FOR_SITES';

// Users
export const USERS_SELECT = 'component/users/USERS_SELECT';

export const USERS_UNSELECT_ALL = 'component/users/USERS_UNSELECT_ALL';

export const USERS_REQUEST = 'component/users/USERS_REQUEST';

export const USERS_REQUEST_WITH_LOADING_STATUS =
  'component/users/USERS_REQUEST_WITH_LOADING_STATUS';

export const USERS_REQUEST_FAILED = 'component/users/USERS_REQUEST_FAILED';

export const USERS_REQUEST_SUCCESS_USERS = 'component/users/USERS_REQUEST_SUCCESS_USERS';

export const USERS_REQUEST_SUCCESS_USER = 'component/users/USERS_REQUEST_SUCCESS_USER';

export const USERS_CLEAR_USER = 'component/users/USERS_CLEAR_USER';
