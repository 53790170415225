import 'isomorphic-fetch';
import fetchCheck from '../redux/middleware/fetchCheck';
import * as types from './actionTypes';

export const url = '/cms/data/v1/signs/';

export const credentials = 'include';

export const headers = { Accept: 'application/json', 'Content-Type': 'application/json' };

export const signsRequest = () => ({ type: types.SIGNS_REQUEST });

export const signsRequestSuccess = result => ({ type: types.SIGNS_REQUEST_SUCCESS, result });

export const signsRequestFailed = error => ({ type: types.SIGNS_REQUEST_FAILED, error });

export const signRequest = () => ({ type: types.SIGN_REQUEST });

export const signRequestSuccess = result => ({ type: types.SIGN_REQUEST_SUCCESS, result });

export const signRequestFailed = error => ({ type: types.SIGN_REQUEST_FAILED, error });

export const editSignForm = (key, value) => ({ type: types.SIGN_FORM_EDIT, key, value });

export const clearSignForm = () => ({ type: types.SIGN_FORM_CLEAR });

export const fetchSigns = query =>
  fetch(url + (query || ''), { method: 'GET', credentials, headers })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const fetchSign = id =>
  fetch(url + id, { method: 'GET', credentials, headers })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const unpublishSign = id =>
  fetch(`${url + id}/unpublish`, { method: 'PUT', credentials, headers }).then(res =>
    fetchCheck(res),
  );

export const deleteSign = id =>
  fetch(url + id, { method: 'DELETE', credentials, headers }).then(res => fetchCheck(res));

export const updateSign = (id, data) =>
  fetch(url + id, { method: 'PUT', credentials, headers, body: JSON.stringify(data) }).then(res =>
    fetchCheck(res),
  );

export const getSigns = query => dispatch => {
  dispatch(signsRequest());

  return fetchSigns(query).then(
    res => dispatch(signsRequestSuccess(res)),
    err => dispatch(signsRequestFailed(err)),
  );
};

export const getSign = id => dispatch => {
  dispatch(signRequest());

  return fetchSign(id).then(
    res => dispatch(signRequestSuccess(res)),
    err => dispatch(signRequestFailed(err)),
  );
};
