import * as types from '../actions/actionTypes';

/* Initial State */
export const initialState = {
  isOpen: false,
};

/* Reducer */
export default function dialog(state = initialState, action = {}) {
  switch (action.type) {
    case types.OPEN_DIALOG:
      return {
        ...state,
        isOpen: true,
      };
    case types.CLOSE_DIALOG:
      return {
        ...state,
        isOpen: false,
      };
    default:
      return state;
  }
}
