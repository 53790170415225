// semantic version (semver) functions

// return original semver on valid semver
//   0.0.1        => 0.0.1
//   1.2.3        => 1.2.3
// return cleaned semver on loosely valid semver
//   2.10.5L4     => 2.10.5
//   3.1.0R6_QR1  => 3.1.0
//   win2.8.5     => 2.8.5
//   1.2.3beta0.1 => 1.2.3
// return null on invalid semver
const clean = semver => {
  // return null on missing semver
  if (!semver) {
    return null;
  }

  const [rawMajor, rawMinor, rawPatch] = semver.split('.');
  // handle when it starts with text e.g.: win2.8.5 => major: 2
  const major = Number((rawMajor?.match(/\d+$/) || [])[0]);
  // do not allow any text in minor
  const minor = Number(rawMinor);
  // handle when it ends with text e.g.: 3.1.0R6_QR1 => patch: 0
  // support legacy player versions that used minor.major only (<=2.7)
  const patch = parseInt(rawPatch, 10) || 0;
  const arr = [major, minor, patch];

  // return null when not every semver part is a number
  // 1.2      => [1, 2, NaN]
  // 1.abc2.3 => [1, NaN, 3]
  if (!arr.every(Number.isInteger)) {
    return null;
  }

  // return null when semver uses invalid leading zeros - the semver part starts with 0 but its value not 0
  // 1.002.3 => [1, 2, 3]
  // 1.2.003 => [1, 2, 3]
  if ((rawMinor?.startsWith('0') && minor !== 0) || (rawPatch?.startsWith('0') && patch !== 0)) {
    return null;
  }

  return arr.join('.');
};

// return true on valid semver
const valid = semver => Boolean(clean(semver));
// return semver numbers in array e.g.: 3.1.0R6_QR1 => [3, 1, 0]
// return null on invalid semver
const toArray = semver => clean(semver)?.split('.').map(Number) || null;

// compare two semvers
// return  1 when semver1 > semver2
// return  0 when semver1 = semver2
// return -1 when semver1 < semver2
// return null on invalid semver
// NB: it can only compares cleaned semvers: e.g.: 3.3.4R44 vs 3.3.4R45 are both
// cleaned to 3.3.4 and treated equal, even though they are not the same version
const compare = (semver1, semver2) => {
  const s1arr = toArray(semver1);
  const s2arr = toArray(semver2);

  if (!s1arr || !s2arr) {
    return null;
  }

  const [s1major, s1minor, s1patch] = s1arr;
  const [s2major, s2minor, s2patch] = s2arr;

  if (s1major === s2major && s1minor === s2minor && s1patch === s2patch) {
    return 0;
  }

  if (s1major === s2major && s1minor === s2minor) {
    return s1patch > s2patch ? 1 : -1;
  }

  if (s1major === s2major) {
    return s1minor > s2minor ? 1 : -1;
  }

  return s1major > s2major ? 1 : -1;
};

export default { clean, compare, toArray, valid };
