import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  FormGroup,
  FormControl,
  ControlLabel,
  Checkbox,
  HelpBlock,
  Grid,
  Row,
  Col,
  Button,
} from 'react-bootstrap';
import Metadata from '../Metadata/Metadata';

export default class FontForm extends Component {
  state = {
    showErrors: false,
    errors: {
      name: null,
    },
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { showErrors } = this.state;

    if (showErrors) {
      this.validate(nextProps);
    }
  }

  onChange = event => {
    const { onChange } = this.props;

    onChange(event.target.name, event.target.value);
  };

  onChangeCheckbox = event => {
    const { onChange } = this.props;

    onChange(event.target.name, event.target.checked);
  };

  errorClass = error => (error ? 'error' : null);

  validate = nextProps => {
    const props = nextProps || this.props;
    const {
      font: { name },
    } = props;
    const errors = {};

    if (!name || !name.trim()) {
      errors.name = 'This field cannot be empty.';
    } else if (!this.isUniqueName(props)) {
      errors.name = 'This is already in use.';
    }

    this.setState({ showErrors: true, errors });

    return !errors.name;
  };

  submit = event => {
    const { onUpdate } = this.props;

    event.preventDefault();

    if (this.validate()) {
      onUpdate();
    }
  };

  isUniqueName = props =>
    !props.fonts.some(item => {
      const name = props.font.name.toLowerCase().trim();
      const itemName = item.name.toLowerCase().trim();

      return item.id !== props.font.id && itemName === name;
    });

  render() {
    const {
      auth: {
        user: {
          permissions: { Writer },
        },
      },
      users,
      fonts,
      font,
      onCancel,
    } = this.props;
    const { errors } = this.state;

    if (!fonts || !font) {
      return null;
    }

    return (
      <form onSubmit={this.submit}>
        <Grid fluid>
          <Row>
            <Col md={6}>
              <FormGroup validationState={this.errorClass(errors.name)}>
                <ControlLabel>Name</ControlLabel>
                <FormControl
                  data-test="input-name"
                  name="name"
                  value={font.name}
                  onChange={this.onChange}
                  disabled={!Writer || font.default}
                />
                <HelpBlock>{errors.name}</HelpBlock>
              </FormGroup>
              <FormGroup>
                <ControlLabel>Test drive</ControlLabel>
                <FormControl
                  data-test="input-testdrive"
                  componentClass="textarea"
                  className={`font font-${font.id}`}
                  rows="2"
                  placeholder="Type something"
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <ControlLabel>Default font</ControlLabel>
                <Checkbox
                  data-test="checkbox-defaultfont"
                  name="shouldBeDefault"
                  checked={font.shouldBeDefault}
                  onChange={this.onChangeCheckbox}
                  disabled={!Writer}
                />
              </FormGroup>
            </Col>
          </Row>
        </Grid>
        <Metadata data={font} users={users} />
        <div className="form-buttons">
          <Button data-test="button-cancel" onClick={onCancel}>
            Cancel
          </Button>
          {Writer && (
            <Button data-test="button-update" bsStyle="success" type="submit">
              Update Font
            </Button>
          )}
        </div>
      </form>
    );
  }
}

FontForm.propTypes = {
  auth: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
  fonts: PropTypes.array,
  font: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
