import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Checkbox } from 'react-bootstrap';
import { isQueryParam, addQueryParam, removeQueryParam } from '../../utils/updateQueryString';
import { addFilter } from '../../actions/filterActions';
import UserProfile from '../../components/UserProfile/UserProfile';

export class FilterCheckboxList extends Component {
  componentDidMount() {
    this.setFilterFromQuery(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setFilterFromQuery(nextProps);
  }

  setFilterFromQuery = props => {
    const { location, name, title, data, addFilter } = props;

    if (!data || !data.length) {
      return;
    }

    for (let index = 0; index < data.length; index += 2) {
      const value = data[index];
      const checked = isQueryParam(location, name, value);

      if (checked) {
        addFilter({ group: name, name: value, query: value, text: value, title });
      }
    }
  };

  handleChange = value => {
    const { history, location, name } = this.props;
    const checked = isQueryParam(location, name, value);

    if (checked) {
      removeQueryParam(history, location, name, value);
    } else {
      addQueryParam(history, location, name, value);
    }
  };

  renderCheckboxList = () => {
    const { location, name, data, users } = this.props;
    const list = [];
    let text;

    if (!data || !data.length) {
      return list;
    }

    for (let index = 0; index < data.length; index += 2) {
      const value = data[index];
      const count = data[index + 1];
      const checked = isQueryParam(location, name, value);

      if (value) {
        if (['createdBy', 'modifiedBy'].includes(name)) {
          text = <UserProfile userId={parseInt(value, 10)} users={users} />;
        } else {
          text = value;
        }

        list.push(
          <Checkbox
            data-test={`checkbox-${name + value.replace(/[^a-z0-9]/gi, '')}`}
            key={index}
            checked={checked}
            onChange={() => this.handleChange(value)}
          >
            {text} <span className="total">{count}</span>
          </Checkbox>,
        );
      }
    }

    return list;
  };

  render() {
    const { title } = this.props;
    const checkboxList = this.renderCheckboxList();

    if (!checkboxList.length) {
      return null;
    }

    return (
      <div>
        <div className="filter-section-title">{title}</div>
        <div className="filter-checkboxlist">{checkboxList}</div>
      </div>
    );
  }
}

FilterCheckboxList.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  data: PropTypes.array,
  addFilter: PropTypes.func.isRequired,
  users: PropTypes.array,
};

const mapDispatchToProps = {
  addFilter,
};

export default withRouter(connect(null, mapDispatchToProps)(FilterCheckboxList));
