import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

export default class ToolTipIcon extends Component {
  getId = () => {
    const { id, toolText } = this.props;

    return id || (typeof toolText === 'string' ? toolText : 'tooltip');
  };

  getClass = () => {
    const { className = '', notIcon } = this.props;
    const defaultClassName = notIcon ? '' : 'tool-tip-icon material-icons';

    return `${defaultClassName} ${className}`;
  };

  // toolText can be string or React object. When it is an object, it cannot be used as an id, an extra id is required.
  getTooltip = () => {
    const { toolText } = this.props;

    return <Tooltip id={this.getId()}>{toolText}</Tooltip>;
  };

  getSpan = () => {
    const { children, infoIcon } = this.props;
    const smallInfoIcon = infoIcon ? <span className="glyphicon glyphicon-info-sign" /> : '';

    return (
      <span
        data-test={`icon-${this.getId().replace(/[^a-z0-9]/gi, '')}`}
        className={this.getClass()}
        aria-describedby={this.getId()}
      >
        {children}
        {smallInfoIcon}
      </span>
    );
  };

  getLink = () => {
    const { children, href, target } = this.props;

    return (
      <Link
        data-test={`icon-${this.getId().replace(/[^a-z0-9]/gi, '')}`}
        className={this.getClass()}
        aria-describedby={this.getId()}
        to={href}
        target={target}
      >
        {children}
      </Link>
    );
  };

  render() {
    const { placement = 'bottom', href, onClick } = this.props;
    const icon = href ? this.getLink() : this.getSpan();

    return (
      <OverlayTrigger
        animation={false}
        placement={placement}
        overlay={this.getTooltip()}
        onClick={event => {
          if (onClick) {
            onClick(event, this.props);
          }
        }}
      >
        {icon}
      </OverlayTrigger>
    );
  }
}

ToolTipIcon.propTypes = {
  children: PropTypes.node.isRequired,
  toolText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  id: PropTypes.string,
  placement: PropTypes.string, // [top, bottom, left, right];
  href: PropTypes.string,
  target: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  notIcon: PropTypes.bool,
  infoIcon: PropTypes.bool,
};
