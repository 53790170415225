import 'isomorphic-fetch';
import fetchCheck from '../redux/middleware/fetchCheck';
import * as types from './actionTypes';

export const url = '/cms/data/v1/folders/';

export const credentials = 'include';

export const headers = { Accept: 'application/json', 'Content-Type': 'application/json' };

export const foldersRequest = () => ({ type: types.FOLDERS_REQUEST });

export const foldersRequestSuccess = result => ({ type: types.FOLDERS_REQUEST_SUCCESS, result });

export const foldersRequestFailed = error => ({ type: types.FOLDERS_REQUEST_FAILED, error });

export const folderRequest = () => ({ type: types.FOLDER_REQUEST });

export const folderRequestSuccess = result => ({ type: types.FOLDER_REQUEST_SUCCESS, result });

export const folderRequestFailed = error => ({ type: types.FOLDER_REQUEST_FAILED, error });

export const clearFolderForm = () => ({ type: types.FOLDER_FORM_CLEAR });

export const fetchFolders = query =>
  fetch(url + (query || ''), { method: 'GET', credentials, headers })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const fetchFolder = id =>
  fetch(url + id, { method: 'GET', credentials, headers })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const addFolder = folder =>
  fetch(url, { method: 'POST', credentials, headers, body: JSON.stringify(folder) })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const updateFolder = (id, folder) =>
  fetch(url + id, {
    method: 'PUT',
    credentials,
    headers,
    body: JSON.stringify(folder),
  }).then(res => fetchCheck(res));

export const deleteFolder = (id, parentPath) =>
  fetch(url + id, {
    method: 'DELETE',
    credentials,
    headers,
    body: JSON.stringify({ parentPath }),
  }).then(res => fetchCheck(res));

export const loadFolders = query => dispatch => {
  dispatch(foldersRequest());

  return fetchFolders(query).then(
    res => dispatch(foldersRequestSuccess(res)),
    err => dispatch(foldersRequestFailed(err)),
  );
};

export const loadFolder = id => dispatch => {
  dispatch(folderRequest());

  return fetchFolder(id).then(
    res => dispatch(folderRequestSuccess(res)),
    err => dispatch(folderRequestFailed(err)),
  );
};
