import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { format } from 'date-fns';
import { withRouter, Prompt } from 'react-router-dom';
import clone from 'clone';
import deepEqual from 'fast-deep-equal';
import { SOLR_DELAY } from '../../../../constants';
import Header from '../../../components/Header/Header';
import { getUsers } from '../../../actions/userActions';
import { loadFolders } from '../../../actions/folderActions';
import { getSigns } from '../../../actions/signActions';
import { loadSequences } from '../../../actions/sequenceActions';
import {
  loadSchedules,
  loadSchedule,
  createSchedule,
  updateSchedule,
  deleteSchedule,
} from '../../../actions/scheduleActions';
import { loadPlaylists } from '../../../actions/playlistActions';
import { loadCampaigns } from '../../../actions/campaignActions';
import { loadAllAssignments } from '../../../actions/assignmentActions';
import { addNotification } from '../../../actions/notificationActions';
import ScheduleForm from '../../../components/Schedules/ScheduleForm';
import Used from '../../../components/Used/Used';
import DuplicateScheduleDialog from '../../../components/Schedules/DuplicateScheduleDialog';
import QuickAction from '../../../components/QuickAction/QuickAction';
import Spinner from '../../../components/Spinner/Spinner';
import Confirm from '../../../components/Confirm/Confirm';
import AssetSelector from '../../../components/AssetSelector/AssetSelector';
import LocationSelector from '../../../components/Locations/LocationSelector';
import ErrorPage from '../ErrorPage/ErrorPage';
import ActionsMenu from '../../../components/ActionsMenu/ActionsMenu';
import errorMessage from '../../../utils/errorMessage';
import { getPermissions } from '../../../utils/getPermissions';
import { getParent } from '../../../utils/parent';

export class SchedulePage extends Component {
  state = {
    loading: true,
    schedule: {},
    parent: null,
    entries: [],
    dirty: false,
    showSignSelector: false,
    showSequenceSelector: false,
    showFolderSelector: false,
    showDuplicateDialog: false,
  };

  componentDidMount() {
    this.loadData();
    document.addEventListener('visibilitychange', this.loadData);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (deepEqual(nextProps, this.props)) {
      return;
    }

    this.buildScheduleWhenDataIsLoaded(nextProps);
    this.updateEntries(nextProps);
  }

  componentWillUnmount() {
    document.removeEventListener('visibilitychange', this.loadData);
  }

  loadData = () => {
    if (document.visibilityState === 'hidden') {
      return;
    }

    const {
      match: {
        params: { id },
      },
      getUsers,
      loadFolders,
      getSigns,
      loadSequences,
      loadSchedules,
      loadPlaylists,
      loadCampaigns,
      loadAllAssignments,
      loadSchedule,
    } = this.props;

    getUsers();
    loadFolders('?count=100000000');
    getSigns('?count=100000000&published=true&isAssignable=true');
    loadSequences('?count=100000000');
    loadSchedules('?count=100000000');
    loadPlaylists('?count=100000000');
    loadCampaigns('?count=100000000');
    loadAllAssignments();

    if (id) {
      loadSchedule(id);
    }
  };

  buildScheduleWhenDataIsLoaded = nextProps => {
    const {
      match: {
        params: { id },
      },
      folders: { loadingFolders },
      schedules: { schedule, loadingSchedule },
      sequences: { loadingSequences },
      signs: { loadingSigns },
    } = nextProps;
    const { loading } = this.state;

    if (loading && !loadingFolders && !loadingSchedule && !loadingSequences && !loadingSigns) {
      this.setState({
        loading: false,
        schedule: id ? schedule : {},
        entries: id ? this.buildEntries(nextProps) : [],
        parent: getParent(nextProps, schedule.parent),
      });
    }
  };

  buildEntries = nextProps => {
    const {
      schedules: {
        schedule: { scheduleEntries },
      },
      sequences,
      signs,
    } = nextProps;

    if (!scheduleEntries) {
      return [];
    }

    scheduleEntries.map(entry => {
      entry.type = entry.sign_id ? 'sign' : 'sequence';

      if (entry.type === 'sign') {
        entry.entry = signs.signs.signs.find(item => item.id === entry.sign_id);
      } else {
        entry.entry = sequences.sequences.sequences.find(item => item.id === entry.sequence_id);
      }

      return entry;
    });

    // remove entry if the sign/sequence not found
    return scheduleEntries.filter(item => item.entry);
  };

  updateEntries = nextProps => {
    const {
      signs: {
        signs: { signs },
      },
      sequences: {
        sequences: { sequences },
      },
    } = nextProps;
    const { entries } = this.state;
    const newEntries = [];

    entries.forEach(item => {
      let entry;

      if (item.sign_id) {
        entry = signs.find(sign => +sign.id === +item.sign_id);
      } else {
        entry = sequences.find(seq => +seq.id === +item.sequence_id);
      }

      if (!entry) {
        return;
      }

      item.entry = entry;
      newEntries.push(item);
    });

    if (newEntries.length) {
      this.setState({ entries: newEntries });
    }
  };

  buildScheduleForServer = () => {
    const { schedule, entries, parent } = this.state;

    return {
      name: schedule.name.trim(),
      parent: Number(parent.id),
      description: schedule.description ? schedule.description.trim() : '',
      tags: schedule.tags,
      scheduleEntries: entries.map(item => ({
        sign_id: item.type === 'sign' ? item.entry.id : 0,
        sequence_id: item.type === 'sequence' ? item.entry.id : 0,
        date: item.date,
        time: item.time,
        recur: item.recur,
        days: item.recur === 2 ? item.days : 0,
      })),
    };
  };

  goBack = () => {
    const { history, previousLocation } = this.props;
    const url = previousLocation
      ? previousLocation.pathname + previousLocation.search
      : '/content?type=schedule&parent=/*';

    this.setState({ dirty: false }, () => history.push(url));
  };

  add = () => {
    const { addNotification } = this.props;
    const schedule = this.buildScheduleForServer();

    return createSchedule(schedule).then(
      () => {
        addNotification({ type: 'success', text: 'Schedule added.' });
        setTimeout(this.goBack, SOLR_DELAY);
      },
      err => addNotification({ type: 'danger', text: errorMessage(err) }),
    );
  };

  update = () => {
    const {
      match: {
        params: { id },
      },
      addNotification,
    } = this.props;
    const schedule = this.buildScheduleForServer();

    return updateSchedule(id, schedule).then(
      () => {
        addNotification({ type: 'success', text: 'Schedule updated.' });
        setTimeout(this.goBack, SOLR_DELAY);
      },
      err => addNotification({ type: 'danger', text: errorMessage(err) }),
    );
  };

  delete = () => {
    const {
      match: {
        params: { id },
      },
      addNotification,
    } = this.props;

    return deleteSchedule(id).then(
      () => {
        addNotification({ type: 'success', text: 'Schedule deleted.' });
        setTimeout(this.goBack, SOLR_DELAY);
      },
      err => addNotification({ type: 'danger', text: errorMessage(err) }),
    );
  };

  duplicate = name => {
    const { addNotification } = this.props;
    const schedule = this.buildScheduleForServer();

    schedule.name = name.trim();

    return createSchedule(schedule).then(
      () => {
        addNotification({ type: 'success', text: 'Schedule duplicated.' });
        setTimeout(this.goBack, SOLR_DELAY);
      },
      err => addNotification({ type: 'danger', text: errorMessage(err) }),
    );
  };

  move = parent => {
    const {
      match: {
        params: { id },
      },
      schedules,
      loadSchedule,
      addNotification,
    } = this.props;

    this.hideFolderSelector();

    return updateSchedule(id, { ...schedules.schedule, parent: parent.id }).then(
      () => {
        addNotification({ type: 'success', text: 'Schedule moved.' });
        loadSchedule(id);
      },
      err => addNotification({ type: 'danger', text: errorMessage(err) }),
    );
  };

  onDelete = () => {
    const confirm = this.confirmDelete;

    confirm.show('Delete Schedule', 'Are you sure you want to delete this Schedule?');
    confirm.onSuccess(this.delete);
  };

  onSuccessDuplicateDialog = name => {
    this.hideDuplicateDialog();
    this.duplicate(name);
  };

  showSignSelector = () => this.setState({ showSignSelector: true });

  showSequenceSelector = () => this.setState({ showSequenceSelector: true });

  showFolderSelector = () => this.setState({ showFolderSelector: true });

  showDuplicateDialog = () => this.setState({ showDuplicateDialog: true });

  hideSignSelector = () => this.setState({ showSignSelector: false });

  hideSequenceSelector = () => this.setState({ showSequenceSelector: false });

  hideDuplicateDialog = () => this.setState({ showDuplicateDialog: false });

  hideFolderSelector = () => this.setState({ showFolderSelector: false });

  onClickSignSelectorItem = item => {
    const { addNotification } = this.props;
    const { entries } = this.state;
    const newEntries = clone(entries);
    const entry = {
      type: 'sign',
      sign_id: item.id,
      sequence_id: null,
      date: format(new Date(), 'yyyyMMdd'),
      time: '1200',
      recur: 1,
      days: 0,
      entry: item,
    };

    newEntries.push(entry);
    this.setState({ entries: newEntries, dirty: true });
    addNotification({ type: 'success', text: `${item.name} added to the entries.` });

    return entry;
  };

  onClickSequenceSelectorItem = item => {
    const { addNotification } = this.props;
    const { entries } = this.state;
    const newEntries = clone(entries);
    const entry = {
      type: 'sequence',
      sign_id: null,
      sequence_id: item.id,
      date: format(new Date(), 'yyyyMMdd'),
      time: '1200',
      recur: 1,
      days: 0,
      entry: item,
    };

    newEntries.push(entry);
    this.setState({ entries: newEntries, dirty: true });
    addNotification({ type: 'success', text: `${item.name} added to the entries.` });

    return entry;
  };

  onChange = (key, value) => {
    const { schedule } = this.state;

    this.setState({ schedule: { ...schedule, [key]: value }, dirty: true });
  };

  onSort = entries => this.setState({ entries, dirty: true });

  onChangeDate = (value, index) => {
    const { entries } = this.state;
    const newEntries = clone(entries);

    newEntries[index].date = value;
    this.setState({ entries: newEntries, dirty: true });
  };

  onChangeHour = (event, index) => {
    const { entries } = this.state;
    const newEntries = clone(entries);

    newEntries[index].time = event.target.value + entries[index].time.slice(2, 4);
    this.setState({ entries: newEntries, dirty: true });
  };

  onChangeMinute = (event, index) => {
    const { entries } = this.state;
    const newEntries = clone(entries);

    newEntries[index].time = entries[index].time.slice(0, 2) + event.target.value;
    this.setState({ entries: newEntries, dirty: true });
  };

  onChangeRepeat = (event, index) => {
    const { entries } = this.state;
    const newEntries = clone(entries);

    newEntries[index].recur = Number(event.target.value);
    this.setState({ entries: newEntries, dirty: true });
  };

  onChangeDays = (event, index, dayIndex) => {
    const { entries } = this.state;
    const newEntries = clone(entries);
    const bitmask = entries[index].days.toString(2).split('').map(Number);

    while (bitmask.length < 7) {
      bitmask.unshift(0);
    }

    bitmask[dayIndex] = bitmask[dayIndex] ? 0 : 1;
    newEntries[index].days = parseInt(bitmask.join(''), 2);
    this.setState({ entries: newEntries, dirty: true });
  };

  onRemoveEntry = index => {
    const { entries } = this.state;
    const newEntries = clone(entries);

    newEntries.splice(index, 1);
    this.setState({ entries: newEntries, dirty: true });
  };

  getHeaderActions = () => {
    const {
      match: {
        params: { id },
      },
      auth,
    } = this.props;
    const p = getPermissions(auth);
    const actions = [];
    const preview = (
      <QuickAction
        key="1"
        toolText="Preview Schedule"
        href={`/player/?schedule=${id}`}
        target="_blank"
        alwaysShow
      >
        play_circle_filled
      </QuickAction>
    );
    const duplicate = (
      <QuickAction
        key="2"
        toolText="Duplicate Schedule"
        onClick={this.showDuplicateDialog}
        alwaysShow
      >
        content_copy
      </QuickAction>
    );
    const move = p.atLeastTierStandard && (
      <QuickAction key="3" toolText="Move Schedule" onClick={this.showFolderSelector} alwaysShow>
        subdirectory_arrow_right
      </QuickAction>
    );
    const remove = (
      <QuickAction key="4" toolText="Delete Schedule" onClick={this.onDelete} alwaysShow>
        delete
      </QuickAction>
    );

    if (!id) {
      return [];
    }

    actions.push(preview);

    if (p.writer) {
      actions.push(duplicate, move, remove);
    }

    return actions.filter(Boolean);
  };

  render() {
    const {
      match: {
        params: { id },
      },
      auth,
      users: { users },
      folders,
      signs: {
        signs: { signs: allSigns },
      },
      sequences: {
        sequences: { sequences },
      },
      schedules: {
        schedules: { schedules },
        schedule,
        loadingSchedule,
        error,
      },
      playlists: {
        playlists: { playlists },
      },
      campaigns: {
        campaigns: { campaigns },
      },
      assignments: {
        allAssignments: { assignments },
      },
    } = this.props;
    const {
      dirty,
      schedule: stateSchedule,
      entries,
      showSignSelector,
      showSequenceSelector,
      showDuplicateDialog,
      showFolderSelector,
    } = this.state;
    const p = getPermissions(auth);
    const signs = p.atLeastTierStandard
      ? allSigns
      : allSigns.filter(sign => sign.name.startsWith('video'));

    if (id && error) {
      return <ErrorPage error={error} />;
    }

    if (!signs || !sequences) {
      return null;
    }

    return (
      <div className="page-structure">
        <Helmet title="Schedule" />
        <Header overwrite={{ id: schedule }} />
        <ActionsMenu actions={this.getHeaderActions()} />
        <div className="page-panels">
          <div className="page-content page-schedule">
            <Spinner loading={loadingSchedule}>
              <Prompt
                when={dirty}
                message="You have unsaved changes. Are you sure you want to leave this page?"
              />
              <ScheduleForm
                auth={auth}
                users={users}
                folders={folders}
                signs={signs}
                schedules={schedules}
                schedule={stateSchedule}
                entries={entries}
                isNew={!id}
                onChange={this.onChange}
                onAdd={this.add}
                onUpdate={this.update}
                onCancel={this.goBack}
                onSort={this.onSort}
                onChangeDate={this.onChangeDate}
                onChangeHour={this.onChangeHour}
                onChangeMinute={this.onChangeMinute}
                onChangeRepeat={this.onChangeRepeat}
                onChangeDays={this.onChangeDays}
                onRemoveEntry={this.onRemoveEntry}
                onClickAddSign={this.showSignSelector}
                onClickAddSequence={this.showSequenceSelector}
              />
              <Used
                type="schedule"
                item={stateSchedule}
                signs={signs}
                playlists={playlists}
                campaigns={campaigns}
                assignments={assignments}
                auth={auth}
              />
            </Spinner>
            <AssetSelector
              type="sign"
              title="Add Signs to the Schedule"
              show={showSignSelector}
              onHide={this.hideSignSelector}
              onSuccess={this.onClickSignSelectorItem}
              assets={signs}
            />
            <AssetSelector
              type="sequence"
              title="Add Sequences to the Schedule"
              show={showSequenceSelector}
              onHide={this.hideSequenceSelector}
              onSuccess={this.onClickSequenceSelectorItem}
              assets={sequences}
              signs={signs}
            />
            <DuplicateScheduleDialog
              schedules={schedules}
              original={schedule}
              show={showDuplicateDialog}
              onHide={this.hideDuplicateDialog}
              onSuccess={this.onSuccessDuplicateDialog}
            />
            <Confirm
              ref={confirm => {
                this.confirmDelete = confirm;
              }}
              title="Delete Schedule"
            />
            <LocationSelector
              type="folder"
              show={showFolderSelector}
              onHide={this.hideFolderSelector}
              onSuccess={this.move}
              items={folders.folders.folders}
              currentPath={schedule.parent}
            />
          </div>
        </div>
      </div>
    );
  }
}

SchedulePage.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  previousLocation: PropTypes.object,
  users: PropTypes.object.isRequired,
  folders: PropTypes.object.isRequired,
  signs: PropTypes.object.isRequired,
  sequences: PropTypes.object.isRequired,
  schedules: PropTypes.object.isRequired,
  playlists: PropTypes.object.isRequired,
  campaigns: PropTypes.object.isRequired,
  assignments: PropTypes.object.isRequired,
  getUsers: PropTypes.func.isRequired,
  loadFolders: PropTypes.func.isRequired,
  getSigns: PropTypes.func.isRequired,
  loadSequences: PropTypes.func.isRequired,
  loadSchedule: PropTypes.func.isRequired,
  loadSchedules: PropTypes.func.isRequired,
  loadPlaylists: PropTypes.func.isRequired,
  loadCampaigns: PropTypes.func.isRequired,
  loadAllAssignments: PropTypes.func.isRequired,
  addNotification: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  previousLocation: state.app.previousLocation,
  users: state.users,
  folders: state.folders,
  signs: state.signs,
  sequences: state.sequences,
  schedules: state.schedules,
  playlists: state.playlists,
  campaigns: state.campaigns,
  assignments: state.assignments,
});
const mapDispatchToProps = {
  getUsers,
  loadFolders,
  getSigns,
  loadSequences,
  loadSchedule,
  loadSchedules,
  loadPlaylists,
  loadCampaigns,
  loadAllAssignments,
  addNotification,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SchedulePage));
