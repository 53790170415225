export const DeviceType = Object.freeze({
  ALLSEE_BATTERYPOWERED_EKD08: 'android_allsee_batterypowered_ekd08',
  ALLSEE_DUALSCREEN_EKD08: 'android_allsee_dualscreen_ekd08',
  ALLSEE: 'android_allsee',
  ANDROID_OTHER_VESTEL: 'android_other_vestel',
  ANDROID_OTHER: 'android_other',
  APC380: 'android_apc380',
  EKD08_WEDGE: 'android_ekd08',
  HISENSE_DM: 'android_hisense_dm',
  LAMASA_DUALSCREEN: 'android_lamasa_dualscreen',
  LEDSTUDIO_339A: 'android_ledstudio_339a',
  LINUX: 'linux',
  M12S: 'android_m12',
  MK808: 'android_mk808',
  MK809III: 'android_mk809iii',
  PHILIPS_3650: 'android_tpv_3650',
  PHILIPS_4050_ZIP: 'android_tpv',
  PHILIPS_4050: 'android_tpv_4050',
  PHILIPS_4051: 'android_tpv_4051',
  PHILIPS_4150: 'android_tpv_4150',
  PHILIPS_4550: 'android_tpv_4550',
  PHILIPS_4650: 'android_tpv_4650',
  PHILIPS_CRD50: 'android_tpv_crd50',
  REFEE_A55FD: 'android_refee_a55fd',
  REFEE_BA37B: 'android_refee_ba37b',
  S35: 'android_s35',
  SAMSUNG_OTHER: 'samsung_other',
  SAMSUNG_TIZEN_4: 'samsung_tizen_4',
  SAMSUNG_TIZEN_7: 'samsung_tizen_7',
  UNKNOWN: '',
  VESTEL_400VS: 'android_vestel_400vs',
  WINDOWS: 'windows',
  X96_X6: 'android_x96_x6',
});

export const FirmwareType = Object.freeze({
  ALLSEE: 'allsee',
  ANDROID: 'android',
  LINUX: 'linux',
  SAMSUNG_TIZEN_4: 'samsung_tizen_4',
  SAMSUNG_TIZEN_7: 'samsung_tizen_7',
  TPV_3650: 'tpv_3650',
  TPV_4050: 'tpv_4050',
  TPV_4051: 'tpv_4051',
  TPV_4150: 'tpv_4150',
  TPV_4550: 'tpv_4550',
  TPV_4650: 'tpv_4650',
  TPV_CRD50: 'tpv_crd50',
  UNKNOWN: '',
  WINDOWS: 'windows',
});

export const deviceTypeToFirmwareType = deviceType => {
  switch (deviceType) {
    case DeviceType.ALLSEE:
    case DeviceType.ALLSEE_BATTERYPOWERED_EKD08:
      return FirmwareType.ALLSEE;
    case DeviceType.PHILIPS_3650:
      return FirmwareType.TPV_3650;
    case DeviceType.PHILIPS_4050_ZIP:
    case DeviceType.PHILIPS_4050:
      return FirmwareType.TPV_4050;
    case DeviceType.PHILIPS_4051:
      return FirmwareType.TPV_4051;
    case DeviceType.PHILIPS_4150:
      return FirmwareType.TPV_4150;
    case DeviceType.PHILIPS_4550:
      return FirmwareType.TPV_4550;
    case DeviceType.PHILIPS_4650:
      return FirmwareType.TPV_4650;
    case DeviceType.PHILIPS_CRD50:
      return FirmwareType.TPV_CRD50;
    case DeviceType.ALLSEE_DUALSCREEN_EKD08:
    case DeviceType.ANDROID_OTHER_VESTEL:
    case DeviceType.ANDROID_OTHER:
    case DeviceType.APC380:
    case DeviceType.EKD08_WEDGE:
    case DeviceType.HISENSE_DM:
    case DeviceType.LAMASA_DUALSCREEN:
    case DeviceType.LEDSTUDIO_339A:
    case DeviceType.M12S:
    case DeviceType.MK808:
    case DeviceType.MK809III:
    case DeviceType.REFEE_A55FD:
    case DeviceType.REFEE_BA37B:
    case DeviceType.S35:
    case DeviceType.UNKNOWN:
    case DeviceType.VESTEL_400VS:
    case DeviceType.X96_X6:
      return FirmwareType.ANDROID;
    case DeviceType.LINUX:
      return FirmwareType.LINUX;
    case DeviceType.WINDOWS:
      return FirmwareType.WINDOWS;
    case DeviceType.SAMSUNG_TIZEN_4:
      return FirmwareType.SAMSUNG_TIZEN_4;
    case DeviceType.SAMSUNG_TIZEN_7:
      return FirmwareType.SAMSUNG_TIZEN_7;
    default:
      return FirmwareType.UNKNOWN;
  }
};

/* convert the supplied Set of DeviceTypes into a Set of FirmwareTypes */
export const deviceTypesToFirmwareTypes = deviceTypes => {
  const firmwareTypes = new Set();

  deviceTypes.forEach(deviceType => firmwareTypes.add(deviceTypeToFirmwareType(deviceType)));

  return [...firmwareTypes];
};

export default DeviceType;
