import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { format, parse } from 'date-fns';
import ToolTipIcon from '../ToolTipIcon/ToolTipIcon';
import SSTable from '../SSTable/SSTable';
import SSColumn from '../SSTable/SSColumn';
import AssetImage from '../Assets/AssetImage';
import { formatTime } from '../../utils/formatUnits';
import { buildTagList } from '../../utils/tagList';

export default class PlaylistFilesTable extends Component {
  getPosition = (item, index) => <span className="circle circle-md">{index + 1}</span>;

  getPreview = item => <AssetImage type="video" item={item} />;

  getName = item => (
    <div className="item-name">
      <div>
        <Link to={`/assets/${item.id}`}>{item.name}</Link>
      </div>
      <div>Folder: {item.parent}</div>
      <div>Tags: {buildTagList(item)}</div>
    </div>
  );

  getDescription = item => <div className="item-description">{item.description}</div>;

  getDimensions = item => `${item.width} x ${item.height}`;

  getDuration = item => {
    const { duration } = item;

    if (!duration || duration <= 0) {
      return null;
    }

    const [secs, ms] = formatTime(duration / 1000).split('.');

    return (
      <div>
        {secs}
        {ms ? <span className="text-light">.{ms}</span> : null}
      </div>
    );
  };

  getRemove = (item, index) => {
    const { onRemoveFile } = this.props;

    return (
      <ToolTipIcon toolText="Remove" onClick={() => onRemoveFile(index)}>
        remove_circle_outline
      </ToolTipIcon>
    );
  };

  getValidity = item => {
    if (item.startDate === '20100101' && item.endDate === '20991231') {
      return 'Always';
    }

    return `${format(parse(item.startDate, 'yyyyMMdd', new Date()), 'dd/MM/yyyy')} - ${format(
      parse(item.endDate, 'yyyyMMdd', new Date()),
      'dd/MM/yyyy',
    )}`;
  };

  render() {
    const {
      auth: {
        user: {
          permissions: { Writer },
        },
      },
      data,
      onSort,
    } = this.props;

    if (!data) {
      return null;
    }

    return (
      <SSTable
        className="responsive table-playlist"
        data={data}
        headerHeight={50}
        rowHeight={50}
        rowsCount={data.length - 1}
        onSort={onSort}
        selectable
        sortable={Writer}
        zebra
      >
        <SSColumn cell={this.getPosition} />
        <SSColumn header="Preview" cell={this.getPreview} />
        <SSColumn header="Name" cell={this.getName} />
        <SSColumn header="Description" cell={this.getDescription} />
        <SSColumn header="Dimensions" cell={this.getDimensions} />
        <SSColumn header="Duration" cell={this.getDuration} />
        <SSColumn header="Validity Period" cell={this.getValidity} />
        {Writer && <SSColumn width={5} header="Remove" cell={this.getRemove} />}
      </SSTable>
    );
  }
}

PlaylistFilesTable.propTypes = {
  auth: PropTypes.object.isRequired,
  data: PropTypes.array,
  onSort: PropTypes.func.isRequired,
  onRemoveFile: PropTypes.func.isRequired,
};
