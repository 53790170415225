import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  FormGroup,
  FormControl,
  ControlLabel,
  HelpBlock,
  Button,
  Grid,
  Row,
  Col,
  Checkbox,
} from 'react-bootstrap';
import clone from 'clone';

export default class RoleForm extends Component {
  getInitialState = () => ({
    showErrors: false,
    errors: { name: null },
  });

  state = this.getInitialState();

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { showErrors } = this.state;

    if (showErrors) {
      this.validate(nextProps);
    }
  }

  onChange = event => {
    const { onEdit } = this.props;

    onEdit(event.target.name, event.target.value);
  };

  onChangePermission = event => {
    const {
      role: { perm },
      onEdit,
    } = this.props;
    const { name } = event.target;
    const permissions = perm.split('|').filter(item => item);

    if (!permissions.includes(name)) {
      permissions.push(name);
    } else {
      permissions.splice(permissions.indexOf(name), 1);
    }

    onEdit('perm', permissions.join('|'));
  };

  errorClass = error => (error ? 'error' : null);

  validate = nextProps => {
    const props = nextProps || this.props;
    const {
      role: { name },
    } = props;
    const errors = clone(this.getInitialState().errors);

    // name
    if (!name.trim()) {
      errors.name = 'This field cannot be empty.';
    } else if (!this.isUniqueName(props)) {
      errors.name = 'This is already in use.';
    }

    // show the errors
    this.setState({ showErrors: true, errors });

    // return false on any error
    return !Object.keys(errors).find(key => errors[key]);
  };

  submit = event => {
    const { isNew, onAdd, onUpdate } = this.props;

    event.preventDefault();

    if (!this.validate()) {
      return;
    }

    if (isNew) {
      onAdd();
    } else {
      onUpdate();
    }
  };

  isUniqueName = props => {
    const { roles, role } = props;
    const newData = role.name.toLowerCase().trim();

    return !roles.some(item => {
      const oldData = item.name.toLowerCase().trim();

      return +item.role_id !== +role.role_id && newData === oldData;
    });
  };

  getPermissionCheckboxes = () => {
    const {
      role: { perm },
      permissions,
      isOwner,
    } = this.props;

    return permissions.map((item, index) => (
      <Checkbox
        data-test={`checkbox-${item.name.replace(/[^a-z0-9]/gi, '')}`}
        key={index}
        name={item.name}
        disabled={isOwner && item.name === 'Admin'}
        checked={perm.includes(item.name)}
        onChange={this.onChangePermission}
      >
        {item.name}
      </Checkbox>
    ));
  };

  getButtons = () => {
    const { isNew, onCancel } = this.props;
    const add = (
      <Button key="1" data-test="button-add" bsStyle="success" type="submit">
        Add Role
      </Button>
    );
    const update = (
      <Button key="2" data-test="button-update" bsStyle="success" type="submit">
        Update Role
      </Button>
    );
    const cancel = (
      <Button key="3" data-test="button-cancel" onClick={onCancel}>
        Cancel
      </Button>
    );

    return isNew ? [cancel, add] : [cancel, update];
  };

  render() {
    const {
      role: { name, desc = '' },
    } = this.props;
    const { errors } = this.state;

    return (
      <form onSubmit={this.submit}>
        <Grid fluid>
          <Row>
            <Col md={6}>
              <FormGroup validationState={this.errorClass(errors.name)}>
                <ControlLabel>Role</ControlLabel>
                <FormControl
                  data-test="input-name"
                  name="name"
                  onChange={this.onChange}
                  value={name}
                />
                <HelpBlock>{errors.name}</HelpBlock>
              </FormGroup>
              <FormGroup>
                <ControlLabel>Description</ControlLabel>
                <FormControl
                  data-test="input-desc"
                  name="desc"
                  componentClass="textarea"
                  placeholder="Enter description"
                  rows="4"
                  value={desc}
                  onChange={this.onChange}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <ControlLabel>Permissions</ControlLabel>
                {this.getPermissionCheckboxes()}
              </FormGroup>
            </Col>
          </Row>
        </Grid>
        <div className="form-buttons">{this.getButtons()}</div>
      </form>
    );
  }
}

RoleForm.propTypes = {
  role: PropTypes.object,
  roles: PropTypes.array,
  permissions: PropTypes.array,
  isNew: PropTypes.bool.isRequired,
  isOwner: PropTypes.bool.isRequired,
  onEdit: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
