import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Panel } from 'react-bootstrap';
import pluralize from 'pluralize';
import SSTable from '../SSTable/SSTable';
import SSColumn from '../SSTable/SSColumn';
import ToolTipIcon from '../ToolTipIcon/ToolTipIcon';
import AssetImage from '../Assets/AssetImage';
import { buildTagList } from '../../utils/tagList';
import { launchCreator } from '../../utils/launchCreator';
import { getPermissions } from '../../utils/getPermissions';

export default class UsedInAssignmentsTable extends Component {
  state = { open: this.props.open || false };

  getHeader = () => {
    const { type, data, title } = this.props;
    const { open } = this.state;

    return (
      <div data-test="used-assignments-title" className="toggle" onClick={this.toggle}>
        <i className="material-icons material-caret inline">
          {open ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}
        </i>
        {title}
        {!title && (
          <span>
            Used in {pluralize('Assignment', data.length, true)}
            {['playlist', 'video'].includes(type) && (
              <span className="text-light text-small">
                {' '}
                Including <i>Video Play Fullscreen</i> events
              </span>
            )}
          </span>
        )}
      </div>
    );
  };

  getCampaign = item => {
    const { campaigns } = this.props;
    const { campaign_id, name } = item;
    const campaign = campaigns.find(camp => +camp.id === +campaign_id);
    const url = `/campaigns/${campaign.id}/assignments?parent=/*&search=${name}`;

    return (
      <div className="item-content">
        <div className="item-name">
          <div>
            <Link to={url}>{campaign.name}</Link>
          </div>
          <div className="item-tags">Folder: {campaign.parent}</div>
          <div className="item-tags">Tags: {buildTagList(campaign)}</div>
        </div>
      </div>
    );
  };

  getDevice = item => {
    const { device_id, campaign_id, name, parent } = item;

    return (
      <div className="item-name">
        <div>
          <Link to={`/devices/${device_id}`}>{name}</Link>
        </div>
        <div className="item-tags">Location: {parent}</div>
        <ToolTipIcon
          toolText="Preview device"
          href={`/player/?device=${device_id}&campaign=${campaign_id}`}
          target="_blank"
        >
          play_circle_filled
        </ToolTipIcon>
      </div>
    );
  };

  getDescription = item => (
    <div className="item-name">
      <div>{item.description}</div>
      <div className="item-tags">Tags: {buildTagList(item)}</div>
    </div>
  );

  getContent = item => {
    const { auth, signs } = this.props;
    const { content_type, content_id, content_name } = item;
    const p = getPermissions(auth);

    return (
      <div className="assignment-content">
        <div>
          <AssetImage id={content_id} type={content_type} item={item} signs={signs} />
        </div>
        <div className="item-name">
          <div>
            {!p.atLeastTierStandard && content_type === 'sign' ? (
              content_name
            ) : (
              <Link to={`/${content_type}s/${content_id}`}>{content_name}</Link>
            )}
          </div>
          <div className="item-tags">Type: {content_type}</div>
          <div>
            <ToolTipIcon
              toolText={`Preview ${content_name}`}
              href={`/player/?${content_type}=${content_id}`}
              target="_blank"
            >
              play_circle_filled
            </ToolTipIcon>
            {p.atLeastTierStandard && content_type === 'sign' && (
              <ToolTipIcon
                toolText={`Open ${content_name} in Creator`}
                onClick={() => launchCreator(content_id)}
              >
                <i className="fa fa-paint-brush" />
              </ToolTipIcon>
            )}
          </div>
        </div>
      </div>
    );
  };

  getSource = item => {
    const {
      content_default_stream_type,
      content_default_stream_id,
      content_default_stream_name,
    } = item;

    if (!content_default_stream_type) {
      return null;
    }

    return (
      <div className="assignment-content">
        <div>
          <AssetImage
            id={content_default_stream_id}
            type={content_default_stream_type}
            item={item}
          />
        </div>
        <div className="item-name">
          <div>
            {content_default_stream_type !== 'playlist' && content_default_stream_name}
            {content_default_stream_type === 'playlist' && (
              <Link to={`/playlists/${content_default_stream_id}`}>
                {content_default_stream_name}
              </Link>
            )}
          </div>
          <div className="item-tags">Type: {content_default_stream_type}</div>
        </div>
      </div>
    );
  };

  toggle = () => {
    const { open } = this.state;

    this.setState({ open: !open });
  };

  render() {
    const { data, signs, campaigns } = this.props;
    const { open } = this.state;

    if (!data || !signs || !campaigns) {
      return null;
    }

    return (
      <Panel className="used-in">
        <Panel.Heading>{this.getHeader()}</Panel.Heading>
        <Panel.Body>
          {open && Boolean(data.length) && (
            <SSTable
              data={data}
              className="responsive table-assignments"
              rowsCount={data.length - 1}
              zebra
            >
              <SSColumn cell={this.getCampaign} header="Campaign" />
              <SSColumn cell={this.getDevice} header="Device" />
              <SSColumn cell={this.getDescription} header="Description" />
              <SSColumn cell={this.getContent} header="Content" />
              <SSColumn cell={this.getSource} header="Video Source" />
            </SSTable>
          )}
        </Panel.Body>
      </Panel>
    );
  }
}

UsedInAssignmentsTable.propTypes = {
  type: PropTypes.string.isRequired,
  data: PropTypes.array,
  auth: PropTypes.object.isRequired,
  signs: PropTypes.array,
  campaigns: PropTypes.array,
  title: PropTypes.string,
  open: PropTypes.bool,
};
