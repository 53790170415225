import React from 'react';

export default () => (
  <div>
    <h1>LICENCE AGREEMENT</h1>
    <h2>1. Interpretation</h2>
    <ol>
      <li>
        <strong>1.1</strong> In this licence, unless the context otherwise requires, the following
        expressions have the following meanings:
        <br />
        <ul>
          <li>
            <strong>Business Day:</strong> a day other than a Saturday, Sunday or public holiday in
            England when banks in London are open for business.
          </li>
          <li>
            <strong>Confidential Information:</strong> information that is proprietary or
            confidential and is either clearly labelled as such or identified as Confidential
            Information in clause 9.5.
          </li>
          <li>
            <strong>Connection:</strong> a connection by which any playback mechanism with a unique
            registration/reference used by the Customer, displays output using the Software.
          </li>
          <li>
            <strong>Customer:</strong> the customer who has entered into this licence and whose
            details are accepted by the Licensor (whether named on an order or otherwise), and who
            using the Passcode, has access to the Services.
          </li>
          <li>
            <strong>Customer Data:</strong> the Customer’s data stored using the Software.
          </li>
          <li>
            <strong>Data Protection Laws:</strong> as applicable in the UK, the Data Protection Act
            1998 and/or the General Data Protection Regulation (EU) 2016/679 from the date on which
            it applies, and/or any corresponding or equivalent national laws or regulations and any
            replacement, amendment, re-enactment or consolidation of the same from time to time
            together with all subordinate legislation.
          </li>
          <li>
            <strong>Documentation:</strong> the user instructions for aiding use of the Services.
          </li>
          <li>
            <strong>Effective Date:</strong> the date that this licence is accepted by the Customer.
          </li>
          <li>
            <strong>Initial Term:</strong> the initial term of this licence as specified in writing
            by the Customer and confirmed by the Licensor, or otherwise shall be 1 month from the
            Effective Date.
          </li>
          <li>
            <strong>Intellectual Property Rights:</strong> all patents, rights to inventions,
            utility models, copyright and related rights, trademarks, service marks, trade, business
            and domain names, rights in trade dress or get-up, rights in goodwill or to sue for
            passing off, unfair competition rights, rights in designs, rights in computer software,
            database rights, topography rights, moral rights, rights in confidential information
            (including know-how and trade secrets) and all other intellectual property rights, in
            each case whether registered or unregistered and including all applications for and
            renewals or extensions of such rights, and all similar or equivalent rights or forms of
            protection in any part of the world.
          </li>
          <li>
            <strong>Licensor:</strong> the entity granting the licence to the Customer to use the
            Services in accordance with the terms of this licence as notified to the
            Customer.References to “Licensor” in this licence shall mean the Reseller or Signstix as
            the case may be depending on which entity the Customer enters into this licence with.
          </li>
          <li>
            <strong>Passcode:</strong> the passcode provided to the Customer to enable it to enter
            into this licence and use the Services.
          </li>
          <li>
            <strong>Renewal Period(s):</strong> the renewal period or periods which shall follow the
            Initial Term and which are specified in writing by the Licensor, or otherwise, if not
            other period is specified, the Renewal Periods shall be successive periods of 1 (one)
            month.
          </li>
          <li>
            <strong>Reseller:</strong> any reseller appointed by Signstix to act as its authorised
            reseller of the Services and with whom the Customer has contracted to enable it to enter
            into this licence.
          </li>
          <li>
            <strong>Services:</strong> the subscription services provided by the Licensor to the
            Customer under this licence via https://cms.Signstix.com or https://api.Signstix.com or
            any other website notified to the Customer from time to time, as more particularly
            described in the Documentation. In this licence, references to the Services shall also
            include the Software and the Documentation.
          </li>
          <li>
            <strong>Signstix:</strong> Signstix Limited incorporated and registered in England and
            Wales with company number 08579363 whose registered office is at The Exchange, Station
            Parade, Harrogate, North Yorkshire, HG1 1TS.
          </li>
          <li>
            <strong>Software:</strong> the online software applications proprietary to Signstix the
            use of which forms part of the Services from time to time.
          </li>
          <li>
            <strong>Subscriptions:</strong> the subscriptions purchased by the Customer which
            entitle Users to access and use the Services on a per Connection basis in accordance
            with this licence.
          </li>
          <li>
            <strong>Subscription Fees:</strong> the subscription fees payable for the Subscriptions
            by the Customer to the Licensor.
          </li>
          <li>
            <strong>Subscription Term:</strong> the Initial Term together with any subsequent
            Renewal Periods.
          </li>
          <li>
            <strong>Support Services Policy:</strong> Signstix&apos;s policy for providing support
            in relation to the Services as made available at https://cms.Signstix.com or
            https://api.signstix.com or such other website address as may be notified to the
            Customer from time to time.
          </li>
          <li>
            <strong>Users:</strong> those employees, agents and independent contractors of the
            Customer who are authorised by the Customer to use the Services on behalf of the
            Customer.
          </li>
          <li>
            <strong>Virus:</strong> any thing or device (including any software, code, file or
            programme) which may: prevent, impair or otherwise adversely affect the operation of any
            computer software, hardware or network, any telecommunications service, equipment or
            network or any other service or device; prevent, impair or otherwise adversely affect
            access to or the operation of any programme or data, including the reliability of any
            programme or data (whether by re-arranging, altering or erasing the programme or data in
            whole or part or otherwise); or adversely affect the user experience, including worms,
            trojan horses, viruses and other similar things or devices.
          </li>
        </ul>
      </li>
      <li>
        <strong>1.2</strong> In this licence:
      </li>
      <li>
        <strong>1.2.1</strong> a person includes a natural person, corporate or unincorporated body
        (whether or not having separate legal personality);
      </li>
      <li>
        <strong>1.2.2</strong> a reference to a party includes its successors and permitted assigns;
      </li>
      <li>
        <strong>1.2.3</strong> a reference to a statute or statutory provision is a reference to
        such statute or statutory provision as amended or re-enacted for time to time and reference
        to a statute or statutory provision includes any subordinate legislation made under that
        statute or statutory provision, as amended or re-enacted; and
      </li>
      <li>
        <strong>1.2.4</strong> any words following the terms including, include, in particular or
        any similar expression shall be construed as illustrative and shall not limit the sense of
        the words preceding those terms.
      </li>
    </ol>
    <h2>2. Grant, formation and scope of licence</h2>
    <ol>
      <li>
        <strong>2.1</strong> The licence granted hereunder is granted either by a Reseller where
        such Reseller has been appointed by Signstix or may be granted by Signstix directly where
        the Services are licensed directly by Signstix.Where a Reseller is appointed and so acts as
        Licensor hereunder, that entity acts in its own name and for its own account and not as
        agent for Signstix.The entity which grants the rights to the Customer to use the Services in
        accordance with the terms hereof shall be the Licensor.
      </li>
      <li>
        <strong>2.2</strong> This licence applies to the Customer’s use of the Services to the
        exclusion of any terms and conditions which the Customer may seek to impose or incorporate,
        or any terms and conditions which are implied by trade, custom, practice or course of
        dealing.These licence terms shall be binding on the Customer from the date on which the
        Customer first uses the Services unless otherwise stated in writing by the Licensor.
      </li>
      <li>
        <strong>2.3</strong> For the avoidance of doubt, these terms and conditions apply to the use
        of the Services only. To the extent that any other services or deliverables are to be
        provided by the Reseller to the Customer, then the same shall be governed by the Reseller’s
        terms and conditions which shall apply to the performance of such services or supply of
        goods.
      </li>
      <li>
        <strong>2.4</strong> In consideration of and subject to, the Customer paying the
        Subscription Fees and complying with the terms of this licence, the Licensor hereby grants
        to the Customer a non-exclusive, non-transferable right, without the right to grant
        sub-licences, to use and to permit its Users to use, the Services during the Subscription
        Term solely for the Customer&apos;s internal business operations, subject to the terms and
        conditions of this licence.
      </li>
      <li>
        <strong>2.5</strong> The Customer shall not, and shall not permit any third party to use the
        Services on behalf of or for the benefit of any third party, or, subject to clause 13.9,
        license, sell, rent, lease, transfer, assign, distribute, display, disclose, or otherwise
        commercially exploit, or otherwise make the Services available to any third party (except
        the Users).
      </li>
      <li>
        <strong>2.6</strong> The Customer undertakes that the maximum number of Connections at any
        time shall not exceed the number of Subscriptions it has purchased from time to time. If
        notwithstanding such restriction, the number of Connections is exceeded, then without
        prejudice to the Licensor’s and Signstix&apos;s other rights, the Customer shall pay the
        amount of any underpayment of Subscription Fees within 10 Business Days of demand, to the
        Licensor.
      </li>
      <li>
        <strong>2.7</strong> The Customer shall not access, store, distribute or transmit any
        Viruses, or any material during the course of its use of the Services that is unlawful,
        harmful, threatening, defamatory, obscene, infringing, harassing or racially or ethnically
        offensive; facilitates illegal activity; depicts sexually explicit images; promotes unlawful
        violence; is discriminatory based on race, gender, colour, religious belief, sexual
        orientation, disability; or is otherwise illegal or causes damage or injury to any person or
        property, and each of the Reseller and Signstix reserves the right, without liability or
        prejudice to its other rights to the Customer, to disable the Customer&apos;s access to any
        material that breaches the provisions of this clause.
      </li>
      <li>
        <strong>2.8</strong> The Customer shall not, except as may be allowed by any applicable law
        which is incapable of exclusion by agreement between the parties and except to the extent
        expressly permitted under this licence:
      </li>
      <li>
        <strong>2.8.1</strong> attempt to copy, modify, duplicate, create derivative works from,
        frame, mirror, republish, download, display, transmit, or distribute all or any portion of
        the Software in any form or media or by any means; or
      </li>
      <li>
        <strong>2.8.2</strong> attempt to de-compile, reverse compile, disassemble, reverse engineer
        or otherwise reduce to human-perceivable form all or any part of the Software.
      </li>
      <li>
        <strong>2.9</strong> The Customer shall not access all or any part of the Services in order
        to build a product or service which competes with the Services.
      </li>
      <li>
        <strong>2.10</strong> The Customer shall use all reasonable endeavours to prevent any
        unauthorised access to, or use of, the Services and, in the event of any such unauthorised
        access or use, promptly notify each of Signstix and the Reseller.
      </li>
      <li>
        <strong>2.11</strong> The Customer shall procure that all Users enter into and acceptable
        use policy applicable to the use of the Services as may be stipulated from time to time.
        Such terms of use are made available when access to the Services is provided to the
        Customer.
      </li>
    </ol>
    <h2>3. Services</h2>
    <ol>
      <li>
        <strong>3.1</strong> Access to the Services shall be deemed to have been accepted by the
        Customer on the earlier of the Effective Date and the Customer’s first use of the Services.
      </li>
      <li>
        <strong>3.2</strong> The Licensor warrants that the Software will provide the facilities and
        functions set out in the Documentation when properly used on or with the hardware, software,
        operating systems and other equipment which complies in all respects with the stipulated
        specifications or recommendations given by the Licensor. The Customer shall be responsible
        for ensuring that all hardware, software, operating systems and other equipment which is to
        be used in any way in connection with the Services has been installed and is fully
        operational prior to the Customer being given access to the Services.
      </li>
      <li>
        <strong>3.3</strong> The warranty at clause 3.2 shall not apply to the extent of any
        non-conformance which is caused by use of the Services contrary to either the Reseller’s or
        Signstix&apos;s instructions, or modification or alteration of the Services by any party
        other than the Licensor or its duly authorised contractors or agents. If the Services do not
        conform with the foregoing warranty, the Licensor will, at its expense, use all reasonable
        commercial endeavours to correct any such non-conformance promptly, or provide the Customer
        with an alternative means of accomplishing the desired performance. Such correction or
        substitution constitutes the Customer&apos;s sole and exclusive remedy for any breach of the
        warranty set out in clause 3.2.Notwithstanding the foregoing, neither Signstix nor the
        Reseller:
      </li>
      <li>
        <strong>3.3.1</strong> warrants that the Customer&apos;s use of the Services will be
        uninterrupted or error-free, or that the Services and/or the information obtained by the
        Customer through the Services will meet the Customer&apos;s requirements;
      </li>
      <li>
        <strong>3.3.2</strong> is responsible for any delays, delivery failures, or any other loss
        or damage resulting from the transfer of data over communications networks and facilities,
        including the internet, and the Customer acknowledges that the Services may be subject to
        limitations, delays and other problems inherent in the use of such communications
        facilities.
      </li>
      <li>
        <strong>3.4</strong> This licence shall not prevent Signstix from entering into similar
        agreements with third parties, or from independently developing, using, selling or licensing
        documentation, products and/or services which are similar to those provided under this
        licence.
      </li>
      <li>
        <strong>3.5</strong> The Licensor warrants that it has and will maintain all necessary
        licences, consents, and permissions necessary for the performance of its obligations under
        this licence.
      </li>
      <li>
        <strong>3.6</strong> Any timescales given or referred to by the Licensor at any time are
        estimates only and time shall not be of the essence.
      </li>
      <li>
        <strong>3.7</strong> The Licensor shall use commercially reasonable endeavours to make the
        Services available 24 hours a day, seven days a week, except for planned maintenance (which
        will be carried out on reasonable notice) or unscheduled maintenance including where
        necessary for emergency reasons.
      </li>
      <li>
        <strong>3.8</strong> Signstix will and at no additional cost to the Customer, provide the
        Customer with Signstix&apos;s standard customer support services in accordance with
        Signstix&apos;s Support Services Policy and/or other support terms in effect at the time
        that the Services are provided.Signstix may amend the Support Services Policy and/or other
        support terms in its sole and absolute discretion from time to time.
      </li>
    </ol>
    <h2>4. Customer Data</h2>
    <ol>
      <li>
        <strong>4.1</strong> The Customer shall own all right, title and interest in and to all of
        the Customer Data and shall have sole responsibility for the legality, reliability,
        integrity, accuracy and quality of the Customer Data.
      </li>
      <li>
        <strong>4.2</strong> In the event of any loss or damage to Customer Data, the
        Customer&apos;s sole and exclusive remedy shall be for the Licensor to use reasonable
        commercial endeavours to restore the lost or damaged Customer Data from the latest back-up
        of such Customer Data maintained by the Licensor or where the Licensor is a Reseller, by
        Signstix. Neither the Reseller nor Signstix shall be responsible for any loss, destruction,
        alteration or disclosure of Customer Data caused by any third party (except those third
        parties sub-contracted by Signstix (or a Reseller) to perform services related to Customer
        Data maintenance and back-up).
      </li>
      <li>
        <strong>4.3</strong> If the Reseller and/or Signstix processes any Personal Data on the
        Customer&apos;s behalf when performing its obligations under this licence or otherwise as
        the proprietor of the Services, the parties record their intention that the Customer shall
        be the Data Controller and each of the Reseller and Signstix shall be a Data Processor, and
        in any such case:
      </li>
      <li>
        <strong>4.3.1</strong> Signstix shall process the Personal Data in compliance with the
        obligations of Data Processors under Data Protection Laws;
      </li>
      <li>
        <strong>4.3.2</strong> the Customer shall comply with all Data Protection Laws in connection
        with the Personal Data including maintaining all relevant regulatory registrations and
        notifications as required under Data Protection Laws;
      </li>
      <li>
        <strong>4.3.3</strong> the Customer warrants, represents and undertakes, that:
      </li>
      <li>
        <strong>4.3.3.1</strong> all such Personal Data shall comply in all respects, including as
        to collection, storage and processing, with Data Protection Laws;
      </li>
      <li>
        <strong>4.3.3.2</strong> all instructions given to the Licensor and/or Signstix in respect
        of Personal Data shall at all times be in accordance with Data Protection Laws; and
      </li>
      <li>
        <strong>4.3.3.3</strong> it is satisfied that Signstix’s and where relevant, the Licensor’s
        processing operations are suitable for the purposes for which the Customer proposes to use
        the Personal Data in respect of the Services and that both the Licensor and Signstix has
        sufficient expertise, reliability and resource to implement technical and organisational
        measures that meet Data Protection Law requirements;
      </li>
      <li>
        <strong>4.3.4</strong> unless otherwise required by Applicable Law, each of Licensor and/or
        Signstix shall process the Personal Data only in accordance with the Customer’s documented
        instructions as updated from time to time;
      </li>
      <li>
        <strong>4.3.5</strong> to the maximum extent permitted, neither the Licensor or Signstix
        shall have any liability (whether in contract, tort (including negligence) or otherwise) for
        any losses, claims, damages, costs, expenses or liabilities arising from or in connection
        with any processing in accordance with the Customer’s instructions;
      </li>
      <li>
        <strong>4.3.6</strong> the Licensor and Signstix shall implement and maintain such technical
        and organisational measures as required to protect the Personal Data from unauthorised
        damage or loss, and taking into account the nature of the processing, to assist the Customer
        insofar as is possible in the Customer’s obligations to respond to Data Subject Requests
        relating to Personal Data;
      </li>
      <li>
        <strong>4.3.7</strong> the Licensor shall refer all Data Subject Requests it receives to the
        Customer in a timely manner;
      </li>
      <li>
        <strong>4.3.8</strong> the Licensor and/or Signstix may transfer such Personal Data to
        countries outside the EEA provided all such transfers shall to the extent required under
        Data Protection Laws, have appropriate safeguards applied and be in accordance with such
        laws; and
      </li>
      <li>
        <strong>4.3.9</strong> the Licensor shall, without undue delay, notify the Customer of any
        Personal Data Breach and provide the Customer with details of the same. In this clause 4.3,
        Data Subject, Personal Data, Processing, Data Processor and Data Controller have the
        meanings given to them in Data Protection Laws, and Data Subject Request means a request
        made by a Data Subject to exercise any rights of Data Subjects under Data Protection Laws.
      </li>
    </ol>
    <h2>5. Third party providers</h2>
    <p>
      The Customer acknowledges that the Services may enable or assist it to access the website
      content of, correspond with, and purchase products and services from, third parties via
      third-party websites and that it does so solely at its own risk. Neither Signstix nor the
      Reseller makes any representation, warranty or commitment and shall have no liability or
      obligation whatsoever in relation to the content or use of, or correspondence with, any such
      third-party website, or any transactions completed, and any contract entered into by the
      Customer, with any such third party.Any contract entered into and any transaction completed
      via any third-party website is between the Customer and the relevant third party, and not with
      either a Reseller or Signstix.The Licensor recommends that the Customer refers to the third
      party&apos;s website terms and conditions and privacy policy prior to using the relevant
      third-party website.Neither Signstix nor the Reseller endorses or approves any third-party
      website nor the content of any of the third-party website made available via the Services.
    </p>
    <h2>6. Customer&apos;s obligations</h2>
    <ol>
      <li>
        <strong>6.1</strong> The Customer shall:
      </li>
      <li>
        <strong>6.1.1</strong> provide each of the Reseller and Signstix with all necessary
        co-operation in relation to this licence, and all necessary access to such information as
        may be required by it or them, in order to provide the Services;
      </li>
      <li>
        <strong>6.1.2</strong> comply with all applicable laws and regulations with respect to its
        activities under this licence;
      </li>
      <li>
        <strong>6.1.3</strong> carry out all other Customer responsibilities set out in this licence
        in a timely and efficient manner. In the event of any delays in the Customer&apos;s
        provision of such assistance as agreed by the parties, the Licensor may adjust any agreed
        timetable or delivery schedule as reasonably necessary;
      </li>
      <li>
        <strong>6.1.4</strong> ensure that the Users use the Services in accordance with the terms
        and conditions of this licence and any acceptable use policy and any other terms stipulated
        by Signstix from time to time, and shall be responsible for any User&apos;s breach of such
        licence or terms;
      </li>
      <li>
        <strong>6.1.5</strong> obtain and shall maintain all necessary licences, consents, and
        permissions necessary for the Licensor, its contractors and agents to perform their
        obligations under this licence, including without limitation the Services;
      </li>
      <li>
        <strong>6.1.6</strong> ensure that its network and systems comply with the relevant
        specifications provided by either the Reseller or Signstix from time to time; and
      </li>
      <li>
        <strong>6.1.7</strong> be solely responsible for procuring and maintaining its network
        connections and telecommunications links from its systems to Signstix&apos;s data centres,
        and all problems, conditions, delays, delivery failures and all other loss or damage arising
        from or relating to the Customer&apos;s network connections or telecommunications links or
        caused by the internet;
      </li>
      <li>
        <strong>6.1.8</strong> at all times adhere to and ensure that all Users adhere to, the terms
        of the Acceptable Use Policy set out in Appendix A hereto.
      </li>
    </ol>
    <h2>7. Charges and payment</h2>
    <ol>
      <li>
        <strong>7.1</strong> If there is a Reseller, the Customer shall pay the Subscription Fees to
        the Reseller in accordance with the terms stipulated by the Reseller. If there is no
        Reseller, the Customer shall pay Signstix:
      </li>
      <li>
        <strong>7.1.1</strong> on the Effective Date, the Subscription Fees payable in respect of
        the Initial Term; and
      </li>
      <li>
        <strong>7.1.2</strong> subject to clause 12.1, at least 30 days prior to each Renewal Period
        the Subscription Fees payable in respect of the next Renewal Period. If payment of any
        Subscription Fees due to Signstix in accordance with clause 7.1 is not received by Signstix
        within 7 days after the relevant due date, interest shall accrue on a daily basis on such
        due amounts at an annual rate equal to 4% over the then current base lending rate of
        Signstix&apos;s bankers in the UK from time to time, commencing on the due date and
        continuing until fully paid, whether before or after judgment.
      </li>
      <li>
        <strong>7.2</strong> If payment of any Subscription Fees due to Signstix is not made by the
        Customer within 7 days after the relevant due date, without prejudice to any other rights
        and remedies of Signstix, Signstix may, without liability to the Customer, disable the
        Customer&apos;s password, account and access to all or part of the Services and Signstix
        shall be under no obligation to provide any or all of the Services while the invoice(s)
        concerned remain unpaid.If payment due to the Reseller is not paid by the due date then
        Signstix may suspend or disable the Service where requested to do so by the Reseller as a
        result of the non-payment by the Customer.Further and without prejudice to its other rights
        and remedies herein, Signstix also reserves the right to disable the Service in the event of
        non-payment of sums due to it by the Reseller and the Reseller and Customer agree that such
        rights and benefit shall be conferred on Signstix expressly.
      </li>
      <li>
        <strong>7.3</strong> All amounts and fees stated or referred to in this licence are
        non-cancellable and non-refundable, and are exclusive of value added tax, which shall be
        paid by the Customer in addition at the appropriate rate.
      </li>
      <li>
        <strong>7.4</strong> If, at any time whilst using the Services, the Customer exceeds the
        amount of storage space specified in the Documentation, Signstix shall charge the Customer,
        and the Customer shall pay, Signstix&apos;s then current excess data storage fees. Unless
        otherwise specified elsewhere there shall be a maximum limit on storage of 1GB per
        Connection.
      </li>
      <li>
        <strong>7.5</strong> If the Customer requests an increase in the number of Connections, the
        Licensor shall notify the Customer of the additional Subscription Fees for those additional
        Connections.Upon payment of the relevant Subscription Fees, The Licensor shall provide or
        procure access to the Services for the relevant additional number of Connections.
      </li>
    </ol>
    <h2>8. Proprietary rights</h2>
    <ol>
      <li>
        <strong>8.1</strong> The Customer acknowledges and agrees that Signstix and/or its third
        party licensors own all Intellectual Property Rights in the Services and the Software.
        Except as expressly stated herein, this licence does not grant the Customer any rights to,
        under or in, any Intellectual Property Rights, or any other rights or licences in respect of
        the Services.
      </li>
      <li>
        <strong>8.2</strong> The Licensor confirms that it has all the rights in relation to the
        Services that are necessary to grant all the rights it purports to grant under, and in
        accordance with, the terms of this licence.
      </li>
      <li>
        <strong>8.3</strong> The Customer shall not remove, suppress or modify any proprietary or
        other marking, including any trademark or copyright notice on or in the Documentation or
        provided in connection with the Services.
      </li>
      <li>
        <strong>8.4</strong> The Customer shall notify the Licensor immediately if the Customer
        becomes aware of any unauthorised use of the whole or any part of the Services or the
        Intellectual Property Rights therein by any person.
      </li>
    </ol>
    <h2>9. Confidentiality</h2>
    <ol>
      <li>
        <strong>9.1</strong> Each party may be given access to Confidential Information from the
        other party in order to perform its obligations under this licence.A party&apos;s
        Confidential Information shall not be deemed to include information that:
      </li>
      <li>
        <strong>9.1.2</strong> was in the other party&apos;s lawful possession before the
        disclosure;
      </li>
      <li>
        <strong>9.1.3</strong> is lawfully disclosed to the receiving party by a third party without
        restriction on disclosure; or
      </li>
      <li>
        <strong>9.1.4</strong> is independently developed by the receiving party, which independent
        development can be shown by written evidence.
      </li>
      <li>
        <strong>9.2</strong> Subject to clause 9.4, each party shall hold the other&apos;s
        Confidential Information in confidence and not make the other&apos;s Confidential
        Information available to any third party, or use the other&apos;s Confidential Information
        for any purpose other than the implementation of this licence.
      </li>
      <li>
        <strong>9.3</strong> Each party shall take all reasonable steps to ensure that the
        other&apos;s Confidential Information to which it has access is not disclosed or distributed
        by its employees or agents in violation of the terms of this licence.
      </li>
      <li>
        <strong>9.4</strong> A party may disclose Confidential Information to the extent such
        Confidential Information is required to be disclosed by law, by any governmental or other
        regulatory authority or by a court or other authority of competent jurisdiction, provided
        that, to the extent it is legally permitted to do so, it gives the other party as much
        notice of such disclosure as possible and, where notice of disclosure is not prohibited and
        is given in accordance with this clause 9.4, it takes into account the reasonable requests
        of the other party in relation to the content of such disclosure.
      </li>
      <li>
        <strong>9.5</strong> The Customer acknowledges that details of the Services, and the results
        of any performance tests of the Services, constitute Signstix&apos;s Confidential
        Information. The Licensor acknowledges that the Customer Data is the Confidential
        Information of the Customer.
      </li>
      <li>
        <strong>9.6</strong> The above provisions of this clause 9 shall survive termination of this
        licence, however arising.
      </li>
    </ol>
    <h2>10. Indemnity</h2>
    <ol>
      <li>
        <strong>10.1</strong> The Customer shall defend, indemnify and hold harmless each of
        Signstix and the Reseller as relevant against claims, actions, proceedings, losses, damages,
        expenses and costs (including without limitation court costs and reasonable legal fees)
        arising out of or in connection with the Customer&apos;s use of the Services, provided that:
      </li>
      <li>
        <strong>10.1.1</strong> the Customer is given prompt notice of any such claim;
      </li>
      <li>
        <strong>10.1.2</strong> Signstix and the Reseller as the case may be provides reasonable
        co-operation to the Customer in the defence and settlement of such claim, at the
        Customer&apos;s expense; and
      </li>
      <li>
        <strong>10.1.3</strong> the Customer is given authority to defend or settle the claim.
      </li>
      <li>
        <strong>10.2</strong> The Licensor shall defend the Customer, its officers, directors and
        employees against any claim that the Services infringes any Intellectual Property Rights,
        and shall indemnify the Customer for any amounts awarded against the Customer in judgment or
        settlement of such claims, provided that:
      </li>
      <li>
        <strong>10.2.1</strong> the Licensor is given prompt notice of any such claim;
      </li>
      <li>
        <strong>10.2.2</strong> the Customer provides reasonable co-operation to the Licensor in the
        defence and settlement of such claim, at the Licensor’s expense; and
      </li>
      <li>
        <strong>10.2.3</strong> the Licensor is given authority to defend or settle the claim.
      </li>
      <li>
        <strong>10.3</strong> In the defence or settlement of any claim, the Licensor may procure
        the right for the Customer to continue using the Services, replace or modify the Services so
        that they become non-infringing or, if such remedies are not reasonably available, terminate
        this licence on 2 Business Days&apos; notice to the Customer without any additional
        liability or obligation to pay liquidated damages or other additional costs to the Customer.
      </li>
      <li>
        <strong>10.4</strong> In no event shall the Licensor, its employees, agents and
        sub-contractors be liable to the Customer to the extent that the alleged infringement is
        based on:
      </li>
      <li>
        <strong>10.4.1</strong> a modification of the Services by anyone other than the Licensor and
        as appropriate, Signstix;
      </li>
      <li>
        <strong>10.4.2</strong> the Customer&apos;s use of the Services in a manner contrary to the
        instructions given to the Customer by Signstix or the Reseller; or
      </li>
      <li>
        <strong>10.4.3</strong> the Customer&apos;s use of the Services after notice of the alleged
        or actual infringement from the Licensor or Signstix or any appropriate authority.
      </li>
      <li>
        <strong>10.5</strong> THE INDEMNITY REFERRED TO IN CLAUSES 10.2 TO 10.4 STATES THE
        CUSTOMER&apos;S SOLE AND EXCLUSIVE RIGHTS AND REMEDIES, AND THE LICENSOR’S AND THAT OF
        SIGNSTIX (INCLUDING EACH OF THEIR EMPLOYEES&apos;, AGENTS&apos; AND SUB-CONTRACTORS&apos;)
        ENTIRE OBLIGATIONS AND LIABILITY, FOR INFRINGEMENT OR ALLEGED INFRINGEMENT OF ANY
        INTELLECTUAL PROPERTY RIGHTS.
      </li>
      <li>
        <strong>10.6</strong> THE INDEMNITY REFERRED TO IN CLAUSES 10.2 TO 10.4 IS FOR THE BENEFIT
        OF THE CUSTOMER ONLY. IN THE EVENT OF A PAYMENT OF ANY INDEMNITY SUM BY SIGNSTIX IN
        ACCORDANCE WITH CLAUSES 10.2 TO 10.4, THE SAME MAY EITHER AT SIGNSTIX’S OPTION BE PAID
        DIRECTLY BY SIGNSTIX TO THE CUSTOMER OR TO THE RESELLER FOR DIRECT ONWARD PAYMENT OF SUCH
        SUM TO THE CUSTOMER.IN THE LATTER CASE, THE RESELLER SHALL PAY SUCH SUM TO THE CUSTOMER
        WITHOUT UNDUE DELAY. FOR THE AVOIDANCE OF DOUBT, UNDER NO CIRCUMSTANCES SHALL SIGNSTIX BE
        LIABLE TO BOTH THE RESELLER AND THE CUSTOMER UNDER ANY INDEMNITY FOR THE SAME LOSSES, CLAIMS
        OR OTHER LIABILITY ARISING.
      </li>
    </ol>
    <h2>11. Liability – THE CUSTOMER’S ATTENTION IS PARTICULARLY DRAWN TO THIS CLAUSE</h2>
    <ol>
      <li>
        <strong>11.1</strong> Except as expressly and specifically provided in this licence:
      </li>
      <li>
        <strong>11.1.1</strong> the Customer assumes sole responsibility for results obtained from
        the use of the Services by the Customer. The Licensor shall have no liability for any damage
        caused by errors or omissions in any information, instructions or scripts provided to the
        Licensor by the Customer in connection with the Services, or any actions taken by the
        Licensor at the Customer&apos;s direction;
      </li>
      <li>
        <strong>11.1.2</strong> all warranties, representations, conditions and all other terms of
        any kind whatsoever implied by statute or common law are, to the fullest extent permitted by
        applicable law, excluded from this licence; and
      </li>
      <li>
        <strong>11.1.3</strong> the Services are provided to the Customer on an &quot;as is&quot;
        basis.
      </li>
      <li>
        <strong>11.2</strong> Nothing in this licence shall limit or exclude the liability of either
        party for death or personal injury caused by its negligence or for fraud or fraudulent
        misrepresentation or for any other liability which may not be limited or excluded at law.
      </li>
      <li>
        <strong>11.3</strong> Subject to clauses 11.1 and 11.2, the Licensor shall not be liable
        whether in contract, tort (including negligence) for breach of statutory duty or otherwise
        howsoever under or in connection with this licence arising for any costs, claims, damages,
        liabilities or expenses for any:
      </li>
      <li>
        <strong>11.3.1</strong> direct loss or profit;
      </li>
      <li>
        <strong>11.3.2</strong> direct loss of anticipated savings;
      </li>
      <li>
        <strong>11.3.3</strong> indirect loss of profit;
      </li>
      <li>
        <strong>11.3.4</strong> indirect loss of anticipated savings;
      </li>
      <li>
        <strong>11.3.5</strong> loss of revenue;
      </li>
      <li>
        <strong>11.3.6</strong> loss of contracts;
      </li>
      <li>
        <strong>11.3.7</strong> loss of business or business opportunity;
      </li>
      <li>
        <strong>11.3.8</strong> loss of goodwill or damage to reputation;
      </li>
      <li>
        <strong>11.3.9</strong> loss of or corruption of data;
      </li>
      <li>
        <strong>11.3.10</strong> any special, indirect or consequential loss or damage, and the
        parties agree that the categories of loss at this clause 11.3 shall be distinct and
        severable.
      </li>
      <li>
        <strong>11.4</strong> Notwithstanding anything else in this licence, the Licensor’s total
        liability whether in contract, tort (including negligence), misrepresentation (other than
        fraudulent misrepresentation), for breach of statutory duty or otherwise howsoever arising
        under or in connection with this licence shall be limited to an amount equal to the total
        amount of the Subscription Fees paid by the Customer in the year in which the event giving
        rise to the liability occurred.
      </li>
    </ol>
    <h2>12. Term and termination</h2>
    <ol>
      <li>
        <strong>12.1</strong> This licence shall, unless otherwise terminated as provided in this
        clause 12, commence on the Effective Date and shall continue for the Initial Term and,
        thereafter, this licence shall be automatically renewed for the Renewal Periods, unless:
      </li>
      <li>
        <strong>12.1.1</strong> either party serves written notice on the other party to terminate,
        in writing, at least 30 days before the end of the Initial Term or any Renewal Period, in
        which case this licence shall terminate upon the expiry of the applicable Initial Term or
        Renewal Period; or
      </li>
      <li>
        <strong>12.1.2</strong> it is otherwise terminated in accordance with the provisions of this
        licence.
      </li>
      <li>
        <strong>12.2</strong> Without affecting any other right or remedy available to it, either
        party may terminate this licence with immediate effect by giving written notice to the other
        party if:
      </li>
      <li>
        <strong>12.2.1</strong> the other party commits a material breach of any term of this
        licence (which shall include any breach by the Customer of clause 7) which breach is
        irremediable or (if such breach is remediable) fails to remedy that breach within a period
        of 14 days after being notified in writing to do so; or
      </li>
      <li>
        <strong>12.2.2</strong> the other party (being an individual or partnership) has a
        bankruptcy order made against it or any partner or it or any partner makes an arrangement or
        composition with its creditors or otherwise takes the benefit of any statutory provision for
        the time being in force for the relief of insolvent debtors or (being a body corporate)
        convenes a meeting of creditors (whether formal or informal) or enters into liquidation
        (whether voluntary or compulsory), or has a receiver and/or manager, administrator or
        administrative receiver appointed of its undertaking or any part thereof, or documents are
        filed with the court for the appointment of an administrator of that party or notice of
        intention to appoint an administrator is given by that party or its directors or by a
        qualifying floating charge holder, or a resolution is passed or a petition presented for the
        winding up of that party or for the granting of an administration order in respect of that
        party, or any proceedings are commenced relating to the insolvency or possible insolvency of
        that party.
      </li>
      <li>
        <strong>12.3</strong> On termination of this licence for any reason:
      </li>
      <li>
        <strong>12.3.1</strong> all licences granted under this licence shall immediately terminate
        and the Customer shall immediately cease all use of the Services;
      </li>
      <li>
        <strong>12.3.2</strong> each party shall return and make no further use of any equipment,
        property, Documentation (if any) and other items (and all copies of them) belonging to the
        other party;
      </li>
      <li>
        <strong>12.3.3</strong> each of the Licensor and Signstix may destroy or otherwise dispose
        of any of the Customer Data in its possession unless each of the Licensor and Signstix
        receives, no later than ten days after the effective date of the termination of this
        licence, a written request for the delivery to the Customer of the then most recent back-up
        of the Customer Data. The Licensor shall use reasonable commercial endeavours to deliver or
        arrange the delivery of the back-up to the Customer within 30 days of its receipt of such a
        written request, provided that the Customer has, at that time, paid all fees and charges
        outstanding at and resulting from termination (whether or not due at the date of
        termination). The Customer shall pay all reasonable expenses incurred by the Licensor and
        Signstix in returning or disposing of Customer Data; and
      </li>
      <li>
        <strong>12.3.4</strong> any rights, remedies, obligations or liabilities of the parties that
        have accrued up to the date of termination, including the right to claim damages in respect
        of any breach of the agreement which existed at or before the date of termination shall not
        be affected or prejudiced.
      </li>
    </ol>
    <h2>13. General</h2>
    <ol>
      <li>
        <strong>13.1</strong> No default, delay or failure to perform on the part of either party
        shall be considered a breach of this licence if such default, delay or failure to perform is
        shown to be to any event beyond the reasonable control of the defaulting party, including,
        without limitation, acts of God, governmental rule or order, strikes or other labour
        difficulties, fire, flood, lockout, explosion or civil commotion, failure of a
        telecommunications network or power outage. Nothing in this clause 13.1 shall excuse the
        Customer from any payment obligations under this licence.
      </li>
      <li>
        <strong>13.2</strong> The Licensor reserves the right to amend these terms and conditions
        from time to time and if at any time these terms and conditions are amended, they shall be
        deemed to supersede any previous terms and conditions of the Licensor.
      </li>
      <li>
        <strong>13.3</strong> The Customer hereby consents to Signstix using the Customer’s name and
        logo for the purpose of marketing the Services and any related services, to the extent that
        such use is limited to identifying the Customer on Signstix’s website or in its promotional
        material as a user of the Services.
      </li>
      <li>
        <strong>13.4</strong> No failure or delay by a party to exercise any right or remedy
        provided under this licence or by law shall constitute a waiver of that or any other right
        or remedy, nor shall it prevent or restrict the further exercise of that or any other right
        or remedy. No single or partial exercise of such right or remedy shall prevent or restrict
        the further exercise of that or any other right or remedy.
      </li>
      <li>
        <strong>13.5</strong> Except as expressly provided in this licence, the rights and remedies
        provided under this licence are in addition to, and not exclusive of, any rights or remedies
        provided by law.
      </li>
      <li>
        <strong>13.6</strong> If any provision (or part of a provision) of this licence is found by
        any court or administrative body of competent jurisdiction to be invalid, unenforceable or
        illegal, the other provisions shall remain in force. If any invalid, unenforceable or
        illegal provision would be valid, enforceable or legal if some part of it were deleted, the
        provision shall apply with whatever modification is necessary to give effect to the
        commercial intention of the parties.
      </li>
      <li>
        <strong>13.7</strong> This licence constitutes the entire agreement between the parties and
        supersedes and extinguishes all previous agreements, promises, assurances, warranties,
        representations and understandings between them, whether written or oral, relating to its
        subject matter.
      </li>
      <li>
        <strong>13.8</strong> Each party acknowledges that in entering into this licence it does not
        rely on, and shall have no remedies in respect of, any statement, representation, assurance
        or warranty (whether made innocently or negligently) that is not set out in this licence.
        Each party agrees that it shall have no claim for innocent or negligent misrepresentation or
        negligent misstatement based on any statement in this licence. Nothing in this clause shall
        limit or exclude any liability for fraud.
      </li>
      <li>
        <strong>13.9</strong> The Customer shall not, without the prior written consent of the
        Licensor, assign, transfer, charge, sub-contract or deal in any other manner with all or any
        of its rights or obligations under this licence.
      </li>
      <li>
        <strong>13.10</strong> Nothing in this licence is intended to or shall operate to create a
        partnership between the parties, or authorise either party to act as agent for the other,
        and neither party shall have the authority to act in the name or on behalf of or otherwise
        to bind the other in any way (including, but not limited to, the making of any
        representation or warranty, the assumption of any obligation or liability and the exercise
        of any right or power).
      </li>
      <li>
        <strong>13.11</strong> The parties to this licence acknowledge and agree that where Signstix
        does not act as Licensor for the purpose of this Agreement, a number of benefits are
        conferred on it hereunder. Except as expressly set out in this licence, this licence does
        not confer any rights on any person or party (other than the parties to this licence and,
        where applicable, their successors and permitted assigns) pursuant to the Contracts (Rights
        of Third Parties) Act 1999.
      </li>
      <li>
        <strong>13.12</strong> All notices required to be given under this licence shall be in
        writing and shall be deemed to have been duly made and received when personally served, or
        when mailed by registered mail, to the party to whom it is addressed at the party’s
        registered office.
      </li>
      <li>
        <strong>13.13</strong> This licence and any dispute or claim arising out of or in connection
        with it or its subject matter or formation (including non-contractual disputes or claims)
        shall be governed by and construed in accordance with the law of England and Wales, and each
        party irrevocably agrees that the courts of England and Wales shall have exclusive
        jurisdiction to settle any such dispute or claim except with regard to matters of
        enforcement in which case the jurisdiction of the English courts shall be non-exclusive.
      </li>
    </ol>
  </div>
);
