import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Checkbox } from 'react-bootstrap';
import {
  isQueryParamSuffix,
  appendUniqueQueryParam,
  truncateQueryParam,
} from '../../utils/updateQueryString';
import { addUniqueFilter } from '../../actions/filterActions';

export class FilterFolderDepth extends Component {
  componentDidMount() {
    this.setFilterFromQuery(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setFilterFromQuery(nextProps);
  }

  setFilterFromQuery = props => {
    const { location, name, title, addUniqueFilter } = props;
    const checked = isQueryParamSuffix(location, 'parent', '*');

    if (checked) {
      addUniqueFilter({
        group: name,
        name: '*',
        query: '*',
        text: '',
        title,
        suffix: { param: 'parent', value: '*' },
      });
    }
  };

  handleChange = () => {
    const { history, location } = this.props;
    const checked = isQueryParamSuffix(location, 'parent', '*');

    if (checked) {
      truncateQueryParam(history, location, 'parent', '*');
    } else {
      appendUniqueQueryParam(history, location, 'parent', '*', '/*');
    }
  };

  render() {
    const { location, name, title } = this.props;
    const checked = isQueryParamSuffix(location, 'parent', '*');

    return (
      <div className="filter-checkboxlist">
        <Checkbox
          data-test={`checkbox-${name}`}
          checked={checked}
          onChange={() => this.handleChange('*')}
        >
          {title}
        </Checkbox>
      </div>
    );
  }
}

FilterFolderDepth.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  addUniqueFilter: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  addUniqueFilter,
};

export default withRouter(connect(null, mapDispatchToProps)(FilterFolderDepth));
