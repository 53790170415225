import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  FormGroup,
  FormControl,
  ControlLabel,
  HelpBlock,
  Grid,
  Row,
  Col,
  Button,
  Checkbox,
} from 'react-bootstrap';
import ToolTipIcon from '../ToolTipIcon/ToolTipIcon';
import TagCloud from '../TagCloud/TagCloud';
import PlaylistFilesTable from './PlaylistFilesTable';
import TagControlledPlaylistPanel from './TagControlledPlaylistPanel';
import Metadata from '../Metadata/Metadata';
import { getPermissions } from '../../utils/getPermissions';

export default class PlaylistForm extends Component {
  state = {
    showErrors: false,
    errors: {},
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { showErrors } = this.state;

    if (showErrors) {
      this.validate(nextProps);
    }
  }

  onChange = event => {
    const { onChange } = this.props;

    onChange(event.target.name, event.target.value);
  };

  onChangeTag = tags => {
    const { onChange } = this.props;

    onChange('tags', tags);
  };

  onChangeCheckbox = event => {
    const { onChange } = this.props;
    const { name, checked } = event.target;

    onChange(name, checked);
  };

  errorClass = error => (error ? 'error' : null);

  validate = nextProps => {
    const props = nextProps || this.props;
    const {
      playlist: { name },
    } = props;
    const errors = {};

    if (!name || !name.trim()) {
      errors.name = 'This field cannot be empty.';
    } else if (!this.isUniqueName(props)) {
      errors.name = 'This name is already in use.';
    }

    this.setState({ showErrors: true, errors });

    return !errors.name;
  };

  submit = event => {
    const { isNew, onAdd, onUpdate } = this.props;

    event.preventDefault();

    if (!this.validate()) {
      return;
    }

    if (isNew) {
      onAdd();
    } else {
      onUpdate();
    }
  };

  isUniqueName = props => {
    const { playlists, playlist } = props;

    return !playlists.some(item => {
      const name = playlist.name.toLowerCase().trim();
      const itemName = item.name.toLowerCase().trim();

      return +item.id !== +playlist.id && itemName === name;
    });
  };

  getButtons = () => {
    const { auth, isNew, onCancel } = this.props;
    const p = getPermissions(auth);
    const add = (
      <Button key="1" data-test="button-add" bsStyle="success" type="submit">
        Add Playlist
      </Button>
    );
    const update = (
      <Button key="2" data-test="button-update" bsStyle="success" type="submit">
        Update Playlist
      </Button>
    );
    const cancel = (
      <Button key="3" data-test="button-cancel" onClick={onCancel}>
        Cancel
      </Button>
    );

    if (!p.writer) {
      return [cancel];
    }

    return isNew ? [cancel, add] : [cancel, update];
  };

  render() {
    const {
      auth,
      users,
      playlist,
      signs,
      sequences,
      schedules,
      playlists,
      campaigns,
      assignments,
      onSort,
      onAddFile,
      onRemoveFile,
    } = this.props;
    const { errors } = this.state;
    const p = getPermissions(auth);

    if (!playlist) {
      return null;
    }

    const { name, description, tags, tagControlled } = playlist;

    return (
      <form onSubmit={this.submit}>
        <Grid fluid>
          <Row>
            <Col md={6}>
              <FormGroup validationState={this.errorClass(errors.name)}>
                <ControlLabel>Name</ControlLabel>
                <FormControl
                  data-test="input-name"
                  name="name"
                  value={name}
                  onChange={this.onChange}
                  disabled={!p.writer}
                />
                <HelpBlock>{errors.name}</HelpBlock>
              </FormGroup>
              <FormGroup>
                <ControlLabel>Description</ControlLabel>
                <FormControl
                  data-test="input-desc"
                  name="description"
                  componentClass="textarea"
                  rows="4"
                  placeholder="Enter description"
                  value={description}
                  onChange={this.onChange}
                  disabled={!p.writer}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <ControlLabel>Tags</ControlLabel>
                <TagCloud tags={tags} onChange={this.onChangeTag} disabled={!p.writer} />
              </FormGroup>
              <FormGroup>
                <Checkbox
                  data-test="checkbox-tag-controlled"
                  name="tagControlled"
                  checked={Boolean(tagControlled)}
                  onChange={this.onChangeCheckbox}
                  disabled={!p.writer}
                >
                  Tag controlled playlist
                </Checkbox>
              </FormGroup>
            </Col>
          </Row>
        </Grid>
        <TagControlledPlaylistPanel
          playlist={playlist}
          signs={signs}
          sequences={sequences}
          schedules={schedules}
          playlists={playlists}
          campaigns={campaigns}
          assignments={assignments}
        />
        {p.writer && (
          <ToolTipIcon className="pull-right add-files" toolText="Add Files" onClick={onAddFile}>
            add_circle
          </ToolTipIcon>
        )}
        <h4>Playlist Entries</h4>
        <PlaylistFilesTable
          auth={auth}
          data={playlist.files}
          onSort={onSort}
          onRemoveFile={onRemoveFile}
        />
        <Metadata data={playlist} users={users} />
        <div className="form-buttons">{this.getButtons()}</div>
      </form>
    );
  }
}

PlaylistForm.propTypes = {
  auth: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
  playlist: PropTypes.object,
  signs: PropTypes.array,
  sequences: PropTypes.array,
  schedules: PropTypes.array,
  playlists: PropTypes.array,
  campaigns: PropTypes.array,
  assignments: PropTypes.array,
  isNew: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
  onAddFile: PropTypes.func.isRequired,
  onRemoveFile: PropTypes.func.isRequired,
};
