import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { withRouter, Prompt } from 'react-router-dom';
import { SOLR_DELAY } from '../../../../constants';
import { addNotification } from '../../../actions/notificationActions';
import { load as loadAuth } from '../../../actions/authActions';
import { getUsers } from '../../../actions/userActions';
import { getSigns } from '../../../actions/signActions';
import { loadSequences } from '../../../actions/sequenceActions';
import { loadSchedules } from '../../../actions/scheduleActions';
import { loadPlaylists } from '../../../actions/playlistActions';
import { loadCampaigns } from '../../../actions/campaignActions';
import { loadAllAssignments } from '../../../actions/assignmentActions';
import { getFonts, getFont, updateFont, deleteFont } from '../../../actions/fontActions';
import Header from '../../../components/Header/Header';
import ActionsMenu from '../../../components/ActionsMenu/ActionsMenu';
import QuickAction from '../../../components/QuickAction/QuickAction';
import Spinner from '../../../components/Spinner/Spinner';
import Confirm from '../../../components/Confirm/Confirm';
import FontForm from '../../../components/Fonts/FontForm';
import Used from '../../../components/Used/Used';
import ErrorPage from '../ErrorPage/ErrorPage';
import errorMessage from '../../../utils/errorMessage';

export class FontPage extends Component {
  state = {
    font: {},
    dirty: false,
  };

  componentDidMount() {
    this.loadData();
    document.addEventListener('visibilitychange', this.loadData);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      auth: {
        user: { defaultClientFont },
      },
      fonts: { font },
    } = nextProps;
    const {
      font: { id },
    } = this.state;

    if (id !== font.id) {
      this.setState({
        font: {
          ...font,
          defaultClientFont,
          shouldBeDefault: font.filename === defaultClientFont,
        },
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('visibilitychange', this.loadData);
  }

  loadData = () => {
    if (document.visibilityState === 'hidden') {
      return;
    }

    const {
      match: {
        params: { id },
      },
      getSigns,
      loadSequences,
      loadSchedules,
      loadPlaylists,
      loadCampaigns,
      loadAllAssignments,
      getUsers,
      getFonts,
      getFont,
    } = this.props;

    getSigns('?count=100000000');
    loadSequences('?count=100000000');
    loadSchedules('?count=100000000');
    loadPlaylists('?count=100000000');
    loadCampaigns('?count=100000000');
    loadAllAssignments();
    getUsers();
    getFonts();

    if (id) {
      getFont(id);
    }
  };

  goBack = () => {
    const { history, previousLocation } = this.props;
    const url = previousLocation ? previousLocation.pathname + previousLocation.search : '/fonts';

    this.setState({ dirty: false }, () => history.push(url));
  };

  update = () => {
    const {
      match: {
        params: { id },
      },
      getFont,
      loadAuth,
      addNotification,
    } = this.props;
    const { font } = this.state;

    return updateFont(id, font).then(
      () => {
        addNotification({ type: 'success', text: 'Font updated.' });
        getFont(id);
        loadAuth();
        setTimeout(this.goBack, SOLR_DELAY);
      },
      err => addNotification({ type: 'danger', text: errorMessage(err) }),
    );
  };

  delete = () => {
    const {
      match: {
        params: { id },
      },
      loadAuth,
      addNotification,
    } = this.props;

    return deleteFont(id).then(
      () => {
        addNotification({ type: 'success', text: 'Font deleted.' });
        loadAuth();
        setTimeout(this.goBack, SOLR_DELAY);
      },
      err => addNotification({ type: 'danger', text: errorMessage(err) }),
    );
  };

  onDelete = () => {
    const {
      fonts: {
        font: { usedInSigns },
      },
    } = this.props;

    this.refDelete.show(
      'Delete Font',
      usedInSigns
        ? 'This Font is used in signs and cannot be deleted. Please remove it from the signs first.'
        : 'Are you sure you want to delete this Font?',
    );

    if (!usedInSigns) {
      this.refDelete.onSuccess(this.delete);
    }
  };

  onChange = (key, value) => {
    const { font } = this.state;

    this.setState({ font: { ...font, [key]: value }, dirty: true });
  };

  getCss = () => {
    const {
      fonts: { font },
    } = this.props;
    const src = [];
    let url;

    if (font.default) {
      url = '/creatorWrapper/creator/style/fonts/';

      if (font.woff2_url) {
        src.push(`url("${url}${font.woff2_url}") format("woff2")`);
      }

      if (font.woff_url) {
        src.push(`url("${url}${font.woff_url}") format("woff")`);
      }

      if (font.ttf_url) {
        if (font.ttf_url.endsWith('.ttf')) {
          src.push(`url("${url}${font.ttf_url}") format("truetype")`);
        } else {
          src.push(`url("${url}${font.ttf_url}") format("opentype")`);
        }
      }
    } else {
      url = `/cms/data/v1/fonts/${font.id}/data/`;

      if (font.woff2_url) {
        src.push(`url("${url}woff2") format("woff2")`);
      }

      if (font.woff_url) {
        src.push(`url("${url}woff") format("woff")`);
      }

      if (font.ttf_url) {
        if (font.ttf_url.endsWith('.ttf')) {
          src.push(`url("${url}ttf") format("truetype")`);
        } else {
          src.push(`url("${url}otf") format("opentype")`);
        }
      }
    }

    return (
      `@font-face { font-family: "${font.filename}"; src: ${src.join(',\n')}; }\n` +
      `.font-${font.id} { font-family: "${font.filename}"; }\n`
    );
  };

  getHeaderActions = () => {
    const {
      auth: {
        user: {
          permissions: { Writer },
        },
      },
      fonts: { font },
    } = this.props;

    if (!Writer || font.default) {
      return [];
    }

    return [
      <QuickAction key="1" toolText="Delete Font" onClick={this.onDelete} alwaysShow>
        delete
      </QuickAction>,
    ];
  };

  render() {
    const {
      auth,
      users: { users },
      fonts: { fonts, font, loading, error },
      signs: {
        signs: { signs },
      },
      sequences: {
        sequences: { sequences },
      },
      schedules: {
        schedules: { schedules },
      },
      playlists: {
        playlists: { playlists },
      },
      campaigns: {
        campaigns: { campaigns },
      },
      assignments: {
        allAssignments: { assignments },
      },
    } = this.props;
    const { font: stateFont, dirty } = this.state;

    if (error) {
      return <ErrorPage error={error} />;
    }

    if (!font.id) {
      return null;
    }

    return (
      <div className="page-structure">
        <Helmet title="Font" />
        <Header overwrite={{ id: font }} />
        <ActionsMenu actions={this.getHeaderActions()} />
        <div className="page-panels">
          <div className="page-content page-font">
            <style dangerouslySetInnerHTML={{ __html: this.getCss() }} />
            <div className="preview">
              <div className={`font font-${font.id}`}>
                The quick brown fox jumps over the lazy dog
              </div>
            </div>
            <Spinner loading={loading}>
              <Prompt
                when={dirty}
                message="You have unsaved changes. Are you sure you want to leave this page?"
              />
              <FontForm
                auth={auth}
                users={users}
                fonts={fonts}
                font={stateFont}
                onChange={this.onChange}
                onUpdate={this.update}
                onCancel={this.goBack}
              />
              <Used
                type="asset"
                item={font}
                signs={signs}
                sequences={sequences}
                schedules={schedules}
                playlists={playlists}
                campaigns={campaigns}
                assignments={assignments}
                auth={auth}
                users={users}
              />
            </Spinner>
          </div>
        </div>
        <Confirm
          ref={ref => {
            this.refDelete = ref;
          }}
        />
      </div>
    );
  }
}

FontPage.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  previousLocation: PropTypes.object,
  auth: PropTypes.object.isRequired,
  users: PropTypes.object.isRequired,
  signs: PropTypes.object.isRequired,
  sequences: PropTypes.object.isRequired,
  schedules: PropTypes.object.isRequired,
  playlists: PropTypes.object.isRequired,
  campaigns: PropTypes.object.isRequired,
  assignments: PropTypes.object.isRequired,
  fonts: PropTypes.object.isRequired,
  loadAuth: PropTypes.func.isRequired,
  getUsers: PropTypes.func.isRequired,
  getSigns: PropTypes.func.isRequired,
  loadSequences: PropTypes.func.isRequired,
  loadSchedules: PropTypes.func.isRequired,
  loadPlaylists: PropTypes.func.isRequired,
  loadCampaigns: PropTypes.func.isRequired,
  loadAllAssignments: PropTypes.func.isRequired,
  getFont: PropTypes.func.isRequired,
  getFonts: PropTypes.func.isRequired,
  addNotification: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  previousLocation: state.app.previousLocation,
  auth: state.auth,
  users: state.users,
  signs: state.signs,
  sequences: state.sequences,
  schedules: state.schedules,
  playlists: state.playlists,
  campaigns: state.campaigns,
  assignments: state.assignments,
  fonts: state.fonts,
});
const mapDispatchToProps = {
  loadAuth,
  getUsers,
  getSigns,
  loadSequences,
  loadSchedules,
  loadPlaylists,
  loadCampaigns,
  loadAllAssignments,
  getFont,
  getFonts,
  addNotification,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FontPage));
