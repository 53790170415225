import React from 'react';
import { Switch, Route } from 'react-router-dom';
import App from './containers/App/App';
import Asset from './containers/Pages/Assets/Asset';
import Assignments from './containers/Pages/Assignments/Assignments';
import Campaign from './containers/Pages/Campaigns/Campaign';
import CampaignSchedule from './containers/Pages/CampaignSchedule/CampaignSchedule';
import Contents from './containers/Pages/Content/Contents';
import Dashboard from './containers/Pages/Dashboard/Dashboard';
import DataObject from './containers/Pages/DataObjects/DataObject';
import DataObjects from './containers/Pages/DataObjects/DataObjects';
import Device from './containers/Pages/Devices/Device';
import Devices from './containers/Pages/Devices/Devices';
import ErrorPage from './containers/Pages/ErrorPage/ErrorPage';
import Folder from './containers/Pages/Folders/Folder';
import Font from './containers/Pages/Fonts/Font';
import Fonts from './containers/Pages/Fonts/Fonts';
import Home from './containers/Pages/Home/Home';
import Location from './containers/Pages/Locations/Location';
import Locations from './containers/Pages/Locations/Locations';
import Login from './containers/Pages/Login/Login';
import Playlist from './containers/Pages/Playlists/Playlist';
import Role from './containers/Pages/Roles/Role';
import Roles from './containers/Pages/Roles/Roles';
import Schedule from './containers/Pages/Schedules/Schedule';
import Sequence from './containers/Pages/Sequences/Sequence';
import Sign from './containers/Pages/Signs/Sign';
import Tier from './containers/Pages/DataObjects/Tier';
import TierLocations from './containers/Pages/DataObjects/TierLocations';
import UrlRuleset from './containers/Pages/UrlRulesets/UrlRuleset';
import UrlRulesets from './containers/Pages/UrlRulesets/UrlRulesets';
import User from './containers/Pages/Users/User';
import Users from './containers/Pages/Users/Users';

export default () => (
  <Switch>
    <Route path="/login">
      <Login />
    </Route>
    <App>
      <Switch>
        <Route path="/assets/:id">
          <Asset />
        </Route>
        <Route path="/campaigns/new">
          <Campaign />
        </Route>
        <Route path="/campaigns/:id/assignments">
          <Assignments />
        </Route>
        <Route path="/campaigns/:id">
          <Campaign />
        </Route>
        <Route path="/campaign-schedule">
          <CampaignSchedule />
        </Route>
        <Route path="/content">
          <Contents />
        </Route>
        <Route path="/dashboard">
          <Dashboard />
        </Route>
        <Route path="/data-objects/:id/:tierid/locations">
          <TierLocations />
        </Route>
        <Route path="/data-objects/:id/:tierid">
          <Tier />
        </Route>
        <Route path="/data-objects/:id">
          <DataObject />
        </Route>
        <Route path="/data-objects">
          <DataObjects />
        </Route>
        <Route path="/devices/:id">
          <Device />
        </Route>
        <Route path="/devices">
          <Devices />
        </Route>
        <Route path="/folders/:id">
          <Folder />
        </Route>
        <Route path="/fonts/:id">
          <Font />
        </Route>
        <Route path="/fonts">
          <Fonts />
        </Route>
        <Route path="/home">
          <Home />
        </Route>
        <Route path="/locations/:id">
          <Location />
        </Route>
        <Route path="/locations">
          <Locations />
        </Route>
        <Route path="/playlists/new">
          <Playlist />
        </Route>
        <Route path="/playlists/:id">
          <Playlist />
        </Route>
        <Route path="/roles/new">
          <Role />
        </Route>
        <Route path="/roles/:id">
          <Role />
        </Route>
        <Route path="/roles">
          <Roles />
        </Route>
        <Route path="/schedules/new">
          <Schedule />
        </Route>
        <Route path="/schedules/:id">
          <Schedule />
        </Route>
        <Route path="/sequences/new">
          <Sequence />
        </Route>
        <Route path="/sequences/:id">
          <Sequence />
        </Route>
        <Route path="/signs/:id">
          <Sign />
        </Route>
        <Route path="/url-rulesets/:id">
          <UrlRuleset />
        </Route>
        <Route path="/url-rulesets">
          <UrlRulesets />
        </Route>
        <Route path="/user">
          <User />
        </Route>
        <Route path="/users/new">
          <User />
        </Route>
        <Route path="/users/:id">
          <User />
        </Route>
        <Route path="/users">
          <Users />
        </Route>
        <Route>
          <ErrorPage error={{ status: 404 }} />
        </Route>
      </Switch>
    </App>
  </Switch>
);
