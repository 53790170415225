import getSignsForAsset from './getSignsForAsset';
import getAssignmentsForSign from './getAssignmentsForSign';

// used for image, folder, urlruleset, dataobject, tier
const getAssignmentsForAsset = (asset, signs, sequences, schedules, playlists, assignments) => {
  if (!asset || !signs || !sequences || !schedules || !playlists || !assignments) {
    return [];
  }

  const used = [];

  getSignsForAsset(asset, signs)
    .filter(item => item.published)
    .forEach(sign => {
      const signUsedInAssignments = getAssignmentsForSign(
        sign,
        sequences,
        schedules,
        playlists,
        assignments,
      );

      (signUsedInAssignments || []).forEach(assignment => {
        if (!used.find(item => item.id === assignment.id)) {
          used.push(assignment);
        }
      });
    });

  return used.sort((a, b) => a.name.localeCompare(b.name));
};

export default getAssignmentsForAsset;
