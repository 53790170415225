import 'isomorphic-fetch';
import fetchCheck from '../redux/middleware/fetchCheck';
import * as types from './actionTypes';

export const url = '/cms/data/v1/campaigns/';

export const credentials = 'include';

export const headers = { Accept: 'application/json', 'Content-Type': 'application/json' };

export const campaignsRequest = () => ({ type: types.CAMPAIGNS_REQUEST });

export const campaignsRequestSuccess = result => ({
  type: types.CAMPAIGNS_REQUEST_SUCCESS,
  result,
});

export const campaignsRequestFailed = error => ({ type: types.CAMPAIGNS_REQUEST_FAILED, error });

export const campaignRequest = () => ({ type: types.CAMPAIGN_REQUEST });

export const campaignRequestSuccess = result => ({
  type: types.CAMPAIGN_REQUEST_SUCCESS,
  result,
});

export const campaignRequestFailed = error => ({ type: types.CAMPAIGN_REQUEST_FAILED, error });

export const campaignAdded = () => ({ type: types.CAMPAIGN_ADDED_CAMPAIGN });

export const clearCampaignForm = () => ({ type: types.CAMPAIGN_FORM_CLEAR });

export const fetchCampaigns = query =>
  fetch(url + (query || ''), { method: 'GET', credentials, headers })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const fetchCampaign = id =>
  fetch(url + id, { method: 'GET', credentials, headers })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const createCampaign = campaign =>
  fetch(url, { method: 'POST', credentials, headers, body: JSON.stringify(campaign) })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const updateCampaign = (id, campaign) =>
  fetch(url + id, { method: 'PUT', credentials, headers, body: JSON.stringify(campaign) })
    .then(res => fetchCheck(res))
    .then(res => res.json());

export const deleteCampaign = id =>
  fetch(url + id, { method: 'DELETE', credentials, headers }).then(res => fetchCheck(res));

export const loadCampaigns = query => dispatch => {
  dispatch(campaignsRequest());

  return fetchCampaigns(query).then(
    res => dispatch(campaignsRequestSuccess(res)),
    err => dispatch(campaignsRequestFailed(err)),
  );
};

export const loadCampaign = id => dispatch => {
  dispatch(campaignRequest());

  return fetchCampaign(id).then(
    res => dispatch(campaignRequestSuccess(res)),
    err => dispatch(campaignRequestFailed(err)),
  );
};
