import clone from 'clone';
import * as types from '../actions/actionTypes';

export const initialState = {
  loadingSequence: false,
  loadingSequences: false,
  error: null,
  sequences: {},
  sequence: {},
  edit: {},
};

export default function sequences(state = initialState, action = {}) {
  switch (action.type) {
    case types.SEQUENCES_REQUEST:
      return {
        ...state,
        loadingSequences: true,
        error: null,
      };
    case types.SEQUENCES_REQUEST_SUCCESS:
      return {
        ...state,
        loadingSequences: false,
        sequences: action.result,
      };
    case types.SEQUENCES_REQUEST_FAILED:
      return {
        ...state,
        loadingSequences: false,
        error: action.error,
      };
    case types.SEQUENCE_REQUEST:
      return {
        ...state,
        loadingSequence: true,
        error: null,
      };
    case types.SEQUENCE_REQUEST_SUCCESS:
      return {
        ...state,
        loadingSequence: false,
        sequence: action.result,
        edit: clone(action.result),
      };
    case types.SEQUENCE_REQUEST_FAILED:
      return {
        ...state,
        loadingSequence: false,
        error: action.error,
      };
    case types.SEQUENCE_FORM_SET:
      return {
        ...state,
        edit: clone(action.sequence),
      };
    case types.SEQUENCE_FORM_EDIT:
      return {
        ...state,
        edit: {
          ...state.edit,
          [action.key]: action.value,
        },
      };
    default:
      return state;
  }
}
